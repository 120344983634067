// Customizable Area Start
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import React from "react";

export interface Props {
    children: React.ReactNode;
}

interface S {
    open: boolean;
    message: string;
}

interface SS {
    id: any;
}
export default class CustomAlertController extends BlockComponent<
    Props,
    S,
    SS
> {
    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.subScribedMessages = [
            getName(MessageEnum.CustomAlertMessage),

        ];

        this.state = {
            open: false,
            message: '',
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
    async receive(from: string, message: Message) {
        if (message.id === getName(MessageEnum.CustomAlertMessage)) {
            const customMessage = message.getData(getName(MessageEnum.CustomAlertBodyData));
            this.setState({ open: true, message: customMessage })
        }
    }
    /* istanbul ignore next */
    handleCloseAlert = () => {
        this.setState({ open: false })
        const redirect1: Message = new Message(getName(MessageEnum.redirectMessage));
        redirect1.addData(getName(MessageEnum.redirectData), true);
        this.send(redirect1);
    }
}

// Customizable Area End
