// Customizable Area Start
import React from "react";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import {EditorState,convertToRaw, ContentState,convertFromHTML , CompositeDecorator} from 'draft-js';
import draftToHtml from 'draftjs-to-html';


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    match: any;
    location: any;
    history: any;
}

interface S {
    arrayHolder: any;
    token: string;
    project_name: string;
    isProject_name_valid: boolean | null;
    deal_id: string;
    is_draft: boolean;
    errorMessage: string;
    isReadMode: boolean;
    isNext: boolean;
    redirect: boolean;
    editorState:any;
    project_description_show:any;
}

interface SS {
    id: any;
}

export default class ProjectDetailController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    productListApiCallId: string = "";
    saveAndDraftApiCallId: string = "";
    validateProjectNameApiCallId: string = "";
    getProjectDetailsAPICalledId: string = "";
    updateSaveAndDraftApiCallId: string = "";
    update_saveAndDraftApiCallId: string = "";
    submit_ProjectDetailsApiCallId: string = "";
    update_submit_ProjectDetailsApiCallId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.redirectMessage),
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            project_name: "",
            deal_id: "",
            isProject_name_valid: null,
            is_draft: true,
            errorMessage: "",
            isReadMode: false,
            isNext: false,
            redirect: false,
            project_description_show:'',
            editorState:EditorState.createEmpty()
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getTokenValueProject();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getTokenValueProject();
            });
        }
    }

    onEditorStateChange = (editorState: EditorState) => {
        /* istanbul ignore next */
        this.setState({
          editorState,
            project_description_show: editorState.getCurrentContent().hasText()
                ? draftToHtml(convertToRaw(editorState.getCurrentContent()))
                : '' 
        });
    };

    findLinkEntities = (contentBlock: any, callback: any, contentState: any) => {
        contentBlock.findEntityRanges(
          (character: any) => {
            const entityKey = character.getEntity();
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'LINK'
            );
          },
          callback
        );
    }

    Link = (props: any) => {
        const {url} = props.contentState.getEntity(props.entityKey).getData();
        return (
          <a href={url} >
            {props.children}
          </a>
        );
      };

    Image = (props: any) => {
        const {
          height,
          src,
          width,
        } = props.contentState.getEntity(props.entityKey).getData();

        return (
          <img src={src} height={height} width={width} />
        );
      };

    findImageEntities = (contentBlock: any, callback: any, contentState: any) => {
        contentBlock.findEntityRanges(
          (character: any) => {
            const entityKey = character.getEntity();
            return (
              entityKey !== null &&
              contentState.getEntity(entityKey).getType() === 'IMAGE'
            );
          },
          callback
        );
      }

    getTokenValueProject = () => {
        const msgProject: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msgProject);
    };

    getListRequest = (tokenValue: any) => {
        const headerProjectList = {
            "Content-Type": configJSON.productApiContentType,
            token: tokenValue,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProductApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headerProjectList)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    /* istanbul ignore next */
    async receive(from: string, message: Message) {
        let responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (message.id === getName(MessageEnum.redirectMessage)) {
            const customRedirect = message.getData(getName(MessageEnum.redirectData));
            if (this.state.redirect && customRedirect) {
                this.props.history.push("/Catalogue/FinancialSummary");
            }
        }

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
            if (apiRequestCallId === this.saveAndDraftApiCallId) {
                if (responseJson?.data?.id) {
                    this.setState({ isProject_name_valid: true })
                    localStorage.setItem("projectDetails_id", responseJson?.data?.id);
                }
                this.responseHandlingForSaveUpdateDraftApiCall(responseJson)
            }

            if (apiRequestCallId === this.update_saveAndDraftApiCallId) {
                if (responseJson?.data?.id) {
                    this.setState({ isProject_name_valid: true })
                }
                this.responseHandlingForSaveUpdateDraftApiCall(responseJson)
            }

            if (apiRequestCallId === this.submit_ProjectDetailsApiCallId) {
                if (responseJson?.data?.id) {
                    this.setState({ isProject_name_valid: true })
                    localStorage.setItem("projectDetails_id", responseJson?.data?.id);
                }
                /* istanbul ignore next */
                if (responseJson?.message) {
                    const msg25: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg25.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.message);
                    this.send(msg25);
                    this.getProjectDetails();
                    this.setState({ redirect :true})
                }else{
                    this.setState({ redirect: false })
                }
                /* istanbul ignore next */
                if (responseJson?.meta?.message) {
                    const msg26: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg26.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                    this.send(msg26);
                    this.setState({ redirect: true })
                }
                    /* istanbul ignore next */
                else {
                    let res = responseJson?.errors
                        .map((i: any) => Object.keys(i) + " " + Object.values(i))
                        .join("\n");
                    const msg27: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg27.addData(getName(MessageEnum.CustomAlertBodyData), res);
                    this.send(msg27);
                    this.setState({ redirect: false })
                }
            }
            if (apiRequestCallId === this.update_submit_ProjectDetailsApiCallId) {
                /* istanbul ignore next */
                if (responseJson?.meta?.message) {
                    const msg28: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg28.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                    this.send(msg28);
                    this.getProjectDetails();
                    this.setState({ isProject_name_valid: true })
                    this.setState({ redirect: true })
                }
                    /* istanbul ignore next */
                else {
                    let res = responseJson?.errors
                        .map((i: any) => Object.keys(i) + " " + Object.values(i))
                        .join("\n");
                    const msg29: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg29.addData(getName(MessageEnum.CustomAlertBodyData), res);
                    this.send(msg29);
                    this.setState({ redirect: false })
                }
            }

            if (this.validateProjectNameApiCallId === apiRequestCallId) {
                this.state.project_name?.length === 0 && this.setState({ isProject_name_valid: false })
                if (responseJson.message === "NIL") {
                    this.setState({ isProject_name_valid: true })
                } else {
                    this.setState({ isProject_name_valid: false })
                }
            }
        /* istanbul ignore next */
        if (this.updateSaveAndDraftApiCallId === apiRequestCallId && responseJson?.meta?.message) {
                const msg30: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                msg30.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                this.send(msg30);
                this.getProjectDetails()
            }

            if (this.getProjectDetailsAPICalledId === apiRequestCallId) {
                if (responseJson?.data) {
                    const decorator = new CompositeDecorator([
                        {
                          strategy: this.findLinkEntities,
                          component: this.Link,
                        },
                        {
                          strategy: this.findImageEntities,
                          component: this.Image,
                        }
                      ]);
                    const blocksFromHTML = convertFromHTML(responseJson?.data?.attributes?.project_description);
                    const state = ContentState.createFromBlockArray(
                        blocksFromHTML.contentBlocks,
                        blocksFromHTML.entityMap,
                    );
                    localStorage.setItem("projectDetails_id", responseJson?.data?.id)
                    this.setState({ 
                        project_name: responseJson?.data?.attributes?.project_name,
                        project_description_show: responseJson?.data?.attributes?.project_description,
                        editorState: EditorState.createWithContent(
                            state,
                            decorator,
                        ),
                       isProject_name_valid: true 
                    })
                }
                else {
                    localStorage.setItem("projectDetails_id", "")
                }
            }
        
        runEngine.debugLog("Message Recived", message);

    }

    responseHandlingForSaveUpdateDraftApiCall = (responseJson: any) => {
        /* istanbul ignore next */
        if (responseJson?.meta?.message) {
            const msg31: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg31.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
            this.send(msg31);
            this.getProjectDetails()
        }
        else {
            this.errorHandlingForSaveUpdateDraftApiCall(responseJson)
        }
    }

    errorHandlingForSaveUpdateDraftApiCall = (responseJson: any) => {
        let errorMessage = "";
        if(Array.isArray(responseJson?.errors)){
            responseJson?.errors?.forEach((message: any, index: any) => {
                Object.keys(message).forEach((key) => {
                    errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                })
            })
        }else{
            errorMessage = responseJson?.errors;
        }
        /* istanbul ignore next */
        if (errorMessage.includes('description')) {
            const msg32: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg32.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
            this.send(msg32);
        } else {
            this.setState({ isProject_name_valid: false, errorMessage })
        }
    }

    validateProjectName = () => {



        const header = {
            token: `${localStorage.getItem("token")}`,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.validateProjectNameApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/Check_project?project_name=${this.state.project_name}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );



        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    saveAsDraft = () => {

        let header = new Headers();
        header.append("token", `${localStorage.getItem("token")}`);

        let formData = new FormData();
        formData.append("data[project_name]", this.state.project_name);
        formData.append("data[project_description]", this.state.project_description_show)
        formData.append("is_draft", JSON.stringify(true))

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveAndDraftApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/project_details?deal_id=${localStorage.getItem("deal_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    update_saveAsDraft = () => {

        let header = new Headers();
        header.append("token", `${localStorage.getItem("token")}`);

        let formData = new FormData();
        formData.append("data[project_name]", this.state.project_name);
        formData.append("data[project_description]", this.state.project_description_show)
        formData.append("is_draft", JSON.stringify(true))

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.update_saveAndDraftApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/project_details/update?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("projectDetails_id")}`

        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }



    submit_projectDetails = () => {

        let header = new Headers();
        header.append("token", `${localStorage.getItem("token")}`);

        let formData = new FormData();
        formData.append("data[project_name]", this.state.project_name);
        formData.append("data[project_description]", this.state.project_description_show)
        formData.append("is_draft", JSON.stringify(false))

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.submit_ProjectDetailsApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/project_details?deal_id=${localStorage.getItem("deal_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    update_submit_ProjectDetailsData = () => {
        let header = new Headers();
        header.append("token", `${localStorage.getItem("token")}`);

        let formData = new FormData();
        formData.append("data[project_name]", this.state.project_name);
        formData.append("data[project_description]", this.state.project_description_show)
        formData.append("is_draft", JSON.stringify(false))

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.update_submit_ProjectDetailsApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/project_details/update?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("projectDetails_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }


    getProjectDetails = () => {
        const header = {
            token: `${localStorage.getItem("token")}`,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProjectDetailsAPICalledId = getValidationsMsg.messageId
        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_project/project_details/show?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("projectDetails_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            header
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }
}
// Customizable Area End
