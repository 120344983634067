import React from "react";
import "./styles.css";
import {
    FormControl,
    InputLabel,
    Select,
    NativeSelect,
    FormHelperText,
    MenuItem,
    makeStyles,
    Divider,
    OutlinedInput
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles({
    label: {
        paddingTop: "1%",
        paddingBottom: "1%",
        // fontFamily: "Roboto-Regular",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#000",
        paddingLeft: "16px",
    },

    option: {
        fontFamily: 'Lato !important',
        borderBottom: '1px solid #f2f4f6 !important',
        color: '#323232 !important',
        fontSize: '16px !important',
        display: 'flex',
        alignItems: 'center',
        height: 40,
        cursor: 'pointer'
    }
});

type SelectProps = {
    label: string;
    options: any;
    name: string;
    onChange: any;
    width: string;
    value: any;
    borderRadius: string;
    onClick: any;
    marginTop?: number;
    disabled?: boolean;
}

function CustomSelect(props: SelectProps) {
    const { label, options, width, name, onChange, value, borderRadius, onClick, marginTop, disabled } = props;
    const classes = useStyles();
    return (
        <div>
            <FormControl
                style={{ width: `${width}` }}
                className="customSelect_formControl"
                // fullWidth
                variant="outlined"
                size="small"
            >
                <Select
                    className="customSelect_select"
                    style={{ borderRadius: `${borderRadius}` }}
                    disableUnderline
                    displayEmpty
                    name={name}
                    disabled={disabled}
                    // native
                    value={value}
                    defaultValue={value}
                    IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
                    onChange={onChange}
                    onClick={onClick}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                borderRadius: "8px",
                            }
                        }
                    }}

                >
                    {/* <option style={{ display: "none", fontSize: "16px", fontWeight: 400, color: "rgba(50,50,50,0.6)" }} value="0" >{label} </option> */}
                    <MenuItem value="" disabled><span style={{ color: "darkgray" }}>{label}</span></MenuItem>
                    {options.map((item: any, index: number) => (
                        <MenuItem className={classes.option} key={item} value={item?.id}>
                            {item?.attributes?.title || item?.attributes?.name}
                        </MenuItem>
                    ))}
                </Select>
                {
                    name == "Minimum_Investment_per_Investor" ? (<FormHelperText>Note: Should be less than Total Deal Size </FormHelperText>) : null
                }
            </FormControl>
        </div>
    );
}

export default CustomSelect;
