import React from "react";
// Customizable Area Start
import {
  Grid,
  Box,
  Button,
  Typography,
  Paper,
  InputLabel,
  Link,
  Container,
} from "@material-ui/core";
import "./style.css";

import { backgroundImage, logo } from "./assets";

import ForgotPasswordController, { Props } from "./ForgotPasswordController";


export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }

  handleEmailChange = (e: any) => {
    let email = e.currentTarget.value;

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = email.split("@");

    !regex.test(email)
       ?  emailArray[0].length > 25 ||
        this.setState({ isErrorEmail: true })
      : this.setState({ isErrorEmail: false });

    this.setState({ email: e.currentTarget.value });
  };

  render() {
    const { navigation } = this.props;

    let isError =
      this.state.password.length === 0 ||
      this.state.reTypePassword.length === 0;
      return(
        <Grid container className="grid_parent_forgot">
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper elevation={3} className="paper_container">
              <Box className="left_container" style={{ backgroundImage: `url(${backgroundImage})`, }} >
                <Box className="sign_up_left_container">
                  <Box>
                    <img alt="logo" src={logo} />
                  </Box>
                </Box>
              </Box>
              <Box className="signUp_rightContainer">
                <Box className="sign_rightInner_container">
                  <Box className="signUp_right_content_Container">
                    <Typography
                      className="header_part"
                    >
                      Forgot your password?
                    </Typography>
                    <Typography
                      variant="body2"
                      className="subheader"
                    >
                      Please enter your email address you'd like your password
                      <br />
                      reset information sent to
                    </Typography>
                    <Box className="right_textInput_container">
                      <InputLabel required id="inputLabel">
                        Email
                      </InputLabel>

                      <input
                        className="signUp_right_textInput"
                        name="email"
                        placeholder="example@gmail.com"
                        onChange={this.handleEmailChange}
                      />
                    </Box>

                    <Box />

                    <Box style={{ width: "100%" }}>
                      <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        className="req_button"
                        onClick={this.forgotPwdRequest}
                      >
                        Request
                      </Button>
                    </Box>
                    <Box>
                      <Typography
                        style={{ textAlign: "center", marginTop: "50px",marginBottom:'12%' }}
                      >
                        Back to{" "}
                        <Link
                          onClick={() =>
                            this.props.navigation.navigate("EmailAccountLoginBlock")
                          }
                          id="sign_link"
                        >
                          Login
                        </Link>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Paper>
          </Grid>
        </Grid>
      );
  }
}
// Customizable Area End
