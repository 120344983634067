import React from "react";
// Customizable Area Start
import "./EmailAccountRegistration.css";
import {
  Box,
  Button,
  Typography,
  Paper,
  InputLabel,
  FormControlLabel,
  Checkbox,
  Link,
  Container,
} from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import { signUpBg, finLogo, verified_tick_image, activeCheckBox, inactiveCheckBox } from "./assets";

const styles = makeStyles(() => ({
  root: {},
  paper: {},
  header: {},
}));

import EmailAccountRegistrationController, {
  Props,
} from "./EmailAccountRegistrationController";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
import OtpInput from "../../../components/src/OtpInput";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class EmailAccountRegistration extends EmailAccountRegistrationController {
  constructor(props: Props) {
    super(props);

  }

  handleRole = (e: any) => {
    let role = e.target.value
    role === "Debt Provider" ? this.setState({ role_id: 2 }) : (role === "Debt Arranger" ? this.setState({ role_id: 3 }) : this.setState({ role_id: 1 }))
    this.setState({ role: e.target.value });
  };

  handleNameChange = (e: any) => {
    const regex = /^[a-zA-Z.][a-zA-Z. ]*$/

    let fullName = e.target.value;
    fullName.length > 25 || fullName.length < 2 || fullName.length === 0
      ? this.setState({ isErrorName: true })
      : this.setState({ isErrorName: false });
    if (regex.test(fullName) || fullName == '') {
      this.setState({ fullName });
    }

  };

  handleEmailChange = (e: any) => {
    let email = e.currentTarget.value;

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = email.split("@");

    !regex.test(email)
      || emailArray[0].length > 64 ||
      emailArray[1].length > 256
      ?
      this.setState({ isErrorEmail: true })
      : this.setState({ isErrorEmail: false });

    this.setState({ email: e.currentTarget.value });
  };

  handlePhoneChange = (e: any) => {
    const regex = /[0-9]$/;
    let phone = e.target.value;
    phone.length === 10
      ? this.setState({ isErrorPhone: false })
      : this.setState({ isErrorPhone: true });
    if (regex.test(phone) || phone == '') {
      this.setState({ phone: phone });
    }

  };

  move = (e: any, prev: string, current: string, next: string) => {
    let length = (document.getElementById(current) as HTMLInputElement)?.value?.length;
    let maxLength = document.getElementById(current)?.getAttribute("maxLength");

    let otp1 = (document.getElementById('text1') as HTMLInputElement)?.value || '';
    let otp2 = (document.getElementById('text2') as HTMLInputElement)?.value || '';
    let otp3 = (document.getElementById('text3') as HTMLInputElement)?.value || '';
    let otp4 = (document.getElementById('text4') as HTMLInputElement)?.value || '';

    let OTP = [otp1, otp2, otp3, otp4]

    if (String(length) == maxLength) {
      if (next !== "") {
        document.getElementById(next)?.focus()
      }
    }
    if (e.key === "Backspace") {
      if (prev !== "") {
        document.getElementById(prev)?.focus()
      }
    }

    OTP = OTP.filter(item => !!item)
    this.setState({ OTP })
    OTP.length == 4 ? setTimeout(this.verifyMobileOtp(OTP), 500) : null
  }

  handleCompanyName = (e: any) => {
    const regex = /^[a-zA-Z.][a-zA-Z. ]*$/

    let companyName = e.target.value;
    companyName.length > 0
      ? this.setState({ isErrorCompanyName: false })
      : this.setState({ isErrorCompanyName: true });

    if (regex.test(companyName) || companyName == '') {
      this.setState({ companyName });
    }
  };

  handlePasswordChange = (e: any) => {
    let password = e.currentTarget.value;
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    !regex.test(password) || password.length > 20
      ? this.setState({ isErrorPassword: true })
      : this.setState({ isErrorPassword: false });
    this.setState({ password: e.currentTarget.value });
  };

  handleConfirmPasswordChange = (e: any) => {
    this.setState({ reTypePassword: e.currentTarget.value });
  };

  handleCheck1 = () => {
    this.setState({ isCheck1: !this.state.isCheck1 });
  };

  handleCheck2 = () => {
    this.setState({ isCheck2: !this.state.isCheck2 });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();
    this.state.fullName.length == 0
      ? this.setState({ isErrorName: true })
      : null;

    this.state.email.length == 0 ? this.setState({ isErrorEmail: true }) : null;

    this.state.companyName.length == 0
      ? this.setState({ isErrorCompanyName: true })
      : null;
    this.state.phone.length == 0 ? this.setState({ isErrorPhone: true }) : null;

    this.state.password.length == 0
      ? this.setState({ isErrorPassword: true })
      : null;

    this.state.reTypePassword.length == 0
      ? this.setState({ isErrorConfirmPasssword: true })
      : null;

    this.state.isErrorName ||
      this.state.isErrorEmail ||
      this.state.isErrorCompanyName ||
      this.state.isErrorPhone ||
      this.state.isErrorPassword ||
      this.state.isErrorConfirmPasssword
      ? null
      : null;

    this.create_Account_With_Email();
  };

  render() {
    let isError =
      this.state.isErrorName ||
      this.state.isErrorEmail ||
      this.state.isErrorCompanyName ||
      this.state.isErrorPhone ||
      this.state.isErrorPassword ||
      this.state.isErrorConfirmPasssword ||
      !this.state.isCheck1 ||
      !this.state.isCheck2 ||
      this.state.fullName.length === 0 ||
      this.state.email.length === 0 ||
      this.state.companyName.length === 0 ||
      this.state.phone.length === 0 ||
      this.state.password.length === 0 ||
      this.state.reTypePassword.length === 0 || this.state.role.length === 0
    return (
      //Merge Engine DefaultContainer
      <Container maxWidth="lg" >
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
          <Alert severity="success">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpenError} autoHideDuration={4000} onClose={this.handleCloseError} >
          <Alert severity="error">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Paper className="paperContainer">
          <Box className="signUp_leftContainer" style={{ backgroundImage: `url(${signUpBg})`, }} textAlign="center">
            <Box className="signUp_left_content_Container">
              <Box mt={'15px'}>
                <img className="finLog" alt="logo" src={finLogo} />
              </Box>
              <Box >
                <Typography className="left_signup_text">
                  Signup
                </Typography>
              </Box>
              <Box pb='30px' >
                <Typography className="alreday_have_aacount_text">
                  Already have an account?
                  <Link
                    className="sign_link"
                    onClick={() =>
                      this.props.navigation.navigate("EmailAccountLoginBlock")
                    }
                  >
                    Login
                  </Link>
                </Typography>
              </Box>
            </Box>
          </Box>
          {this.state.registrationSuccess ? (
            <Paper
              style={{
                display: "flex",
                justifyContent: "center",
                alignContent: "center",
                width: "100%",
              }}
            >
              <Typography variant="h4">Successfully Register</Typography>
            </Paper>
          ) : (
            <Box className="signUp_rightContainer">

              <Box className="signUp_right_content_Container">
                <form onSubmit={this.handleSubmit}>
                  <Box className="signUp_right_roleContainer">
                    <CustomSelect
                      label="Role"
                      options={this.state.roleOptions}
                      onChange={this.handleRole}
                      role={this.state.role}
                    />
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <CustomInput
                      name="fullName"
                      placeholder="Name"
                      label="Name *"
                      value={this.state.fullName}
                      onChange={this.handleNameChange}
                      isMandatory={false}
                    />
                    {this.state.isErrorName ? (
                      <Typography
                        className="error_message"
                      >
                        Name should be between 2 - 25 character.
                      </Typography>
                    ) : null}
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <InputLabel className="singup_inputLabel" required >
                      Email
                    </InputLabel>
                    <Box className="emailInputContainer" >
                      <input
                        className="email_input_container"
                        disabled={this.state.isEmailVerified}
                        name="email"
                        placeholder="example@gmail.com"
                        onChange={this.handleEmailChange}
                        style={{ WebkitBoxShadow: "0 0 0 1000px white inset" }}
                      />
                      {this.state.isEmailVerified ? (
                        <Typography
                          className="email_verified_text"
                        >
                          Verified
                          <img className="verified_tick_logo" src={verified_tick_image} alt="" />
                        </Typography>
                      ) : (
                        <Button
                          className={this.state.isErrorEmail ||
                            this.state.email.length === 0
                            || this.state.fullName.length > 25
                            || this.state.fullName.length < 2 || this.state.fullName.length === 0 || this.state.role == "" ? "verify_email_button_disabled" : "verify_email_button"}

                          onClick={() => this.sendEmail()}
                          disabled={
                            this.state.isErrorEmail ||
                            this.state.email.length === 0
                            || this.state.fullName.length > 25
                            || this.state.fullName.length < 2 || this.state.fullName.length === 0 || this.state.role == ""
                          }
                          variant="contained"
                        >
                          Verify email
                        </Button>
                      )}
                    </Box>
                    {this.state.isErrorEmail ? (
                      <Typography
                        className="error_message"
                      >
                        Please enter a valid email Address
                      </Typography>
                    ) : null}

                    {!this.state.isEmailVerified &&
                      this.state.email_verification_link_send ? (
                      <Box pl={2} mt={1}>
                        <Typography className="send_email_verification_message" >
                          A link has been sent to your email. Click the link to
                          verify it.
                        </Typography>
                      </Box>
                    ) :null}
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <CustomInput
                      label="Company Name"
                      name="companyName *"
                      placeholder="Company Name"
                      onChange={this.handleCompanyName}
                      value={this.state.companyName}
                      isMandatory={false}
                    />
                    {this.state.isErrorCompanyName ? (
                      <Typography
                        className="error_message"
                      >
                        Please enter a valid Company Name
                      </Typography>
                    ) : null}
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <InputLabel required className="singup_inputLabel">
                      Phone Number
                    </InputLabel>
                    <Box
                      className="phone_number_container"
                    >
                      <input
                        disabled={this.state.isMobileVerified}
                        className="signUp_right_textInput_mobile"
                        name="phoneNumber"
                        type="text"
                        placeholder="888 888 8888"
                        maxLength={10}
                        value={this.state.phone}
                        onChange={this.handlePhoneChange}
                        style={{ WebkitBoxShadow: "0 0 0 1000px white inset" }}
                      />
                      <Typography
                        className="label_91_mobile"
                      >
                        +91
                      </Typography>
                      {this.state.isMobileVerified ? (
                        <Typography
                          className="mobile_verified_text"
                        >
                          Verified
                          <img className="verified_tick_logo" src={verified_tick_image} alt="" />
                        </Typography>
                      ) : (
                        <Button
                          className={this.state.isErrorPhone || this.state.phone.length === 0 ? "request_otp_button_disabled" : "request_otp_button"}

                          disabled={this.state.isErrorPhone || this.state.phone.length === 0}
                          onClick={this.sentOtpToMobile}
                          variant="contained"
                        >
                          Request OTP
                        </Button>
                      )}

                    </Box>
                    {this.state.isErrorPhone ? (
                      <Typography
                        className="error_message"
                      >
                        Please enter your 10 digit Phone Number
                      </Typography>
                    ) : null}
                    {this.state.otp_sent_to_mobile ? (
                      <Box
                        p={1}
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        <Box pt={1} pb={1.5}>
                          <Typography className="mobile_otp_send_text_message">
                            A 4 digit code has sent to the number {this.state.phone}. Enter the code to continue
                          </Typography>{" "}
                        </Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                          }}
                        >
                          <Box
                            className="otp_container"
                          >
                            <OtpInput
                              id="text1"
                              onKeyUp={(e: any) => this.move(e, "", "text1", "text2")}
                            />

                            <OtpInput
                              id="text2"
                              onKeyUp={(e: any) => this.move(e, "text1", "text2", "text3")}
                            />

                            <OtpInput
                              id="text3"
                              onKeyUp={(e: any) => this.move(e, "text2", "text3", "text4")}
                            />

                            <OtpInput
                              id="text4"
                              onKeyUp={(e: any) => this.move(e, "text3", "text4", "")}
                            />

                          </Box>
                          <Box pl={1.5}>
                            <Typography className="did_not_receive_code" >
                              Didn’t receive code?
                              <Link className="resend_code" onClick={() => this.resentOtpToMobile()} >
                                Resend Code
                              </Link>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    ) : null}
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <InputLabel required className="singup_inputLabel">
                      Password
                    </InputLabel>

                    <input
                      className="signUp_right_textInput"
                      type="password"
                      name="password"
                      placeholder="Password"
                      style={{ WebkitBoxShadow: "0 0 0 1000px white inset" }}
                      onChange={this.handlePasswordChange}
                    />
                    {this.state.isErrorPassword &&
                      <Typography
                        className="error_message"
                      >
                        Please enter a password between 8 to 20 characters.
                      </Typography>
                    }
                    {
                      this.state.isErrorPassword && <Typography
                        className="show_password_helper_text"
                      >
                        * User can enter 8 characters,1 uppercase letter, 1
                        number, 1 special characters combinations of them in
                        this field.
                      </Typography>
                    }
                  </Box>
                  <Box className="signUp_right_textInput_container">
                    <InputLabel required className="singup_inputLabel">
                      Confirm Password
                    </InputLabel>

                    <input
                      className="signUp_right_textInput"
                      type="password"
                      name="reTypePassword"
                      placeholder="Confirm Password"
                      style={{ WebkitBoxShadow: "0 0 0 1000px white inset" }}
                      onChange={this.handleConfirmPasswordChange}
                    />
                    {this.state.password === this.state.reTypePassword ||
                      this.state.isErrorPassword ||
                      this.state.reTypePassword.length === 0 ? null : (
                      <Typography
                        className="error_message"
                      >
                        Password and Confirm Password does not match.
                      </Typography>
                    )}
                  </Box>
                  <Box />
                  <Box pt={3} pb={3}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          className="checkBox1"
                          color="primary"
                          checked={this.state.isCheck1}
                          checkedIcon={<img src={activeCheckBox} style={{ height: 20, width: 21 }} />}
                          icon={<img src={inactiveCheckBox} style={{ height: 20, width: 21 }} />}
                          style={{ height: 20, width: 21 }}
                          onChange={this.handleCheck1}

                        />
                      }
                      label={
                        <Typography className="privacyPolicy" >
                          By signing up, you confirm that you are above 18 years of age and that you are a citizen of India
                        </Typography>
                      }
                    />
                    <Box pt={2} />
                    <FormControlLabel
                      control={
                        <Checkbox
                          color="primary"
                          checked={this.state.isCheck2}
                          onChange={this.handleCheck2}
                          checkedIcon={<img src={activeCheckBox} style={{ height: 20, width: 21 }} />}
                          icon={<img src={inactiveCheckBox} style={{ height: 20, width: 21 }} />}
                          style={{ height: 20, width: 21 }}
                        />
                      }
                      label={
                        <Typography className="privacyPolicy" >
                          By signing up, you agree to the Terms and Conditions and Privacy Policy
                        </Typography>
                      }
                    />
                  </Box>
                  <Button
                    className="signup_button"
                    disabled={isError || this.state.password != this.state.reTypePassword || !this.state.isEmailVerified || !this.state.isMobileVerified}
                    type="submit"
                    fullWidth
                    style={{

                      backgroundImage: `${isError || this.state.password != this.state.reTypePassword || !this.state.isEmailVerified ? "linear-gradient(to left  ,#9e9e9e,#fafafa )" : "linear-gradient(to left  ,#001d56,#1e43d5 )"}`
                    }}
                  >

                    {this.state.loadingSignin ? <CircularProgress /> : "Signup"}
                  </Button>

                </form>
              </Box>
            </Box>

          )}
        </Paper>
      </Container>

      //Merge Engine End DefaultContainer
    );
  }
}

 // Customizable Area End
