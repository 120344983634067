Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.placeHolderEmail = "Email";

exports.labelHeader = "Value proposition: why users should sign up.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelEmail = "Email";
exports.labelPassword = "Password";
exports.labelRePassword = "Re-Type Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorBlankField = "can't be blank";

exports.labelLegalText =
  "Legal text: By signing up you agree to our Terms &amp; conditions and Privacy policy.";

exports.labelLegalTermCondition = "Terms and conditions";
exports.labelLegalPrivacyPolicy = "Privacy policy";
exports.btnTextSignUp = "Sign up";
exports.errorPasswordNotValid = "Password not valid.";

exports.errorTitle = "Error";

exports.errorBothPasswordsNotSame = "Passwords should be same.";
exports.errorAllFieldsAreMandatory = "All fields are mandatory.";

exports.contentTypeApiAddDetail = "application/json";
exports.sendEmailApiEndPoint = "account_block/accounts/email_confirmation";
exports.sendOTP_TO_MOBILE_ApiEndPoint = "account/accounts/send_otp";
exports.verify_OTP_ApiEndPoint = "account/accounts/sms_confirmation";
exports.resend_OTP_TO_MOBILE_ApiEndPoint = "resend_otp";
exports.accountsAPiEndPoint =
  "account/accounts";

exports.apiMethodTypeAddDetail = "POST";

exports.errorEmailNotValid = "Email not valid.";
exports.urlGetValidations =
  "profile/validations";
exports.validationApiContentType = "application/json";

exports.validationApiMethodType = "GET";
exports.chatCredentialsAPiEndPoint = "bx_block_chatbackuprestore/chat_credentials"
exports.initiateChatApiEndpoint = 'bx_block_bank_deal/dashboard/notify_by_mail_to_initiate_chat'
exports.someSecret= 'someSecret'
// Customizable Area End
