import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import {
  Typography
} from "@material-ui/core";
import { imgPasswordInVisible, imgPasswordVisible, downArrow } from "./assets";
import { apiCall, callApi, getHeaders, isAuthorisedUser, isIssuer, isLoggedIn } from "../../../components/src/common";
import React from "react";


export const configJSON = require("./config");

export interface IDealsListingObject {
  is_arranger_present: any;
  project_name: string;
  groupchat_dialogid_beforeeoi: any;
  attributes: any;
  id: number;
  deal_debt_arrangers_id: number;
  debt_arranger_companies_id: string;
  issuer_email?: any;
  issuer_company_pan?: any;
  debt_seeker_company_name: string;
  company_background: string;
  review_status: string;
  deal_status: string;
  proposed_instruments?: {
    instrument: string;
    yield: string;
    proposed_total_tenor: string;
    sector: string;
    expected_returns_gross: string;
    total_deal_size: string;
    minimum_investment: string;
    repayment_frequency: string;
    repayment_maturity_date: string;
    rating_details: null | string;
  };
  progress_bar?:{
    deal_investment_done:string;
    total_deal_size:string;
    deal_investment_percentage:string;
  };
  submitted_by: string;
  time_stamp: string;
  is_view_details: boolean;
  upload_logo_image?: {
    id: number;
    url: string;
  };
  upload_video?: any;
  company_presentation_file?: any;
}

export interface IOngoingDealHistoryResponse {
  instrument_type: string;
  maturity_date: string;
  project_name: string;
  seeker_name: string;
  data: {
    id: string;
    type: string;
    attributes: {
      amount: null | string;
      date: string;
      deal_name: null | string;
      deal_status: null | string;
      debt_deal_id: number;
      debt_seeker_id: null | string;
      debt_seeker_name: null | string;
      event_type_with_name: string;
      event_type: string;
      instrument_type_name: string;
      maturity_date: string;
      project_details_id: number;
      project_name: string;
      remark: null | string;
      yield: null | string;
    }
  }[];
}

export interface IDealsFilterOptions {
  name: string;
  value: {
    id: string;
    title: string;
  }[]
}

export interface Props {
  navigation: any;
  id: string;

  history: any;
  match: any;
  location: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  isButtonEnable: boolean;
  allApproveData: any[];
  allPendingData: any[];
  onGoingDealActiveButton: boolean;
  transactionHistoryActiveButton: boolean;
  innerWidth: any;
  isLoading: boolean;
  dealsList: null | IDealsListingObject[];
  isKycDone: boolean;
  showDealHistoryDetailForm: boolean;
  dealHostoryPopUp: boolean;
  dealsHistoty: any[];
  dealHistoryData: null | IOngoingDealHistoryResponse;
  isTransactionHistoryDealHistory: boolean;
  selectedOnGoingDealsFilters: any[];
  selectedDealHistoryFilters: string[];
  onGoingDealHistoryId: number;
  dealDashboardFilterOptions: IDealsFilterOptions[];
  seekerList: string[];
  isIssuer: boolean;
  selectedSeekerFilter: string;
  anchorEl: any;
  open:boolean;
  groupchatDialogId:any;
  dealName:any
}

interface SS {
  id: any;


}

export default class DealDashboardController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDealsForDashboardApiCallId: string = "";
  getAllApprovePendingDetailAPICallId = "";
  authToken: string = "";
  getOngoingDealsHistoryApiCallId: string = "";
  getFilterOptionsListApiCallId: string = "";
  getSeekerNamesForOptionsApiCallId: string ="";
  getArrangerDealsListApiCallId: string = "";
  dealHistoryFilterOptions = [
    "EOI"
    , "Deal Listed"
    , "Deal Acceptance"
    , "Update EOI"
    , "Offer Rejected"
    , "Offer Withdrawn"
    , "Offer Date"
    , "Amend Offer"
    , "Deal Withdrawn"
  ]
  notificationsToChatApiCallId:string=''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),

    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      isButtonEnable: true,
      allApproveData: [],
      allPendingData: [],
      onGoingDealActiveButton: true,
      transactionHistoryActiveButton: false,
      innerWidth: "",
      isLoading: true,
      dealsList: null,
      isKycDone: true,
      showDealHistoryDetailForm: false,
      dealHostoryPopUp: false,
      dealsHistoty: [],
      dealHistoryData: null,
      isTransactionHistoryDealHistory: true,
      selectedOnGoingDealsFilters: [],
      selectedDealHistoryFilters: [],
      onGoingDealHistoryId: 0,
      dealDashboardFilterOptions: [],
      seekerList: [],
      isIssuer: false,
      selectedSeekerFilter: '',
      anchorEl: null,
      open:false,
      groupchatDialogId:'',
      dealName:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



  }
  async componentDidMount() {
    super.componentDidMount();

    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      const isUserIssuer = isIssuer();
      this.setState({
        isIssuer: isUserIssuer
      });
      this.getSeekerNamesForOptions();
      if(this.props.location.search === "?transactionHistory"){
        if (isUserIssuer) {
          this.getDealsForDashboard("transactions")
        } else {
          this.getArrangerDealsListing("transactions");
        }  
        this.setState({
          onGoingDealActiveButton: false,
          transactionHistoryActiveButton: true
        });
        this.props.history.replace("/ContentManagement/DealsDashboard")
      } else{
        if(!isUserIssuer){
          this.getArrangerDealsListing();
        } else {
          this.getDealsForDashboard();
          this.getFilterOptions();
        }
        
      }

      
      
    }
    window.addEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };
  /* istanbul ignore next */
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const isAuthorized = isAuthorisedUser(responseJson);
      if (!isAuthorized) {
        this.props.history.push("/EmailAccountLoginBlock");
        return;
      }
      
      /* istanbul ignore next */
      if (this.getDealsForDashboardApiCallId === apiRequestCallId) {
        if (responseJson.error) {
          const msg198: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg198.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg198);
        } else if(responseJson.errors){
          let res12 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0012: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0012.addData(getName(MessageEnum.CustomAlertBodyData), res12);
          this.send(msg0012);
          this.setState({
            dealsList: [],
            isKycDone: responseJson.iskyc
          })
        }
        else if (responseJson && responseJson.data?.length > 0) {
          this.setState({
            dealsList: responseJson.data.map((deal: any) => deal.attributes),
            isKycDone: responseJson.meta.iskyc
          })
        }
          /* istanbul ignore next */
        else if (responseJson  && responseJson.meta) {
          const msg199: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg199.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg199);
          this.setState({
            dealsList: [],
            isKycDone: responseJson.meta.iskyc
          })
        } 
      } else if (this.getOngoingDealsHistoryApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if (responseJson.error) {
          const msg200: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg200.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg200);
        }else if(responseJson.errors){
          let res14 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0014: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0014.addData(getName(MessageEnum.CustomAlertBodyData), res14);
          this.send(msg0014);
        } else if(responseJson.message){
          let res13 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0013: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0013.addData(getName(MessageEnum.CustomAlertBodyData), res13);
          this.send(msg0013);
          this.setState({
            dealHistoryData: null
          })
        } else if (responseJson.data) {
          if (isIssuer() && !this.state.isTransactionHistoryDealHistory) {
            this.setState({
              dealHistoryData: responseJson,
              dealHostoryPopUp: true
            })
          } else {
            this.setState({
              dealHistoryData: responseJson,
              showDealHistoryDetailForm: true
            })
          }
        }
      } else if (this.getFilterOptionsListApiCallId === apiRequestCallId) {
        if (responseJson.error) {
          const msg201: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg201.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg201);
        } else if (responseJson.data) {
          this.setState({
            dealDashboardFilterOptions: responseJson.data
          })
        }
      } else if(this.getSeekerNamesForOptionsApiCallId === apiRequestCallId){
        if(responseJson?.error){
          const msg202: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg202.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg202);
        } else if(responseJson?.data){
          this.setState({
            seekerList: responseJson?.data[0]?.seeker_list
          })
        }
      } else if (this.getArrangerDealsListApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data && responseJson.data?.length > 0) {
          this.setState({
            dealsList: responseJson.data.map((deal: any) => deal.attributes),
            isKycDone: responseJson.meta.iskyc
          })
        }
        else if(responseJson && responseJson.data && responseJson.data.length === 0 && responseJson.meta){
          const msg2033: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg2033.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg2033);
          this.setState({
            dealsList: [],
            isKycDone: responseJson.meta.iskyc
          })
        } 
        else if(responseJson?.error){
          const msg203: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg203.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg203);
          this.setState({
            dealsList: []
          })
        }
      }
      this.setState({
        isLoading: false
      });

    }


  }

  getDealsForDashboard(section: string = "ongoing_deals", requestBody?: any) {
    this.setState({
      isLoading: true
    })
    const header = {
      "token": this.authToken
    };

    const formData = new FormData();
    if (requestBody) {
      Object.keys(requestBody).forEach((key) => {
        let formKey = "";
        switch (key) {
          case "Last added Yield":
            formKey = "yield";
            break;
          case "Tenor":
            formKey = "proposed_total_tenor";
            break;
          case "Type of Instrument":
            formKey = "proposed_type_of_instrument_id";
            break;
          case "Sector":
            formKey = "proposed_sector_id";
            break;
          case "Credit Rating":
            formKey = "rating_details_rating_id";
            break;
        }
        formData.append(String(formKey), String(requestBody[key]));
      })
    }

    const getDeals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDealsForDashboardApiCallId = getDeals.messageId;

    getDeals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDealsForDashboardApiEndpoint + `?section=${section}`
    );

    getDeals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getDeals.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );

    getDeals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getDealsForDashboardApiMethod
    );

    runEngine.sendMessage(getDeals.id, getDeals);
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  takeAction = (dealId: number,isTransaction?:boolean) => {
    this.props.history.push(`/ContentManagement/TakeAction/${dealId}${isTransaction ? "?transactionHistory" : ""}`)
  }

  handlePendingKycClick = () => {
    this.props.history.push("/CfEkycVerification3");
  }

  setDealHistoryPopUp = (dealId: number, isTransactionHistory: boolean = false) => {
    this.setState({
      isTransactionHistoryDealHistory: isTransactionHistory
    })
    this.getOnGoingDealHistoryData(dealId);
  }

  closeDealHistoryPopup = () => {
    this.setState({
      dealHostoryPopUp: false
    })
  }

  getOnGoingDealHistoryData = (dealId: number) => {
    this.setState({
      isLoading: true,
      onGoingDealHistoryId: dealId
    })
    const header = getHeaders({ token: this.authToken });

    const getOngoingDealHistory = apiCall({
      url: configJSON.getOngoingDealsHistoryApiEndpoint + `${dealId}?filter_by=${this.state.selectedDealHistoryFilters}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getOngoingDealsHistoryApiMethod
    });

    this.getOngoingDealsHistoryApiCallId = getOngoingDealHistory.messageId;
    runEngine.sendMessage(getOngoingDealHistory.id, getOngoingDealHistory);
  }

  handleDealHistoryFilterClick = (e: any) => {
    const value = e
    let newValue = [...this.state.selectedDealHistoryFilters]
    if(this.state.selectedDealHistoryFilters.includes(value)){
      newValue = newValue.filter((option)=> option !== value );
    } else {
      newValue.push(value)
    }
    this.setState({
      selectedDealHistoryFilters: newValue
    }, () => {
      this.getOnGoingDealHistoryData(this.state.onGoingDealHistoryId);
    })

  }

  getFilterOptions = () => {
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const filterOptions = apiCall({
      url: configJSON.getOnGoingDealsFilterOptionsApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getOnGoingDealsFilterOptionsApiMethod
    });

    this.getFilterOptionsListApiCallId = filterOptions.messageId;
    runEngine.sendMessage(filterOptions.id, filterOptions);
  }

  handleDealDashboardFilterClick = (e: any) => {
    const value = e;
    let newValue = [...this.state.selectedOnGoingDealsFilters]
    if(this.state.selectedOnGoingDealsFilters.includes(value)){
      newValue = newValue.filter((option)=> option !== value );
    } else {
      newValue.push(value)
    }
    const converted = newValue.reduce((obj: any, option: string) => {
      const [group, value] = option.split(",");

      return {
        ...obj,
        [group]: value
      }
    }, {});
    const updatedDropdownValue = [];

    for (const key in converted) {
      updatedDropdownValue.push(`${key},${converted[key]}`)
    }
    
    this.setState({
      selectedOnGoingDealsFilters: updatedDropdownValue,
    }, () => {
      this.getDealsForDashboard(this.state.onGoingDealActiveButton ? "ongoing_deals" : "transactions", converted);
    })
  }

  makeDealsFilterDataOptions = () => {
    return this.state.dealDashboardFilterOptions.map((group, index)=>(
      <Accordion  style={{ margin: "0" }} >
        <AccordionSummary
          expandIcon={<img src={downArrow} width="13px" alt="" />}
          aria-controls="panel1a-content"
          id="panel1a-header"
          key={`filterGroup${index}`}
        >
            <Typography style={{ fontSize: "18px" }} className="BlueText">
                {group?.name}
            </Typography>
        </AccordionSummary>
        {
          group.value.map((option,optionIndex)=>(
            <AccordionDetails key={`filteroption${optionIndex}`}>
                <Typography
                   className={`accordian_value${this.state.selectedOnGoingDealsFilters.includes(`${group.name},${option.id}`) ? '_active' : ''}`}
                    onClick={() =>
                      this.handleDealDashboardFilterClick(`${group.name},${option.id}`)
                     }
                >
                  {option?.title}
                </Typography>
            </AccordionDetails>
          ))
        }                                                            
      </Accordion>                          
    ))
  }

  getSeekerNamesForOptions = () =>{
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getSeekers = apiCall({
      url: configJSON.getSeekerNamesForOptionsApiEndpoint ,
      header,
      httpBody: {},
      httpMethod: configJSON.getSeekerNamesForOptionsApiMethod
    });

    this.getSeekerNamesForOptionsApiCallId = getSeekers.messageId;
    runEngine.sendMessage(getSeekers.id, getSeekers);
  }

  getArrangerDealsListing = (section: string = "ongoing_deals") =>{
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getArrangerDeals = apiCall({
      url: configJSON.getArrangerDealsListApiEndpoint + `?seeker_name=${this.state.selectedSeekerFilter}&section=${section}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getArrangerDealsListApiMethod
    });

    this.getArrangerDealsListApiCallId = getArrangerDeals.messageId;
    runEngine.sendMessage(getArrangerDeals.id, getArrangerDeals);
  }

  handleSeekerNameFilterChange = (value:any) =>{
    const newValue = value === this.state.selectedSeekerFilter ? '' : value
    this.setState({
      selectedSeekerFilter: newValue
    },()=>{
      this.getArrangerDealsListing(this.state.transactionHistoryActiveButton ? "transactions" : "ongoing_deals");
    })
  }
  openFilterHandler = (event: any) => {
    this.setState({ anchorEl: event?.currentTarget });
  };

  closeFilterHandler = () => {
    this.setState({ anchorEl: null });
  };

  handleOnGoing = () => {
    this.setState({
      transactionHistoryActiveButton: false,
      onGoingDealActiveButton: true,
      selectedSeekerFilter: '',
      selectedOnGoingDealsFilters: []
    }, () => {
      if (this.state.isIssuer) {
        this.getDealsForDashboard();
      } else {
        this.getArrangerDealsListing();
      }
    });
  };
  handleTransactionHistory = () => {
    this.setState({
      onGoingDealActiveButton: false,
      transactionHistoryActiveButton: true,
      selectedSeekerFilter: '',
      selectedOnGoingDealsFilters: []
    }, () => {
      if (this.state.isIssuer) {
        this.getDealsForDashboard("transactions")
      } else {
        this.getArrangerDealsListing("transactions");
      }

    });
  };

  handleAddNewDealClick = () => {
    /* istanbul ignore next */
    if (!this.state.isKycDone) {
      const msg204: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg204.addData(getName(MessageEnum.CustomAlertBodyData), 'Please complete your KYC to proceed further');
      this.send(msg204);
      return;
    }
    localStorage.removeItem("deal_id");
    localStorage.removeItem("proposedInstrumentDetails_id");
    localStorage.removeItem("security_id");
    localStorage.removeItem("projectDetails_id");
    localStorage.removeItem("fin_summary_id");
    localStorage.removeItem("debtProfile_id");
    localStorage.removeItem("supportive_id");
    localStorage.removeItem("manage_deal_edit")
    window.location = '/Catalogue/CompanyOverview' as any
  }
  openChatBox= (dialogId:any,dealId:any,dealName:string)=>{
    
    if(dialogId){
      this.sendNotificationsForChat(dealId)
      this.setState({open:true,groupchatDialogId:dialogId,dealName:dealName})
      
    }
    
  }

  handleChatBox = ()=>{
    this.setState({open:!this.state.open})
  }

  sendNotificationsForChat = (offerId:any)=>{
    const data={method:'GET',payload:'',
    endPoint:configJSON.initiateChatApiEndpoint +`?deal_id=${offerId}`}
    this.notificationsToChatApiCallId= callApi(data)
   
    
}

  // Customizable Area End
}
