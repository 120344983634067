import React from "react";

import {
    Box,
    Input,
    Button,
    InputLabel,
    Typography,
    InputAdornment,
    IconButton,
    // Customizable Area Start
    Grid,
    TextField,
    Link,
    Dialog,
    // Customizable Area End
} from "@material-ui/core";
import { Logo, verified_tick_image } from "./assets";

// Customizable Area Start
// import './emailAccountLogin.css';
import ChangePasswordController, {
    Props,
} from "./ChangePasswordController";

// Customizable Area End

export default class ChangePassword extends ChangePasswordController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start
    typeOfLabel = (event: React.ChangeEvent<{}>, index: number) => {
        // this.setState({
        //   tabIndexValue: index,
        // });
    };
    // Customizable Area End

    render() {
        return (
            // Customizable Area Start
            <Grid container className="grid_change_password">
                <Grid item lg={6} md={6} sm={6} className="background_change_pass">
                    <img src={Logo} alt="logo" className="background_logo" />
                </Grid>
                <Grid item lg={6} md={6} sm={6} className="inner_grid_chane_password2">
                    <Typography className="change_passowrd_heading">
                        Change Password
                    </Typography>
                    <Typography className="change_password_subheader" >
                        Your new password must be different from the recent password
                    </Typography>
                    <Grid item lg={12}>
                        <InputLabel
                            htmlFor="component-simple"
                            required
                            id="inputLabel"
                        >
                            Old password
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            required
                            fullWidth
                            type="password"
                            name="old_password"
                            className="change_password_textInput"
                            placeholder="**********"
                            value={this.state.passwordDetails.old_password}
                            onChange={this.handleChangePasswordDetails}
                        />
                    </Grid>
                    <Grid item lg={12}>
                        <InputLabel
                            htmlFor="component-simple"
                            required
                            id="inputLabel"
                        >
                            Create new password
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            type="password"
                            size="small"
                            name="new_password"
                            value={this.state.passwordDetails.new_password}
                            onChange={this.handleChangePasswordDetails}
                            fullWidth
                            placeholder="**********"
                            className="change_password_textInput"
                        />
                        {
                            this.state.isErrorPassword.new_password &&
                            <>
                                <Typography className="change_password_input_error">
                                    Please enter a password between 8 to 20 charecters.
                                </Typography>
                                <Typography style={{color:'grey',marginLeft:'1%'}}>
                                    *User can enter 8 characters,1 uppercase letter, 1
                                    number, 1 special characters combinations of them in
                                    this field.
                                </Typography>
                            </>
                        }
                    </Grid>
                    <Grid item lg={12}>
                        <InputLabel
                            htmlFor="component-simple"
                            required
                            id="inputLabel"
                        >
                            Confirm new password
                        </InputLabel>
                        <TextField
                            variant="outlined"
                            type="password"
                            size="small"
                            required
                            name="confirm_new_password"
                            value={this.state.passwordDetails.confirm_new_password}
                            onChange={this.handleChangePasswordDetails}
                            fullWidth
                            placeholder="**********"
                            className="change_password_textInput"
                        />
                        {
                            this.state.isErrorPassword.confirm_new_password && this.state.passwordDetails.confirm_new_password === this.state.passwordDetails.new_password ?
                                <>
                                    <Typography className="change_password_input_error">
                                      Please enter a password between 8 to 20 charecters.
                                    </Typography>
                                    <Typography style={{color:'grey',marginLeft:'1%'}}>
                                        *User can enter 8 characters,1 uppercase letter, 1
                                        number, 1 special characters combinations of them in
                                        this field.
                                    </Typography>

                                </>
                                : this.state.passwordDetails.new_password !== '' && this.state.passwordDetails.confirm_new_password !== this.state.passwordDetails.new_password &&  this.state.passwordDetails.confirm_new_password.length!==0 ?
                                    <Typography className="change_password_input_error">
                                         Password and Confirm Password does not match.
                                    </Typography>
                                    :
                                    ''
                        }
                    </Grid>
                    <Grid item lg={12}>
                        <Button
                            variant="contained"
                            className="change_pass_submit_button"
                            onClick={this.handleChangePassword}
                        >
                            Submit
                        </Button>
                    </Grid>
                    <Grid container alignItems="center" justifyContent="center">
                        <InputLabel className="back_to_label">
                            Back to
                            <Link
                                href="EmailAccountLoginBlock"
                                className="login_link_pass"
                            >
                                Login
                            </Link>
                        </InputLabel>
                    </Grid>
                </Grid>
                <Grid>
                    <Dialog
                        onClose={this.handleClose}
                        open={this.state.success_dialog_box}
                        className="success_pop_up"
                        disableBackdropClick={true}
                    >
                        <img
                            src={verified_tick_image}
                            height={35}
                            width={35}
                        />
                        <Typography
                            className="succes_password_change_text"
                            align="center"
                        >
                            Your password has been changed successfully
                        </Typography>
                        <Grid container alignItems="center" justifyContent="center">
                            <InputLabel className="back_to_label">
                                Back to
                                <Link
                                    href="EmailAccountLoginBlock"
                                    className="login_link_pass2"
                                >
                                    Login
                                </Link>
                            </InputLabel>
                        </Grid>
                    </Dialog>
                </Grid>
            </Grid>
            // Customizable Area End
        );
    }
}

// Customizable Area Start
// Customizable Area End
