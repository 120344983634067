import React from "react";
// Customizable Area Start
import {
    Box,
    Toolbar,
    Button,
    Container,
    Typography,
    Grid,
    ListItem,
    ListItemIcon
} from "@material-ui/core";
import {
    createTheme,
    ThemeProvider,
    makeStyles,
} from "@material-ui/core/styles";
import {
    finLogo,
    linkedIn,
    twitter,
    facebook,
    instagram,
    finmart_logo
} from "./assets";
import CustomCard from "../../../components/src/CustomCard";
import "./styles.css";
import Header from "../../../components/src/Header.web";
import { Link } from "react-router-dom";

import LandingPageController, { Props } from "./LandingPageController";
import ProductCard from "../../../components/src/ProductCard";
import Footer from "./Footer.web"
import { withRouter } from "react-router";
import { Role_name } from "../../../components/src/common";

 export class LandingPage2 extends LandingPageController {
    render() {
        const { navigation } = this.props;
        return (
            // <Container disableGutters maxWidth="xl">
            <Box style={{ display: "flex",flexDirection: "column"}} >
                <Header match='' location="" history="" id="" navigation={navigation} />
                <Grid container justifyContent="center" spacing={0} >
                    <Toolbar />
                    <Grid
                        item
                        xs={12}
                    >
                        <div className="home_main_container">
                            <Box className="homepage_container">
                                <Box>
                                    <p className="landingpage_header">
                                        Simplifying Alternative Investments for Everyone
                                    </p>
                                    <p className="landingpage_subheader">
                                        Go-to place for Deal Discovery, Funding, Down-Selling and
                                        In-Depth monitoring of debt instruments.
                                    </p>
                                    <Link to={(this.state.current_role ===  Role_name.Seeker || this.state.current_role === Role_name.Arranger) ? '/ContentManagement/DealsDashboard' : '/DealDiscovery'} className="Join_the_Debt_Revolution_link">
                                        <button className="Join_the_Debt_Revolution" >
                                            Join the Debt Revolution
                                        </button>
                                    </Link>
                                </Box>
                            </Box>
                        </div>
                    </Grid>
                    <Grid
                        item xs={12}
                    >
                        <Grid
                            // container
                            direction="row"
                            alignItems="center"
                            className="debt_card_container"
                        >
                            <Box className="left_box_shadow" />

                            {console.log("this.state.debtTypeList===>", this.state.debtTypeList)}
                            {
                                this.state.debtTypeList.length ? this.state.debtTypeList.map((debtType) => {
                                    return (
                                        <Grid className="customCard_container" item xs={12} sm={12} md={4} lg={4} key={debtType.id}>
                                            <CustomCard
                                                title={debtType.attributes.name}
                                                text={debtType.attributes.description}
                                                img={debtType.attributes.image}
                                            />
                                        </Grid>
                                    )
                                })
                                    : (<Box textAlign="center" style={{ width: "100%" }}  > <Typography variant="h6" >Loading...</Typography></Box>)
                            }
                            <Box className="box_shadow_right" />

                        </Grid>
                    </Grid>
                    <Grid
                        item
                        lg={12}
                        container
                        spacing={3}
                        className="alternative_investment_container"
                    >
                        <Grid item xs={12} lg={5} className="alternative_investement_left_container" >
                            <Box>
                                <p className="alternative_investement alt_invest" >Alternative Investments</p>
                                <Typography className="alternative_investement_are">are</Typography>
                                <p className="alternative_investement" >Future of Finance</p>
                            </Box>
                        </Grid>
                        <Grid item xs={12} lg={3} style={{ marginRight: 20 }} >

                            <p className="alternative_title" >Secured Assets</p>
                            <Box mt={2} />
                            <p className="alternative_content">
                                Go-to place for Deal Discovery, Funding, Down-Selling and In-Depth monitoring of debt instruments.
                            </p>
                            <Box mt={3} />
                            <p className="alternative_title">High Yield</p>
                            <Box mt={2} />
                            <p className="alternative_content">
                                Diversify your portfolio from traditional investments and acheive greater returns
                            </p>
                        </Grid>
                        <Grid item xs={12} lg={3}>
                            <p className="alternative_title"  >The Compounding Factor</p>
                            <Box mt={2} />
                            <p className="alternative_content" >
                                Let's use money to make more money wiht the magic of compounding
                            </p>
                            <Box mt={3} />
                            <p className="alternative_title">Complete Transparency</p>
                            <Box mt={2} />
                            <p className="alternative_content">
                                Full Transparency from start to finish. You will get all the information about the product you are choosing
                            </p>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        xs={12}
                        id="products"
                    >
                        <Grid
                            direction="row"
                            justifyContent="center"
                            alignItems="flex-start"
                            className="product_container"
                            container >
                            <Grid item xs={12}>
                                <Toolbar />
                                <Box
                                    textAlign="center"
                                    className="product_title_container"
                                >
                                    <p className="product_heading" >
                                        Products
                                    </p>
                                </Box>
                            </Grid>
                            {console.log("this.state.productsList2===>", this.state.productsList2)}
                            {
                                this.state.productsList2.length ? this.state.productsList2.map((product) => {
                                    return (
                                        <Grid className="productCard_container" item lg={4} key={product.id} >
                                            <ProductCard
                                                title={product.attributes.name}
                                                description={product.attributes.description && product.attributes.description.replace(/(<([^>]+)>)/ig, '')}
                                            />
                                        </Grid>
                                    )
                                })
                                    : (<Box textAlign="center" > <Typography variant="h6" >Loading...</Typography></Box>)}
                        </Grid>
                    </Grid>
                    <Grid item
                        id="howItWork"
                        xs={12}
                    >
                        <Toolbar style={{ background: "#F4F3EF" }} />
                        <Grid
                            item
                            container
                            className="howItWork_container"
                        >
                            <Grid item xs={12} lg={12} >
                                <Box textAlign="center">
                                    <Box mb={8} mt={6}>
                                        <Typography className="howItWork_heading" >
                                            How it Works
                                        </Typography>
                                    </Box>
                                </Box>
                            </Grid>

                            <Grid item lg={12} className="howIt_work_label_container">
                                <Box className="bullet_content_container" >
                                    <Box className="content_container" >
                                        <Box className="line_with_bullet" >

                                        </Box>
                                        <p className="howIt_work_subheader" >SetUp</p>
                                        <p className="how_it_work_attribute">
                                            Online and seamless KYC Process
                                        </p>
                                    </Box>
                                    <Box className="content_container"  >
                                        <Box className="line_with_bullet" >
                                        </Box>
                                        <p className="howIt_work_subheader">Explore</p>
                                        <p className="how_it_work_attribute" >
                                            Sector agnotic high yield Investments opportunities
                                        </p>
                                    </Box>
                                    <Box className="content_container">
                                        <Box className="line_with_bullet" >
                                        </Box>
                                        <p className="howIt_work_subheader">Invest/Borrow</p>
                                        <p className="how_it_work_attribute" >
                                            Secure Investment process
                                        </p>
                                    </Box>
                                    <Box className="content_container" >
                                        <Box className="line_with_bullet" >
                                        </Box>
                                        <p className="howIt_work_subheader">Monitor</p>
                                        <p className="how_it_work_attribute" style={{ width: '200px' }} >
                                            In-dept financial and project monitoring with periodic
                                            reports
                                        </p>
                                    </Box>
                                    <Box
                                        className="content_container" >
                                        <Box className="line_with_bullet"  >
                                        </Box>
                                        <Box className="remove_extra_line" >
                                        </Box>
                                        <p className="howIt_work_subheader">Trade</p>
                                        <p className="how_it_work_attribute">
                                            Trade in secondary market and access liquidity at your
                                            command
                                        </p>
                                    </Box>

                                </Box>


                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        lg={12}
                        spacing={3}
                        className="feel_free_to_connect_container"
                    >
                        <Grid item lg={6} xs={12} sm={6} md={6} >
                            <Box className="feel_free_to_connect_box">
                                <Box  >
                                    <Typography className="feel_free_to_connect1">
                                        Want to know more?
                                    </Typography>
                                    <Typography className="feel_free_to_connect">
                                        Feel free to chat
                                    </Typography>
                                    <Typography className="connect_timings">
                                        We are available Mon-Sat from 10AM to 7PM
                                    </Typography>
                                </Box>


                            </Box>
                        </Grid>
                        <Grid item lg={6} xs={12} sm={6} md={6} className="emailUs_container" alignItems="center" alignContent="center" >
                            <a href="" className="emailUs_link" >
                                <Button
                                    className="email_us_button"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                >
                                    <a href="mailto:someone@example.com" style={{textDecoration:"none",color: "white"}}>hello@mkp.com</a>
                                    
                                </Button>
                            </a>

                        </Grid>
                    </Grid>

                </Grid>
                <Footer navigation={this.props.navigation} id={""} match={undefined} location={undefined} history={undefined}/>       


                {/* </Container > */}
            </Box >
        );
    }
}
export default withRouter(LandingPage2) as any;
// Customizable Area End
