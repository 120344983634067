// Customizable Area Start
import React from 'react';
import { Select, MenuItem, Checkbox, ListItemText, Typography } from '@material-ui/core';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import { isArrayEmpty } from './common';

interface Props {
    value: any;
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    className: string;
    renderValue: (selected: any) => React.ReactNode;
    providerList: { id: number; full_name: string }[];
}

export const CustomableSelect: React.FC<Props> = ({
    value,
    onChange,
    className,
    renderValue,
    providerList,
}) => {
    return (
        <Select
            value={value}
            className={className}
            renderValue={renderValue}
            onChange={onChange}
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            displayEmpty={true}
            IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
            disableUnderline={true}
            inputProps={{ 'aria-label': 'Without label' }}
            multiple
        >
            <MenuItem style={{ color: '#323232', fontWeight: 400, fontSize: 16 }} disabled value={[]}>
                Please Select Provider
            </MenuItem>
            {isArrayEmpty(providerList) ? (
                <p>No Provider Available</p>
            ) : (
                providerList.map((provider, i: number) => (
                    <MenuItem key={`menu${i + 1}`} value={provider.id}>
                        <Checkbox style={{ color: '#FB7D29' }} checked={value.indexOf(provider.id) > -1} />
                        <ListItemText
                            style={{ fontSize: 16, color: '#323232', fontWeight: 400, marginBottom: 5, marginLeft: 5 }}
                            primary={`${provider.full_name}`}
                        />
                    </MenuItem>
                ))
            )}
        </Select>
    );
};

// Customizable Area End
