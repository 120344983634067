import React from "react";
// Customizable Area Start
import {
    Box,
    Container,
    Grid,
    makeStyles,
    Typography,
    Toolbar,
} from "@material-ui/core";
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";
import "./styles.css"
// Customizable Area End


export default class TermAndCondition extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }
    // Customizable Area Start
    handleProductsOpen = (e: any) => {
        this.setState({ openProducts: e.currentTarget });
    };

    handleAboutUsOpen = (e: any) => {
        this.setState({ openAboutUs: e.currentTarget });
    };

    handleProductsClose = () => {
        this.setState({ openProducts: null });
    };

    componentDidMount(): any {
        this.getTermsAndCondition();
    }
    // Customizable Area End
    render() {
        const { navigation } = this.props;
        return (
            // Customizable Area Start
            <>
                <Container maxWidth="lg" disableGutters >
                    <Header match='' location="" history="" id="" navigation={navigation} />
                    <Toolbar />
                    <Toolbar />
                    <Grid container>
                        <Grid item xs={12}>
                            <Box
                                className="termAndCondition_paper"
                            >
                                <Typography className="termAndCondition_heading">Terms & Conditions</Typography>
                                <Box className="termAndCondition_content" dangerouslySetInnerHTML={{ __html: `${this.state.termAndConditions}` }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>
            </>

            // Customizable Area End
        );
    }
}

// Customizable Area Start

// Customizable Area End
