import React from "react";
// Customizable Area Start

import {
  Box,
  InputLabel,
  Container,
  CircularProgress,
  TextareaAutosize,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import CatalogueController, { Props } from "./FaqListController";
import AddBoxIcon from "@material-ui/icons/AddBox";

import { withRouter } from "react-router-dom";
import { handleReviewSubmitClick, Role_name } from "../../../components/src/common";

class FAQList extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // this.state = { values: [] };
  }

  render() {
    return (
      <>
        <Container maxWidth="xl" >
          <Grid container alignItems="center">
            <Typography
              variant="h5"
              color="textPrimary"
              style={webStyles.heading}
            >
              Frequently Asked Questions (Optional)
            </Typography>
            <Grid xl={10} lg={10} sm={12} xs={12} item={true} style={webStyles.listBox}>
              {
                this.state.Faqs.map((data, index) => {
                  return (
                    <div key={index} style={{display:'flex',gap:'15px'}}>
                    <Grid style={webStyles.gridStyle2}>
                      <Box className="textInput_container">
                        <InputLabel className="inputLabel" style={webStyles.inputLabel}>
                          {index + 1}.
                        </InputLabel>
                        <input
                          className="catalogue_textInput"
                          type="text"
                          name="debtSeeker"
                            disabled={this.state.isReadMode}
                          placeholder="Type question"
                          value={data.attributes.question}
                          onChange={(e) => this.changeFAQData(e, index)}
                          style={webStyles.fullWidthInput}
                          data-test-id = {`input${index+1}`}
                          
                        />
                      </Box>
                      <Box className="textInput_container">
                        <InputLabel className="inputLabel" style={webStyles.inputLabel}>
                          Answer
                        </InputLabel>
                        <TextareaAutosize
                          aria-label="empty textarea"
                          placeholder="Type answer"
                          minRows={2}
                          value={data.attributes.answer}
                          className={index==1 ? "faq_textarea_box2" :"faq_textarea_box"}
                            disabled={this.state.isReadMode}
                          onChange={(e) => this.changeFAQAnswer(e, index)}
                          data-test-id = {`answer${index+1}`}
                        />
                      </Box>
                    </Grid>
                      {(index > 3 && !this.state.isReadMode) && <div style={{cursor:'pointer'}} onClick={()=>this.removeInputField(index,data?.id)} data-test-id='icon'>X</div>}
                    </div>
                  )
                })
              }
            </Grid>
            {!this.state.isReadMode && <Grid container>
              <button className="addRow_button" onClick={() => this.handleAddInputField()} data-test-id ='addMore' >
                Add more <AddBoxIcon style={{ marginLeft: "8px" }} />
              </button>
            </Grid>}
            {!this.state.isReadMode && <Grid className="supprotive_button">
              <Button
                variant="contained"
                color="primary"
                onClick={() => this.createFaqs()}
                className="faq_next_button"
                fullWidth
                data-test-id="goLive"
              >
                {this.state.showLoading?<CircularProgress></CircularProgress>:this.state.role_name === Role_name.Seeker ? `Go Live` : `Submit for Debt Seeker's Approval`}
              </Button>
            </Grid>}
            {this.state.isReadMode ? <Box
                className="submit_button_wrapper"
              ><button data-test-id="submit" onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button">Submit</button></Box>:null}
          </Grid>
        </Container>
      </>
    );
  }
}
export default withRouter(FAQList) as any;

const webStyles = {
  listBox: {
    border: '1px solid #CDCDCD',
    borderRadius: '8px',
    height: '100%',
    width: '70%',
    marginTop: '20px',
    padding: '25px',
    paddingBottom: '0px',
  },
  gridStyle2: {
    marginBottom: '35px',
    width:'100%'
  },
  heading: {
    fontWeight: 700,
    marginBottom: '10px',
    fontFamily: "Lato",
  },
  fullWidthInput: {
    maxWidth: '100%'
  },
  inputLabel: {
    marginBottom: '5px',
    padding: '0 0px 0px 0px',
    width: '110px',
  },
  nextButton: {
    width: `fit-content !important`
  }
};

// Customizable Area End
