import React from "react";
import {
    Paper,
    Box,
    Container,
    Toolbar,
    Grid,
    Typography,
} from "@material-ui/core";
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";



export default class PrivacyPolicy extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getPrivacyPolicy();
    }

    render() {
        return (
            <>
                <Header match='' location="" history="" id="" navigation={this.props.navigation} />
                <Toolbar />
                <Toolbar />
                <Container maxWidth="lg" disableGutters >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box className="privacyPolicy_container" >
                                <Typography className="privacyPolicy_heading">Privacy Policy</Typography>

                                <Box className="termAndCondition_content" dangerouslySetInnerHTML={{ __html: `${this.state.privacyPolicy}` }} />
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </>

        );
    }
}
