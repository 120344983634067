Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.ACCOUNT_TYPE_EMAIL = "EmailAccount";
exports.ACCOUNT_TYPE_SOCIAL = "SocialAccount";
exports.ACCOUNT_TYPE_PHONE = "SmsAccount";

exports.contentTypeApiUpdateUser = "application/json";
exports.apiEndPointUpdateUser = "profile/profile";
exports.apiUpdateUserType = "PUT";

exports.urlGetValidations = "profile/validations";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";

exports.contenttypeApiValidateMobileNo = "application/json";
exports.endPointApiValidateMobileNo = "profile/change_phone_validation";
exports.callTypeApiValidateMobileNo = "POST";

exports.endPointApiGetUserProfile = "profile/profile";
exports.contentTypeApiGetUserProfile = "application/json";
exports.methodTypeApiGetUserProfile = "GET";

// Customizable Area Start
exports.placeHolderEmail = "Email";
exports.labelHeader =
  "This is your profile, Here you can see and update your personal information.";
exports.labelFirstName = "First name";
exports.lastName = "Last name";
exports.labelArea = "Area";
exports.labelMobile = "Mobile";
exports.labelEmail = "Email";
exports.labelCurrentPassword = "Current password";
exports.labelNewPassword = "New Password";
exports.labelRePassword = "Re-Type Password";
exports.btnTextCancelPasswordChange = "Cancel";
exports.btnTextSaveChanges = "Save Changes";
exports.btnTextChangePassword = "Change Password";
exports.errorCountryCodeNotSelected = "Please select country code";
exports.errorMobileNoNotValid = "Phone number is not valid.";
exports.errorTitle = "Error";
exports.errorBothPasswordsNotSame = "Passwords must match.";
exports.errorCurrentNewPasswordMatch = "New password cannot match current password.";
exports.errorCurrentPasswordNotValid = "Current password not valid.";
exports.errorNewPasswordNotValid = "New password not valid.";
exports.errorReTypePasswordNotValid = "Re-type password not valid.";
exports.hintCountryCode = "Select Country";
exports.errorBlankField = "can't be blank";
exports.errorEmailNotValid = "Email not valid.";
exports.changePasswordAPIEndPoint = "bx_block_forgot_password/password/change_password";
exports.getBankListAPIEndPoint = "bx_block_bank_details/banks";
exports.DebtSeekerTypesAPIEndPoint = "bx_block_bank_details/debt_seekers";
exports.DebtProviderTypesAPIEndPoint = "bx_block_bank_details/debt_providers";
exports.DebtArrangerTypesAPIEndPoint = "bx_block_bank_details/debt_arrangers";
exports.getProfileBankDetailsAPIEndPoint = "bx_block_bank_details/account_details/show";
exports.getDematProfileDetailsAPIEndPoint = "/bx_block_bank_details/demat_details/show";
exports.getKYCProfileDetailsAPIEndPoint = "bx_block_bank_details/kyc_details/show";
exports.updateKYCDetailsAPIEndPoint = "bx_block_bank_details/kyc_details/update";
exports.updateDematDetailsAPIEndPoint = "bx_block_bank_details/demat_details/update";
exports.updateBankDetailsAPIEndPoint = "bx_block_bank_details/account_details/update";
exports.getPersonalDetailsAPIEndPoint = "account_block/accounts";
exports.nsdl="nsdl";
exports.cdsl="cdsl";
exports.verifyEmailAPIEndPoint = "account_block/accounts/email_confirmation";
exports.RequestOTPAPIEndPoint = "account/accounts/send_otp";
exports.addDematDetailsAPIEndPoint="bx_block_bank_details/demat_details";
exports.addBankDetailsAPIEndPoint="bx_block_bank_details/account_details";
exports.addKYCDetailsAPIEndPoint = "bx_block_bank_details/kyc_details";
exports.ResendOTPAPIEndPoint = "resend_otp";
exports.VerifyOTPAPIEndPoint = "account/accounts/sms_confirmation";
exports.checkEmailAPIEndPoint = "check_email_verification";
exports.getUserSpecificRoleApiEndpoint = "account_block/account_user_roles/get_profile_user_role";
exports.getUserSpecificRoleApiMethod = "GET";
exports.getRolesListingApiEndpoint = "account_block/account_user_roles/get_roles";
exports.getRolesListingApiMethod = "GET"
// Customizable Area End

