//Customize Area Start
import React from 'react';
import { NavLink, Switch, Route } from "react-router-dom";
import ManageDeal from './ManageDeal.web';
import DealsDashboard from "./DealsDashboard.web";
import ApproveDeal from "./ApproveDeal.web";
import PropTypes from 'prop-types';
import { Box, Grid } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';

import CloseIcon from '@material-ui/icons/Close';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import TakeAction from './TakeAction.web';

const drawerWidth = '16%';
const activeStyle = {
    fontWeight: 700,
    fontSize: "18px",
    color: "#fff"
};

const dashboardLink = {
    display: "block",
    padding: "15px",
    textDecoration: "none",
    fontSize: "18px",
    color: "#bdbdbd"
};
const url = "/ContentManagement";
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        // flex: 1,
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center'
    },
    drawer: {
        backgroundColor: '',
        [theme.breakpoints.up('sm')]: {
            maxWidth: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('md')]: {
            display: 'none',
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
        backgroundColor: '#001d55',
        color: 'white',
        height: '100%'
    },
    mobileDrawerPaper: {
        width: '50%',
        backgroundColor: '#001d55',
        color: 'white',
        height: '100%'
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    closeMenuButton: {
        marginRight: 'auto',
        marginLeft: 0,
        color: 'white'
    },
}));
function ResponsiveDrawer() {
    const classes = useStyles();
    const theme = useTheme();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    function handleDrawerToggle() {
        setMobileOpen(!mobileOpen)
    }
    const drawer = (
        <List>
            <nav>
                <NavLink
                    style={{ ...dashboardLink, }}
                    activeStyle={activeStyle}
                    to={`${url}/DealsDashboard`}
                >
                    Deals Dashboard
                </NavLink>

                <NavLink
                    style={dashboardLink}
                    to={`${url}/ManageDeal`}
                    activeStyle={activeStyle}
                >
                    Manage Deal
                </NavLink>
                {localStorage.getItem("role_id") === String(1) && <NavLink
                    style={dashboardLink}
                    to={`${url}/ApproveDeal`}
                    activeStyle={activeStyle}
                >
                    Approve Deal
                </NavLink>}
            </nav>
        </List>
    );
    return (
        <Grid>
            <div className={classes.root}>
                <CssBaseline />
                <Grid md={2} lg={2} xl={2}
                    style={{ backgroundColor: '#001d55', height: '100%' }}>
                    <nav className={classes.drawer}>
                        <Hidden smUp implementation="css">
                            <Drawer
                                variant="temporary"
                                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                                classes={{
                                    paper: classes.mobileDrawerPaper,
                                }}
                                ModalProps={{
                                    keepMounted: true,
                                }}
                            >
                                <IconButton onClick={handleDrawerToggle} className={classes.closeMenuButton}>
                                    <CloseIcon />
                                </IconButton>
                                {drawer}
                            </Drawer>
                        </Hidden>
                        <Hidden smDown implementation="css">
                            <Drawer
                                className={classes.drawer}
                                variant="permanent"
                                classes={{
                                    paper: classes.drawerPaper,
                                }}
                            >
                                <div className={classes.toolbar} />
                                {drawer}
                            </Drawer>
                        </Hidden>
                    </nav>
                </Grid>
                <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
                    <div className={classes.toolbar} />
                    <Switch>
                        <Route component={DealsDashboard as any} exact path="/ContentManagement/DealsDashboard" />
                        <Route component={ManageDeal} path="/ContentManagement/ManageDeal" />
                        <Route component={ApproveDeal} path="/ContentManagement/ApproveDeal" />
                        <Route component={TakeAction} path="/ContentManagement/TakeAction/:dealId" />
                    </Switch>
                </Grid>
            </div>
        </Grid>
    );
}
ResponsiveDrawer.propTypes = {
    container: PropTypes.object,
};
export default ResponsiveDrawer;
//Customize Area End
