import React from "react";
import {
    Button,
    MenuItem,
    Typography,
    Menu,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import LandingPageController, { Props } from "../../blocks/landingpage/src/LandingPageController";
import "./styles.css";

export default class AboutUsMenu extends LandingPageController {
    constructor(props: Props) {
        super(props);

    }

    handleOpen = (e: any) => {
        this.setState({ openAboutUs: e.currentTarget })
    }

    handleClose = (e: any) => {
        this.setState({ openAboutUs: e.currentTarget })
    }
    render() {
        return (
            <>
                <Button endIcon={<KeyboardArrowDownIcon />} onClick={(e: any) => this.handleOpen(e)}>
                    <Typography className="aboutus_text">About Us</Typography>
                </Button>
                <Menu
                    className="menu"
                    open={Boolean(this.state.openAboutUs)}
                    onClose={(e) => this.setState({ openAboutUs: null })}
                    anchorEl={this.state.openAboutUs}
                    PaperProps={{
                        style: {
                            borderRadius: "16px",
                        }
                    }}
                >
                    {this.state.aboutUsArray.map((item, index) => (


                        <MenuItem key={item.id} className="about_us_menuitem"
                            onClick={() => this.props.navigation.navigate(item.path)}>{item.title} </MenuItem>

                    ))}
                </Menu>
            </>

        )

    }
}


