
import React from "react";
// Customizable Area Start
import { Component } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Drawer,
  Box,
  List,
  CssBaseline
} from "@material-ui/core";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import "./Clipdrawer.web.css";
import ProposedInstrumentDetails from "./ProposedInstrumentDetails.web";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NavLink, Switch, Route, Redirect, RouteProps } from "react-router-dom"
import StructureAndSecurity from "./StructureAndSecurity.web";
import ProjectDetails from "./ProjectDetails.web";
import CompanyOverView from "./CompanyOverView.web";
import ClippedDrawerController, { Props } from "./ClippedDrawerController.web";
import DebtProfile from "./DebtProfile.web";
import ManagementProfile from "./ManagementProfile.web";
import SupportiveDocument from "./SupportiveDocument.web";
import FinancialSumarry from "./FinancialSummary.web";
import FAQList from "./FaqList.web";
import ReviewDeal from "./ReviewDeal.web";
import AppHeader from "./AppHeader.web";
import { isDealIdAvailable } from "../../../components/src/common";
import TakeDecisionWeb from "./TakeDecision.web";

export default class ClippedDrawer extends ClippedDrawerController {
  constructor(props: Props) {
    super(props);
  }

  getSteps = () => {
    return ['Select Deal', 'Review Deal', 'Decision'];
  }

  getStepContent(stepIndex: number) {
    switch (stepIndex) {
      case 0:
        return 'Select Deal';
      case 1:
        return 'Review Deal';
      case 2:
        return 'Decision';
      default:
        return 'Unknown stepIndex';
    }
  }

  render() {
    const steps = this.getSteps();
    const activeStyle = {
      fontWeight: 700,
      fontSize: "18px"
    };

    const dashboardLink = {
      display: "block",
      padding: "15px ",
      textDecoration: "none",
      fontSize: "18px",
      color: isDealIdAvailable() ? "inherit" : "grey"
    };
    const url = "/Catalogue";
    return (
      <div style={webStyle.root}>
        <CssBaseline />
        <Drawer variant="permanent" style={webStyle.drawer} >
        {this.props.user === "Sub_Admin" ? null: <Toolbar />}
          <List disablePadding style={{
            width: 275,
            background: "#001D56",
            color: "#f1f2f4",
            height: '100vh'
          }} >
            <nav>
              <NavLink
                style={{ ...dashboardLink, color: "inherit" }}
                activeStyle={activeStyle}
                to={`${url}/CompanyOverview${this.state.isReadMode ? "?readMode" : this.state.isEdit ? "?edit=true" : ""}`}
                isActive={(match,location)=>checkIsActive(match,location,"CompanyOverview")}
              >
                Company Overview
              </NavLink>

              <NavLink
                style={dashboardLink}
                to={`${url}/ProposedInstrumentDetails${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                isActive={(match,location)=>checkIsActive(match,location,"ProposedInstrumentDetails")}
                onClick={this.validateNavLink}
                exact={false}
              >
                Proposed Instrument Details
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/StructureandSecurity${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"StructureandSecurity")}
              >
                Structure & Security
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/ProjectDetails${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"ProjectDetails")}
              >
                Project Details
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/FinancialSummary${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"FinancialSummary")}
              >
                Financial Summary
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/ManagementProfile${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"ManagementProfile")}
              >
                Management Profile
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/DebtProfile${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"DebtProfile")}
              >
                Debt Profile
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/SupportiveDocuments${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
                isActive={(match,location)=>checkIsActive(match,location,"SupportiveDocuments")}
              >
                Supportive documents
              </NavLink>
              <NavLink
                style={dashboardLink}
                to={`${url}/FAQlist${this.state.isReadMode ? "?readMode" : ""}`}
                activeStyle={activeStyle}
                onClick={this.validateNavLink}
              >
                FAQ List
              </NavLink>
            </nav>
          </List>
        </Drawer>
        {this.props.user === "Sub_Admin" ? null: <AppHeader id="" location="" history="" navigation={this.props.navigation} match="" />}
        <main style={webStyle.content}>
        {this.props.user === "Sub_Admin" ? null: <Toolbar />}
          {
            this.state.isReadMode && <Box style={{ width: "100%", height: "80px", marginBottom: "80px" }} >
              <Stepper style={{ display: "flex", flex: 1 }} activeStep={1} alternativeLabel>
                {steps.map((label) => (
                  <Step style={{ color: "black" }} key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box> 
          }

          {/* <TakeDecisionWeb /> */}

          <Switch>
            <Route exact path="/Catalogue/CompanyOverview" >
              <CompanyOverView id="" navigation={this.props.navigation} />
            </Route>
            <ProtectedRoute component={ProposedInstrumentDetails} exact path="/Catalogue/ProposedInstrumentDetails" />
            <ProtectedRoute component={StructureAndSecurity} path="/Catalogue/StructureandSecurity" />

            <ProtectedRoute component={ProjectDetails} path="/Catalogue/ProjectDetails" />
            <ProtectedRoute component={ManagementProfile} path="/Catalogue/ManagementProfile" />
            <ProtectedRoute component={DebtProfile} path="/Catalogue/DebtProfile" />
            <ProtectedRoute component={FinancialSumarry} path="/Catalogue/FinancialSummary" />
            <ProtectedRoute component={SupportiveDocument} path="/Catalogue/SupportiveDocuments" />
            <ProtectedRoute component={FAQList} path="/Catalogue/FAQList" />
          </Switch>

        </main>
      </div>
    );
  };
}

function ProtectedRoute({ ...restOfProps }: RouteProps) {
  const isDealIdPresent = isDealIdAvailable();

  return isDealIdPresent ? <Route {...restOfProps} /> : <Redirect to={{ pathname: '/Catalogue/CompanyOverview' }} />
}

function checkIsActive(match:any,location:any,checkAgainst: string){
  if(location.pathname.includes(checkAgainst)){
    return true
  }
  return false
}

const webStyle = {
  root: {
    paddingLeft: 250
  },
  appBar: {
    zIndex: 99,
  },
  title: {
    marginRight: "auto"
  },
  drawer: {
    width: 250,
    background: "#0d47a1",
    color: "#f1f2f4",
    // display: "none"
  },
  content: {
    padding: "24px 20px 0 80px ",
    background: "#fff",
    minHeight: '90vh'
  },
  link: {
    textDecoration: "none",
    color: "inherit"
  },
}

// Customizable Area End
