import React from "react";

// Customizable Area Start

import { Box, Typography, Grid, Button, withStyles } from "@material-ui/core";
import {
  logo,
  selectedRadioButton,
  radioButton,
  plus,
  activeCheckbox,
  inactiveCheckbox,
  eye,
} from "./assets";
import AppHeader from "../../catalogue/src/AppHeader.web";

import PrivacySettingsController, {
  Props,
  configJSON,
} from "./PrivacySettingsController";

class PrivacySettings extends PrivacySettingsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    // Customizable Area Start

    const { selectDebtProviders, selectedSettingOption } = this.state;
    const { classes } = this.props;

    // Merge Engine - render - Start
    return (
      <Box className={classes.pageContainer}>
        <AppHeader
          id=""
          location=""
          history=""
          navigation={this.props.navigation}
          match=""
        />

        <Box className={classes.mainContainer}>
          <Typography className={classes.leftContainerHeaderLabel}>
            Debt Provider Visibility Settings
          </Typography>

          <Grid container xs={12}>
            <Grid item md={6} xs={12}>
              {this.settingOptions.map((option) => (
                <Box
                  key={option.id}
                  onClick={() => this.selectSettingHandler(option)}
                  className={classes.providerOptionItem}
                  border={
                    selectedSettingOption.id === option.id
                      ? "2px solid #001D56"
                      : "1px solid #CCCCCC"
                  }
                  boxShadow="0px 4px 4px rgba(0, 0, 0, 0.25)"
                  mb="10px"
                >
                  <Box display="flex">
                    <img
                      src={option.icon}
                      className={classes.providerOptionIcon}
                    />
                    <Typography className={classes.providerOptionLabel}>
                      {option.label}
                    </Typography>
                  </Box>
                  <img
                    src={
                      selectedSettingOption.id === option.id
                        ? selectedRadioButton
                        : radioButton
                    }
                    className={classes.providerOptionRadioIcon}
                  />
                </Box>
              ))}
            </Grid>

            <Grid item md={6} xs={12} className={classes.rightGridContainer}>
              <Box className={classes.rightContainer}>
                <Box className={classes.rightHeaderContainer}>
                  <Box display="flex" ml="14px">
                    <img src={eye} className={classes.eyeIcon} />
                    <Typography className={classes.rightHeaderLabel}>
                      {selectedSettingOption.subLabel}
                    </Typography>
                  </Box>

                  {selectedSettingOption.selectUser && (
                    <Button
                      onClick={this.handleSelectUser}
                      className={classes.selectUserButton}
                    >
                      <Typography className={classes.selectUserButtonLabel}>
                        {selectDebtProviders ? "Save" : "Select more"}
                      </Typography>

                      {!selectDebtProviders && (
                        <img src={plus} className={classes.plusIcon} />
                      )}
                    </Button>
                  )}
                </Box>

                {selectedSettingOption.selectUser && selectDebtProviders && (
                  <Box className={classes.listContainer}>
                    {this.state.providerList.map((obj) => {
                      return (
                        <Box
                          key={`providerSelection${obj.id}`}
                          className={classes.listItem}
                        >
                          <Typography className={classes.userLabel}>
                            {obj.full_name}
                          </Typography>

                          <img
                            onClick={() => this.toggleProviderSelection(obj.id)}
                            src={
                              obj.isSelected ? activeCheckbox : inactiveCheckbox
                            }
                            className={classes.checkBox}
                          />
                        </Box>
                      );
                    })}
                  </Box>
                )}

                {selectedSettingOption.selectUser && !selectDebtProviders && (
                  <Box className={classes.listContainer}>
                    {this.state.providerList
                      .filter((provider) => provider.isSelected)
                      .map((provider) => {
                        return (
                          <Typography
                            key={`selectedList${provider.id}`}
                            className={classes.userLabel}
                          >
                            {provider.full_name}
                          </Typography>
                        );
                      })}
                  </Box>
                )}
              </Box>

              <Box className={classes.submitButtonContainer}>
                <Button
                  className={classes.submitButton}
                  onClick={() => this.submitVisibilitySettings()}
                >
                  <Typography className={classes.submitButtonText}>
                    Submit
                  </Typography>
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
    // Merge Engine - render - End
    // Customizable Area End
  }
}

// Customizable Area Start
const styles = () => ({
  pageContainer: {
    height: "inherit",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
  pageHeaderContainer: {
    height: "80px",
    width: "inherit",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
  },
  logo: {
    width: 43,
    height: 48,
    marginLeft: 16,
  },
  providerOptionRadioIcon: {
    width: 24,
    height: 24,
    marginRight: 39,
  },
  providerOptionLabel: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#323232",
  },
  providerOptionIcon: {
    width: 25,
    height: 25,
    marginLeft: 21,
    marginRight: 10,
  },
  providerOptionItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "inherit",
    height: "103px",
    borderRadius: "12px",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: "10px",
    cursor: "pointer",
  },
  leftContainerHeaderLabel: {
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 24,
    color: "#323232",
    marginBottom: 65,
  },
  headerRightMenu: {
    height: "32px",
    width: "32px",
    borderRadius: "16px",
    backgroundColor: "#323232",
    marginRight: "24px",
  },
  rightGridContainer: {
    paddingLeft: 17,
  },
  mainContainer: {
    height: "calc(100% - 110px)",
    display: "flex",
    flexDirection: "column",
    width: "70%",
    paddingTop: "100px",
  },
  rightContainer: {
    display: "flex",
    flexDirection: "column",
    width: "inherit",
    height: "452px",
    borderRadius: "12px",
    border: "1px solid #CCCCCC",
    boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
    marginBottom: "10px",
  },
  rightHeaderContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    minHeight: "72px",
    borderBottom: "1px solid #CCCCCC",
  },
  rightHeaderLabel: {
    textTransform: "uppercase",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 16,
    color: "#323232",
    width: "200px",
  },
  eyeIcon: {
    width: 18,
    height: 12,
    marginRight: 6,
    marginTop: 5,
  },
  selectUserButton: {
    backgroundColor: "#FAFAFA",
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.14)",
    height: 32,
    width: 127,
    borderRadius: 16,
    marginRight: 20,
  },
  selectUserButtonLabel: {
    color: "#001D56",
    fontFamily: "Lato",
    fontWeight: 700,
    fontSize: 14,
    textTransform: "none",
    marginRight: 10,
  },
  plusIcon: {
    width: 16,
    height: 16,
  },
  listContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: "12px",
    paddingLeft: "38px",
    paddingRight: "48px",
    overflowY: "auto",
  },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginBottom: "15px",
    cursor: "pointer",
  },
  userLabel: {
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: 16,
    color: "#323232",
  },
  checkBox: {
    width: 18,
    height: 18,
  },
  submitButtonContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: "57px",
    marginBottom: "57px",
  },
  submitButton: {
    background: "linear-gradient(108.82deg, #003E8F 25.52%, #001D56 85.05%)",
    boxShadow: "2px 2px 8px rgba(0, 0, 0, 0.14)",
    height: 51,
    width: 238,
    borderRadius: 24,
  },
  submitButtonText: {
    color: "#fff",
    fontFamily: "Lato",
    fontWeight: 400,
    fontSize: 18,
    textTransform: "none",
  },
});

export default withStyles(styles as any)(PrivacySettings);
// Customizable Area End
