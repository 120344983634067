import React from "react";
// Customizable Area Start

import {
  Box,
  InputLabel,
  Container,
  Select,
  IconButton,
  Typography,
  Button,
  Grid
} from "@material-ui/core";
import AddIcon from '@material-ui/icons/Add';
import PlayForWorkIcon from '@material-ui/icons/PlayForWork';

import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import { DownloadIcon } from "./assets";
import { withRouter } from "react-router-dom";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { Close } from "@material-ui/icons";
import CatalogueController, { Props } from "./CatalogueController";
import "./style.css";
import { checkForReadMode, handleReviewSubmitClick } from "../../../components/src/common";



class FinancialSummary extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // this.state = { values: [] };

  }

  uploadFile = (event: any) => {
    let X = event.target.files[0].name.split(".")[1]
    if (X == "xlsx" || X == "xls") {
      this.setState({
        uploadFinSummary: event.target.files[0],
        isUploadSummaryError: false,
        isNext: true
      });
    } else {
      this.setState({
        isUploadSummaryError: true,
      });
    }


  };

  componentDidMount(): any {
    const isReadMode = checkForReadMode(this.props.location);
    this.setState({
      isReadMode: isReadMode
    })
    let deal_id = localStorage.getItem("deal_id")
    if (deal_id) {
      this.setState({ deal_id: deal_id })
      this.fin_show_getapi(deal_id)
    }


    let fin_summary_id = localStorage.getItem("fin_summary_id")

  }


  render() {

    return (
      <>
        < >
          <Grid container spacing={2} >

            <Grid item xs={12} style={{ marginBottom: "30px" }}  >
              <Typography className="header">
                Financial Summary

              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Typography className="sub_header" id="fin_subheader">
                Download the standard Financial Summary template,add the details and upload again.
              </Typography>
            </Grid>
            <Grid item xs={4} className="fin_textInput_Label">
              <InputLabel className="Fin_Input_Label">Download Template </InputLabel>
            </Grid>
            <Grid item xs={8}>
              <div className="fin_add_deal_fileInput">
                <p className="fin_add_deal_fileInput_p">
                  financial_statements_template.xlxs
                </p>
                <input
                  id="downloadTemplate"
                  style={{ display: "none", color: "black" }}

                />
                <label
                  style={{
                    marginBottom: "5px"
                  }}
                  htmlFor="downloadTemplate"
                >
                  <img src={DownloadIcon} className="fin_DownloadIcon"
                    onClick={() => { if (!this.state.isReadMode) { this.downloadTemplate() } }}
                  />
                </label>
              </div>
            </Grid>
            {/* <Box className="fin_textInput_container1"> */}
            <Grid item xs={4} className="fin_textInput_Label">
              <InputLabel className="Fin_Input_Label">Upload Financial Summary </InputLabel>
            </Grid>

            <Grid item xs={8}>
              <div className="fin_add_Finupload_fileInput1">
                <input
                  type="file"
                  accept=".xls,.xlsx"
                  id="customInput2"
                  style={{ display: "none", color: "black" }}
                  name="uploadFinSummary"
                  onChange={this.uploadFile}
                  disabled={this.state.isReadMode}
                />
                <label
                  style={{
                    marginBottom: "5px",
                  }}
                  className="uploadBoxLebel"
                  htmlFor="customInput2"
                >
                  {
                    this.state.isUploadSummaryError ?
                      <Box display={"flex"} justifyContent="space-between">
                        <p className="fin_add_deal_fileInput_p">
                          <Typography className="fin_add_deal_fileInput_p" style={{ color: 'red', marginLeft: '1%' }}> Please choose only excel file</Typography>
                        </p>
                        {
                          this.state.isUploadSummaryError ? <IconButton disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent', padding: 0, margin: 0, paddingLeft: 5 }} onClick={(e) => {
                            this.setState({
                              isUploadSummaryError: false
                            })
                            e.preventDefault()
                          }} >
                            <Close />
                          </IconButton> : <AddCircleOutlineOutlinedIcon style={{ margin: "15px 5px 0 6px", cursor: "pointer" }} />
                        }
                      </Box>
                      :
                      <Box display={"flex"} justifyContent="space-between">
                        <p className="fin_add_deal_fileInput_p">
                          {this.state?.uploadFinSummary ? <p style={{textOverflow:'ellipsis',whiteSpace:'nowrap',overflow:'hidden'}}>{decodeURI(this.state?.uploadFinSummary?.name)} </p>: "Upload financial summary"}
                        </p>
                        {
                          this.state?.uploadFinSummary ?
                            <IconButton disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent', padding: 0, margin: 0, paddingLeft: 5 }} onClick={(e) => {
                              this.setState({
                                uploadFinSummary: '',
                                isNext:true,
                              })
                              e.preventDefault()
                            }} >
                              <Close />
                            </IconButton>
                            : <AddCircleOutlineOutlinedIcon style={{ margin: "15px 5px 0 6px", cursor: "pointer" }} />
                        }
                      </Box>
                  }
                </label>
              </div>
            </Grid>
            <Box >
            </Box>
            <Grid item xs={4}></Grid>
            <Grid item xs={8}>
              {!this.state.isReadMode ? <Box className="catalog_button_wrapper">
                <button
                  onClick={() => {
                    this.setState({ isNavigate: false }, () => {
                      this.handleDraftSubmit('true')
                    })
                  }}
                  className="save_as_draft_button"
                >
                  Save as Draft
                </button>

                {/* <Link to="ManagementProfile" style={{textDecoration:"none"}}> */}
                <button type="submit"
                  className="next_button"
                  onClick={() => {
                    this.setState({ isNavigate: true }, () => {
                      this.handleDraftSubmit('false')
                    })
                  }}
                >Next
                </button>
                {/* </Link> */}

              </Box> : <Box
                className="project_submit_button_wrapper"
              ><button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button">Next</button></Box>}
            </Grid>
          </Grid>
        </>
      </>
    );
  }
}


export default withRouter(FinancialSummary) as any;
// Customizable Area End
