import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  Select,
  TextField,
  FormControl,
  Radio,
  Tabs,
  Tab,
  AppBar,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { backgroundImage } from "./assets";
import { Formik, Form } from "formik";
import { logo } from "./assets";
import Visibility from "@material-ui/icons/Visibility";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      // fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});
const StyledTextField = withStyles({
  root: {
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        borderRadius: `25px`,
      },
    },
    '& . MuiPaper-root MuiAppBar-root MuiAppBar-positionStatic MuiAppBar-colorPrimary MuiPaper-elevation4':{
      boxShadow:'none'
    }
  },
})(TextField);

// Customizable Area End

import CfLedgerAccount4Controller, {
  Props,
  configJSON,
} from "./CfLedgerAccount4Controller";

export default class CfLedgerAccount4 extends CfLedgerAccount4Controller {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  handleChange = (value: any) => {

  };
  handleTab = (event: React.ChangeEvent<{}>, value: any) => {

  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container spacing={2} style={webStyle.gridParent}>
        <Grid item md={4}>
          <Box sx={webStyle.background}>
            <InputLabel style={webStyle.kycVerificationInputLabel}>Bank Account Details</InputLabel>
          </Box>
          <img
            src={logo}
            alt="logo"
            style={{ width: 100, height: 100, position: 'absolute', top: 100, left: 30 }}
          />
        </Grid>
        <Grid item md={1}></Grid>
        <Grid item md={6}>
        </Grid>
        <Grid item md={1}></Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  gridParent: {
    height: '97%',
    maxWidth: '100%',
    padding: '16px'
  },
  kycVerificationInputLabel: {
    color: 'white',
    paddingTop: '65%',
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    // fontWeight: 'normal',
    fontSize: '23px'
  },
  logo: {
    width: 100,
    height: 100,
    position: 'absolute',
    top: 100,
    left: 30
  },
  parentBox: {
    borderRadius: '10px',
    display: 'flex',
    flexDirection: 'column',
    // paddingLeft: '10px',
    // paddingRight: '10px'
  },
  background: {
    display: 'flex',
    alignItem: 'center',
    justifyContent: 'center',
    backgroundImage: `url(${backgroundImage})`,
    backgroundSize: 'cover',
    width: '100%',
    height: '100%',
    position: "relative",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center center",
    borderTopLeftRadius: "10px",
    borderBottomLeftRadius: "10px",
  },
  label: {
    paddingTop: '1%',
    paddingBottom: '1%',
    fontFamily: 'Roboto-Regular',
    fontStyle: 'normal',
    // fontWeight: 'normal',
  },
  formControl: {
    width: '100%',
    borderRadius: '25px'
  },
  selectTag: {
    borderRadius: '25px',
  },
  uploadBox: {
    height: '15vh',
    width: '100%',
    marginRight: '10px',
    borderRadius: '90px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'dashed',
    borderColor: 'grey',
    marginTop: '4%'
  },
  cloudUploadIconBox: {
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  buttonBox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-around',
    marginTop: '5%',
    paddingLeft: '10px',
    paddingRight: '10px'
  },
  radioButton: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    paddingLeft: '10px',
    paddingRight: '10px'
  }
};
// Customizable Area End
