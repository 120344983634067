Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Chat9";
exports.labelBodyText = "Chat9 Body";

exports.btnExampleTitle = "CLICK ME";
exports.chatCredentialsAPiEndPoint = "bx_block_chatbackuprestore/chat_credentials"
exports.someSecret = 'someSecret'
// Customizable Area End