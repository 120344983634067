//Customize Area Start
import React from "react";
import {
  Grid,
  Dialog,
  DialogTitle,
  Typography,
  DialogContent,
  Box,
  Button,
  InputLabel,
  IconButton,
  Divider,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import { IShowAllocateResponse } from "./TakeActionController.web";
import { download, showTextWithValidation } from "../../../components/src/common";
import { Close, InsertDriveFileOutlined } from '@material-ui/icons';
import Chat9 from "../../Chat9/src/Chat9.web";

type Props = {
  closeDrawer: any;
  data: IShowAllocateResponse;
  handleAccept: (id: string) => void;
  handleReject: (id: string) => void;
  handleFileUpload: (files: FileList, offerId: string) => void;
  handleFileRemove: (offerId: string) => void;
  handleOpenChatBox : ()=> void;
  handleDialog: ()=>void;
  handleOpenDialogBox :(opponentId:any,offerId:any)=> void
  dialogId:any;
  open :boolean;
  projectName:string
};

function AllocateDealPopUP(props: Props) {
  const { open,dialogId,projectName,handleDialog,handleOpenDialogBox,closeDrawer, data, handleAccept, handleReject, handleFileUpload, handleFileRemove } = props;

  const isDateExpired = (date: string) => {
    const givenDate = new Date(date);
    const currentDate = new Date();
    /* istanbul ignore next */
    if (givenDate.toDateString() === currentDate.toDateString()) {
      return false;
    } else if (givenDate < currentDate) {
      return true;
    } else {
      return false;
    }
  }

  /* istanbul ignore next */
  const handleFileChange = (e: any, offerId: string) => {
    const filesList = e.target.files;
    handleFileUpload(filesList, offerId);
  }


  return (
    <Dialog open={true} className="allocate_deal_popup">
      <DialogTitle className="ongoing_deal_popup_title">
        <ArrowBackIosIcon data-test-id="back-icon" className="back-icon" onClick={closeDrawer} />
        <Typography className="ongoin-deal-card-heading">
          Debt {localStorage.getItem('role_id') == "1" ? 'Seeker' : 'Arranger'} Dashboard- Ongoing Deals
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography className="deal_name_heading">{data.project_name}</Typography>
        <Grid container spacing={6}>
          <Grid
            item
            xl={4}
            lg={4}
            sm={6}
            xs={12}
            md={4}
            style={{ paddingLeft: "0px" }}
          >
            <Grid item lg={12} className="deal_box">
              <Box
                display={"flex"}
                justifyContent="space-between"
                className="deal_input_container"
              >
                <Typography className="deal_details_title_text">
                  Amount
                </Typography>
                <Box>
                  <p className="onging_deal_input">{showTextWithValidation(`${(data.amount)} CR`)}</p>
                </Box>
              </Box>
              <Box
                display={"flex"}
                justifyContent="space-between"
                className="deal_input_container"
              >
                <Typography className="deal_details_title_text">
                  Yield
                </Typography>
                <Grid>
                  <p className="onging_deal_input">{showTextWithValidation(`${data.yield} %`)}</p>
                </Grid>
              </Box>
              <Box
                display={"flex"}
                justifyContent="space-between"
                className="deal_input_container"
              >
                <Typography className="deal_details_title_text">
                  Maturity Date
                </Typography>
                <Grid>
                  <p className="onging_deal_input">{showTextWithValidation(data.maturity_date)}</p>
                </Grid>
              </Box>
            </Grid>
          </Grid>
          <Grid item xl={6} lg={6} sm={6} xs={12} md={4}>
            <Grid item lg={10} className="deal_box">
              <Box
                display={"flex"}
                justifyContent="space-between"
                className="deal_input_container"
              >
                <Typography className="deal_details_title_text">
                  Total Allocation Percentage
                </Typography>
                <Grid
                  item
                  lg={6}
                  xl={6}
                  md={5}
                  sm={6}
                  xs={6}
                  className="input_filed"
                >
                  <p className="onging_deal_input">{data.total_allocation_percentage !== "NaN" ? `${data.total_allocation_percentage}  %` : "-"}</p>
                </Grid>
              </Box>
              <Box
                display={"flex"}
                justifyContent="space-between"
                className="deal_input_container"
              >
                <Typography className="deal_details_title_text">
                  Total Allocation Amount
                </Typography>
                <Grid
                  item
                  lg={6}
                  xl={6}
                  md={5}
                  sm={6}
                  xs={6}
                  className="input_filed"
                >
                  <p className="onging_deal_input">{showTextWithValidation(`${(data.total_allocation_amount)} CR`)}</p>
                </Grid>
              </Box>
              {data.is_offer_accepted && (
                <Box
                  display={"flex"}
                  justifyContent="space-between"
                  className="deal_input_container"
                >
                  <Typography className="deal_details_title_text">
                    Status
                  </Typography>
                  <Grid
                    item
                    lg={6}
                    xl={6}
                    md={5}
                    sm={6}
                    xs={6}
                    className="input_filed"
                  >
                    <p className="onging_deal_input">{showTextWithValidation(data.status)}</p>
                  </Grid>
                </Box>
              )}
            </Grid>
          </Grid>
          <Grid container className="debt_provider_container">
            <Grid container className="debt_provider_header" wrap={'nowrap'} spacing={3}>
              <Grid item lg={2}>
                <Typography className="deb_provider_header_text" align="center">
                {localStorage.getItem('role_id') == "1" ? "Debt Provider Name" : "Investor Name"}
                </Typography>
              </Grid>
              <Grid item lg={3}>
                <Typography className="deb_provider_header_text" align="center">
                  Latest Offer Received
                </Typography>
                <Grid
                  container
                  style={{ marginTop: "16px", justifyContent: "center" }}
                >
                  <Box className="offer_chips">Amount</Box>
                  <Box className="offer_chips">Yield</Box>
                  <Box className="offer_chips">Date of offer</Box>
                </Grid>
              </Grid>
              <Grid item lg={2}>
                <Typography className="deb_provider_header_text" align="center">
                  Action
                </Typography>
              </Grid>
              <Grid item lg={1}>
                <Typography className="deb_provider_header_text" align="center">
                  Status
                </Typography>
              </Grid>
              {data.is_any_offer_accepted ?
                <Grid item lg={1}>
                  <Typography className="deb_provider_header_text" align="center">
                    Remarks
                  </Typography>
                </Grid> : 
                <Grid item lg={2}>
                  <Typography className="deb_provider_header_text" align="center">
                    Remarks
                  </Typography>
                </Grid> 
              }
              
              {data.is_any_offer_accepted && <Grid item lg={2}>
                <Typography className="deb_provider_header_text" align="center">
                  Upload payment confirmation (optional)
                </Typography>
              </Grid>}

              {
                data.is_any_offer_accepted ? (<Grid item lg={2}  >
                  <Typography
                    className="deb_provider_header_text"
                    align="center"
                    style={{ paddingLeft: "19%" }}
                  >
                    UTR
                  </Typography>
                </Grid>) : (<Grid item lg={2}  >
                  <Typography
                    className="deb_provider_header_text"
                    align="center"
                  >
                    UTR
                  </Typography>
                </Grid>)
              }
            </Grid>
            {data.data.map((obj, index) => {

              return (
                <>
                  <Grid
                    container
                    spacing={3}
                    key={index}
                    className={
                      obj.attributes.show_status === "Offer accepted"
                        ? "offer_accepted_row"
                        : "other_offer_row"
                    }
                    wrap={'nowrap'}
                  >
                    <Grid item lg={2}>
                      <Box className="provider_name_button">
                        {showTextWithValidation(obj.attributes.investor_name)}
                      </Box>
                    </Grid>
                    <Grid item lg={3}>
                      <Box
                        display={"flex"}
                        flexWrap={"wrap"}
                        justifyContent="center"
                      >
                        <Box className="latest_offer_received">
                          {/* {checkTotalDeal(obj.attributes.amount)} CR */}
                          {(obj.attributes.amount)} CR
                        </Box>
                        <Box className="latest_offer_received">
                          {obj.attributes.yield} %
                        </Box>
                        <Box
                          className="latest_offer_received"
                          style={{ marginRight: "0px" }}
                        >
                          {showTextWithValidation(obj.attributes.date_of_offer)}
                        </Box>
                      </Box>
                    </Grid>
                    <Grid item lg={2} md={2} style={{display:'flex',justifyContent:'space-between',alignItems:'center'}}spacing={2}  >
                      
                      <Grid item md={5} lg={5} >
                        <Box  style={{marginBottom:'9px'}} 
                          data-test-id="accept-button"
                        onClick={() => {
                            /* istanbul ignore next */
                          if( data.total_allocation_percentage==100 || data.is_view_details){
                            return false;
                          }
                          else if (obj.attributes.status === "Offer received" || obj.attributes.status === "pending" ) {
                            handleAccept(obj.id);
                          }
                        }} 
                        className={

                          obj.attributes?.show_status == "Offer accepted" 
                          ? "offer_accepted_row_chips"
                          : data.total_allocation_percentage==100?"action-chip-disable":
                          obj.attributes?.show_status == "Offer received"
                           ? "action_chips" :
                            "action-chip-disable"
                        }
                        >Accept</Box>
                        <Box  
                          data-test-id="reject-button"
                        onClick={() => {
                            /* istanbul ignore next */
                          if( data.total_allocation_percentage==100 || data.is_view_details){
                            return false;
                          }
                          else if (obj.attributes.status === "Offer received" || obj.attributes.status === "pending" ) {
                            handleReject(obj.id)                          }
                        }}
                        className={
                          obj.attributes?.show_status == "Offer accepted" 
                            ? "offer_accepted_row_chips"
                            : data.total_allocation_percentage==100?"action-chip-disable":
                            obj.attributes?.show_status == "Offer received"
                             ? "action_chips" :
                              "action-chip-disable"
                        }
                        >Reject</Box>
                      </Grid>
                      <Grid item md={6} lg={6} 
                        data-test-id="connectWithInvestorButton"
                      className={`connectWithInvestorButton ${ obj.attributes?.show_status === "Offer withdrawn"|| ( data.total_allocation_percentage===100&&obj.attributes.show_status!=='Offer accepted')||(obj.attributes.show_status==='Offer accepted'&& isDateExpired(data?.maturity_date.split("/").reverse().join("-"))) ? 'action-chip-disable':''}`} style={{textAlign:'center',cursor:'pointer'}} 
                      onClick={() => {
                        if (obj.attributes?.show_status === "Offer withdrawn" || (data.total_allocation_percentage===100 && obj.attributes.show_status!=='Offer accepted') || (obj.attributes.show_status=='Offer accepted'&& isDateExpired(data?.maturity_date.split("/").reverse().join("-")))){
                          return false;
                        }
                          /* istanbul ignore next */
                        handleOpenDialogBox(obj?.attributes?.groupchat_dialog_id,obj.id)}}>Connect With Investor</Grid>
                    </Grid>

                    <Grid item lg={1}>
                      <Box className="provider_name_button allocate_status_btn">
                        {showTextWithValidation(obj.attributes.show_status)}
                      </Box>
                    </Grid>

                    {
                      data.is_any_offer_accepted ? (<Grid item lg={1}>
                        <Box className="provider_name_button">
                          {showTextWithValidation(obj.attributes.reject_remarks)}
                        </Box>
                      </Grid>) : (<Grid item lg={2}>
                        <Box className="provider_name_button">
                          {showTextWithValidation(obj.attributes.reject_remarks)}
                        </Box>
                      </Grid>)
                    }
                    {/* istanbul ignore next */}
                    {data.is_any_offer_accepted && <Grid item lg={2} style={{display:"flex", alignItems:"center", justifyContent:"center"}}>
                      <Button disabled={obj.attributes.show_status !== "Offer accepted"} className="upload_button_container" variant="outlined"  >
                      {
                          obj.attributes && !obj.attributes.upload_files ? <InputLabel style={{ padding: "16px", cursor: "pointer", textDecoration: "none" }} >
                          <input
                            style={{ display: "none" }}
                            type="file"
                            placeholder="Upload files"
                            accept="image/*,.pdf,.zip,.rar,.7z,.gz"
                              /* istanbul ignore next */
                            data-testid="file-upload-input"
                            disabled={obj.attributes.show_status !== "Offer accepted" || data.is_view_details}
                            onChange={(e) => handleFileChange(e, obj.id)}
                          />Upload Files
                        </InputLabel>
                           : <>
                            <div style={{paddingRight: '0.5em'}}><InsertDriveFileOutlined color='primary'/></div>
                            <p data-testid={'file-name'} style={{color: '#001d56', fontSize: '14px',wordBreak: 'break-all', padding: '8px', fontWeight: 400}}  onClick={()=> download(obj.attributes?.upload_files?.url || '', obj.attributes?.upload_files?.name || '')}>{obj.attributes?.upload_files?.name}</p>
                            <IconButton  style={{ backgroundColor: 'transparent' }} data-testid={'remove-file-button'} onClick={() => handleFileRemove(obj.id)}>
                              <Close/>
                            </IconButton>
                           </>
                        }


                      </Button>

                      {/* <Box className="provider_name_button">Upload files</Box> */}
                    </Grid>}
                    {
                      data.is_any_offer_accepted ? <Grid item lg={2}>
                        <Box className="provider_name_button" style={{padding:"19px 3px"}}>{showTextWithValidation(obj.attributes.utr)}</Box>
                      </Grid> : (<Grid item lg={2}>
                        <Box className="provider_name_button" style={{padding:"19px 3px"}}>{showTextWithValidation(obj.attributes.utr)}</Box>
                      </Grid>)
                    }

                  </Grid>
                  {index !== data.data.length - 1 && (
                    <Divider className="deals_divider" />
                  )}
                </>
              );
            })}
          </Grid>
        </Grid>
        {open ?<Chat9  dialogId={dialogId} navigation={''} id={''}  handleDialog = {handleDialog} dealName={projectName} ></Chat9>:null}
      </DialogContent>
    </Dialog>
  );
}
export default AllocateDealPopUP;
//Customize Area End
