import React from "react";
// Customizable Area Start
import {
    Box,
    makeStyles,
    Typography,
    Button,
    AppBar,
    Toolbar,
} from "@material-ui/core";
import LandingPageController, { Props } from "../../blocks/landingpage/src/LandingPageController";
import CustomMenu from "./CustomMenu";
import {
    finLogo, finmart_logo
} from "../../blocks/landingpage/src/assets";
import AboutUsMenu from "./AboutUsMenu.web";
import "./styles.css";
// Customizable Area End

export default class Header extends LandingPageController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start

        // Customizable Area End
    }

    // Customizable Area Start
    handleProductsOpen = (e: any) => {
        this.setState({ openProducts: e.currentTarget });
    };

    handleProductsClose = () => {
        this.setState({ openProducts: null });
    };

    // Customizable Area End



    render() {
        const { navigation } = this.props;
        return (
            // Customizable Area Start
            <AppBar elevation={0} position="fixed">

                <Toolbar className="appbar" style={{
                    padding: "24px 32px",
                    background: "#F4F3EF",
                }} >
                    <Box className="header_left_shadow" />
                    <img
                        onClick={() => navigation.navigate("LandingPage")}
                        alt="logo"
                        src={finmart_logo}
                        className="header_logo"
                    />
                    <Box flexGrow={1} />
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <CustomMenu
                            label="Products"
                            menuList={this.state.products}
                            onClose={this.handleProductsClose}
                            onClick={this.handleProductsOpen}
                            open={this.state.openProducts}
                        />
                        {/* <a href="/LandingPage#howItWork" style={{ textDecoration: "none" }}> */}
                        <a href="/#howItWork" style={{ textDecoration: "none" }}>
                            <Button className="header_howItWork_button">
                                <Typography
                                    className="header_howItWork_button_text"
                                >
                                    How it Works
                                </Typography>
                            </Button>
                        </a>
                        <a href="#" style={{textDecoration:"none",color:"#323232",paddingTop:'0.3em'}}>
                        <AboutUsMenu
                            id="aboutUs"
                            navigation={navigation} match={undefined} location={undefined} history={undefined} 
                     /></a>
                    </div>
                    <Box flexGrow={.2} />
                    <Box className="header_shadow_right" />
                    {localStorage.getItem("token")?
                     <Box style={{ position: "relative" }} >
                     <Typography
                         className="header_signin"
                         onClick={() => {
                            localStorage.clear();
                            location.reload();
                         }}
                     >
                        Logout
                     </Typography>
                 </Box>:<>
                 <Box style={{ position: "relative" }} >
                            <Typography
                                className="header_signin"
                                onClick={() => {
                                    // localStorage.setItem('hideSignUp', 'true')
                                    navigation.navigate("EmailAccountLoginBlock");
                                }}
                            >
                                Login
                            </Typography>
                        </Box>
                        <Box flexGrow={0.1} />
                            <Box style={{ position: "relative" }} >
                                <Typography
                                    className="header_signup"
                                    onClick={() => {
                                        // localStorage.setItem('hideSignUp', 'true')
                                        navigation.navigate("EmailAccountRegistration");
                                    }}
                                >
                                    Sign up
                                </Typography>
                            </Box>
                 </>
                
                }
                    {
                        // !localStorage.getItem("token") &&
                        // <Box style={{ position: "relative" }} >
                        //     <Typography
                        //         className="header_signin"
                        //         onClick={() => {
                        //             // localStorage.setItem('hideSignUp', 'true')
                        //             navigation.navigate("EmailAccountLoginBlock");
                        //         }}
                        //     >
                        //         Login
                        //     </Typography>
                        // </Box>
                    }

                    {/* {!localStorage.getItem("hideSignUp") && !localStorage.getItem("token") && (
                        <> */}
                            {/* <Box flexGrow={0.1} />
                            <Box style={{ position: "relative" }} >
                                <Typography
                                    className="header_signup"
                                    onClick={() => {
                                        // localStorage.setItem('hideSignUp', 'true')
                                        navigation.navigate("EmailAccountRegistration");
                                    }}
                                >
                                    Sign up
                                </Typography>
                            </Box> */}
                        {/* </> */}
                    {/* )} */}

                    {/* {localStorage.getItem("token") && (
                        <>
                            <Box flexGrow={0.1} />
                            <Box style={{ position: "relative" }} >
                                <Typography
                                    className="header_signup"
                                    onClick={() => {
                                        localStorage.clear()
                                        localStorage.setItem('hideSignUp', 'true')
                                        window.location.reload()
                                    }}
                                >
                                    Logout
                                </Typography>
                            </Box>
                        </>
                    )} */}
                </Toolbar>
            </AppBar>
            // Customizable Area End
        );
    }
}


