// Customizable Area Start
import { callApi, checkForReadMode, Role_name } from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DebtArranger, DebtArrangerCompany } from "./Interface.web";

const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    match: any;
    location: any;
    history: any;
}

interface S {
    arrayHolder: any;
    token: string;
    currentUser: string;
    isUserName: string;
    companyBackground: string;
    debtArrangerCompanyName: string;
    debtArrangerName: string;
    isErrorEmail: boolean;
    isPan: boolean;
    debtSeeker: string;
    debtSeekerFromResponse: string;
    editMode: boolean;
    logo: any;
    isUserEmail: string;
    video: any;
    presentation: any;
    currentUserInfo: any;
    debtArrangerCompanyLoading: boolean;
    debtArrangerCompanyData: DebtArrangerCompany[];
    debtArrangerData: DebtArranger[];
    spinner: boolean;
    videoUrl: string | any;
    imgUrl: string;
    presentationUrl: string;
    redirect: boolean;
    imgError: boolean;
    videoError: boolean;
    PresentationError: boolean;
    isNext: boolean;
    delete_logo_image: boolean;
    delete_video: boolean;
    delete_presentation: boolean;
    seekerCompanyName: string;
    arrangerCompanyName: string;
    arrangerName: string;
    isReadMode: boolean;
    isErrorCompanyName: boolean;
    issuerEmailPresent: boolean;
    is_seeker_deal: boolean;
    openModal: boolean;
    seekerInviteModal: boolean;
    isEmailsent: boolean;
    inviteIssuerLoading: boolean;
    inviteArrangerLoading: boolean;
    sentSuccessfully: boolean;
    dealIdPresent: boolean;
    inviteName: string;
    inviteEmail: string;
    inviteEmailError: boolean;
    isErrorMobile: boolean;
    isErrorInviteName: boolean;
    isDraft: boolean;
    invitePhone: string;
    currentUserRoleName: string;
    seekerDeal: string;
    regex: any;
    checkingPanLoading: boolean;
    firstBlur: boolean;
    companyNameisDisable: boolean;
}
interface SS {
    id: any;
}

export default class CompanyOverViewController extends BlockComponent<Props, S, SS> {
    apiGetdebtArrangerId: string = "";
    apiFilterGetdebtArrangerId: string = "";
    checkIssuerMailId: string = "";
    createAddDealForSaveAsDraft: string = "";
    getSaveAsDraftDataId: string = "";
    getSaveAsDraftDataUpdateId: string = "";
    getCurrentUserInfo: string = "";
    sendInviteIssuerId: string = "";
    sendInviteArrangerId: string = "";
    getDebtSeekerCompanyId: string = "";

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.redirectMessage),
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            currentUser: "1",
            seekerCompanyName: "",
            isUserName: "",
            companyBackground: "",
            debtArrangerCompanyName: "",
            debtArrangerName: "",
            isErrorEmail: false,
            isPan: false,
            editMode: false,
            debtSeeker: "",
            debtSeekerFromResponse: "",
            logo: "",
            isUserEmail: "",
            video: "",
            presentation: "",
            debtArrangerCompanyLoading: false,
            currentUserInfo: {},
            debtArrangerCompanyData: [],
            debtArrangerData: [],
            spinner: false,
            videoUrl: "",
            imgUrl: "",
            presentationUrl: "",
            redirect: false,
            imgError: false,
            videoError: false,
            PresentationError: false,
            isErrorCompanyName: false,
            issuerEmailPresent: false,
            is_seeker_deal: false,
            openModal: false,
            seekerInviteModal: false,
            isEmailsent: false,
            inviteIssuerLoading: false,
            inviteArrangerLoading: false,
            sentSuccessfully: false,
            dealIdPresent: false,
            isNext: false,
            delete_logo_image: false,
            delete_video: false,
            delete_presentation: false,
            arrangerCompanyName: "",
            arrangerName: "",
            inviteName: "",
            inviteEmail: "",
            invitePhone: "",
            currentUserRoleName: "",
            seekerDeal: "",
            regex: /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-]+)(\.[a-zA-Z]{2,5}){1,2}$/,
            isReadMode: false,
            inviteEmailError: false,
            isErrorMobile: false,
            isErrorInviteName: false,
            checkingPanLoading: false,
            firstBlur: true,
            isDraft: false,
            companyNameisDisable: false,
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    //Functional Logic start

    handleEmailChange = (e: any) => {
        let email = e.target.value as string;
        const { regex } = this.state;
        this.setState({ issuerEmailPresent: false });
        const emailArray = email.split("@");

        !regex.test(email) ? this.setState({ isErrorEmail: true }) : this.setState({ isErrorEmail: false,firstBlur:true });
        this.setState({ isUserEmail: email, isNext: true });
    };

    checkIssuerEmail = (e: any) => {
        if (this.state.firstBlur) {
            let email = e?.target.value;
            const { regex } = this.state;
            if (regex.test(email) && this.state.isErrorEmail === false) {
                email ? this.checkIssuerEmailisPresent(email) : null;
            }
            this.setState({firstBlur:false})
        }
    };
    checkArrangerInviteEmail = (e: any) => {
        let email = e.target.value;
        const { regex } = this.state;
        if (regex.test(email)) {
            this.setState({ inviteEmail: email, inviteEmailError: false });
        } else {
            this.setState({ inviteEmailError: true });
        }
    };
    /* istanbul ignore next */
    retunCurrentPayload=()=>{
        const {
                debtSeeker, logo, video, presentation, debtArrangerCompanyName, debtArrangerName, 
                companyBackground, currentUserRoleName,isUserName, isUserEmail, videoUrl, presentationUrl, imgUrl } = this.state;

        let payload={}

        if (currentUserRoleName === Role_name.Seeker){
            payload={
                logo, imgUrl,
                video, videoUrl,
                presentation, presentationUrl,
                debtArrangerCompanyName,
                debtArrangerName,
                companyBackground
            }
        }
        if (currentUserRoleName === Role_name.Arranger) {
            payload = {
                isUserName, isUserEmail, debtSeeker,
                logo, imgUrl,
                video, videoUrl,
                presentation, presentationUrl,
                companyBackground
            }
        }
        if (currentUserRoleName === Role_name.Admin) {
            payload = {
                isUserName, isUserEmail, debtSeeker,
                logo, imgUrl,
                video, videoUrl,
                presentation, presentationUrl,
                companyBackground,
                debtArrangerCompanyName,
                debtArrangerName,
            }
        }

        const isPayloadEmpty = Object.values(payload).every(value => value === '' || value == null)
        return isPayloadEmpty;
    }
    
    /* istanbul ignore next */
    handleSubmit = () => {

        const isPayloadEmpty = this.retunCurrentPayload();
        const deal_id = localStorage.getItem("deal_id");
        const { imgError, videoError, PresentationError } = this.state;

        if (imgError || videoError || PresentationError) {
            const msg14: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg14.addData(getName(MessageEnum.CustomAlertBodyData), "Please select a valid file");
            this.send(msg14);
            return;
        }

        if (isPayloadEmpty) {
            const msg15: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg15.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill all the mandatory fields.");
            this.send(msg15);
            return;
        }        

        this.setState({ redirect: true });

        if (deal_id) {
            this.handleSaveAsDraftUpdate(false);
        } else {
            this.handleSaveAsDraft(false);
        }
       
    };

    arrangerCompanyNameHandler = (e: any) => {
        this.setState({ debtArrangerCompanyName: e.target.value, isNext: true, debtArrangerName: "" }, () => {
            this.callGetFilterArranger();
        });
    };
    debtArrangerNameHandler = (e: any) => {
        this.setState({ debtArrangerName: e.target.value, isNext: true });
    };
    getPanValue = (event: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^[a-zA-Z]{5}[0-9]{4}[a-zA-z]{1}/;
        let isUser = event.target.value;
        !regex.test(isUser) ? this.setState({ isPan: true }) : this.setState({ isPan: false });
        this.setState({ isUserName: isUser.toUpperCase(), isNext: true });
    };

    handleDraft = () => {
        const deal_id = localStorage.getItem("deal_id");
        const { imgError, videoError, PresentationError, currentUserRoleName } = this.state;
        const isPayloadEmpty = this.retunCurrentPayload();
        /* istanbul ignore next */
        if (isPayloadEmpty && currentUserRoleName === Role_name.Admin){
            const msg16: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg16.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter value in any field to create a draft deal.");
            this.send(msg16);
            return;
        }
            if (imgError || videoError || PresentationError) {
                const msg17: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                msg17.addData(getName(MessageEnum.CustomAlertBodyData), "Please select a valid file");
                this.send(msg17);
            } else {
                if (deal_id) {
                    this.handleSaveAsDraftUpdate(true);
                } else {
                    this.handleSaveAsDraft(true);
                }
            }
    };
    onLogoChange = (event: React.ChangeEvent<any>) => {
        const image = event.target.files[0];
        if (/\.(gif|jpe?g|tiff?|png|webp|bmp|jfif)$/i.test(image?.name)) {
            this.setState({ logo: event.target.files[0], delete_logo_image: false });
            this.setState({ imgError: false, isNext: true });
        } else {
            this.setState({ imgError: true, isNext: true });
        }
    };
    onVideoChange = (event: React.ChangeEvent<any>) => {
        const image = event.target.files[0];
        if (/\.(video|mp4|x-m4v|gif)$/i.test(image?.name)) {
            this.setState({ video: event.target.files[0] });
            this.setState({ videoError: false, isNext: true, delete_video: false });
        } else {
            this.setState({ videoError: true, isNext: true });
        }
    };
    onPresentationChange = (event: React.ChangeEvent<any>) => {
        const image = event.target.files[0];
        if (/\.(video|mp4|x-m4v|gif|pdf|ppt|pptx)$/i.test(image?.name)) {
            this.setState({ presentation: event.target.files[0] });
            this.setState({ PresentationError: false, isNext: true, delete_presentation: false });
        } else {
            this.setState({ PresentationError: true, isNext: true });
        }
    };

    handleCompanyName = (e: any) => {
        const regEx = /^[a-z0-9 ]+$/i;
        let company_name = e.target.value;
        if (company_name.length > 0) {
            this.setState({ isErrorCompanyName: false });
        } else {
            this.setState({ isErrorCompanyName: true });
        }

        if (regEx.test(company_name) || company_name === "") {
            this.setState({ debtSeeker: company_name, isNext: true });
        }
    };

    handleMobileChange = (e: any) => {
        const regex = /[0-9]$/;
        let mobile = e.target.value;
        mobile.length === 10 ? this.setState({ isErrorMobile: false }) : this.setState({ isErrorMobile: true });
        if (regex.test(mobile) || mobile == "") {
            this.setState({ invitePhone: mobile });
        }
    };

    handleInviteNameChange = (e: any) => {
        const regex = /^[a-zA-Z.][a-zA-Z. ]*$/;
        let inviteName = e.target.value;
        inviteName.length > 25 || inviteName.length < 2 || inviteName.length === 0
            ? this.setState({ isErrorInviteName: true })
            : this.setState({ isErrorInviteName: false });
        if (regex.test(inviteName) || inviteName == "") {
            this.setState({ inviteName });
        }
    };

    handleNumberChange = (e: any) => {
        let company_name = e?.target.value;
        var pattern = /^[0-9]+$/;
        if (pattern.test(company_name)) {
            this.setState({ isErrorCompanyName: true, isNext: false });
        }
    };
    //Functional Logic end

    async componentDidMount() {
        const deal_id = localStorage.getItem("deal_id");
        const urlParams = new URLSearchParams(window.location.search).get("edit");
        const admin_deal_id = new URLSearchParams(window.location.search).get("id");
        const isAdmin = new URLSearchParams(window.location.search).get("role_name");

        if (urlParams || admin_deal_id) {
            this.setState({ editMode: true });
        }

        isAdmin && localStorage.setItem("deal_id", admin_deal_id ? admin_deal_id : "");

        const isReadMode = checkForReadMode(this.props.location);
        this.setState({
            isReadMode: isReadMode,
        });

        this.getCurrentUserInformation();

        this.getDebtArrangerCompany();
        if (deal_id) {
            this.getSaveAsDraftData();
            this.setState({ dealIdPresent: true });
        } else {
            localStorage.setItem("security_id", "");
        }
    }

    async receive(from: string, message: Message) {
        /* istanbul ignore next */
        if (message.id === getName(MessageEnum.redirectMessage)) {
            const customRedirect = message.getData(getName(MessageEnum.redirectData));
                if (this.state.redirect && customRedirect) {
                    this.props.history.push("/Catalogue/ProposedInstrumentDetails");
                }
        }
        if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));

            var responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));

            var errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage));

            if (responseJson) {
                if (responseJson?.errors) {
                    this.setState({
                        isEmailsent: false,
                        openModal: false,
                        spinner: false,
                        checkingPanLoading: false,
                        inviteName: "",
                        invitePhone: "",
                    });
                    if (responseJson?.errors[0]?.token === "Token has Expired") {
                        localStorage.removeItem("token");
                    } else {
                        let res = responseJson?.errors
                            .map((i: any) => Object.keys(i) + " " + Object.values(i))
                            .join("\n");
                        const msg18: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                        msg18.addData(getName(MessageEnum.CustomAlertBodyData), res);
                        this.send(msg18);
                        this.setState({ redirect: false });
                    }
                } else {
                    if (this.apiGetdebtArrangerId === apiRequestCallId) {
                        this.setState({
                            debtArrangerCompanyData: responseJson?.data,
                            debtArrangerCompanyLoading: false,
                        });
                    }
                    if (this.checkIssuerMailId === apiRequestCallId) {
                        if (responseJson?.found === false) {
                            this.setState({ issuerEmailPresent: true });
                        } else {
                            this.setState({ issuerEmailPresent: false });
                        }
                    }
                    if (this.apiFilterGetdebtArrangerId === apiRequestCallId) {
                        this.setState({
                            debtArrangerData: responseJson?.data,
                        });
                    }
                    if (this.getDebtSeekerCompanyId === apiRequestCallId) {
                        this.setState({
                            checkingPanLoading: false,
                            debtSeeker: responseJson?.data?.attributes?.company_name,
                        });
                    }
                    if (this.sendInviteIssuerId === apiRequestCallId) {
                        this.setState({ inviteIssuerLoading: false });
                        if (responseJson?.meta?.message === "Invite sent successfully to the user's email.") {
                            this.setState({ sentSuccessfully: true, issuerEmailPresent: false });
                        }
                    }
                    if (this.sendInviteArrangerId === apiRequestCallId) {
                        this.setState({
                            inviteArrangerLoading: false,
                            inviteEmail: "",
                            inviteName: "",
                            invitePhone: "",
                        });
                    }
                    /* istanbul ignore next */
                    if (this.createAddDealForSaveAsDraft === apiRequestCallId) {
                        if (responseJson?.meta) {
                            const msg19: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                            msg19.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                            this.send(msg19);
                            localStorage.setItem("deal_id", responseJson?.meta?.deal_id);
                        } else {
                            this.setState({ redirect: false });
                        }
                        this.setState({ spinner: false });
                    }
                    if (this.getSaveAsDraftDataId === apiRequestCallId) {
                        if (responseJson?.data?.attributes?.review_status !== "draft") {
                            this.setState({ isDraft: true });
                        }

                        this.setState({
                            debtSeeker: responseJson?.data?.attributes?.debt_seeker_company_name,
                            debtSeekerFromResponse: responseJson?.data?.attributes?.debt_seeker_company_name,
                        });
                        this.setState({ companyBackground: responseJson?.data?.attributes?.company_background });
                        this.setState({ isUserName: responseJson?.data?.attributes?.issuer_company_pan });
                        this.setState({ isUserEmail: responseJson?.data?.attributes?.issuer_email });
                        if (responseJson?.data?.attributes?.is_seeker_deal === "true") {
                            this.setState({ is_seeker_deal: responseJson?.data?.attributes?.is_seeker_deal });
                        } else {
                            this.setState({ seekerDeal: "no" });
                        }
                        if (responseJson?.data?.attributes?.upload_logo_image) {
                            let image = responseJson?.data?.attributes?.upload_logo_image.split("/");
                            this.setState({ imgUrl: image[image?.length - 1] });
                        }
                        if (responseJson?.data?.attributes?.upload_video) {
                            let video = responseJson?.data?.attributes?.upload_video.split("/");
                            this.setState({ videoUrl: [video[video?.length - 1]] });
                        }
                        if (responseJson?.data?.attributes?.company_presentation_file) {
                            let presentat = responseJson?.data?.attributes?.company_presentation_file.split("/");
                            this.setState({ presentationUrl: presentat[presentat?.length - 1] });
                        }

                        if (
                            responseJson?.data?.attributes?.debt_arranger_companies_id != 0 &&
                            responseJson?.data?.attributes?.debt_arranger_companies_id !== null
                        ) {
                            this.setState(
                                {
                                    debtArrangerCompanyName: responseJson?.data?.attributes?.debt_arranger_companies_id,
                                    companyNameisDisable:true,
                                    debtArrangerName: responseJson?.data?.attributes?.deal_debt_arrangers_id,
                                },
                                () => {
                                    this.callGetFilterArranger();
                                }
                            );
                        }
                        this.setState({ spinner: false });
                    }

                    if (this.getCurrentUserInfo === apiRequestCallId) {
                        this.setState({
                            currentUserRoleName: responseJson?.data?.attributes?.role_name,
                            currentUserInfo: responseJson?.data?.attributes,
                        });
                        if (responseJson?.data?.attributes?.role_name === Role_name.Arranger) {
                            if (responseJson?.data) {
                                this.setState({
                                    debtArrangerCompanyName: responseJson?.data?.attributes?.company_id,
                                    debtArrangerName: responseJson?.data?.id,
                                    arrangerCompanyName: responseJson?.data?.attributes?.company_name,
                                    arrangerName: responseJson?.data?.attributes?.full_name,
                                });
                            }
                        }

                        if (responseJson?.data?.attributes?.role_name === Role_name.Seeker) {
                            this.setState({ debtSeeker: responseJson?.data?.attributes?.company_name });
                        }
                    }
                    if (this.getSaveAsDraftDataUpdateId === apiRequestCallId) {
                        this.setState({ spinner: false });
                        if (responseJson?.data) {
                            const msg20: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                            msg20.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                            this.send(msg20);
                        } else {
                            this.setState({ redirect: false });
                        }
                    }
                }
            }
        }
    }

    getDebtSeekerCompany = async () => {
            this.setState({ checkingPanLoading: true });
            this.getDebtSeekerCompanyId = callApi({
                method: "GET",
                endPoint: `account_block/account_user_roles/get_seeker_details?pan_card=${this.state.isUserName}`,
            });
    };

    inviteArranger = async () => {
        this.setState({ inviteArrangerLoading: true });
        const { inviteEmail, inviteName, invitePhone, currentUserInfo } = this.state;
        const id = localStorage.getItem("deal_id");

        let formdata = new FormData();
        formdata.append("data[email]", inviteEmail);
        formdata.append("data[name]", inviteName);
        formdata.append("data[phone_number]", invitePhone);
        formdata.append("data[company_name]", currentUserInfo?.company_name);
        const userToken = localStorage.getItem("token");
        const header = {
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendInviteArrangerId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bank_deal/debt_deals/send_invite_arranger?id=${id}`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    inviteIssuer = async () => {
        this.setState({ inviteIssuerLoading: true });
        const { isUserEmail } = this.state;
        let formdata = new FormData();
        formdata.append("data[issuer_email]", isUserEmail);
        const userToken = localStorage.getItem("token");
        const header = {
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
        this.sendInviteIssuerId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bank_deal/debt_deals/send_invite_issuer`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getCurrentUserInformation = () => {
        const userToken = localStorage.getItem("token");
        const header = {
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getCurrentUserInfo = requestMessage.messageId;
        requestMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "account_block/accounts");

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getDebtArrangerCompany = () => {
        this.setState({ debtArrangerCompanyLoading: true });
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.companyOverviewApiContentType,
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.apiGetdebtArrangerId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.debtArrangerCompanyEndpoint
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    callGetFilterArranger = () => {
        if (this.state.debtArrangerCompanyName) {
            this.getFilteredDebtArrangerName();
        }
    };

    getFilteredDebtArrangerName = () => {
        this.setState({ debtArrangerCompanyLoading: true });
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.companyOverviewApiContentType,
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.apiFilterGetdebtArrangerId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bank_deal/debt_arranger?company_id=${this.state.debtArrangerCompanyName}`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    checkIssuerEmailisPresent = (email: any) => {
        const userToken = localStorage.getItem("token");
        const header = {
            "Content-Type": configJSON.companyOverviewApiContentType,
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.checkIssuerMailId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/accounts/find_seeker_by_email?email=${email}`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getSaveAsDraftData = () => {
        const userToken = localStorage.getItem("token");
        const deal_id = localStorage.getItem("deal_id");
        const header = {
            "Content-Type": configJSON.companyOverviewApiContentType,
            token: userToken,
        };

        const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));

        this.getSaveAsDraftDataId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_bank_deal/debt_deals/${deal_id}`
        );

        requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), JSON.stringify(header));

        requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiMethodTypeGet);
        
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleSaveAsDraft = (isDraft: boolean) => {
        const {
            debtSeeker,
            logo,
            video,
            presentation,
            debtArrangerCompanyName,
            debtArrangerName,
            companyBackground,
            isUserName,
            isUserEmail,
            isErrorEmail,
            isPan,
            delete_logo_image,
            delete_video,
            delete_presentation,
        } = this.state;
        if (isErrorEmail || isPan) {
            const msg21: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg21.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid input..");
            this.send(msg21);
        } else if (debtArrangerCompanyName && !debtArrangerName) {
            const msg22: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg22.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill Debt Arranger Name.');
            this.send(msg22);
        } else {
            const userToken = localStorage.getItem("token");
            const header = {
                token: userToken,
            };
            let formdata = new FormData();
            isUserName && formdata.append("data[issuer_company_pan]", isUserName);
            if (isUserEmail && this.state.currentUserRoleName && this.state.currentUserRoleName !== Role_name.Seeker) {
                formdata.append("data[issuer_email]", isUserEmail);
            }
            debtSeeker && formdata.append("data[debt_seeker_company_name]", debtSeeker);
            logo && formdata.append("data[upload_logo_image]", logo);
            video && formdata.append("data[upload_video]", video);
            companyBackground && formdata.append("data[company_background]", companyBackground);
            presentation && formdata.append("data[company_presentation_file]", presentation);
            debtArrangerCompanyName && formdata.append("data[debt_arranger_companies_id]", debtArrangerCompanyName);
            debtArrangerName && formdata.append("data[deal_debt_arrangers_id]", debtArrangerName);
            formdata.append("is_draft", isDraft + "");
            delete_logo_image && formdata.append("delete_logo_image", "true");
            delete_video && formdata.append("delete_video", "true");
            delete_presentation && formdata.append("delete_presentation", "true");
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.createAddDealForSaveAsDraft = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.endPointApiPostUserAddDealBySeeker
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.apiPostMethod);
            this.setState({ spinner: true });
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };

    handleSaveAsDraftUpdate = (isDraft: boolean) => {
        const {
            debtSeeker,
            logo,
            video,
            presentation,
            debtArrangerCompanyName,
            debtArrangerName,
            companyBackground,
            isUserName,
            isUserEmail,
            isErrorEmail,
            isPan,
            delete_logo_image,
            delete_video,
            delete_presentation,
        } = this.state;
        const deal_id = localStorage.getItem("deal_id");
        if (isErrorEmail || isPan) {
            const msg23: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg23.addData(getName(MessageEnum.CustomAlertBodyData), '"Please enter valid input.');
            this.send(msg23);
        } else if (debtArrangerCompanyName && !debtArrangerName) {
            const msg24: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg24.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill Debt Arranger Name.');
            this.send(msg24);
        } else {
            const userToken = localStorage.getItem("token");
            const header = {
                token: userToken,
            };
            let formdata = new FormData();
            formdata.append("data[issuer_company_pan]", isUserName);
            this.state.currentUserRoleName ? this.state.currentUserRoleName !== Role_name.Seeker && formdata.append("data[issuer_email]", isUserEmail) : '' 
            formdata.append("data[debt_seeker_company_name]", debtSeeker);
            formdata.append("data[upload_logo_image]", logo);
            formdata.append("data[upload_video]", video);
            formdata.append("data[company_background]", companyBackground);
            formdata.append("data[company_presentation_file]", presentation);
            formdata.append("data[debt_arranger_companies_id]", debtArrangerCompanyName);
            formdata.append("data[deal_debt_arrangers_id]", debtArrangerName);                          
            formdata.append("is_draft", isDraft + "");
            delete_logo_image && formdata.append("delete_logo_image", "true");
            delete_video && formdata.append("delete_video", "true");
            delete_presentation && formdata.append("delete_presentation", "true");
            const requestMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
            this.getSaveAsDraftDataUpdateId = requestMessage.messageId;

            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                `bx_block_bank_deal/debt_deals/${deal_id}`
            );

            requestMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage), header);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestBodyMessage), formdata);
            requestMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage), "PUT");
            this.setState({ spinner: true });
            runEngine.sendMessage(requestMessage.id, requestMessage);
        }
    };
}
// Customizable Area End
