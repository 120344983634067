import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import moment from 'moment'
export interface EndUse {
    id: string;
    type: "string";
    attributes: any;
}
export interface TypeOfInstument {
    id: string;
    type: "string";
    attributes: any;
}
export interface Sector {
    id: string;
    type: "string";
    attributes: any;
}
export interface SubSector {
    id: string;
    type: "string";
    attributes: any;
}
export interface CreditRatingAgency {
    id: string;
    type: "string";
    attributes: any;
}
export interface Rating {
    id: string;
    type: "string";
    attributes: any;
}
export interface RatingOutlook {
    id: string;
    type: "string";
    attributes: any;
}
export interface PrincipalRepaymentFrequency {
    id: string;
    type: "string";
    attributes: any;
}
export interface InterestPaymentFrequency {
    id: string;
    type: "string";
    attributes: any;
}
export interface RatingProps {
    id?: number
    creditRatingAgency: string,
    rating: string;
    ratingOutLook: string;
    rating_date:string;
    so: boolean;
    _destroy: boolean;
    [key: string]: any;
}

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match: any;
    location: any;
    history: any;
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    typeOfFunding: string;
    endUseList: EndUse[];
    endUse1: string;
    typeOfInstrument: string;
    typeOfInstrumentList: TypeOfInstument[];
    sector: string;
    sectorList: Sector[];
    subSector: string;
    subSectorList: SubSector[];
    yield: string;
    couponRate: string;
    totalDealSizeIn_INR_cr: string;
    totalDealSizeIn_INR: string;
    miniMum_investment_per_investor: string;
    miniMum_investment_per_investor_INR: string;
    isRatingAvailable: string;
    ratingArray: string[];
    creditRatingAgency: string;
    credit_rating_agencyList: CreditRatingAgency[];
    ratingObj: RatingProps;
    rating: string;
    ratingListContainer: RatingProps[];
    ratingDetailsList: Rating[];
    ratingOutLook: string;
    rating_outlookList: RatingOutlook[];
    so: boolean;
    principalRepaymentFrequency: string;
    principal_repayment_frequencyList: PrincipalRepaymentFrequency[];
    interestPaymentFrequency: string;
    interest_payment_frequencyList: InterestPaymentFrequency[];
    isCallOption_available: string;
    call_option_details: string;
    isPutOptionAvailable: string;
    put_option_details: string;
    Repayment_Start_Date: string;
    Repayment_Maturity_Date: string;
    Repayment_Start_Date2: string;
    Repayment_Maturity_Date2: string;
    Proposed_Total_Tenor: string;
    uploadFinSummary: any;
    isError: boolean;
    isMobileError: boolean;
    deal_id: string;
    isYeildError: boolean;
    isCouponError: boolean;
    is_draft: boolean;
    is_loading: boolean;
    goto_next: boolean;
    path: string;
    isReadMode: boolean;
    isNext: boolean;
    redirect: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class ProposedInstrumentDetailsController extends BlockComponent<Props, S, SS> {


    getProductApiCallId: any;
    // Customizable Area Start
    endUserApiCallId: string = "";
    typeOfInstumentApiCallId: string = "";
    sectorApiCallId: string = "";
    subSectorApiCallId: string = "";
    principleRepaymentFrequencyApiCallId: string = "";
    interestPaymentFrequencyApiCallId: string = "";
    creditRatingAgencyApiCallId: string = "";
    ratingOutLookApiCallId: string = "";
    ratingDetailsApiCallId: string = "";
    saveAsDraftApiCallId: string = "";
    UploadFinSummaryApiCallId: string = "";
    showProposedInstrumentDetailsApiCallId: string = "";
    updatesaveAsDraftApiCallId: string = "";
    submit_proposed_instrument_details_ApiCallId: string = "";
    update_submit_proposed_instrument_details_ApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.redirectMessage),
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            // Customizable Area Start
            typeOfFunding: "Primary",
            endUseList: [],
            endUse1: "",
            typeOfInstrument: "",
            typeOfInstrumentList: [],
            sector: "",
            sectorList: [],
            subSector: "",
            subSectorList: [],
            is_draft: true,
            credit_rating_agencyList: [],
            yield: "",
            couponRate: "",
            totalDealSizeIn_INR_cr: "",
            totalDealSizeIn_INR: '',
            miniMum_investment_per_investor_INR: '',
            so: false,
            miniMum_investment_per_investor: "",
            isRatingAvailable: "Yes",
            ratingArray: [],
            rating: "",
            creditRatingAgency: "",
            ratingObj: { creditRatingAgency: "", rating: "", ratingOutLook: "",rating_date:'', so: true, _destroy: false },
            ratingListContainer: [{ creditRatingAgency: "", rating: "", ratingOutLook: "", rating_date:'' ,so: true, _destroy: false },],
            ratingDetailsList: [],
            ratingOutLook: "",
            rating_outlookList: [],
            principalRepaymentFrequency: "",
            principal_repayment_frequencyList: [],
            interestPaymentFrequency: "",
            interest_payment_frequencyList: [],
            isCallOption_available: "Yes",
            isPutOptionAvailable: "Yes",
            call_option_details: "",
            put_option_details: "",
            Repayment_Start_Date: "",
            Repayment_Maturity_Date: "",
            Repayment_Start_Date2: "",
            Repayment_Maturity_Date2: "",
            Proposed_Total_Tenor: "",
            uploadFinSummary: "",
            isError: false,
            isMobileError: false,
            deal_id: "",
            isYeildError: false,
            isCouponError: false,
            is_loading: false,
            goto_next: false,
            path: "ProposedInstrumentDetails",
            isReadMode: false,
            redirect: false,
            isNext:false,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {
        // Customizable Area Start
        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        if (message.id === getName(MessageEnum.redirectMessage)) {
            const customRedirect = message.getData(getName(MessageEnum.redirectData));
            if (this.state.redirect && customRedirect) {
                this.props.history.push("/Catalogue/StructureandSecurity")
            }
        }
        if (apiRequestCallId && responseJson) {
            if (apiRequestCallId === this.endUserApiCallId) {
                this.setState({ endUseList: responseJson.data.data });
            }
            if (apiRequestCallId === this.typeOfInstumentApiCallId) {
                this.setState({ typeOfInstrumentList: responseJson.data.data });
            }
            if (apiRequestCallId === this.sectorApiCallId) {
                this.setState({ sectorList: responseJson.data.data });
            }
            if (apiRequestCallId === this.subSectorApiCallId) {
                this.setState({ subSectorList: responseJson.data.data });
            }
            if (apiRequestCallId === this.creditRatingAgencyApiCallId) {
                this.setState({ credit_rating_agencyList: responseJson.data.data });
            }
            if (apiRequestCallId === this.ratingDetailsApiCallId) {
                this.setState({ ratingDetailsList: responseJson.data.data });
            }
            if (apiRequestCallId === this.ratingOutLookApiCallId) {
                this.setState({ rating_outlookList: responseJson.data.data });
            }
            if (apiRequestCallId === this.principleRepaymentFrequencyApiCallId) {
                this.setState({ principal_repayment_frequencyList: responseJson.data.data });
            }
            if (apiRequestCallId === this.interestPaymentFrequencyApiCallId) {
                this.setState({ interest_payment_frequencyList: responseJson.data.data });
            }

            if (apiRequestCallId === this.saveAsDraftApiCallId) {
                responseJson?.data?.data?.id && localStorage.setItem("proposedInstrumentDetails_id", responseJson?.data?.data?.id)
                if (responseJson?.meta?.message){
                    const msg33: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg33.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                    this.send(msg33);
                    return;
                }
                if (responseJson?.errors){
                    const msg34: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg34.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.error);
                    this.send(msg34);
                    return;
                }

                this.showProposedInstrumentDetailsData()
            }
            if (apiRequestCallId === this.updatesaveAsDraftApiCallId) {
                if (responseJson?.data) {
                    this.setState({ is_loading: false });
                    const msg35: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg35.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                    this.send(msg35);
                }
                else {
                    if (responseJson?.errors[0]?.proposed_total_tenor){
                        const msg36: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                        msg36.addData(getName(MessageEnum.CustomAlertBodyData), `Proposed total tenor ${responseJson?.errors[0]?.proposed_total_tenor}`);
                        this.send(msg36);
                    }
                }
                /* istanbul ignore next */
                this.showProposedInstrumentDetailsData()

            }
            if (apiRequestCallId === this.submit_proposed_instrument_details_ApiCallId) {
                if (responseJson?.data) {
                    localStorage.setItem("proposedInstrumentDetails_id", responseJson?.data?.data?.id)
                    const msg37: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg37.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                    this.send(msg37);
                    this.setState({ redirect :true})
                }
                else {
                    let errorMessage = "";
                    responseJson?.errors?.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg38: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg38.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg38);
                    this.setState({ redirect: false })
                }
            }
            if (apiRequestCallId === this.update_submit_proposed_instrument_details_ApiCallId) {

                if (responseJson?.data) {
                    const msg39: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg39.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
                    this.send(msg39);
                    this.setState({ redirect: true })
                }
                else {
                    let errorMessage = "";
                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })

                    const msg40: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg40.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg40);
                    this.setState({ redirect: false })

                }
            }

            /* istanbul ignore next */
            if (apiRequestCallId === this.showProposedInstrumentDetailsApiCallId) {
                if (responseJson?.data) {
                    responseJson?.data?.id && localStorage.setItem("proposedInstrumentDetails_id", responseJson?.data?.id)

                    const tempRatingArray: RatingProps[] = [];

                    responseJson?.data?.attributes?.rating_details?.data.map((rating: any, index: any) => {
                        let tempRatingObj: any = {
                            id: rating?.id,
                            creditRatingAgency: rating?.attributes?.rating_credit_rating_agency?.id,
                            rating: rating?.attributes?.rating_details_rating?.id,
                            ratingOutLook: rating?.attributes?.rating_details_rating_outlook?.id,
                            rating_date: rating?.attributes?.rating_date == null ? '' : rating.attributes.rating_date,
                            so: rating?.attributes?.so,
                            _destroy: false
                        }

                        tempRatingArray.push(tempRatingObj);
                    })
                    this.setState({ ratingListContainer: tempRatingArray })

                    let totalDeal_Size_INR = (responseJson?.data?.attributes?.total_deal_size)


                    let proposedTenor = responseJson?.data?.attributes?.repayment_maturity_date && this.calculateTotalTenor(responseJson?.data?.attributes?.repayment_start_date,responseJson?.data?.attributes?.repayment_maturity_date);
                    this.setState({ Proposed_Total_Tenor: proposedTenor })

                    this.setState({
                        couponRate: responseJson?.data?.attributes?.coupon_rate,
                        yield: responseJson?.data?.attributes?.yield,
                        typeOfFunding: responseJson?.data?.attributes?.type_of_funding,
                        put_option_details: responseJson?.data?.attributes?.put_option_details,
                        miniMum_investment_per_investor: responseJson?.data?.attributes?.minimum_investment_per_investor,
                        isCallOption_available: responseJson?.data?.attributes?.is_call_option_available === 'Yes' ? "Yes" : "No",
                        isPutOptionAvailable: responseJson?.data?.attributes?.is_put_option_available === 'Yes' ? "Yes" : "No",
                        isRatingAvailable: responseJson?.data?.attributes?.is_rating_available === 'Yes' ? "Yes" : "No",
                        call_option_details: responseJson?.data?.attributes?.call_oftion_details,
                        endUse1: responseJson?.data?.attributes?.proposed_end_use?.id,
                        typeOfInstrument: responseJson?.data?.attributes?.proposed_type_of_instrument?.id,
                        sector: responseJson?.data?.attributes?.proposed_sector?.id,
                        subSector: responseJson?.data?.attributes?.proposed_sub_sector?.id,
                        principalRepaymentFrequency: responseJson?.data?.attributes?.principal_repayment_frequency?.id,
                        interestPaymentFrequency: responseJson?.data?.attributes?.interest_payment_frequency?.id,
                        Repayment_Start_Date2: responseJson?.data?.attributes?.repayment_start_date,
                        Repayment_Start_Date: responseJson?.data?.attributes?.repayment_start_date,
                        Repayment_Maturity_Date2: responseJson?.data?.attributes?.repayment_maturity_date,
                        totalDealSizeIn_INR_cr: totalDeal_Size_INR,
                        totalDealSizeIn_INR: totalDeal_Size_INR
                    })
                }
                else {
                    localStorage.setItem("proposedInstrumentDetails_id", "")
                }

            }
        }

        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
        }

        if (
            getName(MessageEnum.RestAPIResponceMessage) === message.id &&
            this.getProductApiCallId != null &&
            this.getProductApiCallId ===
            message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
        ) {
            var responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && !responseJson.errors && responseJson.data) {
                this.setState({ arrayHolder: responseJson.data });
                runEngine.debugLog("arrayHolder", this.state.arrayHolder);
            } else {
                var errorReponse = message.getData(
                    getName(MessageEnum.RestAPIResponceErrorMessage)
                );
                this.parseApiCatchErrorResponse(errorReponse);
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined): Promise<void> {
        if (prevState.sector !== this.state.sector) {
            this.getSubSector()
        }
    }


    getEndUser() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.endUserApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.endUse_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }


    getProposedInstrument() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.typeOfInstumentApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.proposed_instument_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }


    getSector() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.sectorApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.sector_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    getSubSector() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.subSectorApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments/proposed_sub_sectors?proposed_sector_id=${parseInt(this.state.sector)}`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }


    getCreditRatingAgency() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.creditRatingAgencyApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.credit_rating_agency_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }


    getRatingDetails() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ratingDetailsApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.rating_details_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }



    getRatingOutLook() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.ratingOutLookApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.rating_details_outlok_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }




    getPrincipleRepaymentFrequency() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            token: token,
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.principleRepaymentFrequencyApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.principle_frequency_payment_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    getInterestPaymnetFrequency() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.productApiContentType,
            token: token,
        };

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.interestPaymentFrequencyApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.interest_frequency_payment_endPoinApi
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    returnPayload = () => {
        const data = {
            type_of_funding: this.state.typeOfFunding,
            yield: this.state.yield,
            coupon_rate: this.state.couponRate,
            total_deal_size: this.state.totalDealSizeIn_INR,
            minimum_investment_per_investor: this.state.miniMum_investment_per_investor,
            is_call_option_available: this.state.isCallOption_available,
            call_oftion_details: this.state.call_option_details,
            is_put_option_available: this.state.isPutOptionAvailable,
            put_option_details: this.state.put_option_details,
            repayment_start_date: this.state.Repayment_Start_Date2,
            repayment_maturity_date: this.state.Repayment_Maturity_Date2,
            proposed_total_tenor: this.state.Proposed_Total_Tenor,
            is_rating_available: this.state.isRatingAvailable,
            proposed_end_use_id: this.state.endUse1,
            proposed_sector_id: this.state.sector,
            proposed_sub_sector_id: this.state.subSector,
            proposed_type_of_instrument_id: this.state.typeOfInstrument,
            interest_payment_frequency_id: this.state.interestPaymentFrequency,
            principal_repayment_frequency_id: this.state.principalRepaymentFrequency,
        }
        return data;
    }

    getDataOfProposed(){
        const formateRatingList = this.state.ratingListContainer.map((rating) => {   
            const data: any = {
                rating_credit_rating_agency_id:rating.creditRatingAgency ? parseInt(rating.creditRatingAgency) :'',
                rating_details_rating_id: rating.rating ? parseInt(rating.rating) : '',
                rating_details_rating_outlook_id: rating.ratingOutLook ? parseInt(rating.ratingOutLook) : '',
                rating_date: rating.rating_date ? rating.rating_date : '',
                so: rating.so ? String(rating.so) : '',
            }

            if (rating.id) {
                data.id = rating.id
            }
            data._destroy = rating._destroy

            return data
        })
        const payload = this.returnPayload()
        const data = {
            ...payload,
            rating_details: this.state.isRatingAvailable === 'No' ? [] : formateRatingList.length > 0 ? formateRatingList : []
        }
        return data;
    }

    postDataSaveAsDraft() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.contentTypeApiContentType,
            "token": token,
        };
        var data = this.getDataOfProposed();
        const httpBody = {
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`,
            is_draft: this.state.is_draft

        }

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveAsDraftApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }



    updateSaveAsDraft() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.contentTypeApiContentType,
            "token": token,
        };

        var data = this.getDataOfProposed();
        const httpBody = {
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`,
            is_draft: this.state.is_draft
        }

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updatesaveAsDraftApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments/?id=${localStorage.getItem("proposedInstrumentDetails_id")}`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }


    showProposedInstrumentDetailsData() {
        let token = localStorage.getItem("token")
        const headers = {
            "token": token,
        };
        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showProposedInstrumentDetailsApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("proposedInstrumentDetails_id")}`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    submitProposed_instrument_details() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.contentTypeApiContentType,
            "token": token,
        };
        var data = this.getDataOfProposed();
        const httpBody = {
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`,
            is_draft: !this.state.is_draft
        }

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.submit_proposed_instrument_details_ApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    update_submitProposed_instrument_details() {
        let token = localStorage.getItem("token")
        const headers = {
            "Content-Type": configJSON.contentTypeApiContentType,
            "token": token,
        };
        var data = this.getDataOfProposed();
        const httpBody = {
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`,
            is_draft: !this.state.is_draft
        }

        const getendUserMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.update_submit_proposed_instrument_details_ApiCallId = getendUserMsg.messageId;

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `account_block/proposed_instruments/?id=${localStorage.getItem("proposedInstrumentDetails_id")}`
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody),
        );

        getendUserMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
    }

    removeRatingHandler = (ratingIndex: any) => {
        const ratingList: any = [...this.state.ratingListContainer];

        ratingList[ratingIndex]._destroy = true

        this.setState({ ratingListContainer: ratingList, isNext: true })

    }

    calculateTotalTenor = (repayment_start_date: any, repayment_maturity_date: any) => {
        let date1 = moment(repayment_maturity_date).startOf('day');
        let date2 = moment(repayment_start_date).endOf('day');
        let years = date1.diff(date2, 'year');
        let months = date1.diff(date2, 'month') - years * 12;
        let days = date1.diff(date2.add(years, 'year').add(months, 'month'), 'days');

        if (days <= 0) {

            if (date1.get('date') !== date2.get('date') && days === 0) {
                days = 1
            } else {
                days = 0
            }
        } else {
            days += 1
        }

        if (months < 0) {
            months = 0
        }

        if (years < 0) {
            years = 0
        }

        return `${years}yrs ${months}m and ${(days)}d`   
    }
    // Customizable Area End
}
