import React from 'react'
import { Button, makeStyles } from "@material-ui/core";


const useStyles = makeStyles(theme => ({
    root: {
        margin: theme.spacing(0.5)
    },
    label: {
        textTransform: 'none'
    }
}))

type ButtonProps = {
    text: string;
    size: string;
    color: string;
    variant: string;
    onClick: any;

}

export default function CustomButton(props: ButtonProps) {

    const { text, size, color, variant, onClick } = props
    const classes = useStyles();


    return (
        <Button>
            variant={variant || "contained"}
            size={size || "large"}
            color={color || "primary"}
            onClick={onClick}

            classes={{ root: classes.root, label: classes.label }}
            {text}
        </Button>
    )
}