// Customizable Area Start
const OtpReducer = (state: any, { type, payload }: any) => {
    switch (type) {
        case 'UPDATE_OTP_DATA':
            return payload

        case 'BLOG_DATA':
            return payload
        case 'CITY_DATA':
            return payload
        case 'ABOUT_US':
            return payload
            case 'CHAT_DATA':
                return payload    

        default:
            return state;
    }

};

export default OtpReducer;
 // Customizable Area End
