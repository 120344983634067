import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import "./mobileAccountLogin.css";
import MobileAccountLoginController, { Props } from "./MobileAccountLoginController";
import { backgroundImage } from './assets';
import { logo } from './assets';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import OtpInput from "../../../components/src/OtpInput";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';


const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #c4c4c4"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #c4c4c4"
      }
    }
  }
})(TextField);
// Customizable Area End

export default class MobileAccountLoginBlock extends MobileAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  typeOfLabel = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({
      tabIndexValue: index
    });
  };
  move = (e: any, prev: string, current: string, next: string) => {
    let length = (document.getElementById(current) as HTMLInputElement)?.value?.length;
    let maxLength = document.getElementById(current)?.getAttribute("maxLength");

    let otp1 = (document.getElementById('text1') as HTMLInputElement)?.value || '';
    let otp2 = (document.getElementById('text2') as HTMLInputElement)?.value || '';
    let otp3 = (document.getElementById('text3') as HTMLInputElement)?.value || '';
    let otp4 = (document.getElementById('text4') as HTMLInputElement)?.value || '';

    if (String(length) == maxLength) {
      if (next !== "") {
        document.getElementById(next)?.focus()
      }
    }
    if (e.key === "Backspace") {
      if (prev !== "") {
        document.getElementById(prev)?.focus()
      }
    }

    this.setState({
      otp1, otp2, otp3, otp4
    });

  };
  getOtpValue1 = (e: any) => {
    this.setState({
      otp1: e.target.value
    });
  };
  getOtpValue2 = (e: any) => {
    this.setState({
      otp2: e.target.value
    });
  };
  getOtpValue3 = (e: any) => {
    this.setState({
      otp3: e.target.value
    });
  };
  getOtpValue4 = (e: any) => {
    this.setState({
      otp4: e.target.value
    });
  };
  getMobileNumber = (e: React.ChangeEvent<any>) => {
    let phone = e.target.value;
    const regex = /[0-9]$/;
    phone.length === 12 || phone.length === 10
      ? this.setState({ isErrorPhone: false })
      : this.setState({ isErrorPhone: true, sendOtp: false });
    if (regex.test(e.target.value) || e.target.value == '') {
      this.setState({
        mobileNo: e.target.value
      })
    }
  };
  validateOtp = () => {
    const { otp1, otp2, otp3, otp4 } = this.state;
    return Boolean(otp1 && otp2 && otp3 && otp4)
  }
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container className="grid_parent">
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
          <Alert severity="success">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} open={this.state.snackbarOpenError} autoHideDuration={4000} onClose={this.handleCloseError} >
          <Alert severity="error">
            {this.state.snackbarMessage}
          </Alert>
        </Snackbar>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="baground">
          <img src={logo} alt="logo" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="inner_grid">
          <Box className="parent_box">
            <Typography id="MuiTypography-root">Welcome</Typography>
            <AppBar position="static" className="app_bar_mobile">
              <Tabs
                indicatorColor="primary"
                value={this.state.tabIndexValue}
                onChange={this.typeOfLabel}
                variant="fullWidth"
              >
                <Tab
                  label="Login with Mobile"
                  id="tab"
                />
                <Tab
                  label="Login with Email"
                  id="tab"
                />
              </Tabs>
            </AppBar>
            {this.state.tabIndexValue == 0 ? (
              <Box className="parent_box_textfield">
                <Box className="box">
                  <CssTextField
                    variant="outlined"
                    id="outlined-required"
                    label="Phone number"
                    required
                    type="text"
                    size="small"
                    value={this.state.mobileNo}
                    onChange={(e: React.ChangeEvent<any>) => { this.getMobileNumber(e) }}
                    fullWidth
                    placeholder="000 000 0000"
                    style={{
                      borderColor: 'black'
                    }}
                    inputProps={{
                      maxlength: "10",
                      style: { WebkitBoxShadow: "0 0 0 1000px white inset", }
                    }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.mobileNo && this.state.mobileNo.length === 10 && !this.state.sendOtp && (
                            <Link href="#" id="link" onClick={() => {
                              this.sendOtp();
                            }}>
                              Request OTP

                            </Link>
                          )}

                        </InputAdornment>
                      ),
                    }}
                  />
                  {this.state.isErrorPhone && <Typography id="error">Please Enter Valid Phone Number</Typography>}
                </Box>
                {
                  this.state.sendOtp ?
                    <>
                      <Box className="box-login-with-mobile" mt='5px' mb='15px' >
                        <InputLabel>
                          A 4 digit code has been sent to the number{" "}
                          {this.state.mobileNo}. Enter the code to continue
                        </InputLabel>
                      </Box>
                      <Box className="box-login-with-mobile">
                        <Grid container spacing={2}>
                          <Grid item md={6}>
                            <Box className="otp_box">
                              <OtpInput
                                id="text1"
                                onKeyUp={(e: any) => this.move(e, "", "text1", "text2")}
                              // onChange={(e:any)=>{this.getOtpValue1(e.target.value)}}
                              />

                              <OtpInput
                                id="text2"
                                onKeyUp={(e: any) => this.move(e, "text1", "text2", "text3")}
                              // onChange={(e:any)=>{this.getOtpValue2(e.target.value)}}
                              />

                              <OtpInput
                                id="text3"
                                onKeyUp={(e: any) => this.move(e, "text2", "text3", "text4")}
                              // onChange={(e:any)=>{this.getOtpValue3(e.target.value)}}
                              />

                              <OtpInput
                                id="text4"
                                onKeyUp={(e: any) => this.move(e, "text3", "text4", "")}
                              // onChange={(e:any)=>{this.getOtpValue4(e.target.value)}}
                              />

                            </Box>
                          </Grid>
                          <Grid item md={6} style={{ 'display': 'flex' }} >

                            <Box className="forgot_password_mobile_box">
                              <InputLabel className="otp_resend_label">
                                Didn't receive code ?
                                <Link href="#" className="forgot_password_link" underline="always" onClick={() => {
                                  this.resendOtp();
                                }} style={{ marginLeft: 10 }}>
                                  Resend Code
                                </Link>
                              </InputLabel>
                            </Box>
                          </Grid>
                        </Grid>
                      </Box>
                    </>
                    :
                    ''
                }
                <Box className="forgot_password_mobile_box">
                  <InputLabel className={this.state.sendOtp ? "forgot_password_mobile_link" : "forgot_password_mobile_link_with_otp_box"}>
                    {/* <Link
                      href="ForgotPassword"
                      className="forgot_password_mobile_link"
                    >
                      Forgot Password?
                    </Link> */}
                  </InputLabel>
                </Box>
                <Box className="forgot_password_mobile_box">
                  <Button
                    disabled={!this.validateOtp()}
                    variant="contained"
                    id="button"
                    style={{
                      backgroundColor: `${(this.validateOtp()) ? 'darkblue' : ''}`
                    }}
                    onClick={this.doMobileLogIn}
                  >
                    {this.state.loadingSignin ? <CircularProgress /> : "Login"}
                  </Button>
                </Box>

                <InputLabel className="dont_have_account_label">
                  Dont't have an account?
                  <Link
                    href="EmailAccountRegistration"
                    id="sign_up_link"
                  >
                    Sign Up
                  </Link>
                </InputLabel>

              </Box>
            ) : (
              this.props.navigation.navigate("EmailAccountLoginBlock")
            )}
          </Box>
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
