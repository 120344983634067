//Customize Area Start
import React from 'react';
import {
    Grid,
    Dialog,
    DialogTitle,
    Typography,
    DialogContent,
    Box,
    TextField,
    Divider,
} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { IOngoingDealHistoryResponse } from './DealDashboardController.web';
import { showTextWithValidation } from '../../../components/src/common';

type Props = {
    dealsHistoty: IOngoingDealHistoryResponse;
    closeDrawer: any;
}

function DealHistoryModal(props: Props) {
    const { dealsHistoty, closeDrawer } = props;
    return (
        <Dialog
            open={true}
            className="allocate_deal_popup"
        >
            <DialogTitle className='ongoing_deal_popup_title'>
                <ArrowBackIosIcon className="back-icon" onClick={closeDrawer} />
                <Typography className="ongoin-deal-card-heading">
                    Debt Seeker Dashboard- Deal History
                </Typography>
            </DialogTitle>
            <DialogContent>
                <Grid container style={{ marginBottom: '33px' }}>
                    <Grid item lg={4}>
                        <Grid container>
                            <Grid item lg={4}>
                                <Typography
                                    className='deal_history_title'
                                >
                                    Project Name:
                                </Typography>
                            </Grid>
                            <Grid item lg={4}
                                className="deal_history_value"
                            >
                                {showTextWithValidation(dealsHistoty.project_name)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={4}
                                className='deal_history_title'
                            >
                                Debt Seeker Name:
                            </Grid>
                            <Grid item lg={4}
                                className="deal_history_value"
                            >
                                {showTextWithValidation(dealsHistoty.seeker_name)}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item lg={6}>
                        <Grid container>
                            <Grid item lg={3}
                                className='deal_history_title'
                            >
                                Instrument Type:
                            </Grid>
                            <Grid item lg={3}
                                className="deal_history_value"
                            >
                                {showTextWithValidation(dealsHistoty.instrument_type)}
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item lg={3}
                                className='deal_history_title'
                            >
                                Maturity Date:
                            </Grid>
                            <Grid item lg={3}
                                className="deal_history_value"
                            >
                                {showTextWithValidation(dealsHistoty.maturity_date)}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid className='grid_history_list_box'>
                    <Grid container xs={12} >
                        <Grid item container spacing={3} style={{ paddingBottom: 20 }} >
                            <Grid item lg={2} md={2} sm={2}>
                                <Typography className='deal_history_header_text'>
                                    Date
                                </Typography>
                            </Grid>
                            <Grid item lg={3} md={3} sm={2}>
                                <Typography className='deal_history_header_text'>
                                    Event Type
                                </Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                                <Typography className='deal_history_header_text'>
                                    Amount (INR Cr)
                                </Typography>
                            </Grid>
                            <Grid item lg={2} md={2} sm={2}>
                                <Typography className='deal_history_header_text'>
                                    Yield
                                </Typography>
                            </Grid>
                            <Grid item lg={3} md={2} sm={2}>
                                <Typography className='deal_history_header_text'>
                                    Remark
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid container xs={12} >
                        {dealsHistoty.data.length > 0 ? dealsHistoty.data.map((history, index) => (
                            <Grid key={`dealhistory${index}`} item container spacing={3}>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Box className='history_values_list_box'>
                                        <Typography className='deal_histoty_values_text'>
                                            {showTextWithValidation(history.attributes.date)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={2}>
                                    <Box className='history_values_list_box'>
                                        <Typography className='deal_histoty_values_text'>
                                            {showTextWithValidation(history.attributes.event_type)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Box className='history_values_list_box'>
                                        <Typography className='deal_histoty_values_text'>
                                            {showTextWithValidation(history.attributes.amount)}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={2} md={2} sm={2}>
                                    <Box className='history_values_list_box'>
                                        <Typography className='deal_histoty_values_text'>
                                        {`${showTextWithValidation(history.attributes.yield)} %`}
                                        </Typography>
                                    </Box>
                                </Grid>
                                <Grid item lg={3} md={3} sm={3}>
                                    <Box className='history_values_list_box'>
                                        <Typography className='deal_histoty_values_text'>
                                            {showTextWithValidation(history.attributes.remark)}
                                        </Typography>
                                    </Box>
                                </Grid>
                            </Grid>
                        )) : <p> No Data Available</p>}

                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    );
}
export default DealHistoryModal;
//Customize Area End