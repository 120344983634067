import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GlobalContext } from "../../catalogue/src/useContext";

interface product {
  id: string;
  type: string;
  attributes: any;
}
interface alternative_investment_of_future {
  id: string;
  title: string;
  content: string;
}

interface debtType {
  id: string;
  type: string;
  attributes: any;
}
interface aboutUs {
  id: string;
  path: string;
  title: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  openProducts: any;
  openAboutUs: null;
  debtTypeList: debtType[];
  productsList: string[];
  productsList2: product[];
  products: string[];
  aboutUsList: string[];
  termAndConditions: string[];
  privacyPolicy: string[];
  aboutUsArray: aboutUs[];
  list_of_alternative_investment_array: alternative_investment_of_future[];
  openSwitch: boolean;
  user_role: string;
  current_role: string;
  carrerFullName: string;
  carrerEmail: string;
  carrerPhone: any;
  carrerGraduation: any;
  errors: any;
  isErrorName: boolean;
  isErrorEmail: boolean;
  isErrorGraduation: boolean;
  isErrorPhone: boolean;
  blogsData: any;
  carrerCityData: any;
  selectCity: any;
  selectedCity: any;
  role: any;
  city_id: any;
  file: any;
  imgUrl: any;
  imgError: boolean;
  logo: any;
  isNext: boolean;
  delete_logo_image: boolean;
  buttonCheck: boolean;
  snackbarOpen: any;
  snackbarMessage: any;
  snackbarOpenError: boolean;
  snackbarMessageError: any;
  loadingSignin: boolean;
  isErrorSelectCity: boolean;
  isErrorResume: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class LandingPageController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  static contextType = GlobalContext
  productListApiCalledId: string = "";
  termAndConditionApiCalledId: string = "";
  privacyPolicyApiCalledId: string = "";
  debtTypeApiCalledId: string = "";
  getCurrentUserRoleNameId: string = "";
  blogsApiCalledId: string = "";
  carrerApiCityCalledId: string = "";
  carrerApiFormCalledId: string = "";
  aboutUsCalledId: string = "";
  currentRole:any={ '1':'Debt Seeker',
                    '2':'Debt_Provider',
                    '3':'Debt_Arranger',  }
  // Customizable Area End


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage)
    ];

    this.state = {
      openProducts: null,
      current_role: '',
      openAboutUs: null,
      productsList: ["FinMart Trade", "FinMart Monitor", "FinMart Invest"],
      productsList2: [],
      user_role: '',
      debtTypeList: [],
      products: ["FinMart Invest", "FinMart Monitor", "FinMart Trade",],
      aboutUsList: ["About Finmart", "Team", "Advisors"],
      aboutUsArray: [{ id: "1", path: "AboutFinmart", title: "About FinMart" }, { id: "2", path: "Team", title: "Team" }, { id: "3", path: "Advisors", title: "Advisors" }],
      termAndConditions: [
        "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English.",
      ],
      privacyPolicy: [],
      openSwitch: false,
      list_of_alternative_investment_array: [{ id: "1", title: "Secured Assets", content: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" },
      { id: "2", title: "The Compounding Factor", content: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" },
      { id: "3", title: "High Yield", content: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" },
      { id: "4", title: "Complete Transparency", content: "It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout" }],
      carrerFullName: "",
      carrerEmail: "",
      carrerPhone: "",
      carrerGraduation: "",
      errors: "",
      isErrorName: false,
      isErrorEmail: false,
      isErrorPhone: false,
      blogsData: "",
      carrerCityData: "",
      selectCity: "",
      selectedCity: "",
      role: "",
      city_id: 0,
      file: null,
      imgUrl: null,
      imgError: false,
      logo: "",
      isNext: false,
      delete_logo_image: false,
      isErrorGraduation: false,
      buttonCheck: true,
      snackbarOpen: false,
      snackbarMessage: "",
      snackbarOpenError: false,
      snackbarMessageError: "",
      loadingSignin: false,
      isErrorSelectCity: false,
      isErrorResume:false,
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  
  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userToken = localStorage.getItem("token");
    const id = localStorage.getItem("role_id");
    this.setState({current_role:this.currentRole[Number(id)]})
    userToken && this.getCurrentRoleName()
    this.getProductsList();
    this.getDebtType();
    this.getCarrerCity()
    this.getBlogs()
    this.getAboutUs()
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.productListApiCalledId) {
          this.setState({ productsList2: responseJson.data })
        }

        if (apiRequestCallId === this.termAndConditionApiCalledId) {
          this.setState({
            termAndConditions: responseJson.data.attributes.description,
          });
        }
        if (apiRequestCallId === this.privacyPolicyApiCalledId) {
          this.setState({
            privacyPolicy: responseJson.data.attributes.description,
          });
        }
        if (apiRequestCallId === this.debtTypeApiCalledId) {
          this.setState({
            debtTypeList: responseJson.dept_types.data
          });
        }
        if (apiRequestCallId === this.getCurrentUserRoleNameId) {
          // this.setState({ current_role: responseJson?.data?.attributes?.role_name })
          if (responseJson?.data?.attributes?.role_name === 'Sub_Admin') {
            this.props.history.push("/catalogue/CompanyOverview")
          }
        }
      }

      if (this.blogsApiCalledId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
        const apiResponse = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
        if (apiResponse) {
          await this.context.BlogsDispatch({ type: 'BLOG_DATA', payload: apiResponse?.data })
        }
      }
    }

    if (this.carrerApiCityCalledId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        this.setState({ carrerCityData: apiResponse?.data })
        await this.context.CarrerCityDispatch({ type: 'CITY_DATA', payload: apiResponse?.data })

      }
    }
    if (this.carrerApiFormCalledId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.setState({ loadingSignin: false })

        this.setState({ snackbarOpen: true, snackbarMessage: "Submitted Sucessfully" })

        this.setState({carrerFullName:"",carrerEmail:"",carrerPhone:"",carrerGraduation:"",selectCity:"",file:null})
      } else {
        this.setState({ loadingSignin: false })

        this.setState({ snackbarOpenError: true, snackbarMessageError: apiResponse?.errors[0] })
      }
    }
    if (this.aboutUsCalledId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse) {
        await this.context.AboutUsStateDispatch({ type: 'ABOUT_US', payload: apiResponse?.data })
      }
    }

    // Customizable Area End
  }

  // Customizable Area Start

  getDebtType() {
    const headers = {
      "Content-Type": configJSON.getProductListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.debtTypeApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.debtTypeAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  getProductsList() {
    const headers = {
      "Content-Type": configJSON.getProductListApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.productListApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.productListAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getTermsAndCondition() {
    const headers = {
      "Content-Type": configJSON.getTermAndConditionApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.termAndConditionApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.privacyTermAndConditionAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getCurrentRoleName() {
    const userToken = localStorage.getItem("token");
    const header = {
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getCurrentUserRoleNameId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), 'account_block/accounts');

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPrivacyPolicy() {
    const headers = {
      "Content-Type": configJSON.getPrivacyPolicyApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.privacyPolicyApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.privacyPolicyAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  
    ///About us api calling start///
    getAboutUs() {
      const headers = {
        "Content-Type": configJSON.getPrivacyPolicyApiContentType,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.aboutUsCalledId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.aboutUs);
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    ///Aboutus api calling end///
  
  
  ///Blog api calling start///
  getBlogs() {
    const headers = {
      "Content-Type": configJSON.getPrivacyPolicyApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.blogsApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.blogs);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///Blog api calling end///


  ///CARRIR CITY GET API CALL///

  getCarrerCity() {
    const headers = {
      "Content-Type": configJSON.getPrivacyPolicyApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.carrerApiCityCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.carrerApiCities);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage), configJSON.carrerApiCitiesMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  ///Blog api calling end///


  ///CARRER CITY GET API CALL///
  ///CARRER CITY FORM POST API CALL///
  doEmailLogIn(): boolean {
    const header = {
      "Content-Type": configJSON.loginApiContentType,
    };
    let formdata = new FormData();

    formdata.append("data[name]", this.state.carrerFullName);
    formdata.append("data[city_id]", this.state.selectCity);
    formdata.append("data[phone_no]", this.state.carrerPhone);
    formdata.append("data[email]", this.state.carrerEmail.toLowerCase());
    typeof (this.state.file) != 'string' && formdata.append("data[resume]", this.state.file);
    formdata.append("data[education]", this.state?.carrerGraduation);

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.carrerApiFormCalledId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.carrerApi
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.carrerApiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }
  ///CARRER CITY FORM POST API CALL///



  
  handleNameChange = (e: any) => {
    const regex = /^[a-zA-Z.][a-zA-Z. ]*$/

    let carrerFullName = e.target.value;
    carrerFullName.length > 25 || carrerFullName.length < 2 || carrerFullName.length === 0
      ? this.setState({ isErrorName: true })
      : this.setState({ isErrorName: false });
    if (regex.test(carrerFullName) || carrerFullName == '') {
      this.setState({ carrerFullName });
    } else {
      this.setState({ isErrorName: true })
    }

  };
  handleGraduationChange = (e: any) => {
    const regex = /^[a-zA-Z.][a-zA-Z. ]*$/

    let carrerGraduation = e.target.value;
    carrerGraduation.length > 25 || carrerGraduation.length === 0 || carrerGraduation.length < 2
      ? this.setState({ isErrorGraduation: true })
      : this.setState({ isErrorGraduation: false });
    if (regex.test(carrerGraduation) || carrerGraduation == '') {
      this.setState({ carrerGraduation });
    } else {
      this.setState({ isErrorGraduation: true })
    }

  };
  handleEmailChange = (e: any) => {
    let carrerEmail = e.currentTarget.value;
    

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = carrerEmail.split("@");

    !regex.test(carrerEmail)
      || emailArray[0].length > 64 ||
      emailArray[1].length > 256
      ?
      this.setState({ isErrorEmail: true })
      : this.setState({ isErrorEmail: false });

    this.setState({ carrerEmail: e.currentTarget.value });

  };
  handlePhoneChange = (e: any) => {
    const regex = /[0-9]$/;
    let carrerPhone = e.target.value.substr(0, 10);
    carrerPhone.length === 10
      ? this.setState({ isErrorPhone: false })
      : this.setState({ isErrorPhone: true });
    if (regex.test(carrerPhone) || carrerPhone == '') {
      this.setState({ carrerPhone: carrerPhone });
    } else {
      this.setState({ isErrorPhone: true })
    }


  };

  handleRole = (e: any) => {
    let selectCity = e.target.value
    selectCity == "" ? this.setState({ isErrorSelectCity: false })
      : this.setState({ isErrorSelectCity: true });


    this.setState({ selectCity: e.target.value });
  };


  onFileChange = (event: any) => {

    const image = event.target.files[0];
    if (!(/\.(gif|jpe?g|tiff?|png|webp|bmp)$/i).test(image?.name)) {
      this.setState({ file: event.target.files[0], delete_logo_image: false })
      this.setState({ imgError: false, isNext: true })
    } else {
      this.setState({ imgError: true, isNext: true })

    }

  };
  onSubmitForm = () => {
    if ((!this.state.isErrorName && !this.state.isErrorGraduation && !this.state.isErrorPhone && !this.state.isErrorEmail && this.state.selectCity != "" && this.state.file != null)) {
      this.doEmailLogIn();
    }
    else {
    }
  }

  handleClosesnack = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpen: false })
  };
  handleCloseError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenError: false })
  };
  // Customizable Area End
}
