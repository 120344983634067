import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, isAuthorisedUser, isIssuer, isLoggedIn } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface IManageDealObject{
  id: number;
  deal_debt_arrangers_id: number;
  debt_arranger_companies_id: string;
  issuer_email?: any;
  issuer_company_pan?: any;
  debt_seeker_company_name: string;
  company_background: string;
  review_status: string;
  proposed_instruments?: {
    instrument: string;
    yield: string;
    proposed_total_tenor: string;
    sector: string;
    expected_returns_gross: string;
    total_deal_size: string;
    minimum_investment: string;
    repayment_frequency: string;
    repayment_maturity_date: string;
    rating_details: null | string;
  };
  submitted_by: string;
  time_stamp: string;
  deal_status: string;
  upload_logo_image?: {
    id: number;
    url: string;
  };
  upload_video?: any;
  should_delete: string;
  company_presentation_file?: any;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  match: any;
  location: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  innerWidth:any;
  isLoading: boolean;
  dealsList: null | IManageDealObject[];
  isDeletePopup: boolean;
  deleteDealId: number;
  isIssuer: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ManageDealController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  authToken: string = "";
  getManageDealsListApiCallId: string = "";
  deleteDealApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      innerWidth:'',
      isLoading: true,
      dealsList: null,
      isDeletePopup: false,
      deleteDealId: 0,
      isIssuer: true
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  
  updateWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const isAuthorized = isAuthorisedUser(responseJson);
      if(!isAuthorized){
        this.props.history.push("/EmailAccountLoginBlock");
        return;
      }
      this.setState({
        isLoading: false
      })
      /* istanbul ignore next */
      if (this.getManageDealsListApiCallId === apiRequestCallId) {
        if(responseJson.errors){
          let res15 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0015: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0015.addData(getName(MessageEnum.CustomAlertBodyData), res15);
          this.send(msg0015);
          this.setState({
            dealsList: []
          })
        }
          /* istanbul ignore next */
        else if (responseJson && responseJson.data?.length > 0) {
          this.setState({
            dealsList: responseJson.data.map((deal: any) => deal.attributes)
          })
        }
        /* istanbul ignore next */
        else if(responseJson  && responseJson.meta){
          const msg209: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg209.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg209);
          this.setState({
            dealsList: []
          })
        }
      } else if (this.deleteDealApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg210: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg210.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg210);
        } else if(responseJson.success){
          const msg211: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg211.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.success);
          this.send(msg211);
          this.getManageDeals();
          this.setState({
            isDeletePopup: false
          })
        }
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const authToken = isLoggedIn(this.props.navigation);
    if(!isIssuer()){
      this.setState({
        isIssuer: false
      })
    }
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getManageDeals();
    }
    this.getManageDeals();
    window.addEventListener("resize", this.updateWidth);
  }

  getManageDeals() {
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getDeals = apiCall({
      url: configJSON.getManageDealsListApiEndpoint ,
      header,
      httpBody: {},
      httpMethod: configJSON.getManageDealsListApiMethod
    });

    this.getManageDealsListApiCallId = getDeals.messageId;
    runEngine.sendMessage(getDeals.id, getDeals);
  }

  deleteDeal = () => {
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getDeals = apiCall({
      url: configJSON.deleteDealApiEndpoint + `/${this.state.deleteDealId}` ,
      header,
      httpBody: {},
      httpMethod: configJSON.deleteDealApiMethod
    });

    this.deleteDealApiCallId = getDeals.messageId;
    runEngine.sendMessage(getDeals.id, getDeals);
  }

  handleAddOrEditDealButtonClick = (dealId: string = '') => {
    localStorage.removeItem("deal_id");
    localStorage.removeItem("proposedInstrumentDetails_id");
    localStorage.removeItem("security_id");
    localStorage.removeItem("projectDetails_id");
    localStorage.removeItem("fin_summary_id");
    localStorage.removeItem("debtProfile_id");
    localStorage.removeItem("supportive_id");
    localStorage.removeItem("manage_deal_edit");
    if(dealId.length > 0 ){
      localStorage.setItem("deal_id", dealId );
    }
  }

  handleDeleteClick = (dealId: number) =>{
    this.setState({
      isDeletePopup: true,
      deleteDealId: dealId
    })
  };

  handleDeleteCancelClick = () => {
    this.setState({
      isDeletePopup: false
    })
  }
  // Customizable Area End
}
