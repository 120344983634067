import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { GlobalContext } from "../../../blocks/catalogue/src/useContext";
import { callApi } from "../common";
const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();

// Customizable Area End

export const configJSON = require("../../../blocks/Chat9/src/config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  dialogId:any;
  handleDialog:()=>void
  dealName:string
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  open :boolean,
  inputMessage:any,
  allMessages : [],
  file :any,
  chatCredentials :any;
  usersQuickbloxIds :any;
  usersEmailIds : any;
  quickbloxIds : any;
  dialogId :any;
  isLoading:boolean;
  fullName:'';
  opponentFullName:any
  chatBox:boolean

  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ChatQuickbloxController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = GlobalContext
  chatCredentialsApiCalledId : string = '';
  chatCredentials:any=''
  
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
 
    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      open : true,
      inputMessage: '',
      allMessages :[],
      file :'',
      chatCredentials : '',
      usersQuickbloxIds:[],
      usersEmailIds:[],
      quickbloxIds:[],
      dialogId : this.props.dialogId,
      isLoading : true,
      fullName:'',
      opponentFullName:'',
      chatBox: true

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    const userEmail= localStorage.getItem('email')
    const chatEmail= localStorage.getItem('chatEmail')

    if(QB.chat && userEmail===chatEmail ){
      let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(this.props.dialogId);
      QB.chat.muc.join(dialogJid, (error: any, result: any) => { })
      this.handleMessageHistory()
      QB.chat.onMessageListener = this.onMessage
    }
    else{
      const chatData = {method:'GET',payLoad:'', endPoint:configJSON.chatCredentialsAPiEndPoint}
      this.chatCredentials = callApi(chatData)
    }
    
    // Customizable Area End
  }


  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

  
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      if (apiRequestCallId && responseJson) {
       
        if(this.chatCredentials===apiRequestCallId){
          this.context.ChatDispatch({ type: 'CHAT_DATA', payload:responseJson.data[0].attributes  })
          this.handleGetChatUser()
        }
        
      }
      
    
    }


    // Customizable Area End
  }
  // Customizable Area Start
  handleOpenChatBox = ()=>{
    this.setState({open : true})
    this.handleGetChatUser()

  }

  
  handleCloseChatBox = ()=>{
    this.setState({chatBox:!this.state.chatBox})

  }

  handleSelectedFile = (e:any)=>{
    let file = e.target.files[0]
    this.setState({file : file})
}

handleUploadFile = ()=>{
  const {file} = this.state
  let fileParams = {
    name: file.name,
    file: file,
    type: file.type,
    size: file.size,
    public: false
  };
  
  QB.content.createAndUpload(fileParams, async(error:any, result:any)=> {
    if (!error) {
      // prepare a message
      
      let message = {
        type:  "groupchat" ,
        body: `${fileParams.name}`,
        extension: {
          save_to_history: 1,
          dialog_id: this.props.dialogId,
          attachments: [{ id: result.uid, type: "image" }],
          senderName:localStorage.getItem('fullName')
        }
      };
     
  let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(this.state.dialogId);
  QB.chat.muc.join(dialogJid, (error: any, result: any) => { })
  
    await QB.chat.send(dialogJid, message);
    
   this.setState({inputMessage:"", file : ''})
  

    }
    else{
      const chatCredentialsData = {method:'GET',payLoad:'', endPoint:configJSON.chatCredentialsAPiEndPoint}
      this.chatCredentials = callApi(chatCredentialsData)
    }
  });
}

params = {
  email: localStorage.getItem('email'),
  password:configJSON.someSecret
};

handleGetChatUser =  async() => {
  const {application_id,auth_key,auth_secret,accout_key} = this.context.ChatState && this.context.ChatState
  QB.init(application_id,auth_key , auth_secret, accout_key);
await  QB.createSession((error: any, result: any) => {
    console.log('session result',result)
    
    
   QB.login(this.params, (error: any, result: any) => {
      console.log('login result',result)
      localStorage.setItem('id', result.id)
      localStorage.setItem('fullName',result?.full_name)
      localStorage.setItem('chatEmail',result?.email)
      this.setState({fullName: result.full_name})

      
      const userCredentials = {
        userId: result.id,
        password: configJSON.someSecret
      }
      
      QB.chat.connect(userCredentials, (error: any, contactList: any) => {

        if(contactList){
          let dialogJid=QB.chat.helpers.getRoomJidFromDialogId(this.props.dialogId)
          QB.chat.muc.join(dialogJid,(error:any,result:any)=>{})
        }
        
      });
       
       this.handleMessageHistory() 
      QB.chat.onMessageListener = this.onMessage
     
    })
  })
}

handleSendMessage = async()=>{

  if(this.state.file){
    this.handleUploadFile()
    return
  }

  if(this.state.inputMessage===''||this.state.inputMessage?.trim().length===0){
    return
  }

  let message = {
    type: "groupchat",
    body: `${this.state.inputMessage}`,
    
    extension: {
      save_to_history: 1,
      dialog_id: this.props.dialogId,
      senderName:localStorage.getItem('fullName')
    },
    markable: 1
  };
  
  let dialogJid = QB.chat.helpers.getRoomJidFromDialogId(this.props.dialogId);
  QB.chat.muc.join(dialogJid, (error: any, result: any) => { })
  
     QB.chat.send(dialogJid, message);
     this.setState({inputMessage:""})
  
}

onMessage = (userId:any, message:any)=>{
  
  this.handleMessageHistory()
  
}

handleDownloadFile=(id:any)=>{
  let fileUID = id
  let fileUrl = QB?.content?.privateUrl(fileUID);
  return fileUrl
}

handleMessageHistory = ()=>{
  let params = {
    chat_dialog_id:this.props.dialogId,
    sort_asc: 'date_sent',
    limit: 200,
    skip: 0,
    mark_as_read:0
  };
  
  QB.chat.message.list(params, (error:any, messages:any)=> {

    if(!error){
      
      this.setState({allMessages:messages?.items,isLoading:false},()=>{
        setTimeout(() => {
          this.handleScrollToBottom()
          
        }, 50);
      })
    }
    else{
      if(error.message?.errors?.base[0]==='Required session does not exist'){
        
        const chatCredsData = {method:'GET',payLoad:'', endPoint:configJSON.chatCredentialsAPiEndPoint}
        this.chatCredentials = callApi(chatCredsData)
        
      }
      
    }

      
    });
}

getChatCredentials() {
  const userToken = localStorage.getItem("token");
  console.log(userToken)
  
  const headers = {
    contentType: configJSON.validationApiContentType,
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.chatCredentialsApiCalledId = requestMessage.messageId;

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
     configJSON.chatCredentialsAPiEndPoint);

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "GET"
  );
  runEngine.sendMessage(requestMessage.id, requestMessage);
}

handleScrollToBottom = ()=>{
  let scroll_to_bottom = document.getElementById('chat-content');
  
  if(scroll_to_bottom){
   scroll_to_bottom.scrollTop = scroll_to_bottom.scrollHeight;
    
  }
          
}

closePopUp = ()=>{
  this.setState({chatBox:!this.state.chatBox})
}

handleMessageInput= (e:any)=>{
  console.log('inputMessage',e.target.value)
  this.setState({inputMessage:e.target.value})

}

getSessionDetails = ()=>{
  QB.getSession(function (error:any, session:any) {
    if(!error){
      console.log('sessionDetails',session)
    }
    else{
      console.log('error in currentUserSession',error)
    }
  })
}



  // Customizable Area End
}