import React from 'react';
// Customizable Area Start
import {
  Grid,
  Tooltip,
  InputLabel,
  Typography,
  Box,
  FormControl,
  IconButton,
  MenuItem,
  Select
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import AddBoxIcon from '@material-ui/icons/AddBox';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import SupportiiveDocumentController, { Props } from "./SupportiveDocumentController";
import { Close } from '@material-ui/icons';
import { withRouter } from "react-router-dom";
import { handleReviewSubmitClick } from '../../../components/src/common';


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export class SupportiveDocuments extends SupportiiveDocumentController {
  constructor(props: Props) {
    super(props)
  }

  render() {
    const { navigation } = this.props;
    return (
      <>
        <div style={{ width: '100%' }}>
          <Grid container alignItems="center">
            <Grid item xs={12} style={{ marginBottom: "32px" }}>
              <Typography  className="header">Supportive Documents</Typography>
            </Grid>
            <Grid
              item
              container
              spacing={1}
              className="supportive_grid_container"
              alignItems="center"
            >
              <Grid item xs={2}>
                <InputLabel className="supportive_input_label_heading">
                  Document Name
                </InputLabel>
              </Grid>
              <Grid item xs={2}>
                <InputLabel className="supportive_input_label_heading">
                  Document related to
                </InputLabel>
              </Grid>
              <Grid item xs={2}>
                <InputLabel className="supportive_input_label_heading">
                  Upload Document
                </InputLabel>
              </Grid>
              <Grid item xs={6} >
                <InputLabel className="supportive_input_label_heading">
                  Remarks (Optional)
                </InputLabel>
              </Grid>
              {this.state.SupportiveDocumentInputObjArray.map(
                (SupportiveDocumentInput: any, index: number) => (
                  !SupportiveDocumentInput.is_deleted && <React.Fragment key={index}>
                    <Grid item xs={2}>
                      <FormControl variant='outlined' fullWidth size="small">
                        <Select

                          disabled={this.state.isReadMode}
                          data-test-id = "select-drop"
                          MenuProps={{
                            PaperProps: {
                              style: {
                                borderRadius: "8px",
                                width: "inherit",
                              },
                            },
                          }
                          }
                          name="document_name"
                          disableUnderline
                          // native
                          IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'relative', right: 12, pointerEvents: 'none' }} />}
                          value={SupportiveDocumentInput.document_name}
                          onChange={(e: any) =>
                            this.handleAddMoreData(e, index)
                          }
                          placeholder='-Select one-'

                          className="supportive_select_Input"
                        >
                          {/* <option value="" style={{ padding: '20px 20px' }}>-Select one-</option> */}
                          <MenuItem style={{ display: "none", color: 'darkgray' }} value="0"> <span style={{ color: "darkgray" }}>-Select One-</span></MenuItem>

                          {this.state.documentNameList.map(
                            (document1: any, index: any) => (

                              <MenuItem
                                className='supportive_document_menuItem'
                                value={document1.id}
                                key={document1.id}
                                style={{}}
                              >
                                {document1.attributes.name}{" "}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <FormControl fullWidth variant='outlined' size="small">
                        <Select
                          data-test-id = "document-related-id"
                          disabled={this.state.isReadMode}
                          MenuProps={{
                            PaperProps: {
                              style: {
                                borderRadius: "8px",
                                width: "inherit",
                              },
                            },
                          }
                          }
                          name="document_related_to"
                          disableUnderline
                          // native
                          IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'relative', right: 12, pointerEvents: 'none' }} />}
                          value={SupportiveDocumentInput.document_related_to}
                          onChange={(e: any) =>
                            this.handleAddMoreData(e, index)
                          }
                          placeholder='-Select one-'
                          className="supportive_select_Input"
                        >
                          {/* <option value="">-Select one-</option> */}
                          <MenuItem style={{ display: "none", color: 'darkgray' }} value="0"> <span style={{ color: "darkgray" }}>-Select One-</span></MenuItem>

                          {this.state.DocumentRelatedTypeList.map(
                            (document, index) => (
                              <MenuItem className='supportive_document_menuItem' value={document.id} key={`document_related_to_${document.id}`}>
                                {document.attributes.name}
                              </MenuItem>
                            )
                          )}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={2}>
                      <div className="supportive_add_fileInput1">
                        <p className="supportive_upload_p">
                          {SupportiveDocumentInput?.document?.name ||
                            SupportiveDocumentInput?.fileName || 'Upload document'}

                        </p>
                        <input
                          disabled={this.state.isReadMode}
                          type="file"
                          id={`customInput${index}`}
                          style={{ display: "none" }}
                          name="upload_Document"
                          placeholder='Upload document'
                          data-test-id = "upload-doc"
                          // onChange={(e) => { this.getFile(e) }}
                          onChange={(e: any) => {
                            this.documentUploaded(
                              e,
                              index,
                              SupportiveDocumentInput
                            );
                          }}
                        />

                        {SupportiveDocumentInput?.document?.name ||
                          SupportiveDocumentInput?.fileName ? (
                            <button data-test-id = "remove-button" style={{ cursor: 'pointer', margin: 10 }} className="remove_button" disabled={this.state.isReadMode} onClick={(e) => this.removeDocumentHandler(e, index)} >X </button>
                        ) : (
                          <label
                            style={{ marginBottom: "5px", cursor: 'pointer' }}
                            htmlFor={`customInput${index}`}
                          >
                            <AddCircleOutlineOutlinedIcon className="supportive_upload_icon" />
                          </label>
                        )}

                      </div>
                    </Grid>
                    <Grid item xs={6} className='flex_center'>
                    <HtmlTooltip title = {SupportiveDocumentInput.Remarks} placement = "bottom-end" arrow>
                      <textarea
                        disabled={this.state.isReadMode}
                        name="Remarks"
                        data-test-id = "remark-support"
                        value={SupportiveDocumentInput.Remarks}
                        onChange={(e: any) => this.handleAddMoreData(e, index)}
                        rows={1}
                        cols={10}
                        className = "catalog_input_box_remarks"
                        style={{color:'black',width:'100%'}}
                      />
                     </HtmlTooltip>
                    {index !== 0 && <>
                      <IconButton disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent' }} onClick={(e) => this.handleDeleteRow(e, index)}>
                        <Close />
                      </IconButton>

                    </>}
                    </Grid>
                  </React.Fragment>
                )
              )}
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Box className="inputLabel">
              <button
                disabled={this.state.isReadMode}
                className="addRow_button"
                id="add_Button"
                onClick={e => this.handleAddMoreInput(e)}
              >
                Add row <AddBoxIcon style={{ marginLeft: "8px" }} />
              </button>
            </Box>
          </Grid>
          <Grid item xs={12}>
            
                     {!this.state.isReadMode && <Box className="supprotive_button">
                        <button data-test-id = "save_b" onClick={()=>this.saveAsDraft(false)} className="save_as_draft_button" >
                            Save as Draft
                        </button>

                        <button data-test-id = "next_b" className="next_button" onClick={()=>{this.handleSubmit()}}>
                            Next
                        </button>
                      </Box>}

                {this.state.isReadMode && <Box
                className="submit_button_wrapper"
              ><button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button">Next</button></Box>}
          </Grid>
          <Grid item xs={2} />
        </div>
      </>
    );
  }
}

export default withRouter(SupportiveDocuments) as any;
export const HtmlTooltip:any = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(16),
    border: "1px solid #dadde9"
  },
  arrow : {
      color : "#E2DFD2" 
  }
}))(Tooltip);
// Customizable Area End
