import { IBlock } from "../../../framework/src/IBlock";
// Customizable Area Start

import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { checkForReadMode } from "../../../components/src/common";

interface SupportiveDocumentInput {
    document_name: number,
    document_related_to: number,
    document: string,
    Remarks: string,
    id?:string
    _destroy?:boolean;
    fileName?:string;
    [key: string]: any;
}
interface DocumentRelatedType {
    id: string;
    attributes: any;
    type: string;
}
interface DocumentType{
    id: string;
    attributes: any;
    type: string;
}


export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    match: any;
    location: any;
    history : any;
}

interface S {
    
    arrayHolder: any;
    token: string;
    role_id:any,
    redirect:any,
    currentDocumentName: any;
    CurrentDocumentRelatedTo:any;
    SupportiveDocumentInput:any
    document:any;
    documentNameList: any,
    SupportiveDocumentInputObj: SupportiveDocumentInput;
    SupportiveDocumentInputObjArray: SupportiveDocumentInput[];
    uploadDocument:any,
    remarks: "",
    deal_id:any,
    // DocumentNameList: DocumentType[],
    DocumentType: string;
    DocumentRelatedTypeList:DocumentRelatedType[] ,
    DocumentRelatedType:string,
    activeStep:any,
    setActiveStep:any,
    completed:any
    supportive_id:any
    isUpdateDocument:boolean;
    isReadMode: boolean;
    isRedirect : boolean;
}

interface SS {
    id: any;
}

export default class debtProfileController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    saveAndDraftApiCallId: string = "";
    DocumentNameListApiCallId: string = "";
    DocumentRelatedToListApiCallId: any;
    supportiveDocumentGetApiCallId:string="";
    supportivePutApiCallId:string=""

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            getName(MessageEnum.redirectMessage),
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            supportive_id:"",
            role_id:"1",
            redirect:false,
            currentDocumentName: [],
            CurrentDocumentRelatedTo:[],
            SupportiveDocumentInput:[],
           document:"",
            uploadDocument:"",
            documentNameList: [],
            DocumentType:"",
            DocumentRelatedTypeList: [],
            DocumentRelatedType:"",
            deal_id:"",
            remarks: "",
            SupportiveDocumentInputObj: {
                document: "",
                document_related_to: 0,
                document_name: 0,
                Remarks: ""
            },
            SupportiveDocumentInputObjArray: [{
                document: "",
                document_related_to: 0,
                document_name: 0,
                Remarks: ""
            }],

            activeStep:"",
            setActiveStep:"",
            completed:"",
            isUpdateDocument:false,
            isReadMode: false,
            isRedirect : false
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }

    handleAddMoreInput(e: any) {
        let SupportiveDocumentInputObj = {
          document: "",
          document_related_to: 0,
          document_name: 0,
          Remarks: ""
        }
        this.setState({ SupportiveDocumentInputObjArray: [...this.state.SupportiveDocumentInputObjArray, SupportiveDocumentInputObj] })
      }
    
      handleAddMoreData(e: any, index: number) {
        const updatedSupportiveInputList = this.state.SupportiveDocumentInputObjArray.map((input, i) => index == i ? Object.assign(input, { [e.target.name]: e.target.value }) : input);
        this.setState({ SupportiveDocumentInputObjArray: updatedSupportiveInputList });
      }
    
      handleDeleteRow(e: any, index: number) {
        const updatedSupportiveInputList = this.state.SupportiveDocumentInputObjArray.map((input, i) => index == i ? Object.assign(input, { ["is_deleted"]: true }) : input);
        this.setState({ SupportiveDocumentInputObjArray: updatedSupportiveInputList });
      }    

    async componentDidMount() {
        super.componentDidMount();
        const isReadMode = checkForReadMode(this.props.location);
        this.setState({
            isReadMode: isReadMode
        })
        this.getToken();
        this.getDocumentNameList();
        this.getDocumentRelatedToList();
        let deal_id = localStorage.getItem("deal_id");
        let roleId = localStorage.getItem("role_id");
        if (deal_id) {
            this.setState({ deal_id: deal_id });
            this.fetchDocument(deal_id);
        } 
        if(roleId){
            this.setState({role_id: roleId})
        }
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };


    

    async receive(from: string, message: Message) {
        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        /* istanbul ignore next */
        if (message.id === getName(MessageEnum.redirectMessage)) {
            const customRedirect = message.getData(getName(MessageEnum.redirectData));
            if (this.state.isRedirect && customRedirect) {
                this.props.history.push("/Catalogue/FAQlist");
            }
        }
        if (responseJson) {
            
            if (responseJson && this.DocumentNameListApiCallId === apiRequestCallId) {
                if(responseJson.data){
                    this.setState({ documentNameList: responseJson.data })
                } else if(responseJson?.errors[0]?.token){
                    const msg62: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg62.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.token);
                    this.send(msg62); 
                    // alert(responseJson?.errors[0]?.token)
                }
                
            }
            if (responseJson && this.DocumentRelatedToListApiCallId === apiRequestCallId) {
                if(responseJson.data){
                    this.setState({ DocumentRelatedTypeList: responseJson.data })
                }
            }
            if (responseJson && this.saveAndDraftApiCallId === apiRequestCallId) {
                if (responseJson?.errors) {
                    let errorMessage = "";
                    this.setState({ isRedirect :false})
                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg63: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg63.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg63); 
                    /* istanbul ignore next */
                    this.setState({ isRedirect :false})
                    // alert(errorMessage)
                    return;
                }else{
                if(responseJson?.meta){
                        const msg64: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                        msg64.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                        this.send(msg64);
                     }
                //  alert(`${responseJson.meta.message}`)
                if(responseJson?.data?.data){
                    this.fetchDocument(this.state.deal_id);
                    this.setState({
                        isUpdateDocument: true
                    })
                    let supportive_id=JSON.stringify(responseJson)
                    localStorage.setItem("supportive_id",supportive_id)
                }
              
            }
                
             } 
            if(responseJson && this.supportiveDocumentGetApiCallId === apiRequestCallId){
                if(responseJson.data){
                    this.setSupportiveDocumentObjArry(responseJson.data)
                }
            }
            if(responseJson && this.supportivePutApiCallId === apiRequestCallId){
                if (responseJson?.errors) {
                    let errorMessage = "";
                    this.setState({ isRedirect: false })

                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg65: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg65.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg65);
                    /* istanbul ignore next */
                    this.setState({ isRedirect: false })
                    return;
                }else{

                    this.fetchDocument(this.state.deal_id);
                    if(responseJson?.meta){
                        const msg66: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                        msg66.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                        this.send(msg66);
                    } 
                    // alert(`${responseJson.meta.message}`)
                }
                
            }
        }



        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
        }

    }



    setSupportiveDocumentObjArry = (documentAray:any) =>{
        let createStateArray:SupportiveDocumentInput[]= []
        documentAray.map((document:any)=>{
            createStateArray.push({
                document_name: document.attributes.document_id,
                document_related_to: document.attributes.related_document_id,
                document: document.attributes.document_name||'',
                Remarks: document.attributes.remarks,
                id:document.id || null,
                fileName:document.attributes.upload_document_name || ''
            })
        })
        this.setState({SupportiveDocumentInputObjArray:createStateArray,isUpdateDocument:true})
        
    }

    
    documentUploaded=(e:any,index:any,SupportiveDocumentInput:any)=>{
        let x=[...this.state.SupportiveDocumentInputObjArray]
          x[index].document=e?.target?.files[0]
          this.setState({SupportiveDocumentInputObjArray:[...x]})
    }

    removeDocumentHandler = (e: any, index: any) => {
        let x = [...this.state.SupportiveDocumentInputObjArray]
        if(x[index]){
        x[index].document = ''
        x[index].fileName = ''
            /* istanbul ignore next */
        x[index]._destroy = true
        }
        this.setState({ SupportiveDocumentInputObjArray: [...x] })
    }
 
    fetchDocument = (deal_id:any) =>{
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token": `${localStorage.getItem("token")}`
          };
      
              const httpBody = {
              "token": `${localStorage.getItem("token")}`
              }
      
              const getValidationsMsg = new Message(
              getName(MessageEnum.RestAPIRequestMessage)
              );
              this.supportiveDocumentGetApiCallId = getValidationsMsg.messageId;
      
              getValidationsMsg.addData(
              getName(MessageEnum.RestAPIResponceEndPointMessage),
              configJSON.supportiveGetApiEndPoint+deal_id
              );
      
              getValidationsMsg.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(headers)
              );
              getValidationsMsg.addData(
              getName(MessageEnum.RestAPIRequestHeaderMessage),
              JSON.stringify(httpBody)
              );
              getValidationsMsg.addData(
              getName(MessageEnum.RestAPIRequestMethodMessage),
              "GET"
              );
              runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        
    }

    validateSupportiveDocumentSubmission = () =>{
        let isValid = true;
        this.state.SupportiveDocumentInputObjArray.forEach((data: any, index: number)=>{
            if(!data.is_deleted && (!data.document || (typeof(data.document) === "string" && data.document.trim().length === 0) || data.document_related_to === 0 || data.document_name === 0 )){
                isValid = false;
                
                return;
            }
        });
        return isValid;
    }
    /* istanbul ignore next */
    handleSubmit=()=>{
        const { SupportiveDocumentInputObjArray } = this.state

        const fieldsToExclude = ['id'];
        const areAllFieldsEmpty = (obj:any) => {
            for (const key in obj) {
                if (!fieldsToExclude.includes(key) && obj[key] !== '' && obj[key] !== null && obj[key] !== 0) {
                    return false; 
                }
            }
            return true;
        };

        const areAllObjectsEmpty = (data:any) => data.every(areAllFieldsEmpty);
        const result = areAllObjectsEmpty(SupportiveDocumentInputObjArray);

        if (!result) {
            this.saveAsDraft(true)
            /* istanbul ignore next */
        } else {
                const msg67: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                msg67.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill all the mandatory fields.");
                this.send(msg67);
            
            // return alert("Please fill all the mandatory fields.")
        }
    }


    //SaveAsDraft
    saveAsDraft = (next:boolean) => {

           next && this.setState({isRedirect:true})
       
        let token = localStorage.getItem("token");
          
        const header = {
            // "Content-Type": configJSON.validationApiContentType,
            "token":token
        };

        var formdata = new FormData();
        this.state.SupportiveDocumentInputObjArray.map((data:any, index: number) => {
            /* istanbul ignore next */
                if(!this.state.isUpdateDocument && data.is_deleted){
                    return false;
                } else if(this.state.isUpdateDocument && data.is_deleted){
                    if(data.id){
                        formdata.append('data[]id',data.id)
                    }
                    data.is_deleted ? formdata.append('data[]_destroy',data.is_deleted) : ''
                    return false;
                }else {
                    data?.id && formdata.append('data[]id', data.id)
                    formdata.append('data[]related_document_id',data.document_related_to)
                    formdata.append('data[]document_id',data.document_name)
                    formdata.append('data[]remarks',data.Remarks)
                    data._destroy && formdata.append("data[]deleted_image",'true')
                    typeof (data.document) !== "string" ? formdata.append('data[]document', data.document) : ''
                }
                
        })
            formdata.append("deal_id", this.state.deal_id);
          !next &&  formdata.append("is_draft", "true");
          next &&  formdata.append("is_draft", "false");
        

            if(this.state.isUpdateDocument){
                // call api put

                const getValidationsMsg = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.supportivePutApiCallId = getValidationsMsg.messageId;
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.supportivePutApiEndPoint
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    formdata
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "PUT"
                );
                runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);



            }else{
                const getValidationsMsg = new Message(
                    getName(MessageEnum.RestAPIRequestMessage)
                );
                this.saveAndDraftApiCallId = getValidationsMsg.messageId;
                const deal_id = localStorage.getItem("deal_id")
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIResponceEndPointMessage),
                    configJSON.SupportiveSaveAsDraftEndPoint + deal_id
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestHeaderMessage),
                    JSON.stringify(header)
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestBodyMessage),
                    formdata
                );
        
                getValidationsMsg.addData(
                    getName(MessageEnum.RestAPIRequestMethodMessage),
                    "POST"
                );
                runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
                // call api add
            }

       

    }

  getDocumentNameList() {

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      "token": `${localStorage.getItem("token")}`
    };

        const httpBody = {
        "token": `${localStorage.getItem("token")}`
        }

        const getValidationsMsg = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
        );
        
        this.DocumentNameListApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getDocumentNameListEndPoint
        );

        getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
        );
        getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(httpBody)
        );
        getValidationsMsg.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
    }


    getDocumentRelatedToList() {
        const headers = {
          "Content-Type": configJSON.validationApiContentType,
          "token": `${localStorage.getItem("token")}`
        };
    
            const httpBody = {
            "token": `${localStorage.getItem("token")}`
            }
    
            const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
            );
            this.DocumentRelatedToListApiCallId = getValidationsMsg.messageId;
    
            getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getDocumentRelatedToListEndPoint
            );
    
            getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
            );
            getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(httpBody)
            );
            getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
            );
            runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
        }

        supportive_show_getapi(deal_id:any) {
            const headers = {
              "Content-Type": configJSON.validationApiContentType,
              "token": `${localStorage.getItem("token")}`
            };
        
                const getValidationsMsg = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
                );
                this.supportiveDocumentGetApiCallId = getValidationsMsg.messageId;
        
                getValidationsMsg.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.supportiveGetApiEndPoint
                );
        
                getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
                );
                getValidationsMsg.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                "GET"
                );
                runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
            }
    /* istanbul ignore next */
            suppoetive_put_api(supportive_id:any) {
                const msg68: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                msg68.addData(getName(MessageEnum.CustomAlertBodyData), supportive_id);
                this.send(msg68);
                // alert(supportive_id)
                let token = localStorage.getItem("token")
                const headers = {
                  "Content-Type": configJSON.validationApiContentType,
                  token: token
                };
            
                const getendUserMsg = new Message(
                  getName(MessageEnum.RestAPIRequestMessage)
                );
                this.supportivePutApiCallId = getendUserMsg.messageId;
            
                getendUserMsg.addData(
                  getName(MessageEnum.RestAPIResponceEndPointMessage),
                  configJSON.FinacialSummaryUpdateApiEndPoint
                );
            
                getendUserMsg.addData(
                  getName(MessageEnum.RestAPIRequestHeaderMessage),
                  JSON.stringify(headers)
                );
            
                getendUserMsg.addData(
                  getName(MessageEnum.RestAPIRequestMethodMessage),
                  "PUT"
                );
                runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
              }
  


    // Customizable Area End
}
