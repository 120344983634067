import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, isAuthorisedUser, isIssuer, isLoggedIn } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  innerWidth:any;
  remarkText: string;
  isLoading: boolean;
  isApproved: boolean;
  isIssuer: boolean;
  activeStep:number;
  isAnySelected: boolean;
  redirect: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class DecisionController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  dealId: string = "";
  authToken: string = "";
  formSubmitApiCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.redirectMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      innerWidth:'',
      remarkText: '',
      isLoading: false,
      isApproved: false,
      isIssuer: false,
      activeStep:2,
      isAnySelected: false,
      redirect: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    if(isIssuer()){
      this.setState({
        isIssuer: true
      })
    }
    if(typeof(authToken) === "string"){
      this.authToken = authToken;
      const dealId = this.props.navigation.getParam("id");
      this.dealId = dealId;
    }

    window.addEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };
  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const cusDir = message.getData(getName(MessageEnum.redirectData));
      if (this.state.redirect && cusDir) {
        this.props.navigation.navigate("ContentManagement", { param: "ApproveDeal" });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      const isAuthorized = isAuthorisedUser(responseJson);
      if(!isAuthorized){
        this.props.navigation.navigate("EmailAccountLoginBlock");
        return;
      }
      this.setState({
        isLoading: false
      });
      /* istanbul ignore next */
      if(responseJson.error){
        const msg205: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg205.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
        this.send(msg205);
        return;
      }
      if (this.formSubmitApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.message){
          const msg206: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg206.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message);
          this.send(msg206);
          if(responseJson.message === "Deal approved" || responseJson.message === "Deal rejected"){
            this.setState({redirect:true})
          }
        }
      }
    }
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  onSubmit = () =>{
    /* istanbul ignore next */
    if(this.state.remarkText.trim().length === 0){
      const msg207: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg207.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter remarks");
      this.send(msg207);
      return;
    }
    /* istanbul ignore next */
    if(!this.state.isAnySelected){
      const msg208: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg208.addData(getName(MessageEnum.CustomAlertBodyData), "Please select Approve/Reject");
      this.send(msg208);
      return;
    }

    const requestBody = {
      review_status: `${this.state.isApproved ? "approved" : "rejected"}`,
      remarks: this.state.remarkText
    }
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const rejectOffer = apiCall({
      url: configJSON.handleSubmitDecisionApiEndpoint + `?id=${this.dealId}`,
      header,
      httpBody: requestBody,
      httpMethod: configJSON.handleSubmitDecisionApiMethod
    });

    this.formSubmitApiCallId = rejectOffer.messageId;
    runEngine.sendMessage(rejectOffer.id, rejectOffer);
  }

  handleRemarkChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      remarkText: e.target.value
    })
  }
  // Customizable Area End
}
