// Customizable Area Start
import React from "react";
import {CustomInputLabel , webStyles} from "./Profile.web";
import {CustomInputField} from '../../../components/./src/./components/./CustomInputField';
import CinForProfileController from "./CinForProfileController";

export default class CinForProfile extends CinForProfileController{
    constructor(props : any){
        super(props);
    }
    render(){
        return(
            <>
                <CustomInputLabel
                    htmlFor={this.props.htmlFor}
                    style={webStyles.marginGrid}
                    required
                    >
                        {this.props.label}
                </CustomInputLabel>
                <CustomInputField
                    id={this.props.cinId}
                    placeholder={this.props.placeholder}
                    variant="outlined"
                    inputProps={this.props.inputProps}
                    value={this.props.value}
                    onKeyDown={this.props.onKeyDown}
                    onChange={this.props.onChange}
                    disabled={this.props.disabled}
                />
            </>
        );
    }
}
// Customizable Area End