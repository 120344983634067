import React from "react";
import {
  Button,
  MenuItem,
  Menu,
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import "./styles.css";

type MenuProps = {
  menuList: string[];
  open: any;
  label: string;
  onClick: any;
  onClose: any;
};
function CustomMenu(props: MenuProps) {
  const { open, label, onClick, onClose, menuList } = props;

  return (
    <>
      <Button
        endIcon={<KeyboardArrowDownIcon />}
        onClick={onClick}
        className="customMenu_button">
        {label}
      </Button>
      <Menu
        className="customMenu_menu"
        open={Boolean(open)}
        onClose={onClose}
        anchorEl={open}
        PaperProps={{
          style: {
            borderRadius: "16px",

          }
        }}
      >
        {menuList.map((item, index) => (
          <a
            key={index}
            href={label === "Products" ? "/#products" : ""}
            className="customMenu_link"
          >
            <MenuItem className="customMenu_menuItem"
              onClick={onClose}>{item} </MenuItem>
              {index !== menuList.length - 1 && <hr style={{borderWidth:"1px 0px 0px", borderColor:"#F4F4F4", borderStyle:"solid", height:"2"}}/>}
          </a>
        ))}
      </Menu>
    </>
  );
}

export default CustomMenu;
