import React from "react";
// Customizable Area Start
import "./proposedInstrumentDetails.css";
import "./style.css";
import {
    Grid, Box, Typography, Radio, RadioGroup, FormControlLabel, Button, Checkbox, Container,
    InputLabel, FormHelperText, IconButton
} from "@material-ui/core"

import {
    Close
} from "@material-ui/icons";
import Controls from "../../../components/src/controls/Controls"
import ProposedInstrumentDetailsController, { Props } from "./proposedInstrumentDetailsController.web";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { withRouter } from "react-router-dom";
import moment from "moment";
import { Message } from "../../../framework/src/Message";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { checkForReadMode, handleReviewSubmitClick } from "../../../components/src/common";
class ProposedInstrumentDetails extends ProposedInstrumentDetailsController {
    constructor(props: Props) {
        super(props)
    }

    onlyNumberRegex = /^\d+$/;

    onChange = (e: any) => {
        var reg = /^0+/gi;
        if (e.target.value.match(reg)) {
            e.target.value = e.target.value.replace(reg, '');
        }
        if (e.target.value < 0) e.target.value = '';
        if (e.target.value > 100) e.target.value = '';
        if ((this.onlyNumberRegex.test(e.target.value) && e.target.value <= 100) || e.target.value === '') {
            this.setState({ yield: e.target.value, isNext: true })
        }
    }
    handleCuponRate(e: any) {
        var reg = /^0+/gi;
        if (e.target.value.match(reg)) {
            e.target.value = e.target.value.replace(reg, '');
        }
        if (e.target.value < 0) e.target.value = '';
        if (e.target.value > 100) e.target.value = '';
        if (this.onlyNumberRegex.test(e.target.value) || e.target.value == '') {
            this.setState({ couponRate: e.target.value, isNext: true })
        }
    }
    handleTotalDealSize(e: any) {
        if (this.onlyNumberRegex.test(e.target.value) || e.target.value == '') {
            let actual_amount = e.target.value;
            this.setState({ totalDealSizeIn_INR: actual_amount })
            this.setState({ totalDealSizeIn_INR_cr: e.target.value, isNext: true })

            if (parseInt(this.state.miniMum_investment_per_investor || '0') > actual_amount) {
                this.setState({ miniMum_investment_per_investor: '' })
            }
        }
    }

    handleInvestmentPerInvester(e: any) {
        /* istanbul ignore next */
        if (this.onlyNumberRegex.test(e.target.value) || e.target.value == '') {
            let min_invest_INR = e.target.value;
            if (parseInt(min_invest_INR) > parseInt(this.state.totalDealSizeIn_INR)) {
                this.setState({ isError: true })
            }
            if (parseInt(min_invest_INR) <= parseInt(this.state.totalDealSizeIn_INR)) {
                this.setState({ isError: false })
                this.setState({ miniMum_investment_per_investor: e.target.value, isNext: true })
            }

            if (e.target.value == '') {
                this.setState({ miniMum_investment_per_investor: e.target.value })
            }


        }

    }
    handleCallOptionDetails(e: any) {
        let mobileNo = e.target.value;
        mobileNo.length === 12 || mobileNo.length === 10 ? this.setState({ isMobileError: false }) : this.setState({ isMobileError: true })
        e.target.value.charAt(0) !== ' ' && this.setState({ call_option_details: mobileNo, isNext: true })
    }
    handlePutOptionDetail(e: any) {
        e.target.value.charAt(0) !== ' ' && this.setState({ put_option_details: e.target.value, isNext: true })
    }

    handleMaturityDate(e: any) {
        this.setState({
            Repayment_Maturity_Date2: e.target.value,
            isNext: true,
            Proposed_Total_Tenor: this.calculateTotalTenor(this.state.Repayment_Start_Date2,e.target.value)
        })
    }

    handleMaturityStartDate(e: any) {
        this.setState({
            Repayment_Start_Date2: e.target.value,
            Repayment_Maturity_Date2: '',
            isNext: true,
            Proposed_Total_Tenor: `0yrs 0m and 0d`
        })
    }

    handleDate=(e:any)=>{
        this.setState({
            // rating_date:e.target.value
        });
    };

    handleProposedTotalTenor(e: any) {
        this.setState({ Proposed_Total_Tenor: e.target.value, isNext: true })
    }
    handleInputSelect(e: any) {
        const { name, value } = e.target;
        if (name === 'sector') {
            this.setState({ ...this.state, [name]: value, isNext: true, subSector: '' }, () => {
                this.getSubSector()
            })
        } else {
            this.setState({ ...this.state, [name]: value, isNext: true })
        }
    }
    handleTypeOfInstrument(e: any) {
        this.setState({ typeOfInstrument: e.target.value, isNext: true })
    }
    handleSector(e: any) {
        this.setState({ sector: e.target.value, subSector: '', isNext: true })
    }
    handleSubSector(e: any) {
        this.setState({ subSector: e.target.value, isNext: true })
    }

    handleAddRatingToRatingList(e: any) {
        let ratingList = {
            creditRatingAgency: "", rating: "", ratingOutLook: "",rating_date:'',so: false, _destroy: false
        }
        this.setState({ ratingListContainer: [...this.state.ratingListContainer, ratingList], isNext: true })
    }

    handleRating(e: any, index: number) {
        const updatedRating = this.state.ratingListContainer?.map((rating, i) => index == i ? Object.assign(rating, { [e.target.name]: e.target.value }) : rating);
        this.setState({ ratingListContainer: updatedRating, isNext: true })
    }
    handleSoCheckBox(index: number, value: boolean) {
        const updatedRating = this.state.ratingListContainer?.map((rating, i) => index == i ? Object.assign(rating, { so: value }) : rating);
        this.setState({ ratingListContainer: updatedRating, isNext: true })
    }

    saveAsDraft(e: any) {
        localStorage.getItem("deal_id") && localStorage.getItem("proposedInstrumentDetails_id") ? this.updateSaveAsDraft() : this.postDataSaveAsDraft()
    }

    
    retunCurrentPayload=()=>{
        const isEmpty = (value: string) => value === '';

        const areAllFieldsEmpty = this.state.ratingListContainer.every(item => {
            const fieldsToCheck = Object.keys(item).filter(key => key !== 'so' && key !== '_destroy');
            return fieldsToCheck.every(field => isEmpty(item[field]));
        });
        const data = this.returnPayload()
        const isPayloadEmpty = Object.values(data).every(value => value === '' || value == null || value == undefined || value === "Yes" || value === "Primary")
        
        if (isPayloadEmpty && areAllFieldsEmpty){
            return true
        }else{
            return false
        }
        
    }
    /* istanbul ignore next */
    async handleNext() {
       const isPayloadEmpty= this.retunCurrentPayload()
        if (!isPayloadEmpty) {
            setTimeout(() => {
                localStorage.getItem("deal_id") && localStorage.getItem("proposedInstrumentDetails_id") ? this.update_submitProposed_instrument_details() : this.submitProposed_instrument_details();
            }, 1000)
        } else {
            const msg72: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg72.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill all the mandatory fields.');
            this.send(msg72);
        }
    }

    async componentDidMount() {
        window.scrollTo(0, 0);
        const isReadMode = checkForReadMode(this.props.location);
        this.setState({
            isReadMode: isReadMode
        })
        this.getEndUser();
        this.getProposedInstrument();
        this.getSector();
        this.getCreditRatingAgency();
        this.getRatingDetails();
        this.getRatingOutLook();
        this.getPrincipleRepaymentFrequency();
        this.getInterestPaymnetFrequency();
        this.showProposedInstrumentDetailsData()
    }

    render() {
        return (
            <>
                <Grid container spacing={2} >
                    <Grid item xs={12} >
                        <Box mb={3} >
                            <Typography className="header">Proposed Instrument Details</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Box >
                            <InputLabel className="label">Type of Funding</InputLabel>
                        </Box>

                    </Grid>
                    <Grid item xs={8} >
                        <RadioGroup
                            style={{ display: "flex", flexDirection: "row" }}
                            value={this.state.typeOfFunding}
                            onChange={(e) => this.setState({ typeOfFunding: e.target.value, isNext: true })}  >
                            <FormControlLabel
                                value="Primary"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="Primary" />
                            <FormControlLabel
                                style={{ marginLeft: "20px" }}
                                value="Secondary"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="Secondary" />
                        </RadioGroup>
                    </Grid>
                    <Grid item xs={4} className="labelStyle"  >
                        <Typography className="label" >End Use</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controls.CustomSelect
                            marginTop={50}
                            name="endUse1"
                            label="End use"
                            options={this.state.endUseList}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            width="75%"
                            borderRadius=""
                            value={this.state.endUse1}
                            onClick=""
                            disabled={this.state.isReadMode}
                        // onClick={() => this.getEndUser()}
                        />
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label">Type of Instrument</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controls.CustomSelect
                            marginTop={50}
                            name="typeOfInstrument"
                            label="Type of Instrument"
                            options={this.state.typeOfInstrumentList}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            value={this.state.typeOfInstrument}
                            width="75%"
                            borderRadius=""
                            // onClick={() => this.getProposedInstrument()}
                            onClick=""
                            disabled={this.state.isReadMode}
                        />
                    </Grid>
                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label">Sector</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controls.CustomSelect
                            marginTop={50}
                            name="sector"
                            label="Sector"
                            options={this.state.sectorList}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            value={this.state.sector}
                            width="75%"
                            borderRadius=""
                            onClick=""
                            disabled={this.state.isReadMode} />
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label">Sub Sector</Typography>
                    </Grid>
                    <Grid item xs={8}>
                        <Controls.CustomSelect
                            marginTop={50}
                            name="subSector"
                            label="Sub Sector"
                            options={this.state.subSectorList || []}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            value={this.state.subSector}
                            width="75%"
                            borderRadius=""
                            onClick={() => this.getSubSector()}
                            disabled={this.state.isReadMode}
                        />
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label" >Yield</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            name="yield"
                            placeholder="%"
                            onChange={(e: any) => this.onChange(e)}
                            value={this.state.yield}
                            // label=""
                            type="text"
                            maxLength={3}
                            disabled={this.state.isReadMode}
                        />
                        {
                            this.state?.yield.length != 0 && parseInt(this.state?.yield) > 100 && <FormHelperText style={{ color: "red" }} >Should be less than 100</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label" >Coupon Rate</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            name="cuponRate"
                            placeholder="%"
                            onChange={(e: any) => this.handleCuponRate(e)}
                            value={this.state.couponRate}
                            type="text"
                            disabled={this.state.isReadMode} />
                        {
                            this.state?.couponRate.length != 0 && parseInt(this.state?.couponRate) > 999 && <FormHelperText style={{ color: "red" }} >Should be less than 1000</FormHelperText>

                        }

                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label">Total Deal Size (INR Cr.)</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            name="yield"
                            placeholder=""
                            onChange={(e: any) => this.handleTotalDealSize(e)}
                            value={this.state.totalDealSizeIn_INR_cr}
                            type="text"
                            maxLength={8}
                            disabled={this.state.isReadMode} />
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label" >Minimum Investment per Investor <br></br> (INR Cr.)</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            name="Minimum_Investment_per_Investor"
                            placeholder=""
                            onChange={(e: any) => this.handleInvestmentPerInvester(e)}
                            value={this.state.miniMum_investment_per_investor}
                            type="text"
                            maxLength={8}
                            disabled={this.state.isReadMode} />
                        {

                            <FormHelperText style={{ color: "#ff5722", marginLeft: 15 }} >Note: Should be less than or equal to Total Deal Size.</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={12} className="labelStyle">
                        <Typography className="ratingDetail">Credit Rating Details</Typography>
                    </Grid>
                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label">Is Rating available ?</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <RadioGroup style={{ display: "flex", flexDirection: "row" }} value={this.state.isRatingAvailable} onChange={(e) => this.setState({ isRatingAvailable: e.target.value, isNext: true })}  >

                            <FormControlLabel
                                value="Yes"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="Yes" />
                            <FormControlLabel
                                style={{ marginLeft: "20px" }}
                                value="No"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        this.state.isRatingAvailable === "Yes" && <Grid item xs={12}>
                            <Typography className="ratingDetail" >Rating Details</Typography>
                        </Grid>
                    }
                    {
                        this.state.isRatingAvailable === "Yes" && <Grid item xs={12}>
                            <Box style={{ background: "rgba(0,29,86,0.03)", padding: "12px", borderRadius: "10px", width: "90%", maxWidth: "831px", display: "flex", justifyContent: "center" }} >
                                <table style={{ width: "100%" }} >
                                    <thead>
                                        <tr>
                                            <th> <Typography className="ratingDetailHeader" >Credit Rating Agency</Typography></th>
                                            <th> <Typography className="ratingDetailHeader">Rating</Typography></th>
                                            <th><Typography className="ratingDetailHeader">Rating Outlook</Typography></th>
                                            <th><Typography className="ratingDetailHeader">Date</Typography></th>
                                            <th><Typography className="ratingDetailHeader" >SO</Typography></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            this.state.ratingListContainer?.map((rating, index) => !rating._destroy && (
                                                <tr key={index} style={{ marginBottom: "20px" }} >
                                                    <td>
                                                        <Controls.CustomSelectAddMore
                                                            disabled={this.state.isReadMode}
                                                            name="creditRatingAgency"
                                                            label=""
                                                            options={this.state.credit_rating_agencyList}
                                                            onChange={(e: any) => this.handleRating(e, index)}
                                                            value={rating.creditRatingAgency}
                                                            // onClick={() => this.getCreditRatingAgency()}
                                                            onClick=""
                                                            width="90%"
                                                            borderRadius="8px" /> </td>
                                                    <td>
                                                        <Controls.CustomSelectAddMore
                                                            disabled={this.state.isReadMode}
                                                            name="rating"
                                                            label=""
                                                            options={this.state.ratingDetailsList}
                                                            onChange={(e: any) => this.handleRating(e, index)}
                                                            value={rating.rating}
                                                            // onClick={() => this.getRatingDetails()}
                                                            onClick=""
                                                            width="90%"
                                                            borderRadius="8px" />   </td>
                                                    <td>
                                                        <Controls.CustomSelectAddMore
                                                            disabled={this.state.isReadMode}
                                                            name="ratingOutLook"
                                                            label=""
                                                            options={this.state.rating_outlookList}
                                                            onChange={(e: any) => this.handleRating(e, index)}
                                                            value={rating.ratingOutLook}
                                                            // onClick={() => this.getRatingOutLook()}
                                                            onClick=""
                                                            width="90%"
                                                            borderRadius="8px" />
                                                    </td>
                                                    <td>
                                                        <input className="catalogue_date"
                                                            disabled={this.state.isReadMode}
                                                            name="rating_date"
                                                            placeholder=""
                                                            onChange={(e: any) => this.handleRating(e,index)}
                                                            value={rating?.rating_date}
                                                            onKeyDown={(e:any) => e.preventDefault()}
                                                            max={moment().format('YYYY-MM-DD')}
                                                            type="date" />
                                                    </td>
                                                    <td>
                                                        <Checkbox disabled={this.state.isReadMode} checked={Boolean(rating.so)} defaultChecked={Boolean(rating.so)} name="so" onChange={(e: any) => this.handleSoCheckBox(index, e.target.checked)} style={{ color: "#001D56" ,marginLeft:'-12%'}} />
                                                    </td>

                                                    {index > 0 && (
                                                        <td>
                                                            <IconButton disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent' }} onClick={() => this.removeRatingHandler(index)} >
                                                                <Close />
                                                            </IconButton>
                                                        </td>
                                                    )}



                                                </tr>
                                            ))
                                        }

                                    </tbody>
                                </table>
                            </Box>
                            <button disabled={this.state.isReadMode} className="addRow_button" onClick={(e) => this.handleAddRatingToRatingList(e)}>
                                Add More <AddBoxIcon style={{ marginLeft: "8px" }} />
                            </button>
                        </Grid>
                    }
                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label">Principal Repayment Frequency</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Controls.CustomSelect
                            disabled={this.state.isReadMode}
                            marginTop={50}
                            name="principalRepaymentFrequency"
                            label="Select one"
                            options={this.state.principal_repayment_frequencyList}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            value={this.state.principalRepaymentFrequency}
                            width="75%"
                            borderRadius=""
                            onClick="" />

                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label">Interest Payment Frequency</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <Controls.CustomSelect
                            disabled={this.state.isReadMode}
                            marginTop={50}
                            name="interestPaymentFrequency"
                            label="Select one"
                            options={this.state.interest_payment_frequencyList}
                            onChange={(e: any) => this.handleInputSelect(e)}
                            value={this.state.interestPaymentFrequency}
                            width="75%"
                            borderRadius=""
                            onClick="" />
                    </Grid>
                    <Grid item xs={4} className="labelStyle">
                        <Typography className="label" >Is call option available?</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <RadioGroup style={{ display: "flex", flexDirection: "row" }} value={this.state.isCallOption_available} onChange={(e) => this.setState({ isCallOption_available: e.target.value, isNext: true })} >
                            <FormControlLabel
                                value="Yes"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="Yes" />
                            <FormControlLabel
                                style={{ marginLeft: "20px" }}
                                value="No"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        this.state.isCallOption_available === "Yes" && <Grid item xs={4} className="labelStyle" >
                            <Typography className="label">Call option details</Typography>
                        </Grid>
                    }

                    {
                        this.state.isCallOption_available === "Yes" && <Grid item xs={8} >
                            <input className="catalogue_textInput"
                                disabled={this.state.isReadMode}
                                name="callOptionDetail"
                                placeholder=""
                                onChange={(e: any) => this.handleCallOptionDetails(e)}
                                value={this.state.call_option_details}
                                type="" />

                        </Grid>

                    }
                    {/* {
                        this.state.isMobileError && <FormHelperText style={{ color: "#ff5722" }} >Mobile number is not valid</FormHelperText>

                    } */}

                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label" >Is Put option available?</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <RadioGroup row value={this.state.isPutOptionAvailable} onChange={(e) => this.setState({ isPutOptionAvailable: e.target.value, isNext: true })}>
                            <FormControlLabel
                                value="Yes"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="Yes" />
                            <FormControlLabel
                                style={{ marginLeft: "20px" }}
                                value="No"
                                control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                                label="No" />
                        </RadioGroup>
                    </Grid>
                    {
                        this.state.isPutOptionAvailable === "Yes" && <Grid item xs={4} className="labelStyle">
                            <Typography className="label">Put option details</Typography>
                        </Grid>
                    }

                    {
                        this.state.isPutOptionAvailable === "Yes" && <Grid item xs={8} >
                            <input className="catalogue_textInput"
                                disabled={this.state.isReadMode}
                                name="putOptionDetail"
                                placeholder=""
                                onChange={(e: any) => this.handlePutOptionDetail(e)}
                                value={this.state.put_option_details}
                                type="" />
                        </Grid>
                    }

                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label">Repayment Start Date</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            disabled={this.state.isReadMode}
                            name="RepaymentStartDate"
                            placeholder=""
                            onChange={(e: any) => this.handleMaturityStartDate(e)}
                            value={this.state.Repayment_Start_Date2}
                            onKeyDown={(e) => e.preventDefault()}
                            min={moment().format('YYYY-MM-DD')}
                            // value={moment(this.state.Repayment_Start_Date2).format("DD/MM/YYYY")}
                            type="date" />
                    </Grid>

                    <Grid item xs={4} className="labelStyle" >
                        <Typography className="label">Repayment Maturity Date</Typography>
                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            name="RepaymentMaturityDate"
                            placeholder=""
                            disabled={this.state.isReadMode}
                            min={moment(this.state.Repayment_Start_Date2).format('YYYY-MM-DD')}
                            // onChange={(e: any) => this.setState({ Repayment_Maturity_Date2: e.target.value })}
                            onChange={(e: any) => this.handleMaturityDate(e)}
                            value={this.state.Repayment_Maturity_Date2}
                            onKeyDown={(e) => e.preventDefault()}
                            type="date" />
                        {
                            new Date(this.state.Repayment_Start_Date) > new Date(this.state.Repayment_Maturity_Date) ? (<FormHelperText style={{ color: "red" }} > Maturity Date should be greater than Repayment Start Date</FormHelperText>) : null
                        }
                    </Grid>
                    <Grid item xs={4} >
                        <Typography className="label" >Proposed Total Tenor</Typography>
                        <Typography variant="body2" style={{color:'gray'}}>(from today) </Typography>

                    </Grid>
                    <Grid item xs={8} >
                        <input className="catalogue_textInput"
                            disabled={this.state.isReadMode}
                            name="Proposed_Total_enor"
                            placeholder="Xyrs Ym and Zd"
                            value={this.state.Proposed_Total_Tenor}
                            type="" />
                    </Grid>
                    <Grid item xs={4}>
                    </Grid>
                    <Grid item xs={8}>
                        {!this.state.isReadMode ? <Box className="catalog_button_wrapper" >
                            <button
                                className="save_as_draft_button"
                                onClick={(e) => this.saveAsDraft(e)} >Save as Draft</button>
                            <button
                                onClick={() => this.handleNext()}
                                className="next_button"
                                style={{ marginLeft: "12px" }}
                            >Next
                            </button>
                        </Box> : <Box
                            className="project_submit_button_wrapper"
                        ><button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button" data-testid="submit">Next</button></Box>}
                    </Grid>
                </Grid>
            </>
        );
    }
};
export default withRouter(ProposedInstrumentDetails as any) as any;
 // Customizable Area End
 
