import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DealDiscovery } from "../../catalogue/src/Interface.web";
// Customizable Area Start
import { apiCall, getHeaders, isLoggedIn } from "../../../components/src/common";

export const configJSON = require("./config");

// Customizable Area End
export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start


  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  openFilter: boolean;
  dealDiscovery: DealDiscovery[];
  selectedoption: string;
  personalDetailsForm: any;
  bankDetailsForm: {
    name: string;
    bank_id: number;
    bank_name: string;
    account_number: string;
    confirm_account_number: string;
    ifsc_code: string;
    check: any;
    check_name: any;
  };
  old_account_no: string;
  account_type: string;
  dematDetailsForm: any;
  cancelledCheck: any;
  kycDetails: any,
  debt_seeker_type: string;
  panPhotoName: any;
  dematCmrCopyName: any;
  demateNsdlCopyName: any;
  bankListDetailArray: any[];
  seekarTypes: any[];
  editPersonalDetails: boolean;
  isEmailVerified: boolean;
  isMobileVerified: boolean;
  old_phone_number: any;
  isErrorEmail: boolean;
  email_verification_link_send: boolean;
  email_verified: boolean;
  old_email: string;
  otp_send: boolean;
  OtpValue: any[];
  roleOptions: any[];
  showErrorAcoountNo: boolean;
  showErrorConfirmAcoountNo: boolean;
  showErrorIfsc: boolean;
  errorPanCard: boolean;
  role_id: number;
  providerTypes: any[];
  arrangerTypes: any[];
  ArrangerTypes: any[];
  debt_provider_type: string;
  debt_arranger_type: string;
  errorUploadPanPhoto: boolean;
  uploadCmrCopyError: boolean;
  uploadCmrSuccess: boolean;
  uploadPanSuccess: boolean;
  uploadCancelledCheckError: boolean;
  uploadCancelledChecksuccess: boolean;
  isErrorPersonal: any;
  isErrorBankDetails: any;
  isErrorDematDetails: any;
  isErrorKYCDetails: any;
  isErrorName: boolean;
  isErrorCompanyName: boolean;
  isErrorPhone: boolean;
  bankNoError: boolean;
  isClientIdError: boolean;
  isDPIdError: boolean;
  isNsdlDpIdError: boolean;
  isErrorPanNumber: boolean;
  isEmailVerifySend: boolean;
  cinProfileError: boolean;
  llpError: boolean;
  cinProfile: string;
  llp: string;
  pan: string;
  panCard: string;
  panCardName: string;
  file: any;
  uploadDetail: boolean;
  cancelCheckUrl: string;
  showErrorDropValue: boolean;
  showErrorProviderDropValue: boolean;
  showErrorArrangerDropValue: boolean;
  kycPostApiCalled: boolean;
  bankDetailApiCalled: boolean;
  demateApiCalled: boolean;
  profileTabIndexValue: any;
  demat_detail_type: string;
  debtSeekerSelectedShowValueForProfile: string;
  debtProviderSelectedShowValueForProfile: string;
  debtArrangerSelectedShowValueForProfile: string;
  uploadNsdlCmrCopyError: boolean;
  snackbarOpenProfileError: boolean;
  snackbarMessageProfile: string;
  snackbarOpenProfile: boolean;
  preSelectedRoles: any[];
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  // Customizable Area Start
  getDealDescriptionId: string = '';
  getBankListAPICalled: string = '';
  seekerTypeListAPICalled: string = "";
  ProfileBankDetailsAPICalled: string = "";
  ProfileDematDetailsAPICalled: string = "";
  ProfileKYCDetailsAPICalled: string = "";
  ProfileKYCUpdateAPICalled: string = "";
  ProfileBankDetailUpdateAPICalled: string = "";
  ProfileDematDetailsUpdateAPICalled: string = "";
  ProfileKycDetailsUpdateAPICalled: string = "";
  ProfilePersonalDetailUpdateAPICalled: string = "";
  PersonalDetailsAPICalled: string = "";
  verifyEmailAPICalled: string = "";
  RequestOTPAPICalled: string = "";
  ResendOTPAPICalled: string = "";
  VerifyOTPAPICalled: string = "";
  CheckEmailAPICalled: string = "";
  providerTypeListAPICalled: string = "";
  ArrangerTypeListAPICalled: string = "";
  ProfileKycDetailsAddAPICalled: string = "";
  ProfileBankDetailsAddApiCalled: string = "";
  ProfileDemateDetailsAddApiCalled: string = "";
  getRolesListingApiCallId: string = "";
  getUserSpecificRoleApiCallId: string = "";
  authToken:string = "";
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      openFilter: false,
      dealDiscovery: [],
      selectedoption: 'Personal Details',
      personalDetailsForm: {
        role_id: [],
        full_name: "",
        email: "",
        company_name: "",
        full_phone_number: "",
        phone_number: "",
      },
      bankDetailsForm: {
        name: "",
        bank_id: 0,
        bank_name: '',
        account_number: "",
        confirm_account_number: "",
        ifsc_code: "",
        check: "",
        check_name: "",
      },
      dematDetailsForm: {
        cdsl_cmr_copy_name: "",
        nsdl_cmr_copy_name: "",
        cdsl_cmr_copy: "",
        nsdl_cmr_copy: "",
        cdsl_dp_id: "",
        nsdl_dp_id: "",
        cdsl_client_id: "",
        cmr_copy: ""
      },
      demat_detail_type: configJSON.nsdl,
      kycDetails: {
        kyc_verification_type: "",
        pan_card_name: "",
        pan_card: "",
        debt_seeker: 0,
        debt_provider: 0,
        debt_arranger: 0,
        debt_seeker_name:'',
        debt_arranger_name:'',
        debt_provider_name:''
      },
      old_account_no: "",
      old_email: "",
      old_phone_number: "",
      account_type: "",
      debt_seeker_type: '',
      cancelledCheck: '',
      cancelCheckUrl: '',
      panPhotoName: '',
      dematCmrCopyName: '',
      demateNsdlCopyName: '',
      bankListDetailArray: [],
      seekarTypes: [],
      editPersonalDetails: false,
      email_verified: false,
      isEmailVerified: false,
      isMobileVerified: false,
      isErrorEmail: false,
      email_verification_link_send: false,
      otp_send: false,
      OtpValue: [],
      kycPostApiCalled: false,
      roleOptions: [
        {
          id: 1,
          value: "Debt Seekers"
        },
        {
          id: 2,
          value: "Debt Providers"
        },
        {
          id: 3,
          value: "Debt Arrangers"
        }],
      showErrorAcoountNo: false,
      showErrorConfirmAcoountNo: false,
      showErrorIfsc: false,
      errorPanCard: false,
      role_id: 0,
      providerTypes: [],
      arrangerTypes: [],
      ArrangerTypes: [],
      debt_provider_type: "",
      debt_arranger_type: "",
      errorUploadPanPhoto: false,
      uploadCmrCopyError: false,
      uploadCmrSuccess: false,
      uploadPanSuccess: false,
      uploadCancelledCheckError: false,
      uploadCancelledChecksuccess: false,
      isErrorPersonal: {},
      isErrorBankDetails: {},
      isErrorDematDetails: {},
      isErrorKYCDetails: {},
      isErrorName: false,
      isErrorCompanyName: false,
      isErrorPhone: false,
      bankNoError: false,
      isClientIdError: false,
      isDPIdError: false,
      isNsdlDpIdError: false,
      isErrorPanNumber: false,
      isEmailVerifySend: false,
      cinProfileError: false,
      llpError: false,
      cinProfile: '',
      llp: '',
      pan: '',
      panCardName: '',
      file: [],
      panCard: '',
      uploadDetail: false,
      showErrorDropValue: false,
      showErrorArrangerDropValue: false,
      showErrorProviderDropValue: false,
      bankDetailApiCalled: false,
      demateApiCalled: false,
      profileTabIndexValue: 0,
      debtSeekerSelectedShowValueForProfile: '',
      debtProviderSelectedShowValueForProfile: '',
      debtArrangerSelectedShowValueForProfile: '',
      uploadNsdlCmrCopyError: false,
      snackbarOpenProfileError: false,
      snackbarMessageProfile: '',
      snackbarOpenProfile: false,
      preSelectedRoles: []
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    const authToken = isLoggedIn(this.props.navigation);
    if(typeof(authToken) === "string"){
      this.authToken = authToken;
    }
    this.getUserSpecificRoles();
    this.getAllRolesListing();
    this.getBankNameList();
    this.getProfileBankDetails();
    this.getDematDetails();
    this.getKYCDetails();
    this.getPersonalDetails();
    const role_id = localStorage.getItem("role_id");
    if (role_id !== null) {
      this.setState({
        role_id: parseInt(role_id)
      })
      if (role_id === "1") {
        this.getTypesOfSeeker();
      } else if (role_id === "2") {
        this.getTypesOfProvider();
      } else if (role_id === "3") {
        this.getTypesOfArranger();
      }
    }
    // Customizable Area End
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson) {
        if (this.getDealDescriptionId === apiRequestCallId) {
          this.setState({ dealDiscovery: responseJson.data })
        }
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.getRolesListingApiCallId) {
        if (responseJson.role) {
          this.setState({
            roleOptions: responseJson.role
          });
        } else {
          const msg236: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg236.addData(getName(MessageEnum.CustomAlertBodyData), "Something went wrong");
          this.send(msg236);
        }
      }

      if (apiRequestCallId === this.getUserSpecificRoleApiCallId) {
        if (responseJson.role) {
          const userRoles = responseJson.role.map((data: any)=> data.role_id);
          localStorage.setItem("user_roles", JSON.stringify(userRoles));
        }
      }

      if (apiRequestCallId === this.getBankListAPICalled) {
        const dropDownArray: any = responseJson?.data?.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          bankListDetailArray: dropDownArray
        });
      }

      if (apiRequestCallId === this.seekerTypeListAPICalled) {
        const dropDownArray: any[] = responseJson?.data?.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          seekarTypes: dropDownArray
        });
      }
      if (apiRequestCallId === this.ProfileBankDetailsAddApiCalled) {
        this.getProfileBankDetails();
        /* istanbul ignore next */
        if (responseJson?.meta) {
          this.setState({
            editPersonalDetails: false
          });
          const msg237: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg237.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg237);
        }
        else {
          const msg238: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg238.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors[0]);
          this.send(msg238);
        }
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.ProfileKycDetailsAddAPICalled) {
        this.getKYCDetails();
        if (responseJson?.meta) {
          localStorage.setItem("is_kyc_done", JSON.stringify(true));
          this.setState({
            editPersonalDetails: false
          });
          const msg239: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg239.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg239);
        }
        else {
          const msg240: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg240.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors[0]);
          this.send(msg240);
        }
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.ProfileDemateDetailsAddApiCalled) {
        this.getDematDetails();
        if (responseJson?.meta) {
          this.setState({
            editPersonalDetails: false
          });
          const msg241: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg241.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg241);
          this.getDematDetails();
        }
        else {
          const msg242: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg242.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors[0]);
          this.send(msg242);
        }
      }
      if (apiRequestCallId === this.providerTypeListAPICalled) {
        const dropDownArray: any[] = responseJson?.data?.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          providerTypes: dropDownArray
        });
      }

      if (apiRequestCallId === this.ArrangerTypeListAPICalled) {
        const dropDownArray: any[] = responseJson?.data?.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          ArrangerTypes: dropDownArray
        });
      }

      if (apiRequestCallId === this.ProfileBankDetailsAPICalled) {
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
          this.setState({
            bankDetailApiCalled: true
          });
        }
        else {
          this.setState({
            bankDetailApiCalled: false
          });
        }
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.attributes && responseJson?.data[0]?.attributes?.account_number) {
          this.setState({
            bankDetailsForm: { ...responseJson?.data[0]?.attributes, confirm_account_number: responseJson?.data[0]?.attributes?.account_number ? responseJson?.data[0]?.attributes?.account_number : '' },
            old_account_no: responseJson?.data[0]?.attributes?.account_number ? responseJson?.data[0]?.attributes?.account_number : ''
          })
        }
      }

      if (apiRequestCallId === this.ProfileDematDetailsAPICalled) {
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
          this.setState({
            demateApiCalled: true
          });
        }
        else {
          this.setState({
            demateApiCalled: false
          });
        }
        if(responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.attributes){
            this.setState({
                dematDetailsForm:{...this.state.dematDetailsForm,
                cdsl_client_id:responseJson?.data[0]?.attributes?.cdsl_client_id ?responseJson?.data[0]?.attributes?.cdsl_client_id: '',
                cdsl_cmr_copy:responseJson?.data[0]?.attributes?.cdsl_cmr_copy ? responseJson?.data[0]?.attributes?.cdsl_cmr_copy : '',
                cdsl_cmr_copy_name:responseJson?.data[0]?.attributes?.cdsl_cmr_copy_name ?responseJson?.data[0]?.attributes?.cdsl_cmr_copy_name: '',
                nsdl_cmr_copy:responseJson?.data[0]?.attributes?.nsdl_cmr_copy ?responseJson?.data[0]?.attributes?.nsdl_cmr_copy: '',
                nsdl_cmr_copy_name: responseJson?.data[0]?.attributes?.nsdl_cmr_copy_name ?responseJson?.data[0]?.attributes?.nsdl_cmr_copy_name: '',
                cdsl_dp_id:responseJson?.data[0]?.attributes?.cdsl_dp_id ?responseJson?.data[0]?.attributes?.cdsl_dp_id: '',
                nsdl_dp_id:responseJson?.data[0]?.attributes?.nsdl_dp_id ?responseJson?.data[0]?.attributes?.nsdl_dp_id: ''
              }
            })
        if (responseJson?.data[0]?.attributes?.demat_detail_type == "nsdl") {
          this.setState({
            profileTabIndexValue: 0
          });
          localStorage.setItem("tabIndexValue","0");
        }
        else {
          this.setState({
            profileTabIndexValue: 1
          });
          localStorage.setItem("tabIndexValue","1");
        }
      }
    }

      if (apiRequestCallId === this.ProfileKYCDetailsAPICalled) {
        if (responseJson?.data && Array.isArray(responseJson?.data)) {
          if (responseJson && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
            this.setState({
              kycPostApiCalled: true
            });
          }
          else {
            this.setState({
              kycPostApiCalled: false
            });
          }
          this.state.seekarTypes.forEach((element) => {
            if (element.id === responseJson?.data[0]?.attributes?.debt_seeker) {
              this.setState({
                debt_seeker_type: element.value
              })
            }
          });
          this.state.providerTypes.forEach((element) => {
            if (element.id === responseJson?.data[0]?.attributes?.debt_provider) {
              this.setState({
                debt_provider_type: element.value
              })
            }
          });
          this.state.ArrangerTypes.forEach((element) => {
            if (element.id === responseJson?.data[0]?.attributes?.debt_arranger) {
              this.setState({
                debt_arranger_type: element.value
              })
            }
          });
          this.setState({
            kycDetails: responseJson?.data[0] ? responseJson?.data[0]?.attributes : '',
            cinProfile: responseJson?.data[0]?.attributes?.cin ? responseJson?.data[0]?.attributes?.cin : '',
            llp: responseJson?.data[0]?.attributes?.llpin ? responseJson?.data[0]?.attributes?.llpin : '',
            pan: responseJson?.data[0]?.attributes?.pan ? responseJson?.data[0]?.attributes?.pan : '',
            panCard: responseJson?.data[0]?.attributes?.pan_card ? responseJson?.data[0]?.attributes?.pan_card : '',
            panCardName: responseJson?.data[0]?.attributes?.pan_card_name ? responseJson?.data[0]?.attributes?.pan_card_name : '',
            debtSeekerSelectedShowValueForProfile: responseJson?.data[0]?.attributes?.debt_seeker_name ? responseJson?.data[0]?.attributes?.debt_seeker_name : '',
            debtProviderSelectedShowValueForProfile: responseJson?.data[0]?.attributes?.debt_provider_name ? responseJson?.data[0]?.attributes?.debt_provider_name : '',
            debtArrangerSelectedShowValueForProfile: responseJson?.data[0]?.attributes?.debt_arranger_name ? responseJson?.data[0]?.attributes?.debt_arranger_name : ''
          });
        }
      }

      if (apiRequestCallId === this.PersonalDetailsAPICalled) {
        const preselectedRoles = responseJson.meta.role.map((data:any)=>{
          return data.role_id
        });
        this.setState({
          personalDetailsForm: {...responseJson.data.attributes, role_id: preselectedRoles},
          account_type: responseJson.data.type,
          old_email: responseJson.data.attributes.email,
          old_phone_number: responseJson.data.attributes.phone_number,
          preSelectedRoles: preselectedRoles
        });

      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.verifyEmailAPICalled) {
        if (responseJson?.data?.id) {
          let timerId = setInterval(() => {
            this.checkEmail();
            this.setState({
              isEmailVerifySend: true
            });
            this.state.isEmailVerified && clearInterval(timerId);
          }, 5000);

          setTimeout(() => {
            clearInterval(timerId);
          }, 300000)
        }
        this.setState({
          email_verified: true,
        });
        const msg243: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg243.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message[0].account);
        this.send(msg243);
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.RequestOTPAPICalled) {
        if (responseJson?.message) {
          let message = ''
          if (Array.isArray(responseJson?.message)) {
            message = Object.values(responseJson?.message[0])[0] as string
            this.setState({ snackbarOpenProfileError: true, snackbarMessageProfile: `${Object.values(responseJson?.message[0])[0]}` })
          } else if (typeof responseJson?.message === 'string') {
            message = responseJson?.message
            const msg244: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg244.addData(getName(MessageEnum.CustomAlertBodyData), `${message}`);
            this.send(msg244);
          }

          if (responseJson?.token) {
            localStorage.setItem("MOBILE_OTP_TOKEN", responseJson?.token);
            this.setState({ otp_send: true })
          } else {
            if (message.includes("Phone already verified")) {
              this.setState({
                isMobileVerified: true
              });
            }
            this.setState({
              otp_send: false,
              isMobileVerified: false
            })
          }
          return;
        }
        localStorage.setItem("MOBILE_OTP_TOKEN", responseJson?.meta?.token);
        this.setState({ snackbarOpenProfile: true, snackbarMessageProfile: `${responseJson?.meta?.message[0]?.account}` })
        responseJson?.meta?.message[0]?.account && this.setState({ otp_send: true })
        responseJson?.data?.attributes?.activated === true && this.setState({ isMobileVerified: true });
      }

      if (apiRequestCallId === this.ProfileBankDetailUpdateAPICalled) {
        /* istanbul ignore next */
        if (responseJson) {
          if (!responseJson.error) {
            this.setState({
              editPersonalDetails: false,
              cancelCheckUrl: responseJson?.data?.attributes?.check,
              bankDetailsForm: { ...this.state.bankDetailsForm, check_name: responseJson?.data?.attributes?.check_name }
            });
            this.getProfileBankDetails();
            const msg245: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg245.addData(getName(MessageEnum.CustomAlertBodyData), "Bank Details updated successfully.");
            this.send(msg245);
          } else {
            const msg246: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg246.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
            this.send(msg246);
          }
        }
      }

      if (apiRequestCallId === this.ProfileDematDetailsUpdateAPICalled) {
        /* istanbul ignore next */
        if (responseJson) {
          if (!responseJson.error) {
            this.getDematDetails();
            this.setState({
              editPersonalDetails: false
            });
            const msg247: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg247.addData(getName(MessageEnum.CustomAlertBodyData), "Demat Details updated successfully.");
            this.send(msg247);
            this.getDematDetails();
          } else {
            const msg248: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg248.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
            this.send(msg248);
          }
        }
      }

      if (apiRequestCallId === this.ProfileKycDetailsUpdateAPICalled) {
        /* istanbul ignore next */
        if (responseJson) {
          if (!responseJson.error) {
            this.getKYCDetails();
            this.setState({
              editPersonalDetails: false
            });
            const msg249: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg249.addData(getName(MessageEnum.CustomAlertBodyData), "KYC Details updated successfully.");
            this.send(msg249);
          } else {
            const msg250: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg250.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
            this.send(msg250);
          }
        }
      }

      if (apiRequestCallId === this.ProfilePersonalDetailUpdateAPICalled) {
        /* istanbul ignore next */
        if (responseJson) {
          if (!responseJson.error && !responseJson.errors) {
            this.getUserSpecificRoles(); 
            this.setState({
              editPersonalDetails: false,
              role_id: responseJson?.data?.attributes?.role_id
            });
            localStorage.setItem('role_id', responseJson?.data?.attributes?.role_id);
            this.getPersonalDetails();
            this.getKYCDetails();
            this.getBankNameList();
            this.getTypesOfSeeker();
            this.getTypesOfArranger();
            this.getTypesOfProvider();
            const msg251: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg251.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
            this.send(msg251);
          } else {
            if (responseJson.errors[0].account === 'Phone number is not verified') {
              this.setState({
                isMobileVerified: false,
                old_phone_number: null
              })
            }
            /* istanbul ignore next */
            if(responseJson.errors){
              const msg252: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg252.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors[0].account);
              this.send(msg252);
            } 
              /* istanbul ignore next */
            else {
              const msg253: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg253.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
              this.send(msg253);
            }
            
          }
        }
      }

      if (apiRequestCallId === this.CheckEmailAPICalled) {
        if (responseJson.status === false) {
          this.setState({
            isEmailVerified: false,
          });
          this.setState({
            isEmailVerifySend: false
          })
        } else {
          this.setState({ isEmailVerified: true });
        }
      }

      if (apiRequestCallId === this.VerifyOTPAPICalled) {
        if (responseJson?.errors) {
          this.setState({ snackbarOpenProfileError: true, snackbarMessageProfile: `${Object.values(responseJson?.errors[0])[0]}` })

        }

        if (responseJson?.data?.attributes?.activated) {
          this.setState({ isMobileVerified: true, otp_send: false })
        }
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  getOtpValue1 = (e: any) => {
    this.state.OtpValue.push(e.target.value)
    this.setState({
        OtpValue: this.state.OtpValue
    });
  };

  removeUploadFile = () => {
    this.setState({
        cancelledCheck: '',
    });
  };

  move = (e: any, prev: string, current: string, next: string) => {
    let lengthProfile = (document.getElementById(current) as HTMLInputElement)?.value?.length;
    let maxLengthProfile = document.getElementById(current)?.getAttribute("maxLength");

    let otpOne = (document.getElementById('text1') as HTMLInputElement)?.value || '';
    let otpTwo = (document.getElementById('text2') as HTMLInputElement)?.value || '';
    let otpThree = (document.getElementById('text3') as HTMLInputElement)?.value || '';
    let otpFour = (document.getElementById('text4') as HTMLInputElement)?.value || '';

    let OTPProfile = [otpOne, otpTwo, otpThree, otpFour]

    if (String(lengthProfile) == maxLengthProfile) {
        if (next !== "") {
            document.getElementById(next)?.focus()
        }
    }
    if (e.key === "Backspace") {
        if (prev !== "") {
            document.getElementById(prev)?.focus()
        }
    }

    OTPProfile = OTPProfile.filter(item => !!item)
    this.setState({ OtpValue: OTPProfile })
    OTPProfile.length == 4 && setTimeout(this.verifyMobileOtp(OTPProfile), 500)
  }

  getCinVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex1 = /^([A-Za-z]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
    const trimmedValue1 = event.target.value.trim();
    if (regex1.test(event.target.value) || !trimmedValue1.includes(' ') || (event.target.value == '')) {
        this.setState({
            cinProfile: event.target.value.toUpperCase(),
            cinProfileError: false
        });
    }
    if ((event.target.value.length !== 21) || regex1.test(event.target.value) === false) {
        this.setState({
            cinProfileError: true
        });
    }
  };
  getLlpInValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex1 = /^[0-7 ]+$/
    const trimmedValueOne = event.target.value.toUpperCase();
    if (regex1.test(event.target.value) && !trimmedValueOne.includes(' ') || (event.target.value == '')) {
        this.setState({
            llp: event.target.value,
            llpError: false
        });
    }
    if (event.target.value.length !== 7) {
        this.setState({
            llpError: true
        });
    }
  };

  removeBankCheckName = () => {
    this.setState({
      cancelledCheck: '',
      bankDetailsForm: { ...this.state.bankDetailsForm, check: '', check_name: '' },
      uploadCancelledCheckError: false
    })
  };

  showErrorDropDown = () => {
    this.setState({
      showErrorDropValue: true
    });
  }
  showErrorProviderDropDown = () => {
    this.setState({
      showErrorProviderDropValue: true
    });
  };
  showErrorArrangerDropDown = () => {
    this.setState({
      showErrorArrangerDropValue: true
    });
  };
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenProfile: false })
  };
  handleCloseError = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }

    this.setState({ snackbarOpenProfileError: false })
  };
  getPanValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9]+$/;
    const regex2 = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(event.target.value) || (event.target.value == '')) {
      this.setState({
        pan: event.target.value.toUpperCase(),
        errorPanCard: false
      });
    }
    if ((event.target.value.length != 10) || (regex2.test(event.target.value) === false)) {
      this.setState({
        errorPanCard: true
      });
    }
  };
  typeOfLabel = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({
      profileTabIndexValue: index
    });
    if (index === 0) {
      this.setState({
        demat_detail_type: configJSON.nsdl
      })
    }
    else {
      this.setState({
        demat_detail_type: configJSON.cdsl
      })
    }
  };

  cancelFunctionality = () => {
    this.setState({
      editPersonalDetails : false
    });
  };

  getDealDiscovery = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getDealDescriptionId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dealDiscoveryEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  handleSubmitController = () => {

  }

  removePanPhoto = () => {
    this.setState({
      file: [],
      panCardName: '',
      errorUploadPanPhoto: false
    });
  };

  handleChangePersonal = (e: any) => {
    if (e.target.name === "phone_number") {
      /* istanbul ignore next */
      const regex = /[0-9]$/;
      let phone = e.target.value;
      if (phone.length === 10) {
        this.setState({ isErrorPhone: false });
      } else {
        this.setState({ isErrorPhone: true });
      }
      if (regex.test(e.target.value) || e.target.value == '') {
        this.setState({
          personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
        });
      }
    }
    if (e.target.name === "full_name") {
      const regex = /^[a-z ]+$/i

      let fullName = e.target.value;
      if (fullName.length > 25 || fullName.length < 2 || fullName.length === 0) {
        this.setState({ isErrorName: true });
      } else {
        this.setState({ isErrorName: false });
      }
      if (regex.test(fullName) || fullName === '') {
        this.setState({
          personalDetailsForm: { ...this.state.personalDetailsForm, ['full_name']: e.target.value }
        });
      }
    }
    if (e.target.name === "company_name") {
      /* istanbul ignore next */
      const regEx = /^[a-z]+$/i
      let companyName = e.target.value;
      if (companyName.length > 0) {
        this.setState({ isErrorCompanyName: false });
      }
      else {
        this.setState({ isErrorCompanyName: true });
      }

      if (regEx.test(companyName) || companyName === '') {
        this.setState({
          personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
        });
      }
    }
    if (e.target.name === "email") {
      const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
      if (!regex.test(e.target.value)) {
        this.setState({ isErrorEmail: true })
        this.setState({
          personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
        });
      } else {
        this.setState({
          personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
        });
        this.setState({ isErrorEmail: false });
      }
    }
    if (e.target.name === "role_id") {
      this.setState({
        personalDetailsForm: { ...this.state.personalDetailsForm, [e.target.name]: e.target.value }
      });
    }
  };

  handleNumberChange = (e: any) => {
    let companyName = e.target.value
    let pattern = /^[0-9]+$/;
    if (pattern.test(companyName)) {
      this.setState({ isErrorCompanyName: true });
    }
  }

  validatePersonalDetails = (values: any) => {
    const errors: any = {};
    const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (values?.full_name?.length > 25 || values?.full_name?.length < 2 || values?.full_name?.length === 0) {
      this.setState({
        isErrorName: true
      })
    } else {
      this.setState({
        isErrorName: false
      })
    }
    if (values?.email === "" || !regexEmail.test(values?.email)) {
      this.setState({
        isErrorEmail: true
      })
    } else {
      this.setState({
        isErrorEmail: false
      })
    }
    if (values?.phone_number?.length !== 10) {
      this.setState({
        isErrorPhone: true
      })
    } else {
      this.setState({
        isErrorPhone: false
      })
    }
    if (values?.company_name === "") {
      this.setState({
        isErrorCompanyName: true
      })
    } else {
      this.setState({
        isErrorCompanyName: false
      })
    }
    return errors;
  }

  editPersonalDetails = (e: any) => {
    this.setState({ editPersonalDetails: true })
  };
  /* istanbul ignore next */
  setSelectedDetails = (data: string) => {
    if (this.state.editPersonalDetails) {
      if (this.state.selectedoption == "Bank Details") {
        this.bankFormValidationCheckProfile();
      }
      if (this.state.selectedoption == "KYC Details") {
        this.kycValidationCheckProfile();
      }
      if (this.state.selectedoption == "Demat Details") {
        if (this.state.profileTabIndexValue == "1") {
          if (!this.state.dematDetailsForm?.cdsl_client_id || this.state.dematDetailsForm?.cdsl_client_id == 'undefined') {
            const msg132: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg132.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill ClientID.");
            this.send(msg132);
            return false;
          }
          if (this.state.dematDetailsForm?.cdsl_dp_id?.length == 0 || this.state.dematDetailsForm?.cdsl_dp_id == 'undefined') {
            const msg133: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg133.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill DPID.");
            this.send(msg133);
            return false;
          }
          if (this.state.isClientIdError) {
            const msg134: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg134.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove ClientID error.");
            this.send(msg134);
            return false;
          }
          let cmrName: string = '';
          if (this.state.dematDetailsForm?.cdsl_cmr_copy_name) {
            cmrName = this.state.dematDetailsForm?.cdsl_cmr_copy_name
          }
          if (this.state.dematCmrCopyName?.name) {
            cmrName = this.state.dematCmrCopyName?.name
          }
          if (cmrName.length == 0) {
            const msg135: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg135.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload Demat CMR copy.");
            this.send(msg135);
            return false;
          }
          if (this.state.isDPIdError) {
            const msg136: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg136.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove DPID error.");
            this.send(msg136);
            return false;
          }
          if (this.state.uploadCmrCopyError) {
            const msg137: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg137.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove uploading cmr copy error.");
            this.send(msg137);
            return false;
          }
        }
        if (this.state.profileTabIndexValue == "0") {
          if (this.state.dematDetailsForm?.nsdl_dp_id?.length == 0 || this.state.dematDetailsForm?.nsdl_dp_id == 'undefined') {
            const msg138: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg138.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill DPID.");
            this.send(msg138);
            return false;
          }
          let cmrName: string = '';
          if (this.state.dematDetailsForm?.nsdl_cmr_copy_name) {
            cmrName = this.state.dematDetailsForm?.nsdl_cmr_copy_name
          }
          if (this.state.demateNsdlCopyName?.name) {
            cmrName = this.state.demateNsdlCopyName?.name
          }
          if (cmrName.length == 0) {
            const msg139: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg139.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload Demat CMR copy.");
            this.send(msg139);
            return false;
          }
          if (this.state.isNsdlDpIdError) {
            const msg140: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg140.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove DPID error.");
            this.send(msg140);
            return false;
          }
          if (this.state.uploadNsdlCmrCopyError) {
            const msg141: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg141.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove uploading cmr copy error.");
            this.send(msg141);
            return false;
          }
        }
      }
    }
    this.setState({
      selectedoption: data,
      editPersonalDetails: false
    });
  };

  uploadFile = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'image/svg' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.setState({
        file: event.target.files[0],
        errorUploadPanPhoto: false,
      });
    }
    else {
      this.setState({
        errorUploadPanPhoto: true
      })
    }
  };

  uploadDematCmrCopy = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'application/pdf') {
      this.setState({
        dematCmrCopyName: event.target.files[0],
        uploadCmrCopyError: false,
        dematDetailsForm:{...this.state.dematDetailsForm,cdsl_cmr_copy:event.target.files[0]}
      });
    }
    else {
      this.setState({
        uploadCmrCopyError: true
      });
    }
  };

  uploadNsdlDematCmrCopy = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'application/pdf') {
      this.setState({
        demateNsdlCopyName: event.target.files[0],
        uploadNsdlCmrCopyError: false,
        dematDetailsForm:{...this.state.dematDetailsForm,nsdl_cmr_copy:event.target.files[0]}
      });
    }
    else {
      this.setState({
        uploadNsdlCmrCopyError: true
      });
    }
  };

  handleChangeBankDetails = (e: any) => {
    if (e.target.name === "name") {
      const regex = /^[a-z ]+$/i

      let fullName = e.target.value;
      if (fullName.length > 25 || fullName.length < 2 || fullName.length === 0) {
        this.setState({ isErrorName: true });
      } else {
        this.setState({ isErrorName: false });
      }
      if (regex.test(fullName) || fullName == '') {
        this.setState({
          bankDetailsForm: { ...this.state.bankDetailsForm, [e.target.name]: e.target.value }
        });
      }
    } else if (e.target.name === "ifsc_code") {
      const regex2 = /^[A-Za-z]{4}[0][A-Z0-9]{6}$/;
      const regEx = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;

      const trimmedValue: string = e?.target?.value;
      if (regex2.test(e.target.value) || !trimmedValue.includes(' ') || (e.target.value !== '')) {
        this.setState({
          showErrorIfsc: false
        })
        if (regEx.test(e.target.value)) {
          e.target.value = e.target.value.slice(0, e.target.value.length)
        } else {
          this.setState({
            bankDetailsForm: { ...this.state.bankDetailsForm, ["ifsc_code"]: e.target.value.toUpperCase() }
          })
        }
      } else {
        this.setState({
          showErrorIfsc: true
        });
      }
      if ((e.target.value.length !== 11) || !(regex2.test(e.target.value))) {
        this.setState({
          showErrorIfsc: true
        });
      }
    } else if (e.target.name === "account_number") {
      e.target.value = e.target.value.slice(0, 18);
      if (e.target.value.length > 18 || e.target.value.length < 9) {
        this.setState({ bankNoError: true });
        this.setState({
          bankDetailsForm: { ...this.state.bankDetailsForm, ["account_number"]: e.target.value }
        });
      } else {
        this.setState({ bankNoError: false });
        this.setState({
          bankDetailsForm: { ...this.state.bankDetailsForm, ["account_number"]: e.target.value }
        });
      }
    } else if (e.target.name === "confirm_account_number") {
      e.target.value = e.target.value.slice(0, 18);
      if (e.target.value.length > 18 || e.target.value.length < 9) {
        this.setState({ bankNoError: true });
        this.setState({
          bankDetailsForm: { ...this.state.bankDetailsForm, ["confirm_account_number"]: e.target.value }
        });
      } else {
        this.setState({ bankNoError: false });
        this.setState({
          bankDetailsForm: { ...this.state.bankDetailsForm, ["confirm_account_number"]: e.target.value }
        });
      }
    } else {
      this.setState({
        bankDetailsForm: { ...this.state.bankDetailsForm, [e.target.name]: e.target.value }
      });
    }
  };

  validateBankDetails = (values: any) => {
    const regex = /^[0-9 ]+$/;

    if (values?.name?.length > 25 || values?.name?.length < 2 || values?.name?.length === 0) {
      this.setState({
        isErrorName: true
      })
    } else {
      this.setState({
        isErrorName: false
      })
    }
    if (values?.account_number?.length > 18 || !regex.test(values?.account_number) || values?.account_number?.length < 9) {
      this.setState({
        bankNoError: true
      })
    } else {
      this.setState({
        bankNoError: false
      })
    }
    if (values?.confirm_account_number?.length > 18 || !regex.test(values?.confirm_account_number) || values?.account_number?.length < 9) {
      this.setState({
        bankNoError: true
      })
    } else {
      this.setState({
        bankNoError: false
      })
    }
    if (values.ifsc_code === "") {
      this.setState({
        showErrorIfsc: true
      })
    } else {
      this.setState({
        showErrorIfsc: false
      })
    }
    if (this.state.cancelledCheck === "" || values?.check === "") {
      this.setState({
        uploadCancelledCheckError: true
      })
    }
    if (this.state.cancelledCheck !== "" || values?.check !== "") {
      this.setState({
        uploadCancelledCheckError: false
      })
    }
  }

  handleChangeDematDetails = (e: any) => {
    this.setState({
      dematDetailsForm: { ...this.state.dematDetailsForm, [e.target.name]: e.target.value }
    });
    if (e.target.name == "cdsl_client_id") {
      e.target.value = e.target.value.slice(0, 8);
      if (e.target.value.length !== 8) {
        this.setState({
          isClientIdError: true
        })
      } else {
        this.setState({
          isClientIdError: false
        })
        this.setState({
          dematDetailsForm: { ...this.state.dematDetailsForm, ['cdsl_client_id']: e.target.value }
        });
      }
    }
    if (e.target.name == "cdsl_dp_id") {
      e.target.value = e.target.value.slice(0, 8);
      if (e.target.value.length !== 8) {
        this.setState({
          isDPIdError: true
        })
      } else {
        this.setState({
          isDPIdError: false
        })
        this.setState({
          dematDetailsForm: { ...this.state.dematDetailsForm, ['cdsl_dp_id']: e.target.value }
        });
      }
    }
    if (e.target.name == "nsdl_dp_id") {
      e.target.value = e.target.value.slice(0, 8);
      if (e.target.value.length !== 8) {
        this.setState({
          isNsdlDpIdError: true
        })
      } else {
        this.setState({
          isNsdlDpIdError: false
        })
        this.setState({
          dematDetailsForm: { ...this.state.dematDetailsForm, ['nsdl_dp_id']: e.target.value }
        });
      }
    }
  };

  validateDematDetails = (values: any) => {
    if (values?.cmr_copy_name === "" || this.state.dematCmrCopyName === "") {
      this.setState({
        uploadCmrCopyError: true
      })
    } else {
      this.setState({
        uploadCmrCopyError: false
      })
    }
    if (!values?.dp_id || values?.dp_id?.length > 16 || values?.dp_id?.length < 2) {
      this.setState({
        isDPIdError: true
      })
    } else {
      this.setState({
        isDPIdError: false
      })
    }
    if (!values?.client_id || values?.client_id?.length > 16 || values?.client_id?.length < 2) {
      this.setState({
        isClientIdError: true
      })
    } else {
      this.setState({
        isDPIdError: false
      })
    }
  }

  handleChangeKYCDetails = (e: any) => {
    this.setState({
      kycDetails: { ...this.state.kycDetails, [e.target.name]: e.target.value }
    });
    if (localStorage.getItem('role_id') == "1") {
      const seekerArr: any = this.state.seekarTypes.find((o: any) => o.id == e.target.value);
      this.setState({
        debtSeekerSelectedShowValueForProfile: seekerArr?.value
      });
    }
    if (localStorage.getItem('role_id') == "2") {
      const providerArr: any = this.state.providerTypes.find((o: any) => o.id == e.target.value);
      this.setState({
        debtProviderSelectedShowValueForProfile: providerArr?.value,
      });
    }
    if (localStorage.getItem('role_id') == "3") {
      const arrangerArr: any = this.state.ArrangerTypes.find((o: any) => o.id == e.target.value);
      this.setState({
        debtArrangerSelectedShowValueForProfile: arrangerArr?.value
      });
    }
  };


  handleMinusSign = (e: any) => {
    ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault()
  }
  handleMinuSign = (e: any) => {
    ["+", "-", "$", "!", "@", "#", "%", "^", "&", "*", "(", ")", "<", ">", "?", ":", ";", "'", ".", ",", "_", "=", "/", "{", "}", "[", "]", "|", "~", "`", "\\", " \' ", " \" ", `"`, `'`].includes(e.key) && e.preventDefault()
  }
  //  handleMenuSign=(e:any)=>{
  //   ["!@#$^&%*()+=-[]\/{}|:<>?,."].includes(e.key) && e.preventDefault()
  //  }
  validateKYCDetails = (values: any) => {
    const regex = /^[A-Za-z0-9 ]+$/;
    const regex2 = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    const trimmedValue = values?.pan;
    if (!regex.test(values?.pan) || trimmedValue?.includes(' ') || (values?.pan === '')) {
      this.setState({
        isErrorPanNumber: true
      })
    } else {
      this.setState({
        isErrorPanNumber: false
      })
    }
    if (!(regex2.test(values?.pan))) {
      this.setState({
        isErrorPanNumber: true
      });
    } else {
      this.setState({
        isErrorPanNumber: false
      })
    }
    if (values?.pan_card === "" || this.state.panPhotoName === "") {
      this.setState({
        errorUploadPanPhoto: true
      });
    } else {
      this.setState({
        errorUploadPanPhoto: false
      })
    }
  }
  /* istanbul ignore next */
  getBankNameList = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getBankListAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getBankListAPIEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  selectedBankValue = (value: any) => {
    // this.setState({
    //   : value,
    // });
  };

  handlepanupload = (event: any) => {
    if (event.target.files[0].type == 'image/svg' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.setState({
        panPhotoName: event.target.files[0],
        errorUploadPanPhoto: false,
        uploadPanSuccess: true,
      })
    } else {
      this.setState({
        errorUploadPanPhoto: true,
        uploadPanSuccess: false
      })
    }
  };

  handleCmrCopyUpload = (event: any) => {
    if (event.target.files[0].type === 'application/pdf') {
      this.setState({
        dematDetailsForm: { ...this.state.dematDetailsForm, ["cmrCopy"]: event.target.value }
      });
      this.setState({
        dematCmrCopyName: event.target.files[0],
        uploadCmrCopyError: false,
        uploadCmrSuccess: true
      })
    } else {
      this.setState({
        uploadCmrCopyError: true,
        uploadCmrSuccess: false
      })
    }
  };

  uploadCancelledCheckPhoto = (event: any) => {
    if (event.target.files[0].type == 'image/svg' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.setState({
        cancelledCheck: event.target.files[0],
        uploadCancelledCheckError: false,
        uploadCancelledChecksuccess: true
      })
    } else {
      this.setState({
        uploadCancelledCheckError: true,
        uploadCancelledChecksuccess: false
      })
    }
  };
  /* istanbul ignore next */

  getTypesOfSeeker = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.seekerTypeListAPICalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DebtSeekerTypesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */

  getTypesOfProvider = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.providerTypeListAPICalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DebtProviderTypesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */

  getTypesOfArranger = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ArrangerTypeListAPICalled = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.DebtArrangerTypesAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getProfileBankDetails = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProfileBankDetailsAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getProfileBankDetailsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */
  getDematDetails = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProfileDematDetailsAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDematProfileDetailsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */
  getKYCDetails = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ProfileKYCDetailsAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getKYCProfileDetailsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */
  updateKYCDetails = () => {
    if (this.kycValidationCheckProfile()) {
      const updateKycToken = localStorage.getItem("token");
      const formdataUpdateKyc = new FormData();
      const headerUpdateKyc = {
        token: updateKycToken
      };
      if (localStorage.getItem("role_id") == "1") {
        //@ts-ignore
        localStorage.getItem("role_id") == "1" && formdataUpdateKyc.append("data[debt_seeker]", this.state.kycDetails?.debt_seeker);
        if (this.state.debtSeekerSelectedShowValueForProfile == "Private Limited Company" || this.state.debtSeekerSelectedShowValueForProfile == "Public Limited Company") {
          formdataUpdateKyc.append("data[pan]", this.state.pan);
          formdataUpdateKyc.append("data[cin]", this.state.cinProfile);
          formdataUpdateKyc.append("data[llpin]", '');
          if (this.state.file != '') {
            typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
          }
        }
        if (this.state.debtSeekerSelectedShowValueForProfile == "Partnership" || this.state.debtSeekerSelectedShowValueForProfile == "Proprietorship") {
          formdataUpdateKyc.append("data[pan]", this.state.pan);
          formdataUpdateKyc.append("data[cin]", '');
          formdataUpdateKyc.append("data[llpin]", '');
          if (this.state.file != '') {
            typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
          }
        }
        if (this.state.debtSeekerSelectedShowValueForProfile == "LLP") {
          formdataUpdateKyc.append("data[pan]", this.state.pan);
          formdataUpdateKyc.append("data[cin]", '');
          formdataUpdateKyc.append("data[llpin]", this.state.llp);
          if (this.state.file != '') {
            typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
          }
        }
      }
      else if (localStorage.getItem("role_id") == "2") {
        if (this.state.debtProviderSelectedShowValueForProfile == "Individual" || this.state.debtProviderSelectedShowValueForProfile == "HNI" || this.state.debtProviderSelectedShowValueForProfile == "Family Office") {
          formdataUpdateKyc.append("data[pan]", this.state.pan);
          formdataUpdateKyc.append("data[cin]", '');
          formdataUpdateKyc.append("data[llpin]", '');
          formdataUpdateKyc.append("data[debt_provider]", this.state.kycDetails?.debt_provider);
          if (this.state.file != '') {
            typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
          }
        }
        if (this.state.debtProviderSelectedShowValueForProfile == "Bank" || this.state.debtProviderSelectedShowValueForProfile == "Mutual Funds" || this.state.debtProviderSelectedShowValueForProfile == "FI" || this.state.debtProviderSelectedShowValueForProfile == "NBFC") {
          formdataUpdateKyc.append("data[pan]", this.state.pan);
          formdataUpdateKyc.append("data[cin]", this.state.cinProfile);
          formdataUpdateKyc.append("data[llpin]", '');
          formdataUpdateKyc.append("data[debt_provider]", this.state.kycDetails?.debt_provider)
          if (this.state.file != '') {
            typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
          }
        }
      }
      else {
        if (localStorage.getItem("role_id") == "3") {
          if (this.state.debtArrangerSelectedShowValueForProfile == "Private Limited Company") {
            formdataUpdateKyc.append("data[pan]", this.state.pan);
            formdataUpdateKyc.append("data[cin]", this.state.cinProfile);
            formdataUpdateKyc.append("data[llpin]", '');
            formdataUpdateKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger)
            if (this.state.file != '') {
              typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
            }
          }
          if (this.state.debtArrangerSelectedShowValueForProfile == "LLP") {
            formdataUpdateKyc.append("data[pan]", this.state.pan);
            formdataUpdateKyc.append("data[cin]", '');
            formdataUpdateKyc.append("data[llpin]", this.state.llp);
            formdataUpdateKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger)
            if (this.state.file != '') {
              typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
            }
          }
          if (this.state.debtArrangerSelectedShowValueForProfile == "Partnership Firm" || this.state.debtArrangerSelectedShowValueForProfile == "Individual") {
            formdataUpdateKyc.append("data[pan]", this.state.pan);
            formdataUpdateKyc.append("data[cin]", '');
            formdataUpdateKyc.append("data[llpin]", '');
            formdataUpdateKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger)
            if (this.state.file != '') {
              typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
            }
          }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileKycDetailsUpdateAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateKYCDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerUpdateKyc)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataUpdateKyc
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  };
  /* istanbul ignore next */
  updateDematDetails = () => {
    if (this.checkValidationOfDemateProfile()) {
      const userToken = localStorage.getItem("token");
      this.state.dematDetailsForm.cmr_copy = this.state.dematCmrCopyName ?
        this.state.dematCmrCopyName :
        "";
      const formdata = new FormData();
      const header = {
        // "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: userToken
      };
      if(this.state.profileTabIndexValue==1){
        formdata.append("data[cdsl_dp_id]", this.state.dematDetailsForm?.cdsl_dp_id);
        formdata.append("data[cdsl_client_id]", this.state.dematDetailsForm?.cdsl_client_id);
        formdata.append("data[demat_detail_type]", "cdsl");
        formdata.append("data[nsdl_dp_id]", '');
        typeof (this.state.dematDetailsForm?.cdsl_cmr_copy) != 'string' && this.state.dematDetailsForm?.cdsl_cmr_copy && formdata.append("data[cdsl_cmr_copy]", this.state.dematDetailsForm?.cdsl_cmr_copy);
        }
        else{
          formdata.append("data[cdsl_dp_id]", '');
          formdata.append("data[cdsl_client_id]", '');
          formdata.append("data[nsdl_dp_id]", this.state.dematDetailsForm?.nsdl_dp_id);
          formdata.append("data[demat_detail_type]", "nsdl");
          typeof (this.state.dematDetailsForm?.nsdl_cmr_copy) != 'string' && this.state.dematDetailsForm?.nsdl_cmr_copy && formdata.append("data[nsdl_cmr_copy]", this.state.dematDetailsForm?.nsdl_cmr_copy);
        }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileDematDetailsUpdateAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateDematDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
      // }
    };
  };

  /* istanbul ignore next */
  bankFormValidationCheckProfile = () => {
    let check: string = "";
    if (this.state.cancelledCheck?.length != 0) {
      check = this.state.cancelledCheck?.name;
    }
    if (this.state.bankDetailsForm?.check_name?.length != 0) {
      check = this.state.bankDetailsForm?.check_name;
    }
    if (this.state.bankDetailsForm?.name?.length == 0) {
       const msg142: Message = new Message(getName(MessageEnum.CustomAlertMessage));
       msg142.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill name.");
      this.send(msg142);
      return false;
    };
    if (this.state.bankDetailsForm?.account_number?.length == 0) {
      const msg143: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg143.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill account number.");
      this.send(msg143);
      return false;
    }
    if (this.state.bankDetailsForm?.confirm_account_number?.length == 0) {
      const msg144: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg144.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill confirm account number.");
      this.send(msg144);
      return false;
    }
    if (this.state.bankDetailsForm?.ifsc_code?.length == 0) {
      const msg145: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg145.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill IFSC code.");
      this.send(msg145);
      return false;
    }
    if (check?.length == 0) {
      const msg146: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg146.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload cancel check.");
      this.send(msg146);
      return false;
    }
    if (this.state.bankDetailsForm?.bank_id == 0) {
      const msg147: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg147.addData(getName(MessageEnum.CustomAlertBodyData), "Please select bank name.");
      this.send(msg147);
      return false;
    }
    if (this.state.isErrorName) {
      const msg148: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg148.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove name validation error.");
      this.send(msg148);
      return false;
    }
    if (this.state.bankNoError) {
      const msg149: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg149.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove name validation error.");
      this.send(msg149);
      return false;
    }
    if (this.state.showErrorIfsc) {
      const msg150: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg150.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove IFSC error.");
      this.send(msg150);
      return false;
    }
    if (this.state.uploadCancelledCheckError) {
      const msg151: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg151.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove cancel check error.");
      this.send(msg151);
      return false;
    }
    if (this.state.bankDetailsForm?.account_number !== this.state.bankDetailsForm?.confirm_account_number) {
      const msg152: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg152.addData(getName(MessageEnum.CustomAlertBodyData), "Account and confirm account number does not match.");
      this.send(msg152);
      return false;
    }
    return true;
  };
  /* istanbul ignore next */
  updateBankDetails = () => {
    if (this.bankFormValidationCheckProfile()) {
      const userToken = localStorage.getItem("token");
      const formdata = new FormData();
      const header = {
        // "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: userToken
      };
      formdata.append("data[name]", this.state.bankDetailsForm.name);
      formdata.append("data[account_number]", this.state.bankDetailsForm.account_number);
      formdata.append("data[confirm_account_number]", this.state.bankDetailsForm.confirm_account_number);
      formdata.append("data[ifsc_code]", this.state.bankDetailsForm.ifsc_code);
      if (this.state.cancelledCheck) {
        formdata.append("data[check]", this.state.cancelledCheck);
      }
      formdata.append("data[bank_id]", this.state.bankDetailsForm?.bank_id?.toString())
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileBankDetailUpdateAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.updateBankDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  };
  /* istanbul ignore next */

  getPersonalDetails = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.PersonalDetailsAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonalDetailsAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */

  updatePersonalDetails = () => {
    this.validatePersonalDetails(this.state.personalDetailsForm);
    let phoneNumber = this.state.personalDetailsForm.country_code + this.state.personalDetailsForm.phone_number

    if (!this.state.isErrorName && !this.state.isErrorEmail && !this.state.isErrorCompanyName
      && !this.state.isErrorPhone) {
      const userToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: userToken
      };
      const httpBody = {
        data: {
          type: this.state.account_type,
          attributes: {
            ...this.state.personalDetailsForm,
            full_phone_number: phoneNumber
          },profile: {
            role_id :this.state.personalDetailsForm.role_id,
            status :true 
         }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfilePersonalDetailUpdateAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getPersonalDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiUpdateUserType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    // }
    // )
  };
  /* istanbul ignore next */
  verifyEmail = () => {
    if (this.state.old_email !== this.state.personalDetailsForm.email) {
      const userToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: userToken
      };
      const httpBody = {
        data: {
          attributes: {
            email: this.state.personalDetailsForm.email
          }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.verifyEmailAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.verifyEmailAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.callTypeApiValidateMobileNo
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  }
  /* istanbul ignore next */

  requestOTP = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const httpBody = {
      data: {
        attributes: {
          full_phone_number: "+91" + this.state.personalDetailsForm.phone_number
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.RequestOTPAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.RequestOTPAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    // }
  };
  /* istanbul ignore next */
  resendOTP = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const httpBody = {
      data: {
        attributes: {
          full_phone_number: "+91" + this.state.personalDetailsForm.phone_number
        }
      }
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.ResendOTPAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.ResendOTPAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */

  verifyMobileOtp = (OTP: any) => {
    const userToken = localStorage.getItem("MOBILE_OTP_TOKEN");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      // token: userToken
    };
    const httpBody = {
      token: userToken,
      pin: OTP.join(''),
      // full_full_phone_number: "+91" + this.state.personalDetailsForm.full_phone_number
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.VerifyOTPAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.VerifyOTPAPIEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.callTypeApiValidateMobileNo
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */

  checkEmail = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.contentTypeApiGetUserProfile,
      token: userToken
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.CheckEmailAPICalled = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.checkEmailAPIEndPoint + `?email=${this.state.personalDetailsForm.email}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */

  removeCMRCopy = () => {
    this.setState({
      dematCmrCopyName: {
        name: '',
      },
      uploadCmrCopyError: false
    })
    this.setState({
      dematDetailsForm: { ...this.state.dematDetailsForm, ['cdsl_cmr_copy_name']: "" }
    });
  }

  removeNsdlCMRCopy = () => {
    this.setState({
      demateNsdlCopyName: {
        name: '',
      },
      uploadNsdlCmrCopyError: false
    })
    this.setState({
      dematDetailsForm: { ...this.state.dematDetailsForm, ['nsdl_cmr_copy_name']: "" }
    });
  }
  /* istanbul ignore next */
  kycValidationCheckProfile = () => {
    let fileName: string = "";
    if (this.state.file?.length != 0) {
      fileName = this.state.file?.name;
    }
    if (this.state.panCardName?.length != 0) {
      fileName = this.state.panCardName;
    }
    if (localStorage.getItem('role_id') == "1") {
      if (this.state.debtSeekerSelectedShowValueForProfile == "Private Limited Company" || this.state.kycDetails.debt_seeker == "Public Limited Company") {
        if (this.state.cinProfile?.length == 0) {
          const msg152: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg152.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the CIN value.");
          this.send(msg152);
          return false;
        };
        if (this.state.cinProfileError) {
          const msg153: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg153.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the CIN error.");
          this.send(msg153);
          return false;
        }
      }
      if (this.state.debtSeekerSelectedShowValueForProfile == "LLP") {
        if (this.state.llp.length == 0) {
          const msg154: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg154.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the LLPIN value.");
          this.send(msg154);
          return false;
        }
        if (this.state.llpError) {
          const msg155: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg155.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the LLPIN error.");
          this.send(msg155);
          return false;
        }
      }
      if (fileName?.length == 0) {
        const msg156: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg156.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg156);
        return false;
      }
      if (this.state.pan?.length == 0) {
        const msg157: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg157.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the PAN value.");
        this.send(msg157);
        return false;
      }
      if (this.state.errorPanCard || this.state.isErrorPanNumber) {
        const msg158: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg158.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove PAN values error.");
        this.send(msg158);
        return false;
      }
      if (this.state.errorUploadPanPhoto) {
        const msg159: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg159.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the PAN photo error.");
        this.send(msg159);
        return false;
      }
    }
    if (localStorage.getItem('role_id') == "2") {
      if (this.state.debtProviderSelectedShowValueForProfile == "Bank" || this.state.kycDetails.debt_provider == "Mutual Funds" || this.state.kycDetails.debt_provider == "FI" || this.state.debtProviderSelectedShowValueForProfile == "NBFC") {
        if (this.state.cinProfile?.length == 0) {
          const msg160: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg160.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the CIN value.");
          this.send(msg160);
          return false;
        };
        if (this.state.cinProfileError) {
          const msg161: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg161.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the CIN error.");
          this.send(msg161);
          return false;
        }
      }
      if (this.state.pan?.length == 0) {
        const msg162: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg162.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the PAN value.");
        this.send(msg162);
        return false;
      }
      if (this.state.errorPanCard || this.state.isErrorPanNumber) {
        const msg163: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg163.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove PAN values error.");
        this.send(msg163);
        return false;
      }
      if (this.state.errorUploadPanPhoto) {
        const msg164: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg164.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the PAN photo error.");
        this.send(msg164);
        return false;
      }
      if (fileName?.length == 0) {
        const msg165: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg165.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg165);
        return false;
      }
    }
    if (localStorage.getItem("role_id") == "3") {
      if (this.state.debtArrangerSelectedShowValueForProfile == "Private Limited Company") {
        if (this.state.cinProfile?.length == 0) {
          const msg166: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg166.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the CIN value.");
          this.send(msg166);
          return false;
        }
        if (this.state.cinProfileError) {
          const msg167: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg167.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the CIN error.");
          this.send(msg167);
          return false;
        }
      }
      if (this.state.debtArrangerSelectedShowValueForProfile == "LLP") {
        if (this.state.llp?.length == 0) {
          const msg168: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg168.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill the LLPIN value.");
          this.send(msg168);
          return false;
        }
        if (this.state.llpError) {
          const msg169: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg169.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the LLPIN error.");
          this.send(msg169);
          return false;
        }
      }
      if (fileName?.length == 0) {
        const msg170: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg170.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg170);
        return false;
      }
    }
    return true;
  };
  /* istanbul ignore next */

  addKYCDetails = () => {
    if (this.kycValidationCheckProfile()) {
      const addKycToken = localStorage.getItem("token");
      const formdataAddKyc = new FormData();
      const headerAddKyc = {
        token: addKycToken
      };
      if (localStorage.getItem("role_id") == "1") {
        //@ts-ignore
        localStorage.getItem("role_id") == "1" && formdataAddKyc.append("data[debt_seeker]", this.state.kycDetails?.debt_seeker);
        if (this.state.debtSeekerSelectedShowValueForProfile == "Private Limited Company" || this.state.debtSeekerSelectedShowValueForProfile == "Public Limited Company") {
          formdataAddKyc.append("data[pan]", this.state.pan);
          formdataAddKyc.append("data[cin]", this.state.cinProfile);
          formdataAddKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtSeekerSelectedShowValueForProfile == "Partnership" || this.state.debtSeekerSelectedShowValueForProfile == "Proprietorship") {
          formdataAddKyc.append("data[pan]", this.state.pan);
          formdataAddKyc.append("data[cin]", '');
          formdataAddKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtSeekerSelectedShowValueForProfile == "LLP") {
          formdataAddKyc.append("data[pan]", this.state.pan);
          formdataAddKyc.append("data[cin]", '');
          formdataAddKyc.append("data[llpin]", this.state.llp);
          typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
        }
      }
      else if (localStorage.getItem("role_id") == "2") {
        if (this.state.debtProviderSelectedShowValueForProfile == "Individual" || this.state.debtProviderSelectedShowValueForProfile == "HNI" || this.state.debtProviderSelectedShowValueForProfile == "Family Office") {
          formdataAddKyc.append("data[pan]", this.state.pan);
          formdataAddKyc.append("data[cin]", '');
          formdataAddKyc.append("data[llpin]", '');
          formdataAddKyc.append("data[debt_provider]", this.state.kycDetails?.debt_provider)
          typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtProviderSelectedShowValueForProfile == "Bank" || this.state.debtProviderSelectedShowValueForProfile == "Mutual Funds" || this.state.debtProviderSelectedShowValueForProfile == "FI" || this.state.debtProviderSelectedShowValueForProfile == "NBFC") {
          formdataAddKyc.append("data[pan]", this.state.pan);
          formdataAddKyc.append("data[cin]", this.state.cinProfile);
          formdataAddKyc.append("data[llpin]", '');
          formdataAddKyc.append("data[debt_provider]", this.state.kycDetails?.debt_provider)
          typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
        }
      }
      else {
        if (localStorage.getItem("role_id") == "3") {
          if (this.state.debtArrangerSelectedShowValueForProfile == "Private Limited Company") {
            formdataAddKyc.append("data[pan]", this.state.pan);
            formdataAddKyc.append("data[cin]", this.state.cinProfile);
            formdataAddKyc.append("data[llpin]", '');
            formdataAddKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger);
            typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
          }
          if (this.state.debtArrangerSelectedShowValueForProfile == "LLP") {
            formdataAddKyc.append("data[pan]", this.state.pan);
            formdataAddKyc.append("data[cin]", '');
            formdataAddKyc.append("data[llpin]", this.state.llp);
            formdataAddKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger);
            typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
          }
          if (this.state.debtArrangerSelectedShowValueForProfile == "Partnership Firm" || this.state.debtArrangerSelectedShowValueForProfile == "Individual") {
            formdataAddKyc.append("data[pan]", this.state.pan);
            formdataAddKyc.append("data[cin]", '');
            formdataAddKyc.append("data[llpin]", '');
            formdataAddKyc.append("data[debt_arranger]", this.state.kycDetails?.debt_arranger);
            typeof (this.state.file) != 'string' && formdataAddKyc.append("data[pan_card]", this.state.file);
          }
        }
      }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileKycDetailsAddAPICalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addKYCDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerAddKyc)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataAddKyc
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  };
  /* istanbul ignore next */
  addBankDetails = () => {
    if (this.bankFormValidationCheckProfile()) {
      const BankAddToken = localStorage.getItem("token");
      const formdataBankAdd = new FormData();
      const headerBankAdd = {
        // "Content-Type": configJSON.contentTypeApiGetUserProfile,
        token: BankAddToken
      };
      formdataBankAdd.append("data[name]", this.state.bankDetailsForm.name);
      formdataBankAdd.append("data[account_number]", this.state.bankDetailsForm.account_number);
      formdataBankAdd.append("data[confirm_account_number]", this.state.bankDetailsForm.confirm_account_number);
      formdataBankAdd.append("data[ifsc_code]", this.state.bankDetailsForm.ifsc_code);
      if (this.state.cancelledCheck) {
        formdataBankAdd.append("data[check]", this.state.cancelledCheck);
      }
      formdataBankAdd.append("data[bank_id]", this.state.bankDetailsForm?.bank_id?.toString())

      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileBankDetailsAddApiCalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addBankDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerBankAdd)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataBankAdd
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  };
  /* istanbul ignore next */

  checkValidationOfDemateProfile = () => {
    let cmrName: string = "";
    if (this.state.dematCmrCopyName?.name?.length!=0) {
      cmrName = this.state.dematCmrCopyName?.name
    }
    if (this.state.dematDetailsForm?.cdsl_cmr_copy_name?.length!=0) {
      cmrName = this.state.dematDetailsForm?.cdsl_cmr_copy_name
    }
    let cmrNsdlName = "";
    if (this.state.dematDetailsForm?.nsdl_cmr_copy_name?.length!=0) {
      cmrNsdlName = this.state.dematDetailsForm?.nsdl_cmr_copy_name
    }
    if (this.state.demateNsdlCopyName?.name?.length!=0) {
      cmrNsdlName = this.state.demateNsdlCopyName?.name
    }
    if (this.state.profileTabIndexValue == "1") {
      if (this.state.dematDetailsForm?.cdsl_client_id?.length==0 || this.state.dematDetailsForm?.cdsl_client_id=="undefined") {
        const msg171: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg171.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill ClientID.");
        this.send(msg171);
        return false;
      }
      if (this.state.dematDetailsForm?.cdsl_dp_id?.length == 0 || this.state.dematDetailsForm?.cdsl_dp_id=="undefined") {
        const msg172: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg172.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill DPID.");
        this.send(msg172);
        return false;
      }
      if (this.state.isClientIdError) {
        const msg173: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg173.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove ClientID error.");
        this.send(msg173);
        return false;
      }
      if (this.state.isDPIdError) {
        const msg174: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg174.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove DpId error.");
        this.send(msg174);
        return false;
      }
      if (cmrName?.length == 0 || cmrName == null || cmrName == undefined) {
        const msg175: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg175.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload Demat CMR copy.");
        this.send(msg175);
        return false;
      }
      if (this.state.uploadCmrCopyError) {
        const msg176: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg176.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove uploading CMR copy error.");
        this.send(msg176);
        return false;
      }
    }
    else {
      if (this.state.dematDetailsForm?.nsdl_dp_id?.length == 0 || this.state.dematDetailsForm?.nsdl_dp_id===undefined) {
        const msg177: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg177.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill DPID.");
        this.send(msg177);
        return false;
      }
      if (this.state.isNsdlDpIdError) {
        const msg178: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg178.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove DpId error.");
        this.send(msg178);
        return false;
      }
      if (!this.state.dematDetailsForm?.nsdl_cmr_copy_name && !this.state.demateNsdlCopyName?.name) {
        const msg179: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg179.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload Demat CMR copy.");
        this.send(msg179);
        return false;
      }
      if (this.state.uploadNsdlCmrCopyError) {
        const msg180: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg180.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove uploading CMR copy error.");
        this.send(msg180);
        return false;
      }
    }
    return true;
  };
  /* istanbul ignore next */

  addDematDetails = () => {
    if (this.checkValidationOfDemateProfile()) {
      const DematToken = localStorage.getItem("token");
      this.state.dematDetailsForm.cmr_copy = this.state.dematCmrCopyName ?
        this.state.dematCmrCopyName :
        "";
      const formdataDematAdd = new FormData();
      const headerDematAdd = {
        token: DematToken
      };
      if(this.state.profileTabIndexValue==1){
        formdataDematAdd.append("data[cdsl_dp_id]", this.state.dematDetailsForm?.cdsl_dp_id);
        formdataDematAdd.append("data[cdsl_client_id]", this.state.dematDetailsForm?.cdsl_client_id);
        formdataDematAdd.append("data[demat_detail_type]", this.state.demat_detail_type);
        formdataDematAdd.append("data[nsdl_dp_id]", '');
        typeof (this.state.dematDetailsForm?.cdsl_cmr_copy) != 'string' && this.state.dematDetailsForm?.cdsl_cmr_copy && formdataDematAdd.append("data[cdsl_cmr_copy]", this.state.dematDetailsForm?.cdsl_cmr_copy);
        }
        else{
          formdataDematAdd.append("data[cdsl_dp_id]", '');
          formdataDematAdd.append("data[cdsl_client_id]", '');
          formdataDematAdd.append("data[nsdl_dp_id]", this.state.dematDetailsForm?.nsdl_dp_id);
          formdataDematAdd.append("data[demat_detail_type]", this.state.demat_detail_type);
          typeof (this.state.dematDetailsForm?.nsdl_cmr_copy) != 'string' && this.state.dematDetailsForm?.nsdl_cmr_copy && formdataDematAdd.append("data[nsdl_cmr_copy]", this.state.dematDetailsForm?.nsdl_cmr_copy);
        }
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.ProfileDemateDetailsAddApiCalled = requestMessage.messageId;

      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.addDematDetailsAPIEndPoint
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headerDematAdd)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataDematAdd
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    // }
  };
  /* istanbul ignore next */

  demateApiCall = () => {
    if (this.state.demateApiCalled) {
      this.updateDematDetails();
    }
    else {
      this.addDematDetails();
    }
  };
  /* istanbul ignore next */

  bankDetailApiCalledOne = () => {
    if (this.state.bankDetailApiCalled) {
      this.updateBankDetails();
    }
    else {
      this.addBankDetails();
    }
  };
  /* istanbul ignore next */

  kycApiCalled = () => {
    if (this.state.kycPostApiCalled) {
      this.updateKYCDetails();
    }
    else {
      this.addKYCDetails();
    }
  };
  /* istanbul ignore next */

  getAllRolesListing = () =>{
    const header = getHeaders({ token: this.authToken });

    const getRoles = apiCall({
      url: configJSON.getRolesListingApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getRolesListingApiMethod
    });

    this.getRolesListingApiCallId = getRoles.messageId;
    runEngine.sendMessage(getRoles.id, getRoles);
  }
  /* istanbul ignore next */

  getUserSpecificRoles = () => {
    const header = getHeaders({ token: this.authToken });

    const getUserSpecificRole = apiCall({
      url: configJSON.getUserSpecificRoleApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getUserSpecificRoleApiMethod
    });

    this.getUserSpecificRoleApiCallId = getUserSpecificRole.messageId;
    runEngine.sendMessage(getUserSpecificRole.id, getUserSpecificRole);
  }
  // Customizable Area End
}
