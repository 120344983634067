import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
interface DebtProfileInput {
    id?: any,
    name: string;
    debt_product_types_id: string;
    sanctioned_price: string;
    outstanding_price: string;
    _destroy: boolean;
    [key: string]: any;
}
interface productType {
    id: string;
    attributes: any;
    type: string;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    match: any;
    location: any;
    history: any;
    // Customizable Area End
}

interface S {
    arrayHolder: any;
    token: string;
    // Customizable Area Start
    isOutStanding_borrowing: boolean;
    borrowing_profile_asOf: string;
    lender: string;
    productList: productType[],
    productType: string;
    debt_product_types_id: string;
    sanctioned_price: string;
    outstanding_price: string;
    debtProfileInputObj: DebtProfileInput;
    debtProfileInputObjArray: DebtProfileInput[];
    deal_id: string;
    max_date: string;
    is_draft: boolean;
    isReadMode: boolean;
    isNext: boolean;
    redirect: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
}

export default class debtProfileController extends BlockComponent<Props, S, SS> {
    getProductApiCallId: any;
    // Customizable Area Start
    productListApiCallId: string = "";
    saveAndDraftApiCallId: string = "";
    showDebtProfileAPICalledId: string = "";
    updateDebtProfileSaveAndDraftApiCallId: string = "";
    submit_debtProfileApiCallId: string = "";
    update_submit_debtProfileApiCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.SessionSaveMessage),
            getName(MessageEnum.SessionResponseMessage),
            // Customizable Area Start
            getName(MessageEnum.redirectMessage),
            // Customizable Area End
        ];

        this.state = {
            arrayHolder: [],
            token: "",
            // Customizable Area Start
            isOutStanding_borrowing: false,
            borrowing_profile_asOf: "",
            deal_id: "",
            max_date: "2022-07-26",
            is_draft: true,
            lender: "",
            productType: "",
            productList: [],
            sanctioned_price: "",
            outstanding_price: "",
            debt_product_types_id: "",
            debtProfileInputObj: {
                name: "",
                debt_product_types_id: "",
                sanctioned_price: "",
                outstanding_price: "",
                _destroy: false
            },
            debtProfileInputObjArray: [{
                name: "",
                debt_product_types_id: "",
                sanctioned_price: "",
                outstanding_price: "",
                _destroy: false
            }],
            isReadMode: false,
            isNext: false,
            redirect: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
        // Customizable Area Start
        // Customizable Area End
    }

    async componentDidMount() {
        super.componentDidMount();
        this.getToken();
        if (this.isPlatformWeb() === false) {
            this.props.navigation.addListener("willFocus", () => {
                this.getToken();
            });
        }
    }

    getToken = () => {
        const msg: Message = new Message(
            getName(MessageEnum.SessionRequestMessage)
        );
        this.send(msg);
    };

    getListRequest = (token: any) => {
        const header = {
            "Content-Type": configJSON.productApiContentType,
            token: token,
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getProductApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.productAPiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiMethodTypeGet
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    async receive(from: string, message: Message) {
        // Customizable Area Start
        var responseJson = message.getData(
            getName(MessageEnum.RestAPIResponceSuccessMessage)
        );

        var errorReponse = message.getData(
            getName(MessageEnum.RestAPIResponceErrorMessage)
        );

        const apiRequestCallId = message.getData(
            getName(MessageEnum.RestAPIResponceDataMessage)
        );
        /* istanbul ignore next */
        if (message.id === getName(MessageEnum.redirectMessage)) {
            const customRedirect = message.getData(getName(MessageEnum.redirectData));
            if (this.state.redirect && customRedirect) {
                this.props.history.push("/Catalogue/SupportiveDocuments");
            }
        }
        if (responseJson) {
            if (responseJson && this.productListApiCallId === apiRequestCallId) {
                this.setState({ productList: responseJson.data })
            }
            if (responseJson && this.saveAndDraftApiCallId === apiRequestCallId) {
                responseJson?.data?.id && localStorage.setItem("debtProfile_id", responseJson?.data?.id)
                if(responseJson?.meta?.message){
                    const msg53: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg53.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                    this.send(msg53);
                } 
                if (responseJson?.data) {
                    this.showDebtProfile()
                }
                else {
                    let errorMessage = "";
                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg54: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg54.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg54);
                }

            }

            if (responseJson && this.updateDebtProfileSaveAndDraftApiCallId === apiRequestCallId) {
                if(responseJson?.meta?.message){
                    const msg55: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg55.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                    this.send(msg55);
                } 
                if (responseJson?.errors?.length > 1) {
                    let errorMessage = "";
                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg56: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg56.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg56);
                    return;
                }else{
                    if (responseJson?.errors?.length === 1 ){
                        const msg57: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                        msg57.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]);
                        this.send(msg57); 
                    }
                  
                }

                this.showDebtProfile()

            }

            if (apiRequestCallId === this.showDebtProfileAPICalledId) {
                if (responseJson?.data?.id) {
                    localStorage.setItem("debtProfile_id", responseJson?.data?.id)
                    this.setState({ isOutStanding_borrowing: responseJson?.data?.attributes?.is_outstanding_borrowing })
                    let tempDebtProfileArray: DebtProfileInput[] = [];

                    if (responseJson?.data?.attributes?.outstanding_borrowing_details === "No outstanding borrowing") {

                    }
                    else {
                        responseJson?.data?.attributes?.outstanding_borrowing_details?.lenders_details?.data.map((lender: any, index: any) => {

                            let tempDebtObjectInput: any = {
                                id: lender?.id,
                                name: lender?.attributes?.name ? lender?.attributes?.name : "",
                                debt_product_types_id: lender?.attributes?.debt_product_types_id,
                                sanctioned_price: lender?.attributes?.sanctioned_price ? JSON.stringify(lender?.attributes?.sanctioned_price) : "",
                                outstanding_price: lender?.attributes?.outstanding_price ? JSON.stringify(lender?.attributes?.outstanding_price) : lender?.attributes?.outstanding_price,
                                _destroy: false
                            }

                            tempDebtProfileArray.push(tempDebtObjectInput);

                        })
                        this.setState({ debtProfileInputObjArray: tempDebtProfileArray })
                        this.setState({ borrowing_profile_asOf: responseJson?.data?.attributes?.outstanding_borrowing_details?.borrowing_profile_as_of })
                    }
                }
                else {
                    localStorage.setItem("debtProfile_id", "");
                }
            }
            /* istanbul ignore next */
            if (apiRequestCallId === this.submit_debtProfileApiCallId) {
                responseJson?.data?.id && localStorage.setItem("debtProfile_id", responseJson?.data?.id)
                if (responseJson?.data) {
                    localStorage.setItem("debtProfile_id", responseJson?.data?.id)
                    const msg58: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg58.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                    this.send(msg58); 
                    this.setState({redirect:true})
                }
                else {
                    let errorMessage = "";
                    responseJson?.errors?.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg59: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg59.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg59); 
                    this.setState({ redirect: false })
                }
            }
            /* istanbul ignore next */
            if (apiRequestCallId === this.update_submit_debtProfileApiCallId) {
                if (responseJson?.meta?.message) {
                    const msg60: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg60.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
                    this.send(msg60); 
                    this.setState({ redirect: true })
                }
                else {
                    let errorMessage = "";
                    responseJson?.errors.forEach((message: any, index: any) => {
                        Object.keys(message).forEach((key) => {
                            errorMessage = `${errorMessage} ${key} ${message[key]} \n`
                        })
                    })
                    const msg61: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                    msg61.addData(getName(MessageEnum.CustomAlertBodyData), errorMessage);
                    this.send(msg61); 
                    this.setState({ redirect: false })
                }
            }
        }



        runEngine.debugLog("Message Recived", message);
        if (getName(MessageEnum.SessionResponseMessage) === message.id) {
            let token = message.getData(getName(MessageEnum.SessionResponseToken));
        }

        // Customizable Area End
    }

    // Customizable Area Start
    getProductTypeList = () => {
        let token = localStorage.getItem("token");

        const headers = {
            "Content-Type": configJSON.debtProfileApiContentType,
            token
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.productListApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.debtProductListEndPoint
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );

        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    //SaveAsDraft
    saveAsDraft = () => {
        // let token = ;
        let token = localStorage.getItem("token");
        const headers = {
            "Content-Type": configJSON.debtProfileApiContentType,
            token
        };

        const data = {
            borrow_profile: {
                profile_date: this.state.borrowing_profile_asOf
            },
            is_outstanding_borrowing: this.state.isOutStanding_borrowing,
            lenders: this.state.debtProfileInputObjArray
        }

        const httpBody = {
            is_draft: this.state.is_draft,
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.saveAndDraftApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles?deal_id=${localStorage.getItem("deal_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }


    update_saveAsDraft = () => {
        let token = localStorage.getItem("token");

        const headers = {
            "Content-Type": configJSON.debtProfileApiContentType,
            token
        };

        const data = {
            borrow_profile: {
                profile_date: this.state.borrowing_profile_asOf
            },
            is_outstanding_borrowing: this.state.isOutStanding_borrowing,
            lenders: this.state.debtProfileInputObjArray
        }

        const httpBody = {
            is_draft: this.state.is_draft,
            deal_id: `${localStorage.getItem("deal_id")}`,
            data: data,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDebtProfileSaveAndDraftApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("debtProfile_id")}`,
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }



    submit_debtProfile = () => {
        let token = localStorage.getItem("token");

        const headers = {
            "Content-Type": configJSON.debtProfileApiContentType,
            token
        };

        const data = {
            borrow_profile: {
                profile_date: this.state.borrowing_profile_asOf
            },
            is_outstanding_borrowing: this.state.isOutStanding_borrowing,
            lenders: this.state.debtProfileInputObjArray
        }

        const httpBody = {
            is_draft: !this.state.is_draft,
            data: data,
            deal_id: `${localStorage.getItem("deal_id")}`
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.submit_debtProfileApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles?deal_id=${localStorage.getItem("deal_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "POST"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    update_submit_debtProfile = () => {
        let token = localStorage.getItem("token");

        const headers = {
            "Content-Type": configJSON.debtProfileApiContentType,
            token
        };

        const data = {
            borrow_profile: {
                profile_date: this.state.borrowing_profile_asOf
            },
            is_outstanding_borrowing: this.state.isOutStanding_borrowing,
            lenders: this.state.debtProfileInputObjArray
        }

        const httpBody = {
            is_draft: !this.state.is_draft,
            data: data,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.update_submit_debtProfileApiCallId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("debtProfile_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "PUT"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }



    showDebtProfile() {
        const headers = {
            token: `${localStorage.getItem("token")}`,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showDebtProfileAPICalledId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles?deal_id=${localStorage.getItem("deal_id")}&id=${localStorage.getItem("debtProfile_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    getDebtProfile() {
        const headers = {
            token: `${localStorage.getItem("token")}`,
        };

        const getValidationsMsg = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.showDebtProfileAPICalledId = getValidationsMsg.messageId;

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_debt_profile/debt_profiles/show_debt_profile?deal_id=${localStorage.getItem("deal_id")}`
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        getValidationsMsg.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            "GET"
        );
        runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);

    }

    // Customizable Area End
}
