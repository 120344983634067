// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { StructureSecurityDetails, ShareholdingPattern, typesOfStructureAndSecurity } from "./Interface.web";
import { checkForReadMode } from "../../../components/src/common";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  match: any;
  location: any;
  history: any;

}

interface S {
  arrayHolder: any;
  token: string;
  share_holdings: any[];
  security_details: any[];
  security: string;
  description: string;
  share_holding_as_on: string;
  wordCount: number;
  security_cover: string;
  typesOfShareHoldings: typesOfStructureAndSecurity[];
  typesOfSecurity: typesOfStructureAndSecurity[];
  typesOfCharges: typesOfStructureAndSecurity[];
  deal_id: string;
  redirect: boolean;
  isNext: boolean;
  isReadMode: boolean;
  openMsgDialog: boolean;
  security_detailsId: any[];
  share_holdingsId: any[]
}

interface SS {
  id: any;
}

export default class StructureAndSecurityController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  apiGetTypesOfShareholdings: string = '';
  apiGetTypesOfSecurity: string = '';
  apiGetTypesOfCharges: string = '';
  createSecutrityAndStructure_APICalledId: string = '';
  handleSaveDraftId: string = '';
  getSaveAsDraftDataId: string = '';
  handleSaveDraftUpdateId: string = '';

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.redirectMessage),
      getName(MessageEnum.SessionResponseMessage)
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      share_holdings: [{ share_holder_type_id: '', percentage_holding: '', remarks: '', _destroy: false }],
      security_details: [{ type_of_security_id: '', type_of_charge_id: '', details: '', value_inr_core: '', remarks: '', _destroy: false }],
      security: 'secured',
      share_holding_as_on: '',
      description: '',
      security_cover: '',
      typesOfShareHoldings: [],
      typesOfSecurity: [],
      typesOfCharges: [],
      wordCount: 0,
      deal_id: '',
      redirect: false,
      isNext: false,
      isReadMode: false,
      openMsgDialog: false,
      security_detailsId: [],
      share_holdingsId: [],

    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo(0, 0);
    const isReadMode = checkForReadMode(this.props.location);
    this.setState({
      isReadMode: isReadMode
    })
    const security_id = localStorage.getItem("security_id");
    this.setState({ deal_id: `${localStorage.getItem("deal_id")}` })
    this.getTypesOfShareholdings();
    this.getTypesOfSecurity();
    this.getTypesOfCharges();
    //   if(security_id){
    //   this.getSaveAsDraftData()
    // }else{
    this.getStructureAndSecurityDetails()
    // }
  }



  async receive(from: string, message: Message) {
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      if (this.state.redirect && customRedirect) {
        this.props.history.push("/Catalogue/ProjectDetails")
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      /* istanbul ignore next */
      if (responseJson) {
        if (responseJson && responseJson.errors) {
          if (responseJson && responseJson.errors[0] && responseJson.errors[0].token === 'Token has Expired') {
            localStorage.removeItem("token")
          } else if (Array.isArray(responseJson && responseJson.errors)) {

            let res = responseJson && responseJson.errors.map((i: any) => (Object.keys(i) + ' ' + Object.values(i))).join('\n')
            const msg41: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg41.addData(getName(MessageEnum.CustomAlertBodyData), res);
            this.send(msg41);
            this.setState({ isNext: false, redirect: false })
          }
        } else {
          if (this.apiGetTypesOfShareholdings === apiRequestCallId) {
            this.setState({
              typesOfShareHoldings: responseJson && responseJson.data && responseJson.data.data,
            })
          }
          if (this.apiGetTypesOfSecurity === apiRequestCallId) {
            this.setState({
              typesOfSecurity: responseJson && responseJson.data && responseJson.data.data
            })
          }
          if (this.apiGetTypesOfCharges === apiRequestCallId) {
            this.setState({
              typesOfCharges: responseJson && responseJson.data && responseJson.data.data
            })
          }

          /* istanbul ignore next */
          if (this.handleSaveDraftId === apiRequestCallId) {
            localStorage.setItem("security_id", responseJson && responseJson.data && responseJson.data.data && responseJson.data.data.id)
            const msg42: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg42.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
            this.send(msg42);
            responseJson.meta.message !== "Draft data saved successfully." && this.setState({ redirect: true })
            if (responseJson.meta.message === 'Draft data saved successfully.') {
              responseJson.data.data?.attributes?.security_details.map((item: any) => {
                let itemId = {
                  id: item.id
                }
                this.state.security_detailsId.push(itemId)
              })
              responseJson.data.data?.attributes?.share_holdings.map((item: any) => {
                let itemId = {
                  id: item.id
                }
                this.state.share_holdingsId.push(itemId)

              })
            }
          } else {
            this.setState({ redirect: false })
          }
          if (this.getSaveAsDraftDataId === apiRequestCallId) {
            if (responseJson && responseJson.data && responseJson.data.data) {
              this.setState({ security: responseJson.data.data.attributes && responseJson.data.data.attributes.security })
              this.setState({ description: responseJson.data.data.attributes && responseJson.data.data.attributes.description })
              this.setState({ share_holding_as_on: responseJson.data.data.attributes && responseJson.data.data.attributes.share_holding_as_on })
              responseJson.data.data.attributes && responseJson.data.data.attributes.share_holdings.length > 0 && this.setState({ share_holdings: responseJson.data.data.attributes.share_holdings })
              responseJson.data.data.attributes && responseJson.data.data.attributes.security_details.length > 0 && this.setState({ security_details: responseJson.data.data.attributes.security_details })
              this.setState({ security_cover: responseJson.data.data.attributes && responseJson.data.data.attributes.security_cover })
              localStorage.setItem("security_id", responseJson.data.data.id)
            }
          }
          if (this.handleSaveDraftUpdateId === apiRequestCallId) {
            if (responseJson && responseJson.data) {
              const msg43: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg43.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
              this.send(msg43);
              responseJson.meta.message !== "Draft data saved successfully." && this.setState({ redirect: true })
            } else {
              this.setState({ redirect: false })
            }
          }
        }
      }
    }
  }

  onlyNumberRegex = /^\d+$/;

  handleChangeInput = (i: any, e: any) => {
    this.setState({ isNext: true })
    const { name, value } = e.target;
    /* istanbul ignore next */
    if (name === 'percentage_holding') {
      if (this.onlyNumberRegex.test(value) || value === '') {
        if ((value < 101 && value >= 0)) {
          const arr = [...this.state.share_holdings];
          arr[i][name] = value;
          this.setState({ share_holdings: arr });
        } else {
          const msg44: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg44.addData(getName(MessageEnum.CustomAlertBodyData), '% Holding range is 0-100');
          this.send(msg44);
        }
      }
    } else {
      const arr = [...this.state.share_holdings];
      arr[i][name] = value;
      this.setState({ share_holdings: arr });
    }
  };
  handleChangeInput2 = (i: any, e: any) => {
    const { name, value } = e.target;
    const arr = [...this.state.security_details];

    if (name === 'value_inr_core') {
      if (this.onlyNumberRegex.test(value) || value === '') {
        arr[i][name] = value;
      }
    } else {
      arr[i][name] = value;
    }


    this.setState({ security_details: arr });
    this.setState({ isNext: true });
  };

  handleAddClick = () => {
    this.setState((prevState) => ({
      share_holdings: [
        ...prevState.share_holdings,
        { share_holder_type_id: '', percentage_holding: '', remarks: '', _destroy: false },
      ],
    }));
  };
  handleAddClick2 = () => {
    this.setState((prevState: any) => ({
      security_details: [
        ...prevState.security_details,
        { type_of_security_id: '', type_of_charge_id: '', details: '', value_inr_core: '', remarks: '', _destroy: false },
      ],
    }));
  };
  changeDescription = (e: any) => {
    e.target.value.charAt(0) !== ' ' && this.setState({ description: e.target.value })
    this.setState({ isNext: true })
    if (e.target.value.length > 0) {
      var count = e.target.value.match(/(\w+)/g)?.length;
      // var count = e.target.value.length;
      this.setState({ wordCount: count })
    }
  }

  handleSaveAsDraft = () => {
    const security_id = localStorage.getItem("security_id");
    security_id ? this.handleSaveDraftUpdate(true) : this.handleSaveDraft(true)
  };

  handleSecurity = (e: any) => {
    this.setState({ security: e.target.value, isNext: true })
    if (e.target.value == 'unsecured') {
      this.setState({ security_cover: '' })
      this.setState((prevState: any) => ({
        security_details: [
          { type_of_security_id: '', type_of_charge_id: '', details: '', value_inr_core: '', remarks: '', _destroy: false },
        ],
      }))
    }
  }

  handleSecurityCover = (e: any) => {
    this.setState({ security_cover: e.target.value, isNext: true })
  }

  countPercentHolding = (value: string): React.MouseEventHandler<HTMLButtonElement> => {
    return () => {
      let totalPercentage = 0;
      for (let i = 0; i < this.state.share_holdings.length; i++) {
        totalPercentage += parseInt(this.state.share_holdings[i].percentage_holding);
      }
      /* istanbul ignore next */
      if (value == 'submit' && totalPercentage < 100) {
        const msg45: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg45.addData(getName(MessageEnum.CustomAlertBodyData), 'Total % Holdings should be 100%. Please add the remaining %Holdings by selecting the Shareholder Type as "Others".');
        this.send(msg45);
      } else {
        value == 'submit' && this.handleSubmitNext()
        value == 'draft' && this.handleSaveAsDraft()
      }
    };
  }


  returnCurrentData = () => {
    const { share_holdings, security_details, security, share_holding_as_on, description, security_cover } = this.state;
    /* istanbul ignore next */
    const isEmpty = (value: string | boolean) => value === '' || value === false;

    const areAllValuesEmpty = [...share_holdings, ...security_details].every(item => {
      const values = Object.values(item);
      return values.every((value: any) => isEmpty(value));
    });

    const payload = {
      description,
      share_holding_as_on,
      security,
      security_cover,
    };
    const isPayloadEmpty = Object.values(payload).every(value => value === '' || value == null || value === "secured")

    if (isPayloadEmpty && areAllValuesEmpty) {
      return true
    } else {
      return false
    }
  }
  /* istanbul ignore next */
  handleSubmitNext = () => {
    const isPayloadEmpty = this.returnCurrentData()
    const security_id = localStorage.getItem("security_id");
    if (!isPayloadEmpty) {
      if (this.state.description?.length > 500) {
        const msg46: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg46.addData(getName(MessageEnum.CustomAlertBodyData), 'Description should be between(0-500 words)');
        this.send(msg46);
      } else {
        console.log("@@@---------------------", security_id)
        this.setState({ redirect: true })
        security_id ? this.handleSaveDraftUpdate(false) : this.handleSaveDraft(false)
      }
    } else {
      const msg47: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg47.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill all the mandatory fields.');
      this.send(msg47);
    }
  }

  handleFilter = (i: any) => {
    const updatedArray = [...this.state.share_holdings]
    updatedArray[i]._destroy = true;
    this.setState({ share_holdings: updatedArray, isNext: true })
  }
  handleFilter2 = (i: any) => {
    const updatedArray = [...this.state.security_details]
    console.log(updatedArray[i]._destroy);
    updatedArray[i]._destroy = true;
    this.setState({ security_details: updatedArray, isNext: true })
  }

  getTypesOfShareholdings = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTypesOfShareholdings = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.typesOfShareholdingsEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTypesOfSecurity = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTypesOfSecurity = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.typesOfSecuritiesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  getTypesOfCharges = () => {
    const userToken = localStorage.getItem("token");
    const header = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiGetTypesOfCharges = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.typesOfChargesEndpoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  openMessageDialog = (val: boolean) => {
    this.setState({
      openMsgDialog: val
    })
  }

  handlePopoverClose = () => {
    this.setState({
      openMsgDialog: false
    })
  }

  handleSaveDraft = (isDraft: boolean) => {
    const { share_holdings, security_details, security, share_holding_as_on, description, security_cover } = this.state;
    const userToken = localStorage.getItem("token");

    const headers = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const data = {
      description,
      share_holding_as_on,
      share_holdings,
      security_details,
      security,
      security_cover,
    };
    const httpBody = {
      deal_id: this.state.deal_id,
      data,
      is_draft: isDraft

    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleSaveDraftId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/structures?deal_id=${this.state.deal_id}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);





  };
  handleSaveDraftUpdate = (isDraft: boolean) => {
    const { share_holdings, security_details, security, share_holding_as_on, description, security_cover } = this.state;
    const userToken = localStorage.getItem("token");
    const security_id = localStorage.getItem("security_id");
    const deal_id = localStorage.getItem("deal_id");

    let securityDetails = security_details
    this.state.security_detailsId.length < 2 && this.state.security_detailsId.map((item: any, index: any) => {
      securityDetails[index].id = item.id
      this.setState({ security_details: securityDetails })
    })
    let shareHoldings = share_holdings
    this.state.share_holdingsId.length < 2 && this.state.share_holdingsId.map((item: any, index: any) => {
      shareHoldings[index].id = item.id
      this.setState({ share_holdings: shareHoldings })
    })

    const headers = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const data = {
      description,
      share_holding_as_on,
      share_holdings,
      security_details,
      security,
      security_cover,
    };
    const httpBody = {
      deal_id: this.state.deal_id,
      data,
      is_draft: isDraft

    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.handleSaveDraftUpdateId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), `account_block/structures/${security_id}`
    )

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  };

  getStructureAndSecurityDetails = () => {
    const userToken = localStorage.getItem("token");
    const deal_id = localStorage.getItem("deal_id");
    const header = {
      "Content-Type": configJSON.applicationType,
      token: userToken
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getSaveAsDraftDataId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `account_block/structures/show_structure?deal_id=${deal_id}`
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  // Customizable Area End
}
