import React from "react";
// Customizable Area Start
import {
    Box, Button, Checkbox, Grid, IconButton, ListItemText, MenuItem, Select, Typography
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AppHeader from "./AppHeader.web";
import { active, back, inactive, download_icon } from "./assets";
import { withStyles } from "@material-ui/core/styles";


import { isArrayEmpty } from "../../../components/src/common";
import PieChart from "../../../components/src/Piechart";

import SeekerArrangerDealDetailsController, { Props, VisbilityOptionsEnum } from "./SeekerArrangerDealDetailsController.web";
import { DealDetailsHeader, DealInput, SecurityToSupportiveDoc, StructureAndShareHolding } from "../../../components/src/DealDetailsCommonComponent";
import { CustomableSelect } from "../../../components/src/CustomableSelect";

class SeekerArrangerDealDetails extends SeekerArrangerDealDetailsController {
    constructor(props: Props) {
        super(props);
    }
    render() {
        const { dealDetails } = this.state;
        const { classes } = this.props
        return <div style={{ overflowX: 'hidden' }}>

            <AppHeader id="" location="" history="" navigation={this.props.navigation} match="" />
            <div style={{ padding: "40px 40px 0 50px", marginTop: "50px" }}>
                <div className="para_wrapper">
                    <div className="deal_header_wrapper">
                        <ArrowBackIosIcon data-test-id="goBack" onClick={() => history.go(-1)} />
                        <p className="deal_header">Deal Details</p>
                    </div>
                    <div data-test-id="isShowVisibilitySettings" onClick={() => { this.setState({ isShowVisibilitySettings: true }) }}
                        style={{ display: 'flex', alignItems: 'center', color: '#001d56', cursor: 'pointer' }}>
                        <p style={{ paddingRight: '15', fontSize: '18px' }}>  Deal Visibility Settings </p>
                        <div>
                            <ArrowForwardIosIcon style={{ fontSize: '1rem' }} />
                            <ArrowForwardIosIcon style={{ fontSize: '1rem', marginRight: '-8px' }} />
                        </div>
                    </div>
                </div>
                <DealDetailsHeader dealDetails={dealDetails} />
                <DealInput dealDetails={dealDetails} />
                <StructureAndShareHolding dealDetails={dealDetails} />
                <PieChart pieChartData={this.state.pieData} shareHolderType={dealDetails?.structures?.data?.attributes?.share_holdings} />
                <SecurityToSupportiveDoc dealDetails={dealDetails} download_icon={download_icon} rows={this.state.excelData} />

                <Modal
                    disableAutoFocus={true}
                    disableEnforceFocus={true}
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    data-test-id="modal-switch"
                    open={this.state.isShowVisibilitySettings}
                    onClose={() => this.setState({ isShowVisibilitySettings: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={this.state.isShowVisibilitySettings}>
                        <Box style={{
                            minHeight: (this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS || this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR) ? 438 : 357,
                            width: 438,
                            backgroundColor: '#FFFFFF',
                            boxShadow: '-2px 2px 4px rgba(204, 204, 204, 0.5)',
                            borderRadius: 32,
                            paddingTop: 32
                        }} >
                            <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: 35 }} >
                                <IconButton data-test-id="visibilityFalse" onClick={() => this.setState({ isShowVisibilitySettings: false })} style={{ padding: 0 }}>
                                    <img src={back} style={{ width: 11, height: 20 }} />
                                </IconButton>

                                <Typography style={{
                                    color: '#323232',
                                    fontSize: 24,
                                    fontWeight: 700,
                                    marginLeft: 31
                                }} >Deal Visibility Settings</Typography>
                            </Box>
                            <Box style={{ display: 'flex', flexDirection: 'column', width: '100%', alignItems: 'center', justifyContent: 'center', marginTop: 24 }} >
                                <Box style={{
                                    backgroundColor: 'rgba(0, 29, 86, 0.03)',
                                    minHeight: 184,
                                    width: 311,
                                    borderRadius: 12,
                                    paddingTop: 24,
                                    paddingLeft: 24,
                                    marginBottom: 24
                                }} >

                                    <Box data-test-id="visibilityOption" onClick={() => this.setState({
                                        selectedVisibilityOption: VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS
                                    })} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: 18 }} >
                                        <img src={this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS ? active : inactive} style={{ height: 24, width: 24, marginRight: 16 }} />
                                        <Typography style={{
                                            color: '#323232',
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }} >Show to all investors</Typography>
                                    </Box>

                                    <Box data-test-id="visibilityOption2" onClick={() => this.setState({
                                        selectedVisibilityOption: VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS
                                    })} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: 18 }} >
                                        <img src={this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS ? active : inactive} style={{ height: 24, width: 24, marginRight: 16 }} />
                                        <Typography style={{
                                            color: '#323232',
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }} >Show to selected investors</Typography>

                                    </Box>
                                    {
                                        this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS &&
                                        <CustomableSelect
                                            value={this.state.selectedProviders}
                                            onChange={this.handleVisibilitySettingsDropdownChange}
                                            className={classes.selectContainer}
                                            renderValue={(selected: any) =>
                                                selected.length === 0 ? (
                                                    <Typography style={{ fontSize: 16, color: '#323232', fontWeight: 400 }}>Please Select Provider</Typography>
                                                ) : (
                                                    this.state.providerList
                                                        .filter(provider => selected.includes(provider.id))
                                                        .map((provider) => provider.full_name)
                                                        .join(', ')
                                                )
                                            }
                                            providerList={this.state.providerList}
                                        />
                                    }

                                    <Box data-test-id="visibilityOption3" onClick={() => this.setState({
                                        selectedVisibilityOption: VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR
                                    })} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: 18 }} >
                                        <img src={this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR ? active : inactive} style={{ height: 24, width: 24, marginRight: 16 }} />
                                        <Typography style={{
                                            color: '#323232',
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }} >Show to all investors except for</Typography>

                                    </Box>
                                    {
                                        this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR &&
                                        <CustomableSelect
                                            value={this.state.selectedProviders}
                                            onChange={this.handleVisibilitySettingsDropdownChange}
                                            className={classes.selectContainer}
                                            renderValue={(selected: any) =>
                                                selected.length === 0 ? (
                                                    <Typography style={{ fontSize: 16, color: '#323232', fontWeight: 400 }}>Please Select Provider</Typography>
                                                ) : (
                                                    this.state.providerList
                                                        .filter(provider => selected.includes(provider.id))
                                                        .map((provider) => provider.full_name)
                                                        .join(', ')
                                                )
                                            }
                                            providerList={this.state.providerList}
                                        />
                                    }
                                    <Box data-test-id="visibilityOption4" onClick={() => this.setState({
                                        selectedVisibilityOption: VisbilityOptionsEnum.DONT_GO_LIVE_AS_OF_NOW
                                    })} style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', cursor: 'pointer', marginBottom: 18 }} >
                                        <img data-test-id="image" onClick={() => this.setState({
                                            selectedVisibilityOption: VisbilityOptionsEnum.DONT_GO_LIVE_AS_OF_NOW
                                        })} src={this.state.selectedVisibilityOption === VisbilityOptionsEnum.DONT_GO_LIVE_AS_OF_NOW ? active : inactive} style={{ height: 24, width: 24, marginRight: 16 }} />
                                        <Typography style={{
                                            color: '#323232',
                                            fontSize: 16,
                                            fontWeight: 400,
                                        }} >Don’t go live as of now</Typography>
                                    </Box>
                                </Box>
                                <Button data-test-id="submitVisibilitySettings" onClick={() => this.submitVisibilitySettings()} className="visibility_settings_submit_button" >Submit</Button>
                            </Box>
                        </Box>
                    </Fade>
                </Modal>
            </div >
        </div >;
    }
}
const styles = () => ({
    selectContainer: {
        border: '1px solid #CCCCCC',
        borderRadius: '11px !important',
        height: 45,
        width: '85%',
        marginBottom: 16,
        paddingLeft: 16
    },
});

export default withStyles(styles as any)(SeekerArrangerDealDetails);

// Customizable Area End
