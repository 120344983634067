// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
  Popover
} from "@material-ui/core";
import './dealDashboard.css';
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import StarIcon from "@material-ui/icons/Star";
import moment from "moment";
import { Link } from "react-router-dom";
import Slider from "react-slick";
import Avatar from '@material-ui/core/Avatar';

import AddBoxIcon from '@material-ui/icons/AddBox';
import { image_take, downArrow } from './assets';
import DealDashboardController from "./DealDashboardController.web";
import ContentManagementController, {
  configJSON,
  Props,
} from "./ContentManagementController";
import { filterIcon } from "./assets";
import { checkTotalDeal, isArrayEmpty, showTextWithValidation } from "../../../components/src/common";
import DealHistoryModal from "./DealHistory.web";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Chat9 from "../../Chat9/src/Chat9.web";

export default class DealsDashboard extends DealDashboardController {
  constructor(props: Props) {
    super(props);
  }
  
  render() {
    const settings = {
      slidesToShow: this.state.innerWidth > 0 && this.state.innerWidth <= 768 ? 1 : this.state.innerWidth > 768 && this.state.innerWidth < 1440 ? 2 : 2,
      slidesToScroll: 1,
      dots: false,
      arrows: true,
      swipeToSlide: true,
      infinite: false,
      draggable: false,
      touchMove: false,
    };
    return (
      <Grid>
        <Box className="content_management_root">
          <Container>
            {!this.state.isKycDone && <Box onClick={this.handlePendingKycClick} style={{ cursor: "pointer" }} className="text">
              <Typography variant="inherit" className="kyc_information_typography">Please complete the pending KYC</Typography>
            </Box>}
            <Box className= "button_outer_box">
              <Box className="button_parent_box">
                
                <Button data-testid="ongoing-deals-button" className={this.state.onGoingDealActiveButton ? "button_color_active" : "button_color"} onClick={this.handleOnGoing}>Ongoing Deal(s)</Button>
                <Button data-testid="transaction-history-button" className={this.state.transactionHistoryActiveButton ? "button_color_active" : "button_color"}
                  onClick={this.handleTransactionHistory}
                >Transaction History</Button>
              </Box>
            </Box>
            <Box className="filter_parent_box">
              {this.state.isIssuer ? <React.Fragment>
                <Box flexGrow={1} style={{ display: "flex", justifyContent: "left", }} >
                  <Button data-testid="filter-button" onClick={this.openFilterHandler} variant="contained" className="filter_button" endIcon={<Avatar variant="square" className="avatar_tag"><img src={filterIcon} className="filter_icon" /></Avatar>}>Filters</Button>
                </Box>
                <Popover
                  open={Boolean(this.state.anchorEl)}
                  anchorEl={this.state.anchorEl}
                  onClose={this.closeFilterHandler}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  PaperProps={{
                    style: {
                      width: 300,
                      position: 'sticky',
                      ...(this.state.showDealHistoryDetailForm && {padding: '17px 30px 0px 24px'})
                    }
                  }}
                >
                  <Box display='flex' flexDirection='column' mb={this.state.showDealHistoryDetailForm ? '17px' : undefined} >

                    {
                      this.state.showDealHistoryDetailForm ?
                        isArrayEmpty(this.dealHistoryFilterOptions) ? <p>No Filter Option Available</p> : this.dealHistoryFilterOptions.map((option, index) => (
                          <MenuItem
                            key={`dealHistoryFilterOption${index}`}
                            value={option}
                            onClick={() => this.handleDealHistoryFilterClick(option)}
                            selected={this.state.selectedDealHistoryFilters.includes(option)}
                            className="filterListItem"
                          >
                            {option}
                          </MenuItem>
                        )) : isArrayEmpty(this.state.dealDashboardFilterOptions) ?
                          <p>No Filter Option Available</p> :
                          this.makeDealsFilterDataOptions()
                    }

                  </Box>

                </Popover></React.Fragment> : !this.state.showDealHistoryDetailForm && <>
                  <Box flexGrow={1} style={{ display: "flex", justifyContent: "flex-end" }} >
                    <Button data-testid="arranger-filter-button" onClick={this.openFilterHandler} variant="contained" className="filter_button" endIcon={<Avatar variant="square" className="avatar_tag"><img src={filterIcon} className="filter_icon" /></Avatar>}>Filter to select debt seeker</Button>
                  </Box>
                  <Popover
                    open={Boolean(this.state.anchorEl)}
                    anchorEl={this.state.anchorEl}
                    onClose={this.closeFilterHandler}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    transformOrigin={{
                      vertical: 'top',
                      horizontal: 'left',
                    }}
                    PaperProps={{
                      style: {
                        width: 300,
                        padding: '17px 30px 0px 24px'
                      }
                    }}
                  >
                    <Box display='flex' flexDirection='column' mb='17px' >

                      {
                        isArrayEmpty(this.state.seekerList) ?
                          <p>No Data Available</p> :
                          this.state.seekerList.map((provider: string, index: number) => (
                            <MenuItem onClick={() => {this.handleSeekerNameFilterChange(provider); this.closeFilterHandler()}}
                              selected={this.state.selectedSeekerFilter.includes(provider)}
                              className="filterListItem"
                              key={`providerName${provider}`} value={provider}>{provider}</MenuItem>
                          ))
                      }

                    </Box>

                  </Popover></>}
              <Button onClick={this.handleAddNewDealClick} data-testid="add-deal-button" variant="contained" className="add_new_deal_button" endIcon={<AddBoxIcon className="add_icon"
              />}>List/Add new deal</Button>

            </Box>
          </Container>
          {this.state.isLoading ? 
               <Box style={{display: "flex", flexDirection:"row",alignItems:"center",justifyContent:"center"}}> <p>Loading...</p> </Box>: 
            <Box className="on_going_deal_button">
            {
              !this.state.showDealHistoryDetailForm && this.state.onGoingDealActiveButton ?
                <Container>
                  {
                    this.state.dealsList && this.state.dealsList.length > 0 &&
                    <Slider {...settings}>
                      {this.state.dealsList.map((deal, i) =>{
                        const arranger=deal?.is_arranger_present
                        
                        return(
                       

                          <div className="cardWrapperDealDashboard" key={i} style={{ padding: "30px" }}>
                            <Link to={`/SeekerArrangerDealDetails/${deal?.id}`} className="textDecoration">
                              <div style={{ display: "flex" }}>
                                <div style={{ height: '170px' }}>
                                  <img
                                    src={deal.upload_logo_image?.url || ''}
                                    style={{ borderRadius: "10px" }}
                                    alt="discovery"
                                    width="100"
                                    height="100"
                                  />
                                  <span className="rating_chip"> <StarIcon style={{ fontSize: '1rem' }} /> <p className="rating_label" style={{ marginLeft: '3px' }}>{showTextWithValidation(deal.proposed_instruments?.rating_details || 'Unrated')}</p> </span>
                                  <span className="rating_chip"> <p className="rating_label">{showTextWithValidation(deal?.proposed_instruments?.sector)}.</p> </span>
  
                                </div>
                                <div
                                  style={{
                                    margin: "0 14px 0 24px",
                                    height: "194", width: '94%',
                                  }}
                                >
                                  <p className="company__name" style={{marginTop:'0px'}}>{showTextWithValidation(deal?.debt_seeker_company_name)}</p>
                                  <div className="approveCardDescriptionWrapper">
                                    <div className="approve_card">
                                      <Typography className="company__description">
                                        {showTextWithValidation(deal?.company_background)}
                                      </Typography>
  
                                    </div>
                                  </div>
                                </div>
                              </div>
  
                              <div style={{ margin: '0 24 0 0' }} className="normal_font cardpadding">
                                <Grid container spacing={2} style={{ marginTop: "30px" }}>
                                  <Grid item  xs={4}>
                                    <p>Instrument</p>
                                  </Grid>
                                  <Grid item  xs={8}>
                                    <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.instrument)}</p>
                                  </Grid>
  
                                  <Grid item  xs={6}>
                                    <p>Total Amount (INR Cr)</p>
                                  </Grid>
                                  <Grid item  xs={6}>
                                    <p className="BlueText right_text_aligh"> {showTextWithValidation(deal.proposed_instruments?.total_deal_size) !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.total_deal_size)} Cr` : "-"} </p>
                                  </Grid>
  
                                  <Grid item  xs={6}>
                                    <p>Minimum Investment (INR Cr)</p>
                                  </Grid>
                                  <Grid item  xs={6}>
                                    <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr") !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr")}` : "-"}</p>
                                  </Grid>
  
                                  <Grid item  xs={6}>
                                    <p>Expected Returns (Gross)</p>
                                  </Grid>
                                  <Grid item  xs={6}>
                                    <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.expected_returns_gross)} </p>
                                  </Grid>
  
                                  <Grid item  xs={6}>
                                    <p>Maturity Date</p>
                                  </Grid>
                                  <Grid item  xs={6}>
                                    <p className="BlueText right_text_aligh">{deal.proposed_instruments?.repayment_maturity_date ? moment(deal.proposed_instruments?.repayment_maturity_date).format('MMMM DD, YYYY') : "-"}</p>
                                  </Grid>
  
                                  <Grid item  xs={6}>
                                    <p>Repayment Frequency</p>
                                  </Grid>
                                  <Grid item  xs={6}>
                                    <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.repayment_frequency)}</p>
                                  </Grid>
                                </Grid>
                              </div>
                            </Link>
                            <Box 
                            style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent:'space-between', margin: "16px 0px", marginTop: "30px", }} 
                            sx={{fontSize:'14px'}}  >
                              <Button
                                data-testid="take-action-button"
                                onClick={() => this.takeAction(deal.id)}
                                
                                style={{ textTransform: 'none', fontSize: '18px', color: '#001d56', fontFamily: 'Roboto', fontWeight: 400, letterSpacing: '-0.45px'  }} 
                                endIcon={<img src={image_take} width="95%" height="95%" className="icon" />} >{deal.is_view_details ? "View Details" : "Take action"}</Button>
                                
                                <Grid item sm={7} md={8} lg={8} style={{display:'flex', alignItems:'center',justifyContent:'space-around'}}>
                                  <Box className="ongoingDealsChat" style={arranger?{borderRadius:'40px'}:{background: '#CCC',borderRadius:'40px',cursor:'default'}} data-test-id='ongoingChat'
                                  onClick={()=>{if(arranger){this.openChatBox(deal.groupchat_dialogid_beforeeoi,deal.id,deal.project_name)}else{return false}}}>Chat</Box>
                                  <Box data-testid="visit-deal-history-button" onClick={() => this.setDealHistoryPopUp(deal.id)}className="ongoingDealsChat" style={{borderRadius:'40px'}}>Deal History</Box>
                                  </Grid>
                           
                            </Box>
  
                            <div style={{marginRight:'15px'}} className="BlueText">
                                                          <p style={{fontSize:"18px",fontWeight:700,marginBottom:'0px'}}>Investment Done:</p>
                                                          <div className="filter_wrapper">
                                                              <p>{deal?.progress_bar?.deal_investment_done}/{deal?.progress_bar?.total_deal_size}</p>
                                                              <p>{deal?.progress_bar?.deal_investment_percentage}% Investment</p>
                                                          </div>
                                                          <div className="progressBar_outer">
                                                              <div className="progressBar_inner" style={{width:`${deal?.progress_bar?.deal_investment_percentage}%`}}></div>
                                                          </div>
                                                      </div>
                          </div>
                        )
                      } )}


                    </Slider>
                  }
                  {
                    this.state.dealsList && !this.state.isLoading && this.state.dealsList.length === 0 &&
                    <p>No Deals Available</p>
                  }
                </Container>
                :
                <>
                  {
                    !this.state.showDealHistoryDetailForm && <Container>
                      {
                        this.state.dealsList && this.state.dealsList.length > 0 &&
                        <Slider {...settings}>
                          {this.state.dealsList?.map((deal, i) => (

                            <div className="cardWrapperDealDashboard" key={i}>
                              <Link key={`transactionhistory${i}`} to={`/SeekerArrangerDealDetails/${deal?.id}`} className="textDecoration">
                                <div style={{ display: "flex" }}>
                                  <div style={{ height: '170px' }}>
                                    <img
                                      src={deal.upload_logo_image?.url || ''}
                                      style={{ borderRadius: "10px" }}
                                      alt="discovery"
                                      width="100"
                                      height="100"
                                    />
                                    <span className="rating_chip"> <StarIcon style={{ fontSize: '1rem' }} /> <p className="rating_label" style={{ marginLeft: '3px' }}>{showTextWithValidation(deal.proposed_instruments?.rating_details || '')}</p> </span>
                                    <span className="rating_chip"> <p className="rating_label">{showTextWithValidation(deal?.proposed_instruments?.sector)}</p> </span>

                                  </div>
                                  <div
                                    style={{
                                      margin: "0 14px 0 24px",
                                      height: "194", width: '94%'
                                    }}
                                  >
                                    <p className="deal_companyName">{showTextWithValidation(deal?.debt_seeker_company_name)}</p>
                                    <div className="approveCardDescriptionWrapper">
                                      <div className="approve_card">
                                        {showTextWithValidation(deal?.company_background)}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div style={{ margin: '0 24 0 0' }} className="normal_font">
                                  <div className="deal_discovery_body">
                                    <p>Instrument</p><p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.instrument)}</p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Total Amount (INR Cr)</p><p className="BlueText"> {showTextWithValidation(deal.proposed_instruments?.total_deal_size) !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.total_deal_size)} Cr` : "-"} </p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Minimum Investment (INR Cr)</p><p className="BlueText">Rs. {showTextWithValidation(deal.proposed_instruments?.minimum_investment)+" Cr"}</p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Expected Returns (Gross)</p><p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.expected_returns_gross)}</p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Maturity Date</p><p className="BlueText">{moment(deal.proposed_instruments?.repayment_maturity_date || '').format('MMMM DD, YYYY')}</p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Repayment Frequency</p><p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.repayment_frequency)}</p>
                                  </div>
                                  <div className="deal_discovery_body">
                                    <p>Status</p><p className="BlueText">{showTextWithValidation(deal.deal_status)}</p>
                                  </div>
                                </div>
                              </Link>
                              <Box className={`${deal.is_view_details ? "dealsDashboardButtonsParent" : "dealsDashboardButtonsParentForOnlyDealHistory"}`}>
                                {deal.is_view_details && <Button
                                  onClick={() => this.takeAction(deal.id, true)}
                                  style={{ textTransform: 'none', fontSize: '18px', color: '#001d56', fontFamily: 'Roboto', fontWeight: 400, letterSpacing: '-0.45px' }}
                                  endIcon={<img src={image_take} width="95%" height="95%" className="icon" />}>View Details</Button>}
                                <Button onClick={() => this.setDealHistoryPopUp(deal.id, true)} className="deal_history_button" >Deal History</Button>
                              </Box>
                            </div>
                          ))}
                        </Slider>
                      }
                      {
                        this.state.dealsList && !this.state.isLoading && this.state.dealsList.length === 0 &&
                        <p>No Deals Available</p>
                      }
                    </Container>
                  }
                </>
            }
            {this.state.showDealHistoryDetailForm &&
              <Container>
                <ArrowBackIosIcon className="back-icon" onClick={() => {
                  this.setState({
                    showDealHistoryDetailForm: false
                  })
                }} />
                <Box className="cardWrapper_transaction_history history_cards" style={{ backgroundColor: 'rgb(246,248,247)', height: '100%' }}>
                  <Grid container spacing={4} style={{ padding: "20px" }} className="history_container">
                    {isArrayEmpty(this.state.dealHistoryData) ? <p>No History Available</p> : this.state.dealHistoryData && <React.Fragment>
                      <Grid style={{width:'15%'}}>
                        <Typography className="deal_history_detail_typography">Date</Typography>
                      </Grid>
                      <Grid style={{width:'30%',marginLeft:'25px'}}>
                        <Typography className="deal_history_detail_typography">Event Type</Typography>
                      </Grid>
                      <Grid style={{width:'48%',marginLeft:'25px'}}>
                        <Typography className="deal_history_detail_typography">Remark</Typography>
                      </Grid>
                      {this.state.dealHistoryData.data.map((item, index: number) => (
                        <React.Fragment key={`deal_history_details_${index}`}>
                        <Grid  style={{background:"#fff",width:'15%'}}>
                          <Typography key={`dateid${index}`} className="deal_history_detail_typography_data">{item.attributes.date}</Typography>
                        </Grid>
                         <Grid  style={{background:"#fff",width:'30%',marginLeft:'25px'}}>
                          <Typography key={`dealhistoryeventtype${index}`} className="deal_history_detail_typography_data">{item.attributes.event_type_with_name}</Typography>
                         </Grid>
                         <Grid  style={{background:"#fff",width:'48%',marginLeft:'25px'}}>
                          <Typography key={`dealhistoryremark${index}`} className="deal_history_detail_typography_data deal_history_remark" style={{ height: "34px" }}>{item.attributes.remark}</Typography>
                         </Grid>
                         </React.Fragment>
                      ))
                      }
                      
                    </React.Fragment>}
                  </Grid>
                </Box>
              </Container>
            }
          </Box>}
        </Box>
        {this.state.dealHostoryPopUp && this.state.dealHistoryData && <DealHistoryModal dealsHistoty={this.state.dealHistoryData} closeDrawer={this.closeDealHistoryPopup} />}
         {this.state.open?<Chat9 dialogId={this.state.groupchatDialogId} navigation={''} id={''}  handleDialog = {this.handleChatBox} dealName={this.state.dealName}></Chat9>:null}
      </Grid>
    );
  }
}
// Customizable Area End
