// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { number } from "yup";
import { checkForReadMode } from "../../../components/src/common";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  location: any;
  match: any;
}

export interface IManageMentForm {
  name?: string;
  designation?: string;
  remark?: string;
  education?: string;
  file?: any;
  uploaded_file?: string;
  abbrebiation?: string;
  showNameError?: boolean;
  showDesignationError?: boolean;
  showEducationError?: boolean;
  showRemarkError?: boolean;
  fileUploadingError?: boolean;
  management_profileId: any;
  managementUploadFileName: any;
  deleted_image?:boolean;
  _destroy?: boolean;
  [key: string]: any;
}

export interface IDirectorData {
  dir_name?: string;
  dir_designation?: string;
  dir_remark?: string;
  dir_education?: string;
  fileDirector?: any;
  dir_uploaded_file?: string;
  dir_din?: string;
  dateOfAppointment?: any;
  dir_abbrebiation?: string;
  dir_showNameError?: boolean;
  dir_showDesignationError?: boolean;
  dir_showEducationError?: boolean;
  dir_showRemarkError?: boolean;
  dir_fileUploadingError?: boolean;
  dir_showDateAppointmentError?: boolean;
  director_profileId: any;
  directorUploadFileName: any;
  dir_showDinError: boolean;
  deleted_image?:boolean;
  _destroy?: boolean;
  [key: string]: any;
}

interface S {
  managerData: IManageMentForm[];
  directorData: IDirectorData[];
  showAllFieldError: boolean;
  managementProfileId: any[];
  deal_id: any;
  directorProfileId: any[];
  isReadMode: boolean;
  isNext: boolean;
  isRedirect :boolean;
  managerDeleteId: IManageMentForm[];
  directorDeletedId: IDirectorData[];
}

interface SS {
  id: any;
}

export const INITIAL_MANAGEMENTFORM_DETAIL: IManageMentForm = {
  name: '',
  designation: '',
  remark: '',
  education: '',
  file: '',
  abbrebiation: "Ms",
  showNameError: false,
  showDesignationError: false,
  showEducationError: false,
  showRemarkError: false,
  fileUploadingError: false,
  deleted_image: false,
  _destroy: false,
  management_profileId: '',
  managementUploadFileName: ''
}
export const INITIAL_DIRECTOR_DETAIL: IDirectorData = {
  dir_name: "",
  dir_designation: "",
  dir_remark: "",
  dir_education: "",
  fileDirector: "",
  dir_din: "",
  dateOfAppointment: "",
  dir_abbrebiation: "Ms",
  dir_showNameError: false,
  dir_showDesignationError: false,
  dir_showEducationError: false,
  dir_showRemarkError: false,
  dir_fileUploadingError: false,
  dir_showDateAppointmentError: false,
  dir_showDinError: false,
  deleted_image: false,
  _destroy: false,
  director_profileId: '',
  directorUploadFileName: ''
}

export default class ManagementProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  addManagementProfileApiCallId = "";
  updateManagementProfileApiCallId = "";
  getManagementProfileApiCallId = "";
  deleteManagementProfileApiCallId = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.redirectMessage),
    ];

    this.state = {
      managerData: [{ ...INITIAL_MANAGEMENTFORM_DETAIL }],
      directorData: [{ ...INITIAL_DIRECTOR_DETAIL }],
      showAllFieldError: true,
      managementProfileId: [],
      directorProfileId: [],
      deal_id: null,
      isReadMode: false,
      isNext: false,
      isRedirect :false,
      managerDeleteId: [],
      directorDeletedId: [],
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getData();
    this.getToken();
    const isReadMode = checkForReadMode(this.props.location);
    this.setState({
      isReadMode: isReadMode
    })
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }
  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };
  /* istanbul ignore next */
  getResponseData = (responseJson:any , deal_id:any) =>{
    const createdProfiles: any[] = responseJson?.management_profiles?.data.sort((a: any, b: any) => {
      return (a.id - b.id);
    });
    const tempManagerDataList: IManageMentForm[] = [];
    const tempDirectorDataList: IDirectorData[] = [];
    const idList: string[] = [];
    createdProfiles?.map((data: any, index: number) => {
      idList.push(data?.id);
      const tempManagerData: IManageMentForm = {
        name: data?.attributes?.name,
        designation: data?.attributes?.designation,
        remark: data?.attributes?.remarks,
        education: data?.attributes?.education,
        file: data?.attributes?.upload_profile_picture,
        abbrebiation: data?.attributes?.title_name,
        showNameError: false,
        showDesignationError: false,
        showEducationError: false,
        showRemarkError: false,
        fileUploadingError: false,
        management_profileId: data?.id,
        managementUploadFileName: data?.attributes?.upload_profile_name
      }
      let d1 = new Date(`${responseJson?.data?.attributes?.date_of_appointment}`).getDate();
      let m1 = new Date(`${responseJson?.data?.attributes?.date_of_appointment}`).getMonth();
      let y1 = new Date(`${responseJson?.data?.attributes?.date_of_appointment}`).getFullYear();
      let d2 = d1 < 10 ? `0${d1}` : `${d1}`
      let m2 = m1 + 1 < 10 ? `0${m1 + 1}` : `${m1}`;
      const tempDirectorData: IDirectorData = {
        dir_name: data?.attributes?.dir_name,
        dir_designation: data?.attributes?.dir_designation,
        dir_remark: data?.attributes?.dir_remark,
        dir_education: data?.attributes?.dir_education,
        fileDirector: data?.attributes?.dir_profile_picture,
        dir_din: data?.attributes?.din,
        dateOfAppointment: `${d2}/${m2}/${y1}`,
        dir_abbrebiation: data?.attributes?.dir_title_name,
        dir_showNameError: false,
        dir_showDesignationError: false,
        dir_showEducationError: false,
        dir_showRemarkError: false,
        dir_fileUploadingError: false,
        dir_showDateAppointmentError: false,
        dir_showDinError: false,
        director_profileId: data?.id,
        directorUploadFileName: data?.attributes?.dir_profile_name
      }
      tempManagerDataList.push(tempManagerData);
      tempDirectorDataList.push(tempDirectorData);
    })
    this.setState({
      managerData: tempManagerDataList,
      directorData: tempDirectorDataList,
      managementProfileId: idList,
      deal_id: deal_id
    })
    const msg48: Message = new Message(getName(MessageEnum.CustomAlertMessage));
    msg48.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.message);
    this.send(msg48);
  };

  async receive(from: string, message: Message) {
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      if (this.state.isRedirect && customRedirect) {
        this.props.history.push("/Catalogue/DebtProfile");
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    var errorReponse = message.getData(
      getName(MessageEnum.RestAPIResponceErrorMessage)
    );
    if (responseJson) {
      if (this.getManagementProfileApiCallId == apiRequestCallId) {
        const deal_id: number = responseJson?.meta?.deal_id;
        const createdProfiles: any[] = responseJson?.management_profiles?.data.sort((a: any, b: any) => {
          return (a.id - b.id);
        });
        const tempManagerDataList: IManageMentForm[] = [];
        const tempDirectorDataList: IDirectorData[] = [];
        const idList: string[] = [];
        const directorIdList: string[] = [];
        createdProfiles?.map((data: any, index: number) => {
          idList.push(data?.id);
          const tempManagerData: IManageMentForm = {
            name: data?.attributes?.name,
            designation: data?.attributes?.designation,
            remark: data?.attributes?.remarks,
            education: data?.attributes?.education,
            uploaded_file: data?.attributes?.upload_profile_picture,
            abbrebiation: data?.attributes?.title_name,
            showNameError: false,
            showDesignationError: false,
            showEducationError: false,
            showRemarkError: false,
            fileUploadingError: false,
            management_profileId: data?.id,
            managementUploadFileName: data?.attributes?.upload_profile_name
          }
          tempManagerDataList.push(tempManagerData);
        })
        responseJson?.dir_profiles?.data?.map((data: any, index: number) => {
          directorIdList.push(data?.id);
          let d1 = new Date(`${data?.attributes?.date_of_appointment}`).getDate();
          let m1 = new Date(`${data?.attributes?.date_of_appointment}`).getMonth();
          let y1 = new Date(`${data?.attributes?.date_of_appointment}`).getFullYear();
          let d2 = d1 < 10 ? `0${d1}` : `${d1}`
          let m2 = m1 + 1 < 10 ? `0${m1 + 1}` : `${m1}`;
          const tempDirectorData: IDirectorData = {
            dir_name: data?.attributes?.dir_name,
            dir_designation: data?.attributes?.dir_designation,
            dir_remark: data?.attributes?.remarks,
            dir_education: data?.attributes?.education,
            dir_uploaded_file: data?.attributes?.dir_profile_picture,
            dir_din: data?.attributes?.din,
            dateOfAppointment: data?.attributes?.date_of_appointment,
            dir_abbrebiation: data?.attributes?.dir_title_name,
            dir_showNameError: false,
            dir_showDesignationError: false,
            dir_showEducationError: false,
            dir_showRemarkError: false,
            dir_fileUploadingError: false,
            dir_showDateAppointmentError: false,
            dir_showDinError: false,
            director_profileId: data?.id,
            directorUploadFileName: data?.attributes?.dir_profile_name
          }
          tempDirectorDataList.push(tempDirectorData);
        })
        this.setState({
          managerData: tempManagerDataList.length > 0 ? tempManagerDataList : [{ ...INITIAL_MANAGEMENTFORM_DETAIL }],
          directorData: tempDirectorDataList.length > 0 ? tempDirectorDataList : [{ ...INITIAL_DIRECTOR_DETAIL }],
          managementProfileId: idList,
          directorProfileId: directorIdList,
          deal_id: deal_id
        })
      }
      /* istanbul ignore next */
      if (this.addManagementProfileApiCallId == apiRequestCallId) {
        const message: string = responseJson?.message;
        const messageType: string = responseJson?.messageType;
        const deal_id: number = responseJson?.meta?.deal_id;
        if (responseJson?.success) {
          this.getData();
          this.getResponseData(responseJson,deal_id);
        }else{
          let res = responseJson?.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg49: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg49.addData(getName(MessageEnum.CustomAlertBodyData), res);
          this.send(msg49);
          this.setState({ isRedirect: false });
        }
      }
      /* istanbul ignore next */
      if (apiRequestCallId === this.updateManagementProfileApiCallId) {
        this.getData();
        if (responseJson?.errors) {
          let res = responseJson?.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg50: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg50.addData(getName(MessageEnum.CustomAlertBodyData), res);
          this.send(msg50);
          this.setState({ isRedirect: false });
          return;
        }
        if (responseJson) {
          const message: string = responseJson?.message;
          const messageType: string = responseJson?.messageType;
          const deal_id: number = responseJson?.meta?.deal_id;
          if (responseJson?.success) {
            this.getResponseData(responseJson,deal_id)
          } 
        } 
      }
      if (apiRequestCallId === this.deleteManagementProfileApiCallId) {
        /* istanbul ignore next */
        if (responseJson) {
          if (responseJson?.success) {
            this.getData();
            const msg51: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg51.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.success);
            this.send(msg51);
          }
          if (responseJson?.errors) {
            const msg52: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg52.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors);
            this.send(msg52);
          }
        }
      }
    }
  }

  saveAsDraft = (token: any, is_draft: boolean = false) => {
    var myHeaders = new Headers();
    myHeaders.append("token", token);
    var formdata = new FormData();
    this.state.managerData?.map((data: IManageMentForm, index: number) => {
      formdata.append(`data[management_profile][][title_name]`, data.abbrebiation || '');
      formdata.append(`data[management_profile][][name]`, data.name || '');
      formdata.append(`data[management_profile][][designation]`, data.designation || '');
      formdata.append(`data[management_profile][][education]`, data.education || '');
      formdata.append(`data[management_profile][][remarks]`, data.remark || '');
      data?.file && formdata.append(`data[management_profile][][upload_profile_picture]`, data.file);
    });
    this.state.directorData?.map((data: IDirectorData, index: number) => {
      formdata.append(`data[dir_profile][][dir_title_name]`, data.dir_abbrebiation || '');
      formdata.append(`data[dir_profile][][dir_name]`, data.dir_name || '');
      formdata.append(`data[dir_profile][][dir_designation]`, data.dir_designation || '');
      formdata.append(`data[dir_profile][][din]`, data.dir_din || '');
      formdata.append(`data[dir_profile][][education]`, data.dir_education || '');
      formdata.append(`data[dir_profile][][date_of_appointment]`, data.dateOfAppointment || '');
      formdata.append(`data[dir_profile][][remarks]`, data.dir_remark || '');
      data?.fileDirector && formdata.append(`data[dir_profile][][dir_profile_picture]`, data.fileDirector);
    });
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addManagementProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.managementProfileApiEndPoint + `?deal_id=${localStorage.getItem("deal_id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateManagementProfile = (draft:boolean) => {
    var myHeaders = { token: localStorage.getItem("token")};
    
    var formdata = new FormData();
    this.state.managerData?.map((data: IManageMentForm, index: number) => {
       formdata.append(`data[management_profile][][id]`, data?.management_profileId || '');
       formdata.append(`data[management_profile][][title_name]`, data.abbrebiation || '');
       formdata.append(`data[management_profile][][name]`, data.name || '');
       formdata.append(`data[management_profile][][designation]`, data.designation || '');
       formdata.append(`data[management_profile][][education]`, data.education || '');
       formdata.append(`data[management_profile][][remarks]`, data.remark || '');
      data.file && formdata.append(`data[management_profile][][upload_profile_picture]`, data.file||'');
      data.deleted_image && formdata.append(`data[management_profile][][deleted_image]`, data.deleted_image.toString());
    });
    this.state.directorData?.map((data: IDirectorData, index: number) => {
       formdata.append(`data[dir_profile][][id]`, data.director_profileId || '');
       formdata.append(`data[dir_profile][][dir_title_name]`, data.dir_abbrebiation || '');
       formdata.append(`data[dir_profile][][dir_name]`, data.dir_name || '');
       formdata.append(`data[dir_profile][][dir_designation]`, data.dir_designation || '');
       formdata.append(`data[dir_profile][][din]`, data.dir_din || '');
       formdata.append(`data[dir_profile][][education]`, data.dir_education || '');
       formdata.append(`data[dir_profile][][date_of_appointment]`, data.dateOfAppointment || '');
       formdata.append(`data[dir_profile][][remarks]`, data.dir_remark || '');
      data.fileDirector && formdata.append(`data[dir_profile][][dir_profile_picture]`, data.fileDirector||'');
      data?.deleted_image && formdata.append(`data[dir_profile][][deleted_image]`, data.deleted_image.toString());
    });
    formdata.append(`is_draft`, draft.toString());
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateManagementProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.managementProfileUpdateApiEndPoint + `?deal_id=${localStorage.getItem("deal_id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypePut
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getData = () => {
    var myHeaders = new Headers();
    var token: any = localStorage.getItem("token");
    myHeaders.append("token", token);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getManagementProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.managementProfileGetApiEndPoint + `?deal_id=${localStorage.getItem("deal_id")}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  deleteManagementData = (management_id: any, dir_id: any) => {
    var myHeaders = new Headers();
    var token: any = localStorage.getItem("token");
    myHeaders.append("token", token);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.deleteManagementProfileApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      management_id === null ? configJSON.managementProfileDeleteApiEndPoint + `?deal_id=${localStorage.getItem("deal_id")}` + `&dir_id=` + dir_id
        : configJSON.managementProfileDeleteApiEndPoint + `?deal_id=${localStorage.getItem("deal_id")}` + `&management_id=` + management_id
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeDelete
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  // Customizable Area End
}


