// Customizable Area Start
import React from "react";

import {
    Container,
    Box,
    Button,
    Typography,
    Grid,
    TextField,
    Dialog,
    DialogTitle,
    DialogContent,
} from "@material-ui/core";
import './dealDashboard.css';
import { Link, withRouter } from "react-router-dom";
import AddBoxIcon from '@material-ui/icons/AddBox';
import TakeActionController, {
    Props,
} from "./TakeActionController.web";
import {CustomInputField} from '../../../components/./src/./components/./CustomInputField';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import AllocateDealPopUP from "./AllocateDeal.web"
import { showTextWithValidation } from "../../../components/src/common";
class TakeAction extends TakeActionController {
    constructor(props: Props) {
        super(props);
    }
    
    
    render() {

        return (
            <Grid className="ongoing_deals_main_container">
                <Container>
                    <Box>
                        {!this.state.isKycDone &&
                            <Box onClick={this.handlePendingKycClick} className="text" style={{ marginTop: '32px' }}>
                                <Typography variant="inherit" className="kyc_information_typography">Please complete the pending KYC</Typography>
                            </Box>}
                        <Box
                            className="button_outer_box"
                            style={{ marginBottom: '34px' }}
                        >
                            <Box className="button_parent_box">
                                <Button className={this.state.onGoingDealActiveButton ? "button_color_active" : "button_color"} onClick={this.handleOnGoing}>Ongoing Deal(s)</Button>
                                <Button className={this.state.transactionHistoryActiveButton ? "button_color_active" : "button_color"}
                                    onClick={this.handleTransactionHistory}
                                >Transaction History</Button>
                            </Box>
                        </Box>
                        <Box
                            className="filter_parent_box"
                            style={{ margin: '0px 9px' }}
                        >
                            <Link data-testid="add-deal-button" onClick={this.handleAddClick} to="/Catalogue/CompanyOverview" style={{ textDecoration: 'none' }}>
                                <Button variant="contained" className="add_new_deal_button" endIcon={<AddBoxIcon className="add_icon"
                                />}>List/Add new deal</Button>
                            </Link>
                        </Box>

                        <Grid className="onGoing_deals_card">
                            <Grid container>
                                <ArrowBackIosIcon data-testid="back-button" onClick={this.handleBackClick} className="back-icon" />
                                <Typography className="ongoin-deal-card-heading">
                                    Debt {localStorage.getItem('role_id') == "1" ? 'Seeker' : 'Arranger'} Dashboard- {this.isTransaction ? "Transaction History" : "Ongoing Deals"}
                                </Typography>
                            </Grid>
                            <Grid className="deals_list_box">
                                <Grid
                                    container
                                    className="deals_list_header"
                                    spacing={2}
                                    style={{ textAlign: "center" }}
                                >
                                    <Grid item lg={1}>
                                        <Typography
                                            className="heading-text"
                                            align="center"
                                        >
                                            Deals
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography
                                            className="heading-text"
                                            align="center"
                                        >
                                            Listed  Date
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography
                                            className="heading-text"
                                            align="center"
                                        >
                                            EOI Received
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography
                                            align="center"
                                            className="heading-text"
                                        >
                                            Offers Received
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <Typography
                                            align="center"
                                            className="heading-text"
                                        >
                                            Offers  Accepted
                                        </Typography>
                                    </Grid>
                                    <Grid item lg={3}>
                                        <Typography
                                            className="heading-text"
                                            align="center"
                                        >
                                            Action
                                        </Typography>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={2} className="deals_values_box" style={{ textAlign: "center" }}>
                                    <Grid item lg={1}>
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            className="onging_deal_input_takeaction"
                                            variant="outlined"
                                            disabled
                                            value={showTextWithValidation("Deal 1")}
                                            type="text"
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            className="onging_deal_input_takeaction"
                                            variant="outlined"
                                            disabled
                                            value={showTextWithValidation(this.state.takeActionResponse?.data.attributes.listed_date)}
                                            type="text"
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            className="onging_deal_input_takeaction"
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            value={this.state.takeActionResponse?.interest_received || 0}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <TextField
                                            id="outlined-basic"
                                            className="onging_deal_input_takeaction"
                                            variant="outlined"
                                            type="text"
                                            disabled
                                            value={this.state.takeActionResponse?.offer_received || 0}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={2}>
                                        <TextField
                                            id="outlined-basic"
                                            label=""
                                            className="onging_deal_input_takeaction"
                                            variant="outlined"
                                            disabled
                                            type="text"
                                            value={this.state.takeActionResponse?.offer_accepted || 0}
                                        ></TextField>
                                    </Grid>
                                    <Grid item lg={3} style={{ display: 'flex', flexWrap: 'nowrap', justifyContent: "center" }}>
                                        {this.state.takeActionResponse?.is_offer_accepted || this.state.takeActionResponse?.is_view_details ? <Button
                                            variant="outlined"
                                            className="action-button"
                                            onClick={this.handleViewAllocationClick}
                                        >
                                            View Allocations
                                        </Button> :
 
                                            <Button
                                                data-testid="allocate-deal-popup-button"
                                                variant="outlined"
                                                className="action-button"
                                                onClick={this.allocateDealPopUP}
                                            >
                                                Allocate
                                            </Button>}
                                        {!this.state.takeActionResponse?.is_view_details && <>
                                            {!this.state.isArranger && <Button
                                                variant="outlined"
                                                className="action-button"
                                                onClick={this.showWithdrawPopup}
                                            >
                                                Withdraw
                                            </Button>}
                                            {(this.state.takeActionResponse?.offer_received === 0 && !this.state.takeActionResponse?.is_view_details )&& <Button
                                                variant="outlined"
                                                className="action-button"
                                                data-testid="edit-button"
                                                onClick={this.handleEditClick}
                                            >
                                                Edit
                                            </Button>}
                                        </>}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Box>
                    {
                        this.state.allocateDealPopUp && this.state.allocateData && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <AllocateDealPopUP
                                    closeDrawer={this.closeAllocatePopup}
                                    data={this.state.allocateData}
                                    handleAccept={this.setOtpPopUp}
                                    handleReject={this.handleRejectClick}
                                    handleFileUpload={this.handleAcceptedFileUpload}
                                    handleFileRemove={this.handleAcceptedFileRemove}
                                    handleOpenChatBox = {this.handleOpenChatBox}
                                    handleDialog = {this.handleDialog}
                                    handleOpenDialogBox = {this.handleOpenDialogBox}
                                    dialogId = {this.state.groupchatDialogId}
                                    open = {this.state.open}
                                    projectName={this.state.allocateData.project_name}
                                />
                            </Grid>
                        )
                    }
                    {
                        this.state.otpPopUp && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Dialog
                                    open={true}
                                    className="otp_popup"
                                    style={{ zIndex: 10 }}
                                >
                                    <DialogTitle className='ongoing_deal_popup_title'>
                                        <ArrowBackIosIcon className="back-icon" onClick={this.handleOtpBack} />
                                        <Typography className="ongoin-deal-card-heading">
                                            Enter OTP
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent style={{ padding: '0px 30px 30px 30px' }}>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Enter OTP"
                                            variant="outlined"
                                            type="number"
                                            name="company_name"
                                            onChange={this.handleOtpChange}
                                            value={this.state.otp}
                                        />
                                        <Grid container justifyContent="center">
                                            <Button
                                                variant="contained"
                                                className="otp_submit_button"
                                                onClick={this.callVerifyOtpForAccept}
                                                disabled={this.state.isLoading}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        )
                    }
                    {
                        this.state.isRejectPopup && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Dialog
                                    open={true}
                                    className="otp_popup"
                                >
                                    <DialogTitle className='ongoing_deal_popup_title'>
                                        <ArrowBackIosIcon className="back-icon" onClick={this.handleRemarkBack} />
                                        <Typography className="ongoin-deal-card-heading">
                                            Enter Remark
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent style={{ padding: '0px 30px 30px 30px' }}>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Enter Remark"
                                            variant="outlined"
                                            type="text"
                                            name="company_name"
                                            onChange={this.handleRemarkChange}
                                            value={this.state.rejectRemark}
                                        />
                                        <Grid container justifyContent="center">
                                            <Button
                                                variant="contained"
                                                className="otp_submit_button"
                                                onClick={this.rejectApiOffer}
                                                disabled={this.state.isLoading}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        )
                    }
                    {
                        this.state.isWithdrawPopup && (
                            <Grid item lg={12} md={12} sm={12} xs={12}>
                                <Dialog
                                    open={true}
                                    className="otp_popup"
                                >
                                    <DialogTitle className='ongoing_deal_popup_title'>
                                        <ArrowBackIosIcon onClick={this.closeWithdrawPopup} className="back-icon" />
                                        <Typography className="ongoin-deal-card-heading">
                                            Are you sure you want to withdraw?
                                        </Typography>
                                    </DialogTitle>
                                    <DialogContent style={{ padding: '0px 30px 50px 30px' }}>
                                        <Grid container justifyContent="center" spacing={3}>
                                            <Button
                                                variant="contained"
                                                className="otp_submit_button"
                                                onClick={this.withdrawDeal}
                                            >
                                                Confirm
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                className="confirm_cancle_button"
                                                onClick={this.closeWithdrawPopup}
                                            >
                                                Cancel
                                            </Button>
                                        </Grid>
                                    </DialogContent>
                                </Dialog>
                            </Grid>
                        )
                    }
                </Container>
            </Grid>
        );
    }
}
export default withRouter(TakeAction as any) as any;
// Customizable Area End
