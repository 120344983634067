// Customizable Area Start
import React from "react";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, Box, InputLabel, Select, FormControl, TextField, InputAdornment } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ClippedDrawer from "./ClippedDrawer.web";
import ManagementProfileFormController, { Props } from "./ManagementProfileFormController";
import { styled } from '@material-ui/core/styles';
import { IManageMentForm } from "./ManagementProfileController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const StyledTextField = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '25px',
        height: '51px'
    },
}));

export default class ManagementProfileForm extends ManagementProfileFormController {
    constructor(props: Props) {
        super(props);
    }
    getFile = (event: React.ChangeEvent<any>) => {
        const currentManagementData: IManageMentForm = this.props.manageData;
        if (event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png' || event.target.files[0].type == 'image/svg') {

            if (currentManagementData) {
                currentManagementData.file = event.target.files[0];
                currentManagementData.fileUploadingError = false;
            }
        }
        else {
            if (currentManagementData) {
                currentManagementData.fileUploadingError = true;
            }
        }
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    handleInputChange = (item: any) => {
        const currentManagementData: IManageMentForm = this.props.manageData;
        currentManagementData.abbrebiation = item;
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    getName = (e: React.ChangeEvent<any>) => {
        const regex = /^[A-Za-z ]+$/;
        const trimmedValue = e.target.value.trim();
        const currentManagementData: IManageMentForm = this.props.manageData;

        if ((regex.test(trimmedValue) || trimmedValue == '') && e.target.value.charAt(0)!==' ') {
            currentManagementData.name = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
            currentManagementData.showNameError = false;
        }
        else {
            currentManagementData.showNameError = true;
        }
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    getDesignation = (e: React.ChangeEvent<any>) => {
        const trimmedValue = e.target.value.trim();
        const currentManagementData: IManageMentForm = this.props.manageData;
        if(e.target.value.charAt(0)!==' '){
            currentManagementData.designation = e.target.value;
        }
        currentManagementData.showDesignationError = false;
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    getEducation = (e: React.ChangeEvent<any>) => {
        const trimmedValue = e.target.value.trim();
        const currentManagementData: IManageMentForm = this.props.manageData;
        if(e.target.value.charAt(0)!==' '){
            currentManagementData.education = e.target.value;
        }
        currentManagementData.showEducationError = false;
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    getRemark = (e: React.ChangeEvent<any>) => {
        const trimmedValue = e.target.value.trim();
        const currentManagementData: IManageMentForm = this.props.manageData;
        currentManagementData.remark = e.target.value;
        currentManagementData.showRemarkError = false;
        this.props.updateManageMentData(currentManagementData, this.props.index);
    };
    closeBttn = () => {
        this.props.deleteManageData(this.props.index)
    };
    removeUploadFile = (index: any) => {
        const currentManagerData: IManageMentForm = this.props.manageData;
        currentManagerData.uploaded_file = '';
        currentManagerData.managementUploadFileName='';
        currentManagerData.file = '';
        currentManagerData.fileUploadingError = false;
        currentManagerData.management_profileId ? currentManagerData.deleted_image = true :'';
        this.props.updateManageMentData(currentManagerData, index);
    };
    closeDropDown=()=>{
       if(this.state.showDropDown){
        this.setState({
            showDropDown:false
        });
       }
    };
    render() {
        var dropDownValue: any = [
            { abbrebiation: 'Mr' },
            { abbrebiation: 'Ms' },
            { abbrebiation: 'Mrs' }
        ];
        return (
            <Grid container spacing={3} className={this.props.index > 0 ? "management_profile_form_grid_parent_one" : "management_profile_form_grid_parent"} onClick={()=>{this.closeDropDown()}}>
                {
                    this.props.index > 0 ?
                        <Grid item md={12}>
                            <Box className="management_clost_button">
                                <Typography onClick={()=>{
                                    if(!this.props.isReadMode){
                                        this.closeBttn()
                                    }
                                    }}>X</Typography>
                            </Box>
                        </Grid>
                        :
                        null
                }
                <Grid item md={4}>
                    <Typography className="management_label_name">Name</Typography>
                </Grid>
                <Grid item md={8}>
                    <Box className="management_right_container">
                        <Box className="drop_down" id="drop_val" onClick={() => {
                            if(!this.props.isReadMode){
                                this.setState({
                                    showDropDown: !this.state.showDropDown
                                });
                            }
                        }}>
                            <Box className="drop_down_value">
                                <Box className="drop_down_icon">
                                    <Typography>{this.props?.manageData?.abbrebiation || ""}</Typography>
                                </Box>
                                <InputLabel htmlFor="drop_val">
                                    <KeyboardArrowDown className="drop_down_icon" />
                                </InputLabel>
                            </Box>
                            {
                                this.state.showDropDown ?
                                    <Paper className="drop">
                                        {
                                            dropDownValue.map((item: any, index: number) => {
                                                return (
                                                    <Box className="drop_val" onClick={() => this.handleInputChange(item.abbrebiation)}>
                                                        <Typography className="val">{item.abbrebiation}</Typography>
                                                        {index < dropDownValue.length - 1 && <Divider variant="fullWidth" style={{ marginTop: '10%' }} />}
                                                    </Box>
                                                );
                                            })
                                        }
                                    </Paper>
                                    :
                                    null
                            }
                        </Box>
                        <input disabled={this.props.isReadMode} className="kyc_test" value={this.props?.manageData?.name} type="text" style={{ marginLeft: '10px' }}
                            onChange={(e) => { this.getName(e) }}
                        />
                    </Box>
                    {this.props?.manageData?.showNameError ? <Typography className="management_error_label">Please Enter Valid Name</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Designation</Typography>
                </Grid>
                <Grid item md={8}>
                    <input disabled={this.props.isReadMode} className="kyc_test" value={this.props?.manageData?.designation}
                        onChange={(e) => { this.getDesignation(e) }}
                        type="text" />
                    {this.props?.manageData?.showDesignationError ? <Typography className="management_error_label">Please Enter Valid Designation</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Education</Typography>
                </Grid>
                <Grid item md={8}>
                    <input disabled={this.props.isReadMode} className="kyc_test" value={this.props?.manageData?.education} type="text"
                        onChange={(e) => this.getEducation(e)}
                    />
                    {this.props?.manageData?.showEducationError ? <Typography className="management_error_label">Please Enter Valid Education</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Remarks (Optional)</Typography>
                </Grid>
                <Grid item md={8}>
                    <textarea disabled={this.props.isReadMode} value={this.props?.manageData?.remark}
                        onChange={(e) => this.getRemark(e)}
                        rows={1}
                        cols={1}
                        className="management_profile_remarks"
                        />
                    {this.props?.manageData?.showRemarkError ? <Typography className="management_error_label">Please Enter Valid Remark</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Upload Picture (Optional)</Typography>
                </Grid>
                <Grid item md={8}>
                    {
                        this.props?.manageData?.uploaded_file ?
                            <Box className="management_text_input">
                                <Box className="uploadBoxLebel">
                                    <a href={this.props?.manageData?.uploaded_file} style={{ color: '#323232',textOverflow: "ellipsis",overflow:"hidden",whiteSpace : "nowrap"
                                }}>{this.props?.manageData?.managementUploadFileName}</a>
                                    <Typography onClick={() => { if(!this.props.isReadMode){this.removeUploadFile(this.props.index)} }} className="remove_upload_file">X</Typography>
                                </Box>
                            </Box>
                            :
                            <Box className="management_text_input">
                                <input type="file"
                                    id={"managementCustomInput" + this.props.index}
                                    style={{ display: "none", color: "black"}}
                                    accept="image/*"
                                    placeholder="logo name"
                                    name="logo"
                                    disabled={this.props.isReadMode}
                                    onChange={(e) => { this.getFile(e) }}
                                />
                                <Box className="uploadBoxLebel">
                                    {
                                        this.props?.manageData?.file?.name ?
                                            <Typography className="fileLabel" style={{ color: '#323232'}}>{this.props?.manageData?.file?.name}</Typography>
                                            : this.props?.manageData?.fileUploadingError ? <Typography className="management_error_label">Please Upload photo only</Typography> : <Typography className="fileLabel" style = {{color :'grey'}}>upload jpeg / png / tif etc.</Typography>

                                    }
                                    {
                                        this.props?.manageData?.file || this.props?.manageData?.fileUploadingError ?
                                            <Typography onClick={() => { if(!this.props.isReadMode){this.removeUploadFile(this.props.index)} }} className="remove_upload_file">X</Typography>
                                            :
                                            <InputLabel className="company_upload_lebel" htmlFor={"managementCustomInput" + this.props.index}>
                                                <AddCircleOutlineOutlinedIcon />
                                            </InputLabel>
                                    }
                                </Box>
                            </Box>
                    }
                    {/* {this.props?.manageData?.fileUploadingError ? <Typography className="management_error_label">Please Upload photo only</Typography> : ''} */}
                </Grid>
            </Grid>
        )
    }
}
// Customizable Area End
