import React from "react";
// Customizable Area Start
import {
    Box,
    CircularProgress,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    Select,
    TextareaAutosize,
    Typography,
    MenuItem,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import { verified_tick_image } from "./assets";
import CompanyOverViewController, { Props } from "./CompanyOverviewController";

import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { withRouter } from "react-router-dom";
import { handleReviewSubmitClick, Role_name } from "../../../components/src/common";

class CompanyOverView extends CompanyOverViewController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const {
            isErrorEmail,
            sentSuccessfully,
            logo,
            is_seeker_deal,
            inviteEmailError,
            video,
            openModal,
            inviteName,
            checkingPanLoading,
            presentation,
            isEmailsent,
            inviteEmail,
            isUserName,
            currentUserInfo,
            invitePhone,
            companyBackground,
            inviteIssuerLoading,
            debtArrangerCompanyName,
            inviteArrangerLoading,
            debtArrangerName,
            seekerInviteModal,
            currentUserRoleName,
            debtArrangerCompanyData,
            issuerEmailPresent,
            debtArrangerData,
            arrangerCompanyName,
            arrangerName,
            debtSeeker,
            spinner,
            isPan,
            imgError,
            videoError,
            PresentationError,
            isErrorCompanyName,
            imgUrl,
            videoUrl,
            presentationUrl,
        } = this.state;

        return (
            <>
                <Grid container xs={12} spacing={2}>
                    {(currentUserRoleName === Role_name.Arranger && is_seeker_deal === false) ||
                    (currentUserRoleName === Role_name.Admin && is_seeker_deal === false) ? (
                        <>
                            <Grid item xs={4} className="flex_center">
                                <InputLabel className="inputLabel">Issuer Company PAN</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    disabled={this.state.isReadMode}
                                    className="catalogue_textInput"
                                    type="text"
                                    name="isUserName"
                                    onChange={this.getPanValue}
                                    onBlur={isUserName?.length == 10 ? this.getDebtSeekerCompany : undefined}
                                    maxLength={10}
                                    data-test-id="panName"
                                    value={isUserName}
                                />
                                {isPan && (
                                    <FormHelperText data-test-id="pan-helperText" style={{ color: "#ff5722" }}>
                                        Note: Please enter exact 10 alphanumeric character of first 5 input will be
                                        alphabet , next 4 input number and last input is alpha.
                                    </FormHelperText>
                                )}
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                    <Grid item xs={4} className="flex_center">
                        <InputLabel className="inputLabel">Name of the Debt Seeker Company</InputLabel>
                    </Grid>

                    {checkingPanLoading ? (
                        <Grid item xs={6}>
                            <CircularProgress />
                        </Grid>
                    ) : (
                        <Grid item xs={6}>
                            <input
                                className="catalogue_textInput"
                                type="text"
                                name="debtSeeker"
                                value={debtSeeker}
                                data-testid="seekerCompanyName"
                                disabled={this.state.isReadMode || currentUserRoleName === Role_name.Seeker}
                                onChange={this.handleCompanyName}
                                onKeyUp={this.handleNumberChange}
                            />
                            {isErrorCompanyName && (
                                <FormHelperText style={{ color: "red", fontSize: "16px" }}>
                                    Please enter a valid company name.
                                </FormHelperText>
                            )}
                        </Grid>
                    )}

                    {(currentUserRoleName === Role_name.Arranger || currentUserRoleName === Role_name.Admin) &&
                    is_seeker_deal === false ? (
                        <>
                            <Grid item xs={4} className="flex_center">
                                <InputLabel className="inputLabel">Email of Issuer</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <input
                                    className="catalogue_textInput"
                                    type="email"
                                    name="isUserEmail"
                                    disabled={this.state.isReadMode || this.state.isDraft}
                                    onChange={this.handleEmailChange}
                                    onBlur={this.checkIssuerEmail}
                                    value={this.state.isUserEmail}
                                    data-testid="emailField"
                                />
                                {isErrorEmail && (
                                    <Typography
                                        data-test-id="emailErrorHelper"
                                        style={{ color: "red", fontSize: "16px" }}
                                    >
                                        Please enter a valid email address. Note: public domains are not accepted.
                                    </Typography>
                                )}
                                {issuerEmailPresent && (
                                    <Typography
                                        data-test-id="issuerIsEmailPresent"
                                        style={{ color: "red", fontSize: "16px" }}
                                    >
                                        Issuer is not present on the system. Send an invitation.
                                    </Typography>
                                )}
                            </Grid>
                            <Grid item xs={2} className="flex_center">
                                {issuerEmailPresent ? (
                                    <button
                                        data-testid="first-invite"
                                        className="deal_button"
                                        onClick={() =>
                                            this.setState({ seekerInviteModal: !seekerInviteModal }, () => {
                                                this.inviteIssuer();
                                            })
                                        }
                                        style={{ background: "#F87D2E", border: "none", marginBottom: "21px" }}
                                    >
                                        Invite
                                    </button>
                                ) : null}
                            </Grid>
                        </>
                    ) : (
                        ""
                    )}
                    <>
                        <Grid item xs={4} className="flex_center">
                            <InputLabel className="inputLabel">Upload Logo </InputLabel>
                        </Grid>

                        <Grid item xs={6}>
                            <Box className="catalogue_textInput">
                                <input
                                    type="file"
                                    id="customInput"
                                    style={{ display: "none", color: "black" }}
                                    accept="image/*"
                                    placeholder="logo name"
                                    name="logo"
                                    data-testid="logoField"
                                    disabled={this.state.isReadMode}
                                    onChange={this.onLogoChange}
                                />
                                <Box className="uploadBoxLebel">
                                    {imgError ? (
                                        <Typography className="fileLabel" style={{ color: "red" }}>
                                            Upload valid image
                                        </Typography>
                                    ) : imgUrl ? (
                                        <Typography className="fileLabel">{decodeURI(imgUrl)}</Typography>
                                    ) : logo?.name ? (
                                        <Typography className="fileLabel">{decodeURI(logo?.name)}</Typography>
                                    ) : (
                                        <Typography className="fileLabel" style={{ opacity: "0" }}>
                                            upload image
                                        </Typography>
                                    )}

                                    {logo || imgUrl ? (
                                        <span className="company_upload_lebel">
                                            <button
                                                data-testId="logo_url_remove"
                                                className="remove_button"
                                                disabled={this.state.isReadMode}
                                                onClick={() =>
                                                    this.setState({
                                                        logo: "",
                                                        imgUrl: "",
                                                        isNext: true,
                                                        delete_logo_image: true,
                                                    })
                                                }
                                            >
                                                X
                                            </button>{" "}
                                        </span>
                                    ) : imgError ? (
                                        <span className="company_upload_lebel">
                                            <p
                                                data-testId="logo_error_url_remove"
                                                style={{ margin: "0px" }}
                                                onClick={() => this.setState({ imgError: false })}
                                            >
                                                X
                                            </p>{" "}
                                        </span>
                                    ) : (
                                        <InputLabel className="company_upload_lebel" htmlFor="customInput">
                                            <AddCircleOutlineOutlinedIcon />
                                        </InputLabel>
                                    )}
                                </Box>
                            </Box>
                        </Grid>
                    </>
                    <Grid item xs={4} style={{ display: "flex", flexDirection: "column" }}>
                        <InputLabel className="inputLabel">Upload Video (Optional) </InputLabel>
                        <Typography style={{ color: "gray", width: "92%" }} className="videotext">
                            Video pitch covering business, market & strategy
                        </Typography>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="catalogue_textInput">
                            <input
                                onChange={this.onVideoChange}
                                type="file"
                                id="customInput2"
                                style={{ display: "none" }}
                                disabled={this.state.isReadMode}
                                accept="video/mp4,video/x-m4v,video/*"
                                name="video"
                                data-testid="videoField"
                            />
                            <Box className="uploadBoxLebel">
                                {videoError ? (
                                    <Typography className="fileLabel" style={{ color: "red" }}>
                                        Upload valid video
                                    </Typography>
                                ) : videoUrl ? (
                                    <Typography className="fileLabel">{decodeURI(videoUrl)}</Typography>
                                ) : video?.name ? (
                                    <Typography data-test-id="videoName" className="fileLabel">
                                        {decodeURI(video.name)}
                                    </Typography>
                                ) : (
                                    <Typography className="fileLabel" style={{ opacity: "0" }}>
                                        upload video
                                    </Typography>
                                )}
                                {video || videoUrl ? (
                                    <span className="company_upload_lebel">
                                        <button
                                            className="remove_button"
                                            data-testId="video_url_remove"
                                            disabled={this.state.isReadMode}
                                            onClick={() =>
                                                this.setState({
                                                    video: "",
                                                    videoUrl: "",
                                                    isNext: true,
                                                    delete_video: true,
                                                })
                                            }
                                        >
                                            X
                                        </button>
                                    </span>
                                ) : videoError ? (
                                    <span className="company_upload_lebel">
                                        <p
                                            style={{ margin: "0px" }}
                                            data-testId="video_error_url_remove"
                                            onClick={() => this.setState({ videoError: false })}
                                        >
                                            X
                                        </p>{" "}
                                    </span>
                                ) : (
                                    <InputLabel className="company_upload_lebel" htmlFor="customInput2">
                                        <AddCircleOutlineOutlinedIcon />
                                    </InputLabel>
                                )}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid item xs={4} className="flex_center">
                        <InputLabel className="inputLabel">Company Background </InputLabel>
                    </Grid>

                    <Grid item xs={6}>
                        <TextareaAutosize
                            name="companyBackground"
                            onChange={(e) => this.setState({ companyBackground: e.target.value, isNext: true })}
                            className="textBox"
                            data-testid="companyBackground"
                            maxRows={5}
                            minRows={5}
                            value={companyBackground}
                            disabled={this.state.isReadMode}
                        />
                    </Grid>

                    <Grid item xs={4} className="flex_center">
                        <InputLabel className="inputLabel">Company Presentation </InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                        <Box className="catalogue_textInput">
                            <input
                                onChange={this.onPresentationChange}
                                type="file"
                                id="customInput3"
                                style={{ display: "none" }}
                                data-testid="presentation"
                                accept=".pdf,.ppt,.pptx,video/mp4,video/x-m4v,video/*"
                                name="presentation"
                                disabled={this.state.isReadMode}
                            />
                            <Box className="uploadBoxLebel">
                                {PresentationError ? (
                                    <Typography className="fileLabel" style={{ color: "red" }}>
                                        Upload valid file
                                    </Typography>
                                ) : presentationUrl ? (
                                    <Typography className="fileLabel">{decodeURI(presentationUrl)}</Typography>
                                ) : presentation?.name ? (
                                    <Typography className="fileLabel">{decodeURI(presentation?.name)}</Typography>
                                ) : (
                                    <Typography className="fileLabel" style={{ opacity: "0" }}>
                                        upload presentation
                                    </Typography>
                                )}
                                {presentation || presentationUrl ? (
                                    <span className="company_upload_lebel">
                                        <button
                                            data-testId="presentation_url_remove"
                                            className="remove_button"
                                            disabled={this.state.isReadMode}
                                            onClick={() =>
                                                this.setState({
                                                    presentation: "",
                                                    presentationUrl: "",
                                                    isNext: true,
                                                    delete_presentation: true,
                                                })
                                            }
                                        >
                                            X
                                        </button>{" "}
                                    </span>
                                ) : PresentationError ? (
                                    <span className="company_upload_lebel">
                                        <p
                                            style={{ margin: "0px" }}
                                            data-testId="presentation_error_url_remove"
                                            onClick={() => this.setState({ PresentationError: false })}
                                        >
                                            X
                                        </p>{" "}
                                    </span>
                                ) : (
                                    <InputLabel className="company_upload_lebel" htmlFor="customInput3">
                                        <AddCircleOutlineOutlinedIcon />
                                    </InputLabel>
                                )}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12}>
                        <p className="header">Debt Arranger Details {currentUserRoleName !== Role_name.Arranger && "(Optional)"}</p>
                    </Grid>
                    {currentUserRoleName === Role_name.Arranger && (
                        <>
                            <Grid item xs={4}>
                                <InputLabel className="inputLabel">Debt Arranger Company Name</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="catalogue_textInput">
                                    <p style={{ marginTop: "5" }}>
                                        {arrangerCompanyName ? arrangerCompanyName : "Company Name"}
                                    </p>
                                </div>
                            </Grid>
                            {this.state.editMode && this.state.seekerDeal ? (
                                <Grid item xs={2} className="flex_center">
                                    <button
                                        className="deal_button"
                                        data-testid="second-invite"
                                        onClick={() => this.setState({ openModal: !openModal })}
                                        style={{ background: "#F87D2E", border: "none" }}
                                    >
                                        Invite
                                    </button>
                                </Grid>
                            ) : null}
                            <Grid item xs={4}>
                                <InputLabel className="inputLabel">Debt Arranger Name</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <div className="catalogue_textInput">
                                    <p style={{ marginTop: "5" }}>{arrangerName ? arrangerName : "Name"}</p>
                                </div>
                            </Grid>
                        </>
                    )}
                    {(currentUserRoleName === Role_name.Seeker || currentUserRoleName === Role_name.Admin) && (
                        <>
                            <Grid item xs={4}>
                                <InputLabel className="inputLabel">Debt Arranger Company Name</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                                    <Select
                                        disabled={this.state.isReadMode || this.state.isDraft || this.state.companyNameisDisable}
                                        className="catalogue_textInput"
                                        disableUnderline
                                        displayEmpty
                                        data-testid="companyName"
                                        style={{ borderRadius: "25px" }}
                                        name="debtArrangerCompanyName"
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                data-testid="companyNameSelect"
                                                style={{ position: "absolute", right: 12, pointerEvents: "none" }}
                                            />
                                        )}
                                        value={debtArrangerCompanyName}
                                        onChange={(e) => this.arrangerCompanyNameHandler(e)}
                                    >
                                        <MenuItem value="" disabled>
                                            <span style={{ color: "darkgray" }}>Company Name</span>
                                        </MenuItem>
                                        {debtArrangerCompanyData.map((item, i) => (
                                            <MenuItem key={i} value={item?.attributes?.id}>
                                                {item?.attributes?.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                            <Grid item xs={2} className="flex_center">
                                {this.state.editMode && currentUserRoleName !== Role_name.Admin ? (
                                    <button
                                        className="deal_button"
                                        data-testid="third-invite"
                                        onClick={() => this.setState({ openModal: !openModal })}
                                        style={{ background: "#F87D2E", border: "none" }}
                                    >
                                        Invite
                                    </button>
                                ) : null}
                            </Grid>
                            <Grid item xs={4}>
                                <InputLabel className="inputLabel">Debt Arranger Name</InputLabel>
                            </Grid>
                            <Grid item xs={6}>
                                <FormControl variant="outlined" size="small" style={{ width: "100%" }}>
                                    <Select
                                        disabled={this.state.isReadMode}
                                        className="catalogue_textInput"
                                        disableUnderline
                                        displayEmpty
                                        style={{ borderRadius: "25px" }}
                                        name="debtArrangerName"
                                        data-testid="debtArrangerName"
                                        IconComponent={() => (
                                            <KeyboardArrowDownIcon
                                                style={{ position: "absolute", right: 12, pointerEvents: "none" }}
                                            />
                                        )}
                                        onChange={(e) => this.debtArrangerNameHandler(e)}
                                        value={debtArrangerName}
                                    >
                                        <MenuItem value="" disabled>
                                            <span style={{ color: "darkgray" }}>Name</span>
                                        </MenuItem>
                                        {debtArrangerData?.map((item, i) => (
                                            <MenuItem
                                                data-test-id="arrangeNameMenu"
                                                key={i}
                                                value={item?.attributes.id}
                                            >
                                                {item?.attributes.name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </Grid>
                        </>
                    )}
                    <Grid item xs={4}></Grid>
                    <Grid item xs={8} style={{ padding: 'unset', height: '108px' }}>
                        {spinner ? (
                            <div className="circular_progress">
                                {" "}
                                <CircularProgress />{" "}
                            </div>
                        ) : (
                            !this.state.isReadMode && (
                                <Box className="catalog_button_wrapper">
                                    <button
                                        onClick={this.handleDraft}
                                        className="save_as_draft_button"
                                        data-testid="saveButton"
                                    >
                                        Save as Draft
                                    </button>
                                    <button
                                        className="next_button"
                                        data-testid="nextButton"
                                        onClick={this.handleSubmit}
                                    >
                                        Next
                                    </button>
                                </Box>
                            )
                        )}
                        {this.state.isReadMode ? (
                            <Box className="project_submit_button_wrapper">
                                <button
                                    data-testid="readModeNext"
                                    onClick={() => handleReviewSubmitClick(window.location, this.props.history)}
                                    className="next_button"
                                >
                                    Next
                                </button>
                            </Box>
                        ) : null}
                    </Grid>
                </Grid>

                <div>
                    {!this.state.isReadMode && <div style={{ textAlign: 'center', marginBottom: '90px' }}><b>Note:</b> Kindly utilize the Left Navigation Panel for switching between screens if you prefer not to make any modifications to the deal.</div>}
                    {this.state.isReadMode && <div style={{ textAlign: 'center', marginBottom: '90px' }}><b>Note:</b> Kindly utilize the Left Navigation Panel for switching between screens.</div>}
                </div>

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={seekerInviteModal}
                    onClose={() => this.setState({ seekerInviteModal: false })}
                    data-test-id="modal-switch"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={seekerInviteModal}>
                        {inviteIssuerLoading ? (
                            <div
                                className="modal_paper circular_progress"
                                style={{ borderRadius: "12px", width: "460px", height: "215px" }}
                            >
                                {" "}
                                <CircularProgress />
                            </div>
                        ) : (
                            <div className="modal_paper" style={{ borderRadius: "12px", width: "460px" }}>
                                {sentSuccessfully ? (
                                    <div>
                                        <div className="modal margin_auto">
                                            <img src={verified_tick_image} alt="" width={45} />
                                        </div>
                                        <p className="header" style={{ textAlign: "center" }}>
                                            Invite sent successfully to the user's email.
                                        </p>
                                        <p style={{ textAlign: "center", cursor: "pointer" }}>
                                            <u
                                                data-testid="seekerInviteModal"
                                                style={{ color: "orange" }}
                                                onClick={() => this.setState({ seekerInviteModal: false })}
                                            >
                                                Close
                                            </u>
                                        </p>
                                    </div>
                                ) : (
                                    <p style={{ color: "red" }}>Something went wrong. Please try again.</p>
                                )}
                            </div>
                        )}
                    </Fade>
                </Modal>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={openModal}
                    onClose={() => this.setState({ openModal: false })}
                    data-test-id="modal-switch2"
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <div className="modal_paper" style={{ borderRadius: "12px", width: "460px" }}>
                            {isEmailsent ? (
                                inviteArrangerLoading ? (
                                    <div
                                        className=" circular_progress"
                                        style={{ borderRadius: "12px", width: "460px", height: "215px" }}
                                    >
                                        {" "}
                                        <CircularProgress />
                                    </div>
                                ) : (
                                    <div>
                                        <div className="modal margin_auto">
                                            <img src={verified_tick_image} alt="" width={45} />
                                        </div>

                                        <p className="header" style={{ textAlign: "center" }}>
                                            Invite sent successfully to the user's email.
                                        </p>
                                        <p style={{ textAlign: "center", cursor: "pointer" }}>
                                            <u
                                                data-testid="arrangerInviteModal"
                                                style={{ color: "orange" }}
                                                onClick={() =>
                                                    this.setState({ openModal: !openModal }, () => {
                                                        this.setState({ isEmailsent: false });
                                                    })
                                                }
                                            >
                                                Close
                                            </u>
                                        </p>
                                    </div>
                                )
                            ) : (
                                <>
                                    <p className="sub_header BlueText" style={{ textAlign: "center" }}>
                                        Invite User form
                                    </p>

                                    <div className="">
                                        <Grid container xs={12} spacing={2}>
                                            <Grid item xs={5}>
                                                <p style={{ marginTop: "0px" }}>Company</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <p style={{ marginTop: "0px", color: "gray" }}>
                                                    {currentUserInfo.company_name}
                                                </p>
                                            </Grid>
                                            <Grid item xs={5}>
                                                <p style={{ marginTop: "0px" }}>Name</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {" "}
                                                <form action="" autoComplete="off">
                                                    <input
                                                        className="inviteTextInput"
                                                        style={{ marginTop: "0px", color: "gray" }}
                                                        value={this.state.inviteName}
                                                        data-testid="checkArrangerInviteName"
                                                        onChange={(e: any) => this.handleInviteNameChange(e)}
                                                    />
                                                </form>
                                                {this.state.isErrorInviteName ? (
                                                    <p
                                                        data-test-id="error-invite-name"
                                                        style={{ color: "red", margin: "0px" }}
                                                    >
                                                        Name should be between 2 - 25 character.
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </Grid>
                                            <Grid item xs={5}>
                                                <p style={{ marginTop: "0px" }}>Email</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                {" "}
                                                <form action="" autoComplete="off">
                                                    <input
                                                        type="email"
                                                        className="inviteTextInput"
                                                        style={{ marginTop: "0px", color: "gray" }}
                                                        data-testid="checkArrangerInvite"
                                                        onChange={this.checkArrangerInviteEmail}
                                                    />
                                                </form>
                                                {this.state.inviteEmailError ? (
                                                    <p
                                                        data-test-id="inviteErrorMail"
                                                        style={{ color: "red", margin: "0px" }}
                                                    >
                                                        Please input valid email.
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </Grid>

                                            <Grid item xs={5}>
                                                <p style={{ marginTop: "0px" }}>Phone Number</p>
                                            </Grid>
                                            <Grid item xs={7}>
                                                <form action="" autoComplete="off">
                                                    <input
                                                        className="inviteTextInput"
                                                        maxLength={10}
                                                        style={{ marginTop: "0px", color: "gray" }}
                                                        value={this.state.invitePhone}
                                                        data-testid="checkArrangerInvitePhone"
                                                        onChange={(e: any) => this.handleMobileChange(e)}
                                                    />
                                                </form>
                                                {this.state.isErrorMobile ? (
                                                    <p
                                                        data-test-id="errorMobile"
                                                        style={{ color: "red", margin: "0px" }}
                                                    >
                                                        Please input valid phone number.
                                                    </p>
                                                ) : (
                                                    ""
                                                )}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <button
                                        className={
                                            !inviteName ||
                                            !inviteEmail ||
                                            !invitePhone ||
                                            inviteEmailError ||
                                            this.state.isErrorInviteName ||
                                            this.state.isErrorMobile
                                                ? "deal_button_disable margin_auto modal"
                                                : "deal_button margin_auto modal"
                                        }
                                        disabled={
                                            !inviteName ||
                                            !inviteEmail ||
                                            !invitePhone ||
                                            inviteEmailError ||
                                            this.state.isErrorInviteName ||
                                            this.state.isErrorMobile
                                        }
                                        style={{ marginTop: "10px" }}
                                        data-testid="isEmailSent"
                                        onClick={() =>
                                            this.setState({ isEmailsent: !isEmailsent }, () => {
                                                this.inviteArranger();
                                            })
                                        }
                                    >
                                        Invite
                                    </button>
                                </>
                            )}
                        </div>
                    </Fade>
                </Modal>
            </>
        );
    }
}
export default withRouter(CompanyOverView );
// Customizable Area End
