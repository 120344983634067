import React from "react";
import {
    FormControl,
    InputLabel,
    TextField,
    makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
    label: {
        paddingTop: "1%",
        paddingBottom: "1%",
        // fontFamily: "Roboto-Regular",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#000",
        paddingLeft: "16px",
    },
    formControl: {},
    textField: {
        width: "100%",
        height: "51px",
        // border: "1px solid blue",
        maxWidth: "501px",
        [`& fieldset`]: {
            borderRadius: 25,
            height: "51px",
        },
    },
});

type InputProps = {
    name: string;
    label: string;
    placeholder: string;
    onChange: any;
    value: string;
    type: any
};
function CustomInput(props: InputProps) {
    const { placeholder, label, value, onChange, name, type } = props;
    const classes = useStyles();

    return (
        <>
            <TextField
                type={type}
                className={classes.textField}
                fullWidth
                size="small"
                variant="outlined"
                placeholder={placeholder}
                name={name}
                value={value}
                onChange={onChange}
            />
        </>
    );
}

export default CustomInput;
