  // Customizable Area Start
  import React, { useContext } from "react";
  import { GlobalContext } from "../../catalogue/src/useContext";
  import { IBlock } from "../../../framework/src/IBlock";
  import { Message } from "../../../framework/src/Message";
  import { BlockComponent } from "../../../framework/src/BlockComponent";
  import MessageEnum, {
    getName
  } from "../../../framework/src/Messages/MessageEnum";
  import { runEngine } from "../../../framework/src/RunEngine";
  import { DealDetailsInterface } from "./Interface.web";
  import { Link } from "react-router-dom";
import { callApi, isLoggedIn, Role_name } from "../../../components/src/common";

  export const configJSON = require("./config");
  const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
  const QB = new QuickBlox();
  
  export interface Props {
    navigation: any;
    id: string;
    history: any;
    match: any;
    location: any;
  
  }
  
  interface S {
    arrayHolder: any;
    token: string;
    openFilter:boolean;
    openModal:boolean;
      openMakeOfferModal:boolean;
    dealDetails:DealDetailsInterface | any;
    amount:string;
    yield:string;
    brief:string;
    showInterest:string;
    isInvestor:boolean;
    settingModal:boolean;
    kyc:boolean;
    sendOtp: boolean;
    OTP: any;
    isMobileVerified:boolean;
    snackbarOpen:any;
    snackbarMessage:any;
    snackbarOpenError: boolean;
    snackbarMessageError:any;
    navigateTOWithdraw:boolean;
    isShowInterest:boolean;
    tempAmount:any;
    tempYield:any;
    errors:any;
    pieChartData:any[];
    rows:any[];
    open: boolean;
    seekerEmailId: any;
    arrangersEmailIds: any;
    arrangersQuickbloxIds:any;
    provider_quickblox_id:any;
    groupChatDialogId:any;
    dealName:string
  }
  
  interface SS {
    id: any;
  }
  
  export default class DealDetailsController extends BlockComponent<
    Props,
    S,
    SS
  > {
    static contextType = GlobalContext
    getProductApiCallId: any;
    LoggedUserRole: any;
     getDealDetailsId:string=''
     showInterestId:string=''
     getOtpFromUserApiCallId:string=""
     verify_mobile_OTP_ApiCallId:string=''
     verifyToMakeOfferApiCallId:string=""
     verifyToWithDrawOfferApiCallId:string=''
     verifyToMakeOfferApiCallIdNew:string=''
     chatCredentialsApiCalledId:any=''
     chatCrdedentials:any=''
     notificationsToChatApiCallId:any=''
    constructor(props: Props) {
      super(props);
      this.receive = this.receive.bind(this);
  
      this.subScribedMessages = [
        getName(MessageEnum.RestAPIResponceMessage),
        getName(MessageEnum.SessionSaveMessage),
        getName(MessageEnum.SessionResponseMessage)
     
      ];
  
      this.state = {
        arrayHolder: [],
        rows: [],
        pieChartData: [["x", "y", { role: "tooltip", type: "string"}]],
        token: "",
        openFilter:false,
        openModal:false,
          openMakeOfferModal:false,
        dealDetails:{},
        amount:'',
        yield:'',
        brief:'',
        isInvestor:false,
        settingModal:false,
        showInterest:'',
        kyc:false,
        sendOtp:false,
        OTP: [],
        isMobileVerified:false,
        snackbarOpen:false,
        snackbarMessage:"",
        snackbarOpenError: false,
        snackbarMessageError:"",
        errors:{},
        navigateTOWithdraw:false,
        isShowInterest:true,
        tempAmount:"",
        tempYield:"",
        provider_quickblox_id:'',
        groupChatDialogId:'',
        open : false,
        seekerEmailId:'',
        arrangersEmailIds:[],
        arrangersQuickbloxIds: [],
        dealName:''
      };
      runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    }
  
    async componentDidMount() {
      const kyc=localStorage.getItem("is_kyc_done")
      const authToken = isLoggedIn(this.props.navigation);
      const chatData= {method:'GET',payload:'',endPoint:configJSON.chatCredentialsAPiEndPoint}
      
      if(typeof(authToken) === "string"){
          kyc==='true'&& this.setState({kyc:true})
          this.getLoggedUserInfo()
          this.chatCrdedentials= callApi(chatData)
      }
    }
  
    
  
    async receive(from: string, message: Message) {
      if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
        const apiRequestCallId = message.getData(
          getName(MessageEnum.RestAPIResponceDataMessage)
        );
        
  
        var responseJson = message.getData(
          getName(MessageEnum.RestAPIResponceSuccessMessage)
        );
  
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
  
        if (responseJson) {
              /* istanbul ignore next */
          if (this.getDealDetailsId === apiRequestCallId) {
            if(responseJson?.errors?.length>0){
              const msg183: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg183.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]);
              this.send(msg183);
            } 
            
            this.setState({dealDetails:responseJson,
              seekerEmailId : responseJson.deal.data.attributes.issuer_email,
              arrangersEmailIds:responseJson.deal.data.attributes.arranger_emails,
              groupChatDialogId:responseJson.groupchat_dialog_id
            },()=>{ if(this.state.isInvestor&& responseJson.show_interest?.length<1){
              this.handleGetChatUser()}
            })
            this.context.OtpDispatch({ type: 'UPDATE_OTP_DATA', payload: responseJson })
            if(responseJson?.meta?.kyc==true && responseJson?.meta?.bank_detail ==true){
              this.setState({isShowInterest:false})
            }
            
            responseJson?.show_interest && this.setState({showInterest:responseJson?.show_interest[0]})
            this.setState({rows:responseJson?.xlsx_arr})

            let data:any[]=[["x", "y", { role: "tooltip", type: "string"}]]
            const len=responseJson?.structures?.data?.attributes?.share_holdings.length;
            for(var i=0 ; i <len ; i++){
              data.push(["",responseJson?.structures?.data?.attributes?.share_holdings[i]?.percentage_holding,`${responseJson?.structures?.data?.attributes?.share_holdings[i]?.share_holder_type}`])
            }
            this.setState({pieChartData:data})
          }
          if (this.showInterestId === apiRequestCallId) {
               this.props.history.push("DealDiscovery?current=Ongoing_Deals")   
          }
          /* istanbul ignore next */
          if(this.LoggedUserRole===apiRequestCallId){
            if(responseJson.data?.attributes?.role_name===Role_name.Provider){
              const urlParams = new URLSearchParams(window.location.search).get('dealId');
              this.setState({isInvestor:true})
              this.getDealDiscovery(urlParams)
            }else{
              const msg181: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg181.addData(getName(MessageEnum.CustomAlertBodyData), 'Please log in as a provider');
              this.send(msg181);
              this.props.navigation.navigate("EmailAccountLoginBlock");
            }
          }
          if (this.chatCrdedentials === apiRequestCallId) {
            
            this.context.ChatDispatch({ type: 'CHAT_DATA', payload:responseJson.data[0].attributes  })
            
            
            // console.log(this.context.ChatState, 'global chat')
          }
        }
      }
      ///opt call id to make an offer
    if (this.getOtpFromUserApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );


      if (apiResponse?.data) {
        localStorage.setItem("MakeAnOffer",apiResponse?.meta?.token)
        this.setState({snackbarOpen:true,snackbarMessage:"verification code sent via SMS"})
        this.setState({ sendOtp: true })
      }
      else {
        this.setState({ sendOtp: false })
      }
    }
    //otp confirm messageId
    if (this.verify_mobile_OTP_ApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data?.attributes?.activated) {
        this.setState({isMobileVerified:true})
      
      }
      else {
        this.setState({ isMobileVerified: false })
        this.setState({snackbarOpenError:true,snackbarMessageError:"Invalid Pin "})
        this.setState({ sendOtp: true })
      }
    }
    //withdraw error
      /* istanbul ignore next */
    if (this.verifyToWithDrawOfferApiCallId === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        this.props.history.push("DealDiscovery?current=Transaction_History")
      }
      else {
        const msg182: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg182.addData(getName(MessageEnum.CustomAlertBodyData), 'Something went wrong please try again');
        this.send(msg182);
      }
    }
    ///make an offer deal
    /* istanbul ignore next */
    if (this.verifyToMakeOfferApiCallIdNew === message.getData(getName(MessageEnum.RestAPIResponceDataMessage))) {
      const apiResponse = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (apiResponse?.data) {
        location.replace("DealDiscovery?current=Ongoing_Deals")
      }
      else {
        const msg184: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg184.addData(getName(MessageEnum.CustomAlertBodyData), "Something went wrong please try again");
        this.send(msg184);
      }
    }
    }
  
    makeAnOffer = () => {
      if (this.handleVlaidationsForSelectOffer()) {
          this.setState({ openModal: !this.state.openModal })
          // this.handleMakeAnOffer()
          this.createGroupChatDialog()
      } else {
          this.setState({
              errors: this.state.errors
          })
      }
  }
    move = (e: any, prev: string, current: string, next: string) => {
      let length = (document.getElementById(current) as HTMLInputElement)?.value?.length;
      let maxLength = document.getElementById(current)?.getAttribute("maxLength");

      let otimep1 = (document.getElementById('text1') as HTMLInputElement)?.value || '';
      let otimep2 = (document.getElementById('text2') as HTMLInputElement)?.value || '';
      let otimep3 = (document.getElementById('text3') as HTMLInputElement)?.value || '';
      let otimep4 = (document.getElementById('text4') as HTMLInputElement)?.value || '';

      let OTP = [otimep1, otimep2, otimep3, otimep4]
 /* istanbul ignore next */
      if (String(length) == maxLength) {
         /* istanbul ignore next */
          if (next !== "") {
              document.getElementById(next)?.focus()
          }
      }
      if (e.key === "Backspace") {
          if (prev !== "") {
              document.getElementById(prev)?.focus()
          }
      }

      OTP = OTP.filter(item => !!item)
      this.setState({ OTP })
      OTP.length == 4 ? setTimeout(this.verifyMobileOtp(OTP), 500) : null
  }

    getDealDiscovery = (param:any) => {
      const userToken = localStorage.getItem("token");
      const header = {
        "Content-Type": configJSON.companyOverviewApiContentType,
        token: userToken
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
  
      this.getDealDetailsId = requestMessage.messageId;
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `bx_block_bank_deal/deal_discovery/View_deal?deal_id=${param}`
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiMethodTypeGet
      );
  
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  
    handleMakeAnOffer = () => {
      const {amount,brief}=this.state;
      const userToken = localStorage.getItem("token");
      const debt_deal_id = new URLSearchParams(window.location.search).get('dealId');
  
      const headers = {
        "Content-Type": configJSON.applicationType,
        token: userToken
      };
   
      const data = {
          proposed_invester_amount:amount,
         brief,
          proposed_invester_yield_percentage:this.state.yield,
         debt_deal_id
        };
      const httpBody = {
        data,
        interest_data: {
          interest: true,
          debt_deal_id,
          
      },
      provider_quickblox_id: this.state.provider_quickblox_id,
      groupchat_dialog_id:this.state.groupChatDialogId,
      };
  
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.showInterestId = requestMessage.messageId;
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),'bx_block_bank_deal/deal_proposed_investments'
      )
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
  
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
            
    }
    //Otp to make and offer start
    handleOtpToMakeAnOffer =async () => {
      const headers = {
        "Content-Type": "application/json",
        "token": localStorage.getItem("token")
      }
    
      const httpBody = {
       
         data: {
              attributes: {
                  "full_phone_number":this.context.OtpState?.current_user?.full_phone_number,
                  "debt_deal_id":this.context.OtpState?.deal?.data?.id,
              }
          }
    
      }
      const getpasswordfrom = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.getOtpFromUserApiCallId = getpasswordfrom.messageId;
    
      getpasswordfrom.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.getOtpFromUserApiEndpoint
      );
    
      getpasswordfrom.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
      );
    
      getpasswordfrom.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(httpBody)
      );
    
      getpasswordfrom.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.getOtpFromUserApiMethod
        
      );
      runEngine.sendMessage(getpasswordfrom.id, getpasswordfrom);
    }
        //Otp to make and offer End
/* Verify Mobile OTP */
verifyMobileOtp = (OTP: any) => {
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "token":localStorage.getItem("token")
  };

  const httpBody = {
    sms_token: `${localStorage.getItem("MakeAnOffer")}`,
    pin: OTP.join('')
  };

  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.verify_mobile_OTP_ApiCallId = getValidationsMsg.messageId;

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.verify_OTP_ApiEndPoint
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.verify_OTP_ApiMethod
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
};
handleWidthDrawOffer=()=>{
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "token":localStorage.getItem("token")
  };


  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.verifyToWithDrawOfferApiCallId = getValidationsMsg.messageId;

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
   `bx_block_bank_deal/offers/withdraw?deal_id=${this.context.OtpState?.deal?.data?.id}`
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    "DELETE"
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}

handletoDealDiscovery=()=>{
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "token":localStorage.getItem("token")
  };

  const httpBody = {
    data: {
      "amount": this.state.amount,
      "yield": this.state.yield,
      "debt_deal_id":this.context.OtpState?.deal?.data?.id, 
      "make_an_offer":true
      
  }
  };
  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.verifyToMakeOfferApiCallId = getValidationsMsg.messageId;

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.validationApiEndPoint
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethod
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
handletoDealDiscoveryForMakeAnOffer=()=>{
  const headers = {
    "Content-Type": configJSON.validationApiContentType,
    "token":localStorage.getItem("token")
  };

  const httpBody = {
    data: {
      "amount": this.state.amount,
      "yield": this.state.yield,
      "debt_deal_id":this.context.OtpState?.deal?.data?.id, 
      "make_an_offer":false
  }
  };
  const getValidationsMsg = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );
  this.verifyToMakeOfferApiCallIdNew = getValidationsMsg.messageId;

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    configJSON.validationApiEndPoint
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(headers)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(httpBody)
  );

  getValidationsMsg.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    configJSON.validationApiMethod
  );
  runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
}
handleVlaidationsForBorrowersYield=()=>{
  let status=true;
  let mininvest=Number(this.state.dealDetails?.proposed_instrument?.data?.attributes?.minimum_investment_per_investor)
  let borrowersAmount=Number(this.state.dealDetails?.proposed_instrument?.data?.attributes?.total_deal_size)
  let providerAmount=Number(this.state.amount)
  
  if (this.state.amount === '') {
    this.state.errors['amount'] = "Enter your amount"
    status = false
  }
  if (borrowersAmount<providerAmount) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  if (providerAmount<mininvest) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  if (providerAmount==0 ) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  
  if (this.state.yield === '') {
    this.state.errors['yield'] = "Enter your yield"
    status = false
  }
  if (this.state.yield === "0") {
    this.state.errors['yield'] = "Enter valid yield"
    status = false
  }
  
  return status
}
hideBlur=()=>{
  if (this.state.tempAmount!=""){
    this.setState({tempAmount:this.state.tempAmount+"Cr"})
    this.setState({amount:this.state.tempAmount})
  }
  if (this.state.tempAmount==""){
    this.setState({amount:""})
  }
}
hideBlur1=()=>{

  if (this.state.tempYield!=""){
    this.setState({tempYield:this.state.tempYield+"%"})
    this.setState({yield:this.state.tempYield})
  }
  if (this.state.tempYield==""){
    this.setState({yield:""})
  }

}
mouseenter=()=>{
    this.setState({tempAmount:this.state.amount})
  
}
mouseenter1=()=>{

  this.setState({tempYield:this.state.yield})

}
hideError = (event: any) => {
  this.state.errors[event.target.name] = '';
  this.setState({
    errors: this.state.errors
  })
}
navigateToDealDiscovery=async() => {
 if(this.handleVlaidationsForBorrowersYield()){
  await this.handletoDealDiscoveryForMakeAnOffer()

 }  else {
  this.setState({
    errors: this.state.errors
  })
}
 
}
withDrawOffer=async()=>{
   await this.handleWidthDrawOffer()
}
handleClose = (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === "clickaway") {
    return;
  }

  this.setState({ snackbarOpen: false })
};
handleCloseError= (event?: React.SyntheticEvent, reason?: string) => {
  if (reason === "clickaway") {
    return;
  }

  this.setState({ snackbarOpenError: false })
};
handleVlaidationsForSelectOffer=()=>{
  let status=true;
  let mininvest=Number(this.state.dealDetails?.proposed_instrument?.data?.attributes?.minimum_investment_per_investor)
  let borrowersAmount=Number(this.state.dealDetails?.proposed_instrument?.data?.attributes?.total_deal_size)
  let providerAmount=Number(this.state.amount)
  if (this.state.amount === '') {
    this.state.errors['amount'] = "Enter your amount"
    status = false
  }
  if (borrowersAmount<providerAmount) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  if (providerAmount<mininvest) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  if (providerAmount==0 ) {
    this.state.errors['amount'] = "Enter valid amount"
    status = false
  }
  
  if (this.state.yield === '') {
    this.state.errors['yield'] = "Enter your yield"
    status = false
  }
  if (this.state.yield === "0") {
    this.state.errors['yield'] = "Enter valid yield"
    status = false
  }
  
  
 
  return status
}

checkValidations=()=>{
 
  if (this.state.amount!=""&& this.state.yield!=""&& this.state.brief!=""){
return  false;
  }
 else{
  return true;
 }
}

getLoggedUserInfo = () => {
  this.LoggedUserRole = callApi({
    method: "GET",
    endPoint: 'account_block/accounts',
  })
}
onlyNumbers = (event:any) => {
  return !isNaN(event.key);
}

params = {
  email: localStorage.getItem('email'),
  password: configJSON.someSecret,
};


handleGetChatUser =  async() => {
  if(QB.chat && localStorage.getItem('email')===localStorage.getItem('chatEmail')){
    return
  }
  const {application_id,auth_key,auth_secret,accout_key} = this.context.ChatState 
        
  QB.init(application_id,auth_key , auth_secret, accout_key);
await  QB.createSession((error: any, result: any) => {
    console.log('session result',result)
    QB.login(this.params, (error: any, result: any) => {
      if(!error){
        console.log('login result',result)
        this.getArrangersQuickbloxIds()
        

      }
     
      localStorage.setItem('id', result.id)
      localStorage.setItem('chatEmail',result.email)
      
      
      const userCredentials = {
        userId: result.id,
        password: configJSON.password
      }
      this.setState({provider_quickblox_id:result?.id,})
      QB.chat.connect(userCredentials, (error: any, contactList: any) => {
       
       
      });
    })
  })
}

createGroupChatDialog= ()=>{
  const {dealDetails} = this.state

  const params = {
    type: 1,
    name: dealDetails.project_detail?.data.attributes.project_name
    
  };
  
  QB?.chat?.dialog?.create(params, (error:any, dialog:any)=> {
    
   
    this.setState({groupChatDialogId :dialog?._id},()=>{this.handleMakeAnOffer()})
   
  });
}


getArrangersQuickbloxIds = async()=> {
    let quickbloxId :any=[]
  let emailIds = [this.state.seekerEmailId,...this.state.arrangersEmailIds]

  await Promise.all(
    emailIds.map(async(email:any)=>{
  
    let id =  await this.handleGetUser(email)
      if(id!== 'error.code'){
        quickbloxId.push(id)
      }
    })
  )

  
  
  this.setState({arrangersQuickbloxIds: quickbloxId})
  

}

handleGetUser = async(email:any)=>{

  let searchParams = {email: email};

return new Promise(function(resolve, reject) {

QB.users.get(searchParams,async(error:any,user:any)=> {

if (error) {
  
  resolve('error.code')
} else {
  
  resolve(user.id)
}
})

})

}

  handleOpenChatBox = ()=>{
    console.log('open', this.state.open)
    this.setState({open : true})
    const urlParams = new URLSearchParams(window.location.search).get('dealId');
    this.sendEmailNotifications(urlParams)
  }
  
  handleDialog= ()=>{
    this.setState({open: !this.state.open})
  }

  sendEmailNotifications = (offerId:any)=>{
    const data={method:'GET',payload:'',
    endPoint:configJSON.initiateChatApiEndpoint +`?deal_id=${offerId}`}
    this.notificationsToChatApiCallId= callApi(data)
   
    }

    // Customizable Area End
  }

  
