import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  // Customizable Area Start
  Grid,
  AppBar,
  Tabs,
  Tab,
  TextField,
  Link,
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import './emailAccountLogin.css';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import EmailAccountLoginController, {
  Props,
} from "./EmailAccountLoginController";
import {
  createTheme,
  ThemeProvider,
  withStyles,
} from "@material-ui/core/styles";
import { backgroundImage } from "./assets";
import { logo } from "./assets";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      // fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const CssTextField = withStyles({
  root: {
    "& .MuiOutlinedInput-root": {
      "&:hover fieldset": {
        border: "1px solid #c4c4c4"
      },
      "&.Mui-focused fieldset": {
        border: "1px solid #c4c4c4"
      }
    }
  }
})(TextField);

// Customizable Area End

export default class EmailAccountLoginBlock extends EmailAccountLoginController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  typeOfLabel = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({
      tabIndexValue: index,
    });
  };
  getEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    let email = e.currentTarget.value;

    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailArray = email.split("@");

    !regex.test(email)
      || emailArray[0].length > 64 ||
      emailArray[1].length > 256
      ?
      this.setState({ emailError: true })
      : this.setState({ emailError: false });

    this.setState({ email: e.currentTarget.value });
  };
  // Customizable Area End

  render() {
    return (
      // Customizable Area Start
      <Grid container className="grid_parent_email">
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="background_email">
          <img src={logo} alt="logo" />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} className="inner_grid_email">
          <Typography align="center" id="MuiTypography-root">Welcome</Typography>
          <AppBar position="static" className="app_bar_email">
            <Tabs
              indicatorColor="primary"
              variant="fullWidth"
              value={this.state.tabIndexValue}
              onChange={this.typeOfLabel}
            >
              <Tab
                label="Login with Mobile"
                id="tab"
              />
              <Tab
                label="Login with Email"
                id="tab"
              />
            </Tabs>
          </AppBar>
          {this.state.tabIndexValue == 0 ? (
            this.props.navigation.navigate("MobileAccountLoginBlock")
          ) : (
            <Box className="parent_box_textfield">
              <Box id="email_parent" className="box">
                <CssTextField
                  variant="outlined"
                  id="outlined-required"
                  size="small"
                  required
                  fullWidth
                  label={'Email'}
                  value={this.state.email}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.getEmail(e)
                  }}
                  placeholder="example@gmail.com"
                  inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                />
                {this.state.emailError ? <Typography style={{ color: 'red', fontSize: '12px' }}>Please Enter Valid Email</Typography> : ''}
              </Box>
              <Box className="password_box">
                <CssTextField
                  variant="outlined"
                  type="password"
                  // id="textfield"
                  size="small"
                  required
                  label={'Password'}
                  value={this.state.password}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    this.setState({ password: e.target.value });
                  }}
                  fullWidth
                  onKeyPress={(e) => e.key === 'Enter' && this.doEmailLogIn()}
                  placeholder="password"
                  inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
                />
              </Box>
              <Box className="forgot_password_email_box">
                <InputLabel id="forgot_password_email_link">
                  <span

                    onClick={() => { this.props.navigation.navigate("ForgotPassword") }}
                    id="forgot_password_email_link"
                  >
                    Forgot Password?
                  </span>
                </InputLabel>
              </Box>
              <Box className="email_login_box">
                <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }} open={this.state.snackbarOpen} autoHideDuration={4000} onClose={this.handleClose} >
                  <Alert severity="success">
                    {this.state.snackbarMessage}
                  </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }} open={this.state.snackbarOpenError} autoHideDuration={4000} onClose={this.handleCloseError} >
                  <Alert severity="error">
                    {this.state.snackbarMessage}
                  </Alert>
                </Snackbar>
                <Button
                  variant="contained"
                  disabled={(this.state.email != '' && this.state.password != '') ? false : true}
                  id="button"
                  style={{
                    backgroundColor: `${(this.state.email != '' && this.state.password != '') ? 'darkblue' : ''}`
                  }}
                  onClick={() => {
                    this.doEmailLogIn();
                    if (localStorage.getItem("successMsg") === "User is successfully logged in") {

                    }
                  }
                  }>{this.state.loadingSignin ? <CircularProgress /> : "Login"}
                </Button>
              </Box>
              <InputLabel className="dont_have_account_label">
                Don't have an account?
                <span
                  onClick={() => { this.props.navigation.navigate("EmailAccountRegistration") }}
                  id="sign_up_link"
                >
                  Sign Up
                </span>
              </InputLabel>
            </Box>
          )}
        </Grid>
      </Grid>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
// Customizable Area End
