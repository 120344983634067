import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
  pan: string;
  getPanValue: (e: any) => void;
  handleMinuSign: (e: any) => void;
  placeholder: string;
  errorPanNumber: boolean;
  panCardName: string;
  panCard: string;
  removeUploadFile: () => void;
  uploadFile: (e: any) => void;
  uploadDetail: boolean;
  file: any;
  errorUploadPanPhoto: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
      
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class PanNumberController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.state={
        
    };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
