import React from "react";
// Customizable Area Start
import {
  Box,
  InputLabel,
   Select,
   TextareaAutosize,
   Typography,
   Container,
   Grid,
   FormControl,
   TextField
} from "@material-ui/core";
import { Link } from "react-router-dom";

import "./style.css";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";


import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";

import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import SupportiiveDocumentController,{ Props } from "./SupportiveDocumentController";
import Catalogue from '../../catalogue/src/Catalogue.web';

const steps = [
  'Select Deal',
  'Review Deal',
  'Decision',
];

export default class ReviewDeal extends SupportiiveDocumentController {
  render() {
    return (
      <>
        <Catalogue id="" navigation={this.props.navigation} history={undefined} match={undefined} location={undefined} />
      </>
    );
  }
}

const webStyles = {
  textInput_container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: "18px"
  },
  inputLabel: {
    color: "#000",
    marginBottom: "5px",
    padding: "0 60px",
    width: "390px",
    fontSize:"18px",
    // fontWeight:"400"
  },
  details:{
    fontWeight: 700,
    fontSize: "30px",
    padding: " 0 60px",
    marginTop: "50px"
  },
  detailWrapper:{
    display: "flex",
    alignItems: "center",
    margin: "20px 0"
  },
  formControl: {
    borderRadius: '25px',
    width:'0'
  },
  selectTag: {
    borderRadius: '25px',
  },

};

// Customizable Area End
