// Customizable Area Start
import React from "react";
import "./style.css";
import moment from "moment";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider, withStyles } from "@material-ui/core/styles";
import { Typography, Grid, Button, Box, InputLabel, Select, FormControl, TextField, InputAdornment } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import AddCircleOutlineOutlinedIcon from "@material-ui/icons/AddCircleOutlineOutlined";
import ClippedDrawer from "./ClippedDrawer.web";
import ManagementProfileController from "./ManagementProfileController";
import { styled } from '@material-ui/core/styles';
import { IDirectorData } from "./ManagementProfileController";
import DirectorProfileFormController, { Props } from "./DirectorProfileFormController";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

const StyledTextField:any = styled(TextField)(() => ({
    '& fieldset': {
        borderRadius: '25px',
        height: '55px !important'
    },
}));

export default class DirectorProfileForm extends DirectorProfileFormController {
    constructor(props: Props) {
        super(props);
    }
    getFile = (event: React.ChangeEvent<any>) => {
        const currentManagementData: IDirectorData = this.props.directManagementData;
        if (event.target.files[0].type == 'application/pdf' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
            if (currentManagementData) {
                currentManagementData.fileDirector = event.target.files[0];
                currentManagementData.dir_fileUploadingError = false;
            }
        }
        else {
            currentManagementData.dir_fileUploadingError = true;
        }
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    handleInputChange = (item:any) => { 
        const currentManagementData: IDirectorData = this.props.directManagementData;
        currentManagementData.dir_abbrebiation = item;
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getName = (e: React.ChangeEvent<any>) => {
        const regex = /^[A-Za-z ]+$/;
        const trimmedValue = e.target.value.trim();
        const currentManagementData: IDirectorData = this.props.directManagementData;
        if ((regex.test(trimmedValue) || trimmedValue == '') && e.target.value.charAt(0)!==' ') {
            currentManagementData.dir_name = e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1);
            currentManagementData.dir_showNameError = false;
        }
        else {
            currentManagementData.dir_showNameError = true
        }
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getDesignation = (e: React.ChangeEvent<any>) => {
        const currentManagementData: IDirectorData = this.props.directManagementData;
        const trimmedValue = e.target.value.trim();
        if(e.target.value.charAt(0)!==' '){
        currentManagementData.dir_designation = e.target.value;
        }
        currentManagementData.dir_showDesignationError = false;
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getEducation = (e: React.ChangeEvent<any>) => {
        const currentManagementData: IDirectorData = this.props.directManagementData;
        const trimmedValue = e.target.value.trim();
        if(e.target.value.charAt(0)!==' '){
        currentManagementData.dir_education = e.target.value;
        }
        currentManagementData.dir_showEducationError = false
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getDin = (e: React.ChangeEvent<any>) => {
        const regex = /^[0-9]+$/;
        const currentManagementData: IDirectorData = this.props.directManagementData;
        if (regex.test(e.target.value) == true || e.target.value == '') {
            const currentManagementData: IDirectorData = this.props.directManagementData;
            currentManagementData.dir_din = e.target.value;
            currentManagementData.dir_showDinError = false;
        }
        if (e.target.value.length != 8 || regex.test(e.target.value) === false) {
            currentManagementData.dir_showDinError = true;
        }
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getRemark = (e: React.ChangeEvent<any>) => {
        const currentManagementData: IDirectorData = this.props.directManagementData;
        const trimmedValue = e.target.value.trim();
        currentManagementData.dir_remark = e.target.value
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    getDateOfAppointment = (e: React.ChangeEvent<any>) => {
        const trimmedValue = e.target.value.replace('|', '/');
        const currentManagementData: IDirectorData = this.props.directManagementData;
        currentManagementData.dateOfAppointment = trimmedValue;
        this.props.updateDirectManageMentData(currentManagementData, this.props.index);
    };
    closeBttn = () => {
        this.props.deleteManageData(this.props.index)
    };
    removeUploadFile = (index: any) => {
        const currentDirectorData: IDirectorData = this.props.directManagementData;
        currentDirectorData.dir_uploaded_file = '';
        currentDirectorData.directorUploadFileName = '';
        currentDirectorData.fileDirector = '';
        currentDirectorData.dir_fileUploadingError = false;
        currentDirectorData.director_profileId ? currentDirectorData.deleted_image = true : '';
        this.props.updateDirectManageMentData(currentDirectorData, index);
    };
    closeDropDown=()=>{
        if(this.state.showDropDown){
         this.setState({
             showDropDown:false
         });
        }
     };
    render() {
        var dropDownValue: any = [
            { abbrebiation: 'Mr' },
            { abbrebiation: 'Ms' },
            { abbrebiation: 'Mrs' }
        ];
        return (
            <Grid container spacing={3} className={this.props.index > 0 ? "director_grid_parent_one" : "director_grid_parent"} onClick={this. closeDropDown}>
                {this.props.index > 0 ?
                    <Grid item md={12}>
                        <Box className="management_clost_button">
                            <Typography onClick={()=>{
                                if(!this.props.isReadMode){
                                    this.closeBttn()
                                }
                            }}>X</Typography>
                        </Box>
                    </Grid>
                    :
                    null}
                <Grid item md={4}>
                    <Typography className="management_label_name">Name</Typography>
                </Grid>
                <Grid item md={8}>
                    <Box className="management_right_container">
                        <Box className="drop_down" id="drop_val" onClick={()=>{
                            if(!this.props.isReadMode){
                                this.setState({
                                    showDropDown:!this.state.showDropDown
                                });
                            }
                            }}>
                         <Box className="drop_down_value">
                            <Box className="drop_down_icon">
                                <Typography>{this.props.directManagementData.dir_abbrebiation}</Typography>
                            </Box>
                            <InputLabel htmlFor="drop_val">
                              <KeyboardArrowDown  className="drop_down_icon"/>
                            </InputLabel>
                          </Box>
                          {
                           this.state.showDropDown?
                          <Paper className="drop">
                          {
                            dropDownValue.map((item:any,index:number)=>{
                                return(
                                    <Box className="drop_val" onClick= {()=> this.handleInputChange(item.abbrebiation) }>
                                       <Typography className="val">{item.abbrebiation}</Typography>
                                       {index<dropDownValue.length-1 && <Divider variant="fullWidth" style={{marginTop:'10%'}}/>}
                                    </Box>
                                );
                            })
                          }
                          </Paper>
                          :
                          null
                          }
                        </Box>
                        <input disabled={this.props.isReadMode} style={{ marginLeft: '10px' }} className="kyc_test" value={this.props?.directManagementData?.dir_name} type="text"
                            onChange={(e) => { this.getName(e) }}
                        />
                    </Box>
                    {this.props?.directManagementData?.dir_showNameError ? <Typography className="management_error_label">Please Enter Valid Name</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Designation</Typography>
                </Grid>
                <Grid item md={8}>
                    <input disabled={this.props.isReadMode} value={this.props?.directManagementData?.dir_designation}
                        onChange={(e) => { this.getDesignation(e) }}
                        className="kyc_test" type="text" />
                    {this.props?.directManagementData?.dir_showDesignationError ? <Typography className="management_error_label">Please Enter Valid Designation</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">DIN</Typography>
                </Grid>
                <Grid item md={8}>
                    <input disabled={this.props.isReadMode} value={this.props?.directManagementData?.dir_din}
                        onChange={(e) => this.getDin(e)}
                        maxLength={8}
                        className="kyc_test" type="text" />
                    {this.props?.directManagementData?.dir_showDinError && <Typography className="management_error_label">Please Enter Valid DIN Number</Typography>}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Date Of Appointment</Typography>
                </Grid>
                <Grid item md={8}>
                    <StyledTextField
                        disabled={this.props.isReadMode}
                        size="small" variant="outlined"
                        value={this.props.directManagementData?.dateOfAppointment}
                        style={{ cursor: "pointer", color: "gray", width: '100%' }}
                        onChange={(e:any) => { this.getDateOfAppointment(e) }}
                        InputProps={{ inputProps: { max: moment().format('YYYY-MM-DD') } }}
                        onKeyDown={(e:any) => e.preventDefault()}
                        type="date"
                    />
                    {this.props?.directManagementData?.dir_showDateAppointmentError ? <Typography className="management_error_label">Please Enter Valid Date Of Appointment</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Education</Typography>
                </Grid>
                <Grid item md={8}>
                    <input disabled={this.props.isReadMode} className="kyc_test" value={this.props?.directManagementData?.dir_education}
                        onChange={(e) => { this.getEducation(e) }}
                        type="text" />
                    {this.props?.directManagementData?.dir_showEducationError ? <Typography className="management_error_label">Please Enter Valid Education</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Remarks (Optional)</Typography>
                </Grid>
                <Grid item md={8}>
                    <textarea disabled={this.props.isReadMode} className="management_profile_remarks" value={this.props?.directManagementData?.dir_remark} rows={1} cols={1}
                        onChange={(e) => { this.getRemark(e) }}
                    />
                    {this.props?.directManagementData?.dir_showRemarkError ? <Typography className="management_error_label">Please Enter Valid Remark</Typography> : ''}
                </Grid>
                <Grid item md={4}>
                    <Typography className="management_label">Upload Picture (Optional)</Typography>
                </Grid>
                <Grid item md={8}>
                    {
                        this.props?.directManagementData?.directorUploadFileName ?
                            <Box className="management_text_input">
                                <Box className="uploadBoxLebel">
                                    <a href={this.props?.directManagementData?.dir_uploaded_file} style={{ color: '#323232' ,textOverflow: "ellipsis",overflow:"hidden",whiteSpace : "nowrap"}}>{this.props?.directManagementData?.directorUploadFileName}</a>
                                    <Typography onClick={() => { if(!this.props.isReadMode){ this.removeUploadFile(this.props.index)} }} className="remove_upload_file">X</Typography>
                                </Box>
                            </Box>
                            :
                            <Box className="management_text_input">
                                <input type="file"
                                    disabled={this.props.isReadMode}
                                    id={"directorCustomInput1" + this.props.index}
                                    style={{ display: "none", color: "black" }}
                                    accept="image/*"
                                    placeholder="logo name"
                                    name="logo"
                                    onChange={(e) => { this.getFile(e) }}
                                />
                                <Box className="uploadBoxLebel">
                                    {
                                        this.props?.directManagementData?.fileDirector?.name ?
                                            <Typography className="fileLabel" style={{ color: '#323232'}}>{this.props?.directManagementData?.fileDirector?.name}</Typography>
                                            : this.props?.directManagementData?.dir_fileUploadingError ? <Typography className="management_error_label">Please Upload Photo only</Typography> : <Typography className="fileLabel" style = {{color : 'grey'}}>upload jpeg / png / tif etc.</Typography>

                                    }
                                    {
                                        this.props?.directManagementData?.fileDirector || this.props?.directManagementData?.dir_fileUploadingError ?
                                            <Typography onClick={() => { if(!this.props.isReadMode){this.removeUploadFile(this.props.index)} }} className="remove_upload_file">X</Typography>
                                            :
                                            <InputLabel className="company_upload_lebel" htmlFor={"directorCustomInput1" + this.props.index}>
                                                <AddCircleOutlineOutlinedIcon />
                                            </InputLabel>
                                    }
                                </Box>
                            </Box>
                    }
                </Grid>
            </Grid>
        )
    }
}
// Customizable Area End
