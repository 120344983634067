import React from "react";
// Customizable Area Start
import {
    Box,
    Typography,
    Grid,
} from "@material-ui/core";
import {
    finLogo,
    linkedIn,
    twitter,
    facebook,
    instagram,
} from "./assets";
import "./styles.css";

import LandingPageController, { Props } from "./LandingPageController";


export default class Footer extends LandingPageController {
    constructor(props: Props) {
        super(props);

    }


    render() {
        const { navigation } = this.props;
        return (
            <Grid container spacing={2} xs={12} style={{ display: "block" }}>
            <Box className="footer_container">
                <Grid item xl={3} xs={3}>
                    <Box className="footer_content_container_logo" >
                        <img
                            alt="brand_logo"
                            src={finLogo}
                            className="footer_logo"
                        />
                    </Box>
                </Grid>
                <Grid item xl={3} xs={3}>
                    <Box className="footer_content_container_termsAnd_condition" >
                        <Typography
                            className="termAndCondition"
                            data-test-id="term"
                            onClick={() => {
                                navigation.navigate("TermAndCondition");
                            }}
                        >
                            Terms and Conditions
                        </Typography>
                        <Box mt={5} />
                        <Typography
                            className="termAndCondition"
                            data-test-id="policy"
                            onClick={() => {
                                navigation.navigate("PrivacyPolicy");
                            }}
                        >
                            Privacy Policy
                        </Typography>
                        <Box mt={5} />
                        <Box className="footer_content_container_social_icons" >
                            <img src={instagram} className="social_icons" />
                            <a href="https://www.linkedin.com/company/truboard-partners/" target="_blank"><img src={linkedIn} className="social_icons" /></a>
                            <a href="https://twitter.com/truboardams" target="_blank"><img src={twitter} className="social_icons" /></a>
                            <img src={facebook} className="social_icons" />
                        </Box>

                    </Box>
                </Grid>
                <Grid item xl={3} xs={2}>
                    <Box className="footer_content_container_career_blog">
                        <Typography
                            className="termAndCondition"
                            data-test-id="carrer"
                            onClick={() => {
                                navigation.navigate("Careers");
                            }}
                        >
                            <a href="#" style={{textDecoration:"none",color:"#323232"}}>Career</a></Typography>
                        <Box mt={5} />
                        <Typography
                            className="termAndCondition"
                            data-test-id="blogs"
                            onClick={() => {
                                navigation.navigate("Blog");
                            }}
                        >
                            <a href="#" style={{textDecoration:"none",color:"#323232"}}>Blog</a></Typography>
                    </Box>
                </Grid>
                <Grid item xl={3} xs={4}>
                    {/* <Box className="footer_content_container_contactus"> */}
                    <Box pb={2} >
                        <Typography className="termAndConditionContact" >Contact Us</Typography>
                    </Box>
                    <Typography className="contact_us_content">
                        TruJasper, 321, Building no 3,  Solitaire <br /> Corporate Park, Guru Hargovindji Road,<br /> Chakala, Andheri East,<br /> Mumbai - 400093<br /> (Phone no - 022- 62397971)
                    </Typography>
                    {/* </Box> */}
                </Grid>
            </Box>
        </Grid>
        );
    }
}