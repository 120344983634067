
import CustomSelect from "./CustomSelect";
import CustomCheckbox from "./CustomCheckBox";
import CustomDatePicker from "./CustomDatePicker";
import CustomButton from "./CustomButton";
import CustomInput from "./CustomInput";
import CustomSelectAddMore from "./CustomSelectAddMore";
const Controls = {

    CustomSelect,
    CustomCheckbox,
    CustomDatePicker,
    CustomButton,
    CustomInput,
    CustomSelectAddMore

}

export default Controls;