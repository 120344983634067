import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, isAuthorisedUser, isLoggedIn } from "../../../components/src/common";


export const configJSON = require("./config");

export interface ITakeActiongObject {
  data:{
    id: string;
    type: string;
    attributes:{
      id: number;
      deal_debt_arrangers_id: number;
      debt_arranger_companies_id: number;
      issuer_email?: string;
      issuer_company_pan?: string;
      debt_seeker_company_name: string;
      company_background: string;
      upload_video: string;
      review_status: string;
      deal_profile_step_status?: string;
      listed_date: string;
      upload_logo_image: string;
      upload_logo_image_name: string;
      company_presentation_file: string;
    }
  };
  interest_received: number;
  is_offer_accepted: boolean;
  offer_received: number;
  offer_accepted: number;
  iskyc: boolean;
  is_view_details: boolean;
}

export interface IShowAllocateResponse{
  amount: string;
  is_offer_accepted: boolean;
  maturity_date: string;
  total_allocation_percentage: number | string;
  total_allocation_amount: number;
  is_any_offer_accepted: boolean;
  yield: string;
  project_name: string;
  status: string;
  is_view_details: boolean;
  data:{
    id:string;
    type:string;
    attributes:{
      groupchat_dialog_id(groupchat_dialog_id: any, id: string): unknown;
      accept_datetime: null | string;
      account_id: number;
      utr: string;
      amount: number;
      date_of_offer: string;
      debt_deal_id: number;
      investor_name: string;
      reject_remarks?: string;
      rejected_by?: string;
      show_status: string;
      status: string;
      withdraw_datetime?: string;
      yield: number;
      upload_files: {
        id: number,
        url: string,
        name: string
      } | null
    }
  }[];
}

export interface Props {
  navigation: any;
  id: string;

  history: any;
  match: any;
  location: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;

  isButtonEnable: boolean;
  allApproveData: any[];
  allPendingData: any[];
  onGoingDealActiveButton: boolean;
  transactionHistoryActiveButton: boolean;
  innerWidth: any;
  isLoading: boolean;
  takeActionResponse: null | ITakeActiongObject;
  isKycDone: boolean;
  allocateDealPopUp: boolean;
  onGoingDeals: any[];
  otpPopUp: boolean;
  otp: any;
  isWithdrawPopup: boolean;
  allocateData: null | IShowAllocateResponse;
  otpToken: string;
  otpOfferId: string;
  isArranger: boolean;
  isViewAllocation: boolean;
  afterAccept: boolean;
  afterReject: boolean;
  isRejectPopup: boolean;
  redirect: boolean;
  rejectRemark: string;
  rejectOfferId: string;
  open:boolean;
  dialogId : any;
  groupchatDialogId:any;
}

interface SS {
  id: any;


}

export default class TakeActionController extends BlockComponent<
  Props,
  S,
  SS
> {
  getTakeActionDataForDealApiCallId: string = "";
  authToken: string = "";
  dealId: string = "";
  showAllocateApiCallId: string = "";
  sendAcceptOtpApiCallId: string = "";
  verifyAcceptOtpApiCallId: string = "";
  rejectOfferApiCallId: string = "";
  withdrawApiCallId: string = "";
  acceptedOfferFileUploadApiCallId: string = "";
  acceptedOfferFileRemoveApiCallId: string = "";
  isTransaction: boolean = false;
  connectWithInvestorApiCallId:string=''


  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);


    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),

      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.redirectMessage),


    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,

      isButtonEnable: true,
      allApproveData: [],
      allPendingData: [],
      onGoingDealActiveButton: true,
      transactionHistoryActiveButton: false,
      innerWidth: "",
      isLoading: false,
      takeActionResponse: null,
      isKycDone: true,
      allocateDealPopUp: false,
      onGoingDeals: [
        {
          status: 'rejcted'
        },
        {
          status: 'Offer accepted'
        },
        {
          status: 'rejcted'
        }
      ],
      otpPopUp: false,
      otp: "",
      isWithdrawPopup: false,
      allocateData: null,
      otpToken: "",
      otpOfferId:"",
      isArranger: false,
      isViewAllocation: false,
      afterAccept: false,
      afterReject: false,
      isRejectPopup: false,
      redirect: false,
      rejectRemark: "",
      rejectOfferId: "",
      open : false,
      dialogId:'',
      groupchatDialogId:''
// Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);



  }
  async componentDidMount() {
    super.componentDidMount();
    const authToken = isLoggedIn(this.props.navigation);
    const roleId = localStorage.getItem("role_id");
    if(roleId === String(3)){
      this.setState({
        isArranger: true
      })
    }
    if(this.props.location.search === "?transactionHistory"){
      this.isTransaction = true;
      this.setState({
        transactionHistoryActiveButton: true,
        onGoingDealActiveButton: false
      })
    }
    if(this.props.match.params.dealId){
      this.dealId = this.props.match.params.dealId
    }
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getTakeActionData();
    }
    window.addEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };
  async receive(from: string, message: Message) {
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect2 = message.getData(getName(MessageEnum.redirectData));
      if (this.state.redirect && customRedirect2) {
        this.props.history.push("/ContentManagement/DealsDashboard")
      }
      if (this.state.afterAccept && customRedirect2) {
        this.setState({
          otpPopUp: false
        }, () => {
          this.showAllocateApiCall();
          this.getTakeActionData();
        })
      }
      if (this.state.afterReject && customRedirect2) {
        this.setState({
          isRejectPopup: false
        }, () => {
          this.showAllocateApiCall();
          this.getTakeActionData();
        })
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      const isAuthorized = isAuthorisedUser(responseJson);
      if(!isAuthorized){
        this.props.history.push("/EmailAccountLoginBlock");
        return;
      }
      this.setState({
        isLoading: false
      })
      if (this.getTakeActionDataForDealApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg212: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg212.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg212);
          return;
        } else if(responseJson.data){
          this.setState({
            takeActionResponse: responseJson,
            isKycDone: responseJson.iskyc
          })
        }
      } else if (this.showAllocateApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg213: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg213.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg213);
        } else if(responseJson.data){
          this.setState({
            allocateData: responseJson,
            allocateDealPopUp: true
          })
        } else if(responseJson.message && !responseJson.data){
          const msg007: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg007.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message);
          this.send(msg007);
        }
      } else if (this.sendAcceptOtpApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg214: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg214.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg214);
        }else if(responseJson.errors){
          let res1 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg001: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg001.addData(getName(MessageEnum.CustomAlertBodyData), res1);
          this.send(msg001);
        } else if(responseJson.message){
          const msg002: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg002.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message);
          this.send(msg002);
        } else if (responseJson.meta && responseJson.data){
          const msg215: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg215.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message[0].account);
          this.send(msg215);
          this.setState({
            allocateDealPopUp: false,
            otpPopUp: true,
            otp: "",
            otpToken: responseJson.meta.token
          })
        } 
      } else if (this.verifyAcceptOtpApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg216: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg216.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg216);
        } else if(responseJson.errors){
          let res2 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg003: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg003.addData(getName(MessageEnum.CustomAlertBodyData), res2);
          this.send(msg003);
        } else if(responseJson.data){
          const msg217: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg217.addData(getName(MessageEnum.CustomAlertBodyData), "Offer Accepted.");
          this.send(msg217);
          this.setState({ afterAccept :true})
        } else {
          const msg0002: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0002.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg0002);
        }
      }
      else if (this.verifyAcceptOtpApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg218: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg218.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg218);

        } else if(responseJson.errors){
          let res3 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg004: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg004.addData(getName(MessageEnum.CustomAlertBodyData), res3);
          this.send(msg004);
        } else if(responseJson.data){
          const msg219: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg219.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg219);
          // this.setState({
          //   isRejectPopup: false
          // },()=>{
          //   this.showAllocateApiCall();
          // })
        }
      }
      else if (this.withdrawApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg220: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg220.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg220);
        } else {
          let res005 = responseJson.message
          const msg005: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg005.addData(getName(MessageEnum.CustomAlertBodyData), res005);
          this.send(msg005);
          if (responseJson.message === "Deal withdrawn successfully"){
            this.setState({ redirect :true})
            // this.props.history.push("/ContentManagement/DealsDashboard")
          }
          
        }
      }
      else if (this.acceptedOfferFileUploadApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg221: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg221.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg221);
        } else if(responseJson.errors){
          const msg222: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg222.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors);
          this.send(msg222);
        } else if(responseJson.data){
          const msg222: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg222.addData(getName(MessageEnum.CustomAlertBodyData), 'File Uploaded Successfully');
          this.send(msg222);
          this.showAllocateApiCall();
        }
      } else if (this.acceptedOfferFileRemoveApiCallId === apiRequestCallId) {
        /* istanbul ignore next */
        if(responseJson.error){
          const msg223: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg223.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg223);
        } else if(responseJson.errors){
          const msg224: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg224.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg224);
        } else if(responseJson.data){
          const msg225: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg225.addData(getName(MessageEnum.CustomAlertBodyData), "File Removed Successfully");
          this.send(msg225);
          this.showAllocateApiCall();
        }
      } else if(this.rejectOfferApiCallId === apiRequestCallId){
        /* istanbul ignore next */
        if(responseJson.error){
          const msg226: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg226.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg226);
        } else if(responseJson.errors){
          let res4 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg006: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg006.addData(getName(MessageEnum.CustomAlertBodyData), res4);
          this.send(msg006);
        } else if(responseJson.data && responseJson.meta){
          const msg227: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg227.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg227);
          this.setState({ afterReject :true})
        }
      }
    }


  }


  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  getTakeActionData() {
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getTakeAction = apiCall({
      url: configJSON.getTakeActionForOnGoingDealsApiEndpoint + `/${this.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getTakeActionForOnGoingDealsApiMethod
    });

    this.getTakeActionDataForDealApiCallId = getTakeAction.messageId;
    runEngine.sendMessage(getTakeAction.id, getTakeAction);
  }

  allocateDealPopUP = () => {
    this.setState({
      isViewAllocation: false
    },()=>{
      this.showAllocateApiCall();
    })
  };

  handleViewAllocationClick = () => {
    this.setState({
      isViewAllocation: true
    },()=>{
      this.showAllocateApiCall();
    })
  }

  closeAllocatePopup = () =>{
    this.setState({
      allocateDealPopUp: false
    })
  };

  handleBackClick = () =>{
    if(this.isTransaction){
      this.props.history.replace("/ContentManagement/DealsDashboard?transactionHistory");
    }else {
      this.props.history.replace("/ContentManagement/DealsDashboard")
    }
    
  }
  setOtpPopUp = (offerId: string) => {
    this.sendOtpForAccept(offerId);
  }

  handleOtpBack = () =>{
    this.setState({
      allocateDealPopUp: true,
      otpPopUp: false
    })
  }

  handleRemarkBack = () =>{
    this.setState({
      allocateDealPopUp: true,
      isRejectPopup: false
    })
  }

  showAllocateApiCall = () =>{
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const showAllocate = apiCall({
      url: configJSON.showAllocateApiEndpoint + `/${this.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.showAllocateApiMethod
    });

    this.showAllocateApiCallId = showAllocate.messageId;
    runEngine.sendMessage(showAllocate.id, showAllocate);
  }

  sendOtpForAccept = (offerId: string) =>{
    
    this.setState({
      isLoading: true,
      otpOfferId: offerId
    })
    const header = getHeaders({ token: this.authToken });

    const sendOtp = apiCall({
      url: configJSON.sendOtpForAcceptApiEndpoint + `${offerId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.sendOtpForAcceptApiEndMethod
    });

    this.sendAcceptOtpApiCallId = sendOtp.messageId;
    runEngine.sendMessage(sendOtp.id, sendOtp);
  }

  validateAndPrepareRequestBody = () =>{
    /* istanbul ignore next */
    if(String(this.state.otp).length < 4){
      const msg228: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg228.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid Otp");
      this.send(msg228);
      return false
    } else {
      const requestBody = {
        sms_token: this.state.otpToken,
        pin: this.state.otp
      }
      return requestBody
    }
  }
  
  callVerifyOtpForAccept = () =>{
    const requestBody = this.validateAndPrepareRequestBody();
    if(!requestBody){
      return false;
    }

    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const verifyOtp = apiCall({
      url: configJSON.verifyOtpForAcceptApiEndpoint + `${this.state.otpOfferId}`,
      header,
      httpBody: requestBody,
      httpMethod: configJSON.verifyOtpForAcceptApiMethod
    });

    this.verifyAcceptOtpApiCallId = verifyOtp.messageId;
    runEngine.sendMessage(verifyOtp.id, verifyOtp);
  }

  handleDealOneClick = () => {
    if(this.state.isArranger){
      this.setState({
        isViewAllocation: true
      },()=>{
        this.showAllocateApiCall();
      })
    }
  }

  handleRejectClick = (offerId: string) =>{
    this.setState({
      isRejectPopup: true,
      allocateDealPopUp: false,
      rejectOfferId: offerId
    })
  }

  prepareRejectRequestBody = () => {
    /* istanbul ignore next */
    if(this.state.rejectRemark.trim().length === 0){
      const msg229: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg229.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter remark");
      this.send(msg229);
      return false;
    }
    return {
      reject_remarks: this.state.rejectRemark
    }
  }

  rejectApiOffer = () =>{
    const requestBody = this.prepareRejectRequestBody();
    if(!requestBody){
      return false;
    }
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const rejectOffer = apiCall({
      url: configJSON.rejectOfferApiEndpoint + `${this.state.rejectOfferId}`,
      header,
      httpBody: requestBody,
      httpMethod: configJSON.rejectOfferApiMethod
    });

    this.rejectOfferApiCallId = rejectOffer.messageId;
    runEngine.sendMessage(rejectOffer.id, rejectOffer);
  }
  
  handleEditClick = () => {
    localStorage.setItem("deal_id", this.dealId);
    this.props.history.push("/Catalogue/CompanyOverview")
  }

  showWithdrawPopup = () => {
    this.setState({
      isWithdrawPopup: true
    })
  }

  closeWithdrawPopup = () =>{
    this.setState({
      isWithdrawPopup: false
    })
  }

  withdrawDeal = () => {
    /* istanbul ignore next */
    this.setState({
      isLoading: true,
      isWithdrawPopup :false

    })
    const header = getHeaders({ token: this.authToken });

    const withdrawOffer = apiCall({
      url: configJSON.withdrawDealApiEndpoint + `${this.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.withdrawDealApiMethod
    });

    this.withdrawApiCallId = withdrawOffer.messageId;
    runEngine.sendMessage(withdrawOffer.id, withdrawOffer);
  }

  handlePendingKycClick = () => {
    this.props.history.push("/CfEkycVerification3");
  }

  handleAcceptedFileUpload = (files: any, offerId: string) =>{
    // Validating File Type starts
    const validFileTypes = ['image', 'application/pdf', 'zip', 'rar'];
    const uploadedFileType = files[0]?.type;
    let isValidFile = false;
    validFileTypes.forEach((validType)=>{
      if(uploadedFileType.includes(validType)){
        isValidFile = true;
      }
    });
    /* istanbul ignore next */
    if(!isValidFile){
      const msg230: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg230.addData(getName(MessageEnum.CustomAlertBodyData), 'Only Image, Pdf and Zip File are allowed');
      this.send(msg230);
      return false;
    }

    // Validating File Type ends

    this.setState({
      isLoading: true
    })
    const header = {
      "token":this.authToken
    };

    const formData = new FormData();
    Object.entries(files).forEach((file:any) => {
      formData.append("data[file]", file[1]);
    });

    const submitUploadFile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptedOfferFileUploadApiCallId = submitUploadFile.messageId;

    submitUploadFile.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.acceptedOfferFileUploadApiEndpoint + `/${offerId}`
    );

    submitUploadFile.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    submitUploadFile.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
    );

    submitUploadFile.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
    );

    runEngine.sendMessage(submitUploadFile.id, submitUploadFile);

  }

  handleAcceptedFileRemove = (offerId: string) =>{

    this.setState({
      isLoading: true
    })
    const header = {
      "token":this.authToken
    };

    const removeUploadFile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.acceptedOfferFileRemoveApiCallId = removeUploadFile.messageId;

    removeUploadFile.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.acceptedOfferFileRemoveApiEndpoint + `${offerId}`
    );

    removeUploadFile.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
    );

    removeUploadFile.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.acceptedOfferFileRemoveApiMethod
    );

    runEngine.sendMessage(removeUploadFile.id, removeUploadFile);
  }

  handleOnGoing = () => {
    this.setState({
        transactionHistoryActiveButton: false,
        onGoingDealActiveButton: true
    });
  };

  handleTransactionHistory = () => {
    this.props.history.push("/ContentManagement/DealsDashboard?transactionHistory")
  };

  handleAddClick = () =>{
    localStorage.removeItem("deal_id");
    localStorage.removeItem("proposedInstrumentDetails_id");
    localStorage.removeItem("security_id");
    localStorage.removeItem("projectDetails_id");
    localStorage.removeItem("fin_summary_id");
    localStorage.removeItem("debtProfile_id");
    localStorage.removeItem("supportive_id");
    localStorage.removeItem("manage_deal_edit")
  }

  handleOtpChange = (e:React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({
      otp: e.target.value
    })
  }

  handleRemarkChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    this.setState({
      rejectRemark: e.target.value
    })
  }
  handleOpenChatBox = ()=>{
    this.setState({open : true})
  }

  handleDialog= ()=>{
    this.setState({open:false})
  }

  handleOpenDialogBox = (groupchatDialogId:any,offerId:any)=>{
  this.sendNotifications(offerId)

    this.setState({open : true, groupchatDialogId:groupchatDialogId  },
      )
  }

  sendNotifications = (offerId:any)=>{
   
    const header = {
      "token": this.authToken
    };

     const getDeals = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.connectWithInvestorApiCallId = getDeals.messageId;

    getDeals.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.connectWithInvestorApiEndpoint + `?offer_id=${offerId}`
    );

    getDeals.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    getDeals.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      'GET'
    );

    runEngine.sendMessage(getDeals.id, getDeals);

    

  }

  // Customizable Area End
}
