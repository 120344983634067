import React from 'react';
// Customizable Area Start
import { handleDynamicBackground, options } from './common';
import { Chart } from "react-google-charts";


const PieChart = (props:any) => {
    return (
        <div style={{
            display:'flex',
            alignItems:'flex-start',
            gap:'20%'
        }}>
            <div>
                <p className="shareHolding_asOn">Shareholder type</p>
                {
                  props?.shareHolderType?.map((holdingName:any,i:any)=>(

                            <>
                        <div className="flex_center" style={{gap:'20px'}}>
                            <div style={{
                                width:'20px',
                                height:'20px',
                                backgroundColor:handleDynamicBackground(i)
                            }}/>
                            <p>{holdingName?.share_holder_type}</p>
                        </div>
                            <div style={{ marginLeft: '41px' }}>
                                <span>{holdingName?.remarks ? `Remark: ${holdingName?.remarks}` : 'Remark: N/A'}</span>
                            </div>
                        </>
                  )) 
                
                }
            </div>
            <div>
                <Chart
                    chartType="PieChart"width="100%" height="400px"
                    data={props?.pieChartData} options={options}
                />
            </div>
            
        </div>
    );
};

export default PieChart;
//Structure of Api calling End