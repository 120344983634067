import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start


  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  passwordDetails: any;
  isErrorPassword: any;
  success_dialog_box: any;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class ChangePasswordController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  // Customizable Area Start
  getDealDescriptionId: string = '';
  changePasswordAPICallId: string = "";

  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      passwordDetails: {
        old_password: '',
        new_password: '',
        confirm_new_password: '',
      },
      isErrorPassword: {
        old_password: false,
        new_password: false,
        confirm_new_password: false,
      },
      success_dialog_box: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
  }



  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.changePasswordAPICallId != null &&
      this.changePasswordAPICallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      /* istanbul ignore next */
      if(responseJson && responseJson?.errors){
        const msg235: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg235.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.password);
        this.send(msg235);
      }
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({
          success_dialog_box: true
        })
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleChangePasswordDetails = (e: any) => {
      const regex=/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/;
      if(regex.test(e.target.value) || e.target.value.includes('')){
        this.setState({
          passwordDetails: { ...this.state.passwordDetails, [e.target.name]: e.target.value, },
          isErrorPassword:{...this.state.isErrorPassword,[e.target.name]:false}
        });
      }
      if(regex.test(e.target.value)==false){
            this.setState({
              isErrorPassword:{...this.state.isErrorPassword,[e.target.name]:true}
            });
      }
  };

  validatePasswordDetails = (values: any) => {
    const errors: any = {};
    if (!values.old_password) {
      errors.old_password = "Old Password is required!";
    }
    if (!values.new_password) {
      errors.new_password = "New Password is required!";
    }
    if (!values.confirm_new_password) {
      errors.confirm_new_password = "Confirm Password is required!";
    }
    return errors;
  };

  // handleChangePassword = () => {
  //   this.setState({ isErrorPassword: this.validatePasswordDetails(this.state.passwordDetails) },
  //     () => {
  //       const { old_password, new_password, confirm_new_password } = this.state.passwordDetails;
  //       if (old_password !== '' && new_password !== '' && confirm_new_password !== '') {
  //         const header = {
  //           "Content-Type": configJSON.contentTypeApiUpdateUser,
  //           token: `${localStorage.getItem("token")}`
  //         };

  //         const httpBody = {
  //           data: this.state.passwordDetails
  //         }
  //         const requestMessage = new Message(
  //           getName(MessageEnum.RestAPIRequestMessage)
  //         );
  //         this.changePasswordAPICallId = requestMessage.messageId;

  //         requestMessage.addData(
  //           getName(MessageEnum.RestAPIResponceEndPointMessage),
  //           configJSON.changePasswordAPIEndPoint
  //         );
  //         requestMessage.addData(
  //           getName(MessageEnum.RestAPIRequestHeaderMessage),
  //           JSON.stringify(header)
  //         );
  //         requestMessage.addData(
  //           getName(MessageEnum.RestAPIRequestBodyMessage),
  //           JSON.stringify(httpBody)
  //         );
  //         requestMessage.addData(
  //           getName(MessageEnum.RestAPIRequestMethodMessage),
  //           configJSON.apiUpdateUserType
  //         );
  //         runEngine.sendMessage(requestMessage.id, requestMessage);
  //       }
  //     })
  // };
   handleChangePassword = () =>{
    const { old_password, new_password, confirm_new_password } = this.state.passwordDetails;
        if (old_password !== '' && new_password !== '' && confirm_new_password !== '') {
          const header = {
            "Content-Type": configJSON.contentTypeApiUpdateUser,
            token: `${localStorage.getItem("token")}`
          };

          const httpBody = {
            data: this.state.passwordDetails
          }
          const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
          );
          this.changePasswordAPICallId = requestMessage.messageId;

          requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.changePasswordAPIEndPoint
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(httpBody)
          );
          requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.apiUpdateUserType
          );
          runEngine.sendMessage(requestMessage.id, requestMessage);
        }
   }
  handleClose = () => {
    this.setState({
      success_dialog_box: false
    })
  }

  // Customizable Area End
}
