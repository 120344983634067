// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  children?: React.ReactNode;
  index: number;
  value: number;
  history: any;
  location: any;
  match: any;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  debtSeekerSelectValue: number;
  debtSeekerInputLabel: string;
  debtArrangerSelectedValue: string;
  debtArrangerInputLabel: string;
  debtProviderInputLabel: string;
  debtProviderSelectedValue: string;
  role_id: string;
  showKycAddBankDetailForm: boolean;
  showDematDetailForm: boolean;
  tabIndexValue: number;
  pan: string;
  cin: string;
  llpin: string;
  file: any;
  nsdlFile: any;
  name: string;
  bankName: string;
  accNo: string;
  confirmAccNo: string;
  ifscCode: string;
  bankCheck: any;
  demateCMRCopy: any;
  bankNameValue: string;
  demat_detail_type: string;
  clientId: string;
  dpId: string;
  uploadDetail: boolean;
  uploadBankCancelCheck: boolean;
  uploadDematCmrCopy: boolean;
  showError: boolean;
  showErrorIfsc: boolean;
  errorUploadPanPhoto: boolean;
  errorUploadBankCancelCheckPhoto: boolean;
  errorUploadDemateFile: boolean;
  errorAccNo: boolean;
  errorPanNumber: boolean;
  cinError: boolean;
  llpinError: boolean;
  nameError: boolean;
  selectLabel: boolean;
  redirectAfterKycCompletion: boolean;
  kycUpdateApiCalled: boolean;
  bankDetailUpdateApiCalled: boolean;
  seekarDropValue: any[];
  providerDropValue: any[];
  arrangerDropValue: any[];
  bankListDetailArray: any[];
  isLlp: boolean;
  demateDetailUpdateApiCalled: boolean;
  panCardName: string;
  panCard: string;
  cancelBankCheckName: string;
  cancelBankUrl: string;
  demateCmrUrl: string;
  demateCmrCopyName: string;
  demateNsdlCmrCopyName: string;
  demateNsdlCMRCopy: any;
  activeStep: number;
  dpError: boolean;
  cdError: boolean;
  showSeekarDropDownValue: boolean;
  showArrangerDropDownValue: boolean;
  showProviderDropDownValue: boolean;
  debtSeekerKycShowValue: string;
  debtArrangerKycShowValue: string;
  debtProviderKycShowvalue: string;
  showBankListValue: boolean;
  nsdlKycDpId: string;
  nsdlDpError: boolean;
  demateNsdlCmrUrl: string;
  errorNsdlUploadDemateFile: boolean;
  isNevigate: boolean;
  isBankUpdateNext: boolean;
  isKycUpdateNext: boolean;
  isDematUpdateNext: boolean;
}

interface SS {
  id: any;
}

export default class CfEkycVerification3Controller extends BlockComponent<
  Props,
  S,
  SS
> {
  addAllKycDetailAPICallId = "";
  addAllDematDetailAPICallId = "";
  addAllBankAccountDetail = "";
  kycViewDetailAPICallId = "";
  kycUpdateDetailAPICallId = "";
  addAllBankAccountDetailUpdateApi = "";
  showBankAccountDetailAPICallId = "";
  dematViewDetailAPICallId = "";
  updateAllDematDetailAPICallId = "";
  getSeekarDropDown = "";
  getArrangerDropDown = "";
  getBankListDropDown = "";
  getProviderDropDown = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials),
      getName(MessageEnum.redirectMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      debtSeekerSelectValue: 0,
      debtSeekerInputLabel: configJSON.debtSeekerInputLabel,
      debtArrangerInputLabel: configJSON.debtArrangerInputLabel,
      debtArrangerSelectedValue: "",
      role_id: `${localStorage.getItem("role_id")}`,
      debtProviderInputLabel: configJSON.debtProviderInputLabel,
      debtProviderSelectedValue: "",
      showKycAddBankDetailForm: false,
      showDematDetailForm: false,
      tabIndexValue: configJSON.tabValue,
      pan: "",
      cin: "",
      llpin: "",
      file: '',
      name: "",
      bankName: "",
      accNo: "",
      confirmAccNo: "",
      ifscCode: "",
      demateCMRCopy: "",
      bankCheck: "",
      bankNameValue: "",
      demat_detail_type: configJSON.nsdl,
      clientId: "",
      dpId: "",
      uploadDetail: false,
      uploadBankCancelCheck: false,
      uploadDematCmrCopy: false,
      showError: false,
      showErrorIfsc: false,
      errorUploadBankCancelCheckPhoto: false,
      errorUploadDemateFile: false,
      errorUploadPanPhoto: false,
      errorAccNo: false,
      errorPanNumber: false,
      cinError: false,
      llpinError: false,
      nameError: false,
      selectLabel: true,
      redirectAfterKycCompletion: false,
      kycUpdateApiCalled: false,
      bankDetailUpdateApiCalled: false,
      demateDetailUpdateApiCalled: false,
      seekarDropValue: [],
      arrangerDropValue: [],
      providerDropValue: [],
      bankListDetailArray: [],
      isLlp: false,
      panCardName: '',
      panCard: '',
      cancelBankCheckName: '',
      cancelBankUrl: '',
      demateCmrCopyName: '',
      demateNsdlCmrCopyName: '',
      demateCmrUrl: '',
      activeStep: 0,
      dpError: false,
      cdError: false,
      showSeekarDropDownValue: false,
      showProviderDropDownValue: false,
      showArrangerDropDownValue: false,
      debtSeekerKycShowValue: '',
      debtArrangerKycShowValue: '',
      debtProviderKycShowvalue: '',
      showBankListValue: false,
      nsdlKycDpId: '',
      nsdlFile: '',
      nsdlDpError: false,
      demateNsdlCmrUrl: '',
      demateNsdlCMRCopy: '',
      errorNsdlUploadDemateFile: false,
      isNevigate: false,
      isBankUpdateNext: false,
      isKycUpdateNext: false,
      isDematUpdateNext: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    const urlParamIsDemat = new URLSearchParams(window.location.search).get('demat');
    if(urlParamIsDemat){
      this.setState({
        showDematDetailForm : true,
        showKycAddBankDetailForm : true
      });
    }
    else{
    if (localStorage.getItem("is_kyc_done") && localStorage.getItem("is_kyc_done") == "false") {
      this.setState({
        showDematDetailForm: false,
        showKycAddBankDetailForm: false
      });
    }
    else if (localStorage.getItem("isBankDetailDone") && localStorage.getItem("isBankDetailDone") == "false") {
      this.setState({
        showDematDetailForm: false,
        showKycAddBankDetailForm: true
      });
    }
    else if (localStorage.getItem("isDemateDetailDone") && localStorage.getItem("isDemateDetailDone") == "false") {
      this.setState({
        showDematDetailForm: true,
        showKycAddBankDetailForm: true
      });
    }
  }
    this.getDropDownOfSeekar();
    this.getDropDownOfArranger();
    this.getDropDownOfProvider();
    this.getBankNameList();
    this.viewKyc();
    this.showBankAccountDetail();
    this.showDemateDetail();
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
  }
  /* istanbul ignore next */
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      const customRedirect2 = message.getData(getName(MessageEnum.redirectData));

      if (this.state.isBankUpdateNext && customRedirect2) {
        this.showDematForm();
        this.handleNext();
        this.setState({ isBankUpdateNext: false })
        return
      }
      if (this.state.isKycUpdateNext && customRedirect) {
        this.handleNext();
        this.saveAndContinue();
        this.setState({ isKycUpdateNext :false})
        return
      }
      if (this.state.isDematUpdateNext && customRedirect) {
        this.handleNext();
        this.props.history.push(localStorage.getItem("role_id") == '2' ? "/DealDiscovery" : 'ContentManagement/DealsDashboard');
        this.setState({ isDematUpdateNext :false})
        return
      }
    }
    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    let responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    if (apiRequestCallId === this.addAllKycDetailAPICallId) {
        if (responseJson?.data) {
          this.viewKyc();
          const msg83: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg83.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg83);
          this.setState({ isKycUpdateNext :true})
          if (responseJson?.meta?.message) {
            localStorage.setItem("is_kyc_done", JSON.stringify(true));
          }
        }
        else {
          const msg84: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg84.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.message);
          this.send(msg84);
        }
    }
    if (apiRequestCallId === this.addAllBankAccountDetail) {
      const msg85: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg85.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
      this.send(msg85);
        this.setState({isBankUpdateNext:true})
        if (responseJson?.data) {
          localStorage.setItem("isBankDetailDone", JSON.stringify(true));
          this.showBankAccountDetail();
        }
        else {
          const msg86: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg86.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.message);
          this.send(msg86);
        }
    }
    if (apiRequestCallId === this.addAllDematDetailAPICallId) {
        if (responseJson?.data) {
          this.showDemateDetail();
          localStorage.setItem("isDemateDetailDone", JSON.stringify(true));
          const msg87: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg87.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.meta?.message);
          this.send(msg87);
          this.setState({ isDematUpdateNext :true})
        }
        else {
          const msg88: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg88.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.message);
          this.send(msg88);
        }
        if (responseJson?.meta?.message) {
          localStorage.setItem("is_kyc_pending", JSON.stringify(true));
        }
    }
    if (apiRequestCallId === this.kycViewDetailAPICallId) {
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
          this.setState({
            kycUpdateApiCalled: true
          })
        }
        else if (responseJson?.errors && responseJson?.errors.length > 0) {
          this.setState({
            kycUpdateApiCalled: false
          })
        }
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]) {
          this.setState({
            cin: responseJson?.data[0]?.attributes?.cin,
            pan: responseJson?.data[0]?.attributes?.pan,
            llpin: responseJson?.data[0]?.attributes?.llpin,
            panCardName: responseJson?.data[0]?.attributes?.pan_card_name,
            panCard: responseJson?.data[0]?.attributes?.pan_card,
            debtArrangerSelectedValue: responseJson?.data[0]?.attributes?.debt_arranger,
            debtProviderSelectedValue: responseJson?.data[0]?.attributes?.debt_provider,
            debtSeekerSelectValue: responseJson?.data[0]?.attributes?.debt_seeker,
            debtSeekerKycShowValue: responseJson?.data[0]?.attributes?.debt_seeker_name ,
            debtArrangerKycShowValue: responseJson?.data[0]?.attributes?.debt_arranger_name,
            debtProviderKycShowvalue: responseJson?.data[0]?.attributes?.debt_provider_name
          })
        }
    }
    if (apiRequestCallId === this.kycUpdateDetailAPICallId) {
        if (responseJson?.data) {
          this.viewKyc();
          const msg89: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg89.addData(getName(MessageEnum.CustomAlertBodyData), "KYC details updated successfully");
          this.send(msg89);
          this.setState({isKycUpdateNext:true})
        }
    }
    if (apiRequestCallId === this.addAllBankAccountDetailUpdateApi) {
      if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]) {
        this.setState({
          name: responseJson?.data[0]?.attributes?.name,
          accNo: responseJson?.data[0]?.attributes?.account_number,
          ifscCode: responseJson?.data[0]?.attributes?.ifsc_code,
          bankCheck: responseJson?.data[0]?.attributes?.check,
          bankNameValue: responseJson?.data[0]?.attributes?.bank_name
        });
        const msg90: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg90.addData(getName(MessageEnum.CustomAlertBodyData), "Bank Details updated successfully.");
        this.send(msg90);
        this.showBankAccountDetail();
        this.setState({isBankUpdateNext:true})
      }
    }
    if (apiRequestCallId === this.showBankAccountDetailAPICallId) {
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
          this.setState({
            bankDetailUpdateApiCalled: true
          });
        }
        else if (responseJson?.errors && responseJson?.errors.length > 0) {
          this.setState({
            bankDetailUpdateApiCalled: false
          });
        }
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]) {
          this.setState({
            name: responseJson?.data[0]?.attributes?.name,
            accNo: responseJson?.data[0]?.attributes?.account_number,
            ifscCode: responseJson?.data[0]?.attributes?.ifsc_code,
            bankCheck: responseJson?.data[0]?.attributes?.check,
            bankNameValue:  responseJson?.data[0]?.attributes?.bank_id,
            cancelBankCheckName: responseJson?.data[0]?.attributes?.check_name,
            cancelBankUrl: responseJson?.data[0]?.attributes?.check
          });
        }
        else {
          this.setState({
            bankDetailUpdateApiCalled: false
          });
        }
    }
    if (apiRequestCallId === this.dematViewDetailAPICallId) {
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]?.id) {
          this.setState({
            demateDetailUpdateApiCalled: true
          });
        }
        if (responseJson?.errors && responseJson?.errors.length > 0) {
          this.setState({
            demateDetailUpdateApiCalled: false
          });
        }
        if (responseJson?.data && Array.isArray(responseJson?.data) && responseJson?.data[0]){
             this.setState({
                 nsdlKycDpId: responseJson?.data[0]?.attributes?.nsdl_dp_id?responseJson?.data[0]?.attributes?.nsdl_dp_id:'',
                 demateNsdlCMRCopy: responseJson?.data[0]?.attributes?.nsdl_cmr_copy_name,
                 demateNsdlCmrUrl: responseJson?.data[0]?.attributes?.nsdl_cmr_copy,
                 demateNsdlCmrCopyName: responseJson?.data[0]?.attributes?.nsdl_cmr_copy_name,
                 dpId: responseJson?.data[0]?.attributes?.cdsl_dp_id?responseJson?.data[0]?.attributes?.cdsl_dp_id:'',
                 clientId: responseJson?.data[0]?.attributes?.cdsl_client_id?responseJson?.data[0]?.attributes?.cdsl_client_id : '',
                 demateCmrCopyName: responseJson?.data[0]?.attributes?.cdsl_cmr_copy_name ?responseJson?.data[0]?.attributes?.cdsl_cmr_copy_name:'',
                 demateCmrUrl: responseJson?.data[0]?.attributes?.cdsl_cmr_copy ?responseJson?.data[0]?.attributes?.cdsl_cmr_copy: ''
             });
        if (responseJson?.data[0]?.attributes?.demat_detail_type == "nsdl") {
          this.setState({
            tabIndexValue: 0
          });
          localStorage.setItem("tabIndexValue","0");
        }
        else {
          this.setState({
            tabIndexValue: 1
          });
          localStorage.setItem("tabIndexValue","1");
        }
      }
    }
    if (apiRequestCallId === this.updateAllDematDetailAPICallId) {
        if (responseJson?.data) {
          this.showDemateDetail();
          const msg91: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg91.addData(getName(MessageEnum.CustomAlertBodyData), "Data Updated Successfully.");
          this.send(msg91);
          this.setState({ isDematUpdateNext: true });
        }
    }
    if (apiRequestCallId === this.getSeekarDropDown) {
        const dropDownArray: any[] = responseJson?.data.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          seekarDropValue: dropDownArray
        });
    }
    if (apiRequestCallId === this.getArrangerDropDown) {
        const dropDownArray: any[] = responseJson?.data.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          arrangerDropValue: dropDownArray
        });
    }
    if (apiRequestCallId === this.getProviderDropDown) {
        const dropDownArray: any[] = responseJson?.data.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          providerDropValue: dropDownArray
        });
    }
    if (apiRequestCallId === this.getBankListDropDown) {
        const dropDownArray: any = responseJson?.data.map((item: any, index: number) => {
          return {
            id: item.attributes.id,
            value: item.attributes.name
          }
        });
        this.setState({
          bankListDetailArray: dropDownArray
        });
    }
  }

  selectedBankValue = (item: any) => {
    this.setState({
      bankNameValue: item.id
    });
  };
  typeOfLabel = (event: React.ChangeEvent<{}>, index: number) => {
    this.setState({
      tabIndexValue: index
    });
    if (index == 0) {
      this.setState({
        demat_detail_type: configJSON.nsdl
      })
    }
    else {
      this.setState({
        demat_detail_type: configJSON.cdsl
      })
    }
  };
  closeDropDown = () => {
    if (this.state.showBankListValue) {
      this.setState({
        showBankListValue: false
      });
    }
    if (this.state.showArrangerDropDownValue) {
      this.setState({
        showArrangerDropDownValue: false,
      });
    }
    if (this.state.showProviderDropDownValue) {
      this.setState({
        showProviderDropDownValue: false
      });
    }
    if (this.state.showSeekarDropDownValue) {
      this.setState({
        showSeekarDropDownValue: false
      });
    }
  };
  getLlpInVal = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-7 ]+$/
    const trimmedValue = event.target.value.toUpperCase();
    if (regex.test(event.target.value) && !trimmedValue.includes(' ') || (event.target.value == '')) {
      this.setState({
        llpin: event.target.value,
        llpinError: false
      });
    }
    if (event.target.value.length !== 7) {
      this.setState({
        llpinError: true
      });
    }
  };
  getCinValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^([A-Za-z]{1})([0-9]{5})([A-Z]{2})([0-9]{4})([A-Z]{3})([0-9]{6})+$/
    const trimmedValue = event.target.value.trim();
    if (regex.test(event.target.value) || !trimmedValue.includes(' ') || (event.target.value == '')) {
      this.setState({
        cin: event.target.value.toUpperCase(),
        cinError: false
      });
    }
    if ((event.target.value.length !== 21) || regex.test(event.target.value) === false) {
      this.setState({
        cinError: true
      });
    }
  };
  getPanValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z0-9]+$/;
    const regex2 = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
    if (regex.test(event.target.value) || (event.target.value == '')) {
      this.setState({
        pan: event.target.value.toUpperCase(),
        errorPanNumber: false
      });
    }
    if ((event.target.value.length != 10) || (regex2.test(event.target.value) === false)) {
      this.setState({
        errorPanNumber: true
      });
    }
  };
  handleMinuSign = (e: any) => {
    ["+", "-", "$", "!", "@", "#", "%", "^", "&", "*", "(", ")", "<", ">", "?", ":", ";", "'", ".", ",", "_", "=", "/", "{", "}", "[", "]", "|", "~", "`", "\\", " \' ", " \" ", `"`, `'`].includes(e.key) && e.preventDefault()
  }
  showBankList = () => {
    this.setState({
      showBankListValue: !this.state.showBankListValue
    });
  };
  uploadFile = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'image/svg' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.setState({
        file: event.target.files[0],
        uploadDetail: true,
        errorUploadPanPhoto: false,
        panCardName: event.target.files[0]?.name
      });
    }
    else {
      this.setState({
        errorUploadPanPhoto: true
      })
    }
  };
  uploadBankCheck = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'image/svg' || event.target.files[0].type == 'image/jpeg' || event.target.files[0].type == 'image/jpg' || event.target.files[0].type == 'image/png') {
      this.setState({
        bankCheck: event.target.files[0],
        uploadBankCancelCheck: true,
        cancelBankCheckName :event.target.files[0]?.name ,
        errorUploadBankCancelCheckPhoto: false,
      });
    }
    else {
      this.setState({
        errorUploadBankCancelCheckPhoto: true
      });
    }
  };
  uploadNsdlDematCmrCopy = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'application/pdf') {
      this.setState({
        demateNsdlCMRCopy: event.target.files[0],
        demateNsdlCmrCopyName : event.target.files[0]?.name,
        errorNsdlUploadDemateFile: false,
      });
    }
    else {
      this.setState({
        errorNsdlUploadDemateFile: true
      });
    }
  };

  uploadDematCmrCopy = (event: React.ChangeEvent<any>) => {
    if (event.target.files[0].type == 'application/pdf') {
      this.setState({
        demateCMRCopy: event.target.files[0],
        demateCmrCopyName : event.target.files[0]?.name,
        uploadDematCmrCopy: true,
        errorUploadDemateFile: false
      });
    }
    else {
      this.setState({
        errorUploadDemateFile: true
      });
    }
  };
  getClientId = (e: React.ChangeEvent<any>) => {
    const regex = /^[0-9]+$/;
    if (regex.test(e.target.value) == true || (e.target.value == '')) {
      this.setState({
        clientId: e.target.value?.trim().toString(),
        cdError: false
      })
    }
    if ((e.target.value.length !== 8)) {
      this.setState({
        cdError: true
      })
    }
  };
  getDpId = (e: React.ChangeEvent<any>) => {
    const regex = /^[0-9]+$/;
    if (regex.test(e.target.value) == true || (e.target.value?.trim() == '')) {
      this.setState({
        dpId: e.target.value?.trim().toString(),
        dpError: false
      })
    }
    if ((e.target.value.length !== 8)) {
      this.setState({
        dpError: true
      })
    }
  };

  getNsdlDpId = (e: React.ChangeEvent<any>) => {
    const regex = /^[0-9]+$/;
    if (regex.test(e.target.value) || (e.target.value?.trim() == '')) {
      this.setState({
        nsdlKycDpId: e.target.value?.trim().toString(),
        nsdlDpError: false
      })
    }
    if ((e.target.value.length !== 8)) {
      this.setState({
        nsdlDpError: true
      })
    }
  };

  getConfirmPassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({
      confirmAccNo: e.target.value
    });
  }
  getName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[A-Za-z ]+$/;
    const trimmedValue = e.target.value;
    if (regex.test(trimmedValue) || trimmedValue == '' || !trimmedValue.includes('')) {
      this.setState({
        name: e.target.value.charAt(0).toUpperCase() + e.target.value.slice(1),
        nameError: false
      });
    }
    if ((e.target.value.length < 2) || (e.target.value.length > 25) || (e.target.value[0] == '') || (e.target.value[1] == '')) {
      this.setState({
        nameError: true
      });
    }
  };
  getAccNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9 ]+$/;
    if ((regex.test(e.target.value) || (e.target.value == '')) && (e.target.value.charAt(0) !== ' ')) {
      this.setState({
        accNo: e.target.value,
        errorAccNo: false,
        showError: false
      })
    }
    if ((e.target.value.length < 9) || (e.target.value.length > 18)) {
      this.setState({
        errorAccNo: true
      })
    }
    if ((this.state.confirmAccNo !== e.target.value) || (e.target.value !== '' && this.state.confirmAccNo === '')) {
      this.setState({
        showError: true
      });
    }
  };
  getConfirmAccNo = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex = /^[0-9 ]+$/;
    if ((regex.test(e.target.value) || (e.target.value == '')) && (e.target.value.charAt(0) !== ' ')) {
      this.setState({
        showError: false,
        confirmAccNo: e.target.value
      });
    }
    if ((this.state.accNo !== e.target.value) || (e.target.value.length < 9) || (e.target.value.length > 18)) {
      this.setState({
        showError: true
      });
    }
    if (regex.test(e.target.value) && e.target.value.charAt(0) !== ' ') {
      this.setState({
        confirmAccNo: e.target.value
      })
    }
  };
  getIfscCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    const regex2 = /^[A-Za-z]{4}[0][A-Z0-9]{6}$/;
    const trimmedValue: string = e?.target?.value;

    if (regex2.test(e.target.value) || !trimmedValue.includes(' ') || (e.target.value == '')) {
      this.setState({
        ifscCode: e.target.value.toUpperCase(),
        showErrorIfsc: false
      })
    }
    if ((e.target.value.length != 11) || (regex2.test(e.target.value) === false)) {
      this.setState({
        showErrorIfsc: true
      });
    }
  };
  /* istanbul ignore next */
  validationCheck = () => {
    if (localStorage.getItem("role_id") == "1") {
      //@ts-ignore
      if (this.state.debtSeekerSelectValue == "1" || this.state.debtSeekerSelectValue == "2") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg92: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg92.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter Valid CIN Number.");
          this.send(msg92);
          return;
        }
      }
      //@ts-ignore
      if (this.state.debtSeekerSelectValue == "4") {
        if (this.state.llpinError || this.state.llpin.length === 0) {
          const msg93: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg93.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter Valid LLPIN Number.");
          this.send(msg93);
          return;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg94: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg94.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter Valid PAN Number.");
        this.send(msg94);
        return;
      }
      if (!this.state.panCardName && !this.state.file) {
        const msg95: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg95.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload file.");
        this.send(msg95);
        return;
      }
    }
    if (localStorage.getItem("role_id") == "2") {
      if (this.state.debtProviderSelectedValue == "1" || this.state.debtProviderSelectedValue == "4" || this.state.debtProviderSelectedValue == "5") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg96: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg96.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid CIN Number.");
          this.send(msg96);
          return;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg97: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg97.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid PAN Number.");
        this.send(msg97);
        return;
      }
    }
    if (localStorage.getItem("role_id") === "3") {
      if (this.state.debtArrangerSelectedValue == "2") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg98: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg98.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid CIN number.");
          this.send(msg98);
          return;
        }
      }
      if (this.state.debtArrangerSelectedValue == "4") {
        if (this.state.llpinError || this.state.llpin.length === 0) {
          const msg99: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg99.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid LLPIN number.");
          this.send(msg99);
          return;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg100: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg100.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid PAN number.");
        this.send(msg100);
        return;
      }
      if (!this.state.panCardName && !this.state.file) {
        const msg101: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg101.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload file.");
        this.send(msg101);
        return;
      }
    }
    this.props.navigation.navigate("EmailAccountRegistration");
    this.handleBack();
  };
  getSteps = () => {
    return ['', '', ''];
  };
  demateValidationCheck = () => {
    /* istanbul ignore next */
    if (this.state.dpError || this.state.cdError) {
      const msg102: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg102.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove the validation error.");
      this.send(msg102);
      return ;
    }
    else {
      this.setState({
        showDematDetailForm: false
      })
      this.handleBack();
    }
  };
  /* istanbul ignore next */

  showSeekarDropValue = () => {
    this.setState({
      showSeekarDropDownValue: !this.state.showSeekarDropDownValue
    });
  };

  handleChange = (val: any) => {
    if (this.state.role_id == "1") {
      this.setState({
        debtSeekerSelectValue: val.id,
        debtSeekerKycShowValue: val.value
      });
    }
    else if (this.state.role_id == "3") {
      this.setState({
        debtArrangerSelectedValue: val.id,
        debtArrangerKycShowValue: val.value,
      });
    }
    else {
      this.setState({
        debtProviderSelectedValue: val.id,
        debtProviderKycShowvalue: val.value,
      });
    }
    this.setState({
      showSeekarDropDownValue: false
    });
  };

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  removeUploadFile = () => {
    this.setState({
      file: [],
      panCardName: '',
      panCard: '',
      uploadDetail: false
    });
  };


  removeCancelCheck = () => {
    this.setState({
      cancelBankCheckName: '',
      cancelBankUrl: '',
      bankCheck: '',
      uploadBankCancelCheck: false
    });
  };

  removeDemate = () => {
    this.setState({
      demateCMRCopy: '',
      demateCmrCopyName: '',
      demateCmrUrl: ''
    });
  };

  removeNsdlDemate = () => {
    this.setState({
      demateNsdlCMRCopy: '',
      demateNsdlCmrCopyName: '',
      demateNsdlCmrUrl: ''
    });
  };

  saveAndContinue = () => {
    this.setState({
      showKycAddBankDetailForm: true
    });
  };
  showProviderDropValue = () => {
    this.setState({
      showProviderDropDownValue: !this.state.showProviderDropDownValue
    });
  };
  showArrangerDropValue = () => {
    this.setState({
      showArrangerDropDownValue: !this.state.showArrangerDropDownValue
    });
  };
  handleNext = () => {
    this.setState({
      activeStep: this.state.activeStep + 1
    });
  };

  handleBack = () => {
    this.setState({
      activeStep: this.state.activeStep - 1
    });
  };
  /* istanbul ignore next */
  addKycDetailValidation=()=>{
    let panImage:any="";
    if (this.state.file.length != 0) {
      panImage=this.state.file?.name;
    }
    if (this.state.panCardName?.length!=0) {
       panImage=this.state.panCardName;
    }
    if (localStorage.getItem("role_id") == "1") {
      if (!this.state.debtSeekerSelectValue) {
        const msg103: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg103.addData(getName(MessageEnum.CustomAlertBodyData), "Please select the type of seekar.");
        this.send(msg103);
        return false;
      }
      if (this.state.debtSeekerKycShowValue == "Private Limited Company" || this.state.debtSeekerKycShowValue == "Public Limited Company") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg104: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg104.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid CIN number.");
          this.send(msg104);
          return false;
        }
      }
      if (this.state.debtSeekerKycShowValue == "LLP") {
        if (this.state.llpinError || this.state.llpin.length === 0) {
          const msg105: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg105.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid LLPIN number.");
          this.send(msg105);
          return false;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg106: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg106.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid PAN number.");
        this.send(msg106);
        return false;
      }
      if(panImage?.length==0){
        const msg107: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg107.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg107);
        return false;
      }
    }
    if (localStorage.getItem("role_id") == "2") {
      if (!this.state.debtProviderSelectedValue) {
        const msg108: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg108.addData(getName(MessageEnum.CustomAlertBodyData), "Please select the type of debt provider value.");
        this.send(msg108);
        return false;
      }
      if (this.state.debtProviderKycShowvalue == "Bank" || this.state.debtProviderKycShowvalue == "Mutual Funds" || this.state.debtProviderKycShowvalue == "FI" || this.state.debtProviderKycShowvalue == "NBFC") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg109: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg109.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid CIN number.");
          this.send(msg109);
          return false;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg110: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg110.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid PAN number.");
        this.send(msg110);
        return false;
      }
      if(panImage?.length==0){
        const msg111: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg111.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg111);
        return false;
      }
    }
    if (localStorage.getItem("role_id") == "3") {
      if (!this.state.debtArrangerSelectedValue) {
        const msg112: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg112.addData(getName(MessageEnum.CustomAlertBodyData), "Please select the type of debt arranger value.");
        this.send(msg112);
        return false;
      }
      if (this.state.debtArrangerKycShowValue == "Private Limited Company") {
        if (this.state.cinError || this.state.cin.length === 0) {
          const msg113: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg113.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid CIN number.");
          this.send(msg113);
          return false;
        }
      }
      if (this.state.debtArrangerKycShowValue == "LLP") {
        if (this.state.llpinError || this.state.llpin.length === 0) {
          const msg114: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg114.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid LLPIN number.");
          this.send(msg114);
          return false;
        }
      }
      if (this.state.errorPanNumber || this.state.pan.length === 0) {
        const msg15: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg15.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid PAN number.");
        this.send(msg15);
        return false;
      }
      if(panImage?.length==0){
        const msg116: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg116.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload PAN.");
        this.send(msg116);
        return false;
      }
    }
    return true;
  };
  /* istanbul ignore next */

  addKycDetail = () => {
    if(this.addKycDetailValidation()){
    this.setState({ role_id: `${localStorage.getItem("role_id")}` });
    if (this.state.role_id) {
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.addAllKycDetailAPICallId = requestMessage.messageId;
      let myHeadersAddKyc = new Headers();
      myHeadersAddKyc.append("token", `${localStorage.getItem("token")}`);
      const formdataKyc = new FormData();
      if (localStorage.getItem("role_id") == "1") {
        //@ts-ignore
        localStorage.getItem("role_id") == "1" && formdataKyc.append("data[debt_seeker]", this.state.debtSeekerSelectValue);
        if (this.state.debtSeekerKycShowValue == "Private Limited Company" || this.state.debtSeekerKycShowValue == "Public Limited Company") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", this.state.cin);
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtSeekerKycShowValue == "Partnership" || this.state.debtSeekerKycShowValue == "Proprietorship") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", '');
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtSeekerKycShowValue == "LLP") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", '');
          formdataKyc.append("data[llpin]", this.state.llpin);
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
      }
      else if (localStorage.getItem("role_id") == "2") {
        localStorage.getItem("role_id") == "2" && formdataKyc.append("data[debt_provider]", this.state.debtProviderSelectedValue);
        if (this.state.debtProviderKycShowvalue == "Individual" || this.state.debtProviderKycShowvalue == "HNI" || this.state.debtProviderKycShowvalue == "Family Office") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", '');
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtProviderKycShowvalue == "Bank" || this.state.debtProviderKycShowvalue == "Mutual Funds" || this.state.debtProviderKycShowvalue == "FI" || this.state.debtProviderKycShowvalue == "NBFC") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", this.state.cin);
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
      }
      else {
        localStorage.getItem("role_id") == "3" && formdataKyc.append("data[debt_arranger]", this.state.debtArrangerSelectedValue);
        if (this.state.debtArrangerKycShowValue == "Private Limited Company") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", this.state.cin);
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtArrangerKycShowValue == "LLP") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", '');
          formdataKyc.append("data[llpin]", this.state.llpin);
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
        if (this.state.debtArrangerKycShowValue == "Partnership Firm" || this.state.debtArrangerKycShowValue == "Individual") {
          formdataKyc.append("data[pan]", this.state.pan);
          formdataKyc.append("data[cin]", '');
          formdataKyc.append("data[llpin]", '');
          typeof (this.state.file) != 'string' && formdataKyc.append("data[pan_card]", this.state.file);
        }
      }
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.endPointApiPostUserAddKYCDetail
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        myHeadersAddKyc
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdataKyc
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.apiPostMethod
      );

      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    };
  }
  /* istanbul ignore next */
  addBankValidation=()=>{
    let bankChequePhoto:any="";
    if (this.state.bankCheck) {
      bankChequePhoto=this.state.bankCheck?.name;
    }
    if(this.state.cancelBankCheckName){
      bankChequePhoto=this.state.cancelBankCheckName;
    }
    if(bankChequePhoto?.length==0){
      const msg117: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg117.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload bank cancelled cheque.");
      this.send(msg117);
      return false;
    }
    if (this.state.name?.length == 0 || this.state.name?.length > 25) {
      const msg118: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg118.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid name.");
      this.send(msg118);
      return false;
    }
    if (!this.state.bankNameValue) {
      const msg119: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg119.addData(getName(MessageEnum.CustomAlertBodyData), "Please select bank name.");
      this.send(msg119);
      return false;
    }
    if (this.state.accNo?.length < 8 || this.state.accNo?.length > 18) {
      const msg120: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg120.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid confirm account number.");
      this.send(msg120);
      return false;
    }
    if (!this.state.confirmAccNo) {
      const msg121: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg121.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid account number.");
      this.send(msg121);
      return false;
    }
    if (this.state.confirmAccNo?.length < 8 && this.state.confirmAccNo?.length > 18) {
      const msg122: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg122.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid account number.");
      this.send(msg122);
      return false;
    }
    if (this.state.ifscCode?.length == 0) {
      const msg123: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg123.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid IFSC code.");
      this.send(msg123);
      return false;
    }
    if (this.state.showErrorIfsc || this.state.errorAccNo || this.state.showError || this.state.nameError) {
      const msg124: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg124.addData(getName(MessageEnum.CustomAlertBodyData), "Please remove validation error.");
      this.send(msg124);
      return false;
    }
    return true;
  };
  /* istanbul ignore next */

  addBankDetail = () => {
    if(this.addBankValidation()){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAllBankAccountDetail = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPostUserAddBankDetail
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);

    let formdata = new FormData();
    formdata.append("data[name]", this.state.name);
    formdata.append("data[account_number]", this.state.accNo);
    formdata.append("data[confirm_account_number]", this.state.confirmAccNo);
    formdata.append("data[ifsc_code]", this.state.ifscCode);
    typeof (this.state.bankCheck) != 'string' && formdata.append("data[check]", this.state.bankCheck);
    formdata.append("data[bank_id]", this.state.bankNameValue || '');

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    if (this.state.accNo == this.state.confirmAccNo && ((this.state.name.length > 1 && this.state.name.length <= 25)) && this.state.bankNameValue && (this.state.accNo.length > 8 || this.state.accNo.length <= 18) && (this.state.confirmAccNo.length > 8 || this.state.confirmAccNo.length <= 18) && (this.state.ifscCode.length > 10 || this.state.ifscCode.length <= 30) && (this.state.bankCheck.length !== 0)) {
      this.setState({
        showError: false,
      })
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
      /* istanbul ignore next */
    else {
      const msg125: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg125.addData(getName(MessageEnum.CustomAlertBodyData), "Please fill all the required fields.");
      this.send(msg125);
    }
  }
  }
  /* istanbul ignore next */
  showDematForm = () => {
    this.setState({
      showDematDetailForm: true
    });
  };
  /* istanbul ignore next */
  addDematValidation=()=>{
    let demateCdslCopy:any="";
    let dematNsdlCopy:any="";
    if(this.state.demateCMRCopy.length != 0){
      demateCdslCopy=this.state.demateNsdlCMRCopy?.name;
    }
    if(this.state.demateCmrCopyName){
      demateCdslCopy=this.state.demateCmrCopyName;
    }
    if (this.state.demateNsdlCmrCopyName) {
      dematNsdlCopy = this.state.demateNsdlCmrCopyName
    }
    if(this.state.demateNsdlCMRCopy?.length != 0){
      dematNsdlCopy=this.state.demateNsdlCMRCopy?.name;
    }
    if (this.state.tabIndexValue == 0) {
      if (this.state.nsdlKycDpId.length === 0) {
        const msg126: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg126.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid DP id.");
        this.send(msg126);
        return false;
      }
      if (dematNsdlCopy?.length==0) {
        const msg127: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg127.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload the Demat CMR copy.");
        this.send(msg127);
        return false;
      }
    }
    else {
      if (this.state.dpId?.length === 0) {
        const msg128: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg128.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid DP id.");
        this.send(msg128);
        return false;
      }
      if (this.state.clientId?.length === 0) {
        const msg129: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg129.addData(getName(MessageEnum.CustomAlertBodyData), "Plese enter valid Client id.");
        this.send(msg129);
        return false;
      }
      if (demateCdslCopy?.length==0) {
        const msg130: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg130.addData(getName(MessageEnum.CustomAlertBodyData), "Please upload the Demat CMR copy.");
        this.send(msg130);
        return false;
      }
    }
    return true;
  };
  /* istanbul ignore next */

  addDematDetail = () => {
    //@ts-ignore
    if(this.addDematValidation()){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAllDematDetailAPICallId = requestMessage.messageId;
    let myHeaders = new Headers();
    myHeaders.append("token", `${localStorage.getItem('token')}`);
    let formdata = new FormData();
    if(this.state.tabIndexValue==0){
      formdata.append("data[cdsl_dp_id]", '');
      formdata.append("data[cdsl_client_id]", '');
      formdata.append("data[demat_detail_type]", this.state.demat_detail_type);
      formdata.append("data[nsdl_dp_id]", this.state.nsdlKycDpId !=null? this.state.nsdlKycDpId :'');
      typeof (this.state.demateNsdlCMRCopy) != 'string' && this.state.demateNsdlCMRCopy && formdata.append("data[nsdl_cmr_copy]", this.state.demateNsdlCMRCopy);
    }
    else{
      formdata.append("data[cdsl_dp_id]", this.state.dpId ? this.state.dpId : '');
      formdata.append("data[nsdl_dp_id]", '');
      formdata.append("data[cdsl_client_id]", this.state.clientId ? this.state.clientId : '');
      formdata.append("data[demat_detail_type]", this.state.demat_detail_type);
      typeof (this.state.demateCMRCopy) != 'string' && this.state.demateCMRCopy && formdata.append("data[cdsl_cmr_copy]", this.state.demateCMRCopy);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiPostUserAddDematDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiPostMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  }
  /* istanbul ignore next */
  viewKyc = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.kycViewDetailAPICallId = requestMessage.messageId;
    let myHeaders = new Headers();
    myHeaders.append("token", `${localStorage.getItem('token')}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.kycViewApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
/* istanbul ignore next */
  kycUpdateApi = () => {
     if(this.addKycDetailValidation()){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.kycUpdateDetailAPICallId = requestMessage.messageId;
    let myHeadersUpdate = new Headers();
    const formdataUpdateKyc = new FormData();
    //@ts-ignore
    if (localStorage.getItem("role_id") == "1") {
      //@ts-ignore
      localStorage.getItem("role_id") == "1" && formdataUpdateKyc.append("data[debt_seeker]", this.state.debtSeekerSelectValue);
      if (this.state.debtSeekerKycShowValue == "Private Limited Company" || this.state.debtSeekerKycShowValue == "Public Limited Company") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", this.state.cin);
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
      if (this.state.debtSeekerKycShowValue == "Partnership" || this.state.debtSeekerKycShowValue == "Proprietorship") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", '');
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
      if (this.state.debtSeekerKycShowValue == "LLP") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", '');
        formdataUpdateKyc.append("data[llpin]", this.state.llpin);
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
    }
    else if (localStorage.getItem("role_id") == "2") {
      localStorage.getItem("role_id") == "2" && formdataUpdateKyc.append("data[debt_provider]", this.state.debtProviderSelectedValue);
      if (this.state.debtProviderKycShowvalue == "Individual" || this.state.debtProviderKycShowvalue == "HNI" || this.state.debtProviderKycShowvalue == "Family Office") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", '');
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
      if (this.state.debtProviderKycShowvalue == "Bank" || this.state.debtProviderKycShowvalue == "Mutual Funds" || this.state.debtProviderKycShowvalue == "FI" || this.state.debtProviderKycShowvalue == "NBFC") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", this.state.cin);
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
    }
    else {
      localStorage.getItem("role_id") == "3" && formdataUpdateKyc.append("data[debt_arranger]", this.state.debtArrangerSelectedValue);
      if (this.state.debtArrangerKycShowValue == "Private Limited Company") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", this.state.cin);
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
      if (this.state.debtArrangerKycShowValue == "LLP") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", '');
        formdataUpdateKyc.append("data[llpin]", this.state.llpin);
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
      if (this.state.debtArrangerKycShowValue == "Partnership Firm" || this.state.debtArrangerKycShowValue == "Individual") {
        formdataUpdateKyc.append("data[pan]", this.state.pan);
        formdataUpdateKyc.append("data[cin]", '');
        formdataUpdateKyc.append("data[llpin]", '');
        typeof (this.state.file) != 'string' && formdataUpdateKyc.append("data[pan_card]", this.state.file);
      }
    }
    myHeadersUpdate.append("token", `${localStorage.getItem('token')}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.kycUpdateApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeadersUpdate
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataUpdateKyc
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiUpdateMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  }
  /* istanbul ignore next */
  bankDetailUpdateApi = () => {
    if(this.addBankValidation()){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.addAllBankAccountDetailUpdateApi = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiBankDetailUpdateApi
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);

    let formdata = new FormData();
    formdata.append("data[name]", this.state.name);
    formdata.append("data[account_number]", this.state.accNo);
    formdata.append("data[confirm_account_number]", this.state.confirmAccNo);
    formdata.append("data[ifsc_code]", this.state.ifscCode);
    typeof (this.state.bankCheck) != 'string' && formdata.append("data[check]", this.state.bankCheck);
    formdata.append("data[bank_id]", this.state.bankNameValue || '')
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiUpdateMethod
    );
    if (this.state.accNo == this.state.confirmAccNo && (this.state.name.length > 1 && this.state.name.length <= 25) && this.state.bankNameValue && (this.state.accNo.length > 8 || this.state.accNo.length <= 18) && (this.state.confirmAccNo.length > 8 || this.state.confirmAccNo.length <= 18) && (this.state.ifscCode.length > 10 || this.state.ifscCode.length <= 30)) {
      this.setState({
        showError: false,
      })
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  }
  /* istanbul ignore next */
  showBankAccountDetail = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.showBankAccountDetailAPICallId = requestMessage.messageId;
    let myHeaders = new Headers();
    myHeaders.append("token", `${localStorage.getItem('token')}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetUserBankAccountDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  /* istanbul ignore next */
  showDemateDetail = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.dematViewDetailAPICallId = requestMessage.messageId;
    let myHeaders = new Headers();
    myHeaders.append("token", `${localStorage.getItem('token')}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.dematViewApiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */
  updateDematDetailData = () => {
    //@ts-ignore
    if(this.addDematValidation()){
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateAllDematDetailAPICallId = requestMessage.messageId;
    let myHeadersKyc = new Headers();
    myHeadersKyc.append("token", `${localStorage.getItem('token')}`);

    let formdataKyc = new FormData();
    if(this.state.tabIndexValue==0){
      formdataKyc.append("data[nsdl_dp_id]", this.state.nsdlKycDpId!=null ? this.state.nsdlKycDpId : '');
      typeof (this.state.demateNsdlCMRCopy) != 'string' && this.state.demateNsdlCMRCopy && formdataKyc.append("data[nsdl_cmr_copy]", this.state.demateNsdlCMRCopy);
      formdataKyc.append("data[demat_detail_type]", this.state.demat_detail_type);
    }
    else{
    formdataKyc.append("data[cdsl_dp_id]", this.state.dpId ? this.state.dpId : '');
    formdataKyc.append("data[cdsl_client_id]", this.state.clientId ? this.state.clientId : '');
    formdataKyc.append("data[demat_detail_type]", this.state.demat_detail_type);
    typeof (this.state.demateCMRCopy) != 'string' && this.state.demateCMRCopy && formdataKyc.append("data[cdsl_cmr_copy]", this.state.demateCMRCopy);
    }
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiUpdateUserAddDematDetail
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeadersKyc
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdataKyc
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiUpdateMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    };
  }
  /* istanbul ignore next */
  getDropDownOfSeekar = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getSeekarDropDown = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetSeekarDropDownDetail
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */
  getDropDownOfArranger = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getArrangerDropDown = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetarrangerDropDownDetail
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */
  getDropDownOfProvider = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProviderDropDown = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetProviderDropDownDetail
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
  /* istanbul ignore next */
  getBankNameList = () => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBankListDropDown = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.endPointApiGetBankListDropDownDetail
    );
    let header = new Headers();
    header.append("token", `${localStorage.getItem("token")}`);
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      header
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }
}
 // Customizable Area End
