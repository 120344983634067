import React from "react";
import "./styles.css";
import {
  FormControl,
  InputLabel,
  TextField,
  makeStyles,
} from "@material-ui/core";

const useStyles = makeStyles({
  label: {
    paddingTop: "1%",
    paddingBottom: "1%",
    // fontFamily: "Lato",
    fontStyle: "normal",
    fontWeight: 400,
    color: "#000",
    paddingLeft: "16px",
  },
  formControl: {},
  textField: {
    height: "51px",
    fontFamily: "Lato",
    fontWeight: 400,
    [`& fieldset`]: {
      borderRadius: 25,
      height: "51px",
      alignItems: "center",
      display: "flex",
    },
  },
});

type InputProps = {
  name: string;
  label: string;
  placeholder: string;
  onChange: any;
  value: string;
  isMandatory: boolean;
};
function CustomInput(props: InputProps) {
  const { placeholder, label, value, onChange, name, isMandatory } = props;
  const classes = useStyles();

  return (
    <div className="customInput_container" >
      <InputLabel className="customInput_label">{label}{isMandatory && '  *'} </InputLabel>

      <TextField
        type={name === "phoneNumber" ? "number" : "text"}
        className={classes.textField}
        fullWidth
        size="small"
        variant="outlined"
        placeholder={placeholder}
        name={name}
        value={value}
        onChange={onChange}
        inputProps={{ style: { WebkitBoxShadow: "0 0 0 1000px white inset" } }}
      />
    </div>
  );
}

export default CustomInput;
