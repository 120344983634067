import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
// Customizable Area End

const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  password: string;
  countryCodeSelected: string;
  mobileNo: string;
  enablePasswordField: boolean;
  checkedRememberMe: boolean;

  placeHolderCountryCode: string;
  placeHolderMobile: string;
  placeHolderPassword: string;
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: string;
  btnTxtLogin: string;
  labelRememberMe: string;
  btnTxtSocialLogin: string;
  labelOr: string;
  labelForgotPassword: string;
  btnTxtEmailLogin: string;
  tabIndexValue: number;
  mobileNumber: string;
  otp1: string;
  otp2: string;
  otp3: string;
  otp4: string;
  isErrorPhone: boolean;
  sendOtp: boolean;
  snackbarOpen: boolean;
  snackbarMessage:any;
  loadingSignin:boolean;
  snackbarOpenError: boolean;
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class MobileAccountLoginController extends BlockComponent<
  Props,
  S,
  SS
> {

  // Customizable Area Start
  apiPhoneLoginCallId: any;
  apiResendOtpCallId: any;
  apiSendOtpCallId: any;
  apiVerifyOtpCallId: any;
  labelTitle: string;
  private readonly errorTitle = "Error";
  // Customizable Area End    

  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.CountryCodeMessage),
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.ReciveUserCredentials)
    ];

    this.state = {
      countryCodeSelected: "",
      mobileNo: "",
      password: "",
      placeHolderCountryCode: "Select Country",
      enablePasswordField: true,
      checkedRememberMe: false,

      placeHolderMobile: configJSON.placeHolderMobile,
      placeHolderPassword: configJSON.placeHolderPassword,
      imgPasswordVisible: imgPasswordVisible,
      imgPasswordInVisible: imgPasswordInVisible,
      labelHeader: configJSON.labelHeader,
      btnTxtLogin: configJSON.btnTxtLogin,
      labelRememberMe: configJSON.labelRememberMe,
      btnTxtSocialLogin: configJSON.btnTxtSocialLogin,
      labelOr: configJSON.labelOr,
      labelForgotPassword: configJSON.labelForgotPassword,
      btnTxtEmailLogin: configJSON.btnTxtEmailLogin,
      tabIndexValue: configJSON.tabValue,
      mobileNumber: '',
      otp1: '',
      otp2: '',
      otp3: '',
      otp4: '',
      isErrorPhone: false,
      sendOtp: false,
      snackbarOpen: false,
  snackbarMessage:{},
  loadingSignin:false,
  snackbarOpenError: false,
    };

    this.labelTitle = configJSON.labelTitle;
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.send(new Message(getName(MessageEnum.RequestUserCredentials)));
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.ReciveUserCredentials) === message.id) {
      const countryCode = message.getData(
        getName(MessageEnum.LoginCountryCode)
      );

      const userName = message.getData(getName(MessageEnum.LoginUserName));

      const password = message.getData(getName(MessageEnum.LoginPassword));

      if (countryCode && userName && password) {
        this.setState({
          mobileNo: userName,
          placeHolderCountryCode: "+" + countryCode,
          countryCodeSelected: countryCode,
          password: password,
          checkedRememberMe: true
        });
      }
    } else if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode
        });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiPhoneLoginCallId !== null &&
      this.apiPhoneLoginCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiPhoneLoginCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson.meta && responseJson.meta.token) {
        runEngine.unSubscribeFromMessages(this, this.subScribedMessages);
        this.saveLoggedInUserData(responseJson);
        this.sendLoginSuccessMessage();
        this.setState({loadingSignin:false})

        localStorage.setItem("token", responseJson.meta.token);
        localStorage.setItem("role_id", responseJson.meta.role_id);
        localStorage.setItem("successMsg", responseJson?.meta?.message);
        localStorage.setItem("user_roles", JSON.stringify(responseJson?.meta?.user_role));
        localStorage.setItem("security_id", responseJson?.meta?.structure);
        localStorage.setItem("deal_id", responseJson?.meta?.deal_id);
        localStorage.setItem("is_kyc_done", JSON.stringify(responseJson?.meta?.kyc));
        localStorage.setItem("isBankDetailDone", JSON.stringify(responseJson?.meta?.bank_detail));
        localStorage.setItem("isDemateDetailDone", JSON.stringify(responseJson?.meta?.demat_detail));
        localStorage.setItem('email',responseJson.meta.email)
        
        if(responseJson.meta.role_id === 1 || responseJson.meta.role_id === 3){
          this.props.navigation.navigate("ContentManagement",{param: "DealsDashboard"});
        } else {
            this.props.navigation.navigate("DealDiscovery");
        }
      }

      if (responseJson && responseJson?.errors) {
        this.setState({loadingSignin:false})
        this.setState({snackbarOpenError:true,snackbarMessage:`${Object.values(responseJson?.errors[0])[0]}`})
        // alert(`${Object.values(responseJson?.errors[0])[0]}`)
      }

    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiSendOtpCallId !== null &&
      this.apiSendOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiSendOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (responseJson && responseJson?.meta && responseJson.meta?.token) {
        if (responseJson?.meta.message) {
          this.setState({loadingSignin:false})
          this.setState({snackbarOpen:true,snackbarMessage:`${Object.values(responseJson?.meta?.message[0])[0]}`})
          // alert(`${Object.values(responseJson?.meta?.message[0])[0]}`)
          if (responseJson?.meta?.token) {
            localStorage.setItem("OTP_TOKEN", responseJson?.meta?.token);
            this.setState({ sendOtp: true })
          } else {
            this.setState({ sendOtp: false })
          }
        }
      }

      if (responseJson && responseJson?.errors) {
        this.setState({loadingSignin:false})
        this.setState({snackbarOpenError:true,snackbarMessage:`${Object.values(responseJson?.errors[0])[0]}`})
        // alert(`${Object.values(responseJson?.errors[0])[0]}`)
        console.log("sjdgbjhdbfjdf",`${Object.values(responseJson?.errors[0])[0]}`)
      }

    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.apiResendOtpCallId !== null &&
      this.apiResendOtpCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      this.apiResendOtpCallId = null;

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      if (responseJson && responseJson?.meta && responseJson.meta?.token) {
        if (responseJson?.meta.message) {
          this.setState({loadingSignin:false})
          this.setState({snackbarOpen:true,snackbarMessage:`${Object.values(responseJson?.meta?.message[0])[0]}`})
          // alert(`${Object.values(responseJson?.meta?.message[0])[0]}`)
          if (responseJson?.meta?.token) {
            localStorage.setItem("OTP_TOKEN", responseJson?.meta?.token);
          }
        }
      }

      if (responseJson && responseJson?.errors) {
        this.setState({loadingSignin:false})
        this.setState({snackbarOpenError:true,snackbarMessage:`${Object.values(responseJson?.errors[0])[0]}`})
        // alert(`${Object.values(responseJson?.errors[0])[0]}`)
      }

    }
    // Customizable Area End
  }

  // Customizable Area Start
  sendLoginFailMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginFaliureMessage));
    this.send(msg);
  }
  sendLoginSuccessMessage() {
    const msg: Message = new Message(getName(MessageEnum.LoginSuccessMessage));

    msg.addData(getName(MessageEnum.LoginUserName), this.state.mobileNo);

    msg.addData(
      getName(MessageEnum.LoginCountryCode),
      this.state.countryCodeSelected
    );

    msg.addData(getName(MessageEnum.LoginPassword), this.state.password);
    msg.addData(
      getName(MessageEnum.LoginIsRememberMe),
      this.state.checkedRememberMe
    );

    this.send(msg);
  }

  saveLoggedInUserData(responseJson: any) {
    if (responseJson && responseJson.meta && responseJson.meta.token) {
      const msg: Message = new Message(getName(MessageEnum.SessionSaveMessage));

      msg.addData(
        getName(MessageEnum.SessionResponseData),
        JSON.stringify(responseJson)
      );
      msg.addData(
        getName(MessageEnum.SessionResponseToken),
        responseJson.meta.token
      );

      this.send(msg);
    }
  }

  openInfoPage() {
    const msg: Message = new Message(getName(MessageEnum.AccoutLoginSuccess));

    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);

    this.send(msg);
  }

  goToForgotPassword() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationForgotPasswordMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    msg.addData(getName(MessageEnum.NavigationForgotPasswordPageInfo), "sms");
    this.send(msg);
  }

  goToSocialLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationSocialLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToEmailLogin() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationEmailLogInMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  doMobileLogIn = () => {
    this.setState({loadingSignin:true})
    const header = {
      "Content-Type": configJSON.loginApiContentType
    };

    const { otp1, otp2, otp3, otp4 } = this.state;

    const httpBody = {
      token: localStorage.getItem('OTP_TOKEN'),
      pin: `${otp1}${otp2}${otp3}${otp4}`
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.apiPhoneLoginCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.loginAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

    return true;
  }

  sendOtp = () => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", configJSON.loginApiContentType);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      full_phone_number: `+91${this.state.mobileNo}`
    };
    this.apiSendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  resendOtp = () => {
    (document.getElementById('text1') as HTMLInputElement).value = '';
    (document.getElementById('text2') as HTMLInputElement).value = '';
    (document.getElementById('text3') as HTMLInputElement).value = '';
    (document.getElementById('text4') as HTMLInputElement).value = '';

    var myHeaders = new Headers();
    myHeaders.append("Content-Type", configJSON.loginApiContentType);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const httpBody = {
      full_phone_number: `+91${this.state.mobileNo}`
    };
    this.apiResendOtpCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resendApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      myHeaders
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.loginAPiMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
  
    this.setState({ snackbarOpen: false })
  };
  handleCloseError= (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
  
    this.setState({ snackbarOpenError: false })
  };
  // Customizable Area End
}
