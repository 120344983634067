// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  InputLabel,
  Typography,
  Grid,
  Toolbar,
} from "@material-ui/core";
import CheckIcon from '@material-ui/icons/Check';
import CloseIcon from '@material-ui/icons/Close';
import DecisionController, { Props } from './DecisionController.web';
import AppHeader from "../../catalogue/src/AppHeader.web";
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import "./Decision.web.css";
export default class Decision extends DecisionController {
  constructor(props: Props) {
    super(props);
  }

  getSteps = () => {
    return ['Select Deal', 'Review Deal', 'Decision'];
  }

  render() {
    const steps = this.getSteps();
    
    return (
      <Container >
      <Grid container  >
        <Grid item xs={12} >
          <AppHeader navigation={this.props.navigation} id="" history="" match="" location="" />
        </Grid>
        <Toolbar/>
        <Grid item xs={12}>
          <Box className="decision_box" >
          <Stepper style={{ display: "flex", flex: .8 }} activeStep={this.state.activeStep} alternativeLabel>
                {steps.map((label) => (
                  <Step style={{ color: "black" }} key={label}>
                    <StepLabel>{label}</StepLabel>
                  </Step>
                ))}
              </Stepper>
          </Box>
        
        </Grid>
        <Grid item xs={1} sm={1} md={1} lg={2} xl={2}><Typography></Typography></Grid>
        <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
          <Box className="decision_box">
            <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <InputLabel data-testid="decision-label" className="decision_inputLabel" style={{marginTop:'1%'}}>Decision</InputLabel>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <Box className="decision_button_box">
                <Button data-testid="approve-button" variant="contained"   onClick={()=>this.setState({
                  isApproved: true,
                  isAnySelected: true
                })} className={`approve_button ${this.state.isAnySelected && this.state.isApproved ? "bg_grey" : ""}`} endIcon={<CheckIcon className="check_icon"/>}>Approve</Button>
                <Button data-testid="reject-button" onClick={()=>this.setState({
                  isApproved: false,
                  isAnySelected: true
                })} variant="contained" className={`reject_button ${this.state.isAnySelected && !this.state.isApproved ? "bg_grey" : ""}`} endIcon={<CloseIcon className="cross_icon"/>}>Reject</Button>
              </Box>
            </Grid>
          </Box>
          <Box className="decision_box_one">
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
              <InputLabel className="decision_inputLabel">Remarks</InputLabel>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={8} xl={8}>
              <input value={this.state.remarkText} onChange={this.handleRemarkChange} data-testid="remark-input" className="decision_input" placeholder="Text"/>
          </Grid>
          </Box>
        </Grid>
       
      
        <Grid item xs={12} sm={12} md={12} lg={10} xl={12} >
        <Box  className="submit_button_container" >
        <Button
                data-testid="submit-button"
                variant="contained"
                className="otp_submit_button"
                onClick={this.onSubmit}
                disabled={!this.state.isIssuer || this.state.isLoading}
            >
              Submit
          </Button>
        </Box>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={4} xl={2} />
       
       
      </Grid>
      </Container>);
  }
}

// Customizable Area End
