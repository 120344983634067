// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
} from "@material-ui/core";
import CircularProgress from '@material-ui/core/CircularProgress';
import { Link, withRouter } from "react-router-dom";
import StarIcon from "@material-ui/icons/Star";
import { edit } from "./assets";
import { deleteIcon } from "./assets";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import ManageDealController,{Props} from "./ManageDealController";
import moment from "moment";
import { showTextWithValidation } from "../../../components/src/common";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';

class ManageDeal extends ManageDealController {
  constructor(props: Props) {
    super(props);
  }
  render() {
    return (
      <>
        <Container>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <Box className="manage_deal_header_container" p={3}>
                <Typography className="manage_deal_header">
                  Deals Added
                </Typography>
                <Link
                  data-testid="add-deal-button"
                  onClick={()=> this.handleAddOrEditDealButtonClick()}
                  to="/Catalogue/CompanyOverview"
                  style={{
                    textDecoration: "none",
                    display: "flex",
                    justifyContent: "flex-end",
                  }}
                >
                  <Button
                    className="next_button"
                    style={{
                      fontSize: "18px !important",
                      fontWeight: 400,
                      display: "flex-end",
                    }}
                  >
                    Add Deal
                  </Button>
                </Link>
              </Box>

            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ padding: "16px" }} >
            {this.state.dealsList &&
              !this.state.isLoading &&
              this.state.dealsList.length > 0 ?
              this.state.dealsList.map((deal, id) => (
                <Grid key={`manageDeal${deal.id}`} item xs={12} sm={12} md={12} lg={6} xl={6}>
                  <Link
                    to={`/SeekerArrangerDealDetails/${deal?.id}`}
                    className="textDecoration"
                  >
                    <div className="cardWrapperDealDashboard_manageDeal">
                      <div style={{ display: "flex" }}>
                        <div style={{ height: "170px" }}>
                          <img
                            src={deal.upload_logo_image?.url || ''}
                            style={{ borderRadius: "10px" }}
                            alt="discovery"
                            width="100"
                            height="100"
                          />
                          <span className="rating_chip">
                            <StarIcon style={{ fontSize: "1rem" }} />
                            <p
                              className="rating_label"
                              style={{ marginLeft: "3px" }}
                            >
                              {showTextWithValidation(deal.proposed_instruments?.rating_details || 'Unrated')}
                            </p>{" "}
                          </span>
                          <span className="rating_chip">
                            {" "}
                            <p className="rating_label">{showTextWithValidation(deal.proposed_instruments?.sector)}</p>{" "}
                          </span>
                        </div>
                        <div
                          style={{
                            margin: "0 14px 0 24px",
                            height: "194",
                            width: "94%",
                          }}
                        >
                          <p className="deal_companyName" style={{ color: 'black' }}>{showTextWithValidation(deal.debt_seeker_company_name)}</p>
                          <div style={{ width: "100%", overflowY: 'auto' }} className="cardDescriptionWrapper">
                            <div className="cardDescription" style={{ color: 'black'}}>
                              {showTextWithValidation(deal.company_background)}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div
                        style={{ margin: "0 22 0 0" }}
                        className="normal_font"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <p>Instrument</p>
                            <p>Total Amount (INR Cr)</p>
                            <p>Minimum Investment (INR Cr)</p>
                            <p>Expected Returns (Gross)</p>
                            <p>Maturity Date</p>
                            <p>Repayment Frequency</p>
                          </Grid>
                          <Grid item xs={6}>
                            <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.instrument)}</p>
                            <p className="BlueText right_text_aligh"> {showTextWithValidation(deal.proposed_instruments?.total_deal_size) !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.total_deal_size)} Cr` : "-"} </p>
                            <p className="BlueText right_text_aligh"> {deal?.proposed_instruments && deal.proposed_instruments?.minimum_investment !=='' && showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr") !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr")}` : "-"}</p>
                            <p className="BlueText right_text_aligh">{ deal?.proposed_instruments && deal.proposed_instruments?.expected_returns_gross !=="" && deal.proposed_instruments?.expected_returns_gross !=="% IRR" ? showTextWithValidation(deal.proposed_instruments?.expected_returns_gross): "-"}</p>
                            <p className="BlueText right_text_aligh">
                              {deal.proposed_instruments?.repayment_maturity_date ? moment(deal.proposed_instruments?.repayment_maturity_date).format("MMMM DD, YYYY") : "-"}
                            </p>
                            <p className="BlueText right_text_aligh">{showTextWithValidation(deal.proposed_instruments?.repayment_frequency)}</p>
                          </Grid>
                        </Grid>
                      </div>
                      <Divider
                        light
                        style={{ marginRight: "-3%", marginLeft: "-3%" }}
                      />
                      <div
                        style={{ margin: "0 22 0 0" }}
                        className="normal_font"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={6}>
                            <Typography className="name_title_deal">
                              Submitted by
                            </Typography>
                            <Typography className="name_title_deal">
                              Status
                            </Typography>
                            <Typography className="name_title_deal">
                              Time Stamp
                            </Typography>
                          </Grid>
                          <Grid item xs={6}>
                            <Typography className="name_typography_deal right_text_aligh">
                              {showTextWithValidation(deal.submitted_by)}
                            </Typography>
                            <Typography className="name_typography_deal right_text_aligh">
                              {showTextWithValidation(deal.review_status)}
                            </Typography>
                            <Typography className="name_typography_deal right_text_aligh">
                              {showTextWithValidation(deal.time_stamp)}
                            </Typography>
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Link>
                  <Grid item xs={12} sm={12} md={6} lg={12} xl={12}>
                    <Box className="edit_button_parent">
                      <Link
                        data-testid="edit-deal-button"
                        onClick={() => this.handleAddOrEditDealButtonClick(String(deal.id))}
                        to="/Catalogue/CompanyOverview?edit=true"
                        style={{ textDecoration: "none", width: "100%" }}
                      >
                        <Box className="edit_button_child">
                          <Button
                            style={{ marginBottom: "4px", textTransform: "none", }}
                            fullWidth
                            endIcon={<img className="responsive_image" src={edit} />}
                          >
                            Edit
                          </Button>

                        </Box>
                      </Link>
                      {this.state.isIssuer && deal.should_delete === "true" && <Box
                        onClick={() => this.handleDeleteClick(deal.id)}
                        data-testid="delete-deal-button"
                      >
                        <Button fullWidth endIcon={<img className="responsive_image" src={deleteIcon} />} style={{ textTransform: "none", color: "red" }}>
                          Delete
                        </Button>

                      </Box>}
                    </Box>
                  </Grid>
                </Grid>
              )) : this.state.isLoading && <Box p={5} style={{ display: "flex", justifyContent: "center", width: "95%" }} >
                <CircularProgress color="inherit" />
              </Box>}
            {this.state.dealsList && !this.state.isLoading && this.state.dealsList.length === 0 &&
              <p>No Deals Available</p>}
          </Grid>

          {
            this.state.isDeletePopup && (
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Dialog
                  open={true}
                  className="otp_popup"
                >
                  <DialogTitle className='ongoing_deal_popup_title'>
                    <ArrowBackIosIcon onClick={this.handleDeleteCancelClick} className="back-icon" />
                    <Typography className="ongoin-deal-card-heading">
                      Are you sure you want to delete?
                    </Typography>
                  </DialogTitle>
                  <DialogContent style={{ padding: '0px 30px 50px 30px' }}>
                    <Grid container justifyContent="center" spacing={3}>
                      <Button
                        variant="contained"
                        className="otp_submit_button"
                        onClick={this.deleteDeal}
                      >
                        Confirm
                      </Button>
                      <Button
                        variant="outlined"
                        className="confirm_cancle_button"
                        data-testid="cancel-delete-button"
                        onClick={this.handleDeleteCancelClick}
                      >
                        Cancel
                      </Button>
                    </Grid>
                  </DialogContent>
                </Dialog>
              </Grid>
            )
          }
        </Container>
      </>
    );
  }
}

export default withRouter(ManageDeal as any) as any;
// Customizable Area End
