// Customizable Area Start
import React from "react";

import {
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  Grid,
  AppBar,
  Tabs,
  Tab
} from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import Divider from '@material-ui/core/Divider';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked';
import Stepper from '@material-ui/core/Stepper';
import StepLabel from '@material-ui/core/StepLabel';
import Step from '@material-ui/core/Step';
import { withRouter } from "react-router";
import "./kycVerification.css";
import { logo } from './assets';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
const configJSON = require("./config");
import { Link } from "react-router-dom";


import CfEkycVerification3Controller, {
  Props,
} from "./CfEkycVerification3Controller";
import PanNumber from "./PanNumber.web";

class CfEkycVerification3 extends CfEkycVerification3Controller {
  constructor(props: Props) {
    super(props);
  }

  removeDemateOnClick = (e: any) => {
    e.preventDefault();
    this.removeDemate()
  }
  saveAsDraf = () => {
    this.setState({ showKycAddBankDetailForm: true, showDematDetailForm: false })
    this.handleBack();
  }
  QontoStepIcon = (props: any) => {
    const { active, completed } = props;

    return (
      <div
      >
        {active ? <div style={{ width: '20px', height: '20px', marginTop: 2, borderRadius: '50%', backgroundColor: 'darkblue' }} /> :
          !completed && !active ? <RadioButtonCheckedIcon style={{ color: 'lightgrey' }} />
            :
            <div>
              <CheckCircleIcon style={{ color: 'darkblue' }} />
            </div>
        }
      </div>
    );
  }

  getPanCardView = (id: string) => {
    return <PanNumber id={id} pan={this.state.pan} getPanValue={this.getPanValue} handleMinuSign={this.handleMinuSign} placeholder={"ABCTY1234D"} errorPanNumber={this.state.errorPanNumber} panCardName={this.state.panCardName} panCard={this.state.panCard} removeUploadFile={this.removeUploadFile} uploadFile={this.uploadFile} uploadDetail={this.state.uploadDetail} file={this.state.file} errorUploadPanPhoto={this.state.errorUploadPanPhoto}/>
  }

  render() {
    const step = this.getSteps();
    let bankDropDownSelected: string = '';
    if (this.state.bankNameValue) {
      let selectIndex: number = this.state.bankListDetailArray.findIndex((obj: any) => obj.id == this.state.bankNameValue);
      if (selectIndex !== -1) {
        bankDropDownSelected = this.state.bankListDetailArray[selectIndex].value;
      }
    }
    const showLabel = (this.state.showKycAddBankDetailForm ? configJSON.kycAddBankDetailLabel :
      configJSON.kycVerificationLabel);
    return (
      <Grid container className="kyc_grid_parent" onClick={this.closeDropDown}>
        <Grid item xs={12} md={4} lg={4} xl={4} sm={12} className="kyc_background">
          <InputLabel id="kyc_verification_input_label">
            {
              this.state.showDematDetailForm ? configJSON.kycAddDematDetail
                : showLabel
            }
          </InputLabel>
          <img
            src={logo}
            alt="logo"
            className="img_kyc"
          />
        </Grid>
        <Grid item xs={12} md={8} lg={8} xl={8} sm={12} className="inner_grid_kyc">
          {
            this.state.showKycAddBankDetailForm ?
              <>
                <Box id="demat_detail_parent_box">
                  {
                    this.state.showDematDetailForm ?
                      <>
                        <Box className="demat_detail_inner_1_new">
                          <Box className="parent_box">
                            <AppBar position="static" id="MuiAppBar-colorPrimary">
                              <Tabs indicatorColor="primary" data-test-id="typeOflabel" value={this.state.tabIndexValue} onChange={this.typeOfLabel}>
                                <Tab label="NSDL" id="demate_tab" />
                                <Tab label="CDSL" id="demate_tab" />
                              </Tabs>
                            </AppBar>
                            {
                              this.state.tabIndexValue == 1 ?
                                <>
                                  <Box className="demat_detail_pan_box">
                                    <InputLabel id="label1">DP ID</InputLabel>
                                    <input className="kyc_test" value={this.state.dpId} type="text"
                                      maxLength={8}
                                      placeholder="DP ID"
                                      data-test-id="dpId"
                                      onChange={(e: React.ChangeEvent<any>) => { this.getDpId(e) }}
                                    />
                                    {this.state.dpError ? <InputLabel id="error_label">Please Enter Valid DP ID</InputLabel> : ''}
                                  </Box>
                                  <Box className="demat_detail_pan_box">
                                    <InputLabel id="label1">Client ID</InputLabel>
                                    <input value={this.state.clientId} className="kyc_test" type="text" placeholder="Client ID"
                                      maxLength={8}
                                      data-test-id="clientId"
                                      onChange={(e) => { this.getClientId(e) }}
                                    />
                                    {this.state.cdError ? <InputLabel id="error_label">Please Enter Valid Client ID</InputLabel> : ''}
                                  </Box>
                                  {
                                    this.state.demateCmrCopyName ?
                                      <Box className="demat_detail_upload_box1" style={{ flexDirection: 'row', justifyContent: 'center', color: 'black', alignItems: 'center' }}>
                                        <Typography id="upload_label_kyc" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}><a href={this.state.demateCmrUrl}>{this.state.demateCmrCopyName}</a></Typography>
                                        <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '2%' }} onClick={() => { this.removeDemate() }}>X</Typography>
                                      </Box>
                                      :
                                      <Box className="demat_detail_upload_box1">
                                        <Button
                                          component="label"
                                          fullWidth
                                          style={{ textTransform: 'none' }}
                                        >
                                          <Input
                                            type="file"
                                            hidden
                                            id="demate_upload_label_id"
                                            disableUnderline={true}
                                            onChange={this.uploadDematCmrCopy}
                                            data-test-id="uploadDematCmrCopy"

                                            inputProps={{
                                              accept: "application/pdf"
                                            }}
                                            style={{ visibility: 'hidden', display: 'none', height: '25vh' }}
                                          />
                                          <Box className="cloud_upload_icon_box">
                                            {
                                              !this.state.demateCMRCopy &&
                                              <InputLabel className="cloud_upload_icon_box" htmlFor="demate_upload_label_id">
                                                <CloudUploadIcon style={{ color: 'black' }} />
                                              </InputLabel>
                                            }
                                            {
                                              this.state.demateCMRCopy ?
                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between', columnGap: '4%' }}>
                                                  <Typography id="demat_upload_label" style={{ textOverflow: 'ellipsis', paddingLeft : '1%' ,overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.state.demateCMRCopy.name}</Typography>
                                                  <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={(e) => { this.removeDemate(); e.preventDefault() }}>X</Typography>
                                                </Box>
                                                :
                                                <InputLabel id="label" style={{ color: 'grey' }} >{configJSON.dematUploadLabel}</InputLabel>
                                            }
                                          </Box>
                                        </Button>
                                        {this.state.errorUploadDemateFile && <InputLabel id="error_label">Upload PDF File Only</InputLabel>}
                                      </Box>
                                  }
                                </>
                                :
                                <>
                                  <Box className="demat_detail_pan_box">
                                    <InputLabel id="label1">DP ID</InputLabel>
                                    <input className="kyc_test" value={this.state.nsdlKycDpId} type="text"
                                      maxLength={8}
                                      placeholder="DP ID"
                                      data-test-id="dpId2"
                                      onChange={(e: React.ChangeEvent<any>) => { this.getNsdlDpId(e) }}
                                    />
                                    {this.state.nsdlDpError ? <InputLabel id="error_label">Please Enter Valid DP ID</InputLabel> : ''}
                                  </Box>
                                  {
                                    this.state.demateNsdlCmrCopyName ?
                                      <Box className="demat_detail_upload_box1" style={{ flexDirection: 'row', justifyContent: 'space-evenly', color: 'black' }}>
                                        <Typography id="upload_label_kyc" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}><a href={this.state.demateNsdlCmrUrl}>{this.state.demateNsdlCmrCopyName}</a></Typography>
                                        <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '2%' }} onClick={() => { this.removeNsdlDemate() }}>X</Typography>
                                      </Box>
                                      :
                                      <Box className="demat_detail_upload_box1">
                                        <Button
                                          component="label"
                                          fullWidth
                                          style={{ textTransform: 'none' }}
                                        >
                                          <Input
                                            type="file"
                                            hidden
                                            id="demate_upload_label_id"
                                            disableUnderline={true}
                                            data-test-id="uploadNsdlDematCmrCopy"
                                            onChange={this.uploadNsdlDematCmrCopy}
                                            inputProps={{
                                              accept: "application/pdf"
                                            }}
                                            style={{ visibility: 'hidden', display: 'none', height: '25vh' }}
                                          />
                                          <Box className="cloud_upload_icon_box">
                                            {
                                              !this.state.demateNsdlCMRCopy &&
                                              <InputLabel className="cloud_upload_icon_box" htmlFor="demate_upload_label_id">
                                                <CloudUploadIcon style={{ color: 'black' }} />
                                              </InputLabel>
                                            }
                                            {
                                              this.state.demateNsdlCMRCopy ?
                                                <Box style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'space-between', columnGap: '8%' }}>
                                                  <Typography id="demat_upload_label" style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}>{this.state.demateNsdlCMRCopy?.name}</Typography>
                                                  <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '2%' }} onClick={(e) => { this.removeNsdlDemate(); e.preventDefault() }}>X</Typography>
                                                </Box>
                                                :
                                                <InputLabel id="label" style={{ color: 'grey' }} >{configJSON.dematUploadLabel}</InputLabel>
                                            }
                                          </Box>
                                        </Button>
                                        {this.state.errorNsdlUploadDemateFile && <InputLabel id="error_label">Upload PDF File Only</InputLabel>}
                                      </Box>
                                  }
                                </>
                            }
                            <Box className="demat_detail_pan_box">
                              <InputLabel className="skip_label">
                                {configJSON.skipText} <span onClick={() => this.props.history.push(localStorage.getItem("role_id") == '2'? "/DealDiscovery":'ContentManagement/DealsDashboard')}  className="link">Skip</span>
                              </InputLabel>
                            </Box>
                          </Box>
                        </Box>
                        <Box className="demat_detail_inner_3">
                          <Box className="kyc_button_box">
                            <Button variant="contained" className="save_as_draft_button" onClick={this.saveAsDraf}>
                              Back
                            </Button>
                            <Button variant="contained" className="next_button" onClick={() => {
                              this.state.demateDetailUpdateApiCalled === true ? this.updateDematDetailData() : this.addDematDetail();
                            }}>
                              Submit
                            </Button>
                          </Box>
                        </Box>
                      </>
                      :
                      <>
                        <Box className="demat_detail_inner_1">
                          <Box>
                            <InputLabel id="label1">Name</InputLabel>
                            <input value={this.state.name}
                              className="kyc_test"
                              data-test-id="name_account"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getName(e)}
                              type="text" placeholder="Name of Account Holder"
                              maxLength={25} />
                            {this.state.nameError && <InputLabel id="error_label">Name In Between 2-25 Characters Only </InputLabel>}
                          </Box>
                          <Box className="bankBox">
                            <InputLabel id="label1">Bank Name</InputLabel>
                            <Box className="kyc_drop_box" id="bank_drop_value" onClick={() => { this.showBankList() }}>

                              <Typography style={{ cursor: 'pointer', marginLeft: '2%' }}>
                                {bankDropDownSelected ? bankDropDownSelected : <Typography style={{ color: 'grey' }}>Bank Name</Typography>}
                              </Typography>
                              {
                                this.state.showBankListValue ?
                                  <Paper className="bank_kyc">
                                    {
                                      this.state.bankListDetailArray.map((item: any, index: number) => {
                                        return <Box className="kyc_drop_val" key={index} onClick={() => { this.selectedBankValue(item) }}>
                                          <Typography className="kyc_val"> {item.value}</Typography>
                                          {index < this.state.bankListDetailArray.length - 1 && <Divider variant="fullWidth" style={{ marginTop: '1%' }} />}
                                        </Box>
                                      })
                                    }
                                  </Paper>
                                  :
                                  null
                              }
                              <InputLabel htmlFor="bank_drop_value">
                                <KeyboardArrowDown className="drop_icon" />
                              </InputLabel>
                            </Box>
                          </Box>
                          <Box className="bankBox">
                            <InputLabel id="label1">Account Number</InputLabel>
                            <input value={this.state.accNo}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }} onCopy={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                              data-test-id="getAccNo"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getAccNo(e)}
                              maxLength={18}
                              className="kyc_test" placeholder="Account Number" />
                            {this.state.errorAccNo && <InputLabel id="error_label">Please Enter Valid Account Number</InputLabel>}
                          </Box>
                          <Box className="bankBox">
                            <InputLabel id="label1">Confirm Account Number</InputLabel>
                            <input value={this.state.confirmAccNo}
                              onPaste={(e) => {
                                e.preventDefault()
                                return false;
                              }} onCopy={(e) => {
                                e.preventDefault()
                                return false;
                              }}
                              data-test-id="getConfirmAccNo"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getConfirmAccNo(e)}
                              maxLength={18}
                              className="kyc_test" placeholder="Confirm Account Number" />
                            {this.state.showError ? <InputLabel id="error_label">Account Don't Match</InputLabel> : ''}
                          </Box>
                          <Box className="bankBox">
                            <InputLabel id="label1">IFSC Code</InputLabel>
                            <input
                              value={this.state.ifscCode}
                              maxLength={11}
                              onKeyDown={this.handleMinuSign}
                              data-test-id="getIfscCode"
                              onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.getIfscCode(e)}
                              className="kyc_test" placeholder="IFSC Code" />
                            {this.state.showErrorIfsc && <InputLabel id="error_label">Please Enter Valid Ifsc Code</InputLabel>}
                          </Box>
                          {
                            this.state.cancelBankCheckName ?
                              <Box id="kyc_upload_box1" style={{ flexDirection: 'row', justifyContent: 'space-evenly', color: 'black' }}>
                                <Typography id="upload_label_bank"><a href={this.state.cancelBankUrl}>{this.state.cancelBankCheckName}</a></Typography>
                                <Typography id="upload_label_bank" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={() => { this.removeCancelCheck() }}>X</Typography>
                              </Box>
                              :
                              <Box id="kyc_upload_box1">
                                <Button
                                  component="label"
                                  fullWidth
                                  style={{ textTransform: 'none' }}
                                >
                                  <Input
                                    type="file"
                                    hidden
                                    id="bank_cheque_upload"
                                    disableUnderline={true}
                                    data-test-id="uploadBankCheck"
                                    onChange={this.uploadBankCheck}
                                    inputProps={{
                                      accept: ".png, .jpg, .jpeg"
                                    }}
                                    style={{ visibility: 'hidden', display: 'none', height: '25vh' }}
                                  />
                                  <Box className="cloud_upload_icon_box">
                                    {
                                      !this.state.uploadBankCancelCheck &&
                                      <InputLabel className="cloud_upload_icon_box" htmlFor="bank_cheque_upload" style={{ color: 'black' }}>
                                        <CloudUploadIcon />
                                      </InputLabel>
                                    }
                                    {
                                      this.state.bankCheck?.name ?
                                        <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', columnGap: '15%' }}>
                                          <InputLabel className="upload_label_kyc" style={{ color: 'blue' }}>{this.state.bankCheck.name}</InputLabel>
                                          <Typography id="upload_label_bank" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={(e) => { this.removeCancelCheck(); e.preventDefault() }}>X</Typography>
                                        </Box>
                                        :
                                        <InputLabel id="label">{configJSON.bankUploadLabel}</InputLabel>
                                    }
                                    {this.state.errorUploadBankCancelCheckPhoto && <InputLabel id="error_label">Upload Only Image</InputLabel>}
                                  </Box>
                                </Button>
                              </Box>
                          }
                        </Box>
                        <Box className="demat_detail_inner_2">
                          <Box className="kyc_button_box">
                            <Button variant="contained" className="save_as_draft_button" onClick={() => {
                              this.setState({ showKycAddBankDetailForm: false });
                              this.handleBack()
                            }}>Back</Button>
                            <Button variant="contained" className="next_button" onClick={() => {
                              this.state.bankDetailUpdateApiCalled === true ? this.bankDetailUpdateApi() : this.addBankDetail()
                            }}>Submit</Button>
                          </Box>
                        </Box>
                      </>
                  }
                </Box>

              </>
              :
              <>
                <Box className="seekar_kyc_box">
                  <Box className="seekar_kyc_box_inner_1">
                    {
                      localStorage.getItem('role_id') == "1" &&
                      <>
                        <Box onClick={() => { this.setState({ selectLabel: false }) }}>
                          <InputLabel id="kyc_debt_seekar" required>
                            {this.state.debtSeekerInputLabel}
                          </InputLabel>
                          <Box className="kyc_drop_box" id="seeker_drop_value" onClick={() => { this.showSeekarDropValue() }}>

                            <Typography style={{ cursor: 'pointer', marginLeft: '2%' }}>
                              {this.state.debtSeekerKycShowValue ? this.state.debtSeekerKycShowValue : <Typography style={{ color: 'grey' }}>Type of Debt Seeker</Typography>}
                            </Typography>
                            {
                              this.state.showSeekarDropDownValue ?
                                <Paper className="drop_kyc">
                                  {
                                    this.state.seekarDropValue.map((item: any, index: number) => {
                                      return <Box key={`seekerDropDown${index}`} className="kyc_drop_val" onClick={() => { this.handleChange(item) }}>
                                        <Typography className="kyc_val"> {item.value}</Typography>
                                        {index < this.state.seekarDropValue.length - 1 && <Divider variant="fullWidth" style={{ marginTop: '1%' }} />}
                                      </Box>
                                    })
                                  }
                                </Paper>
                                :
                                null
                            }
                            <InputLabel htmlFor="seeker_drop_value">
                              <KeyboardArrowDown className="drop_icon" />
                            </InputLabel>
                          </Box>
                        </Box>
                        {
                          this.state.debtSeekerKycShowValue == "LLP" ?
                            <Box className="llp_box">
                              <InputLabel id="label1" required>LLPIN</InputLabel>
                              <input className="kyc_test" maxLength={7} value={this.state.llpin} data-test-id="llp_box" onChange={this.getLlpInVal} type="text" placeholder="0000000 0000000 0000000" />
                              {this.state.llpinError && <InputLabel id="error_label">Please Enter Valid LLpin Number</InputLabel>}
                            </Box>
                            :
                            null
                        }
                        {
                          this.state.debtSeekerKycShowValue == "Private Limited Company" || this.state.debtSeekerKycShowValue == "Public Limited Company" ?
                            <Box className="cin_box">
                              <InputLabel id="label1" required>CIN</InputLabel>
                              <input className="kyc_test" data-test-id="cin_box" maxLength={21} value={this.state.cin} onKeyDown={this.handleMinuSign} onChange={this.getCinValue} type="text" placeholder="0000000 0000000 0000000"
                              />
                              {this.state.cinError && <InputLabel id="error_label">Please Enter Valid CIN Number </InputLabel>}
                            </Box>
                            :
                            null
                        }
                        <Box className="pan_box">
                          <InputLabel id="label1" required>PAN</InputLabel>
                          <input maxLength={10} className="kyc_test" value={this.state.pan} onChange={this.getPanValue} onKeyDown={this.handleMinuSign} type="text" placeholder="ABCTY1234D"
                          />
                          {this.state.errorPanNumber && <InputLabel id="error_label">Please Enter Valid Pan Value</InputLabel>}
                        </Box>
                        {
                          this.state.panCardName ?
                            <Box id="kyc_upload_box2">
                              <Typography id="upload_label_kyc"><a href={this.state.panCard}>{this.state.panCardName}</a></Typography>
                              <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={() => { this.removeUploadFile() }}>X</Typography>
                            </Box>
                            :
                            <Box id="kyc_upload_box2">
                              <Button
                                component="label"
                                fullWidth
                                style={{ textTransform: 'none' }}
                              >
                                <Input
                                  type="file"
                                  hidden
                                  id="seekar_upload"
                                  onChange={this.uploadFile}
                                  disableUnderline={true}
                                  inputProps={{
                                    accept: ".png, .jpg, .jpeg"
                                  }}
                                  style={{ visibility: 'hidden', display: 'none', height: '25vh' }}
                                />
                                <Box className="cloud_upload_icon_box">
                                  {
                                    !this.state.uploadDetail &&
                                    <InputLabel htmlFor="seekar_upload" style={{ color: 'black' }} >
                                      <CloudUploadIcon />
                                    </InputLabel>
                                  }
                                  {
                                    this.state.file?.name ?
                                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', columnGap: '1%' }}>
                                        <Typography id="upload_label_kyc">{this.state.file?.name}</Typography>
                                        {this.state.file.name && <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={(e) => { this.removeUploadFile(); e.preventDefault() }}>X</Typography>}
                                      </Box>
                                      :
                                      <InputLabel id="label">{configJSON.kycUploadLabel}</InputLabel>
                                  }
                                  {this.state.errorUploadPanPhoto && <InputLabel id="error_label">Upload Only Image File</InputLabel>}
                                </Box>
                              </Button>
                            </Box>
                        }
                      </>
                    }
                    {
                      localStorage.getItem('role_id') == "3" &&
                      <>
                        <Box className="kyc_arranger_parent_box" onClick={() => { this.setState({ selectLabel: false }) }}>
                          <InputLabel id="label1" required>
                            {this.state.role_id == "3" && this.state.debtArrangerInputLabel}
                          </InputLabel>
                          <Box className="kyc_drop_box" id="arranger_drop_value" onClick={() => { this.showArrangerDropValue() }}>

                            <Typography style={{ cursor: 'pointer', marginLeft: '2%' }}>
                              {this.state.debtArrangerKycShowValue ? this.state.debtArrangerKycShowValue : <Typography style={{ color: 'grey' }}>Type of Debt Arranger</Typography>}
                            </Typography>
                            {
                              this.state.showArrangerDropDownValue ?
                                <Paper className="drop_arranger">
                                  {
                                    this.state.arrangerDropValue.map((item: any, index: number) => {
                                      return <Box className="kyc_drop_val" key={index} onClick={() => { this.handleChange(item) }}>
                                        <Typography className="kyc_val"> {item.value}</Typography>
                                        {index < this.state.arrangerDropValue.length - 1 && <Divider variant="fullWidth" style={{ marginTop: '1%' }} />}
                                      </Box>
                                    })
                                  }
                                </Paper>
                                :
                                null
                            }
                            <InputLabel htmlFor="arranger_drop_value">
                              <KeyboardArrowDown className="drop_icon" />
                            </InputLabel>
                          </Box>
                        </Box>
                        {
                          this.state.debtArrangerKycShowValue == "LLP" ?
                            <Box className="llp_box">
                              <InputLabel id="label1" required>LLPIN</InputLabel>
                              <input className="kyc_test"
                                value={this.state.llpin} maxLength={7} onChange={this.getLlpInVal} type="text" placeholder="0000000 0000000 0000000" />
                              {this.state.llpinError && <InputLabel id="error_label">Please Enter Valid LLpin Number</InputLabel>}
                            </Box>
                            :
                            null
                        }
                        {
                          this.state.debtArrangerKycShowValue == "Private Limited Company"
                            ?
                            <Box className="cin_box">
                              <InputLabel id="label1" required>CIN</InputLabel>
                              <input className="kyc_test" value={this.state.cin} maxLength={21} onKeyDown={this.handleMinuSign} onChange={this.getCinValue} type="text" placeholder="0000000 0000000 0000000"
                              />
                              {this.state.cinError && <InputLabel id="error_label">Please Enter Valid CIN Number</InputLabel>}
                            </Box>
                            :
                            null
                        }
                        {this.getPanCardView("arranger_upload")}
                      </>
                    }
                    {
                      localStorage.getItem('role_id') == "2" &&
                      <>
                        <Grid item lg={12} md={12} xs={12} xl={12} onClick={() => { this.setState({ selectLabel: false }) }}>
                          <InputLabel id="label1" required>
                            {this.state.debtProviderInputLabel}
                          </InputLabel>
                          <Box className="kyc_drop_box" id="provider_drop_value" onClick={() => { this.showProviderDropValue() }}>
                            <Typography style={{ cursor: 'pointer', marginLeft: '2%' }}>
                              {this.state.debtProviderKycShowvalue ? this.state.debtProviderKycShowvalue : <Typography style={{ color: 'grey' }}>Type of Debt provider</Typography>}
                            </Typography>
                            {
                              this.state.showProviderDropDownValue ?
                                <Paper className="drop_provider">
                                  {
                                    this.state.providerDropValue.map((item: any, index: number) => {
                                      return <Box key={`seekerDropDown${index}`} className="kyc_drop_val" onClick={() => { this.handleChange(item) }}>
                                        <Typography className="kyc_val"> {item.value}</Typography>
                                        {index < this.state.providerDropValue.length - 1 && <Divider variant="fullWidth" style={{ marginTop: '1%' }} />}
                                      </Box>
                                    })
                                  }
                                </Paper>
                                :
                                null
                            }
                            <InputLabel htmlFor="provider_drop_value">
                              <KeyboardArrowDown className="drop_icon" />
                            </InputLabel>
                          </Box>
                        </Grid>
                        {
                          (this.state.debtProviderKycShowvalue == "Bank" || this.state.debtProviderKycShowvalue == "Mutual Funds" || this.state.debtProviderKycShowvalue == "FI" || this.state.debtProviderKycShowvalue == "NBFC") ?
                            <Box className="llp_box">
                              <InputLabel id="label1" required>CIN</InputLabel>
                              <input className="kyc_test" value={this.state.cin} maxLength={21} onKeyDown={this.handleMinuSign} onChange={this.getCinValue} type="text" placeholder="0000000 0000000 0000000" />
                              {this.state.cinError && <InputLabel id="error_label">Please Enter Valid CIN Number</InputLabel>}
                            </Box>
                            :
                            null
                        }
                        {this.getPanCardView("provider_upload")}
                      </>
                    }
                  </Box>
                  <Box className="seekar_kyc_box_inner_2">
                    <Box className="kyc_button_box">
                      <Button variant="contained" className="save_as_draft_button" onClick={() => {
                        this.props.navigation.navigate("LandingPage");
                      }}
                      >Back</Button>
                      <Button variant="contained" className="kyc_next_button" style={{ textTransform: 'none' }} onClick={() => {
                        this.state.kycUpdateApiCalled === true ? this.kycUpdateApi() : this.addKycDetail()
                      }}>Save and Continue</Button>
                    </Box>
                  </Box>
                </Box>
              </>
          }
          <Box className="kyc_radio_button">
            <Stepper activeStep={this.state.activeStep} alternativeLabel>
              {step.map((label: any, index: number) => (
                <Step key={label + index}>
                  <StepLabel StepIconComponent={this.QontoStepIcon}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>
        </Grid>
      </Grid>
    );
  }
}
export default withRouter(CfEkycVerification3 as any) as any;
// Customizable Area End
