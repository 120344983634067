import React, { Children } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
} from "@material-ui/core";
import "./styles.css";

type Props = {
  title: string;
  text: string;
  img: string;
};


const CustomCard: React.FC<Props> = ({ title, text, img }) => {

  return (
    <Card className="customCard_card">
      <CardContent>
        <Box textAlign="center">
          <img
            className="customCard_img"
            alt="img"
            src={img}
          />
        </Box>
        <Box textAlign="center" mt={2} mb={2}>
          <Typography className="customCard_textlabel_ForDebtSeekersText">
            {title}
          </Typography>
        </Box>
        <Box textAlign="center">
          <Typography className="customCard_textlabel_OneStopSolutionToText" >
            {text}
          </Typography>
        </Box>
        {
          title === "For Debt Seekers" && <Box mt={2} ><Typography className="customCard_get_access" > Get access of investors across the globe</Typography></Box>
        }
      </CardContent>
    </Card>
  );
};

export default CustomCard;
