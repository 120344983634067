import React from "react";
// Customizable Area Start
import {
    Paper,
    Box,
    Container,
    Toolbar,
    Grid,
    makeStyles,
    Typography,
    CssBaseline
} from "@material-ui/core";
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";
import {
    aboutuspage,
    Business_256,
    report_256,
    bell_256,
    update_256,
    Compliance_256,
    web6,
    trigger,
    Automated,
    email,
    Compliance,
    data_256
}
    from "../../../blocks/landingpage/src/assets";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import Footer from "../../../blocks/landingpage/src/Footer.web";

export default class AboutFinmart extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getPrivacyPolicy();
    }

    render() {
        return (
            <>
                <Header match='' location="" history="" id="" navigation={this.props.navigation} />
                <Toolbar />
                <Toolbar />


                <Container maxWidth={false} disableGutters  >
                    <Typography style={webstyles.headingBlogHeader}>About Us</Typography>
                    <Grid container style={{ display: 'flex', padding: '2em' }}>
                        <Grid item xs={12} sm={5} md={5} style={{ alignItems: 'stretch', justifyContent: 'center', paddingLeft: "5em" }}>
                            <img
                                alt="connect"
                                src={aboutuspage}
                                style={webstyles.imageStyle}

                            />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} style={{ alignItems: 'stretch', justifyContent: 'center', paddingRight: '3em' }}>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour} >Truboard Value Proposition</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        At TruBoard, our mission is to improve post investment decision making. We believe that effective monitoring determines the success of an investment strategy. Accordingly, we have launched a core monitoring product for our clients, combining asset class specific features, sector and product expertise and technology (data analytics). By serving various post investment needs of capital providers, we aim to ensure success in investment strategy for our clients and in the long run help expand the investor pool across asset classes in the industry.
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour}>Credit Monitoring</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        Our Credit Monitoring solution, helps debt providers to digitize the post investment value chain one step at a time. We help our clients manage their investments on our platform through covenant monitoring, information management, early warning signals, dashboarding, etc.                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour}>Retail Loan Servicing</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        Our Retail Loan Servicing solution offers a third-party servicing and collections solution for clients. We believe that the evolution of data analytics driven third-party servicing companies is a structural trend and there is now a clear need to separate the servicing solutions provider from the originator. This trend is likely to lead to a wider investor base for retail asset pools, for both performing and distress assets.

                                        Know more
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour}>Real Asset Management Services</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        Our Real Asset Management services adds a layer of management responsibility to our core monitoring solution to help yield investors run their portfolios in India. As their independent asset managers, we help investors manage real assets such as, infrastructure, real estate, etc. on the ground through a combination of multi-functional expertise such as, technical, financial, legal, ESG, etc. Know more
                                    </Typography>
                                </Box>
                            </Box>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour}>Dispute Resolution and Advisory</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        Our Dispute Resolution and Advisory practice is an integral service component that dovetails into the above products by focussing on dispute resolution, fund-raising, treasury management, credit administration etc. Know more                                     </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                    <div style={{ backgroundColor: '#f1f6fc', paddingBottom: '3em' }}>
                        <Typography style={webstyles.headingBlog}>Our Monitoring Philosophy</Typography>
                        <Grid container style={{ display: 'flex' }}>
                            <Grid item sm={2} md={2} style={{ alignItems: 'stretch', justifyContent: 'center', paddingLeft: "6em" }}>
                                <img
                                    src={Business_256}
                                    style={webstyles.imagesB}
                                />
                            </Grid>
                            <Grid item sm={4} md={4} style={{ paddingRight: '2.5em' }}>
                                <Typography style={webstyles.earstoGround}>Ears to the ground</Typography>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Dedicated manpower for site visits & assessments</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Construction updates</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Contract management, dispute resolution, etc.</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Real time feedback to credit team</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Independent reporting to committee and board</Typography>
                                </div>


                            </Grid>

                            <Grid item sm={1} md={1}  >
                                <img

                                    src={report_256}
                                    style={webstyles.imagesB}
                                />
                            </Grid>
                            <Grid item sm={5} md={5} style={{ paddingRight: '6em' }} >
                                <Typography style={webstyles.earstoGround}>Adherence to business plan</Typography>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Mapping budget vs actual performance (under construction & operational assets)</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Financial analysis, Covenant testing</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Amendment, Deviations, NOC’s issuance & analysis</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>TRA / Escrow management</Typography>
                                </div>



                            </Grid>
                            <Grid item sm={2} md={2} style={{ alignItems: 'stretch', justifyContent: 'center', paddingLeft: "6em", marginTop: "2.5em" }}>
                                <img

                                    src={bell_256}
                                    style={webstyles.imagesB}
                                />
                            </Grid>
                            <Grid item sm={4} md={4} style={{ paddingRight: '2.5em', marginTop: "2.5em" }}>
                                <Typography style={webstyles.earstoGround}>Early Warning Signals</Typography>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Highlight issues at early stage</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Map market & regulatory trends for each deal</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Classification of each deal as per risk perception</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Highlight stress in portfolio</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Pro-active resolution approach</Typography>
                                </div>


                            </Grid>
                            <Grid item sm={1} md={1} style={{ marginTop: "2.5em" }} >
                                <img

                                    src={update_256}
                                    style={webstyles.imagesB}
                                />
                            </Grid>
                            <Grid item sm={5} md={5} style={{ paddingRight: '6em', marginTop: "2.5em" }} >
                                <Typography style={webstyles.earstoGround}>Reporting</Typography>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Develop deal & portfolio MIS</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Monthly, Quarterly & Annual MIS</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Internal and regulatory compliances</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>ESG</Typography>
                                </div>



                            </Grid>
                            <Grid item sm={2} md={2} style={{ alignItems: 'stretch', justifyContent: 'center', paddingLeft: "6em", marginTop: "2.5em", }}>
                                <img

                                    src={Compliance_256}
                                    style={webstyles.imagesB}
                                />
                            </Grid>
                            <Grid item sm={4} md={4} style={{ paddingRight: '2.5em', marginTop: "2.5em" }}>
                                <Typography style={webstyles.earstoGround}>Cover analysis</Typography>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Skin in the game</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Movement in industry metrics</Typography>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <CheckCircleRoundedIcon style={webstyles.iconColor} />
                                    <Typography style={webstyles.earstoGroundSub}>Asset and consolidated cash flows</Typography>
                                </div>

                            </Grid>

                        </Grid>
                    </div>
                    <Grid container style={{ display: 'flex', padding: '2em' }}>
                        <Grid item xs={12} sm={5} md={5} style={{ alignItems: 'stretch', justifyContent: 'center', paddingLeft: "5em" }}>
                            <img
                                alt="connect"
                                src={web6}
                                style={webstyles.imageStyle1}

                            />
                        </Grid>
                        <Grid item xs={12} sm={7} md={7} style={{ alignItems: 'stretch', justifyContent: 'center', paddingRight: '3em' }}>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4" style={webstyles.headingColour}>Advanced Monitoring Framework</Typography>

                                <Box sx={webstyles.privacyContainer}  >
                                    <Typography align="justify">
                                        One of the biggest challenges that we face today, is the assimilation, demystification & interpretation of all the data that is generated across investments, such as financial, technical, compliance and statutory transactions. Our framework combines all the deal data at one location & helps search through large amounts of data to highlight key risk areas for investments. Individual deal data then flows into the portfolio level data.
                                    </Typography>
                                    <Typography align="justify" style={webstyles.privacyContainer1}>
                                        Our technology platform enables real time tracking and comparison of the performance parameters of portfolio companies to the underwriting cases. Our solution can be fully integrated to the current technology stack and processes and can be customized to provide monitoring requirements for individual assets or portfolio.
                                    </Typography>
                                    <Typography align="justify" style={webstyles.privacyContainer1}>
                                        Over time, as the quantity & quality of data improves, our AI/ML tools will derive data driven insights for our clients.                                    </Typography>
                                </Box>
                            </Box>

                        </Grid>
                    </Grid>
                    <Typography style={webstyles.headingBlogFeatures}>Features</Typography>
                    <Grid container style={{ paddingLeft: '6em', display: "flex", width: "90%", }}>
                        <Grid item xs={12} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingTop: "0.8em" }}>
                                <img

                                    src={trigger}
                                    style={webstyles.imagesC}
                                />
                                <Typography style={webstyles.earstoGroundBottom}>User friendly & customizable</Typography>
                            </Box>


                        </Grid>

                        <Grid item xs={12} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingLeft: '2em', paddingTop: "0.8em" }}>
                                <img
                                    src={Automated}
                                    style={webstyles.imagesD}
                                />
                                <Typography style={webstyles.earstoGround}>Automated Trigger System</Typography>
                            </Box>

                        </Grid>

                        <Grid item sm={6} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingTop: "0.8em" }}>
                                <img
                                    src={email}
                                    style={webstyles.imagesC}
                                />
                                <Typography style={webstyles.earstoGround}>
                                    Email Notification System</Typography>
                            </Box>

                        </Grid>
                        <Grid item sm={6} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingLeft: '2em', paddingTop: "0.8em" }}>

                                <img
                                    src={Compliance}
                                    style={webstyles.imagesD}
                                />
                                <Typography style={webstyles.earstoGround}>

                                    Compliance Management</Typography>
                            </Box>

                        </Grid>
                        <Grid item sm={6} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingTop: "0.8em" }}>

                                <img
                                    src={report_256}
                                    style={webstyles.imagesC}
                                />
                                <Typography style={webstyles.earstoGround}>


                                    Reporting & MIS</Typography>
                            </Box>

                        </Grid>
                        <Grid item sm={6} md={6} style={{ display: "flex", paddingBottom: '1.5em' }}>
                            <Box style={{ backgroundColor: '#f1f6fc', display: "flex", width: "95%", paddingLeft: '2em', paddingTop: "0.8em", paddingRight: "0.2em" }}>

                                <img
                                    src={data_256}
                                    style={webstyles.imagesD}
                                />
                                <Typography style={webstyles.earstoGround}>



                                    Robust Data Governance Mechanism</Typography>
                            </Box>

                        </Grid>
                    </Grid>
                    <div style={{ marginTop: '3em' }}>
                        <Footer navigation={this.props.navigation} id={""} match={undefined} location={undefined} history={undefined} />
                    </div>
                </Container>

            </>

        );
    }
}

const webstyles = {
    imagesC: {
        width: "3.472vw",
        height: "3.472vw",
        paddingRight: "2em",
        paddingLeft: '2em'
    },
    imagesD: {
        width: "3.472vw",
        height: "3.472vw",
        paddingRight: "2em",
    },
    imageStyle1: {
        width: "18.472vw",
        height: "24.306vw",
    },
    iconColor: {
        color: "#011d57",
    },
    headingColour: {
        color: "#011d57",
    },
    earstoGround: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#011d57",
        paddingBottom: '0.8em'

    },
    earstoGroundBottom: {
        fontSize: "24px",
        fontWeight: 600,
        color: "#011d57",

    },
    earstoGroundSub: {

        color: " #666",
        fontWeight: 500,
        paddingBottom: '1em',
        paddingLeft: '1em'

    },

    imagesB: {
        width: "5.556vw",
        height: "4.722vw",
    },
    headingBlogFeatures: {
        color: "#323232",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        paddingLeft: '1.5em',
        paddingBottom: "1em",
    },
    headingBlog: {
        color: "#323232",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        paddingLeft: '1.5em',
        paddingBottom: "1em",
        paddingTop: "1em"
    },
    headingBlogHeader: {
        color: "#323232",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        paddingLeft: '2.5em',
        paddingTop: '0.5em',
        paddingBottom: "1em",
    },
    imageStyle: {
        width: "27.153vw",
        height: "52.083vw"
    },
    paper: {
        // padding: "24px 32px",
        paddingBottom: "2.5em"


    },
    privacyContainer: {
        paddingTop: "20px",
        paddingRight: '2.5em'

    },
    privacyContainer1: {
        paddingTop: "10px",

    }
}
// Customizable Area End