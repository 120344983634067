// Customizable Area Start
import React from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  TextField,
  FormControl,
  Divider
} from "@material-ui/core";
import { createTheme } from "@material-ui/core/styles";
import StopIcon from '@material-ui/icons/Stop';
import { search_icon } from "./assets";
import moment from "moment";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { withRouter } from "react-router";

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

import  {Props} from "./ContentManagementController";
import ApproveDealController from "./ApproveDealController.web";
import { showTextWithValidation } from "../../../components/src/common";

class ApproveDeal extends ApproveDealController {
  constructor(props: Props) {
    super(props);
  }
  handleSelect = (e: any) => {
    this.setState({
      approveDealSearchDropdown: e.target.value
    });
  };
  render() {
    // width="6%"  height="32%"
    return (
      <Container>
        <Grid container justify="space-around" spacing={2}>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box className="parent_box_approve_header">
              <FormControl className="form_control_approve_deal" size="small">
                <TextField
                  data-testid="search-approve-deal"
                  fullWidth
                  value={this.state.searchString} onChange={this.handleSearchChange} onKeyPress={this.handleSearchKeyPress} id="outlined-basic" label="Search by deal status" variant="outlined" margin="dense" />
              </FormControl>
              <img data-testid="search-approve-deal-button" onClick={() => this.getApprovoveDealsList()} src={search_icon} style={{ marginRight: '5%', maxWidth: "24px", width: "100%", height: "auto", cursor: "pointer" }} />
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box style={{ display: 'flex', marginTop: '14%', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-end' }}>
              <Typography className="header_status_typography">Status of Deals</Typography>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
                <Grid md={6} lg={6} xl={6}>
                  <StopIcon className="approve_icon" />
                </Grid>
                <Grid md={6} lg={6} xl={6}>
                  <Typography data-testid="approve-deal-status" style={{ marginLeft: '-8%' }}>Approved</Typography>
                </Grid>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '-3%' }}>
                <Grid md={6} lg={6} xl={6}>
                  <StopIcon className="pending_icon" />
                </Grid>
                <Grid md={6} lg={6} xl={6}>
                  <Typography style={{ marginLeft: '-8%' }}>Pending</Typography>
                </Grid>
              </Box>
              <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center', marginTop: '-3%' }}>
                <Grid md={6} lg={6} xl={6}>
                  <StopIcon className="rejected_icon" />
                </Grid>
                <Grid md={6} lg={6} xl={6}>
                  <Typography style={{ marginLeft: '-8%' }}>Rejected</Typography>
                </Grid>
              </Box>
            </Box>
          </Grid>
          {
            this.state.dealsList && !this.state.isLoading && this.state.dealsList.length > 0 &&
            this.state.dealsList.map((deal, index) => (
              <Grid key={`approve-deal-${index}`}  style={{cursor:"pointer"}}item xs={12} sm={12} md={6} lg={6} xl={6}>
                <Box sx={{ marginLeft: { xs: 'none', sm: 'none', md: 'none', lg: '12%', xl: '12%' } }}>
                  <div onClick={() => this.handleApproveDealCardClick(deal)} className="textDecoration">
                    <div className={`${deal.review_status === "Pending for approval" ? "cardWrapperDealDashboard_approveDeal" : deal.review_status === "Rejected" ? "cardWrapperDealDashboard_approveDeal_rejected" : "cardWrapperDealDashboard_approveDeal_one"}`}>
                      <div style={{ display: "flex" }}>
                        <div style={{ height: '170px' }}>
                          <img
                            src={deal.upload_logo_image?.url || ""}
                            style={{ borderRadius: "10px" }}
                            alt="discovery"
                            width="100"
                            height="100"
                          />
                          <span className="rating_chip">  <p className="rating_label" style={{ marginLeft: '3px' }}>{showTextWithValidation(deal.proposed_instruments?.rating_details || 'Unrated')}</p> </span>
                          <span className="rating_chip"> <p className="rating_label">{showTextWithValidation(deal.proposed_instruments?.sector)}</p> </span>

                        </div>
                        <div
                          style={{
                            margin: "0 14px 0 24px",
                            height: "194", width: '94%'
                          }}
                        >
                          <p className="deal_companyName" style={{ color: 'black' }}>{showTextWithValidation(deal.debt_arranger_companies_id)}</p>
                          <div className="approveCardDescriptionWrapper">
                            <div className="approve_card" style={{ color: 'black' }}>
                              {showTextWithValidation(deal.company_background)}
                            </div>
                          </div>
                        </div>
                      </div>

                      <div style={{ margin: '0 24 0 0' }} className="normal_font">
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <p>Instrument</p>
                            <p>Total Amount (INR Cr)</p>
                            <p>Minimum Investment (INR Cr)</p>
                            <p>Expected Returns (Gross)</p>
                            <p>Maturity Date</p>
                            <p>Repayment Frequency</p>
                          </Grid>
                          <Grid item xs={4}>
                            <p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.instrument)}</p>
                            <p className="BlueText"> {showTextWithValidation(deal.proposed_instruments?.total_deal_size) !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.total_deal_size)} Cr` : "-"} </p>
                            <p className="BlueText"> {showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr") !== "-" ? `Rs. ${showTextWithValidation(deal.proposed_instruments?.minimum_investment + " Cr")}` : "-"}</p>
                            <p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.expected_returns_gross)}</p>
                            <p className="BlueText">{moment(deal.proposed_instruments?.repayment_maturity_date || "").format('MMMM DD, YYYY')}</p>
                            <p className="BlueText">{showTextWithValidation(deal.proposed_instruments?.repayment_frequency)}</p>
                          </Grid>
                        </Grid>
                      </div>
                      <Divider light style={{ marginRight: '-3%', marginLeft: '-3%' }} />
                      <div
                        style={{ margin: "0 22 0 0" }}
                        className="normal_font"
                      >
                        <Grid container spacing={2}>
                          <Grid item xs={8}>
                            <Typography className="name_title_deal">
                              Submitted by
                            </Typography>
                            <Typography className="name_title_deal">
                              Status
                            </Typography>
                            <Typography className="name_title_deal">
                              Time Stamp
                            </Typography>
                          </Grid>
                          <Grid item xs={4}>
                            <Typography className="name_typography_deal">{showTextWithValidation(deal.submitted_by)}</Typography>
                            <Typography className="name_typography_deal">{showTextWithValidation(deal.review_status)}</Typography>
                            <Typography className="name_typography_deal">{showTextWithValidation(deal.time_stamp)}</Typography>
                          </Grid>
                        </Grid>
                      </div>

                    </div>
                  </div>
                </Box>
              </Grid>
            ))
          }
          {this.state.dealsList && !this.state.isLoading && this.state.dealsList.length === 0 &&
            <p data-testid="no-data-available-text">No deals Available</p>}

        </Grid>
      </Container>
    );
  }
}

export default withRouter(ApproveDeal as any) as any;

const webStyle = {
  gridParent: {
    height: '97%',
    maxWidth: '100%',
    padding: '16px'
  },
};
// Customizable Area End
