import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { GlobalContext } from "../../catalogue/src/useContext";
const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  firstName: string;
  lastName: string;
  fullName: string;
  companyName: string;
  role: string;
  email: string;
  password: string;
  reTypePassword: string;
  otpAuthToken: string;
  emailAuthToken: string;
  role_id: number;
  registrationSuccess: boolean;
  data: any[];
  passwordHelperText: string;
  enablePasswordField: boolean;
  enableReTypePasswordField: boolean;
  countryCodeSelected: string;
  phone: string;
  email_verification_link_send: boolean;
  otp_sent_to_mobile: boolean;
  counter: string;
  OTP: string[];
  isEmailVerified: boolean;
  isMobileVerified: boolean;
  isError: boolean;
  isErrorName: boolean;
  isErrorEmail: boolean;
  isErrorPhone: boolean;
  isPassword: boolean;
  isErrorCompanyName: boolean;
  isErrorPassword: boolean;
  isErrorConfirmPasssword: boolean;
  isCheck1: boolean;
  isCheck2: boolean;
  isRegistrationIsSuccessfull: boolean;
  roleOptions: string[];
   snackbarOpen: boolean;
  snackbarMessage:any;
  loadingSignin:boolean;
  snackbarOpenError: boolean;
  // Customizable Area End
}

export interface SS {
  // Customizable Area Start
  id: any;
  navigation: any;
  // Customizable Area End
}

export default class EmailAccountRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  static contextType = GlobalContext;
  arrayholder: any[];
  passwordReg: RegExp;
  emailReg: RegExp;
  createAccountApiCallId: any;
  validationApiCallId: string = "";
  sendEmailApiCalledId: string = "";
  emailVerificationResponseApiCalledId: string = "";
  sendOTP_TO_MOBILE_ApiCallId: string = "";
  verify_mobile_OTP_ApiCallId: string = "";
  resendOTP_TO_MOBILE_ApiCallId: string = "";
  createAccount_APICalledId: string = "";
  imgPasswordVisible: any;
  imgPasswordInVisible: any;
  labelHeader: any;
  labelFirstName: string;
  lastName: string;
  labelEmail: string;
  labelPassword: string;
  labelRePassword: string;
  labelLegalText: string;
  labelLegalTermCondition: string;
  labelLegalPrivacyPolicy: string;
  btnTextSignUp: string;
  currentCountryCode: any;
  chatCredentialsApiCalledId:string=''
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
      getName(MessageEnum.CountryCodeMessage),
    ];
    this.receive = this.receive.bind(this);
    this.isStringNullOrBlank = this.isStringNullOrBlank.bind(this);

    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      firstName: "",
      lastName: "",
      fullName: "",
      email: "",
      password: "",
      reTypePassword: "",
      role: "",
      companyName: "",
      otpAuthToken: "",
      emailAuthToken: "",
      role_id: 1,
      roleOptions: ["Debt Seeker", "Debt Provider", "Debt Arranger"],
      data: [],
      OTP: [],
      passwordHelperText: "",
      enablePasswordField: true,
      enableReTypePasswordField: true,
      countryCodeSelected: "",
      registrationSuccess: false,
      phone: "",
      email_verification_link_send: false,
      otp_sent_to_mobile: false,
      counter: "02:00",
      isEmailVerified: false,
      isMobileVerified: false,
      isError: false,
      isErrorName: false,
      isErrorEmail: false,
      isErrorPhone: false,
      isPassword: false,
      isErrorCompanyName: false,
      isErrorPassword: false,
      isErrorConfirmPasssword: false,
      isCheck1: false,
      isCheck2: false,
      isRegistrationIsSuccessfull: false,
      snackbarOpen: false,
      snackbarMessage:{},
      loadingSignin:false,
      snackbarOpenError: false,
      // Customizable Area End
    };

    // Customizable Area Start
    this.arrayholder = [];
    this.passwordReg = new RegExp("\\w+");
    this.emailReg = new RegExp("\\w+");

    this.imgPasswordVisible = imgPasswordVisible;
    this.imgPasswordInVisible = imgPasswordInVisible;

    this.labelHeader = configJSON.labelHeader;
    this.labelFirstName = configJSON.labelFirstName;
    this.lastName = configJSON.lastName;
    this.labelEmail = configJSON.labelEmail;
    this.labelPassword = configJSON.labelPassword;
    this.labelRePassword = configJSON.labelRePassword;
    this.labelLegalText = configJSON.labelLegalText;
    this.labelLegalTermCondition = configJSON.labelLegalTermCondition;
    this.labelLegalPrivacyPolicy = configJSON.labelLegalPrivacyPolicy;
    this.btnTextSignUp = configJSON.btnTextSignUp;
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start

    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      if (apiRequestCallId && responseJson) {
        if (apiRequestCallId === this.createAccount_APICalledId) {
          if (responseJson?.data) {
            responseJson.data?.id 
            this.setState({loadingSignin:false})
            this.setState({snackbarOpen:true,snackbarMessage:"Registered Successfully"})
            this.handleRegisterQuickbloxUser()
            responseJson.data && 
            setTimeout(()=>this.props.navigation.navigate("EmailAccountLoginBlock"),3000)
            
            return;
          }
          if (responseJson?.errors[0]?.full_phone_number) {
            this.setState({loadingSignin:false})
            this.setState({snackbarOpenError:true,snackbarMessage:`${responseJson?.errors[0]?.full_phone_number}`})
            if (responseJson?.errors[0]?.full_phone_number.includes('This phone number is already taken')) {
              this.setState({
                otp_sent_to_mobile: false,
                isMobileVerified: false
              })
            }

            return;
          }
          /* istanbul ignore next */
          if (responseJson?.errors) {
            if (typeof responseJson?.errors === 'string') {
              return
            }
            const msg131: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg131.addData(getName(MessageEnum.CustomAlertBodyData), `${ responseJson?.errors[0]?.account } Please enter another Email ` || "Something went wrong");
            this.send(msg131);
            
            if (responseJson?.errors[0]?.account.includes('Email already taken')) {
              this.setState({
                email_verification_link_send: false,
                isEmailVerified: false
              })
            }
            return
          }


        }
        if (apiRequestCallId === this.sendEmailApiCalledId) {
          let message = ''

          if (responseJson?.message) {
            message = Object.values(responseJson?.message[0])[0] as string
            this.setState({loadingSignin:false})
            this.setState({snackbarOpenError:true,snackbarMessage:message})
          }

          if (responseJson?.meta?.message) {
            message = Object.values(responseJson?.meta?.message[0])[0] as string
            this.setState({loadingSignin:false})
            this.setState({snackbarOpen:true,snackbarMessage:message})
          }

          if (message) {
            // console.log("gdfsgfsgf",message)
          }

          if (responseJson?.data?.id) {
            this.checkEmailVerification();
            this.setState({ email_verification_link_send: true });
          }

          if (message.includes('Email already verified')) {
            this.setState({ isEmailVerified: true, email_verification_link_send: false });
          }
        }

        if (apiRequestCallId === this.emailVerificationResponseApiCalledId) {
          responseJson.status === false
            ? this.setState({ isEmailVerified: false })
            : this.setState({ isEmailVerified: true });
        }

        if (apiRequestCallId === this.sendOTP_TO_MOBILE_ApiCallId) {
          /* istanbul ignore next */
          if (responseJson?.message) {
            let message = ''
            if (Array.isArray(responseJson?.message)) {
              message = Object.values(responseJson?.message[0])[0] as string
              // // alert(`${message}`)
              // console.log("fdsgdsssdzf",message)
              this.setState({loadingSignin:false})
              this.setState({snackbarOpenError:true,snackbarMessage:`${Object.values(responseJson?.message[0])[0]}`})
            } else if (typeof responseJson?.message === 'string') {
              message = responseJson?.message
              const msg231: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg231.addData(getName(MessageEnum.CustomAlertBodyData), `${message}`);
              this.send(msg231);
            }

            if (responseJson?.token) {
              localStorage.setItem("OTP_TOKEN", responseJson?.token);
              this.setState({ otp_sent_to_mobile: true })
            } else {
              const data = {
                otp_sent_to_mobile: false,
                isMobileVerified: false
              }
              if (message.includes("Phone already verified")) {
                data.isMobileVerified = true
              }
              this.setState(data)
            }
            return;
          }
          localStorage.setItem("OTP_TOKEN", responseJson?.meta?.token);
          console.log("jsfbsdjbf",`${responseJson?.meta?.message[0]?.account}`)
          this.setState({loadingSignin:false})
          this.setState({snackbarOpen:true,snackbarMessage:`${responseJson?.meta?.message[0]?.account}`})
          responseJson?.meta?.message[0]?.account 
          responseJson?.meta?.message[0]?.account && this.setState({ otp_sent_to_mobile: true })
          responseJson?.data?.attributes?.activated === true ? this.setState({ isMobileVerified: true }) : null;
        }

        if (apiRequestCallId === this.resendOTP_TO_MOBILE_ApiCallId) {
          /* istanbul ignore next */
          if(responseJson?.message){
            const msg232: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg232.addData(getName(MessageEnum.CustomAlertBodyData), `${responseJson?.message}`);
            this.send(msg232);
          } 
          localStorage.setItem("OTP_TOKEN", responseJson?.meta?.token);
          /* istanbul ignore next */
          if(responseJson?.meta?.message[0]?.account){
            const msg233: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg233.addData(getName(MessageEnum.CustomAlertBodyData), `${responseJson?.meta?.message[0]?.account}`);
            this.send(msg233);
          }
          responseJson?.data?.attributes?.activated === true ? this.setState({ isMobileVerified: true }) : null;
        }
        if (apiRequestCallId === this.chatCredentialsApiCalledId) {
          
          this.context.ChatDispatch({ type: 'CHAT_DATA', payload:responseJson.data[0].attributes  })
          console.log(this.context.ChatState, 'global chat')
          // this.handleGetChatUser()
         
        }

        if (apiRequestCallId === this.verify_mobile_OTP_ApiCallId) {
          if (responseJson?.errors) {
            this.setState({snackbarOpenError:true,snackbarMessage:`${Object.values(responseJson?.errors[0])[0]}`})

          }

          if (responseJson?.data?.attributes?.activated) {
            this.setState({ isMobileVerified: true, otp_sent_to_mobile: false })
          }

        }

        if (apiRequestCallId === this.validationApiCallId) {
          this.arrayholder = responseJson.data;
          /*api response*/

          if (this.arrayholder && this.arrayholder.length !== 0) {
            let regexData = this.arrayholder[0];

            if (regexData.password_validation_regexp) {
              this.passwordReg = new RegExp(
                regexData.password_validation_regexp
              );
            }

            if (regexData.password_validation_rules) {
              this.setState({
                passwordHelperText: regexData.password_validation_rules,
              });
            }

            if (regexData.email_validation_regexp) {
              this.emailReg = new RegExp(regexData.email_validation_regexp);
            }
          }
        } else if (apiRequestCallId === this.createAccountApiCallId) {
          if (!responseJson.errors) {
            const msg: Message = new Message(
              getName(MessageEnum.AccoutResgistrationSuccess)
            );

            msg.addData(
              getName(MessageEnum.NavigationPropsMessage),
              this.props
            );

            this.send(msg);
          } else {
            //Check Error Response
            this.parseApiErrorResponse(responseJson);
          }

          this.parseApiCatchErrorResponse(errorReponse);
        }
      }
    }

    if (getName(MessageEnum.NavigationPayLoadMessage) === message.id) {
      const otpAuthTkn = message.getData(
        getName(MessageEnum.AuthTokenDataMessage)
      );
      if (otpAuthTkn && otpAuthTkn.length > 0) {
        this.setState({ otpAuthToken: otpAuthTkn });
        runEngine.debugLog("otpAuthTkn", this.state.otpAuthToken);
        runEngine.unSubscribeFromMessages(this as IBlock, [message.id]);
      }
    }

    if (getName(MessageEnum.CountryCodeMessage) === message.id) {
      var selectedCode = message.getData(
        getName(MessageEnum.CountyCodeDataMessage)
      );

      if (selectedCode !== undefined) {
        this.setState({
          countryCodeSelected:
            selectedCode.indexOf("+") > 0
              ? selectedCode.split("+")[1]
              : selectedCode,
        });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  goToPrivacyPolicy() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationPrivacyPolicyMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  goToTermsAndCondition() {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationTermAndConditionMessage)
    );
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }

  isStringNullOrBlank(str: string) {
    return str === null || str.length === 0;
  }

  isValidEmail(email: string) {
    return this.emailReg.test(email);
  }

  createAccount(): boolean {
    if (
      this.isStringNullOrBlank(this.state.firstName) ||
      this.isStringNullOrBlank(this.state.lastName) ||
      this.isStringNullOrBlank(this.state.email) ||
      this.isStringNullOrBlank(this.state.password) ||
      this.isStringNullOrBlank(this.state.reTypePassword)
    ) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorAllFieldsAreMandatory
      );
      return false;
    }

    var phoneNumberError = this.validateCountryCodeAndPhoneNumber(
      this.state.countryCodeSelected,
      this.state.phone
    );

    if (phoneNumberError) {
      this.showAlert(configJSON.errorTitle, phoneNumberError);
      return false;
    }

    if (!this.isValidEmail(this.state.email)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorEmailNotValid);
      return false;
    }

    if (!this.passwordReg.test(this.state.password)) {
      this.showAlert(configJSON.errorTitle, configJSON.errorPasswordNotValid);
      return false;
    }

    if (this.state.password !== this.state.reTypePassword) {
      this.showAlert(
        configJSON.errorTitle,
        configJSON.errorBothPasswordsNotSame
      );
      return false;
    }

    const header = {
      "Content-Type": configJSON.contentTypeApiAddDetail,
    };

    const attrs = {
      first_name: this.state.firstName,
      last_name: this.state.lastName,
      email: this.state.email,
      password: this.state.password,
      full_phone_number:
        "+" + this.state.countryCodeSelected + this.state.phone,
    };

    const data = {
      type: "email_account",
      attributes: attrs,
    };

    const httpBody = {
      data: data,
      token: this.state.otpAuthToken,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    this.handleRegisterQuickbloxUser()
    return true;
    
  }


  /* OPT SENT TO MOBILE */
  sentOtpToMobile = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      data: {
        attributes: {
          full_phone_number: `+91${this.state.phone}`,
        }
      }
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendOTP_TO_MOBILE_ApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.sendOTP_TO_MOBILE_ApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  /* OPT RESENT TO MOBILE */
  resentOtpToMobile = () => {

    (document.getElementById('text1') as HTMLInputElement).value = '';
    (document.getElementById('text2') as HTMLInputElement).value = '';
    (document.getElementById('text3') as HTMLInputElement).value = '';
    (document.getElementById('text4') as HTMLInputElement).value = '';

    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      data: {
        attributes: {
          full_phone_number: `+91${this.state.phone}`,
        }
      }
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.resendOTP_TO_MOBILE_ApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.resend_OTP_TO_MOBILE_ApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };


  /* Verify Mobile OTP */
  verifyMobileOtp = (OTP: any) => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const httpBody = {
      token: `${localStorage.getItem("OTP_TOKEN")}`,
      pin: OTP.join('')
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verify_mobile_OTP_ApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verify_OTP_ApiEndPoint
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  /* Create account with email */
  create_Account_With_Email = () => {
    this.setState({loadingSignin:true})
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    let fullName = this.state.fullName.split(" ");
    let first_name = fullName[0];
    let last_name = fullName[1];

    const data = {
      type: "email_account",
      attributes: {
        role_id: this.state.role_id,
        company_name: this.state.companyName,
        full_name: this.state.fullName,
        // first_name: first_name,
        // last_name: last_name,
        full_phone_number: `+91${this.state.phone}`,
        email: this.state.email,
        password: this.state.password,
      },
    };

    const httpBody = {
      data: data,
    };
    console.log("signupData--->", data)
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createAccount_APICalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.accountsAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  /*SendVerificationEmail*/
  sendVerificationEmail = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };
   
    const data = {
      attributes: {
        email: this.state.email.toLowerCase(),
        full_name: this.state.fullName,
        role_id:  this.state.role_id
      },
    };

    const httpBody = {
      data: data,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.sendEmailApiCalledId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage), configJSON.sendEmailApiEndPoint);

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  //Get Email Verification status
  getEailVerificationResponse = () => {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.emailVerificationResponseApiCalledId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `check_email_verification?email=${this.state.email}`
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );

    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  };

  /* sample api call */
  getValidations() {
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const getValidationsMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.validationApiCallId = getValidationsMsg.messageId;

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.urlGetValidations
    );

    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );
    getValidationsMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.validationApiMethodType
    );
    runEngine.sendMessage(getValidationsMsg.id, getValidationsMsg);
  }

  isNonNullAndEmpty(value: String) {
    return (
      value !== undefined &&
      value !== null &&
      value !== "null" &&
      value.trim().length > 0
    );
  }

  validateCountryCodeAndPhoneNumber(countryCode: string, phoneNumber: string) {
    let error = null;

    if (this.isNonNullAndEmpty(phoneNumber)) {
      if (!this.isNonNullAndEmpty(String(countryCode))) {
        error = configJSON.errorCountryCodeNotSelected;
      }
    } else if (this.isNonNullAndEmpty(countryCode)) {
      if (!this.isNonNullAndEmpty(phoneNumber)) {
        error = "Phone " + configJSON.errorBlankField;
      }
    }

    return error;
  }

  imgEnableRePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnreTypePasswordShowHideProps = {
    onPress: () => {
      this.setState({
        enableReTypePasswordField: !this.state.enableReTypePasswordField,
      });
      this.txtInputreTypePasswordProps.secureTextEntry = !this.state
        .enableReTypePasswordField;
      this.imgEnableRePasswordFieldProps.source = this
        .txtInputreTypePasswordProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  imgEnablePasswordFieldProps = {
    source: imgPasswordVisible,
  };

  btnPasswordShowHideProps = {
    onPress: () => {
      this.setState({ enablePasswordField: !this.state.enablePasswordField });
      this.txtInputPasswordProps.secureTextEntry = !this.state
        .enablePasswordField;
      this.imgEnablePasswordFieldProps.source = this.txtInputPasswordProps
        .secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnSignUpProps = {
    onPress: () => this.createAccount(),
  };

  btnLegalPrivacyPolicyProps = {
    onPress: () => this.goToPrivacyPolicy(),
  };

  btnLegalTermsAndConditionProps = {
    onPress: () => this.goToTermsAndCondition(),
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      //@ts-ignore
      this.txtInputEmailPrpos.value = text;
    },
  };

  txtInputEmailMobilePrpos = {
    ...this.txtInputEmailWebPrpos,
    keyboardType: "email-address",
  };

  txtInputEmailPrpos = this.isPlatformWeb()
    ? this.txtInputEmailWebPrpos
    : this.txtInputEmailMobilePrpos;

  txtPhoneNumberWebProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });

      //@ts-ignore
      this.txtPhoneNumberProps.value = text;
    },
  };

  txtPhoneNumberMobileProps = {
    ...this.txtPhoneNumberWebProps,
    autoCompleteType: "tel",
    keyboardType: "phone-pad",
  };

  txtPhoneNumberProps = this.isPlatformWeb()
    ? this.txtPhoneNumberWebProps
    : this.txtPhoneNumberMobileProps;

  txtInputLastNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });

      //@ts-ignore
      this.txtInputLastNamePrpos.value = text;
    },
  };

  txtInputFirstNamePrpos = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });

      //@ts-ignore
      this.txtInputFirstNamePrpos.value = text;
    },
  };

  txtInputreTypePasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ reTypePassword: text });

      //@ts-ignore
      this.txtInputreTypePasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  txtInputPasswordProps = {
    onChangeText: (text: string) => {
      this.setState({ password: text });

      //@ts-ignore
      this.txtInputPasswordProps.value = text;
    },
    secureTextEntry: true,
  };

  sendEmail = () => {
    this.sendVerificationEmail();
    this.getChatCredentials();
  };


  checkEmailVerification = () => {
    let timerId = setInterval(() => {
      this.getEailVerificationResponse();
      this.state.isEmailVerified && clearInterval(timerId);
    }, 5000);

    setTimeout(() => {
      clearInterval(timerId);
    }, 300000)
  }
  handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
  
    this.setState({ snackbarOpen: false })
  };
  handleCloseError= (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
  
    this.setState({ snackbarOpenError: false })
  };
  getChatCredentials() {
    const userToken = localStorage.getItem("token");
    console.log(userToken)
    
    const headers = {
      contentType: configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.chatCredentialsApiCalledId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
       configJSON.chatCredentialsAPiEndPoint);

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  handleRegisterQuickbloxUser = async()=>{
    console.log('handle register quickblox user called')
    const params = {
      login: this.state.email,
      password: configJSON.someSecret,
      full_name: this.state.fullName,
      email :this.state.email
    };

    const {application_id,auth_key,auth_secret,accout_key} = this.context.ChatState 
    
    QB.init(application_id,auth_key , auth_secret, accout_key) 
    await  QB.createSession((error: any, result: any) => {
        console.log('session result',result)
        QB.users.create(params, function(error:any, result:any) {
          if(!error){
            console.log('user',result)
          }
          else{
            console.log(JSON.stringify(error))
          }
        });
        })
     
  
  }

  // Customizable Area End
}
