export const finLogo = require("../assets/finLogo.png");
export const DownloadIcon = require("../assets/DownloadIcon.png");
export const credCo = require("../assets/9ff986ca79666f3e60a6ba2999cddce9b72f56cb.png");
export const abayo = require("../assets/185ad0029fa0ad110adc53c72c30e3334c62338e.png");
export const pozo = require("../assets/62cbe3d43121196a885076c5ed3fcf4998c2af2e.png");
export const download_icon = require("../assets/15ebafb1808e318db532cc5b1b5e8cce1075ecaf.png");
export const accordian = require("../assets/072d47a0721c9e640dddf3b7205fba976fc1658f.png");
export const filter = require("../assets/3345dbce254e942b548ce4d74e14014a6e11dee0.png");
export const tick_icon = require("../assets/tick_icon.png");
export const verified_tick_image = require("../assets/verified_tick_image.png");
export const Logo = require("../assets/logo.png");
export const back = require("../assets/back.png");
export const active = require("../assets/active.png");
export const inactive = require("../assets/inactive.png");
export const management_profile_demo = require("../assets/management profile demo.png");