import React, { Children } from "react";
import {
  Card,
  CardContent,
  Box,
  Typography,
} from "@material-ui/core";

type Props = {
  title: string;
  description: string;
};


const ProductCard: React.FC<Props> = ({ title, description }) => {

  return (
    <Card className="productCard_card" elevation={0}>
      <CardContent>
        <Box component="div" className="productCard_card_titleContainer">
          <Typography className="productCard_card_attrbuted_textlabel_FinmartTradeText" >
            {title}
          </Typography>
        </Box>
        <Box mt={2}>
          <Typography className="productCard_card_textlabel_ExploreWideRangeOText">
            {description}
          </Typography>
        </Box>
      </CardContent>
    </Card>
  );
};

export default ProductCard;
