
import { BlockComponent } from "../../../framework/src/BlockComponent";
 // Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
    uploadCopy: (e: any) => void;
    uploadCopyError: boolean;
    removeCopy: () => void;
    copyName: string;
    editPersonalDetails: boolean
    dpIdName: string;
    dpIdValue: string;
    handleChangeDematDetails: (e: any) => void;
    isDPIdError: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
      
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class UploadResumeController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.state={
        
    };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
