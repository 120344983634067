// Customizable Area Start
import React from "react";

import {
  Box,
  Input,
  Button,
  InputLabel,
  Typography
} from "@material-ui/core";
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
const configJSON = require("./config");

import PanNumberController, {
  Props,
} from "./PanNumberController";

class PanNumber extends PanNumberController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
      <Box className="pan_box">
        <InputLabel id="label1" required>PAN</InputLabel>
        <input className="kyc_test" value={this.props.pan} maxLength={10} onChange={this.props.getPanValue} onKeyDown={this.props.handleMinuSign} type="text" placeholder={this.props.placeholder}
        />
        {this.props.errorPanNumber && <InputLabel id="error_label">Please Enter Valid Pan Value</InputLabel>}
    </Box>
    {
                          this.props.panCardName ?
                            <Box className="kyc_arranger_upload_box2">
                              <Typography id="upload_label_kyc"><a href={this.props.panCard}>{this.props.panCardName}</a></Typography>
                              <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={() => { this.props?.removeUploadFile() }}>X</Typography>
                            </Box>
                            :
                            <Box className="kyc_arranger_upload_box">
                              <Button
                                component="label"
                                fullWidth
                                style={{ textTransform: 'none' }}
                              >
                                <Input
                                  type="file"
                                  hidden
                                  id={this.props.id}
                                  onChange={this.props.uploadFile}
                                  inputProps={{
                                    accept: ".png, .jpg, .jpeg"
                                  }}
                                  disableUnderline={true}
                                  style={{ visibility: 'hidden', display: 'none', height: '25vh' }}
                                />
                                <Box className="cloud_upload_icon_box">
                                  {
                                    !this.props.uploadDetail &&
                                    <InputLabel htmlFor={this.props.id} style={{ color: 'black' }} >
                                      <CloudUploadIcon />
                                    </InputLabel>
                                  }
                                  {
                                    this.props?.file?.name ?
                                      <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'space-between', justifyContent: 'space-between', columnGap: '1%' }}>
                                        <Typography id="upload_label_kyc">{this.props?.file?.name}</Typography>
                                        <Typography id="upload_label_kyc" style={{ cursor: 'pointer', color: 'black', paddingRight: '1%' }} onClick={(e) => { this.props?.removeUploadFile(); e.preventDefault() }}>X</Typography>
                                      </Box>
                                      :
                                      <InputLabel id="label">{configJSON.kycUploadLabel}</InputLabel>
                                  }
                                  {this.props.errorUploadPanPhoto && <InputLabel id="error_label">Upload Only Image File</InputLabel>}
                                </Box>
                              </Button>
                            </Box>
                        }
      </>
    );
  }
}
export default PanNumber;
// Customizable Area End
