// Customizable Area Start
import React from "react";
import {CustomInputLabel , webStyles} from "./Profile.web";
import {CustomInputField} from '../../../components/./src/./components/./CustomInputField';
import UploadResumeController from "./UploadResumeController";
import {
    Typography,
    Grid
} from "@material-ui/core";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';

export default class UploadResume extends UploadResumeController{
    constructor(props : any){
        super(props);
    }
    render(){
        return(
            <>
            <Grid item lg={12} sm={12} md={12} xs={12}>
        <CustomInputLabel
            htmlFor="component-simple"
            style={webStyles.marginGrid}
            required
        >
            Demat CMR Copy
        </CustomInputLabel> 
        <div className="upload_file_fileInput">
            <input
                type="file"
                id="customInput2"
                placeholder="Upload your Resume"
                accept="application/pdf"
                disabled={!this.props.editPersonalDetails}
                hidden
                style = {{textOverflow : 'ellipsis' , overflow :'hidden' , whiteSpace : 'nowrap'}}
                onChange={(event: React.ChangeEvent<any>) => {
                    this.props?.uploadCopy(event)
                }
                }
            />
            <label
                className="label_upload_cmr_copy"
                htmlFor="customInput2"
                style={{display : 'flex' , flexDirection : 'row' , columnGap : '1%' ,textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
            >
                {
                    this.props.uploadCopyError ?
                        <>
                            <Typography className="placeholder_text_cmr_copy" style={{ color: 'red' }}>
                                Upload PDF File Only
                            </Typography>
                            <p style={{ marginRight: '10px' ,textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}} onClick={(e: any) => { this.props.editPersonalDetails && this.props?.removeCopy(), e.preventDefault() }}>X</p>
                        </>
                        :
                        this.props.copyName ?
                            (
                                <>
                                    <Typography
                                        className="upload_text_cmr_copy"
                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                    >
                                        {
                                            this.props.copyName
                                        }
                                    </Typography>
                                    <span
                                        className="company_upload_lebel"
                                        onClick={(e: any) => { this.props.editPersonalDetails && this.props?.removeCopy(), e.preventDefault() }}
                                    >
                                        <p style={{ marginRight: '10px' , marginLeft : '1%'}}>X</p>
                                    </span>
                                </>
                            )
                            :
                            (
                                <>
                                    <Typography
                                        className="placeholder_text_cmr_copy"
                                    >
                                        Demat CMR Copy
                                    </Typography>
                                    <AddCircleOutlineRoundedIcon
                                        className="add_circle_icon"
                                    />
                                </>
                            )
                }
            </label>
        </div>
    </Grid>
    <Grid item lg={12} sm={12} md={12} xs={12}>
    <CustomInputLabel
        htmlFor="component-simple"
        style={webStyles.marginGrid}
        required
    >
        DP ID
    </CustomInputLabel>
    <CustomInputField
        id="outlined-basic"
        placeholder="DP ID"
        variant="outlined"
        name={this.props.dpIdName}
        type={'number'}
        inputProps={{
            maxLength: 8
        }}
        value={this.props.dpIdValue}
        onChange={this.props?.handleChangeDematDetails}
        disabled={!this.props.editPersonalDetails}
    />
    {
        this.props.isDPIdError && (
            <div className="error_message">
                Please Enter Valid DP ID
            </div>
        )
    }
</Grid>
    </>
        );
    }
}
// Customizable Area End