import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { callApi,Role_name, checkForReadMode } from "../../../components/src/common";
import { GlobalContext } from "./useContext";
const QuickBlox = require('quickblox/quickblox.min').QuickBlox;
const QB = new QuickBlox();
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  match: any;
  location: any;
  history: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  uploadFinSummary: any;
  isEdited: boolean;
  Faqs: any[];
  role_name: string;
  faqId: string;
  newFaq: any;
  isReadMode: boolean;
  groupchatDialogId:any,
  arrangersEmailIds:any;
  seekerEmailId:string;
  arrangersQuickbloxIds:any;
  showAlert:boolean;
  showLoading:boolean;
  redirect:boolean;
  userEmail:any
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {


  getProductApiCallId: any;
  // Customizable Area Start
  static contextType = GlobalContext
  getFaqsApiCallId: string = "";
  createFaqAPICallId: string = "";
  getLoggedUserInformation: string = "";
  deleteFaqId: string = "";
  chatCredentialsApiCalledId='';
  chatCredentials:any='';
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.redirectMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      uploadFinSummary: "",
      isEdited: false,
      Faqs: [],
      role_name: "",
      faqId: "",
      isReadMode: false,
      newFaq: {
        attributes: {
          question: '',
          answer: ''
        }
      },
      groupchatDialogId:'',
      arrangersEmailIds:[],
      seekerEmailId:'',
      arrangersQuickbloxIds:[],
      showAlert:true,
      showLoading:false,
      redirect:false,
      userEmail:''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    // Customizable Area Start
    this.getFaqList()
    this.getLoggedUserInfo()
    const isReadMode = checkForReadMode(this.props.location);
    this.setState({ isReadMode: isReadMode })
   
   
    // Customizable Area End
  }
  
  async receive(from: string, message: Message) {
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      if (this.state.redirect && customRedirect) {
        if (this.state.role_name === Role_name.Admin) {
          window.close();
        } else if (this.state.role_name === Role_name.Seeker) {
          window.location.href = "/PrivacySettings";
        } else if (this.state.role_name === Role_name.Arranger) {
          window.location.href = "/ContentManagement/DealsDashboard";
        }
      }
    }
    if (responseJson) {
      if (responseJson.errors) {
        let res8 = responseJson.errors
          .map((i: any) => Object.keys(i) + " " + Object.values(i))
          .join("\n");
        const msg008: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg008.addData(getName(MessageEnum.CustomAlertBodyData), res8);
        this.send(msg008);
      } else {
        if (apiRequestCallId === this.deleteFaqId) {
          const msg69: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg69.addData(getName(MessageEnum.CustomAlertBodyData), 'Deleted');
          this.send(msg69);
        }
        if (apiRequestCallId === this.getLoggedUserInformation) {
          this.setState({ role_name: responseJson?.data?.attributes?.role_name })
        }
        
        if (apiRequestCallId === this.getFaqsApiCallId) {
          this.setState({ Faqs: responseJson.data, faqId: responseJson.data[0]?.id,
            groupchatDialogId:responseJson.meta.groupchat_dialogid_beforeeoi,
            seekerEmailId:responseJson.meta.issuer_email,userEmail:responseJson.meta.user_email,
          arrangersEmailIds:responseJson.meta.arranger_emails },()=>{
            
            if(!this.state.groupchatDialogId){
              this.chatCredentials= callApi({method:'GET',endPoint:configJSON.chatCredentialsAPiEndPoint,payload:''})
            }
          });
        }


        if (apiRequestCallId === this.createFaqAPICallId) {
          
          this.setState({showLoading:false})
          /* istanbul ignore next */
          if (responseJson?.error) {
            let res = responseJson?.error[0]
            const msg70: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg70.addData(getName(MessageEnum.CustomAlertBodyData), res);
            this.send(msg70);

          } else if(this.state.isEdited) {
              const msg71: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg71.addData(getName(MessageEnum.CustomAlertBodyData), 'Captured the Questions and Answers successfully!');
              this.send(msg71);
              this.setState({ redirect :true})
            }else{
                if (this.state.role_name === Role_name.Admin) {
                  window.close();
                } else if (this.state.role_name === Role_name.Seeker) {
                  window.location.href = "/PrivacySettings";
                } else if (this.state.role_name === Role_name.Arranger) {
                  window.location.href = "/ContentManagement/DealsDashboard";
                }
            }
           }

        if(this.chatCredentials===apiRequestCallId){
          this.context.ChatDispatch({ type: 'CHAT_DATA', payload:responseJson.data[0].attributes  })
          
        }
        
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start

  handleAddInputField = () => {
    let Obj = {
      attributes: {
        question: '',
        answer: ''
      }
    }
    this.state.Faqs.push(Obj);
    this.setState({
      Faqs: this.state.Faqs,
      newFaq: this.state.Faqs
    })
  };
  removeInputField = (index: number, id: any) => {
    id ? this.deleteFaq(id) : null;
    this.state.Faqs.splice(index, 1);
    this.setState({
      Faqs: this.state.Faqs,
      newFaq: this.state.Faqs,
      isEdited: true,
    })
  };



  changeFAQData = (e: any, index: number) => {
    const regex = /^[a-z0-9A-Z.][a-z0-9A-Z.? ]*$/
    let question=e.target.value    
    if (regex.test(question) || question == '') {
      this.state.Faqs[index].attributes.question = question;
      this.setState({ Faqs: this.state.Faqs, newFaq: this.state.Faqs, isEdited: true });
    }
  }

  changeFAQAnswer = (e: any, index: number) => {
    const regex =/^[a-z0-9A-Z.][a-z0-9A-Z.,;!@#$%&*-+='"\n ]*$/
    let answer=e.target.value
    if (regex.test(answer) || answer == '') {
      this.state.Faqs[index].attributes.answer = answer;
      this.setState({ Faqs: this.state.Faqs, newFaq: this.state.Faqs, isEdited: true });
    }

  }

  getFaqList = () => {
    let deal_id = localStorage.getItem("deal_id")
    this.getFaqsApiCallId = callApi({
      method: "GET",
      endPoint: `bx_block_faq/faqs?debt_deal_id=${deal_id}`,
    })
  }

  

  createFaqs = async() => {
    console.log('create faq api call')
    this.setState({showLoading:true})
    
    let groupchatDialogId
    if(!this.state.groupchatDialogId){
      groupchatDialogId =await this.createGroupChatDialog()
    }

    console.log('create faqs api call')
    
    let deal_id = localStorage.getItem("deal_id")
    

    const header = {
      "Content-Type": configJSON.companyOverviewApiContentType,
      token: `${localStorage.getItem("token")}`
    };

    const httpBody = {
      data: this.state.isEdited ? this.state.newFaq : this.state.Faqs,
      groupchat_dialogid_beforeeoi:groupchatDialogId
      
    }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createFaqAPICallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `bx_block_faq/faqs/${this.state.faqId}?debt_deal_id=${deal_id}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  deleteFaq = (id: any) => {
    this.deleteFaqId = callApi({
      method: "DELETE",
      endPoint: `bx_block_faq/faqs/${id}`,
    })
  }

  getLoggedUserInfo = () => {
    this.getLoggedUserInformation = callApi({
      method: "GET",
      endPoint: 'account_block/accounts',
    })
  }
  

  params = {
    email: localStorage.getItem('email'),
    password: configJSON.someSecret,
  };
  
  
  
  
  createGroupChatDialog= ()=>{

    return new Promise((resolve,reject)=>{
      
    const params = {
      type: 1,
      name: "groupchat testing"
      
    };
    const {application_id,auth_key,auth_secret,accout_key} = this.context.ChatState 
    
          QB.init(application_id,auth_key , auth_secret, accout_key);
           QB.createSession((error: any, result: any) => {
            QB.users.create({email:this.state.userEmail,password:configJSON.someSecret},(error:any,result:any)=>{
              QB.login({email:this.state.userEmail,password:configJSON.someSecret}, (error: any, result: any) => {
          
                localStorage.setItem('id', result.id)
      
                QB?.chat?.dialog?.create(params, (error:any, dialog:any)=> {
                  if(!error){
                    resolve(dialog?._id)
                      
                  }
                  else{
                    resolve('')
                    
                  }
                })
              })
            })
    })
    })
  }
  
  
  
  

  // Customizable Area End
}
