// App.js - WEB
import React, { Component } from 'react';
import { View } from 'react-native';
import firebase from 'firebase';
import { connect } from 'react-firebase';

import WebRoutesGenerator from "../../components/src/NativeWebRouteWrapper";
import { ModalContainer } from "react-router-modal";
import HomeScreen from "../../components/src/HomeScreen";
import TopNav from "../../components/src/TopNav";

import InfoPage from '../../blocks/info-page/src/InfoPageBlock';
import AlertBlock from '../../blocks/alert/src/AlertBlock.web';
import VisualAnalytics from '../../blocks/visualanalytics/src/VisualAnalytics';
import SocialMediaAccountLoginScreen from '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen';
import CfYieldCalculator from '../../blocks/CfYieldCalculator/src/CfYieldCalculator';
import MerchantDashboard2 from '../../blocks/MerchantDashboard2/src/MerchantDashboard2';
import CfLedgerAccount4 from '../../blocks/CfLedgerAccount4/src/CfLedgerAccount4';
import DataImportexportcsv from '../../blocks/DataImportexportcsv/src/DataImportexportcsv';
import MobileAccountLoginBlock from '../../blocks/mobile-account-login/src/MobileAccountLoginBlock';
import OTPInputAuth from '../../blocks/otp-input-confirmation/src/OTPInputAuth';
import ChatBackuprestore from '../../blocks/ChatBackuprestore/src/ChatBackuprestore';
import RolesPermissions2 from '../../blocks/RolesPermissions2/src/RolesPermissions2';
import CfEmailIntegration5 from '../../blocks/CfEmailIntegration5/src/CfEmailIntegration5';
import RequestManagement from '../../blocks/RequestManagement/src/RequestManagement';
import Customform from '../../blocks/customform/src/Customform';
import Pushnotifications from '../../blocks/pushnotifications/src/Pushnotifications';
import ForgotPassword from '../../blocks/forgot-password/src/ForgotPassword';
import ForgotPasswordOTP from '../../blocks/forgot-password/src/ForgotPasswordOTP';
import NewPassword from '../../blocks/forgot-password/src/NewPassword';
import Notifications from '../../blocks/notifications/src/Notifications';
import Filteritems from '../../blocks/filteritems/src/Filteritems';
import Filteroptions from '../../blocks/filteritems/src/Filteroptions';
import AdminConsole3 from '../../blocks/AdminConsole3/src/AdminConsole3';
import BulkUploading from '../../blocks/BulkUploading/src/BulkUploading';
import Scheduling from '../../blocks/scheduling/src/Scheduling';
import ProductDescription from '../../blocks/ProductDescription/src/ProductDescription';
import Categoriessubcategories from '../../blocks/categoriessubcategories/src/Categoriessubcategories';
import DigitalSignatures from '../../blocks/DigitalSignatures/src/DigitalSignatures';
import ContentManagement from '../../blocks/ContentManagement/src/ContentManagement.web';
import CountryCodeSelector from '../../blocks/country-code-selector/src/CountryCodeSelector';
import ReviewAndApproval from '../../blocks/ReviewAndApproval/src/ReviewAndApproval';
import DownloadPermissions from '../../blocks/DownloadPermissions/src/DownloadPermissions';
import PhoneNumberInput from '../../blocks/mobile-account-registration/src/PhoneNumberInput';
import AdditionalDetailForm from '../../blocks/mobile-account-registration/src/AdditionalDetailForm';
import QuoteManagement from '../../blocks/QuoteManagement/src/QuoteManagement';
import SocialMediaAccountRegistrationScreen from '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen';
import Sorting from '../../blocks/sorting/src/Sorting';
import Catalogue from '../../blocks/catalogue/src/Catalogue';
import Chat9 from '../../blocks/Chat9/src/Chat9';
import CfDealLifecycleManagement from '../../blocks/CfDealLifecycleManagement/src/CfDealLifecycleManagement';
import EmailAccountRegistration from '../../blocks/email-account-registration/src/EmailAccountRegistration';
import EmailAccountLoginBlock from '../../blocks/email-account-login/src/EmailAccountLoginBlock.web';
import TimeTracker from '../../blocks/TimeTracker/src/TimeTracker';
import LandingPage from '../../blocks/landingpage/src/LandingPage2.web';
import TermAndCondition from '../../components/src/components/TermAndCondition.web';
import PrivacyPolicy from '../../components/src/components/PrivacyPolicy.web';
import AboutFinmart from '../../components/src/components/AboutFinmart.web';
import Team from '../../components/src/components/Team.web';
import Advisors from '../../components/src/components/Advisors.web';
import Careers from '../../components/src/components/Careers.web';
import Blog from '../../components/src/components/Blog.web';
import PhotoLibrary3 from '../../blocks/PhotoLibrary3/src/PhotoLibrary3';
import AdvancedSearch from '../../blocks/AdvancedSearch/src/AdvancedSearch';
import CfEkycVerification3 from '../../blocks/CfEkycVerification3/src/CfEkycVerification3';
import EmailNotifications from '../../blocks/EmailNotifications/src/EmailNotifications';
import Favourites from '../../blocks/favourites/src/Favourites';
import AddFavourites from '../../blocks/favourites/src/AddFavourites';
import PrivacySettings from '../../blocks/PrivacySettings/src/InvestorVisibilitySettings.web';
import GroupChat from '../../blocks/GroupChat/src/GroupChat';
import Interactivefaqs from '../../blocks/interactivefaqs/src/Interactivefaqs';
import AddInteractivefaqs from '../../blocks/interactivefaqs/src/AddInteractivefaqs';
import FormApprovalWorkflow from '../../blocks/FormApprovalWorkflow/src/FormApprovalWorkflow';
import AuditTrail from '../../blocks/AuditTrail/src/AuditTrail';
import UserProfileBasicBlock from '../../blocks/user-profile-basic/src/UserProfileBasicBlock';
import DealDiscovery from '../../blocks/catalogue/src/DealDiscovery';
import CustomAlert from '../../blocks/catalogue/src/CustomAlert.web';
import DealDetails from '../../blocks/catalogue/src/DealDetails.web';
import SeekerArrangerDealDetails from '../../blocks/catalogue/src/SeekerArrangerDealDetails.web';
import Profile from "../../blocks/user-profile-basic/src/Profile.web";
import ChangePassword from "../../blocks/user-profile-basic/src/ChangePassword.web";
import Decision from "../../blocks/ContentManagement/src/Decision.web";
import GlobalProvider from "../../blocks/catalogue/src/useContext";
import ReviewDeal from '../../blocks/catalogue/src/ReviewDeal.web'

const routeMap = {
  Favourites: {
    component: Favourites,
    path: '/Favourites'
  },
  AddFavourites: {
    component: AddFavourites,
    path: '/AddFavourites'
  },
  PrivacySettings: {
    component: PrivacySettings,
    path: '/PrivacySettings'
  },
  GroupChat: {
    component: GroupChat,
    path: '/GroupChat'
  },
  Interactivefaqs: {
    component: Interactivefaqs,
    path: '/Interactivefaqs'
  },
  AddInteractivefaqs: {
    component: AddInteractivefaqs,
    path: '/AddInteractivefaqs'
  },
  FormApprovalWorkflow: {
    component: FormApprovalWorkflow,
    path: '/FormApprovalWorkflow'
  },
  AuditTrail: {
    component: AuditTrail,
    path: '/AuditTrail'
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock'
  },

  VisualAnalytics: {
    component: VisualAnalytics,
    path: '/VisualAnalytics'
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen'
  },
  CfYieldCalculator: {
    component: CfYieldCalculator,
    path: '/CfYieldCalculator'
  },
  MerchantDashboard2: {
    component: MerchantDashboard2,
    path: '/MerchantDashboard2'
  },
  CfLedgerAccount4: {
    component: CfLedgerAccount4,
    path: '/CfLedgerAccount4'
  },
  DataImportexportcsv: {
    component: DataImportexportcsv,
    path: '/DataImportexportcsv'
  },
  MobileAccountLoginBlock: {
    component: MobileAccountLoginBlock,
    path: '/MobileAccountLoginBlock'
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth'
  },
  ChatBackuprestore: {
    component: ChatBackuprestore,
    path: '/ChatBackuprestore'
  },
  RolesPermissions2: {
    component: RolesPermissions2,
    path: '/RolesPermissions2'
  },
  CfEmailIntegration5: {
    component: CfEmailIntegration5,
    path: '/CfEmailIntegration5'
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement'
  },
  Customform: {
    component: Customform,
    path: '/Customform'
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications'
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword'
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP'
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword'
  },
  Notifications: {
    component: Notifications,
    path: '/Notifications'
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems'
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions'
  },
  AdminConsole3: {
    component: AdminConsole3,
    path: '/AdminConsole3'
  },
  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading'
  },
  Scheduling: {
    component: Scheduling,
    path: '/Scheduling'
  },
  ProductDescription: {
    component: ProductDescription,
    path: '/ProductDescription'
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories'
  },
  DigitalSignatures: {
    component: DigitalSignatures,
    path: '/DigitalSignatures'
  },
  ContentManagement: {
    component: ContentManagement,
    path: '/ContentManagement/:param'
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector'
  },
  ReviewAndApproval: {
    component: ReviewAndApproval,
    path: '/ReviewAndApproval'
  },
  DownloadPermissions: {
    component: DownloadPermissions,
    path: '/DownloadPermissions'
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput'
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm'
  },
  QuoteManagement: {
    component: QuoteManagement,
    path: '/QuoteManagement'
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen'
  },
  Sorting: {
    component: Sorting,
    path: '/Sorting'
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue'
  },
  DealDiscovery: {
    component: DealDiscovery,
    path: '/DealDiscovery'
  },
  DealDetails: {
    component: DealDetails,
    path: '/DealDetails'
  },
  Chat9: {
    component: Chat9,
    path: '/Chat9'
  },
  CfDealLifecycleManagement: {
    component: CfDealLifecycleManagement,
    path: '/CfDealLifecycleManagement'
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration'
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock'
  },
  TimeTracker: {
    component: TimeTracker,
    path: '/TimeTracker'
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage'
  },

  TermAndCondition: {
    component: TermAndCondition,
    path: '/TermAndCondition'
  },

  PrivacyPolicy: {
    component: PrivacyPolicy,
    path: '/PrivacyPolicy'
  },
  AboutFinmart: {
    component: AboutFinmart,
    path: '/AboutFinmart'
  },
  Team: {
    component: Team,
    path: '/Team'
  },
  Advisors: {
    component: Advisors,
    path: '/Advisors'
  },
  Careers: {
    component: Careers,
    path: '/Careers'
  },
  Blog: {
    component: Blog,
    path: '/Blog'
  },
  PhotoLibrary3: {
    component: PhotoLibrary3,
    path: '/PhotoLibrary3'
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch'
  },
  CfEkycVerification3: {
    component: CfEkycVerification3,
    path: '/CfEkycVerification3'
  },
  EmailNotifications: {
    component: EmailNotifications,
    path: '/EmailNotifications'
  },

  LandingPage: {
    component: LandingPage,
    path: '/',
    exact: true
  },
  InfoPage: {
    component: InfoPage,
    path: '/InfoPage'
  },

  AlertWeb: {
    component: AlertBlock,
    path: '*/AlertWeb',
    modal: true
  },
  Profile: {
    component: Profile,
    path: "/Profile",
  },
  ChangePassword: {
    component: ChangePassword,
    path: "/ChangePassword"
  },
  SeekerArrangerDealDetail: {
    component: SeekerArrangerDealDetails,
    path: "/SeekerArrangerDealDetails/:id"
  },
  Decision:{
    component: Decision,
    path:"/Decision/:id"
  },
  ReviewDeal:{
    component: ReviewDeal,
    path:"/ReviewDeal/:id"
  }
};

const firebaseAPI = firebase.initializeApp({
  apiKey: 'AIzaSyDgl9aTbKMdRZ9-ijSZRionh3V591gMJl4',
  authDomain: 'rnmasterapp-c11e9.firebaseapp.com',
  databaseURL: 'https://rnmasterapp-c11e9.firebaseio.com',
  projectId: 'rnmasterapp-c11e9',
  storageBucket: 'rnmasterapp-c11e9.appspot.com',
  messagingSenderId: '649592030497',
  appId: '1:649592030497:web:7728bee3f2baef208daa60',
  measurementId: 'G-FYBCF3Z2W3'
});

class App extends Component {
  render() {
    const defaultAnalytics = firebaseAPI.analytics();
    defaultAnalytics.logEvent('APP_Loaded');

    return (
      <GlobalProvider>
      <View style={{ height: '100vh' }}>
        <TopNav />
        {WebRoutesGenerator({ routeMap })}
        <ModalContainer />
      </View>
      <CustomAlert/>
      </GlobalProvider>
    );
  }
}

export default App;
