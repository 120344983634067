// Customizable Area Start
import React, { createContext, useReducer } from 'react';
import OtpReducer from './Reducer';
import { otpToMakeAnOffer, blogsData,carrerCityData,aboutUsStateData,chatCredentials } from './GlobalProvider'
export const GlobalContext: any = createContext({});
const GlobalProvider = ({ children }: any) => {

    const [OtpState, OtpDispatch] = useReducer(OtpReducer, otpToMakeAnOffer)
    const [BlogsState, BlogsDispatch] = useReducer(OtpReducer, blogsData)
    const [CarrerCityState, CarrerCityDispatch] = useReducer(OtpReducer, carrerCityData)
    const [AboutUsState, AboutUsStateDispatch] = useReducer(OtpReducer, aboutUsStateData)
    const [ChatState, ChatDispatch] = useReducer(OtpReducer, chatCredentials)
    return (
        <GlobalContext.Provider value={{
            OtpDispatch,
            OtpState,
            BlogsState,
            BlogsDispatch,
            CarrerCityState,
            CarrerCityDispatch,
            AboutUsState,
            AboutUsStateDispatch,
            ChatState,
            ChatDispatch

        }}>
            {children}
        </GlobalContext.Provider>
    )
}

export default GlobalProvider;
 // Customizable Area End
