// Customizable Area Start
import React from 'react'
import "./style.css";
import { Typography, Box, Grid, InputLabel, OutlinedInput, FormHelperText } from "@material-ui/core";
import ProjectDetailController, { Props } from "./ProjectDetailsController.web";
import PriorityHighIcon from '@material-ui/icons/PriorityHigh';
import { tick_icon } from "./assets";
import { withRouter } from "react-router-dom";
import { checkForReadMode, handleReviewSubmitClick } from '../../../components/src/common';
import '../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { Editor } from "react-draft-wysiwyg";
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';

class ProjectDetails extends ProjectDetailController {
    constructor(props: Props) {
        super(props);
    }

    debounce = (callback: any, delay: any) => {
        let timer: any;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay)
        }
    }

    debounceLog = this.debounce(() => this.validateProjectName(), 500)

    handleInputChange = (e: any) => {
        let projectName = e.target.value;
        this.setState({ project_name: projectName, isProject_name_valid: null, isNext: true })
        // this.debounceLog(e.target.value)
    }
    async componentDidMount() {
        const isReadMode = checkForReadMode(this.props.location);
        this.setState({
            isReadMode: isReadMode
        })
        localStorage.getItem("deal_id") && this.getProjectDetails()
    }
    /* istanbul ignore next */
    handleNext = () => {
        if (this.state.project_name === '' && this.state.project_description_show ===''){
            const msg191: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg191.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill all the mandatory fields.');
            this.send(msg191);
        }else{
            localStorage.getItem("deal_id") && localStorage.getItem("projectDetails_id") ? this.update_submit_ProjectDetailsData() : this.submit_projectDetails();
        }
    }

    handleSaveAsDraft() {
        localStorage.getItem("deal_id") && localStorage.getItem("projectDetails_id") ? this.update_saveAsDraft() : this.saveAsDraft();
    }

    render() {
        const { editorState } = this.state;
        return (
            <div style={{ minHeight: "100%" }} >
                <Grid container spacing={3}>
                    <Grid item xs={12} style={{ marginBottom: "40px" }}  >
                        <Typography className='header'>Project Details</Typography>
                    </Grid>
                    <Grid item xs={4} >
                        <InputLabel className='label'>Project Name</InputLabel>
                    </Grid>
                    <Grid item xs={6} >
                        <Box className="projectName_Container">
                            <OutlinedInput
                                disabled={this.state.isReadMode}
                                className='projectName_input'
                                name='project_name'
                                fullWidth
                                value={this.state.project_name}
                                onChange={this.handleInputChange}
                                style={{ borderRadius: "30px", height: "51px" }} />
                            {(this.state.isProject_name_valid !== null && this.state.isProject_name_valid) && this.state.project_name && <img src={tick_icon} alt="" style={{
                                position: "absolute",
                                right: "12px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "48px",
                                color: "#22bb33",


                            }} />}

                            {this.state.isProject_name_valid !== null && !this.state.isProject_name_valid && <PriorityHighIcon style={{
                                position: "absolute",
                                right: "15px",
                                display: "flex",
                                alignItems: "center",
                                borderRadius: "48px",
                                color: "red",

                            }} />
                            }
                        </Box>
                        {
                            this.state.isProject_name_valid !== null && !this.state.isProject_name_valid && <FormHelperText style={{ color: "red", fontSize: "15px" }} >{this.state.errorMessage || "The project name is already taken. Please try another project name."}</FormHelperText>
                        }
                    </Grid>
                    <Grid item xs={2} />
                    <Grid item xs={4} >
                        <InputLabel className='label'>Project Description</InputLabel>
                    </Grid>
                    <Grid item xs={6}>
                        <Editor
                            editorState={editorState}
                            wrapperClassName="demo-wrapper"
                            editorClassName="demo-editor"
                            toolbarClassName="toolbar-class"
                            readOnly={this.state.isReadMode}
                            wrapperStyle={{paddingLeft:'2%',paddingRight:'1%', borderRadius: "32px", border: "1px solid #B2BEB5" }}
                            onEditorStateChange={this.onEditorStateChange}
                        />
                    </Grid>
                    <Grid item xs={4} />
                    <Grid item xs={8} >
                        {!this.state.isReadMode ?
                            <Box style={{ marginTop: '135px' }}>
                                <Box
                                    className="catalog_button_wrapper"
                                >
                                    {/* <InputLabel className="inputLabel" /> */}
                                    <button
                                        onClick={() => this.handleSaveAsDraft()}
                                        className="save_as_draft_button"
                                    >
                                        Save as Draft
                                    </button>
                                    <button className="next_button" onClick={() => this.handleNext()}>
                                        Next
                                    </button>
                                </Box>
                            </Box>
                            : <Box
                                className="project_submit_button_wrapper"
                                style={{ maxWidth: 525 ,marginTop:'135'}}
                            >
                                <button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className='next_button'>Next</button>
                            </Box>}
                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withRouter(ProjectDetails as any) as any;
// Customizable Area End
