import React from "react";
// Customizable Area Start
import { Box, Grid } from "@material-ui/core";
import Chip from '@material-ui/core/Chip';
import moment from "moment";
import { download, isArrayEmpty, showTextWithValidation } from "./common";
import { management_profile_demo } from "../../blocks/catalogue/src/assets";


export function DealDetailsHeader({ dealDetails }: any) {
    return (
        <div >
            <p className="header" style={{ margin: '20px 0px' }}>Overview</p>

            <div className="banner_wrapper">
                <Grid item >
                    <div style={{ width: '209px', height: '261px' }}>
                        <img src={dealDetails?.deal?.data?.attributes?.upload_logo_image} width="209" height="209" alt="" style={{ borderRadius: '8px' }} />
                        <p className="company_name">{dealDetails?.deal?.data?.attributes?.debt_seeker_company_name}</p>
                    </div>
                </Grid>

                <Grid item style={{ width: '70%' }}>
                    <div className="dealDescriptionWrapper">
                        <p className="dealDescription">
                            {dealDetails?.deal?.data?.attributes?.company_background}
                        </p>
                    </div>
                </Grid>
                <Grid item>
                    <video width="475" height="270px" style={{ borderRadius: '10px' }} key={dealDetails?.deal?.data?.attributes?.upload_video} controls>
                        <source src={dealDetails?.deal?.data?.attributes?.upload_video} />
                    </video>
                </Grid>
            </div>
            <Box className="deal_input_wrapper">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="grid_box_130">Type of Deal</p>
                    <p className="grid_box_130">End Use</p>
                    <p className="grid_box_130">Type of Instrument</p>
                    <p className="grid_box_130">Credit Rating</p>
                    <p className="grid_box_130">Yield</p>
                    <p className="grid_box_130">Maturity Date</p>
                    <p className="grid_box_130">Sector</p>
                    <p className="grid_box_130">Total Deal Size</p>
                </div>

                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.type_of_funding)}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.proposed_end_use?.title)}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.proposed_type_of_instrument?.title)}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.rating_details?.data[0]?.attributes?.rating_credit_rating_agency?.title || 'Unrated')}</p>
                    <p className="box_text_deal">{dealDetails?.proposed_instrument?.data?.attributes?.yield ? dealDetails?.proposed_instrument?.data?.attributes?.yield + '% IRR (Gross)' : '-'}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.repayment_maturity_date && moment(dealDetails?.proposed_instrument?.data?.attributes?.repayment_maturity_date).format('MMM DD, YYYY'))}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.proposed_sector?.title)}</p>
                    <p className="box_text_deal">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.total_deal_size) !== '-' ? 'Rs. ' + Number(dealDetails?.proposed_instrument?.data?.attributes?.total_deal_size) + ' Cr' : '-'}</p>
                </div>
            </Box>
        </div>
    )
}

export function DealInput({ dealDetails }: any) {
    return (
        <>
            <Grid item xs={9} className="deal_input_wrapper">
                <Grid container spacing={2}>
                    <Grid item xs={3}><p className="grid_box">Coupon Rate</p></Grid>
                    <Grid item xs={3}><p className="grid_box">Principal Frequency</p></Grid>
                    <Grid item xs={3}><p className="grid_box">Interest Frequency</p></Grid>
                    <Grid item xs={3}><p className="grid_box">Minimum Investment</p></Grid>
                </Grid>
                <Grid container spacing={2} style={{ height: '60px' }}>
                    <Grid item xs={3}><p className="box_text">{dealDetails?.proposed_instrument?.data?.attributes?.coupon_rate ? dealDetails?.proposed_instrument?.data?.attributes?.coupon_rate + "%" : "-"}</p></Grid>
                    <Grid item xs={3}><p className="box_text">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.principal_repayment_frequency?.title)}</p></Grid>
                    <Grid item xs={3}><p className="box_text">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.interest_payment_frequency?.title)}</p></Grid>
                    <Grid item xs={3}><p className="box_text">{dealDetails?.proposed_instrument?.data?.attributes?.minimum_investment_per_investor ? '₹' + ' ' + dealDetails?.proposed_instrument?.data?.attributes?.minimum_investment_per_investor + " Cr" : "-"}</p></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}><p>Call Option Details</p></Grid>
                    <Grid item xs={3}><p>Put Option Details</p></Grid>
                    <Grid item xs={3}><p>Maturity Date</p></Grid>
                    <Grid item xs={3}><p>Remaining Tenor</p></Grid>
                </Grid>
                <Grid container spacing={2}>
                    <Grid item xs={3}><p className="box_text">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.call_oftion_details)} </p></Grid>
                    <Grid item xs={3}><p className="box_text">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.put_option_details)}  </p></Grid>
                    <Grid item xs={3}><p className="box_text">{dealDetails?.proposed_instrument?.data?.attributes?.repayment_maturity_date ? moment(dealDetails?.proposed_instrument?.data?.attributes?.repayment_maturity_date).format('MMM DD, YYYY') : "-"}  </p></Grid>
                    <Grid item xs={3}><p className="box_text">{showTextWithValidation(dealDetails?.proposed_instrument?.data?.attributes?.proposed_total_tenor)}  </p></Grid>
                </Grid>
            </Grid>
        </>
    )
}
export function StructureAndShareHolding({ dealDetails }: any) {
    return (
        <>
            <div>
                <p className="header" style={{ margin: '32px 0 0 0' }}>Structure</p>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={10} >
                        {dealDetails?.structure_supportive_documents?.data?.every((obj: any) => obj.attributes?.remarks === '') ? <p className="structure_paragraph">No Remarks available</p> :
                            dealDetails?.structure_supportive_documents?.data?.map((structure: any, i: any) => (
                                <p key={i} className="structure_paragraph">{structure?.attributes?.remarks}</p>
                            ))}
                    </Grid>

                    <Grid xs={2}>
                        <p className="doc_upload">Documents Uploaded</p>
                        {isArrayEmpty(dealDetails?.structure_supportive_documents?.data) ? <p className="document_upload">No Document Available</p> : dealDetails?.structure_supportive_documents?.data?.map((structure: any, i: any) => (
                            <p key={i} className="document_upload" onClick={() => { download(structure?.attributes?.document?.url, structure?.attributes?.upload_document_name) }}>{i + 1}. {structure?.attributes?.upload_document_name}</p>
                        ))}
                    </Grid>
                </Grid>

            </div>
            <div>
                <p className="header" style={{ margin: '32px 0 0 0' }}>Shareholding</p>
                <p className="shareHolding_asOn">Shareholding as on:</p>
                <p className="as_on_date">{showTextWithValidation(dealDetails?.structures?.data?.attributes?.share_holding_as_on_date_format)}</p>
            </div>
        </>
    )
}
export function SecurityToSupportiveDoc({ dealDetails, download_icon, rows }: any) {
    return (
        <>
            <div>
                <p className="header" style={{ marginBottom: '30px' }} >Security</p>
                <div className="security_element"><p className="security_element_text" >Secured:</p> <p>{dealDetails?.structures?.data?.attributes?.security === 'secured' ? 'Yes' : 'No'}</p></div>
                <div className="security_element"><p className="security_element_text" >Security Cover:</p> <p>{dealDetails?.structures?.data?.attributes?.security_cover ? dealDetails?.structures?.data?.attributes?.security_cover + ' times' : "-"}</p></div>
                <div className="security_element"><pre className="security_element_text" style={{ width: '350px' }}>Security Details: {dealDetails?.structures?.data?.attributes?.security_details.length <= 0 ? '         No data found.' : ''}</pre></div>

            </div>
            <div style={{ width: '84%' }}>
                <Grid container spacing={4}>
                    {dealDetails?.structures?.data?.attributes?.security_details.length > 0 && <Grid item xs={10}>
                        <Box className="deal_input_wrapper" >
                            <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="grid_box_130" >Type of Security</p>
                                <p className="grid_box_130" >Type of Charge</p>
                                <p className="grid_box_130" >Details</p>
                                <p className="grid_box_130" >Value(INR Cr)</p>
                                <p className="grid_box_130" >Remarks</p>
                            </Box>
                            {
                                dealDetails?.structures?.data?.attributes?.security_details?.map((structure: any) => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }}>
                                        <p className="box_text_deal">{showTextWithValidation(structure?.type_of_security)}</p>
                                        <p className="box_text_deal">{showTextWithValidation(structure?.type_of_chage)}</p>
                                        <p className="box_text_deal">{showTextWithValidation(structure?.details)}</p>
                                        <p className="box_text_deal">{showTextWithValidation(structure?.value_inr_core)}</p>
                                        <p className="box_text_deal">{showTextWithValidation(structure?.remarks)}</p>
                                    </div>
                                ))
                            }

                        </Box>
                    </Grid>}

                </Grid>
            </div>
            <div>
                <p className="header" style={{ paddingTop: '2em' }}>Project Details</p>
                <Grid container spacing={4} alignItems="center">
                    <Grid item xs={10}>
                        {
                            dealDetails?.project_detail?.data?.attributes?.project_description ? <div dangerouslySetInnerHTML={{ __html: dealDetails?.project_detail?.data?.attributes?.project_description }} /> : "No data found"
                        }
                    </Grid>
                    <Grid item xs={2}>
                        <p style={{ fontWeight: 700, fontSize: '16px', color: '#001D56', }}>Documents Uploaded</p>
                        {
                            isArrayEmpty(dealDetails?.project_details_supportive_documents?.data) ? <p className="document_upload">No Document Uploaded</p> : dealDetails?.project_details_supportive_documents?.data.map((pDetails: any, i: any) => (

                                <p key={i} className="document_upload" onClick={() => { download(pDetails?.attributes?.document?.url, pDetails?.attributes?.upload_document_name) }}>{i + 1}. {pDetails?.attributes?.upload_document_name}</p>
                            ))
                        }
                    </Grid>
                </Grid>
            </div>
            <div>
                <p className="details_title">Financial Summary</p>
                <p className="doc_upload">Documents Uploaded</p>
                {
                    dealDetails?.financial_summarie?.data?.attributes?.financial_summarie.length > 0 ? dealDetails?.financial_summarie?.data?.attributes?.financial_summarie?.map((data: any, i: any) => (
                        <div className="document_download" key={i} ><p >{i + 1}.{data?.name} </p><img src={download_icon} width="25px" style={{ cursor: 'pointer' }}
                            onClick={() => { download(data?.url, data?.name) }} /></div>
                    )) : 'No Document Uploaded.'
                }

                {rows.length > 0 ? <table className="excel-table">
                    {
                        rows?.map((cols: any) => (
                            <tr>
                                {
                                    cols?.map((j: any) => <td>{j}</td>
                                    )}
                            </tr>
                        ))
                    }
                </table> : ''}

            </div>
            <div>
                <p className="header" style={{ margin: '32px 0 0 0' }}>Team Profile</p>
                <Grid container spacing={4} style={{ marginTop: '24px' }} >
                    <Grid item xs={6}>
                        <p className="header" style={{ fontSize: '22px', margin: '0 0 16px 0' }}>Management Profile</p>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                dealDetails?.management_profile?.data?.length > 0 ? dealDetails?.management_profile?.data?.map((mp: any, i: any) => (
                                    <div className="team_profile_card_wrapper">
                                        <div style={{ width: '108px', height: '176px' }}>
                                            <img src={mp?.attributes?.upload_profile_picture ? mp?.attributes?.upload_profile_picture :management_profile_demo} style={{ borderRadius: '8px' }} height="176" width="108" alt="" />
                                        </div>
                                        <div style={{ marginLeft: "30px" }}>
                                            <p className="header" style={{ margin: '0px 0 0 0' }}>{mp?.attributes?.title_name + '.'} {mp?.attributes?.name}</p>
                                            <Chip label={mp?.attributes?.designation} className="chip_executive" /> <br />
                                            <Chip label={mp?.attributes?.education} />
                                            <div className="team_profile_card_paragraph"><p className="team_profile_card_p">{mp?.attributes?.remarks ? mp?.attributes?.remarks :"No Remarks available"}</p></div>
                                        </div>
                                    </div>
                                )) : 'No data found.'
                            }

                        </div>
                    </Grid>
                    <Grid item xs={6}>
                        <p className="header" style={{ fontSize: '22px', margin: '0 0 16px 0' }}>Director Profile</p>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            {
                                dealDetails?.dir_profiles?.data?.length > 0 ? dealDetails?.dir_profiles?.data?.map((dp: any, i: any) => (
                                    <div className="team_profile_card_wrapper">
                                        <div style={{ width: '108px', height: '176px' }}>
                                            <img src={dp?.attributes?.dir_profile_picture ? dp?.attributes?.dir_profile_picture : management_profile_demo} style={{ borderRadius: '8px' }} height="176" width="108" alt="" />
                                        </div>
                                        <div style={{ marginLeft: "30px" }}>
                                            <p className="header" style={{ margin: '0px 0 0 0' }}>{dp?.attributes?.dir_title_name + '.'} {dp?.attributes?.dir_name}</p>
                                            <Chip label={dp?.attributes?.dir_designation} className="chip_executive" /> <br />
                                            <Chip label={dp?.attributes?.education} />
                                            <div className="team_profile_card_paragraph"><p className="team_profile_card_p">{dp?.attributes?.remarks ? dp?.attributes?.remarks : "No Remarks available"}</p></div>
                                        </div>
                                    </div>
                                )) : 'No data found.'

                            }

                        </div>
                    </Grid>
                </Grid>
            </div>

            <div>
                <p className="header" style={{ margin: '32px 0 0 0' }} >Debt Profile</p>
                {dealDetails?.debt_profiles?.data?.attributes?.outstanding_borrowing_details?.borrowing_profile_as_of_date_format ? <p className="debt_profile_paragraph">Outstanding Borrowings as on date {showTextWithValidation(dealDetails?.debt_profiles?.data?.attributes?.outstanding_borrowing_details?.borrowing_profile_as_of_date_format)}</p> : <p className="debt_profile_paragraph">No outstanding borrowing.</p>}
                <Grid item style={{ width: '807px' }}>
                    {dealDetails?.debt_profiles?.data?.attributes?.outstanding_borrowing_details?.lenders_details?.data?.length > 0 &&
                        <Box className="deal_input_wrapper">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <p className="grid_box" style={{ width: '150px' }} >Lender</p>
                                <p className="grid_box" style={{ width: '150px' }} >Product Type</p>
                                <p className="grid_box" style={{ width: '150px' }} >Sanctioned (INR Cr)</p>
                                <p className="grid_box" style={{ width: '150px' }} >Outstanding (INR Cr)</p>
                            </div>
                            {
                                dealDetails?.debt_profiles?.data?.attributes?.outstanding_borrowing_details?.lenders_details?.data?.map((data: any, i: any) => (
                                    <div style={{ display: 'flex', justifyContent: 'space-between', fontWeight: 500 }} key={i}>
                                        <p className="box_text_deal" style={{ width: '130px' }}>{data?.attributes?.name} </p>
                                        <p className="box_text_deal" style={{ width: '130px' }}>{data?.attributes?.debt_product_types} </p>
                                        <p className="box_text_deal" style={{ width: '130px' }}>{data?.attributes?.sanctioned_price} </p>
                                        <p className="box_text_deal" style={{ width: '130px' }}>{data?.attributes?.outstanding_price} </p>
                                    </div>
                                ))

                            }
                        </Box>}
                </Grid>
            </div>
            <div>
                <p className="header" style={{ margin: '32px 0 0 0' }}>Supportive Documents</p>

                <Grid container spacing={3} style={{ margin: '24px 0 20px 0' }}>
                    {
                        dealDetails?.supportive_documents?.data?.map((data: any, i: any) => (
                            <Grid item className="supportive_doc" onClick={() => download(data?.attributes?.document?.url, data?.attributes?.upload_document_name)}>
                                <p style={{color:'black'}} key={i}>{data?.attributes?.document_name}</p>
                                <p key={i}>{data?.attributes?.upload_document_name}</p>
                            </Grid>
                        ))
                    }
                </Grid>
            </div>
        </>
    )
}

// Customizable Area End