import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {
  cross,
  imgPasswordInVisible,
  imgPasswordVisible,
  minus,
  rightTick,
  triangle,
} from "./assets";
import {
  apiCall,
  getHeaders,
  isLoggedIn,
} from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  anchorEl: any;
  selectedSettingOption: any;
  dealId: number;
  isLoading: boolean;
  selectedProviders: [];
  providerList: {
    last_name: string;
    first_name: string;
    full_phone_number: string;
    phone_number: number;
    country_code: number;
    email: string;
    device_id?: any;
    activated: boolean;
    unique_auth_id: string;
    created_at: Date;
    password_digest: string;
    updated_at: Date;
    platform?: any;
    user_name?: any;
    user_type?: any;
    last_visit_at?: any;
    app_language_id?: any;
    suspend_until?: any;
    is_blacklisted: boolean;
    status: string;
    stripe_subscription_id?: any;
    stripe_id?: any;
    role_id: number;
    gender?: any;
    full_name: string;
    date_of_birth?: any;
    age?: any;
    company_name: string;
    is_paid: boolean;
    id: number;
    terms_and_conditions: boolean;
    is_age_above_18: boolean;
    approved_by_admin: boolean;
    login_at?: any;
    isSelected: boolean;
    stripe_subscription_date?: any;
  }[];
  selectDebtProviders: boolean;
  isUpdate: boolean;
  redirect: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class PrivacySettingsController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  //@ts-ignore
  authToken: string;getProvidersListApiCallId: string;submitSettingApiCallId: string;getDealVisibilitySettingsApiCallId: string;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.redirectMessage),
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      anchorEl: null,
      selectedSettingOption: this.settingOptions[0],
      providerList: [],
      dealId: 0,
      isLoading: false,
      selectedProviders: [],
      selectDebtProviders: false,
      isUpdate: false,
      redirect: false,
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);

    if (message.id === getName(MessageEnum.AccoutLoginSuccess)) {
      let value = message.getData(getName(MessageEnum.AuthTokenDataMessage));

      this.showAlert(
        "Change Value",
        "From: " + this.state.txtSavedValue + " To: " + value
      );

      this.setState({ txtSavedValue: value });
    }

    // Customizable Area Start

    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      if (this.state.redirect && customRedirect) {
        this.props.navigation.navigate("ContentManagement", {
          param: "DealsDashboard",
        });
      }
    }
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      this.setState({
        isLoading: true,
      });
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      /* istanbul ignore next */
      if (this.getProvidersListApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.provider_list) {
          this.setState({
            providerList: responseJson.provider_list.map((obj: any) => {
              return { ...obj, isSelected: false };
            }),
          });
          this.getDealVisibilityDetail();
        } 
          /* istanbul ignore next */
        else if (responseJson.error) {
          const msg01: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg01.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg01);
        }
          /* istanbul ignore next */
        else if (responseJson.errors) {
          let res16 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0016: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0016.addData(getName(MessageEnum.CustomAlertBodyData), res16);
          this.send(msg0016);
        }
      }
      /* istanbul ignore next */
      if (this.submitSettingApiCallId === apiRequestCallId) {
        if (responseJson.error) {
          const msg2016: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg2016.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg2016);
        } else if (
          responseJson.data &&
          responseJson.meta 
        ) {
          const msg0016: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0016.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg0016);
          this.setState({ redirect :true})
          
        } else {
          const msg1016: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg1016.addData(getName(MessageEnum.CustomAlertBodyData), "Something went wrong");
          this.send(msg1016);
        }
      }

      if (this.getDealVisibilitySettingsApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          
          const settings = responseJson.data.attributes;
          const selectedInvestors = settings.Selected_investors
          this.setState({
            isUpdate: true,
            selectedSettingOption: settings.Show_to_all_investors ? 
                                        this.settingOptions[0] : 
                                          settings.Show_to_Selected_investors 
                                            ? this.settingOptions[1] :
                                              settings.Show_to_Selected_investors_except_for ?
                                                this.settingOptions[2] :
                                                 settings.dont_go_live_as_of_now ? this.settingOptions[3] : this.settingOptions[0],
            providerList: [...this.state.providerList.map((provider)=>{
              const isIncludes = selectedInvestors.find((investor:any) => investor.id === provider.id);
              return {
                ...provider,
                isSelected: isIncludes ? true : false
              }
            })]
          })
        } 
      }
    }

    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start

  async componentDidMount() {
    super.componentDidMount();
    const token = isLoggedIn(this.props.navigation);
    if (token && typeof token === "string") {
      this.authToken = token;
      this.getProvidersList();
    }
    let deal_id = localStorage.getItem("deal_id");
    if (deal_id) {
      this.setState({ dealId: +deal_id });
    }
  }

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleSelectUser = () => {
    this.setState((prevState) => {
      return { selectDebtProviders: !prevState.selectDebtProviders };
    });
  };

  selectSettingHandler = (setting: any) => {
    this.setState((prevState) => ({
      selectedSettingOption: setting,
      selectDebtProviders: false,
      providerList: prevState.providerList.map((obj: any) => {
        return { ...obj, isSelected: false };
      }),
    }));
  };

  settingOptions = [
    {
      id: 1,
      label: "Show to all debt providers",
      icon: triangle,
      subLabel: "visible to all debt providers",
      selectUser: false,
    },
    {
      id: 2,
      label: "Show to selected debt providers",
      icon: rightTick,
      subLabel: "visible to selected debt providers",
      selectUser: true,
    },
    {
      id: 3,
      label: "Show to all debt providers except for",
      icon: minus,
      subLabel: "visible to debt providers except for",
      selectUser: true,
    },
    {
      id: 4,
      label: "Don't go live as of now",
      icon: cross,
      subLabel: "visible to no debt providers",
      selectUser: false,
    },
  ];

  getProvidersList = () => {
    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    const getProviders = apiCall({
      url: configJSON.getProvidersListApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getProvidersListApiMethod,
    });

    this.getProvidersListApiCallId = getProviders.messageId;
    runEngine.sendMessage(getProviders.id, getProviders);
  };
  /* istanbul ignore next */

  validateAndPrepareRequestBody = () => {
    const requestBody: any = {
      data: {
        debt_deal_id: this.state.dealId,
      },
    };

    requestBody.data.Show_to_all_investors = false;
    requestBody.data.Show_to_Selected_investors = false;
    requestBody.data.Show_to_Selected_investors_except_for = false;
    requestBody.data.dont_go_live_as_of_now = false;
    requestBody.data.Selected_investors = "";
    const selectedProvidersList = this.state.providerList
      .filter((provider) => provider.isSelected === true)
      .map((provider) => provider.id);
    if (this.state.selectedSettingOption.id === 1) {
      requestBody.data.Show_to_all_investors = true;
    } else if (this.state.selectedSettingOption.id === 2) {
      if (selectedProvidersList.length === 0) {
        const msg255: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg255.addData(getName(MessageEnum.CustomAlertBodyData), 'Please select Debt Provider');
        this.send(msg255);
        return false;
      } else {
        requestBody.data.Selected_investors = selectedProvidersList.toString();
        requestBody.data.Show_to_Selected_investors = true;
      }
    } else if (this.state.selectedSettingOption.id === 3) {
      if (selectedProvidersList.length === 0) {
        const msg256: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg256.addData(getName(MessageEnum.CustomAlertBodyData), 'Please select Debt Provider');
        this.send(msg256);
        return false;
      } else {
        requestBody.data.Selected_investors = selectedProvidersList.toString();
        requestBody.data.Show_to_Selected_investors_except_for = true;
      }
    } else if (this.state.selectedSettingOption.id === 4) {
      requestBody.data.dont_go_live_as_of_now = true;
    }

    return requestBody;
  };

  submitVisibilitySettings = () => {
    const requestBody = this.validateAndPrepareRequestBody();
    if (!requestBody) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    let url = '';
    if(this.state.isUpdate){
      url = configJSON.updateVisibilitySettingsApiEndpoint;
    } else {
      url = configJSON.submitSettingApiEndpoint + `?deal_id=${this.state.dealId}`;
    }

    const submitSettings = apiCall({
      url:url,
      header,
      httpBody: requestBody,
      httpMethod: this.state.isUpdate ? configJSON.updateVisibilitySettingsApiMethod : configJSON.submitSettingApiMethod,
    });

    this.submitSettingApiCallId = submitSettings.messageId;
    runEngine.sendMessage(submitSettings.id, submitSettings);
  };

  toggleProviderSelection = (id: number) => {
    const prevList = [...this.state.providerList];
    prevList.map((prevProvider) => {
      if (id === prevProvider.id) {
        prevProvider.isSelected = !prevProvider.isSelected;
      }

      return prevProvider;
    });

    this.setState({
      providerList: prevList,
    });
  };

  getDealVisibilityDetail = () => {
    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    const getVisibility = apiCall({
      url: configJSON.getDealVisibilityApiEndpoint + `?debt_deal_id=${this.state.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getDealVisibilityApiMethod,
    });

    this.getDealVisibilitySettingsApiCallId = getVisibility.messageId;
    runEngine.sendMessage(getVisibility.id, getVisibility);
  };
  // Customizable Area End
}
