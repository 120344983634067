import React from 'react'
import { FormControl, FormControlLabel, Checkbox } from '@material-ui/core';


type checkBoxProps = {
    name: string;
    label: string;
    value: boolean;
    onChange: any;

}
export default function CustomCheckbox(props: checkBoxProps) {

    const { name, label, value, onChange } = props;


    const convertToDefEventPara = (name: string, value: boolean) => ({
        target: {
            name, value
        }
    })

    return (
        <FormControl>
            <FormControlLabel
                control={<Checkbox
                    name={name}
                    color="primary"
                    checked={value}
                    onChange={e => onChange(convertToDefEventPara(name, e.target.checked))}
                />}
                label={label}
            />
        </FormControl>
    )
}