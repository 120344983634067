import React from 'react';
// Customizable Area Start
import "./style.css";
import {
    Grid,
    InputLabel,
    Typography,
    Box,
    FormControlLabel,
    Checkbox,
    OutlinedInput,
    FormControl,
    FormHelperText,
    CssBaseline,
    Select,
    IconButton,
    MenuItem
} from "@material-ui/core";
import {
    Close
} from "@material-ui/icons";
import Controls from "../../../components/src/controls/Controls"
import CatalogueController, { Props } from "./DebtProfileController";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import AddBoxIcon from "@material-ui/icons/AddBox";
import { withRouter } from "react-router-dom";
import { checkForReadMode, handleReviewSubmitClick } from '../../../components/src/common';
import { Message } from '../../../framework/src/Message';
import MessageEnum, { getName } from '../../../framework/src/Messages/MessageEnum';


class debtProfileController extends CatalogueController {
    constructor(props: Props) {
        super(props)
    }

    onlyNumberRegex = /^\d+$/;

    handleAddMoreInput(e: any) {
        let debtInputObj = {
            name: "",
            debt_product_types_id: "",
            sanctioned_price: "",
            outstanding_price: "",
            _destroy: false
        }
        this.setState({ debtProfileInputObjArray: [...this.state.debtProfileInputObjArray, debtInputObj] })
    }

    handleAddMoreData(e: any, index: number) {
        if (e.target.name === 'sanctioned_price' || e.target.name === 'outstanding_price') {
            if (this.onlyNumberRegex.test(e.target.value) || e.target.value === '') {
                const updatedDebtInputList = this.state.debtProfileInputObjArray?.map((input, i) => index == i ? Object.assign(input, { [e.target.name]: e.target.value }) : input);
                this.setState({ debtProfileInputObjArray: updatedDebtInputList, isNext: true });
            }
        } else {
            const updatedDebtInputList = this.state.debtProfileInputObjArray?.map((input, i) => index == i ? Object.assign(input, { [e.target.name]: e.target.value }) : input);
            this.setState({ debtProfileInputObjArray: updatedDebtInputList, isNext: true });
        }


    }

    handleRemoveLender(lenderIndex: number) {

        const debtInputList: any = [...this.state.debtProfileInputObjArray];

        debtInputList[lenderIndex]._destroy = true

        this.setState({ debtProfileInputObjArray: debtInputList, isNext: true })

    }

    handleSaveAsDraft() {
        localStorage.getItem("deal_id") && localStorage.getItem("debtProfile_id") ? this.update_saveAsDraft() : this.saveAsDraft();
    }
    /* istanbul ignore next */
    checkFullEmpty=()=>{
        const { borrowing_profile_asOf, isOutStanding_borrowing, debtProfileInputObjArray }=this.state

        const fieldsToExclude = ['id'];

        const areAllFieldsEmpty = (obj:any) => {
        for (const key in obj) {
            if (!fieldsToExclude.includes(key) && obj[key] !== '' && obj[key] !== null && obj[key] !== false) {
            return false;  // At least one non-excluded field is not empty
            }
        }
        return true;  // All non-excluded fields are empty
        };

        const areAllObjectsEmpty = (data:any) => data.every(areAllFieldsEmpty);

        const result = areAllObjectsEmpty(debtProfileInputObjArray);

        if (isOutStanding_borrowing || borrowing_profile_asOf || !result){
            return true
        }else{
            return false
        }

    }
    /* istanbul ignore next */
    handleNext = () => {

       const isPayloadEmpty= this.checkFullEmpty()
        if (isPayloadEmpty) {
            localStorage.getItem("deal_id") && localStorage.getItem("debtProfile_id") ? this.update_submit_debtProfile() : this.submit_debtProfile();

        } else {
            const msg74: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg74.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill all the mandatory fields.');
            this.send(msg74);
        }
    }

    async componentDidMount() {
        const isReadMode = checkForReadMode(this.props.location);
        this.setState({
            isReadMode: isReadMode
        })
        this.getProductTypeList()
        this.setState({ deal_id: `${localStorage.getItem("deal_id")}` })

        let toDay = new Date();
        let dd: any = toDay.getDate();
        let mm: any = toDay.getMonth() + 1;
        let yy = toDay.getFullYear();

        if (mm < 10) {
            mm = `0${mm}`
        }
        if (dd < 10) {
            dd = `0${dd}`
        }
        this.setState({ max_date: `${yy}-${mm}-${dd}` })

        localStorage.getItem("deal_id") && this.getDebtProfile()
    }

    render() {
        return (
            <div >
                {/* <CssBaseline /> */}
                <Grid container alignItems="center" >
                    <CssBaseline />
                    <Grid container alignItems="center" style={{ background: "#fff" }} >
                        <Grid item xs={12} style={{ marginBottom: "24px" }}  >
                            <Typography className='header'>Debt Profile</Typography>
                        </Grid>
                        <Grid item xs={12} />
                        <Grid item xs={12} />
                        <Grid item xs={6} >
                            <InputLabel
                                className='outstanding_borowing_label'
                            >Provide details about your outstanding borrowings</InputLabel>
                        </Grid>
                        <Grid item xs={6} >
                            <Box className='checkbox_and_text_container' >
                                <FormControlLabel control={<Checkbox disabled={this.state.isReadMode} className='debtProfile_checkbox' checked={this.state.isOutStanding_borrowing} onChange={() => this.setState({ isOutStanding_borrowing: !this.state.isOutStanding_borrowing, isNext: true })} />} label={<Typography className='debtProfile_checkbox_label' >Check this box if there is no outstanding borrowing</Typography>} />
                            </Box>
                        </Grid>
                        {
                            !this.state.isOutStanding_borrowing && (<Grid item container spacing={1} className="debtprofile_borowing_profile_container" alignItems="center" >
                                <Grid item xs={3} >
                                    <InputLabel className='label'>Borrowing Profile as of</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <input
                                        disabled={this.state.isReadMode}
                                        style={{ cursor: "pointer", color: "gray" }}
                                        className='catalogue_textInput_date '
                                        type='date'
                                        onKeyDown={(e) => e.preventDefault()}
                                        name="borrowing_profile_asOf"
                                        value={this.state.borrowing_profile_asOf}
                                        max={this.state.max_date}
                                        onChange={(e: any) => this.setState({ borrowing_profile_asOf: e.target.value, isNext: true })}
                                    />
                                </Grid>
                                <Grid item xs={6} />
                                <Grid item xs={12} />
                                <Grid item xs={12} />
                                <Grid item xs={12} />
                                <Grid item xs={12} />

                                <Grid item xs={3} >
                                    <InputLabel
                                        className='debt_profile_innerHeader'
                                    >Lender</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <InputLabel
                                        className='debt_profile_innerHeader'
                                    >Product Type</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <InputLabel
                                        className='debt_profile_innerHeader'
                                    >Sanctioned(INR Crore)</InputLabel>
                                </Grid>
                                <Grid item xs={3} >
                                    <InputLabel
                                        className='debt_profile_innerHeader'
                                    >OutStanding(INR Crore)</InputLabel>
                                </Grid>

                                {
                                    this.state.debtProfileInputObjArray?.map((debtProfileInput, index) => !debtProfileInput._destroy && (
                                        <>
                                            <Grid item xs={3} >
                                                <OutlinedInput
                                                    disabled={this.state.isReadMode}
                                                    className='debtprofile_outlined_input'
                                                    name="name"
                                                    value={debtProfileInput.name}
                                                    onChange={(e: any) => this.handleAddMoreData(e, index)}
                                                    margin='dense'
                                                />
                                            </Grid>
                                            <Grid item xs={3} >
                                                <FormControl
                                                    fullWidth
                                                    variant='outlined'
                                                    size='small'
                                                    style={{
                                                        border: "none",
                                                        outline: "none",
                                                        maxWidth: "263px"
                                                    }}
                                                >
                                                    <Select
                                                        disabled={this.state.isReadMode}
                                                        name="debt_product_types_id"
                                                        disableUnderline
                                                        displayEmpty
                                                        // native
                                                        IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'relative', right: 12, pointerEvents: 'none' }} />}
                                                        value={debtProfileInput.debt_product_types_id?.toString()}
                                                        onChange={(e: any) => this.handleAddMoreData(e, index)}
                                                        MenuProps={{
                                                            PaperProps: {
                                                                style: {
                                                                    borderRadius: "20px",
                                                                }
                                                            }
                                                        }}

                                                        style={{
                                                            borderRadius: "8px",
                                                            background: "#fff",
                                                            height: "34px"
                                                        }}
                                                    >
                                                        {/* <MenuItem className='supportive_document_menuItem' style={{ display: "none", paddingTop: "5px", marginBottom: "10px" }} value="">-Select one-</MenuItem> */}
                                                        <MenuItem style={{ display: "none", color: 'darkgray' }} value={debtProfileInput.debt_product_types_id}> <span style={{ color: "darkgray" }}>Select</span></MenuItem>

                                                        {
                                                            this.state.productList?.map((product, index) => (
                                                                <MenuItem className='supportive_document_menuItem' value={product?.id} key={product?.id} >{product?.attributes?.name} </MenuItem>
                                                            ))
                                                        }


                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                            <Grid item xs={3} >
                                                <OutlinedInput
                                                    disabled={this.state.isReadMode}
                                                    className='debtprofile_outlined_input'
                                                    name="sanctioned_price"
                                                    value={debtProfileInput.sanctioned_price?.toString()}
                                                    onChange={(e: any) => this.handleAddMoreData(e, index)}
                                                    margin='dense'
                                                    type="text"
                                                    inputProps={{
                                                        maxLength: 8
                                                    }}
                                                />

                                            </Grid>
                                            <Grid item xs={2} zeroMinWidth >
                                                <OutlinedInput
                                                    disabled={this.state.isReadMode}
                                                    className='debtprofile_outlined_input'
                                                    name="outstanding_price"
                                                    value={debtProfileInput?.outstanding_price?.toString()}
                                                    onChange={(e: any) => this.handleAddMoreData(e, index)}
                                                    margin='dense'
                                                    type="text"
                                                    inputProps={{
                                                        maxLength: 8
                                                    }}
                                                />

                                            </Grid>

                                            {index > 0 && (
                                                <Grid item xs={1} zeroMinWidth >
                                                    <IconButton disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent' }} onClick={() => this.handleRemoveLender(index)}>
                                                        <Close />
                                                    </IconButton>
                                                </Grid>
                                            )}



                                            <Grid item xs={12} >
                                                {
                                                    debtProfileInput.sanctioned_price=='null' && debtProfileInput.sanctioned_price?.length == 0 && debtProfileInput.outstanding_price?.length != 0 && <FormHelperText style={{ color: "red", textAlign: "center", fontSize: "15px" }} >Sanctioned(INRCrore) amount should not be empty.</FormHelperText>
                                                }
                                            </Grid>
                                        </>
                                    ))
                                }
                            </Grid>)
                        }
                        {
                            !this.state.isOutStanding_borrowing && (<Grid item xs={12} >
                                <button disabled={this.state.isReadMode} className="addRow_button" onClick={(e) => this.handleAddMoreInput(e)}>
                                    Add Lender <AddBoxIcon style={{ marginLeft: "8px" }} />
                                </button>

                            </Grid>
                            )
                        }

                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                            {!this.state.isReadMode ? <Box className="catalog_button_wrapper">
                                <button
                                    className="save_as_draft_button"
                                    onClick={() => this.handleSaveAsDraft()}
                                >Save as Draft</button>

                                <button
                                    onClick={() => this.handleNext()}
                                    className="next_button"
                                >Next</button>
                            </Box> :
                                <Box
                                    className="project_submit_button_wrapper"
                                >
                                    <button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button">Next</button>
                                </Box>

                            }
                        </Grid>


                    </Grid>
                </Grid>
            </div>
        )
    }
}
export default withRouter(debtProfileController) as any;

// Customizable Area End
