import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { apiCall, getHeaders, isAuthorisedUser, isIssuer, isLoggedIn  } from "../../../components/src/common";
// Customizable Area End

export const configJSON = require("./config");

export interface IApproveDealObject{
  id: number;
  deal_debt_arrangers_id: number;
  debt_arranger_companies_id: string;
  issuer_email?: any;
  issuer_company_pan?: any;
  debt_seeker_company_name: string;
  company_background: string;
  review_status: string;
  proposed_instruments?: {
    instrument: string;
    yield: string;
    proposed_total_tenor: string;
    sector: string;
    expected_returns_gross: string;
    total_deal_size: string;
    minimum_investment: string;
    repayment_frequency: string;
    repayment_maturity_date: string;
    rating_details: null | string;
  };
  submitted_by: string;
  time_stamp: string;
  deal_status: string;
  upload_logo_image?: {
    id: number;
    url: string;
  };
  upload_video?: any;
  should_delete: string;
  company_presentation_file?: any;
}

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  match: any;
  location: any;
  // Customizable Area End
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  // Customizable Area Start
  allApproveData:any[];
  allPendingData:any[];
  approveDealSearchDropdown:string;
  innerWidth:any;
  dealsList: null | IApproveDealObject[];
  isLoading: boolean;
  searchString: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class ApproveDealController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  getApproveDealListApiCallId = "";
  authToken = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)
      // Customizable Area End
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      // Customizable Area Start
      allApproveData:[],
      allPendingData:[],
      approveDealSearchDropdown:"Search by deal status",
      innerWidth:'',
      isLoading: false,
      dealsList: null,
      searchString: ''
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }
  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    if (typeof (authToken) === "string") {
      if(!isIssuer()){
        this.props.history.push("/")
      }
      this.authToken = authToken;
      this.getApprovoveDealsList();
    }
    window.addEventListener("resize", this.updateWidth);
  }
  updateWidth = () => {
    this.setState({ innerWidth: window.innerWidth });
  };
  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      
      this.setState({
        isLoading: false
      })

      const authorisedUser = isAuthorisedUser(responseJson);
      if(!authorisedUser){
        this.props.history.push("/EmailAccountLoginBlock")
      }
      /* istanbul ignore next */
      if (this.getApproveDealListApiCallId === apiRequestCallId) {
        if(responseJson.error){
          const msg196: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg196.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg196);
        } else if(responseJson.errors){
          let res11 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0011: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0011.addData(getName(MessageEnum.CustomAlertBodyData), res11);
          this.send(msg0011);
          this.setState({
            dealsList: []
          })
        } else if(responseJson.data){
          this.setState({
            dealsList: responseJson.data.map((deal: any) => deal.attributes)
          })
        } else { 
          const msg00011: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg00011.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg00011);
        }
      }
    }
    // Customizable Area Start
    // Customizable Area End
  }

  txtInputWebProps = {
    onChangeText: (text: string) => {
      this.setState({ txtInputValue: text });
    },
    secureTextEntry: false,
  };

  txtInputMobileProps = {
    ...this.txtInputWebProps,
    autoCompleteType: "email",
    keyboardType: "email-address",
  };

  txtInputProps = this.isPlatformWeb()
    ? this.txtInputWebProps
    : this.txtInputMobileProps;

  btnShowHideProps = {
    onPress: () => {
      this.setState({ enableField: !this.state.enableField });
      this.txtInputProps.secureTextEntry = !this.state.enableField;
      this.btnShowHideImageProps.source = this.txtInputProps.secureTextEntry
        ? imgPasswordVisible
        : imgPasswordInVisible;
    },
  };

  btnShowHideImageProps = {
    source: this.txtInputProps.secureTextEntry
      ? imgPasswordVisible
      : imgPasswordInVisible,
  };

  btnExampleProps = {
    onPress: () => this.doButtonPressed(),
  };

  doButtonPressed() {
    let msg = new Message(getName(MessageEnum.AccoutLoginSuccess));
    msg.addData(
      getName(MessageEnum.AuthTokenDataMessage),
      this.state.txtInputValue
    );
    this.send(msg);
  }

  // web events
  setInputValue = (text: string) => {
    this.setState({ txtInputValue: text });
  };

  setEnableField = () => {
    this.setState({ enableField: !this.state.enableField });
  };

  // Customizable Area Start
  getApprovoveDealsList() {
    this.setState({
      isLoading: true
    })
    const header = getHeaders({ token: this.authToken });

    const getDeals = apiCall({
      url: configJSON.getApproveDealsListApiEndpoint + `?search_params=${this.state.searchString.trim()}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getApproveDealsListApiMethod
    });

    this.getApproveDealListApiCallId = getDeals.messageId;
    runEngine.sendMessage(getDeals.id, getDeals);
  }

  handleApproveDealCardClick = (deal: IApproveDealObject) =>{
    /* istanbul ignore next */
    if(localStorage.getItem("is_kyc_done")=="true"){
      if (deal.review_status === "Pending for approval") {
        localStorage.setItem("deal_id", String(deal.id));
        this.props.history.push("/Catalogue/CompanyOverview?readMode")
      } else {
        this.props.history.push(`/SeekerArrangerDealDetails/${deal?.id}`)
      }
    }else{
      const msg197: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg197.addData(getName(MessageEnum.CustomAlertBodyData), "KYC and Bank Details are not present.");
      this.send(msg197);
    }
  }

  handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    this.setState({ searchString: e.target.value })
  }

  handleSearchKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) =>{
    if (e.key === 'Enter') {
      this.getApprovoveDealsList()
    }
  }
  
  // Customizable Area End
}
