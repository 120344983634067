import React from "react";
// Customizable Area Start
import {
    Box, Button, Grid, TextareaAutosize, Typography
} from "@material-ui/core";
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Modal from '@material-ui/core/Modal';
import Snackbar from "@material-ui/core/Snackbar";
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import Alert from '@material-ui/lab/Alert';
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import OtpInput from "../../../components/src/OtpInput";
import PieChart from "../../../components/src/Piechart";
import AppHeader from "./AppHeader.web";
import { download_icon, verified_tick_image } from "./assets";
import DealDetailsController, { Props } from "./DealDetailsController";
import { DealDetailsHeader, DealInput, SecurityToSupportiveDoc, StructureAndShareHolding } from "../../../components/src/DealDetailsCommonComponent";
import Chat9 from "../../Chat9/src/Chat9.web";

class DealDetails extends DealDetailsController {
    constructor(props: Props) {
        super(props);
    }


    render() {

        const { openModal, dealDetails, openMakeOfferModal, showInterest, kyc } = this.state;
        return (
            <div style={{ overflowX: 'hidden' }}>
                <AppHeader id="" location="" history="" navigation={this.props.navigation} match="" />
                <div style={{ padding: "40px 40px 0 50px", marginTop: "50px" }}>
                    <Box>
                        <Grid container spacing={2}>
                            {
                                !kyc &&
                                <Link to="CfEkycVerification3" style={{ textDecoration: 'none', width: '100%' }}>
                                    <Grid xs={12} >
                                        <p className="pending_kyc">Please complete the pending KYC</p>
                                    </Grid>
                                </Link>
                            }
                        </Grid>
                    </Box>
                    <div className="para_wrapper">
                        <Link to="/DealDiscovery" style={{ textDecoration: 'none' }}>
                            <div className="deal_header_wrapper">
                                <ArrowBackIosIcon />
                                <p className="deal_header">Deal Details</p>
                            </div>
                        </Link>

                    </div>
                    <DealDetailsHeader dealDetails={dealDetails} />
                    <Grid container spacing={2} className="dealDetails_input_header">

                        <DealInput dealDetails={dealDetails} />
                        {
                            (this.state.isInvestor && kyc) ? <Grid item xs={2}>
                                <div className="investment_detail_wrapper">
                                    <p className="investment_detail">Investment Details</p>
                                    <button data-test-id="showInterest" onClick={() => this.setState({ openModal: !openModal })} className={(showInterest || this.state.isShowInterest) ? 'disable_button' : 'deal_button'} style={{ width: '192px' }}>Show Interest </button>
                                    {showInterest && <p className="doc_upload">You have shown interest on {moment(showInterest).format('DD-MMMM-YYYY')}</p>}
                                    {showInterest && <button className="deal_button" style={{ width: '192px' }} onClick={this.handleOpenChatBox}>Chat</button>}
                                    {showInterest && <button data-test-id="showInterest_button" onClick={() => this.setState({ openMakeOfferModal: !openMakeOfferModal })} className="deal_button" style={{ width: '192px', marginTop: '20px' }}>{this.context.OtpState?.make_an_offer ? "Make an offer" : "Offer details"} </button>}
                                    {this.state.open && <Chat9 navigation={''} id={''} dialogId={this.state.groupChatDialogId} handleDialog={this.handleDialog} dealName={dealDetails.project_detail?.data.attributes.project_name}    ></Chat9>}

                                </div>
                            </Grid>
                                :
                                <Grid item xs={2}>
                                    <div className="investment_detail_wrapper">
                                        <p className="investment_detail">Investment Details</p>
                                        <button data-testid="disabledShowInterest" className='disable_button' style={{ width: '192px' }}>Show Interest </button>
                                    </div>
                                </Grid>
                        }

                    </Grid>
                    <StructureAndShareHolding dealDetails={dealDetails} />
                    <PieChart shareHolderType={dealDetails?.structures?.data?.attributes?.share_holdings} pieChartData={this.state.pieChartData} />
                    <SecurityToSupportiveDoc download_icon={download_icon} dealDetails={dealDetails} rows={this.state?.rows} />
                    <div style={{ margin: '32px 0 50px 0' }}>
                    </div>
                    {/* Show Interest */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="modal"
                        open={openModal}
                        data-test-id="modal-switch"
                        onClose={() => this.setState({ openModal: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openModal}>

                            <div className="modal_paper">
                                <div className="deal_header_wrapper" data-test-id="openModal" style={{ height: '40px' }} onClick={() => this.setState({ openModal: !openModal })}>
                                    <ArrowBackIosIcon />
                                    <p className="deal_header">Deal Name</p>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '300px' }}>
                                        <p className="grid_box" style={{ margin: '15px 0px' }}>Debt Seeker's ask</p>
                                        <AskingPriceForm dealDetails={dealDetails} />
                                    </div>
                                    <div style={{ width: '250px' }}>
                                        <p className="grid_box" style={{ margin: '15px 0px' }}>Proposed Investment</p>
                                        <div className="deal_input_wrapper" style={{ marginTop: '10px' }}>
                                            <div className="para_wrapper">
                                                <p className="grid_box" style={{ width: '150px' }} >Amount</p>
                                                <input className="box_text_modal"
                                                    name="amount"
                                                    placeholder="INR (Cr)"
                                                    onMouseUp={this.mouseenter}
                                                    onFocus={this.hideError}
                                                    value={this.state.tempAmount}
                                                    data-testid="amount"
                                                    onBlur={this.hideBlur}
                                                    onChange={(e) => this.setState({ tempAmount: !isNaN(Number(e.target.value)) ? e.target.value : this.state.tempAmount })}
                                                    type="text"
                                                    style={{ border: '1px solid gray', marginLeft: '14' }} />

                                            </div>
                                            <div className="para_wrapper">
                                                <p className="grid_box" style={{ width: '150px' }} >%</p>
                                                <input className="box_text_modal"
                                                    onMouseUp={this.mouseenter1}
                                                    onFocus={this.hideError}
                                                    value={this.state.tempYield}
                                                    data-testid="yield"
                                                    onBlur={this.hideBlur1}
                                                    onChange={(e) => this.setState({ tempYield: !isNaN(Number(e.target.value)) ? e.target.value : this.state.tempYield })}
                                                    type="text"
                                                    name="yield"
                                                    style={{ border: '1px solid gray', marginLeft: '57' }} />

                                            </div>
                                            <small>
                                                {
                                                    (this.state.errors.yield)
                                                        ? <small style={{
                                                            padding: '5px',
                                                            color: 'red',

                                                            marginLeft: '5.5em',
                                                            fontSize: ' 12px',
                                                        }} >{this.state.errors.yield}</small>
                                                        : <small style={{ visibility: 'hidden' }}>""</small>
                                                }
                                            </small>
                                        </div>
                                        <small>
                                            {(this.state.errors.amount)
                                                ? <small style={{
                                                    lineHeight: '19px',
                                                    color: 'red',
                                                    fontSize: ' 12px',
                                                    fontWeight: 600,
                                                }} >
                                                    <span style={{
                                                        fontWeight: 700,
                                                        color: 'red',
                                                        lineHeight: '19px',
                                                        fontSize: '16px',
                                                    }} ></span> {'Amount should be greater than or equal to Minimum Investment and less than or equal to Total Amount.'}</small>
                                                :
                                                <small style={{ visibility: 'hidden' }}>""</small>
                                            }
                                        </small>

                                    </div>
                                </div>
                                <div>
                                    <p className="grid_box_fullWidth">Brief of any other requirement:</p>
                                    <Box className="text_area_container">
                                        <TextareaAutosize
                                            name="brief"
                                            className="textBox"
                                            data-testid="briefdes"
                                            style={{ height: '65px', borderRadius: '12px' }}
                                            onChange={(e) => this.setState({ brief: e.target.value })}
                                        />
                                    </Box>
                                    <p className="grid_box_fullWidth">Note- this is a non binding proposal.</p>
                                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '25px' }}>
                                        <Button
                                            data-testid="submitInterest"
                                            className={this.checkValidations() ? "make_an_offer_button_hide" : "make_an_offer_button"}
                                            onClick={this.makeAnOffer}
                                            disabled={this.checkValidations()}
                                        >Submit</Button></div>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                    {/* Make an offer */}
                    <Modal
                        aria-labelledby="transition-modal-title"
                        aria-describedby="transition-modal-description"
                        className="modal"
                        open={openMakeOfferModal}
                        data-test-id="modal-switch2"
                        onClose={() => this.setState({ openMakeOfferModal: false })}
                        closeAfterTransition
                        BackdropComponent={Backdrop}
                        BackdropProps={{
                            timeout: 500,
                        }}
                    >
                        <Fade in={openMakeOfferModal}>

                            <div className="modal_paper">
                                <div className="deal_header_wrapper" data-test-id="makeOfferModal" style={{ height: '40px' }} onClick={() => this.setState({ openMakeOfferModal: !openMakeOfferModal })}>
                                    <ArrowBackIosIcon />
                                    <p className="deal_header">Deal Name</p>
                                </div>

                                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                    <div style={{ width: '300px' }}>
                                        <p className="grid_box" style={{ margin: '15px 0px' }}>Borrowers ask</p>
                                        <AskingPriceForm dealDetails={dealDetails} />
                                    </div>
                                    <div style={{ width: '250px' }}>
                                        <p className="grid_box" style={{ margin: '15px 0px' }}>Provider Offer</p>
                                        <div className="deal_input_wrapper" style={{ marginTop: '10px' }}>
                                            <div className="para_wrapper">
                                                <p className="grid_box" style={{ width: '150px' }} >Amount</p>
                                                <input className="box_text_modal"
                                                    name="amount"
                                                    data-testid="amount2"
                                                    placeholder="INR (Cr)"
                                                    onMouseUp={this.mouseenter}
                                                    value={this.state.tempAmount}
                                                    onFocus={this.hideError}
                                                    onBlur={this.hideBlur}
                                                    onChange={(e) => this.setState({ tempAmount: !isNaN(Number(e.target.value)) ? e.target.value : this.state.tempAmount })}
                                                    type="text"
                                                    style={{ border: '1px solid gray', marginLeft: '14' }} />
                                            </div>

                                            <div className="para_wrapper">
                                                <p className="grid_box" style={{ width: '150px' }} >%</p>
                                                <input className="box_text_modal"
                                                    name="yield"
                                                    data-testid="offerYield"
                                                    value={this.state.tempYield}
                                                    onMouseUp={this.mouseenter1}
                                                    onFocus={this.hideError}
                                                    onBlur={this.hideBlur1}
                                                    onChange={(e) => this.setState({ tempYield: !isNaN(Number(e.target.value)) ? e.target.value : this.state.tempYield })}
                                                    style={{ border: '1px solid gray', marginLeft: '57' }} />
                                            </div>

                                            {
                                                (this.state.errors.yield)
                                                    ? <small className="deal_details_show_error" >{this.state.errors.yield}</small>
                                                    : <small style={{ visibility: 'hidden' }}>""</small>
                                            }
                                        </div>
                                        <small>
                                            {(this.state.errors.amount) ? <small style={{
                                                color: 'red',
                                                fontSize: ' 12px',
                                                lineHeight: '19px',
                                                fontWeight: 600,
                                            }} > <span style={{
                                                color: 'red',
                                                lineHeight: '19px',
                                                fontSize: '16px',
                                                fontWeight: 700,
                                            }} ></span> {'Amount should be greater than or equal to Minimum Investment and less than or equal to Total Amount.'}</small>
                                                : <small style={{ visibility: 'hidden' }}>""</small>
                                            }
                                        </small>
                                    </div>
                                </div>
                                <div>

                                    <Box className="phone_number_container" mt={3}>
                                        <Snackbar anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }} open={this.state.snackbarOpen} autoHideDuration={2000} onClose={this.handleClose} >
                                            <Alert severity="success">
                                                {this.state.snackbarMessage}
                                            </Alert>
                                        </Snackbar>
                                        <Snackbar anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                        }} open={this.state.snackbarOpenError} autoHideDuration={2000} onClose={this.handleCloseError} >
                                            <Alert severity="error">
                                                {this.state.snackbarMessageError}
                                            </Alert>
                                        </Snackbar>
                                        <input
                                            className="signUp_right_textInput_mobile"
                                            name="phoneNumber"
                                            data-testid="phoneNumber"
                                            type="number"
                                            placeholder="Enter OTP"
                                            disabled={true}
                                            value={this.context.OtpState?.current_user?.full_phone_number.substring(2)}
                                        />
                                        {this.state.isMobileVerified ? (
                                            <Typography
                                                className="mobile_verified_text"
                                                data-testid="checkVerification"
                                            >
                                                Verified
                                                <img className="verified_tick_logo" src={verified_tick_image} alt="" />
                                            </Typography>
                                        ) : (
                                            <Button
                                                className="request_otp_button"
                                                data-testid="generateOtp"
                                                style={{ fontWeight: 600 }}
                                                onClick={this.handleOtpToMakeAnOffer}
                                                variant="contained"
                                            >
                                                Generate OTP
                                            </Button>
                                        )}

                                    </Box>
                                    {!this.state.isMobileVerified ?

                                        this.state.sendOtp ? (
                                            <Box p={1} style={{ display: "flex", flexDirection: "column" }}>
                                                <Box pt={1} pb={1.5}>
                                                    <Typography className="mobile_otp_send_text_message" data-test-id="mobile_otp_send_text_message">
                                                        A 4 digit code has sent to the number {this.context.OtpState?.current_user?.full_phone_number.substring(2)}. Enter the code to continue
                                                    </Typography>{" "}
                                                </Box>
                                                <Box className="center">
                                                    <Box className="otp_container">
                                                        <OtpInput id="text1" data-test-id="text1" onKeyUp={(e: any) => this.move(e, "", "text1", "text2")} />
                                                        <OtpInput id="text2" data-test-id="text2" onKeyUp={(e: any) => this.move(e, "text1", "text2", "text3")} />
                                                        <OtpInput id="text3" data-test-id="text3" onKeyUp={(e: any) => this.move(e, "text2", "text3", "text4")} />
                                                        <OtpInput id="text4" data-test-id="text4" onKeyUp={(e: any) => this.move(e, "text3", "text4", "")} />

                                                    </Box>
                                                    <Box pl={1.5}>
                                                        <Typography className="did_not_receive_code" >
                                                            Didn’t receive code?
                                                            <a className="resend_code" data-test-id="resend_code" onClick={() => this.handleOtpToMakeAnOffer()} >
                                                                Resend Code
                                                            </a>
                                                        </Typography>
                                                    </Box>
                                                </Box>
                                            </Box>
                                        ) : null : ''
                                    }

                                    <Box mt={3} className="banner_wrapper">
                                        {this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer ?
                                            <Button className="make_an_offer_button"
                                                onClick={this.navigateToDealDiscovery}>Make an offer</Button>
                                            : <Button data-testid="makeAnOffer" className={(this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer) ? "make_an_offer_button" : "make_an_offer_button_hide"}
                                                disabled={(this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer) ? false : true}
                                                onClick={this.navigateToDealDiscovery}>Make an offer</Button>

                                        }
                                        {this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false ?
                                            <Button className="make_an_offer_button"
                                                onClick={this.navigateToDealDiscovery}>Amend offer</Button>
                                            : <Button data-testid="amendOffer" className={(this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false) ? "make_an_offer_button" : "make_an_offer_button_hide"}
                                                disabled={(this.state.amount != '' && this.state.yield != '' && this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false) ? false : true}
                                                onClick={this.navigateToDealDiscovery}>Amend offer</Button>

                                        }
                                        {this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false ?

                                            <Button data-testid="withrawOffer" className="make_an_offer_button"
                                                onClick={this.withDrawOffer}>Withdraw offer</Button>
                                            : <Button data-testid="withrawOffer" className={(this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false) ? "make_an_offer_button" : "make_an_offer_button_hide"}
                                                disabled={(this.state.isMobileVerified && this.context.OtpState?.make_an_offer === false) ? false : true}
                                                onClick={this.withDrawOffer}>Withdraw offer</Button>

                                        }

                                    </Box>
                                </div>
                            </div>
                        </Fade>
                    </Modal>
                </div>
            </div>
        );
    }
}
export default withRouter(DealDetails) as any;

/* istanbul ignore next */
function AskingPriceForm({ dealDetails }: any) {
    return (
        <div className="deal_input_wrapper" style={{ marginTop: '10px' }}>
            <div className="para_wrapper">
                <p className="grid_box" style={{ width: '150px' }} >Amount</p>
                <p className="box_text_modal">{dealDetails?.proposed_instrument?.data?.attributes?.total_deal_size_in_str}</p>
            </div>
            <div className="para_wrapper">
                <p className="grid_box" style={{ width: '150px' }} >Yield(%)</p>
                <p className="box_text_modal">{dealDetails?.proposed_instrument?.data?.attributes?.yield_in_str}</p>
            </div>
            <div className="para_wrapper">
                <p className="grid_box" style={{ width: '150px' }} >Maturity Date</p>
                <p className="box_text_modal">{moment(dealDetails?.proposed_instrument?.data?.attributes?.repayment_maturity_date).format('DD-MM-YYYY')}</p>
            </div>
        </div>
    )
}

// Customizable Area End
