import React from "react";
// Customizable Area Start
import {
    Box, FormControl, FormControlLabel, Grid, InputLabel, Radio, RadioGroup, Select,
    TextareaAutosize, TextField,
    Typography, IconButton, MenuItem
} from "@material-ui/core";
import {
    Close
} from "@material-ui/icons";
import { HtmlTooltip } from "./SupportiveDocument.web";
import AddBoxIcon from "@material-ui/icons/AddBox";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import { withRouter } from "react-router-dom";
import StructureAndSecurityController, { Props } from "./StructureAndSecurityController";
import "./style.css";
import moment from 'moment';
import { handleReviewSubmitClick } from "../../../components/src/common";

class StructureAndSecurity extends StructureAndSecurityController {
    constructor(props: Props) {
        super(props);
    }
    render() {                                           
        const { share_holdings, security_details, security, typesOfShareHoldings, typesOfSecurity, typesOfCharges } = this.state;
        return (
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <InputLabel >
                        <Typography variant="h5" className="header">Deal Structure</Typography>
                    </InputLabel>
                </Grid>
                <Grid item xs={3}>
                    <InputLabel className="inputLabel">
                        Description
                    </InputLabel>
                </Grid>

                <Grid item xs={8}>
                    <TextareaAutosize
                        disabled={this.state.isReadMode}
                        name="companyBackground"
                        data-test-id ="description"
                        className="textBox"
                        maxRows={5}
                        minRows={5}
                        value={this.state.description}
                        onChange={(e) => this.changeDescription(e)}
                    />
                    <br />

                    {
                        this.state.description.length > 500 && <span
                            style={{ color: "#ff5722", margin: "0 0 0 20px " }}
                        >
                            Text should be between(0-500 words).
                        </span>
                    }
                </Grid>
                <Grid item xs={12}>
                    <InputLabel >
                        <Typography variant="h5" className="header">Shareholding</Typography>
                    </InputLabel>
                </Grid>

                <Grid container spacing={2} alignItems="center" style={{ margin: "10px 0px" }}>
                    <Grid item xs={3}>
                        <InputLabel className="inputLabel">
                            Shareholding as on
                        </InputLabel>
                    </Grid>
                    <Grid item xs={8}>
                        <input
                            disabled={this.state.isReadMode}
                            data-test-id = "date"
                            style={{ cursor: "pointer", color: "gray" }}
                            onChange={(e: any) => this.setState({ share_holding_as_on: e.target.value, isNext: true })}
                            className="catalogue_textInput"
                            max={moment().format('YYYY-MM-DD')}
                            type="date"
                            onKeyDown={(e) => e && e.preventDefault()}
                            value={this.state.share_holding_as_on}
                        />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="h6" className="sub_header">
                        Shareholding Pattern
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <Box style={{ background: "#f2f4f6", padding: "12px", borderRadius: "10px", width: "95%", display: "flex", justifyContent: "center" }} >
                        <table style={{ width: "100%" }} >
                            <thead>
                                <tr>
                                    <th> <Typography variant="h6" style={{ width: '200px' }} className="catalog_input_header" >Shareholder type</Typography></th>
                                    <th> <Typography variant="h6" style={{ width: '100px' }} className="catalog_input_header">%Holding</Typography></th>
                                    <th><Typography variant="h6" className="catalog_input_header">Remarks (Optional)</Typography></th>
                                </tr>
                            </thead>
                            <tbody>
                                {share_holdings && share_holdings.map((input, index) => !input._destroy && (
                                    <tr key={index}>
                                        <td style={{ width: '200px' }}>
                                            <FormControl variant="outlined" >
                                                <Select
                                                    disabled={this.state.isReadMode}
                                                    className="select_textInput"
                                                    data-test-id = "select-share-holder"
                                                    disableUnderline
                                                    displayEmpty
                                                    name="share_holder_type_id"
                                                    IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
                                                    value={input && input.share_holder_type_id}
                                                    onChange={(e) => 
                                                        this.handleChangeInput(index, e)
                                                    }
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                borderRadius: "20px",
                                                            }
                                                        }
                                                    }}
                                                >
                                                    <MenuItem value="" disabled> <span style={{ color: "darkgray" }}>-Select One-</span></MenuItem>
                                                    {typesOfShareHoldings.map((item, i) => (
                                                        <MenuItem className="select_option_1" key={i} value={item && item.id}>
                                                            {item && item.attributes && item.attributes.name}
                                                        </MenuItem>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </td>
                                        <td style={{ width: '100px' }}>
                                            <input
                                                style = {{outline : 'none',height : '41px',width : '100%',borderRadius : '5px',border : '1px solid #B2BEB5',backgroundColor : "white"}}
                                                data-test-id = "percentage-holding"
                                                className="catalog_input_box"
                                                name="percentage_holding"
                                                type="text"
                                                disabled={this.state.isReadMode}
                                                value={input && input.percentage_holding}
                                                onChange={(e) =>
                                                    this.handleChangeInput(index, e)
                                                }
                                            />
                                        </td>
                                        <td>
                                            <HtmlTooltip title={input?.remarks} placement="bottom-end" arrow>
                                                <textarea
                                                    id="outlined-basic"
                                                    className="catalog_input_box_remarks"
                                                    name="remarks"
                                                    data-test-id = "remark-structure"
                                                    onMouseOver={() => {this.openMessageDialog(true)}}
                                                    style={{ color: 'black', width: '100%', marginBottom: '10px' }}
                                                    value={input && input.remarks}
                                                    disabled={this.state.isReadMode}
                                                    onChange={(e) =>
                                                        this.handleChangeInput(index, e)
                                                    }
                                                />
                                            </HtmlTooltip>
                                        </td>

                                        {index > 0 && (
                                            <td>
                                                <IconButton data-test-id = "close-icon-one" disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent', marginTop: '-30px' }} onClick={() => this.handleFilter(index)}>
                                                    <Close />
                                                </IconButton>
                                            </td>
                                        )}
                                    </tr>
                                ))
                                }

                            </tbody>
                        </table>
                    </Box>
                    <button data-test-id = "add-row" disabled={this.state.isReadMode} className="addRow_button" onClick={this.handleAddClick}>
                        Add row <AddBoxIcon style={{ marginLeft: "8px" }} />
                    </button>
                </Grid>


                <Grid item xs={3}>
                    <InputLabel required className="header">
                        Security
                    </InputLabel>
                </Grid>
                <Grid item xs={8}>
                    <RadioGroup
                        data-test-id = "radio-secure"
                        style={{ display: "flex", flexDirection: "row" }}
                        onChange={this.handleSecurity}
                        value={this.state?.security}
                    >
                        <FormControlLabel
                            value="secured"
                            control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                            label="Secured"
                        />
                        <FormControlLabel
                            value="unsecured"
                            control={<Radio disabled={this.state.isReadMode} style={{ color: "#ff5722" }} />}
                            label="Unsecured"
                        />
                    </RadioGroup>
                </Grid>

                {
                    security === 'secured' &&
                    <>
                        <Grid container spacing={2} alignItems="center" style={{ margin: "10px 0px" }}>
                            <Grid item xs={3}>
                                <InputLabel required className="sub_header">
                                    Security Cover
                                </InputLabel>
                            </Grid>
                            <Grid item xs={8} style={{ fontSize: '18px' }}>
                                <input
                                    disabled={this.state.isReadMode}
                                    className="catalogue_textInput"
                                    style={{
                                        width: "120px",
                                        textAlign: 'center',
                                        marginRight: "15px",
                                    }}
                                    type="number"
                                    maxLength={8}
                                    data-test-id = "security-cover"
                                    value={this.state.security_cover}
                                    onChange={(e) => this.handleSecurityCover(e)}
                                /> times
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <InputLabel >
                                <Typography variant="h6" className="sub_header">Security Details</Typography>
                            </InputLabel>
                        </Grid>
                        <Grid item xs={12}>
                            <Box style={{ background: "#f2f4f6", padding: "12px", borderRadius: "10px", width: "95%", display: "flex", justifyContent: "center" }} >
                                <table style={{ width: "100%" }} >
                                    <thead>
                                        <tr>
                                            <th style={{ width: '10%' }}> <Typography variant="h6" className="catalog_input_header" >Type of Security</Typography></th>
                                            <th style={{ width: '10%' }}> <Typography variant="h6" className="catalog_input_header">Type of Charge</Typography></th>
                                            <th style={{ width: '9%' }}><Typography variant="h6" className="catalog_input_header">Details</Typography></th>
                                            <th style={{ width: '5%' }}><Typography variant="h6" className="catalog_input_header">Value(Cr)</Typography></th>
                                            <th style={{ width: '66%' }}><Typography variant="h6" className="catalog_input_header">Remarks (Optional)</Typography></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {security_details.map((ele, i) => !ele._destroy && (
                                            <tr key={i}>
                                                <td>
                                                    <FormControl variant="outlined" size="small">
                                                        <Select
                                                            disabled={this.state.isReadMode}
                                                            className="select_textInput_security"
                                                            disableUnderline
                                                            displayEmpty
                                                            data-test-id = "security_select"
                                                            name="type_of_security_id"
                                                            IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
                                                            value={ele && ele.type_of_security_id}
                                                            onChange={(e) =>
                                                                this.handleChangeInput2(i, e)
                                                            }

                                                        >
                                                            <MenuItem value="" disabled> <span style={{ color: "darkgray" }}>-Select One-</span></MenuItem>
                                                            {typesOfSecurity.map((item, i) => (
                                                                <MenuItem className="select_option_1" key={i} value={item?.id}>
                                                                    {item?.attributes?.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl> </td>
                                                <td>
                                                    <FormControl variant="outlined" size="small">
                                                        <Select
                                                            disabled={this.state.isReadMode}
                                                            className="select_textInput_security"
                                                            disableUnderline
                                                            data-test-id = "charge_id"
                                                            displayEmpty
                                                            name="type_of_charge_id"
                                                            IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
                                                            value={ele && ele.type_of_charge_id}
                                                            onChange={(e) =>
                                                                this.handleChangeInput2(i, e)
                                                            }
                                                            
                                                        >
                                                            <MenuItem value="" disabled> <span style={{ color: "darkgray" }}>-Select One-</span></MenuItem>
                                                            {typesOfCharges.map((item, i) => (
                                                                <MenuItem style={{ width: 200, whiteSpace: 'normal', textOverflow: 'ellipsis' }} key={i} value={item && item.id}>
                                                                    {item?.attributes?.name}
                                                                </MenuItem>
                                                            ))}
                                                        </Select>
                                                    </FormControl></td>
                                                <td style={{ width: '9%' }}>
                                                    <HtmlTooltip title={ele?.details} placement="bottom-end" arrow>
                                                        <textarea
                                                            id="outlined-basic"
                                                            data-test-id = "details"
                                                            disabled={this.state.isReadMode}
                                                            style={{ marginBottom: '10px', width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                            className="catalog_input_box_remarks"
                                                            name="details"
                                                            value={ele?.details}
                                                            onMouseOver={() => { this.openMessageDialog(true)}}
                                                            onChange={(e) =>
                                                                this.handleChangeInput2(i, e)
                                                            }
                                                        />
                                                    </HtmlTooltip>
                                                </td>
                                                <td style={{ width: '10%' }}>
                                                    <input
                                                        style = {{outline : 'none',height : '41px',width : '100%',borderRadius : '5px',border : '1px solid #B2BEB5',backgroundColor : "white"}}
                                                        disabled={this.state.isReadMode}
                                                        data-test-id = "inr-cr"
                                                        className="catalog_input_box"
                                                        name="value_inr_core"
                                                        type="text"
                                                        maxLength = {8}
                                                        value={ele?.value_inr_core}
                                                        onChange={(e) =>
                                                            this.handleChangeInput2(i, e)
                                                        }
                                                    />
                                                </td>
                                                <td style={{ width: '60%' }}>
                                                    <HtmlTooltip title={ele?.remarks} placement="bottom-end" arrow>
                                                        <textarea
                                                            id="outlined-basic"
                                                            data-test-id = "sec-remark"
                                                            disabled={this.state.isReadMode}
                                                            style={{ marginBottom: '10px', width: '100%', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}
                                                            className="catalog_input_box_remarks"
                                                            name="remarks"
                                                            value={ele?.remarks}
                                                            onChange={(e) =>
                                                                this.handleChangeInput2(i, e)
                                                            }
                                                        />
                                                    </HtmlTooltip>
                                                </td>

                                                {i > 0 && (
                                                    <td>
                                                        <IconButton data-test-id = "icon" disabled={this.state.isReadMode} style={{ backgroundColor: 'transparent', marginTop: '-30px' }} onClick={() => this.handleFilter2(i)}>
                                                            <Close />
                                                        </IconButton>
                                                    </td>
                                                )}

                                            </tr>
                                        ))
                                        }
                                    </tbody>
                                </table>
                            </Box>
                            <button data-test-id = "add" disabled={this.state.isReadMode} className="addRow_button" onClick={this.handleAddClick2}>
                                Add row <AddBoxIcon style={{ marginLeft: "8px" }} />
                            </button>
                        </Grid>
                    </>
                }

                <Grid item xs={4}></Grid>
                <Grid item xs={8}>
                    {!this.state.isReadMode ? <Box className="catalog_button_wrapper">
                        <button data-test-id = "save-as-draft" onClick={this.countPercentHolding('draft')} className="save_as_draft_button" >
                            Save as Draft
                        </button>

                        <button data-test-id = "handle-submit" className="next_button" onClick={this.countPercentHolding('submit')}>
                            Next
                        </button>
                    </Box> : <Box
                        className="project_submit_button_wrapper"
                    ><button  data-test-id = "next" onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button">Next</button></Box>}
                </Grid>
            </Grid>
        );
    }
}

export default withRouter(StructureAndSecurity as any) as any;
// Customizable Area End