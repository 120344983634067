import React from "react";
// Customizable Area Start
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";
import ClippedDrawer from "./ClippedDrawer.web";
const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  // Customizable Area End

  render() {
    return (
      //Merge Engine DefaultContainer
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        <Container maxWidth="xl" disableGutters >
          <ClippedDrawer id="" navigation={this.props.navigation} user={this.state.user}/>
        </Container>
      </ThemeProvider>
      // Customizable Area End
      //Merge Engine End DefaultContainer
    );
  }
}

// Customizable Area Start

// Customizable Area End
