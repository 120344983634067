import React from "react";
import "./styles.css";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function CustomSelect(props: any) {
  const { label, options, role } = props;
  return (
    <div style={{ height: "78px" }}>
      <InputLabel className="customSelect_label" required>
        {label}
      </InputLabel>
      <FormControl
        className="customSelect_formControl"
        fullWidth
        variant="outlined"
        size="small"
      >
        {/* <Select
          className="customSelect_select"
          disableUnderline
          native
          value={role}
          IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
          onChange={props.onChange}
        >
          <option className="customSelect_placeholder" value="" > User Role</option>
          {options.map((item: string, index: number) => (
            <option className="select_option" key={item} value={item}>
              {item}
            </option>
          ))}
        </Select> */}
        <Select
          className="customSelect_select"
          disableUnderline
          displayEmpty
          value={role}
          IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
          onChange={props.onChange}
          MenuProps={{
            PaperProps: {
                style: {
                    maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                    width: 200,
                    marginTop: 8,
                    borderRadius: "20px",
                }
            },
            anchorOrigin: {
                vertical: "bottom",
                horizontal: "left"
            },
            getContentAnchorEl: null,
        }}
        >
          <MenuItem value="" style={{ display: 'none' }}>User Role</MenuItem>
          {
            options.map((item: any, index: number) => {
              return (
                <MenuItem className="select_option_1" key={index} value={item}>
            {item}
          </MenuItem>
              )
            })
          }
        </Select>
      </FormControl>
    </div>
  );
}

export default CustomSelect;
