Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.companyOverviewApiContentType = "application/json";
exports.getApprovalPendingContentType = "application/json";
exports.debtProfileApiContentType = "application/json";
exports.contentTypeApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.apiMethodTypePut = "PUT";
exports.apiMethodTypeDelete = "DELETE";
exports.managementProfileGetApiEndPoint = `bx_block_management/management_profiles`;
exports.managementProfileUpdateApiEndPoint = `bx_block_management/management_profiles/update`;
exports.productAPiEndPoint = "catalogue/catalogues";
exports.managementProfileDeleteApiEndPoint = "bx_block_management/management_profiles/destroy";
exports.managementProfileApiEndPoint = `bx_block_management/management_profiles`;
exports.apiPostMethod = "POST";
exports.debtArrangerCompanyEndpoint = 'bx_block_bank_deal/debt_arranger_company';
exports.debtArrangerNameEndpoint = 'bx_block_bank_deal/debt_arranger';
exports.debtProductListEndPoint = 'bx_block_debt_profile/debt_product_types';
exports.apiPostMethod = "POST";
exports.endPointApiPostUserAddDealBySeeker = "bx_block_bank_deal/debt_deals";
exports.endPointApiPostUserAddDealByAdminOrArranger = "bx_block_bank_deal/debt_deals";
exports.endUse_endPoinApi = 'account_block/proposed_instruments/proposed_end_uses';
exports.proposed_instument_endPoinApi = 'account_block/proposed_instruments/proposed_type_of_instruments';
exports.sector_endPoinApi = 'account_block/proposed_instruments/proposed_sectors';
exports.credit_rating_agency_endPoinApi = 'account_block/proposed_instruments/rating_credit_rating_agency';
exports.rating_details_endPoinApi = 'account_block/proposed_instruments/rating_details_ratings';
exports.rating_details_outlok_endPoinApi = 'account_block/proposed_instruments/rating_details_rating_outlooks';
exports.interest_frequency_payment_endPoinApi = 'account_block/proposed_instruments/interest_payment_frequencies';
exports.principle_frequency_payment_endPoinApi = 'account_block/proposed_instruments/principal_repayment_frequencies';
exports.typesOfShareholdingsEndpoint = "account_block/structures/type_of_share_holders";
exports.typesOfSecuritiesEndpoint = "account_block/structures/type_of_securities";
exports.typesOfChargesEndpoint = "account_block/structures/type_of_charges"
exports.createStructureAndSecurity = "account_block/structures"
exports.applicationType = "application/json";
exports.getDocumentNameListEndPoint = 'bx_block_supportive_documents/documents'
exports.getDocumentRelatedToListEndPoint = 'bx_block_supportive_documents/related_documents'
exports.SupportiveSaveAsDraftEndPoint = `bx_block_supportive_documents/supportive_documents?deal_id=`;
exports.getFaqListEndPoint = 'bx_block_faq/faqs?';
exports.createFaqsEndPoint = 'bx_block_faq/faqs';
exports.getDocumentNameListEndPoint = 'bx_block_supportive_documents/documents'
exports.getDocumentRelatedToListEndPoint = 'bx_block_supportive_documents/related_documents'
exports.supportiveGetApiEndPoint = `bx_block_supportive_documents/supportive_documents?deal_id=`;
exports.supportivePutApiEndPoint = `bx_block_supportive_documents/supportive_documents/update`;
exports.FinacialSummariSubmitApiEndPoint = `bx_block_financial/financial_summaries?deal_id=`
exports.dealDiscoveryEndpoint = 'bx_block_bank_deal/deal_discovery';
exports.FinacialSummaryGetApiEndPoint = `bx_block_financial/financial_summaries/show?deal_id=`;
exports.FinacialSummaryUpdateApiEndPoint = `bx_block_financial/financial_summaries/update?`
exports.FinancialSummaryDownloadApiEndPoint = "bx_block_financial/financial_summaries/download_file";
exports.changePasswordAPIEndPoint = "bx_block_forgot_password/password/change_password";
exports.DebtSeekerTypesAPIEndPoint = "bx_block_bank_details/debt_seekers";
exports.getProfileBankDetailsAPIEndPoint = "bx_block_bank_details/account_details/show";
exports.getDematProfileDetailsAPIEndPoint = "/bx_block_bank_details/demat_details/show";
exports.getKYCProfileDetailsAPIEndPoint = "bx_block_bank_details/kyc_details/show";
exports.updateKYCDetailsAPIEndPoint = "bx_block_bank_details/kyc_details/update";
exports.updateDematDetailsAPIEndPoint = "bx_block_bank_details/demat_details/update";
exports.updateBankDetailsAPIEndPoint = "bx_block_bank_details/account_details/update";
exports.getPersonalDetailsAPIEndPoint = "account_block/accounts";
exports.verifyEmailAPIEndPoint = "account_block/accounts/email_confirmation";
exports.RequestOTPAPIEndPoint = "account/accounts/send_otp";
exports.ResendOTPAPIEndPoint = "resend_otp";
exports.VerifyOTPAPIEndPoint = "account/accounts/sms_confirmation";
exports.checkEmailAPIEndPoint = "check_email_verification";
exports.getDealDetailsApiEndpoint = "bx_block_bank_deal/dashboard/get_deal_details";
exports.getDealDetailsApiMethod = "GET";
exports.getProvidersListApiEndpoint = "bx_block_bank_deal/deal_visibilities/get_provider_list";
exports.getProvidersListApiMethod = "GET";
exports.getDealVisibilityApiEndpoint = "bx_block_bank_deal/deal_visibilities/show";
exports.getDealVisibilityApiMethod = "GET";
exports.submitSettingApiEndpoint = "bx_block_bank_deal/deal_visibilities";
exports.submitSettingApiMethod = "POST";
exports.getOtpFromUserApiMethod= "POST";
exports.getOtpFromUserApiEndpoint= "bx_block_bank_deal/send_otps";
exports.verify_OTP_ApiEndPoint= "bx_block_bank_deal/sms_confirmations";
exports.verify_OTP_ApiMethod= "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethod = "POST";
exports.validationApiEndPoint = "bx_block_bank_deal/offers";
exports.updateVisibilitySettingsApiEndpoint = "bx_block_bank_deal/deal_visibilities/update";
exports.updateVisibilitySettingsApiMethod = "PUT";
exports.switchProfileApiEndpoint = "account_block/account_user_roles/switch_profile";
exports.switchProfileApiMethod = "PUT"
exports.getUserSpecificRoleApiEndpoint = "account_block/account_user_roles/get_profile_user_role";
exports.getUserSpecificRoleApiMethod = "GET";
exports.getPersonalDetailsEndPoint = "account_block/accounts";
exports.somethingWentWrongErrorMessage = "Something Went Wrong";
exports.chatCredentialsAPiEndPoint = "bx_block_chatbackuprestore/chat_credentials"
exports.initiateChatApiEndpoint = 'bx_block_bank_deal/dashboard/notify_by_mail_to_initiate_chat'
exports.someSecret= 'someSecret'
// Customizable Area End
