// Customizable Area Start
import React from "react";
import "./managementProfile.css";
import Container from '@material-ui/core/Container';
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { Typography, Grid, Box, InputLabel, Select, FormControl, Button } from '@material-ui/core';
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import CatalogueController, { Props } from "./CatalogueController";
import ClippedDrawer from "./ClippedDrawer.web";
import AddBoxIcon from '@material-ui/icons/AddBox';
import ManagementProfileForm from "./ManagementProfileForm.web";
import DirectorProfileForm from "./DirectorForm.web";
import { withRouter } from "react-router-dom";
import ManagementProfileController, { IDirectorData, IManageMentForm, INITIAL_DIRECTOR_DETAIL, INITIAL_MANAGEMENTFORM_DETAIL } from "./ManagementProfileController";
import { handleReviewSubmitClick } from "../../../components/src/common";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

const theme = createTheme({
    palette: {
        primary: {
            main: "#0000ff",
            contrastText: "#fff",
        },
    },
});

class ManagementProfile extends ManagementProfileController {
    constructor(props: Props) {
        super(props);
    }

    /* istanbul ignore next */
    checkAnyFieldHasValue=()=>{
        const hasTruthyValue = [...this.state.managerData, ...this.state.directorData].some(item => {
            const keysToCheck = Object.keys(item).filter(key => key !== 'abbrebiation' && key !== 'dir_abbrebiation' && key !== 'director_profileId' && key !=='management_profileId');
            return keysToCheck.some(key => item[key]);
        });
        return hasTruthyValue;
    } 
    checkAllFieldFill = (managerData: IManageMentForm[], directorData: IDirectorData[]) => {
        let finalDisableValue = false;
        managerData?.map((item: any, index: number) => {
            if ((item?.name?.length === 0) || (item?.designation?.length === 0) || (item?.education?.length === 0)) {
                finalDisableValue = true;
            }
        });
        directorData?.map((item: any, index: number) => {
            if ((item?.dir_name?.length === 0) || (item?.dir_designation?.length === 0) || (item?.dir_education?.length === 0) || (item?.dir_din?.length === 0) || (item?.dateOfAppointment?.length === 0)) {
                finalDisableValue = true;
            }
        });
        this.setState({
            showAllFieldError: finalDisableValue
        })
        return finalDisableValue
    };
    handleAddClick = () => {
        const currentManagementList: IManageMentForm[] = this.state.managerData;
        const newManager: IManageMentForm = { ...INITIAL_MANAGEMENTFORM_DETAIL };
        currentManagementList.push(newManager);
        this.setState({
            managerData: currentManagementList,
            showAllFieldError: true
        });
    };
    handleAddClick1 = () => {
        const currentManagementList2: IDirectorData[] = this.state.directorData;
        const newManager2: IDirectorData = { ...INITIAL_DIRECTOR_DETAIL };
        currentManagementList2.push(newManager2);
        this.setState({
            directorData: currentManagementList2,
            showAllFieldError: true
        });
    };
    render() {
        return (
            <ThemeProvider theme={theme}>
                <>
                    <Typography id="management_profile_label">Management Profile</Typography>
                    {
                        this.state.managerData?.map((manageData: IManageMentForm, index: number) => {
                            return (<Box className="management_box">
                                <ManagementProfileForm isReadMode={this.state.isReadMode} navigation={this.props.navigation} id={""} manageData={manageData} index={index} updateManageMentData={(data: IManageMentForm, updateIndex: number) => {
                                    const currentManagementList: IManageMentForm[] = this.state.managerData;
                                    currentManagementList[updateIndex] = data;
                                    this.setState({
                                        managerData: currentManagementList,
                                        isNext: true
                                    })
                                    this.checkAllFieldFill(currentManagementList, this.state.directorData)
                                }}
                                    deleteManageData={(updateIndex: number) => {
                                        const currentManagementList: IManageMentForm[] = this.state.managerData;
                                        const selectedManagementData: IManageMentForm = currentManagementList[updateIndex];
                                        currentManagementList.splice(updateIndex, 1)
                                        this.setState({
                                            managerData: currentManagementList,
                                            isNext: true
                                        })
                                        this.deleteManagementData(selectedManagementData.management_profileId, null)
                                    }}
                                />
                            </Box>)
                        })}
                    <button disabled={this.state.isReadMode} className="addRow_button" onClick={this.handleAddClick}>
                        Add More <AddBoxIcon style={{ marginLeft: "8px" }} />
                    </button>
                    <Typography id="management_profile_label">Director</Typography>
                    {
                        this.state.directorData?.map((directManagementData: IDirectorData, index: number) => {
                            return (
                                <Box className="management_box">
                                    <DirectorProfileForm isReadMode={this.state.isReadMode} navigation={this.props.navigation} id={""} directManagementData={directManagementData} index={index} updateDirectManageMentData={(data: IDirectorData, updateIndex: number) => {
                                        const currentManagementList2: IDirectorData[] = this.state.directorData;
                                        currentManagementList2[updateIndex] = data;
                                        this.setState({
                                            directorData: currentManagementList2,
                                            isNext: true
                                        })
                                        this.checkAllFieldFill(this.state.managerData, currentManagementList2)
                                    }}
                                        deleteManageData={(updatedIndex: number) => {
                                            const currentDirectorData: IDirectorData[] = this.state.directorData;
                                            const selectedDirectorData: IDirectorData = currentDirectorData[updatedIndex];
                                            currentDirectorData.splice(updatedIndex, 1);
                                            this.setState({
                                                directorData: currentDirectorData,
                                                isNext: true
                                            });
                                            this.deleteManagementData(null, selectedDirectorData.director_profileId);
                                        }}
                                    />
                                </Box>
                            );
                        })
                    }
                    <button disabled={this.state.isReadMode} className="add_more_button" onClick={this.handleAddClick1}>
                        Add More <AddBoxIcon style={{ marginLeft: "8px" }} />
                    </button>
                    <Grid container xs={12} >
                        <Grid item xs={4}></Grid>
                        <Grid item xs={8}>
                            {!this.state.isReadMode ? <Box className="catalog_button_wrapper">

                                <Button variant="outlined" data-test-id="save_as_draft_button" className="save_as_draft_button"
                                    onClick={
                                        () => {
                                            this.updateManagementProfile(true)
                                        }
                                    }
                                >
                                    Save as Draft
                                </Button>

                                <Button variant="contained" className="next_button"
                                    onClick={() => {
                                        const isPayloadEmpty = this.checkAnyFieldHasValue()
                                        /* istanbul ignore next */
                                        if (isPayloadEmpty) {
                                                this.setState({
                                                    isRedirect : true
                                                })
                                                this.state.managementProfileId?.length > 0 ? this.updateManagementProfile(false)
                                                    : this.saveAsDraft(localStorage.getItem("token"), true)
                                            }
                                            else {
                                            this.setState({isRedirect: false})
                                            const msg75: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                                            msg75.addData(getName(MessageEnum.CustomAlertBodyData), 'Please fill all the mandatory fields.');
                                            this.send(msg75);
                                            }
                                        
                                    }}
                                >
                                    Next</Button>

                            </Box> : <Box
                                style={{ maxWidth: 585 }}
                                className="project_submit_button_wrapper"
                            >
                                <button onClick={() => handleReviewSubmitClick(window.location, this.props.history)} className="next_button" >Next</button>
                            </Box>


                            }
                        </Grid>
                    </Grid>


                </>
            </ThemeProvider>
        );
    }
}
export default withRouter(ManagementProfile) as any;
// Customizable Area End
