export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const logo = require("../assets/logo.png");
export const triangle = require("../assets/triangle.png");
export const selectedRadioButton = require("../assets/selected_radio_button.png");
export const radioButton = require("../assets/radio_button.png");
export const rightTick = require("../assets/right_tick.png");
export const cross = require("../assets/cross.png");
export const minus = require("../assets/minus.png");
export const eye = require("../assets/eye.png");
export const plus = require("../assets/plus.png");
export const activeCheckbox = require("../assets/active_checkbox.png");
export const inactiveCheckbox = require("../assets/inactive_checkbox.png");
