import React from "react";
// Customizable Area Start
import "../styles.css";
import "../../../blocks/email-account-registration/src/EmailAccountRegistration.css"
import {
    Box,
    Container,
    Toolbar,
    Grid,
    Typography,
    Button,
    InputLabel,
    Select,
    MenuItem
} from "@material-ui/core";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import FormControl from '@material-ui/core/FormControl';
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import BackupIcon from '@material-ui/icons/Backup';
import CircularProgress from '@material-ui/core/CircularProgress';
import { careerPage } from "../../../blocks/landingpage/src/assets"
import CustomInput from "../CustomInput";
import { withStyles } from "@material-ui/core/styles";
import Footer from "../../../blocks/landingpage/src/Footer.web";
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const CustomInputLabel = withStyles({
    root: {
        margin: "22px 0px 8px 16px",
        fontSize: "16px",
        color: '#323232',
        fontFamily: 'Lato',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '-0.4px',
    },
})(InputLabel);
const theme = createTheme({
    palette: {
        primary: {
            main: "#nnn",
            contrastText: "#fff",
        },
    },
    typography: {
        h6: {
            fontWeight: 500,
        },
        subtitle1: {
            margin: "20px 0px",
        },
    },
    overrides: {
        MuiIconButton: {
            root: {
                padding: 0
            },
        },
    },
});


export default class Careers extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getPrivacyPolicy();
    }

    render() {
        return (
            <>
                <ThemeProvider theme={theme}>
                    <Header id="" navigation={this.props.navigation} match={undefined} location={undefined} history={undefined} />
                    <Toolbar />
                    <Toolbar />
                    <Container maxWidth={false} style={webStyle.ContainerWidth}>
                        <Grid container style={webStyle.mainWrapper} spacing={0}>
                            <Grid item xs={12} md={6} style={webStyle.mainInnerWrapper} >
                                <Box style={webStyle.BoxImageStyle}>
                                    <img
                                        alt="connect"
                                        src={careerPage}
                                        style={webStyle.imgStyle}
                                    />
                                </Box>
                            </Grid>
                            <Grid item xs={12} md={6} style={webStyle.mainInnerWrapper}>
                                <Box style={{ paddingTop: '2.5em', width: '70%', }}>
                                    <Typography style={webStyle.carrerfont}>
                                        Join us and be a part of the growth story,
                                        Share your details here:
                                    </Typography>
                                    <Box style={{ paddingTop: '0.1em' }}>
                                        <CustomInput
                                            name="carrerFullName"
                                            placeholder="FullName"
                                            label="Name"
                                            value={this.state.carrerFullName}
                                            onChange={this.handleNameChange}
                                            isMandatory={false}

                                        />
                                        {this.state.isErrorName ? (
                                            <Typography
                                                className="error_message"
                                            >
                                                Name should be between 2 - 25 character.
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box style={{ paddingTop: '0.1em' }}>
                                        <CustomInput
                                            name="carrerEmail"
                                            placeholder="example@gmail.com"
                                            label="Email"
                                            value={this.state.carrerEmail}
                                            onChange={this.handleEmailChange}
                                            isMandatory={false}
                                        />
                                        {this.state.isErrorEmail ? (
                                            <Typography
                                                className="error_message"
                                            >
                                                Please enter a valid email Address
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box style={{ paddingTop: '0.1em' }}>
                                        <CustomInput
                                            name="carrerPhone"
                                            placeholder="+91 9920 02 0202"
                                            label="Phone Number"
                                            value={this.state.carrerPhone}
                                            onChange={this.handlePhoneChange}



                                            isMandatory={false}
                                        />
                                        {this.state.isErrorPhone ? (
                                            <Typography
                                                className="error_message"
                                            >
                                                Please enter your 10 digit Phone Number
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box style={{ paddingTop: '0.1em' }}>
                                        <CustomInput
                                            name="carrerGraduation"
                                            placeholder="ex: Graduation"
                                            label="Education"
                                            value={this.state.carrerGraduation}
                                            onChange={this.handleGraduationChange}
                                            isMandatory={false}
                                        />
                                        {this.state.isErrorGraduation ? (
                                            <Typography
                                                className="error_message"
                                            >
                                                Please enter your Graduation.
                                            </Typography>
                                        ) : null}
                                    </Box>
                                    <Box style={{ paddingTop: '0.1em' }} >


                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyle.marginGrid}
                                        >
                                            City
                                        </CustomInputLabel>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            className="kyc_form_control"
                                            style={{ marginBottom: '15px', }}
                                            placeholder="City"
                                        >
                                            <Select
                                                fullWidth
                                                disableUnderline
                                                value={this.state.selectCity == "" ? 0 : this.state.selectCity}
                                                onChange={this.handleRole}
                                                name="City"
                                                className="kyc_form_control"
                                                placeholder="City"
                                                IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                            width: 250,
                                                            marginTop: 8
                                                        }
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                            // defaultValue={"ex:Delhi"}
                                            >

                                                <MenuItem style={{ display: "none", color: 'darkgray' }} value="0"> <span style={{ color: "darkgray" }}>ex:Delhi</span></MenuItem>

                                                {
                                                    this.context.CarrerCityState && this.context.CarrerCityState.map((item: any, index: number) => {
                                                        return (
                                                            <MenuItem value={item.id} key={index}>
                                                                {item?.attributes?.name}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>

                                    </Box>
                                    <Box style={{ paddingTop: '0.1em' }}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyle.marginGrid}
                                        >
                                            Resume
                                        </CustomInputLabel>
                                        <div className="upload_file_fileInput">
                                            <input
                                                type="file"
                                                id="customInput2"
                                                style={{ display: "none", color: "black" }}
                                                placeholder="Upload your Resume"

                                                className="hidden"
                                                accept=".doc,.docx,.xml,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,.pdf"
                                                name="Resume"
                                                disabled={false}
                                                onChange={this.onFileChange}

                                            />
                                            <Box className="uploadBoxLebel">
                                                {
                                                    this.state.imgError ? <Typography className="fileLabel" style={{ color: 'red' }}>Upload valid file</Typography> : this.state.imgUrl ? <Typography className="fileLabel">{decodeURI(this.state.imgUrl)}</Typography> : this.state.file?.name ?
                                                        <Typography className="fileLabel"><div style={{ paddingLeft: "0.5em", paddingTop: "0.4em" }}>{decodeURI(this.state.file?.name)}</div></Typography>
                                                        : <Typography className="fileLabel" ><div style={{ paddingLeft: "0.5em", paddingTop: "0.4em" }}>Upload your Resume</div></Typography>

                                                }
                                                {
                                                    this.state.file || this.state.imgUrl ? <span className="company_upload_lebel"><p style={{ margin: '0px' }} onClick={() => this.setState({ file: '', imgUrl: '', isNext: true, delete_logo_image: true, })}><div style={{ marginTop: '0.4em', paddingRight: '0.4em' }}>X</div></p> </span>
                                                        : this.state.imgError ? <span className="company_upload_lebel"><p style={{ margin: '0px' }} onClick={() => this.setState({ imgError: false })}><div style={{ marginTop: '0.4em', paddingRight: '0.4em' }}>X</div></p> </span> : <InputLabel className="company_upload_lebel" htmlFor="customInput2">
                                                            <div style={{ marginTop: '0.4em', paddingRight: '0.4em' }}>
                                                                <BackupIcon />
                                                            </div>

                                                        </InputLabel>
                                                }
                                            </Box>


                                        </div>
                                    </Box>

                                </Box>
                                <Box className="header_submit1" style={{
                                    backgroundColor: `${(this.state.carrerFullName != '' && this.state.carrerEmail != '' && this.state.carrerPhone != '' && this.state.carrerGraduation != "" && this.state.selectCity != "" && this.state.file != null && this.state.file != "") ? "darkblue" : "#E8E8E8"} `, marginTop: '1.6em'
                                }}
                                >
                                    <Button style={{ height: '1.8em' }}
                                        disabled={(this.state.carrerFullName != '' && this.state.carrerEmail != '' && this.state.carrerPhone != '' && this.state.carrerGraduation != "" && this.state.selectCity != "" && this.state.file != null  && this.state.file != "") ? false : true} onClick={this.onSubmitForm}>
                                        <span style={{ color: "#ffff", textTransform: "none" }}>{this.state.loadingSignin ? <CircularProgress /> : "Submit"}</span>
                                    </Button>
                                </Box>
                            </Grid>



                        </Grid>


                    </Container>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={this.state.snackbarOpen} autoHideDuration={3000} onClose={this.handleClosesnack} >
                        <Alert severity="success">
                            {this.state.snackbarMessage}
                        </Alert>
                    </Snackbar>
                    <Snackbar anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }} open={this.state.snackbarOpenError} autoHideDuration={3000} onClose={this.handleCloseError} >
                        <Alert severity="error">
                            {this.state.snackbarMessageError}
                        </Alert>
                    </Snackbar>
                </ThemeProvider>
                <div style={{ marginTop: '12em' }}>
                    <Footer navigation={this.props.navigation} id={""} match={undefined} location={undefined} history={undefined} />
                </div>
            </>

        );
    }
}
const webStyle = {
    marginGrid: {
        marginTop: '10px'
    },
    carrerfont: {
        width: "600px",
        height: "90px",
        color: "#323232",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
    },
    EmailOutlinedIcon: {
        color: "#b5b5be"
    },
    BoxImageStyle: {
        width: "100%",
        paddingTop: '1.5em'
    },
    ContainerWidth: {
        margin: "20px auto",
        width: '95%',
        height: '100vh',
    },
    landingToAnotherPage: {
        display: "flex",
        alignItems: "center",
        marginBottom: "30px",
    },
    SignupFormSubmitStyle: {
        width: "100%",
        height: "52px",
        marginTop: "50px",
        marginBottom: "35px",
        border: "none",
        borderRadius: "10px",
        backgroundColor: " #fab03d",
        color: "#fafafb",
        fontFamily: "Poppins",
        fontSize: " 16px",
        fontWeight: 600,
    },
    LinkToForgetPassword: {
        width: "112px",
        height: " 17px",
        color: "#57afeb",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 500,
        lineHeight: "17px",
        letterSpacing: "0.1px",
    },
    CustomCheckBox: {
        color: "#282828",
        height: "17px",
        width: "17px",
    },
    RembeberLabel: {
        color: "#696974",
        fontFamily: "Roboto",
        fontSize: "14px",
        fontWeight: 400,
    },
    formFlex: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        marginTop: "27px",
    },
    fontColour: {
        color: "#171725"
    },
    mainWrapper: {
        display: "flex",
        flexDirection: "row" as "row",
        height: "100%",
        justifyContent: "center",
        boxShadow: "3px -5px 40px rgba(205,205,212,0.1)",
    },
    mainInnerWrapper: {

        // display: "flex",
        alignItems: "stretch",
        justifyContent: "center",
    },
    leftWrapper: {
        flex: 1,
        border: "1px solid #f1f1f5",
        borderTopLeftRadius: "50px",
        borderBottomLeftRadius: "50px",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
    },

    innerLeftStyle: {
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "90px",
        textAlign: "center",
    },

    rightWrapper: {
        flex: 1,
        borderTopRightRadius: "50px",
        borderBottomRightRadius: "50px",
        backgroundColor: "#fbfbfb",
        display: "flex",
        flexDirection: "column" as "column",
        alignItems: "center",
        justifyContent: "center",
    },

    innerRightStyle: {
        paddingLeft: "50px",
        paddingRight: "50px",
    },

    imgStyle: {
        width: "100%",
    },

    textStyleRight: {
        marginTop: "43px",
        marginBottom: "36px",
        height: "42px",
        color: "#171725",
        fontFamily: "Poppins",
        fontSize: "28px",
        fontWeight: 600,
        lineHeight: "42px",
        letterSpacing: "0.12px",
    },

    inputLabelStyle: {
        marginBottom: "10px",
        width: "220px",
        height: "22px",
        color: "#555555",
        fontFamily: "Roboto",
        fontSize: "12px",
        fontWeight: 400,
        lineHeight: "22px",
    },

    inputBoxStyle: {
        marginTop: "27px",
    },
    textStyleInquiry: {
        color: "#696974",
        fontFamily: "Roboto",
        fontSize: " 16px",
        fontWeight: 400,
        lineHeight: "19px",
        letterSpacing: "0.1px",
    },
    btnStyleInquiry: {
        marginTop: "25px",
        textDecoration: "underline",
        fontFamily: "Roboto",
        fontSize: " 16px",
        fontWeight: 500,
        lineHeight: "19px",
    },
    textStyleLeft: {
        marginTop: "15px",
        color: "#757575",
        fontFamily: "Poppins",
        fontSize: " 25px",
        fontWeight: 600,
        lineHeight: "36px",
        letterSpacing: "0.1px",
        alignText: "center",
        width: "443px"
    },
    errorStyle: {
        padding: "5px",
        color: "red",
        fontFamily: "Roboto",
        fontSize: " 12px",
    },
    linkStyle: {
        color: 'black',
        textDecoration: 'underline',
        textDecorationColor: 'black',
    },
};
// Customizable Area End
