import React from "react";
// Customizable Area Start
import Brightness1Icon from '@material-ui/icons/Brightness1';
import { AppBar, Box, IconButton, Toolbar, Menu, MenuItem, Typography, Grid, Dialog, Button } from "@material-ui/core";
import CatalogueController, { Props } from "./CatalogueController";
import { withStyles } from "@material-ui/core/styles";
import {
    finLogo,
    profile,
    password,
    signOut,
    switchProfile,
    headerProfile,
} from "../../landingpage/src/assets";
import { logoutUser } from "../../../framework/src/Utilities";
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';

export default class AppHeader extends CatalogueController {
    constructor(props: Props) {
        super(props);
    }
    async componentDidMount() { 
        super.componentDidMount();
        const userRolesFromLocalStorage = localStorage.getItem("user_roles");
        if(userRolesFromLocalStorage){
            this.userRoles = JSON.parse(userRolesFromLocalStorage);
        }
    }

    handleClick = (e: any) => {
        this.setState({ openProfileMenus: e.currentTarget });
    };
    handleClose = (e: any, option: string) => {
        this.setState({ openProfileMenus: false });
        if (option === 'profile') {
            this.props.navigation.navigate("Profile");
        } else if (option === 'changePassword') {
            this.props.navigation.navigate("ChangePassword");
        } else if (option === 'signOut') {
            this.handleSignOut();
        } else {
            this.setState({ openProfileMenus: false });
        }

    };

    handleSwitchClick = (e: any) => {
        this.setState({ openSwitch: !this.state.openSwitch });
    };
    handleSwitchClose = (e: any) => {
        this.setState({ openSwitch: false });
    };
    handleSignOut = () => {
        this.setState({ sign_out_pop_up: !this.state.sign_out_pop_up });
    };

    userSignOut = () => {
        logoutUser('');
     
        this.props.navigation.navigate("LandingPage");
        location.reload();
        localStorage.clear();
    };

    handleSwitchProfileClick = (roleId: number) =>{
        this.handleSwitchProfileApiCall(roleId);
    }   

    returnRoleNameBasedOnRoleId = (roleId: number) => {
        let roleName;
        switch(roleId){
            case 1:
                roleName = "As Debt Seeker";
                break;
            case 2:
                roleName = "As Debt Provider";
                break;
            case 3:
                roleName = "As Debt Arranger";
                break;
            default:
                roleName = "";
                break;
        }
        return roleName
    }
    render() {
        return (
            <div>
                <AppBar position="fixed" color="inherit" style={{ zIndex: 1201 }}>
                    <Toolbar>
                        <img src={finLogo} alt="finmart" style={{ height: "40px", maxWidth: "100%",cursor: "pointer"}} onClick={()=>{this.props.navigation.navigate("LandingPage")}} />
                        <Box flexGrow={1} />
                        <Typography style={{color: 'balck',fontWeight:'bold'}}>Welcome! {this.state.userName}</Typography>
                        <IconButton
                            id="basic-button"
                            aria-controls={this.state.openProfileSettings ? 'basic-menu' : undefined}
                            aria-haspopup="true"
                            style={{width :'4%'}}
                            aria-expanded={this.state.openProfileSettings ? 'true' : undefined}
                            onClick={this.handleClick}
                        >
                              <Brightness1Icon style = {{color : 'rgb(0,29,85)',marginLeft : '-34%'}}/>
                        </IconButton>
                        <Menu
                            id="basic-menu"
                            anchorEl={this.state.openProfileMenus}
                            open={Boolean(this.state.openProfileMenus)}
                            onClose={this.handleClose}
                            getContentAnchorEl={null}
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                        >
                            <CustomMenuItem onClick={(e: any) => this.handleClose(e, 'profile')}>
                                <IconButton style={webstyles.iconBtn}>
                                    <img src={profile} style={webstyles.menuIcon} />
                                </IconButton>
                                <Typography style={webstyles.menuText}>
                                    Profile
                                </Typography>
                            </CustomMenuItem>
                            
                            <CustomMenuItem onClick={this.handleSwitchClick}>
                                <IconButton style={webstyles.iconBtn}>
                                    <img src={switchProfile} style={webstyles.menuIcon} />
                                </IconButton>
                                <Typography style={webstyles.menuText}>
                                    Switch Profile
                                    {this.state.openSwitch ?
                                        <ExpandLess style={webstyles.expandIcon} />
                                        :
                                        <ExpandMore style={webstyles.expandIcon} />}
                                </Typography>
                                
                            </CustomMenuItem>
                            {
                                this.state.openSwitch ? this.userRoles.length>0 ?  this.userRoles.map((userRole: number)=>(
                                    <CustomMenuItem key={userRole} onClick={(e: any) => this.handleSwitchProfileClick(userRole)}>
                                       <Typography data-testid={`user-profile-${userRole}`} style={webstyles.profileText}> {this.returnRoleNameBasedOnRoleId(userRole)}</Typography>
                                    </CustomMenuItem> 
                                )) : <p style={{margin:'0 0 0 58px'}}>No Roles are added</p> : null
                            }
                            
                            <CustomMenuItem onClick={(e: any) => this.handleClose(e, 'changePassword')}>
                                <IconButton style={webstyles.iconBtn}>
                                    <img src={password} style={webstyles.menuIcon} />
                                </IconButton>
                                <Typography style={webstyles.menuText}>
                                    Change Password
                                </Typography>
                            </CustomMenuItem>
                            <CustomMenuItem onClick={(e: any) => this.handleClose(e, 'signOut')}>
                                <IconButton style={webstyles.iconBtn}>
                                    <img src={signOut} style={webstyles.menuIcon} />
                                </IconButton>
                                <Typography style={webstyles.menuText}>
                                    Sign Out
                                </Typography>
                            </CustomMenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <Grid>
                    <Dialog
                        // onClose={this.handleClose}
                        open={this.state.sign_out_pop_up}
                        className="sign_out_pop_up"
                        disableBackdropClick={true}
                    >
                        <Typography
                            className="sign_out_text"
                            align="center"
                        >
                            Are you sure you want to sign out?
                        </Typography>
                        <Grid>
                            <Button
                                onClick={this.handleSignOut}
                                variant="outlined"
                                className="cancel_button"
                            >
                                Cancel
                            </Button>
                            <Button
                                onClick={this.userSignOut}
                                className="sign_out_button"
                                variant="contained"
                            >
                                Sign Out
                            </Button>
                        </Grid>
                    </Dialog>
                </Grid>
            </div>
        );
    }
}

const webstyles = {
    menuIcon: {
        height: '25px',
        width: '25px',
        marginRight: '5px',
        fill: '#0A1738'
    },
    menuText: {
        fontFamily: 'Lato',
        fontWeight: 600,
        color: '#0A1738',
        fontSize: '16px'
    },
    profileText: {
        fontFamily: 'Lato',
        fontWeight: 600,
        color: 'black',
        fontSize: '16px',
        paddingLeft: '22%'
    },
    iconBtn: {
        paddingLeft: '0px'
    },
    expandIcon: {
        height: '20px',
        width: '20px',
        marginTop:'3%'
    }
}

const CustomMenuItem:any = withStyles({
    root: {
        height: '45px',
        width: '240px'
    }
})(MenuItem);
// Customizable Area End
