Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.apiGetMethod="GET";
exports.getAllApprovePendingApiEndPoint="bx_block_bank_deal/debt_deals/get_approve_and_pending_deals";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "ContentManagement";
exports.labelBodyText = "ContentManagement Body";

exports.btnExampleTitle = "CLICK ME";
exports.getDealsForDashboardApiEndpoint = "bx_block_bank_deal/dashboard/filter_deals";
exports.getDealsForDashboardApiMethod = "POST";
exports.getManageDealsListApiEndpoint = "bx_block_bank_deal/debt_deals";
exports.getManageDealsListApiMethod = "GET";
exports.getTakeActionForOnGoingDealsApiEndpoint = "bx_block_bank_deal/dashboard/ongoing_deal";
exports.getTakeActionForOnGoingDealsApiMethod = "GET";
exports.showAllocateApiEndpoint = "bx_block_bank_deal/dashboard/show_offers/";
exports.showAllocateApiMethod = "GET";
exports.sendOtpForAcceptApiEndpoint = "bx_block_bank_deal/offers/send_otp/";
exports.sendOtpForAcceptApiEndMethod = "POST";
exports.verifyOtpForAcceptApiEndpoint = "bx_block_bank_deal/offers/accept_offer_with_otp/";
exports.verifyOtpForAcceptApiMethod = "POST";
exports.deleteDealApiEndpoint = "bx_block_bank_deal/debt_deals";
exports.deleteDealApiMethod = "DELETE";
exports.getApproveDealsListApiEndpoint = "bx_block_bank_deal/debt_deals/get_approval_deals";
exports.getApproveDealsListApiMethod = "GET";
exports.getOngoingDealsHistoryApiEndpoint = "bx_block_bank_deal/dashboard/get_deal_history/";
exports.getOngoingDealsHistoryApiMethod = "GET";
exports.rejectOfferApiEndpoint = "bx_block_bank_deal/offers/reject_offer/";
exports.rejectOfferApiMethod = "PUT";
exports.withdrawDealApiEndpoint = "bx_block_bank_deal/dashboard/withdraw_deal/";
exports.withdrawDealApiMethod = "GET";
exports.handleSubmitDecisionApiEndpoint = "bx_block_bank_deal/debt_deals/review_deals_decision";
exports.handleSubmitDecisionApiMethod = "PUT";
exports.acceptedOfferFileUploadApiEndpoint = "bx_block_bank_deal/offers/upload_files";
exports.acceptedOfferFileUploadApiMethod = "PUT";
exports.getOnGoingDealsFilterOptionsApiEndpoint = "bx_block_bank_deal/dashboard/deals_filter_dropdown";
exports.getOnGoingDealsFilterOptionsApiMethod = "GET";
exports.getSeekerNamesForOptionsApiEndpoint = "bx_block_bank_deal/debt_deals/get_seeker_list";
exports.getSeekerNamesForOptionsApiMethod = "GET";
exports.getArrangerDealsListApiEndpoint = "bx_block_bank_deal/debt_deals/filter_seekers_deals";
exports.getArrangerDealsListApiMethod = "GET";
exports.acceptedOfferFileRemoveApiEndpoint = "bx_block_bank_deal/offers/delete_uploaded_file/";
exports.acceptedOfferFileRemoveApiMethod = "GET"
exports.connectWithInvestorApiEndpoint = 'bx_block_bank_deal/dashboard/notify_by_mail_to_connect_with_investor'
exports.initiateChatApiEndpoint='bx_block_bank_deal/dashboard/notify_by_mail_to_initiate_chat'

// Customizable Area End