import React from "react";
// Customizable Area Start
import { Box, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Tooltip, Typography } from "@material-ui/core";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Backdrop from "@material-ui/core/Backdrop";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import { Role_name, showTextWithValidation } from "../../../components/src/common";
import AppHeader from "./AppHeader.web";
import { accordian, filter } from "./assets";
import DealDiscoveryController, { Props } from "./DealDiscoveryController";
import Chat9 from "../../Chat9/src/Chat9.web";

import "./style.css";


// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import 'swiper/swiper-bundle.css';
// import required modules
import { Navigation, Pagination } from "swiper";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";

class DealDiscovery extends DealDiscoveryController {
    constructor(props: Props) {
        super(props);

    }

    render() {
        const settings = {
            slidesToShow: 2.6,
            slidesToScroll: 1,
            dots: false,
            arrows: true,
            swipeToSlide: true,
            infinite: false,
            centerMode: false,
            draggable: false,
            touchMove: false,
        };

        function TextWithValidation(text: string | null | undefined) {
            if (!text || text.length === 0) {
                return "";
            }
            return text;
        }

        const handleClick = () => {
            this.setState({ openFilter: !openFilter });
        };
        const handleClick2 = () => {
            this.setState({ openFilter2: !openFilter2 });
        };
        const handleClick3 = () => {
            this.setState({ openFilterCurrent: !openFilterCurrent });
        };
        const handleClick4 = () => {
            this.setState({ openFiltertTransaction: !openFiltertTransaction });
        };

        const handleClickAway = () => {

            if (openFilter) {
                console.log("** called");

                this.handleSubmitController();
            }
            if (openFilter2) {
                this.setState({ openFilter2: false });
            }
            if (openFilterCurrent) {
                this.setState({ openFilterCurrent: false });
            }
            if (openFiltertTransaction) {
                this.setState({ openFiltertTransaction: false });
            }
        };
        const handleSortBy = (e: any) => {
            if (e.target.value) {
                this.setState({ sort_by: e.target.value, sortbyTop: 85 }, () => {
                    sort_type && this.handleSubmitController();
                });
            } else {
                this.setState({ sort_by: '' })
                !sort_type && this.getDealDiscovery()

            }

        };
        const sortByIssuer = (e: any) => {
            this.setState({ sortByIssuerId: e.target.value }, () => {
                this.getTransactionFilter()
            });
        }
        const handleSortType = (e: any) => {
            if (e.target.value) {
                this.setState({ sort_type: e.target.value, sortTypeTop: 85 }, () => {
                    sort_by && this.handleSubmitController();
                });
            } else {
                this.setState({ sort_type: '' });
                !sort_by && this.getDealDiscovery()
            }
        };

        const handleUtrNumber = (e: any, id: any) => {
            this.setState({ currentHoldingCardId: id })
            var utrnumber = e.target.value.toUpperCase();

            if (utrnumber[4] === 'R') {
                this.setState({ range: 22 })
            }

            if (utrnumber[4] === 'N') {
                this.setState({ range: 16 })
            }

            if (range === 22 || range === 5) {
                const regex = /^[a-zA-Z]{4}[Rr]{1}[0-9a-zA-Z]{1}[0-9]{16}/;
                !regex.test(utrnumber)
                    ? this.setState({ utrError: true })
                    : this.setState({ utrError: false });
                this.setState({ utrNumber: utrnumber.toUpperCase() });
            }
            if (range === 16 || range === 5) {
                const regex = /^[a-zA-Z]{4}[Nn]{1}[0-9]{11}/;
                !regex.test(utrnumber)
                    ? this.setState({ utrError: true })
                    : this.setState({ utrError: false });
                this.setState({ utrNumber: utrnumber.toUpperCase() });
            }
        }

        const handleUtr = (id: any) => {
            /* istanbul ignore next */
            if (utrError) {
                const msg185: Message = new Message(getName(MessageEnum.CustomAlertMessage));
                msg185.addData(getName(MessageEnum.CustomAlertBodyData), "Please enter valid UTR number");
                this.send(msg185);
            } else {
                this.postUtrNumber(id)
            }
        }

        const checkTotalDeal = (myString: any) => {
            let str = parseFloat(myString)
            // return str = str ? str/10000000 : 0;
            return str = str ? str : 0;
        }

        const {
            kyc, range,
            openModal,
            openFilter,
            openFilter2,
            dealDiscovery,
            role, utrNumber,
            filterData,
            transactionDropdownId,
            currentHoldingDropdownId,
            openFiltertTransaction,
            transactionDetails,
            transactionFilter,
            currentData,
            onGoingDealsData,
            currentHoldingsData,
            transactionHistory,
            sort_by, utrError,
            sort_type,
            proposed_total_tenor,
            onGoingDetailsData,
            ongoingDropdownId,
            onGoingHistoryMeta,
            yieldData,
            proposed_sectorsTitle,
            proposed_type_of_instrument_title,
            rating_details_rating_title,
            onGoingModal,
            projectDropdown,
            OngoingProjectDropdown,
            currentProjectDropdown,
            openFilterCurrent,
            deal_status,
            sortByIssuerId, transactionExpand2, onGoingLoading,
            currentHoldingCardId, expanded, onGoingExpanded, currentHoldingExpand, transactionExpand,
        } = this.state;
        const handleAccordian = (name: string, value: any, title: any) => {
            if (name == "Last added Yield") {
                this.setState({ yieldData: value }, () => { handleClickAway() });
                handleAccordion(false, `panel0`)
                handleClickAway()
            } else if (name == "Tenor") {
                this.setState({ proposed_total_tenor: value }, () => { handleClickAway() });
                handleAccordion(false, `panel1`)
            } else if (name == "Type of Instrument") {
                this.setState({ proposed_type_of_instrument_id: value }, () => { handleClickAway() });
                this.setState({ proposed_type_of_instrument_title: title });
                handleAccordion(false, `panel2`)

            } else if (name == "Credit Rating") {
                this.setState({ rating_details_rating_id: value }, () => { handleClickAway() });
                this.setState({ rating_details_rating_title: title });
                handleAccordion(false, `panel3`)

            } else if (name == "Sector") {
                this.setState({ proposed_sectors: value }, () => { handleClickAway() });
                this.setState({ proposed_sectorsTitle: title });
                handleAccordion(false, `panel4`)

            } else {
                console.log("Something went wrong...");
            }
        };
        const handleUnSelectAccordian = (name: string, value: any, title: any) => {
            if (name == "Last added Yield") {
                this.setState({ yieldData: '' }, () => { handleClickAway() });
                handleAccordion(false, `panel0`)
            } else if (name == "Tenor") {
                this.setState({ proposed_total_tenor: '' }, () => { handleClickAway() });
                handleAccordion(false, `panel1`)
            } else if (name == "Type of Instrument") {
                this.setState({ proposed_type_of_instrument_id: '' }, () => { handleClickAway() });
                this.setState({ proposed_type_of_instrument_title: '' });
                handleAccordion(false, `panel2`)

            } else if (name == "Credit Rating") {
                this.setState({ rating_details_rating_id: '' }, () => { handleClickAway() });
                this.setState({ rating_details_rating_title: '' });
                handleAccordion(false, `panel3`)

            } else if (name == "Sector") {
                this.setState({ proposed_sectors: '' }, () => { handleClickAway() });
                this.setState({ proposed_sectorsTitle: '' });
                handleAccordion(false, `panel4`)

            } else {
                console.log("Something went wrong...");
            }
        };

        const handleOngoingDropdown = (id: any) => {

            if (id === ongoingDropdownId) {
                this.setState({ ongoingDropdownId: '' });
                this.getOngoingDeals()

            } else {
                this.setState({ ongoingDropdownId: id });
                this.onGoingAfterFilter(id, "onGoing");
            }
            this.setState({ onGoingExpanded: !onGoingExpanded })


        };
        const handleCurrentDropdown = (id: any) => {
            if (id === currentHoldingDropdownId) {
                this.setState({ currentHoldingDropdownId: '' });
                this.getCurrentHolding()
            } else {
                this.setState({ currentHoldingDropdownId: id });
                this.onGoingAfterFilter(id, "onTransaction");
            }
            this.setState({ currentHoldingExpand: !currentHoldingExpand })
        };
        const handleTransactionDropdown = (id: any) => {
            if (id === transactionDropdownId) {
                this.setState({ transactionDropdownId: '' }, () => {
                    this.getTransactionFilter()
                });
            } else {
                this.setState({ transactionDropdownId: id }, () => {
                    this.getTransactionFilter()
                });
            }
            this.setState({ transactionExpand: !transactionExpand })
        };

        const handleTransactionDealStatus = (value: string) => {
            if (value === deal_status) {
                this.setState({ deal_status: '' }, () => {
                    this.getTransactionFilter()
                });
            } else {
                this.setState({ deal_status: value }, () => {
                    this.getTransactionFilter()
                });
            }
            this.setState({ transactionExpand2: !transactionExpand2 })


        }

        const manageDealHistory = (e: any, id: any) => {
            e.stopPropagation()
            ongoingDetails(id)
        }

        const gotoDealDetails = (id: any) => {
            this.props.history.push(`/DealDetails?dealId=${id}`)
        }

        const handleCurrentData = (e: any) => {
            var curData = e.target.value;
            const kyc = localStorage.getItem("is_kyc_done")
            this.setState({ currentData: curData });
            this.setState({
                transactionDetails: [], deal_status: '', transactionDropdownId: '', sort_type: '', sort_by: '',
                sortByIssuerId: '', currentHoldingDropdownId: '', proposed_sectors: '', proposed_sectorsTitle: '', rating_details_rating_id: '', rating_details_rating_title: '', proposed_type_of_instrument_id: '', proposed_type_of_instrument_title: '', ongoingDropdownId: '', yieldData: '', proposed_total_tenor: '',
            });

            this.props.history.push(`/DealDiscovery?current=${curData}`);
            if (curData === 'Discover_Deals') {
                this.getDealDiscovery()
            }
            if (curData === 'Ongoing_Deals') {
                if (kyc === 'true') {
                    this.getOngoingDeals()
                }
            }
            if (curData === 'Current_Holdings') {
                if (kyc === 'true') {
                    this.getCurrentHolding()
                }
            }
            if (curData === 'Transaction_History') {
                if (kyc === 'true') {
                    this.getTransactionHistory()
                }
            }
        };

        const ongoingDetails = (id: any) => {
            this.setState({ onGoingModal: true });
            this.getDeal_History(id);
        };
        const transactionDetail = (id: any) => {
            this.getDeal_Transaction_History(id);
        };
        const handleAccordion = (isExpanded: boolean, panel: string) => {
            this.setState({ expanded: isExpanded ? panel : false })

        }
        return (
            <div style={{ width: "100vw", overflowX: "hidden" }}>
                <AppHeader id="" location="" history="" navigation={this.props.navigation} match="" />

                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={openModal}
                    onClose={() => this.setState({ openModal: false })}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={openModal}>
                        <div className="modal_paper" style={{ borderRadius: "12px" }}>
                            <p className="sub_header BlueText" style={{ textAlign: "center" }}>
                                {" "}
                                Please complete the sign up / login process.{" "}
                            </p>
                            <div className="justify_center">
                                <Link to="EmailAccountLoginBlock">
                                    <button
                                        onClick={() => this.setState({ openModal: false })}
                                        className="next_button "
                                    >
                                        Okay
                                    </button>
                                </Link>
                            </div>
                        </div>
                    </Fade>
                </Modal>

                <div style={{ padding: "40px 40px 0 50px", marginTop: "50px" }}>
                    <Grid container spacing={2}>
                        {(!kyc || !this.state.isBank) && (
                            <Link to="CfEkycVerification3" style={{ textDecoration: 'none', width: '100%' }}>
                                <Grid xs={12} >
                                    <Tooltip title="Click here to complete kyc" placement="top">
                                        <p className="pending_kyc">Please complete the pending KYC</p>
                                    </Tooltip>
                                </Grid>
                            </Link>
                        )}
                        {(currentData === "Discover_Deals" || currentData === "Ongoing_Deals") && kyc && this.state.isBank && this.state.isDemat ? (
                            <Link to="CfEkycVerification3?demat=true" style={{ textDecoration: 'none', width: '100%' }}>
                                <Grid xs={12} >
                                    <Tooltip title="Click here to complete demat" placement="top">
                                        <p className="pending_kyc">For all the deals where the Type of Instrument is Bond, please add the Demat details.</p>
                                    </Tooltip>
                                </Grid>
                            </Link>
                        ) : null}
                    </Grid>
                    <div className="radio">
                        <input
                            type="radio"
                            className="radio__input"
                            value="Discover_Deals"
                            name="myRadio"
                            onChange={handleCurrentData}
                            id="myRadio1"
                            checked={currentData === "Discover_Deals"}
                        />
                        <label className="radio__label" htmlFor="myRadio1">
                            Discover Deals
                        </label>
                        <input
                            type="radio"
                            className="radio__input"
                            value="Ongoing_Deals"
                            name="myRadio"
                            onChange={handleCurrentData}
                            id="myRadio2"
                            checked={currentData === "Ongoing_Deals"}
                        />
                        <label className="radio__label" htmlFor="myRadio2">
                            Ongoing Deal(s)
                        </label>
                        <input
                            type="radio"
                            className="radio__input"
                            value="Current_Holdings"
                            name="myRadio"
                            onChange={handleCurrentData}
                            id="myRadio3"
                            checked={currentData === "Current_Holdings"}

                        />
                        <label className="radio__label" htmlFor="myRadio3">
                            Current Holdings
                        </label>
                        <input
                            type="radio"
                            className="radio__input"
                            value="Transaction_History"
                            name="myRadio"
                            onChange={handleCurrentData}
                            id="myRadio4"
                            checked={currentData === "Transaction_History"}
                        />
                        <label className="radio__label" htmlFor="myRadio4">
                            Transaction History
                        </label>
                    </div>
                    {
                        currentData === "Discover_Deals" &&
                        <> <p className="header">Explore Alternative Investment Opportunities</p>
                            <div style={{ color: "#323232 ", fontSize: "18px" }}>
                                <p>
                                    TruJasper is a one stop solution for deal discovery, Funding, Monitoring & trade. Specially crafted to cater to the needs of Institutional & retail Investors, HNIs/ UHNIs, Treasury offices.
                                </p>

                                <p>Offerings on the MKP ranges from plain vanilla trades to complex structured products.</p>
                            </div>
                            <p className="header">Opportunities.......</p>
                            <div className="filter_wrapper">

                                <ClickAwayListener onClickAway={() => this.setState({ openFilter: false })}>
                                    <div style={{ position: "relative" }}>
                                        <div className="filter" onClick={handleClick}>
                                            <div> Filters</div>
                                            <img src={filter} width="20px" alt="" />
                                        </div>
                                        {openFilter ? (
                                            <div className="popUp">
                                                {filterData?.map((data: any, i: any) => (
                                                    <Accordion style={{ margin: "0" }} expanded={expanded === `panel${i}`} onChange={(e, isExpanded) => handleAccordion(isExpanded, `panel${i}`)}>
                                                        <AccordionSummary
                                                            expandIcon={<img src={accordian} width="13px" alt="" />}
                                                            aria-controls="panel1a-content"
                                                            id="panel1a-header"
                                                        >
                                                            <Typography style={{ fontSize: "18px" }} className="BlueText">
                                                                {data?.name}
                                                            </Typography>
                                                        </AccordionSummary>
                                                        {data?.value?.map((val: any, index: any) =>
                                                            typeof val == "object" ? (
                                                                <AccordionDetails>
                                                                    {val?.title == rating_details_rating_title ||
                                                                        val?.title ==
                                                                        proposed_type_of_instrument_title ||
                                                                        val?.title == proposed_sectorsTitle ? <Typography
                                                                            className="accordian_value_active"
                                                                            onClick={() =>
                                                                                handleUnSelectAccordian(data?.name, val?.id, val?.title)
                                                                            }
                                                                        >
                                                                        {val?.title}
                                                                    </Typography> :
                                                                        <Typography
                                                                            className="accordian_value"
                                                                            onClick={() =>
                                                                                handleAccordian(data?.name, val?.id, val?.title)
                                                                            }
                                                                        >
                                                                            {val?.title}
                                                                        </Typography>}
                                                                </AccordionDetails>
                                                            ) : (
                                                                <AccordionDetails>
                                                                    {val == yieldData || val == proposed_total_tenor ? <Typography
                                                                        className="accordian_value_active"
                                                                        onClick={() => handleUnSelectAccordian(data?.name, val, '')}
                                                                    >
                                                                        {val}
                                                                    </Typography> :
                                                                        <Typography
                                                                            className="accordian_value"
                                                                            onClick={() => handleAccordian(data?.name, val, '')}
                                                                        >
                                                                            {val}
                                                                        </Typography>}
                                                                </AccordionDetails>
                                                            )
                                                        )}
                                                    </Accordion>
                                                ))}
                                            </div>
                                        ) : null}
                                    </div>
                                </ClickAwayListener>

                                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                                    <div className="sort">Sort By</div>
                                    <FormControl variant="outlined" size="small">
                                        <Select
                                            className="select_textInput"
                                            disableUnderline
                                            displayEmpty
                                            style={{ width: "200px" }}
                                            name="share_holder_by_id"
                                            IconComponent={() => (<KeyboardArrowDownIcon style={{ position: "absolute", right: 12, pointerEvents: "none" }} />
                                            )}
                                            onChange={(e) => handleSortBy(e)}
                                            value={sort_by}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: "20px",
                                                        marginTop: `${this.state.sortbyTop}`,
                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                sort_by ? <option value="" className="clear">Clear</option> : <MenuItem value="" style={{ display: 'none' }}>-Select one-</MenuItem>
                                            }
                                            {this.state.sortByData?.map((item: any, i: any) => (
                                                <option className="select_option_1" key={i} value={item}>
                                                    {item}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    <FormControl variant="outlined" size="small">
                                        <Select
                                            className="select_textInput"
                                            displayEmpty
                                            disableUnderline
                                            style={{ width: "200px" }}
                                            name="share_holder_type_id"
                                            IconComponent={() => (
                                                <KeyboardArrowDownIcon
                                                    style={{ position: "absolute", right: 12, pointerEvents: "none" }}
                                                />
                                            )}
                                            onChange={(e) => handleSortType(e)}
                                            value={sort_type}
                                            MenuProps={{
                                                PaperProps: {
                                                    style: {
                                                        borderRadius: "20px",
                                                        marginTop: `${this.state.sortTypeTop}`

                                                    }
                                                }
                                            }}
                                        >
                                            {
                                                sort_type ? <option value="" className="clear">Clear</option> : <MenuItem value="" style={{ display: 'none' }}>-Select one-</MenuItem>
                                            }
                                            {this.state.sortTypeData?.map((item: any, i: any) => (
                                                <option className="select_option_1" key={i} value={item?.id}>
                                                    {item?.value}
                                                </option>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </div>

                            </div>
                            <div style={{ height: "700px", marginTop: "24px", paddingBottom: "200px" }}>
                                {this.state.spinner ? <div className="circular_progress"> <CircularProgress /> </div> : <Swiper
                                    slidesPerView={3}
                                    navigation={true}
                                    allowTouchMove={false}
                                    spaceBetween={30}
                                    modules={[Pagination, Navigation]}>
                                    {
                                        dealDiscovery?.length > 0 ? dealDiscovery?.map((deal, i) => (
                                            <SwiperSlide>

                                                <div className="cardWrapper" key={i} style={{ height: '615px' }}>
                                                    <Link
                                                        to={role == Role_name.Provider ? `/DealDetails?dealId=${deal?.attributes?.id}` : "#"}
                                                        className="textDecoration unset_width"
                                                    >
                                                        <div style={{ display: "flex" }}>
                                                            <div style={{ height: "170px" }}>
                                                                <img
                                                                    src={deal?.attributes?.upload_logo_image?.url}
                                                                    style={{ borderRadius: "10px" }}
                                                                    alt="discovery"
                                                                    width="100"
                                                                    height="100"
                                                                />
                                                                <span className="rating_chip">
                                                                    {" "}

                                                                    <p className="rating_label" style={{ marginLeft: "3px" }}>
                                                                        {deal?.attributes?.proposed_instruments?.rating_details}
                                                                    </p>{" "}
                                                                </span>
                                                                <span className="rating_chip">
                                                                    {" "}
                                                                    <p className="rating_label">
                                                                        {deal?.attributes?.proposed_instruments?.sector}.
                                                                    </p>{" "}
                                                                </span>
                                                            </div>
                                                            <div
                                                                style={{
                                                                    margin: "0 14px 0 24px",
                                                                    height: "194",
                                                                    width: "94%",
                                                                }}
                                                            >
                                                                <p className="deal_companyName">
                                                                    {deal?.attributes?.debt_seeker_company_name}
                                                                </p>
                                                                <div className="cardDescriptionWrapper">
                                                                    <div className="cardDescription">
                                                                        {deal?.attributes?.company_background}
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{ margin: "0 15 0 0" }} className="normal_font">
                                                            <div className="deal_discovery_body">
                                                                <p>Instrument</p>
                                                                <p className="BlueText">
                                                                    {deal?.attributes?.proposed_instruments?.instrument}
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Total Amount (INR Cr)</p>
                                                                <p className="BlueText">
                                                                    Rs.{" "}
                                                                    {checkTotalDeal(deal?.attributes?.proposed_instruments?.total_deal_size)} Cr
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Minimum Investment (INR Cr)</p>
                                                                <p className="BlueText">
                                                                    Rs.{" "}
                                                                    {parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment) ? parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment).toLocaleString('en-In') : 0} Cr
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Expected Returns (Gross)</p>
                                                                <p className="BlueText">
                                                                    {
                                                                        parseFloat(deal?.attributes?.proposed_instruments
                                                                            ?.expected_returns_gross) ? parseFloat(deal?.attributes?.proposed_instruments
                                                                                ?.expected_returns_gross) : 0
                                                                    }% IRR
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Maturity Date</p>
                                                                <p className="BlueText">
                                                                    {deal?.attributes?.proposed_instruments
                                                                        ?.repayment_maturity_date && moment(
                                                                            deal?.attributes?.proposed_instruments
                                                                                ?.repayment_maturity_date
                                                                        ).format("DD-MMM-YYYY")}
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Repayment Frequency</p>
                                                                <p className="BlueText">
                                                                    {
                                                                        deal?.attributes?.proposed_instruments
                                                                            ?.repayment_frequency
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                margin: "10 14px",
                                                            }}
                                                        >
                                                            <Link
                                                                to={
                                                                    role == Role_name.Provider && kyc && this.state.isBank
                                                                        ? `/DealDetails?dealId=${deal?.attributes?.id}`
                                                                        : "#"
                                                                }
                                                            >
                                                                <button
                                                                    className={
                                                                        role == Role_name.Provider && kyc && this.state.isBank ? "next_button" : "disable_button"
                                                                    }
                                                                    style={{ width: "134px" }}
                                                                >
                                                                    Show Interest
                                                                </button>
                                                            </Link>
                                                        </div>
                                                        <div style={{ marginRight: '15px' }}>
                                                            <p style={{ fontSize: "18px", fontWeight: 700, marginBottom: '0px' }}>Investment Done:</p>
                                                            <div className="filter_wrapper">
                                                                <p>{deal?.attributes?.progress_bar?.deal_investment_done}/{deal?.attributes?.progress_bar?.total_deal_size}</p>
                                                                <p>{deal?.attributes?.progress_bar?.deal_investment_percentage}% Investment</p>
                                                            </div>
                                                            <div className="progressBar_outer">
                                                                <div className="progressBar_inner" style={{ width: `${deal?.attributes?.progress_bar?.deal_investment_percentage}%` }}></div>
                                                            </div>
                                                        </div>

                                                    </Link>
                                                </div>
                                            </SwiperSlide>

                                        ))
                                            : <SwiperSlide className="">
                                                <p>No Data Found</p>
                                            </SwiperSlide>}
                                </Swiper>}
                            </div>
                        </>}
                    {currentData === "Ongoing_Deals" && <div>

                        <ClickAwayListener onClickAway={handleClickAway}>
                            <div style={{ position: "relative" }}>
                                <div className="filter" onClick={handleClick2}>
                                    <div> Filters</div>
                                    <img src={filter} width="20px" alt="" />
                                </div>
                                {openFilter2 ? (
                                    <div className="popUp">
                                        {
                                            <Accordion style={{ margin: "0" }} expanded={onGoingExpanded}
                                                onClick={() => this.setState({ onGoingExpanded: !onGoingExpanded })}
                                            >
                                                <AccordionSummary
                                                    expandIcon={<img src={accordian} width="13px" alt="" />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                >
                                                    <Typography style={{ fontSize: "18px" }} className="BlueText" >
                                                        Project Name
                                                    </Typography>
                                                </AccordionSummary>

                                                {OngoingProjectDropdown?.map((val, ind) => (
                                                    <AccordionDetails>
                                                        {<Typography
                                                            onClick={() => handleOngoingDropdown(val?.id)}
                                                            className={
                                                                val?.id === ongoingDropdownId
                                                                    ? "accordian_value_active"
                                                                    : "accordian_value"
                                                            }
                                                        >
                                                            {val?.project_name}
                                                        </Typography>}
                                                    </AccordionDetails>
                                                ))}
                                            </Accordion>
                                        }
                                    </div>
                                ) : null}
                            </div>
                        </ClickAwayListener>

                        <div style={{ height: "700px", marginTop: "24px", paddingBottom: "200px" }}>
                            {onGoingLoading ? <div className="circular_progress"> <CircularProgress /> </div> : <Swiper
                                slidesPerView={3}
                                navigation={true}
                                allowTouchMove={false}
                                spaceBetween={30}
                                modules={[Pagination, Navigation]} >
                                {
                                    onGoingDealsData?.length > 0 ? onGoingDealsData?.map((deal, i) => (
                                        <SwiperSlide >
                                            <div className="cardWrapper" key={i} onClick={() => gotoDealDetails(deal?.attributes?.id)} style={{ cursor: 'pointer', height: '615px' }}>
                                                <div >
                                                    <div className="flex_center onGoing_card_header">
                                                        <div>
                                                            <span className="rating_chip">
                                                                {" "}
                                                                <p
                                                                    className="rating_label"
                                                                    style={{ marginLeft: "3px" }}
                                                                >
                                                                    {
                                                                        deal?.attributes?.proposed_instruments
                                                                            ?.rating_details?.data[0]?.attributes
                                                                            ?.rating_credit_rating_agency?.title
                                                                    }
                                                                </p>{" "}
                                                            </span>
                                                            <span className="rating_chip">
                                                                {" "}
                                                                <p className="rating_label">
                                                                    {deal?.attributes?.proposed_instruments?.sector}.
                                                                </p>{" "}
                                                            </span>
                                                        </div>
                                                        <div style={{ width: "100%", textAlign: "center" }}>
                                                            <p className="onGoing_Company_Name">
                                                                {deal?.attributes?.project_name}
                                                            </p>
                                                        </div>
                                                    </div>
                                                    <div className="flex_center">
                                                        <img
                                                            src={deal?.attributes?.upload_logo_image?.url}
                                                            style={{ borderRadius: "10px" }}
                                                            alt="discovery"
                                                            width="100"
                                                            height="100"
                                                        />
                                                        <div style={{ width: "100%", textAlign: "center" }}>
                                                            <p className="onGoing_Company_Name">{deal?.attributes?.debt_seeker_company_name}</p>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div style={{ margin: "0 15 0 0" }} className="normal_font">
                                                    <div className="deal_discovery_body">
                                                        <p>Instrument</p>
                                                        <p className="BlueText">
                                                            {deal?.attributes?.proposed_instruments?.instrument}
                                                        </p>
                                                    </div>
                                                    <div className="deal_discovery_body">
                                                        <p>Total Amount (INR Cr)</p>
                                                        <p className="BlueText">
                                                            Rs.{" "}
                                                            {checkTotalDeal(deal?.attributes?.proposed_instruments?.total_deal_size)} Cr
                                                        </p>
                                                    </div>
                                                    <div className="deal_discovery_body">
                                                        <p>Minimum Investment (INR Cr)</p>
                                                        <p className="BlueText">
                                                            Rs.{" "}
                                                            {parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment) ? parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment).toLocaleString('en-In') : 0} Cr
                                                        </p>
                                                    </div>
                                                    <div className="deal_discovery_body">
                                                        <p>Expected Returns (Gross)</p>
                                                        <p className="BlueText">
                                                            {
                                                                parseFloat(deal?.attributes?.proposed_instruments
                                                                    ?.expected_returns_gross) ? parseFloat(deal?.attributes?.proposed_instruments
                                                                        ?.expected_returns_gross) : 0
                                                            }% IRR
                                                        </p>
                                                    </div>
                                                    <div className="deal_discovery_body">
                                                        <p>Maturity Date</p>
                                                        <p className="BlueText">
                                                            {deal?.attributes?.proposed_instruments
                                                                ?.repayment_maturity_date && moment(
                                                                    deal?.attributes?.proposed_instruments
                                                                        ?.repayment_maturity_date
                                                                ).format("DD-MMM-YYYY")}
                                                        </p>
                                                    </div>
                                                    <div className="deal_discovery_body">
                                                        <p>Repayment Frequency</p>
                                                        <p className="BlueText">
                                                            {
                                                                deal?.attributes?.proposed_instruments
                                                                    ?.repayment_frequency
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                                {/* <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        margin: "10 14px",
                                                    }}
                                                >
                                                    <button
                                                        className="next_button"
                                                        style={{ width: "134px" }}
                                                        onClick={(e) => manageDealHistory(e, deal?.attributes?.id)}
                                                    >
                                                        Deal History
                                                    </button>
                                                </div> */}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "space-between",
                                                        alignItems:'center',
                                                        margin: "10 0px",
                                                    }}
                                                >
                                                   <button
                                                        className="chat_button"
                                                        style={{ width: "134px"}}
                                                        onClick= {(e)=>{this.openChatBox(e,deal.attributes.groupchat_dialog_id, deal.id,deal.attributes.project_name)}}
                                                    >
                                                        Chat
                                                    </button>
                                                    
                                                    <button
                                                        className="chat_button"
                                                        style={{ width: "134px" }}
                                                        
                                                        onClick={(e) => manageDealHistory(e,deal?.attributes?.id)}
                                                    >
                                                        Deal History
                                                    </button>
                                                </div>
                                                
                                                <div style={{ marginRight: '15px' }}>
                                                    <p style={{ fontSize: "18px", fontWeight: 700, marginBottom: '0px' }} className="BlueText">Investment Done:</p>
                                                    <div className="filter_wrapper BlueText">
                                                        <p>{deal?.attributes?.progress_bar?.deal_investment_done}/{deal?.attributes?.progress_bar?.total_deal_size}</p>
                                                        <p>{deal?.attributes?.progress_bar?.deal_investment_percentage}% Investment</p>
                                                    </div>
                                                    <div className="progressBar_outer">
                                                        <div className="progressBar_inner" style={{ width: `${deal?.attributes?.progress_bar?.deal_investment_percentage}%` }}></div>
                                                    </div>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                    ))
                                        : <SwiperSlide className="">
                                            <p>No Data Found</p>
                                        </SwiperSlide>}
                            </Swiper>}
                        </div>
                        <Modal
                            aria-labelledby="transition-modal-title"
                            aria-describedby="transition-modal-description"
                            className="modal"
                            open={onGoingModal}
                            onClose={() => this.setState({ onGoingModal: false })}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 500,
                            }}
                        >
                            <Fade in={onGoingModal}>
                                <div className="modal_paper" style={{ width: "950px" }}>
                                    <div
                                        className="deal_header_wrapper"
                                        style={{ height: "40px", marginBottom: "30" }}
                                        onClick={() => this.setState({ onGoingModal: !onGoingModal })}
                                    >
                                        <ArrowBackIosIcon />
                                        <p className="deal_header">Debt Provider Dashboard- Deal History</p>
                                    </div>
                                    <div>
                                        <Grid container style={{ marginBottom: "33px" }}>
                                            <Grid item xs={5}>
                                                <Grid container>
                                                    <Grid item xs={3}>
                                                        <Typography className="deal_history_title">Project Name:</Typography>
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        // xs={4}
                                                        className="deal_history_value"
                                                        style={{ marginLeft: "17px" }}
                                                    >
                                                        {showTextWithValidation(
                                                            onGoingHistoryMeta?.project_detail?.project_name
                                                        )}
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ marginTop: '5px' }}>
                                                    <Grid className="deal_history_title" >Debt Seeker Name:</Grid>
                                                    <Grid item xs={4} className="deal_history_value">
                                                        {onGoingHistoryMeta?.seeker_data?.first_name}{" "}
                                                        {TextWithValidation(onGoingHistoryMeta?.seeker_data?.last_name)}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={5} style={{ marginLeft: "30px" }}>
                                                <Grid container>
                                                    <Grid className="deal_history_title">Instrument Type:</Grid>
                                                    <Grid className="deal_history_value">
                                                        {showTextWithValidation(onGoingHistoryMeta?.instrument_type)}
                                                    </Grid>
                                                </Grid>
                                                <Grid container style={{ marginTop: '5px' }}>
                                                    <Grid item xs={3} className="deal_history_title">
                                                        Maturity Date:
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        // xs={3}
                                                        className="deal_history_value"
                                                        style={{ marginLeft: "5px" }}
                                                    >
                                                        {onGoingHistoryMeta?.maturity_date && showTextWithValidation(moment(onGoingHistoryMeta?.maturity_date).format("DD-MMM-YYYY"))}
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                        <Grid className="grid_history_list_box" style={{ overflowY: "unset" }}>
                                            <table style={{ width: "100%", borderSpacing: "10px 0" }}>
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            {" "}
                                                            <Typography variant="h6" className="catalog_input_header BlueText">
                                                                Date
                                                            </Typography>
                                                        </th>
                                                        <th>
                                                            {" "}
                                                            <Typography variant="h6" className="catalog_input_header BlueText">
                                                                Event Type
                                                            </Typography>
                                                        </th>
                                                        <th>
                                                            <Typography variant="h6" className="catalog_input_header BlueText">
                                                                Amount (INR Cr)
                                                            </Typography>
                                                        </th>
                                                        <th>
                                                            <Typography variant="h6" className="catalog_input_header BlueText">
                                                                Yield
                                                            </Typography>
                                                        </th>
                                                        <th>
                                                            <Typography variant="h6" className="catalog_input_header BlueText">
                                                                Remarks
                                                            </Typography>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td>
                                                            <Box className="onGoing_value_list">
                                                                {onGoingDetailsData?.map((data, ind) => (
                                                                    <Typography className="onGoing_value_list_text">
                                                                        {data?.attributes?.date && moment(data?.attributes?.date).format("MMM DD, YYYY")}
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        </td>
                                                        <td>
                                                            <Box className="onGoing_value_list">
                                                                {onGoingDetailsData?.map((data, ind) => (
                                                                    <Typography className="onGoing_value_list_text">
                                                                        {showTextWithValidation(data?.attributes?.event_type)}
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        </td>
                                                        <td>
                                                            <Box className="onGoing_value_list">
                                                                {onGoingDetailsData?.map((data, ind) => (
                                                                    <Typography className="onGoing_value_list_text">
                                                                        {showTextWithValidation(data?.attributes?.amount)}
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        </td>
                                                        <td>
                                                            <Box className="onGoing_value_list">
                                                                {onGoingDetailsData?.map((data, ind) => (
                                                                    <Typography className="onGoing_value_list_text">
                                                                        {parseFloat(data?.attributes?.yield)}%
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        </td>
                                                        <td>
                                                            <Box className="onGoing_value_list">
                                                                {onGoingDetailsData?.map((data, ind) => (
                                                                    <Typography className="onGoing_value_list_text">
                                                                        {showTextWithValidation(data?.attributes?.remark)}
                                                                    </Typography>
                                                                ))}
                                                            </Box>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </div>
                                </div>
                            </Fade>
                        </Modal>
                    </div>}
                    {currentData === "Current_Holdings" && <div>
                        <div className="filter_wrapper">
                            <ClickAwayListener onClickAway={handleClickAway}>
                                <div style={{ position: "relative" }}>
                                    <div className="filter" onClick={handleClick3}>
                                        <div> Filters</div>
                                        <img src={filter} width="20px" alt="" />
                                    </div>
                                    {openFilterCurrent ? (
                                        <div className="popUp">
                                            {
                                                <Accordion style={{ margin: "0" }} expanded={currentHoldingExpand} onClick={() => this.setState({ currentHoldingExpand: !currentHoldingExpand })} >
                                                    <AccordionSummary
                                                        expandIcon={<img src={accordian} width="13px" alt="" />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                    >
                                                        <Typography style={{ fontSize: "18px" }} className="BlueText">
                                                            Project Name
                                                        </Typography>
                                                    </AccordionSummary>

                                                    {currentProjectDropdown?.map((val, ind) => (
                                                        <AccordionDetails>
                                                            <Typography
                                                                onClick={() => handleCurrentDropdown(val?.id)}
                                                                className={
                                                                    val?.id === currentHoldingDropdownId
                                                                        ? "accordian_value_active"
                                                                        : "accordian_value"
                                                                }
                                                            >
                                                                {val?.project_name}
                                                            </Typography>{" "}
                                                        </AccordionDetails>
                                                    ))}
                                                </Accordion>
                                            }
                                        </div>
                                    ) : null}
                                </div>
                            </ClickAwayListener>
                        </div>
                        <div style={{ height: "546px", marginTop: "24px", paddingBottom: "200px" }}>
                            {this.state.loading ? <div className="circular_progress"> <CircularProgress /> </div> : <Swiper
                                slidesPerView={2}
                                navigation={true}
                                allowTouchMove={false}
                                spaceBetween={30}
                                modules={[Pagination, Navigation]}
                            >
                                {currentHoldingsData.length > 0 ? currentHoldingsData?.map((data, i) => (
                                    <SwiperSlide>
                                        <div className="cardWrapper_currentHolding">
                                            <div className="onGoing_flex" style={{ alignItems: "flex-end" }}>
                                                <img
                                                    src={data?.attributes?.upload_logo_image?.url}
                                                    style={{ borderRadius: "10px" }}
                                                    alt="discovery"
                                                    width="100"
                                                    height="100"
                                                />

                                                <div>
                                                    <p className="sub_header">
                                                        {data?.attributes?.debt_seeker_company_name}
                                                    </p>
                                                    <div className="onGoing_flex">
                                                        <span className="rating_chip" style={{ width: 'fit-content', minWidth: '93px', maxWidth: '250px' }}>
                                                            <p className="rating_label" style={{ marginLeft: "3px", padding: '0 7px' }}>
                                                                {data?.attributes?.proposed_instruments?.sector}
                                                            </p>
                                                        </span>
                                                        <span className="rating_chip" style={{ width: 'fit-content', minWidth: '93px', maxWidth: '250px' }}>
                                                            <p className="rating_label" style={{ padding: '0 7px' }} >{data?.attributes?.proposed_instruments?.rating_details?.data[0]?.attributes?.rating_details_rating_outlook?.title}</p>{" "}
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div>
                                                <p className="sub_header">{data?.attributes?.project_name}</p>
                                                <Grid item xs={12}>
                                                    <Box style={{ display: "flex" }}>
                                                        <table style={{ width: "100%" }}>
                                                            <thead>
                                                                <tr>
                                                                    <th>
                                                                        {" "}
                                                                        <Typography
                                                                            variant="h6"
                                                                            className="catalog_input_header"
                                                                            style={{ color: "gray" }}
                                                                        >
                                                                            Investment
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        {" "}
                                                                        <Typography
                                                                            variant="h6"
                                                                            className="catalog_input_header"
                                                                            style={{ color: "gray" }}
                                                                        >
                                                                            Yield
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography
                                                                            variant="h6"
                                                                            className="catalog_input_header"
                                                                            style={{ color: "gray" }}
                                                                        >
                                                                            Investment Date
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography
                                                                            variant="h6"
                                                                            className="catalog_input_header"
                                                                            style={{ color: "gray" }}
                                                                        >
                                                                            Maturity Date
                                                                        </Typography>
                                                                    </th>
                                                                    <th>
                                                                        <Typography
                                                                            variant="h6"
                                                                            className="catalog_input_header"
                                                                            style={{ color: "gray" }}
                                                                        >
                                                                            End Use
                                                                        </Typography>
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                <tr>
                                                                    <td className="BlueText font_700">
                                                                        Rs.
                                                                        {
                                                                            ` ${data?.attributes?.proposed_instruments
                                                                                ?.total_deal_size} `
                                                                        } Cr
                                                                    </td>
                                                                    <td className="BlueText font_700">
                                                                        {data?.attributes?.proposed_instruments?.yield}%
                                                                    </td>
                                                                    <td className="BlueText font_700">{data?.attributes?.investment_date && moment(data?.attributes?.investment_date).format("MMMM DD, YYYY")}</td>
                                                                    <td className="BlueText font_700">
                                                                        {
                                                                            data?.attributes?.proposed_instruments
                                                                                ?.repayment_maturity_date && moment(data?.attributes?.proposed_instruments
                                                                                    ?.repayment_maturity_date).format("DD-MMM-YYYY")
                                                                        }
                                                                    </td>
                                                                    <td className="BlueText font_700">{data?.attributes?.proposed_instruments
                                                                        ?.proposed_end_use}</td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </Box>
                                                </Grid>
                                                <Grid container spacing={2} style={{ marginTop: "25px", alignItems: 'center' }}>

                                                    {
                                                        data?.attributes?.utr ? <>
                                                            <p className="inputLabel" style={{ margin: '0 20', paddingLeft: '10px' }}>UTR Number</p>
                                                            <button className="catalogue_textInput" style={{ width: '250px' }} >{data?.attributes?.utr}</button></> :
                                                            <>
                                                                <Grid className="flex_center" style={{ margin: "10px" }}>
                                                                    <InputLabel className="inputLabel">Add UTR Number</InputLabel>
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    <input maxLength={range} value={currentHoldingCardId == data?.attributes?.id ? utrNumber : ''} onChange={(e) => handleUtrNumber(e, data?.attributes?.id)} className="catalogue_textInput" type="text" name="" />
                                                                </Grid>
                                                                <Grid > {!data?.attributes?.utr && <button className={(this.state.utrNumber && currentHoldingCardId == data?.attributes?.id) ? "deal_button" : "deal_button_disable"} disabled={!this.state.utrNumber || currentHoldingCardId !== data?.attributes?.id} onClick={() => handleUtr(data?.attributes?.id)}>Save</button>}</Grid>
                                                            </>
                                                    }
                                                    {(currentHoldingCardId == data?.attributes?.id) ? (utrError && this.state.utrNumber) && <p className="utrError"> Please input correct UTR number.</p> : null}
                                                </Grid>
                                                {/* <div className="flex_center font_700" style={{ fontSize: "18px" }}>
                                                    <span className="flex_center BlueText" onClick={() => this.repaymentDownload(data?.attributes?.id)} style={{ margin: "25px 0px", cursor: 'pointer' }}>
                                                        <p>View Repayment Profile</p>
                                                        
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" style={{ fontSize: '26px' }} viewBox="0 0 1024 1024" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                                    </span>
                                                    <span className="flex_center BlueText" onClick={() => this.assetMonitoringDownload(data?.attributes?.id)} style={{ margin: "25px 40px", cursor: 'pointer' }}>
                                                        <p>Asset Monitoring Report</p>
                                                        
                                                        <svg stroke="currentColor" fill="currentColor" stroke-width="0" style={{ fontSize: '26px' }} viewBox="0 0 1024 1024" height="1em" width="2em" xmlns="http://www.w3.org/2000/svg"><path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                                    </span>
                                                </div> */}
                                                <Grid container spacing={1} style={{display:'flex',alignItems:'center',justifyContent:'space-around'}}>
                                            <button className="utr-chat-button BlueText" onClick={(e)=>this.openChatBox(e,data?.attributes?.groupchat_dialog_id,data.id,data.attributes.project_name)}>Chat</button>
                                            <span className="flex_center BlueText" onClick={()=>this.repaymentDownload(data?.attributes?.id)} style={{ margin: "25px 0px",cursor:'pointer' }}>
                                                    <p>View Repayment Profile</p>
                                                    
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" style={{fontSize:'26px'}} viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                                </span>
                                                <span className="flex_center BlueText" onClick={()=>this.assetMonitoringDownload(data?.attributes?.id)} style={{ margin: "25px 4px 25px 9px ",cursor:'pointer', }}>
                                                    <p>Asset Monitoring Report</p>
                                                    
                                                    <svg stroke="currentColor" fill="currentColor" stroke-width="0" style={{fontSize:'26px'}} viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M505.7 661a8 8 0 0 0 12.6 0l112-141.7c4.1-5.2.4-12.9-6.3-12.9h-74.1V168c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v338.3H400c-6.7 0-10.4 7.7-6.3 12.9l112 141.8zM878 626h-60c-4.4 0-8 3.6-8 8v154H214V634c0-4.4-3.6-8-8-8h-60c-4.4 0-8 3.6-8 8v198c0 17.7 14.3 32 32 32h684c17.7 0 32-14.3 32-32V634c0-4.4-3.6-8-8-8z"></path></svg>
                                                </span>

                                            </Grid>

                                            </div>
                                        </div>
                                    </SwiperSlide>
                                )) : <SwiperSlide className="">
                                    <p>No Data Found</p>
                                </SwiperSlide>}

                            </Swiper>}
                        </div>
                    </div>}
                    {currentData === "Transaction_History" && <div>
                        <div>
                            {transactionDetails?.length == 0 && (
                                <div>
                                    <div className="filter_wrapper2">
                                        <ClickAwayListener onClickAway={handleClickAway}>
                                            <div style={{ position: "relative" }}>
                                                <div className="filter" onClick={handleClick4}>
                                                    <div> Filters</div>
                                                    <img src={filter} width="20px" alt="" />
                                                </div>
                                                {openFiltertTransaction ? (
                                                    <div className="popUp2">
                                                        {
                                                            <Accordion style={{ margin: "0" }} expanded={transactionExpand} onClick={() => this.setState({ transactionExpand: !transactionExpand })}>
                                                                <AccordionSummary
                                                                    expandIcon={<img src={accordian} width="13px" alt="" />}
                                                                    aria-controls="panel1a-content"
                                                                    id="panel1a-header"
                                                                >
                                                                    <Typography
                                                                        style={{ fontSize: "18px" }}
                                                                        className="BlueText"
                                                                    >
                                                                        Deal
                                                                    </Typography>
                                                                </AccordionSummary>

                                                                {projectDropdown?.map((val, ind) => (
                                                                    <AccordionDetails>
                                                                        <Typography
                                                                            onClick={() => handleTransactionDropdown(val?.id)}
                                                                            className={
                                                                                val?.id === transactionDropdownId
                                                                                    ? "accordian_value_active"
                                                                                    : "accordian_value"
                                                                            }
                                                                        >
                                                                            {val?.project_name}
                                                                        </Typography>{" "}
                                                                    </AccordionDetails>
                                                                ))}
                                                            </Accordion>
                                                        }
                                                        <Accordion style={{ margin: "0" }} expanded={transactionExpand2} onClick={() => this.setState({ transactionExpand2: !transactionExpand2 })}>
                                                            <AccordionSummary
                                                                expandIcon={<img src={accordian} width="13px" alt="" />}
                                                                aria-controls="panel1a-content"
                                                                id="panel1a-header"
                                                            >
                                                                <Typography style={{ fontSize: "18px" }} className="BlueText">
                                                                    Deal Status
                                                                </Typography>
                                                            </AccordionSummary>
                                                            <AccordionDetails>
                                                                <Typography
                                                                    className={
                                                                        deal_status === "All"
                                                                            ? "accordian_value_active"
                                                                            : "accordian_value"
                                                                    }
                                                                    onClick={() => handleTransactionDealStatus("All")}
                                                                >
                                                                    All
                                                                </Typography>
                                                            </AccordionDetails>
                                                            <AccordionDetails>
                                                                <Typography
                                                                    className={
                                                                        deal_status === "ongoing"
                                                                            ? "accordian_value_active"
                                                                            : "accordian_value"
                                                                    }
                                                                    onClick={() => handleTransactionDealStatus("ongoing")}
                                                                >
                                                                    Ongoing Deals
                                                                </Typography>
                                                            </AccordionDetails>
                                                            <AccordionDetails>
                                                                <Typography
                                                                    className={
                                                                        deal_status === "holdings"
                                                                            ? "accordian_value_active"
                                                                            : "accordian_value"
                                                                    }
                                                                    onClick={() => handleTransactionDealStatus("holdings")}
                                                                >
                                                                    Current Holdings
                                                                </Typography>
                                                            </AccordionDetails>
                                                        </Accordion>
                                                    </div>
                                                ) : null}
                                            </div>
                                        </ClickAwayListener>
                                        <div>
                                            <FormControl variant="outlined" size="small">
                                                <Select
                                                    className="select_textInput"
                                                    style={{ width: "230px" }}
                                                    disableUnderline
                                                    displayEmpty
                                                    name="share_holder_type_id"
                                                    MenuProps={{
                                                        PaperProps: {
                                                            style: {
                                                                borderRadius: "20px",
                                                                marginTop: "142px",
                                                                marginLeft: '-9px',
                                                            }
                                                        }
                                                    }}
                                                    IconComponent={() => (
                                                        <KeyboardArrowDownIcon
                                                            style={{ position: "absolute", right: 12, pointerEvents: "none" }}
                                                        />
                                                    )}
                                                    onChange={(e) => sortByIssuer(e)}
                                                    value={sortByIssuerId}
                                                >
                                                    {
                                                        sortByIssuerId ? <option value="" className="clear">Clear</option> : <MenuItem value="" className="select_option_1" disabled>-Select issuer-</MenuItem>
                                                    }
                                                    {transactionFilter?.map((item: any, i: any) => (
                                                        <option className="select_option_1" key={i} value={item?.id}>
                                                            {`${item?.first_name}${" "}${TextWithValidation(item?.last_name)}`}
                                                        </option>
                                                    ))}
                                                </Select>
                                            </FormControl>
                                        </div>
                                    </div>
                                    <div style={{ height: "546px", marginTop: "24px", paddingBottom: "200px" }}>
                                        {this.state.transactionSpinner ? <div className="circular_progress"> <CircularProgress /> </div> : <Swiper
                                            slidesPerView={3}
                                            navigation={true}
                                            allowTouchMove={false}
                                            spaceBetween={30}
                                            modules={[Pagination, Navigation]}>
                                            {transactionHistory?.length > 0 ? transactionHistory?.map((deal, i) => (
                                                <SwiperSlide onClick={() => transactionDetail(deal?.attributes?.id)}>
                                                    <div className="cardWrapper" key={i} style={{ cursor: 'pointer' }}>
                                                        <div>
                                                            <div className="flex_center onGoing_card_header">
                                                                <div>
                                                                    <span className="rating_chip">
                                                                        {" "}
                                                                        <p
                                                                            className="rating_label"
                                                                            style={{ marginLeft: "3px" }}
                                                                        >
                                                                            {deal?.attributes?.proposed_instruments?.sector}
                                                                        </p>{" "}
                                                                    </span>
                                                                    <span className="rating_chip">
                                                                        {" "}
                                                                        <p className="rating_label">

                                                                            {deal?.attributes?.proposed_instruments
                                                                                ?.rating_details?.data[0]?.attributes
                                                                                ?.rating_details_rating?.title}
                                                                        </p>{" "}
                                                                    </span>
                                                                </div>
                                                                <div style={{ width: "100%", textAlign: "center" }}>
                                                                    <p className="onGoing_Company_Name">
                                                                        {deal?.attributes?.project_name}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                            <div className="flex_center">
                                                                <img
                                                                    src={deal?.attributes?.upload_logo_image?.url}
                                                                    style={{ borderRadius: "10px" }}
                                                                    alt="discovery"
                                                                    width="100"
                                                                    height="100"
                                                                />
                                                                <div style={{ width: "100%", textAlign: "center" }}>
                                                                    <p className="onGoing_Company_Name">{deal?.attributes?.debt_seeker_company_name}</p>
                                                                </div>
                                                            </div>
                                                        </div>

                                                        <div style={{ margin: "0 15 0 0" }} className="normal_font">
                                                            <div className="deal_discovery_body">
                                                                <p>Instrument</p>
                                                                <p className="BlueText">
                                                                    {deal?.attributes?.proposed_instruments?.instrument}
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Total Amount (INR Cr)</p>
                                                                <p className="BlueText">
                                                                    Rs.{" "}
                                                                    {checkTotalDeal(deal?.attributes?.proposed_instruments?.total_deal_size)} Cr
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Minimum Investment (INR Cr)</p>
                                                                <p className="BlueText">
                                                                    Rs.{" "}
                                                                    {parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment) ? parseFloat(deal?.attributes?.proposed_instruments?.minimum_investment).toLocaleString('en-In') : 0} Cr
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Expected Returns (Gross)</p>
                                                                <p className="BlueText">
                                                                    {
                                                                        parseFloat(deal?.attributes?.proposed_instruments
                                                                            ?.expected_returns_gross) ? parseFloat(deal?.attributes?.proposed_instruments
                                                                                ?.expected_returns_gross) : 0
                                                                    }% IRR
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Maturity Date</p>
                                                                <p className="BlueText">
                                                                    {deal?.attributes?.proposed_instruments
                                                                        ?.repayment_maturity_date && moment(
                                                                            deal?.attributes?.proposed_instruments
                                                                                ?.repayment_maturity_date
                                                                        ).format("DD-MMM-YYYY")}
                                                                </p>
                                                            </div>
                                                            <div className="deal_discovery_body">
                                                                <p>Repayment Frequency</p>
                                                                <p className="BlueText">
                                                                    {
                                                                        deal?.attributes?.proposed_instruments
                                                                            ?.repayment_frequency
                                                                    }
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                justifyContent: "flex-end",
                                                                margin: "10 14px",
                                                            }}
                                                        >
                                                            <button
                                                                className="next_button"
                                                                style={{ width: "134px" }}
                                                                onClick={() => transactionDetail(deal?.attributes?.id)}
                                                            >
                                                                Deal History
                                                            </button>
                                                        </div>
                                                    </div>
                                                </SwiperSlide>
                                            ))
                                                : <SwiperSlide className="">
                                                    <p>No Data Found</p>
                                                </SwiperSlide>}
                                        </Swiper>}
                                    </div>
                                </div>
                            )}

                            {transactionDetails?.length > 0 ? (
                                <Grid className="grid_history_list_box" style={{ overflowY: "unset", marginTop: "15px" }}>
                                    <table style={{ width: "100%", borderSpacing: "10px 0" }}>
                                        <thead>
                                            <tr>
                                                <th>
                                                    {" "}
                                                    <Typography variant="h6" className="catalog_input_header BlueText">
                                                        Date
                                                    </Typography>
                                                </th>
                                                <th>
                                                    {" "}
                                                    <Typography variant="h6" className="catalog_input_header BlueText">
                                                        Event Type
                                                    </Typography>
                                                </th>
                                                <th>
                                                    <Typography variant="h6" className="catalog_input_header BlueText">
                                                        Remark
                                                    </Typography>
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ width: '250px' }}>
                                                    <Box className="onGoing_value_list">
                                                        {transactionDetails?.map((data, ind) => (
                                                            <Typography className="onGoing_value_list_text">
                                                                {data?.attributes?.date && moment(data?.attributes?.date).format("DD-MMM-YY")}
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                </td>
                                                <td style={{ width: '350px' }}>
                                                    <Box className="onGoing_value_list">
                                                        {transactionDetails?.map((data, ind) => (
                                                            <Typography className="onGoing_value_list_text">
                                                                {showTextWithValidation(data?.attributes?.event_type)}
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                </td>
                                                <td>
                                                    <Box className="onGoing_value_list">
                                                        {transactionDetails?.map((data, ind) => (
                                                            <Typography className="onGoing_value_list_text">
                                                                {showTextWithValidation(data?.attributes?.remark)}
                                                            </Typography>
                                                        ))}
                                                    </Box>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </Grid>
                            ) : null}
                        </div>
                    </div>}
                    {this.state.open?<Chat9 dialogId={this.state.groupChatDialogId} navigation={''} id={''}  handleDialog = {this.handleDialogBox} dealName={this.state.dealName}></Chat9>:null}
                </div>
            </div>
        );
    }
}

export default withRouter(DealDiscovery) as any;


// Customizable Area End