import { withStyles } from "@material-ui/core/styles";
import {TextField} from '@material-ui/core';

export const CustomInputField:any = withStyles({
    root: {
        width: '100%',
        "::placeholder": {
            fontSize: "16px",
            fontFamily: 'Lato',
            fontWeight: 400,
            color: '#323232',
            lineHeight: '19px',
            letterSpacing: '-0.4px',
        },
        "& .MuiOutlinedInput-root": {
            borderRadius: "65px",
            fontSize: "16px",
            height: '51px',
            fontFamily: 'Lato',
            fontWeight: 400,
            color: '#323232',
            lineHeight: '19px',
            letterSpacing: '-0.4px',
            marginBottom: '15px',
            "& .MuiOutlinedInput-input": {
                borderColor: '#CCCCCC',
                padding: '16px 16px 16px 16px',
            },
        },
    },
})(TextField);