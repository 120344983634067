import React from "react";
// Customizable Area Start
import {
    Typography,
    Grid,
    Button,
    InputLabel,
    InputAdornment,
    Box,
    FormControl,
    Select,
    MenuItem,
    AppBar,
    Tabs,
    Tab,
    Checkbox,
    ListItemText
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from '@material-ui/lab/Alert';
import "./style.css";
import CinForProfile from "./CinForProfile.web";
import ProfileController, { Props } from "./ProfileController";
import AppHeader from "../../catalogue/src/AppHeader.web";
import OtpInput from "../../../components/src/OtpInput";
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown';
import { verified_tick_image } from "./assets";
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {CustomInputField} from '../../../components/./src/./components/./CustomInputField';
import UploadResume from "./UploadResume.web";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
export default class Profile extends ProfileController {
    constructor(props: Props) {
        super(props);
    }
    debounce = (callback: any, delay: any) => {
        let timer: any;
        return (...args: any) => {
            clearTimeout(timer);
            timer = setTimeout(() => callback(...args), delay)
        }
    }

    getUploadResumeView = (uploadCopy: (e: any) => void, removeCopy: () => void, copyName: string, uploadCopyError: boolean, dpIdName: string, dpIdValue: string, isDPIdError: boolean) => {
        return <UploadResume uploadCopy={uploadCopy} removeCopy={removeCopy} copyName={copyName} uploadCopyError={uploadCopyError} editPersonalDetails={this.state.editPersonalDetails} dpIdName={dpIdName} dpIdValue={dpIdValue} handleChangeDematDetails={this.handleChangeDematDetails} isDPIdError={isDPIdError} />
    }

    getDebtValueSelectedForProfileCinView = () => {
        if(this.state.debtSeekerSelectedShowValueForProfile == "Private Limited Company" || this.state.debtSeekerSelectedShowValueForProfile == "Public Limited Company" || this.state.debtProviderSelectedShowValueForProfile == "Bank" || this.state.debtProviderSelectedShowValueForProfile == "Mutual Funds" || this.state.debtProviderSelectedShowValueForProfile == "FI" || this.state.debtProviderSelectedShowValueForProfile == "NBFC" || this.state.debtArrangerSelectedShowValueForProfile == "Private Limited Company"){
            /* istanbul ignore next */
            return (
                <>
                    <CinForProfile htmlFor="component-simple" label="CIN" onChange={(e: any) => { this.getCinVal(e) }} onKeyDown={this.handleMinuSign} inputProps = {{maxLength: 21}} value = {this.state.cinProfile} placeholder = "0000000 0000000 0000000" disabled = {!this.state.editPersonalDetails} cinId = "outlined-basic"/>
                    {
                        this.state.cinProfileError && this.state.cinProfile.length != 0 && <Typography style={{ color: 'red' }}>Please enter valid cin value</Typography>
                    }
                </>
            )
        }
        return null;
    }

    render() {
        let cmrName: string = '';
        let cmrNsdlName: string = "";
        let cancelledCheckName: string = '';
        let debtSeekerValue: string = '';
        let debtProviderProfileValue: string = "";
        let debtArrangerValue: string = "";
        if (this.state.kycDetails?.debt_seeker_name) {
            debtSeekerValue = this.state.kycDetails?.debt_seeker_name
        }
        if (this.state.kycDetails?.debt_provider_name) {
            debtProviderProfileValue = this.state.kycDetails?.debt_provider_name
        }
        if (this.state.kycDetails?.debt_arranger_name) {
            debtArrangerValue = this.state.kycDetails?.debt_arranger_name;
        }
        if (this.state.dematDetailsForm?.cdsl_cmr_copy_name) {
            cmrName = this.state.dematDetailsForm?.cdsl_cmr_copy_name
        }
        if (this.state.dematDetailsForm?.nsdl_cmr_copy_name) {
            cmrNsdlName = this.state.dematDetailsForm?.nsdl_cmr_copy_name
        }
        if (this.state.dematDetailsForm?.cdsl_cmr_copy?.name) {
            cmrName = this.state.dematCmrCopyName?.name
        }
        if (this.state.dematDetailsForm?.nsdl_cmr_copy?.name) {
            cmrNsdlName = this.state.demateNsdlCopyName?.name
        }
        if (this.state.bankDetailsForm?.check && this.state.bankDetailsForm?.check_name) {
            cancelledCheckName = this.state.bankDetailsForm?.check_name
        }
        if (this.state.cancelledCheck && this.state.cancelledCheck?.name) {
            cancelledCheckName = this.state.cancelledCheck?.name
        }
        return (
            <>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} open={this.state.snackbarOpenProfile} autoHideDuration={4000} onClose={this.handleClose} >
                    <Alert severity="success">
                        {this.state.snackbarMessageProfile}
                    </Alert>
                </Snackbar>
                <Snackbar anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }} open={this.state.snackbarOpenProfileError} autoHideDuration={4000} onClose={this.handleCloseError} >
                    <Alert severity="error">
                        {this.state.snackbarMessageProfile}
                    </Alert>
                </Snackbar>
                <AppHeader navigation={this.props.navigation} id="" location="" history="" match="">
                </AppHeader>
                <Grid container justifyContent="space-between" style={webStyles.mainGrid}>
                    <Typography variant="h5" style={webStyles.header}>
                        Profile
                    </Typography>
                    {
                        !this.state.editPersonalDetails && (
                            this.state.selectedoption === 'Personal Details' ?
                                <Button
                                    variant="contained"
                                    color="primary"
                                    style={webStyles.editButton}
                                    className="buttonTextStyle"
                                    onClick={this.editPersonalDetails}
                                >
                                    Edit Personal Details
                                </Button>
                                :
                                this.state.selectedoption === 'Bank Details' ?
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={webStyles.editButton}
                                        className="buttonTextStyle"
                                        onClick={this.editPersonalDetails}
                                    >
                                        Edit Bank Details
                                    </Button>
                                    : this.state.selectedoption === 'Demat Details' ?
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            style={webStyles.editButton}
                                            className="buttonTextStyle"
                                            onClick={this.editPersonalDetails}
                                        >
                                            Edit Demat Details
                                        </Button>
                                        : <Button
                                            variant="contained"
                                            color="primary"
                                            style={webStyles.editButton}
                                            className="buttonTextStyle"
                                            onClick={this.editPersonalDetails}
                                        >
                                            Edit KYC Details
                                        </Button>
                        )
                    }
                </Grid>
                <Grid container style={webStyles.secondGrid}>
                    <Grid item lg={4} md={4} sm={5} xs={12}>
                        <Grid item lg={8} md={9} sm={10} xs={12} style={webStyles.optionBox}>
                            <CustomButton
                                variant={
                                    this.state.selectedoption === 'Personal Details' ?
                                        "contained" : "outlined"
                                }
                                data-testid = "personalbutton"
                                onClick={() => this.setSelectedDetails('Personal Details')}
                            >
                                Personal Details
                            </CustomButton>
                            <CustomButton
                                variant={
                                    this.state.selectedoption === 'Bank Details' ?
                                        "contained" : "outlined"
                                }
                                data-testid = "bankdetails"
                                onClick={() => this.setSelectedDetails('Bank Details')}
                            >
                                Bank Details
                            </CustomButton>
                            <CustomButton
                                variant={
                                    this.state.selectedoption === 'Demat Details' ?
                                        "contained" : "outlined"
                                }
                                data-testid = "demateId"
                                onClick={() => this.setSelectedDetails('Demat Details')}
                            >
                                Demat Details
                            </CustomButton>
                            <CustomButton
                                variant={
                                    this.state.selectedoption === 'KYC Details' ?
                                        "contained" : "outlined"
                                }
                                data-testid = "kycId"
                                onClick={() => this.setSelectedDetails('KYC Details')}
                            >
                                KYC Details
                            </CustomButton>
                        </Grid>
                    </Grid>
                    <Grid item lg={5} md={7} sm={7} xs={12} style={webStyles.gridMargin}>
                        {
                            this.state.selectedoption === 'Personal Details' && (
                                <>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Role
                                        </CustomInputLabel>
                                        {
                                            !this.state.editPersonalDetails ?
                                                <CustomInputField
                                                    id="outlined-basic"
                                                    placeholder="Role"
                                                    variant="outlined"
                                                    name="role_id"
                                                    value={this.state.roleOptions.filter((role:any)=> this.state.personalDetailsForm.role_id.includes(role.id)).map((data:any)=> data.name).join(", ")}
                                                    onChange={this.handleChangePersonal}
                                                    disabled={!this.state.editPersonalDetails}
                                                />
                                                : <FormControl
                                                    fullWidth
                                                    variant="outlined"
                                                    size="small"
                                                    className="kyc_form_control"
                                                    style={{ marginBottom: '15px' }}
                                                >
                                                    <Select
                                                        disableUnderline
                                                        value={this.state.personalDetailsForm?.role_id}
                                                        onChange={this.handleChangePersonal}
                                                        name="role_id"
                                                        className="kyc_form_control"
                                                        data-test-id="kyc_form"
                                                        IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                        }
                                                        MenuProps={{
                                                            style: { marginTop: "8px", },
                                                            anchorOrigin: {
                                                                vertical: "bottom",
                                                                horizontal: "left"
                                                            },
                                                            getContentAnchorEl: null,
                                                        }}
                                                        multiple
                                                        defaultValue={""}
                                                        disabled={!this.state.editPersonalDetails}
                                                        renderValue={(selected:any) => selected.length === 0 ? <Typography>Please Select Role</Typography> : 
                                                        this.state.roleOptions?.filter((role)=> this.state.personalDetailsForm?.role_id?.includes(role.id)).map((role)=>role.name).join(", ")}
                                                    >
                                                        <MenuItem value={0} disabled>
                                                            <em className="select_placeholder">Role</em>
                                                        </MenuItem>
                                                        {
                                                            this.state.roleOptions?.map((item: any, index: number) => {
                                                                return (
                                                                    <MenuItem disabled={this.state.preSelectedRoles?.includes(item.id)} value={item.id} key={index}>
                                                                        <Checkbox style={{ color: '#001D56' }} checked={this.state.personalDetailsForm?.role_id.indexOf(item.id) > -1} />
                                                                        <ListItemText style={{
                                                                        marginLeft: 5,
                                                                        marginBottom: 5
                                                                        }} primary={item.name} />
                                                                    </MenuItem>
                                                                )
                                                            })
                                                        }
                                                    </Select>
                                                </FormControl>
                                        }
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Name
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Full Name"
                                            variant="outlined"
                                            name="full_name"
                                            inputProps={{
                                                maxLength: 25
                                            }}
                                            value={this.state.personalDetailsForm?.full_name}
                                            onChange={this.handleChangePersonal}
                                            disabled={!this.state.editPersonalDetails}
                                        />
                                        {
                                            this.state.isErrorName && (
                                                <div className="error_message">
                                                    Name should be between 2 - 25 character.
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Email
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Email"
                                            variant="outlined"
                                            name="email"
                                            type="email"
                                            value={this.state.personalDetailsForm?.email}
                                            onChange={this.handleChangePersonal}
                                            disabled={!this.state.editPersonalDetails}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        {
                                                            this.state.editPersonalDetails && (
                                                                this.state.old_email === this.state.personalDetailsForm?.email || this.state.isEmailVerified ? (
                                                                    <Button
                                                                        className="verified_email_button"
                                                                        disabled={
                                                                            this.state.isErrorEmail ||
                                                                            this.state.email_verification_link_send || this.state.personalDetailsForm?.email.length === 0
                                                                        }
                                                                        variant="contained"
                                                                    >
                                                                        Verify email
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className="verify_email_button"
                                                                        disabled={
                                                                            this.state.isErrorEmail ||
                                                                            this.state.email_verification_link_send || this.state.personalDetailsForm?.email.length === 0
                                                                        }
                                                                        variant="contained"
                                                                        onClick={this.verifyEmail}
                                                                    >
                                                                        Verify email
                                                                    </Button>
                                                                ))
                                                        }
                                                    </InputAdornment>
                                            }}
                                        />
                                        {
                                            this.state.isErrorEmail && (
                                                <div className="error_message">
                                                    Please enter a valid email Address
                                                </div>
                                            )
                                        }
                                        {
                                            this.state.isEmailVerifySend && (
                                                <div className="helper-text">
                                                    A link has been sent to your email. Click the link to
                                                    verify it.
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Company Name
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Company Name"
                                            variant="outlined"
                                            type="text"
                                            name="company_name"
                                            value={this.state.personalDetailsForm?.company_name}
                                            onChange={this.handleChangePersonal}
                                            onKeyUp={this.handleNumberChange}
                                            disabled={!this.state.editPersonalDetails}
                                        />
                                        {this.state.isErrorCompanyName && (
                                            <Typography
                                                className="error_message"
                                            >
                                                Please enter a valid Company Name
                                            </Typography>
                                        )}
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Phone Number
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Phone Number"
                                            variant="outlined"
                                            name="phone_number"
                                            // type="text"
                                            inputProps={{
                                                maxLength: 10
                                            }}
                                            className="phone_no_field_value"
                                            value={this.state.personalDetailsForm?.phone_number}
                                            onChange={this.handleChangePersonal}
                                            disabled={!this.state.editPersonalDetails}
                                            InputProps={{
                                                endAdornment:
                                                    <InputAdornment position="end">
                                                        {
                                                            this.state.editPersonalDetails && !this.state.isErrorPhone && (
                                                                this.state.personalDetailsForm?.phone_number === this.state.old_phone_number || this.state.isMobileVerified ? (
                                                                    <Typography
                                                                    className="mobile_verified_text"
                                                                  >
                                                                    Verified
                                                                    <img className="verified_tick_logo" src={verified_tick_image} alt="" />
                                                                  </Typography>
                                                                ) : (
                                                                    <Button
                                                                        className="verify_email_button"
                                                                        variant="contained"
                                                                        onClick={this.requestOTP}
                                                                    >
                                                                        Request OTP
                                                                    </Button>
                                                                ))
                                                        }
                                                    </InputAdornment>,
                                                startAdornment:
                                                    <InputAdornment position="start">
                                                        <Typography
                                                            className=""
                                                        >
                                                            +91
                                                        </Typography>
                                                    </InputAdornment>
                                            }}
                                        />
                                        {
                                            this.state.otp_send && (
                                                <div>
                                                    <Typography className="helper-text">
                                                        A 4 digit code has been sent to the number
                                                        {" "} {this.state.personalDetailsForm?.phone_number}.
                                                        Enter the code to continue
                                                    </Typography>
                                                </div>
                                            )
                                        }
                                        <Grid item lg={12} sm={12} md={12} xs={12}>
                                            {
                                                this.state.isErrorPhone && (
                                                    <div className="error_message">
                                                        Please enter your 10 digit Phone Number
                                                    </div>
                                                )
                                            }
                                        </Grid>
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        {
                                            this.state.otp_send && (
                                                <Box pl={1.5} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                                    <Grid container style={webStyles.otpContainer} item lg={12} sm={12} md={12} xs={12}>
                                                        <Box className="otp_box_profile">
                                                            <OtpInput
                                                                id="text1"
                                                                onKeyUp={
                                                                    (e: any) => this.move(e, "", "text1", "text2")
                                                                }
                                                                className={
                                                                    this.state.OtpValue[0] !== undefined &&
                                                                    'profile-otp-input'
                                                                }
                                                            />
                                                            <OtpInput
                                                                id="text2"
                                                                onKeyUp={(e: any) => this.move(e, "text1", "text2", "text3")}
                                                                className={
                                                                    this.state.OtpValue[1] !== undefined &&
                                                                    'profile-otp-input'
                                                                }
                                                            />
                                                            <OtpInput
                                                                id="text3"
                                                                onKeyUp={(e: any) => this.move(e, "text2", "text3", "text4")}
                                                                className={
                                                                    this.state.OtpValue[2] !== undefined &&
                                                                    'profile-otp-input'
                                                                }
                                                            />
                                                            <OtpInput
                                                                id="text4"
                                                                onKeyUp={(e: any) => this.move(e, "text3", "text4", "text4")}
                                                                className={
                                                                    this.state.OtpValue[3] != undefined &&
                                                                    'profile-otp-input'
                                                                }
                                                            />
                                                        </Box>
                                                    </Grid>
                                                    <Grid item lg={12} sm={12} md={12} xs={12} style={{ display: 'flex', flexDirection: 'row', marginTop: '39px', alignItems: 'center' }}>
                                                        <Typography className="did_not_receive_code">
                                                            Didn’t receive code?
                                                        </Typography>
                                                        <Typography className="resend_code"

                                                            onClick={this.resendOTP}
                                                        >
                                                            Resend code
                                                        </Typography>
                                                    </Grid>
                                                </Box>
                                            )

                                        }
                                    </Grid>
                                </>
                            )
                        }
                        {
                            this.state.selectedoption === 'Bank Details' && (
                                <>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Name
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Full Name"
                                            variant="outlined"
                                            name="name"
                                            value={this.state.bankDetailsForm.name}
                                            onChange={this.handleChangeBankDetails}
                                            disabled={!this.state.editPersonalDetails}
                                        />
                                        {
                                            this.state.isErrorName && (
                                                <div className="error_message">
                                                    Name should be between 2 - 25 character.
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Bank Name
                                        </CustomInputLabel>
                                        <FormControl
                                            fullWidth
                                            variant="outlined"
                                            className="kyc_form_control"
                                            style={{ marginBottom: '15px', }}
                                            placeholder="Bank Name"
                                        >
                                            <Select
                                                fullWidth
                                                disableUnderline
                                                value={this.state.bankDetailsForm.bank_id ? this.state.bankDetailsForm.bank_id : 0}
                                                onChange={this.handleChangeBankDetails}
                                                name="bank_id"
                                                className="kyc_form_control"
                                                placeholder="Bank Name"
                                                IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                }
                                                MenuProps={{
                                                    PaperProps: {
                                                        style: {
                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                            width: 250,
                                                            marginTop: 8
                                                        }
                                                    },
                                                    anchorOrigin: {
                                                        vertical: "bottom",
                                                        horizontal: "left"
                                                    },
                                                    getContentAnchorEl: null,
                                                }}
                                                defaultValue={"Bank Name"}
                                                disabled={!this.state.editPersonalDetails}
                                            >
                                                <MenuItem value={0} disabled>
                                                    <em className="select_placeholder">Bank Name</em>
                                                </MenuItem>
                                                {
                                                    this.state.bankListDetailArray?.map((item: any, index: number) => {
                                                        return (
                                                            <MenuItem value={item.id} key={index}>
                                                                {item.value}
                                                            </MenuItem>
                                                        )
                                                    })
                                                }
                                            </Select>
                                        </FormControl>
                                        {
                                            this.state.bankDetailsForm.bank_id === 0 && !localStorage.getItem("isBankDetailDone") && (
                                                <div className="error_message">
                                                    Please Select Bank Name
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Account Number
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="Account Number"
                                            variant="outlined"
                                            name="account_number"
                                            type={'number'}
                                            value={this.state.bankDetailsForm.account_number}
                                            onChange={this.handleChangeBankDetails}
                                            onKeyDown={this.handleMinusSign}
                                            disabled={!this.state.editPersonalDetails}
                                            onPaste={(e:React.ClipboardEvent<HTMLInputElement>) => {
                                                e.preventDefault()
                                                return false;
                                            }} onCopy={(e:React.ClipboardEvent<HTMLInputElement>) => {
                                                e.preventDefault()
                                                return false;
                                            }}
                                        />
                                        {
                                            this.state.bankNoError && (
                                                <div className="error_message">
                                                    Please Enter Valid Account Number
                                                </div>
                                            )
                                        }
                                    </Grid>
                                    {
                                        this.state.bankDetailsForm.account_number !== this.state.old_account_no &&
                                        (
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                <CustomInputLabel
                                                    htmlFor="component-simple"
                                                    style={webStyles.marginGrid}
                                                    required
                                                >
                                                    Confirm Account Number
                                                </CustomInputLabel>
                                                <CustomInputField
                                                    id="outlined-basic"
                                                    placeholder="Confirm Account Number"
                                                    variant="outlined"
                                                    name="confirm_account_number"
                                                    type={'number'}
                                                    onKeyDown={this.handleMinusSign}
                                                    value={this.state.bankDetailsForm.confirm_account_number}
                                                    onChange={this.handleChangeBankDetails}
                                                    disabled={!this.state.editPersonalDetails}
                                                    onPaste={(e:React.ClipboardEvent<HTMLInputElement>) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }} onCopy={(e:React.ClipboardEvent<HTMLInputElement>) => {
                                                        e.preventDefault()
                                                        return false;
                                                    }}
                                                />
                                                {this.state.bankDetailsForm.account_number !== this.state.bankDetailsForm.confirm_account_number
                                                    ? <Typography className="error_message">Account Don't Match</Typography> :
                                                    this.state.bankNoError ?
                                                        (
                                                            <div className="error_message">
                                                                Please Enter Valid Account Number
                                                            </div>
                                                        )
                                                        : ""
                                                }
                                            </Grid>
                                        )
                                    }

                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >

                                            IFSC Code
                                        </CustomInputLabel>
                                        <CustomInputField
                                            id="outlined-basic"
                                            placeholder="IFSC Code"
                                            variant="outlined"
                                            name="ifsc_code"
                                            inputProps={{
                                                maxLength: 11
                                            }}
                                            onKeyDown={this.handleMinuSign}
                                            value={this.state.bankDetailsForm.ifsc_code}
                                            onChange={this.handleChangeBankDetails}
                                            disabled={!this.state.editPersonalDetails}
                                        />
                                        {this.state.showErrorIfsc &&
                                            <Typography className="error_message">Please Enter Valid Ifsc Code</Typography>}
                                    </Grid>
                                    <Grid item lg={12} sm={12} md={12} xs={12}>
                                        <CustomInputLabel
                                            htmlFor="component-simple"
                                            style={webStyles.marginGrid}
                                            required
                                        >
                                            Cancelled Cheque Photo
                                        </CustomInputLabel>
                                        <div className="upload_file_fileInput">
                                            <input
                                                type="file"
                                                id="customInput2"
                                                placeholder="Cancelled Cheque Photo"
                                                className="hidden"
                                                accept="image/jpg,image/jpeg,image/*"
                                                name="check"
                                                hidden
                                                disabled={!this.state.editPersonalDetails}
                                                onChange={(e: React.ChangeEvent<any>) => {
                                                    this.uploadCancelledCheckPhoto(e);
                                                }}
                                                style ={{textOverflow : 'ellipsis' , overflow : 'hidden' ,whiteSpace : 'nowrap'}}
                                            />
                                            <Grid item>
                                                <label
                                                    className="label_upload_cmr_copy"
                                                    htmlFor="customInput2"
                                                    style={{textOverflow : 'ellipsis' ,overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                >
                                                    {
                                                        this.state.uploadCancelledCheckError ?
                                                            <Typography className="upload_text_cmr_copy_error">
                                                                Upload Only Image
                                                            </Typography>
                                                            :
                                                            (cancelledCheckName ?
                                                                (
                                                                    <Box style={{ display: 'flex', textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap' , flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between', columnGap: '6%' }}>
                                                                        <Typography
                                                                            className="upload_text_cmr_copy"
                                                                            style ={{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                                        >
                                                                            {
                                                                                cancelledCheckName
                                                                            }
                                                                        </Typography>
                                                                    </Box>
                                                                )
                                                                :
                                                                (
                                                                    <Typography
                                                                        className="placeholder_text_cmr_copy"
                                                                    >
                                                                        Cancelled Cheque Photo
                                                                    </Typography>
                                                                )
                                                            )
                                                    }
                                                    {this.state.uploadCancelledCheckError || this.state.bankDetailsForm?.check.length !== 0 || this.state.cancelledCheck ?
                                                        <Typography className="add_circle_icon" onClick={(e: any) => {
                                                            this.state.editPersonalDetails && (
                                                                this.removeBankCheckName(),
                                                                e.preventDefault()
                                                            )
                                                        }}>X</Typography>
                                                        :
                                                        <AddCircleOutlineRoundedIcon
                                                            className="add_circle_icon"
                                                        />
                                                    }
                                                </label>
                                            </Grid>
                                        </div>
                                    </Grid>


                                </>
                            )
                        }
                        {
                            this.state.selectedoption === 'Demat Details' && (
                                <>
                                    <Grid item lg={12} sm={12} md={12} xs={12} style={{ marginBottom: '4%' }}>
                                        <AppBar position="static" id="MuiAppBar-colorPrimary">
                                            <Tabs indicatorColor="primary" value={this.state.profileTabIndexValue} onChange={this.typeOfLabel}>
                                                <Tab label="NSDL" id="demate_tab" disabled={this.state.editPersonalDetails === false} />
                                                <Tab label="CDSL" id="demate_tab" disabled={this.state.editPersonalDetails === false} />
                                            </Tabs>
                                        </AppBar>
                                    </Grid>
                                    {
                                        this.state.profileTabIndexValue == "1" ?
                                            <>
                                            {this.getUploadResumeView(this.uploadDematCmrCopy,this.removeCMRCopy, cmrName, this.state.uploadCmrCopyError,"cdsl_dp_id",this.state.dematDetailsForm?.cdsl_dp_id,this.state.isDPIdError)}
                                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                                    <CustomInputLabel
                                                        htmlFor="component-simple"
                                                        style={webStyles.marginGrid}
                                                        required
                                                    >
                                                        Client ID
                                                    </CustomInputLabel>
                                                    <CustomInputField
                                                        id="outlined-basic"
                                                        placeholder="Client ID"
                                                        variant="outlined"
                                                        name="cdsl_client_id"
                                                        type="number"
                                                        inputProps={{
                                                            maxLength: 8
                                                        }}
                                                        value={this.state.dematDetailsForm?.cdsl_client_id}
                                                        onChange={this.handleChangeDematDetails}
                                                        disabled={!this.state.editPersonalDetails}
                                                    />
                                                    {
                                                        this.state.isClientIdError && (
                                                            <div className="error_message">
                                                                Please Enter Valid Client ID
                                                            </div>
                                                        )
                                                    }
                                                </Grid>
                                            </>
                                            :
                                            <>
                                            {this.getUploadResumeView(this.uploadNsdlDematCmrCopy,this.removeNsdlCMRCopy, cmrNsdlName, this.state.uploadNsdlCmrCopyError,"nsdl_dp_id",this.state.dematDetailsForm?.nsdl_dp_id,this.state.isNsdlDpIdError)}
                                            </>
                                    }
                                </>
                            )
                        }
                        {
                            this.state.selectedoption === 'KYC Details' && (
                                <>
                                    {
                                        localStorage.getItem('role_id') == "1" ?
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                <CustomInputLabel
                                                    htmlFor="component-simple"
                                                    style={webStyles.marginGrid}
                                                    required
                                                >
                                                    Type Of Debt Seeker
                                                </CustomInputLabel>
                                                {
                                                    !this.state.editPersonalDetails ?

                                                        <CustomInputField
                                                            id="outlined-basic"
                                                            placeholder="Type Of Debt Seeker"
                                                            variant="outlined"
                                                            name="debt_seeker_type"
                                                            value={this.state.kycDetails?.debt_seeker ? debtSeekerValue : this.state.debt_seeker_type}
                                                            onChange={this.handleChangeKYCDetails}
                                                            disabled={!this.state.editPersonalDetails}
                                                        />
                                                        :
                                                        <FormControl
                                                            fullWidth
                                                            variant="outlined"
                                                            className="kyc_form_control"
                                                            style={{ marginBottom: '15px' }}
                                                            placeholder="Type Of Debt Seeker"
                                                        >
                                                            <Select
                                                                disableUnderline
                                                                value={this.state.kycDetails?.debt_seeker ? this.state.kycDetails?.debt_seeker : 0}
                                                                onChange={this.handleChangeKYCDetails}
                                                                name="debt_seeker"
                                                                className="kyc_form_control"
                                                                onClick={this.showErrorDropDown}
                                                                placeholder="Type Of Debt Seeker"
                                                                IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                                }
                                                                MenuProps={{
                                                                    PaperProps: {
                                                                        style: {
                                                                            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                            marginTop: 8
                                                                        }
                                                                    },
                                                                    anchorOrigin: {
                                                                        vertical: "bottom",
                                                                        horizontal: "left",
                                                                    },
                                                                    getContentAnchorEl: null
                                                                }}
                                                                defaultValue={""}
                                                                disabled={!this.state.editPersonalDetails}
                                                            >
                                                                <MenuItem value={0} disabled>
                                                                    <em className="select_placeholder">Type Of Debt Seeker</em>
                                                                </MenuItem>
                                                                {
                                                                    this.state.seekarTypes?.map((item: any, index: number) => {
                                                                        return (
                                                                            <MenuItem value={item.id} key={index}>
                                                                                {item.value}
                                                                            </MenuItem>
                                                                        )
                                                                    })
                                                                }
                                                            </Select>
                                                        </FormControl>
                                                }
                                                {
                                                    this.state.editPersonalDetails && this.state.showErrorDropValue && this.state.kycDetails.debt_seeker === 0 && (
                                                        <div className="error_message">
                                                            Please select debt seeker
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                            :
                                            localStorage.getItem('role_id') == "2" ?
                                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                                    <CustomInputLabel
                                                        htmlFor="component-simple"
                                                        style={webStyles.marginGrid}
                                                        required
                                                    >
                                                        Type Of Debt Provider
                                                    </CustomInputLabel>
                                                    {
                                                        !this.state.editPersonalDetails ?

                                                            <CustomInputField
                                                                id="outlined-basic"
                                                                placeholder="Type Of Debt Provider"
                                                                variant="outlined"
                                                                name="debt_provider_type"
                                                                value={this.state.kycDetails.debt_provider ? debtProviderProfileValue : this.state.debt_provider_type}
                                                                onChange={this.handleChangeKYCDetails}
                                                                disabled={!this.state.editPersonalDetails}
                                                            />
                                                            :
                                                            <FormControl
                                                                fullWidth
                                                                variant="outlined"
                                                                className="kyc_form_control"
                                                                style={{ marginBottom: '15px' }}
                                                                placeholder="Type Of Debt Provider"
                                                            >
                                                                <Select
                                                                    disableUnderline
                                                                    value={this.state.kycDetails.debt_provider ? this.state.kycDetails.debt_provider : 0}
                                                                    onChange={this.handleChangeKYCDetails}
                                                                    name="debt_provider"
                                                                    className="kyc_form_control"
                                                                    placeholder="Type Of Debt Provider"
                                                                    onClick={this.showErrorProviderDropDown}
                                                                    IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                                    }
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                marginTop: 8
                                                                            }
                                                                        },
                                                                        anchorOrigin: {
                                                                            vertical: "bottom",
                                                                            horizontal: "left"
                                                                        },
                                                                        getContentAnchorEl: null,
                                                                    }}
                                                                    defaultValue={"Type Of Debt Provider"}
                                                                    disabled={!this.state.editPersonalDetails}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <em className="select_placeholder">Type Of Debt Provider</em>
                                                                    </MenuItem>
                                                                    {
                                                                        this.state.providerTypes?.map((item: any, index: number) => {
                                                                            return (
                                                                                <MenuItem value={item.id} key={index}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                    }
                                                    {
                                                        this.state.editPersonalDetails && this.state.showErrorProviderDropValue && this.state.kycDetails.debt_provider === 0 && (
                                                            <div className="error_message">
                                                                Please select debt provider
                                                            </div>
                                                        )
                                                    }
                                                </Grid>
                                                :
                                                <Grid item lg={12} sm={12} md={12} xs={12}>
                                                    <CustomInputLabel
                                                        htmlFor="component-simple"
                                                        style={webStyles.marginGrid}
                                                        required
                                                    >
                                                        Type Of Debt Arranger
                                                    </CustomInputLabel>
                                                    {
                                                        !this.state.editPersonalDetails ?

                                                            <CustomInputField
                                                                id="outlined-basic"
                                                                placeholder="Type Of Debt Arranger"
                                                                variant="outlined"
                                                                name="typeOdDebtSeeker"
                                                                value={this.state.kycDetails.debt_arranger ? debtArrangerValue : this.state.debt_arranger_type}
                                                                onChange={this.handleChangeKYCDetails}
                                                                disabled={!this.state.editPersonalDetails}
                                                            />
                                                            :
                                                            <FormControl
                                                                fullWidth
                                                                variant="outlined"
                                                                className="kyc_form_control"
                                                                style={{ marginBottom: '15px' }}
                                                            >
                                                                <Select
                                                                    disableUnderline
                                                                    value={this.state.kycDetails.debt_arranger ? this.state.kycDetails.debt_arranger : 0}
                                                                    onChange={this.handleChangeKYCDetails}
                                                                    name="debt_arranger"
                                                                    className="kyc_form_control"
                                                                    onClick={this.showErrorArrangerDropDown}
                                                                    IconComponent={() => <KeyboardArrowDown className="select_icon_profile" />
                                                                    }
                                                                    MenuProps={{
                                                                        PaperProps: {
                                                                            style: {
                                                                                maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                                                                                marginTop: 8
                                                                            }
                                                                        },
                                                                        anchorOrigin: {
                                                                            vertical: "bottom",
                                                                            horizontal: "left"
                                                                        },
                                                                        getContentAnchorEl: null,
                                                                    }}
                                                                    defaultValue={""}
                                                                    disabled={!this.state.editPersonalDetails}
                                                                >
                                                                    <MenuItem value={0} disabled>
                                                                        <em className="select_placeholder">Type Of Debt Arranger </em>
                                                                    </MenuItem>
                                                                    {
                                                                        this.state.ArrangerTypes?.map((item: any, index: number) => {
                                                                            return (
                                                                                <MenuItem value={item.id} key={index}>
                                                                                    {item.value}
                                                                                </MenuItem>
                                                                            )
                                                                        })
                                                                    }
                                                                </Select>
                                                            </FormControl>
                                                    }
                                                    {
                                                        this.state.editPersonalDetails && this.state.showErrorArrangerDropValue && this.state.kycDetails.debt_arranger === 0 && (
                                                            <div className="error_message">
                                                                Please select debt arranger
                                                            </div>
                                                        )
                                                    }
                                                </Grid>
                                    }
                                    {
                                        localStorage.getItem('role_id') == "1" &&
                                        <>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                {
                                                    this.getDebtValueSelectedForProfileCinView()
                                                }
                                                {
                                                    this.state.debtSeekerSelectedShowValueForProfile == "LLP"
                                                    &&
                                                    <>
                                                        <CinForProfile htmlFor="component-simple" label = "LLPIN" cinId = "outlined-basic" placeholder= "LLP" inputProps = {{maxLength : 7}} value = {this.state.llp} onChange ={(e: any) => { this.getLlpInValue(e)}} disabled={!this.state.editPersonalDetails}/>
                                                        {
                                                            this.state.llpError && this.state.llp.length != 0 && <Typography style={{ color: 'red' }}>Please enter valid LLPIN value</Typography>
                                                        }
                                                    </>
                                                }
                                                 <CinForProfile cinId="outlined-basic" htmlFor = "component-simple"  label = "PAN" placeholder = "ABCTY1234D" inputProps = {{maxLength: 10}} value = {this.state.pan} onKeyDown = {this.handleMinuSign} onChange = {(e: any) => { this.getPanValue(e) }} disabled = {!this.state.editPersonalDetails}/>
                                                {
                                                    this.state.errorPanCard && (
                                                        <div className="error_message">
                                                            Please Enter valid PAN Number
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                <CustomInputLabel
                                                    htmlFor="component-simple"
                                                    style={webStyles.marginGrid}
                                                    required
                                                >
                                                    PAN Photo
                                                </CustomInputLabel>
                                                <div className="upload_file_fileInput">
                                                    <input
                                                        type="file"
                                                        id="customInput2"
                                                        placeholder="Upload your Resume"
                                                        className="hidden"
                                                        accept="image/jpg,image/jpeg,image/*"
                                                        name="panPhoto"
                                                        hidden
                                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                        disabled={!this.state.editPersonalDetails}
                                                        onChange={(e: React.ChangeEvent<any>) => {
                                                            this.uploadFile(e);
                                                        }}
                                                    />
                                                    <label
                                                        className="label_upload_cmr_copy"
                                                        htmlFor="customInput2"
                                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                    >
                                                        {
                                                            this.state.errorUploadPanPhoto ? (
                                                                <Box style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between' }}>
                                                                    <Typography className="upload_text_cmr_copy_error">
                                                                        Upload Only Image File
                                                                    </Typography>
                                                                    <Typography
                                                                        onClick={(e: any) => {
                                                                            this.state.editPersonalDetails &&
                                                                                (this.removePanPhoto(),
                                                                                    e.preventDefault())
                                                                        }}
                                                                        style={{
                                                                            marginRight: '-90%'
                                                                        }}
                                                                    >X</Typography>
                                                                </Box>
                                                            )
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.file.length !== 0 || this.state.panCardName?.length !== 0 ?
                                                                            <>
                                                                                <Typography
                                                                                    className="upload_text_cmr_copy"
                                                                                    style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                                                >
                                                                                    {
                                                                                        this.state.panCardName ?
                                                                                            this.state.panCardName
                                                                                            :
                                                                                            this.state.file.name
                                                                                    }
                                                                                </Typography>
                                                                            </>
                                                                            :
                                                                            <Typography
                                                                                className="placeholder_text_cmr_copy"
                                                                            >
                                                                                PAN Photo
                                                                            </Typography>
                                                                    }
                                                                    {
                                                                        this.state.file.length !== 0 || this.state.panCardName ?
                                                                            <Typography className="add_circle_icon"
                                                                                onClick={(e: any) => {
                                                                                    this.state.editPersonalDetails &&
                                                                                        (this.removePanPhoto(),
                                                                                            e.preventDefault())
                                                                                }}
                                                                            >X</Typography>
                                                                            :
                                                                            <AddCircleOutlineRoundedIcon
                                                                                className="add_circle_icon"
                                                                            />
                                                                    }
                                                                </>
                                                        }
                                                    </label>
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        localStorage.getItem('role_id') == "2" &&
                                        <>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                {
                                                    this.getDebtValueSelectedForProfileCinView()
                                                }
                                                  <CinForProfile cinId="outlined-basic" htmlFor = "component-simple"  label = "PAN" placeholder = "ABCTY1234D" inputProps = {{maxLength: 10}} value = {this.state.pan} onKeyDown = {this.handleMinuSign} onChange = {(e: any) => { this.getPanValue(e) }} disabled = {!this.state.editPersonalDetails}/>                                                {
                                                    this.state.errorPanCard && (
                                                        <div className="error_message">
                                                            Please Enter valid PAN Number
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                <CustomInputLabel
                                                    htmlFor="component-simple"
                                                    style={webStyles.marginGrid}
                                                    required
                                                >
                                                    PAN Photo
                                                </CustomInputLabel>
                                                <div className="upload_file_fileInput">
                                                    <input
                                                        type="file"
                                                        id="customInput2"
                                                        placeholder="Upload your Resume"
                                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                        className="hidden"
                                                        accept="image/jpg,image/jpeg,image/*"
                                                        name="panPhoto"
                                                        hidden
                                                        disabled={!this.state.editPersonalDetails}
                                                        onChange={(e: React.ChangeEvent<any>) => {
                                                            this.uploadFile(e);
                                                        }}
                                                    />
                                                    <label
                                                        className="label_upload_cmr_copy"
                                                        htmlFor="customInput2"
                                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                    >
                                                        {
                                                            this.state.errorUploadPanPhoto ?
                                                                <Typography className="upload_text_cmr_copy_error">
                                                                    Upload Only Image File
                                                                </Typography>
                                                                :
                                                                <>
                                                                    {
                                                                        this.state.file.length !== 0 || this.state.panCardName?.length !== 0 ?
                                                                            <>
                                                                                <Typography
                                                                                    className="upload_text_cmr_copy"
                                                                                    style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                                                >
                                                                                    {
                                                                                        this.state.panCardName ?
                                                                                            this.state.panCardName
                                                                                            :
                                                                                            this.state.file.name
                                                                                    }
                                                                                </Typography>
                                                                            </>
                                                                            :
                                                                            <Typography
                                                                                className="placeholder_text_cmr_copy"
                                                                            >
                                                                                PAN Photo
                                                                            </Typography>
                                                                    }
                                                                </>
                                                        }
                                                        {
                                                            this.state.file.length !== 0 || this.state.panCardName || this.state.errorUploadPanPhoto ?
                                                                <Typography className="add_circle_icon"
                                                                    onClick={(e: any) => {
                                                                        this.state.editPersonalDetails &&
                                                                            (this.removePanPhoto(),
                                                                                e.preventDefault())
                                                                    }}
                                                                >X</Typography>
                                                                :
                                                                <AddCircleOutlineRoundedIcon
                                                                    className="add_circle_icon"
                                                                />
                                                        }
                                                    </label>
                                                </div>
                                            </Grid>
                                        </>
                                    }
                                    {
                                        localStorage.getItem('role_id') == "3" &&
                                        <>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                {
                                                    this.getDebtValueSelectedForProfileCinView()
                                                }
                                                {
                                                    this.state.debtArrangerSelectedShowValueForProfile == "LLP"
                                                    &&
                                                    <>
                                                        <CinForProfile htmlFor="component-simple" label = "LLPIN" cinId = "outlined-basic" placeholder= "LLP" inputProps = {{maxLength : 7}} value = {this.state.llp} onChange ={(e: any) => { this.getLlpInValue(e)}} disabled={!this.state.editPersonalDetails}/>
                                                        {
                                                            this.state.llpError && this.state.llp.length != 0 && <Typography style={{ color: 'red' }}>Please enter valid LLPIN value</Typography>
                                                        }
                                                    </>
                                                }
                                                <CinForProfile cinId="outlined-basic" htmlFor = "component-simple"  label = "PAN" placeholder = "ABCTY1234D" inputProps = {{maxLength: 10}} value = {this.state.pan} onKeyDown = {this.handleMinuSign} onChange = {(e: any) => { this.getPanValue(e) }} disabled = {!this.state.editPersonalDetails}/>
                                                {
                                                    this.state.errorPanCard && (
                                                        <div className="error_message">
                                                            Please Enter valid PAN Number
                                                        </div>
                                                    )
                                                }
                                            </Grid>
                                            <Grid item lg={12} sm={12} md={12} xs={12}>
                                                <CustomInputLabel
                                                    htmlFor="component-simple"
                                                    style={webStyles.marginGrid}
                                                    required
                                                >
                                                    PAN Photo
                                                </CustomInputLabel>
                                                <div className="upload_file_fileInput">
                                                    <input
                                                        type="file"
                                                        id="customInput2"
                                                        placeholder="Upload your Resume"
                                                        className="hidden"
                                                        accept="image/jpg,image/jpeg,image/*"
                                                        name="panPhoto"
                                                        hidden
                                                        style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                        disabled={!this.state.editPersonalDetails}
                                                        onChange={(e: React.ChangeEvent<any>) => {
                                                            this.uploadFile(e);
                                                        }}
                                                    />
                                                    <label
                                                        className="label_upload_cmr_copy"
                                                        htmlFor="customInput2"
                                                        style={{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                    >
                                                        {
                                                            this.state.errorUploadPanPhoto ?
                                                                <Typography className="upload_text_cmr_copy_error">
                                                                    Upload Only Image File
                                                                </Typography>
                                                                :
                                                                this.state.file.length !== 0 || this.state.panCardName?.length !== 0 ?
                                                                    <>
                                                                        <Typography
                                                                            className="upload_text_cmr_copy"
                                                                            style = {{textOverflow : 'ellipsis' , overflow : 'hidden' , whiteSpace : 'nowrap'}}
                                                                        >
                                                                            {
                                                                                this.state.panCardName ?
                                                                                    this.state.panCardName
                                                                                    :
                                                                                    this.state.file.name
                                                                            }
                                                                        </Typography>
                                                                    </>
                                                                    :
                                                                    <Typography
                                                                        className="placeholder_text_cmr_copy"
                                                                    >
                                                                        PAN Photo
                                                                    </Typography>
                                                        }
                                                        {
                                                            this.state.file.length !== 0 || this.state.panCardName || this.state.errorUploadPanPhoto ?
                                                                <Typography className="add_circle_icon"
                                                                    onClick={(e: any) => {
                                                                        this.state.editPersonalDetails &&
                                                                            (this.removePanPhoto(),
                                                                                e.preventDefault())
                                                                    }}
                                                                >X</Typography>
                                                                :
                                                                <AddCircleOutlineRoundedIcon
                                                                    className="add_circle_icon"
                                                                />
                                                        }
                                                    </label>
                                                </div>
                                                {/* {
                                                    this.state.errorUploadPanPhoto ? (
                                                        <Typography className="error_message">
                                                            Upload Only Image File
                                                        </Typography>
                                                    )
                                                        :
                                                        null
                                                } */}
                                            </Grid>
                                        </>
                                    }
                                </>
                            )
                        }
                    </Grid>
                    {
                        this.state.editPersonalDetails && (
                            <Grid
                                container
                                item
                                lg={12} sm={12} md={12} xs={12}
                                spacing={2}
                                justifyContent="center"
                                style={webStyles.buttonContainer}
                            >
                                <Grid item lg={2} sm={3} md={3} xs={12}></Grid>
                                <Grid item lg={2} sm={3} md={3} xs={6}>
                                    <Buttons
                                        variant="outlined"
                                        onClick={this.cancelFunctionality}
                                    >
                                        Cancel
                                    </Buttons>
                                </Grid>
                                <Grid item lg={2} sm={3} md={3} xs={6}>
                                    <Buttons
                                        variant="contained"
                                        data-testid = "profilesubmit"
                                        onClick={
                                            this.state.selectedoption === "KYC Details" ?
                                            this.debounce(() => this.kycApiCalled(), 500)
                                                : this.state.selectedoption === "Demat Details" ?
                                                    this.debounce(() => this.demateApiCall(), 500) :
                                                     this.state.selectedoption === "Bank Details" ?
                                                       this.debounce(() => this.bankDetailApiCalledOne(),500)
                                                        : this.state.selectedoption === "Personal Details" ?
                                                            this.debounce(() => this.updatePersonalDetails() , 500)
                                                            : this.debounce(() => this.updateKYCDetails() , 500)
                                        }
                                    >
                                        Submit
                                    </Buttons>
                                </Grid>
                            </Grid>
                        )
                    }
                </Grid>
            </>
        );
    }
}

export const webStyles:any = {
    editButton: {
        borderRadius: "24px",
        backgroundImage: "linear-gradient(to left ,#001d56,#1e43d5 )",
        fontFamily: 'Lato',
        fontWeight: 400,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.45px',
        color: '#FFFFFF',
        height: '51px',
        width: '233.14px',
        padding: '10px 25px'
    },
    header: {
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '24px',
        lineHeight: '29px',
        letterSpacing: '-0.6px',
        color: '#323232',
        marginBottom: '22px',
    },
    mainGrid: {
        padding: '90px 84px 0px 126px',
    },
    secondGrid: {
        padding: '32px 84px 50px 123px'
    },
    optionBox: {
        maxWidth: '222px',
        backgroundColor: '#ECEDF2',
        padding: '8px 10.18px',
        marginBottom: '30px',
        maxHeight: '295px',
        borderRadius: '12px',
        background: '#EBEDF2',
    },
    marginGrid: {
        marginTop: '10px'
    },
    gridMargin: {
        marginTop: '-10px'
    },
    cancelButton: {
        border: '1px sold linear-gradient(to left ,#001d56,#1e43d5)',
        color: '#2A354A',
        fontSize: '16px',
        height: '35px',
        fontWeight: 500,
        fontFamily: 'Lato'
    },
    buttonContainer: {
        marginTop: '58px'
    },
    otpContainer: {
        marginTop: '39px'
    },
    receiveCodeText: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.4px',
        color: '#323232',
        paddingLeft: '68px',
        paddingTop: '9px',
    },
    resendCode: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '16px',
        lineHeight: '19px',
        letterSpacing: '-0.4px',
        textDecorationLine: 'underline',
        color: '#001D56',
        paddingLeft: '8px',
        paddingTop: '9px',
        cursor: 'hand',
    }
};

const CustomButton:any = withStyles({
    root: {
        "& .MuiButton-label": {
            justifyContent: 'left'
        },
        height: '59px',
        minWidth: '201px',
        fontFamily: 'Lato',
        fontWeight: 700,
        fontSize: '18px',
        lineHeight: '22px',
        letterSpacing: '-0.45px',
        color: '#FFFFFF',
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '12px',
        padding: '19px 0px 18px 14.18px',
        cursor: 'hand',
        "&.MuiButton-contained": {
            backgroundColor: ' #001D56',
            marginBottom: '10px',
            color: '#FFFFFF',
            "&:hover": {
                backgroundColor: ' #001D56',
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid #ECEDF2',
            color: '#000000',
            marginBottom: '10px',
            padding: '19px 0px 18px 14.18px',
            "&:hover": {
                backgroundColor: '#ECEDF2',
            }
        },
        "&:hover": {
            boxShadow: 'none',
        },
        "@media (max-width: 500px)": {
            width: '100%',
        }
    },
})(Button);

export const CustomInputLabel:any = withStyles({
    root: {
        margin: "22px 0px 8px 16px",
        fontSize: "16px",
        color: '#323232',
        fontFamily: 'Lato',
        fontWeight: 500,
        lineHeight: '19px',
        letterSpacing: '-0.4px',
    },
})(InputLabel);

const Buttons:any = withStyles({
    root: {
        height: '51px',
        width: '100%',
        fontWeight: 400,
        fontFamily: 'Lato',
        fontStyle: 'normal',
        fontSize: '18px',
        textTransform: 'none',
        boxShadow: 'none',
        borderRadius: '25px',
        lineHeight: '22px',
        letterSpacing: '-0.45px',
        color: '#001D56',
        padding: '13px 15px 14px 15px',
        "&.MuiButton-contained": {
            background: `linear-gradient(108.82deg, #003E8F 25.52%, #001D56 85.05%)`,
            color: 'rgba(255, 255, 255, 1)',
            marginBottom: '13px',
            "&:hover": {
                background: `linear-gradient(108.82deg, #003E8F 25.52%, #001D56 85.05%)`,
            }
        },
        "&.MuiButton-outlined": {
            border: '1px solid #002863',
            color: '#002863',
            "&:hover": {
                backgroundColor: 'transparent',
            }
        },
        "&:hover": {
            boxShadow: 'none',
        },
        "@media (max-width: 500px)": {
            width: '100%',
        }
    },
})(Button);
// Customizable Area End
