// Customizable Area Start
import React from "react";

import {
  Container,
  Box,
  Input,
  Button,
  InputLabel,
  Typography,
  InputAdornment,
  IconButton,
  AppBar,
  Toolbar,
  Drawer,
  List,
  Grid,
  CssBaseline
} from "@material-ui/core";
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import { NavLink, Switch, Route } from "react-router-dom"
import { finLogo } from "./assets"
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import Visibility from "@material-ui/icons/Visibility";
import { withRouter } from "react-router-dom";
import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
import ResponsiveDrawer from "./Drawer.web";
import AppHeader from "../../catalogue/src/AppHeader.web";

class ContentManagement extends ContentManagementController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    return (
      <>
        <AppHeader navigation={this.props.navigation} id="" location="" history="" match="">
        </AppHeader>
        <ResponsiveDrawer />
      </>
    );
  }
}
export default withRouter(ContentManagement as any) as any;
// Customizable Area End
