import React from "react";
// Customizable Area Start
import {
    Paper,
    Box,
    Container,
    Toolbar,
    Grid,
    makeStyles,
    Typography,
    CssBaseline
} from "@material-ui/core";
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";



export default class Advisors extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getPrivacyPolicy();
    }

    render() {
        return (
            <>
                <Header match='' location="" history="" id="" navigation={this.props.navigation} />
                <Toolbar />
                <Toolbar />
                <Container maxWidth="lg" disableGutters >
                    <Grid container>
                        <Grid item xs={12}>
                            <Box style={webstyles.paper}>
                                <Typography variant="h4">Advisors</Typography>

                                <Box sx={webstyles.privacyContainer} >
                                    <Typography>
                                        Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.
                                    </Typography>
                                </Box>
                            </Box>
                        </Grid>
                    </Grid>
                </Container>

            </>

        );
    }
}

const webstyles = {
    paper: {
        padding: "24px 32px",
        minHeight: "auto",
        margin: "16px 0",
    },
    privacyContainer: {
        paddingTop: "40px"
    }
}
// Customizable Area End