import React from "react";
import "./styles.css";
import {
    FormControl,
    Select,
    FormHelperText,
    makeStyles, MenuItem
} from "@material-ui/core";
import KeyboardArrowDownIcon from "@material-ui/icons/KeyboardArrowDown";

const useStyles = makeStyles({
    label: {
        paddingTop: "1%",
        paddingBottom: "1%",
        // fontFamily: "Roboto-Regular",
        fontStyle: "normal",
        fontWeight: "normal",
        color: "#000",
        paddingLeft: "16px",
    },


    option: {
        // fontStyle: "Lato",
        fontWeight: 400,
        marginTop: "10px",
        fontSize: "16px",
        cursor: "pointer"

    }
});

type SelectProps = {
    label: string;
    options: any;
    name: string;
    onChange: any;
    width: string;
    value: any;
    borderRadius: string;
    onClick: any;
    disabled?: boolean;
}

function CustomSelectAddMore(props: SelectProps) {
    const { label, options, width, name, onChange, value, borderRadius, onClick, disabled } = props;
    const classes = useStyles();
    return (
        <div>
            <FormControl
                style={{ width: `${width}` }}
                className="customSelectAddMore_formControl"
                fullWidth
                variant="outlined"
                size="small"
            >
                <Select
                    className="customSelectAddMore_select"
                    style={{ borderRadius: `${borderRadius}` }}
                    disableUnderline
                    displayEmpty
                    name={name}
                    disabled={disabled}
                    // native
                    value={value}
                    IconComponent={() => <KeyboardArrowDownIcon style={{ position: 'absolute', right: 12, pointerEvents: 'none' }} />}
                    onChange={onChange}
                    onClick={onClick}
                    MenuProps={{
                        PaperProps: {
                            style: {
                                borderRadius: "8px",
                                padding: "8px",
                            }
                        }
                    }}

                ><MenuItem value="" disabled><span style={{ color: "darkgray" }}>-Select one-</span></MenuItem>
                    {options.map((item: any, index: number) => (
                        <MenuItem className={classes.option} key={item} value={item?.id}>
                            {item?.attributes?.title || item?.attributes?.name}
                        </MenuItem>
                    ))}
                </Select>
                {
                    name == "Minimum_Investment_per_Investor" ? (<FormHelperText>Note: Should be less than Total Deal Size </FormHelperText>) : null
                }
            </FormControl>
        </div>
    );
}

export default CustomSelectAddMore;
