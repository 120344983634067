import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import {EndUse,RatingProps,InterestPaymentFrequency,PrincipalRepaymentFrequency,RatingOutlook,TypeOfInstument,Sector,SubSector,CreditRatingAgency,Rating} from './proposedInstrumentDetailsController.web';
import { apiCall, callApi, getHeaders, isLoggedIn, Role_name } from "../../../components/src/common";

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  history: any;
  match: any;
  location: any;
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  fin_summary_id: string;
  uploadFinSummary: any;
  deal_id: string;
  user: string;
  userName :string;
  isYeildError: boolean;
  isCouponError: boolean;
  isUpdateFinancialSummary: boolean;
  isNavigate: boolean;
  openProfileSettings: any;
  openSwitch: boolean;
  openProfileMenus: any;
  sign_out_pop_up: boolean;
  isUploadSummaryError: boolean;
  showFinanncialSummaryError: boolean;
  isReadMode: boolean;
  isNext: boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {


  getProductApiCallId: any;
  // Customizable Area Start
  endUserApiCallId: string = "";
  typeOfInstumentApiCallId: string = "";
  sectorApiCallId: string = "";
  subSectorApiCallId: string = "";
  principleRepaymentFrequencyApiCallId: string = "";
  interestPaymentFrequencyApiCallId: string = "";
  creditRatingAgencyApiCallId: string = "";
  ratingOutLookApiCallId: string = "";
  ratingDetailsApiCallId: string = "";
  saveAsDraftApiCallId: string = "";
  UploadFinSummaryApiCallId: string = "";
  UploadFinSummarySafeDraftApiCallId: string = "";
  fin_show_getApiCallId: string = "";
  fin_summary_put_ApiCallId: string = "";
  FinSummaryDownloadApiCallId: string = "";
  userRoles: any[] = [];
  switchProfileApiCallId: string = "";
  getUserSpecificRoleApiCallId: string = "";
  getPresentUserInformation: string = "";
  getApiForPersonalDeatils : string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      getName(MessageEnum.redirectMessage),
      // Customizable Area End
    ];

    this.state = {
      arrayHolder: [],
      token: "",
      // Customizable Area Start
      fin_summary_id: "",
      uploadFinSummary: "",
      deal_id: "",
      user: "",
      userName : "",
      isYeildError: false,
      isCouponError: false,
      isUpdateFinancialSummary: false,
      openProfileSettings: false,
      isNavigate: false,
      openSwitch: false,
      openProfileMenus: false,
      sign_out_pop_up: false,
      isUploadSummaryError: false,
      showFinanncialSummaryError: false,
      isReadMode: false,
      isNext: false
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    super.componentDidMount();
    this.getToken();
    // Customizable Area Start
    const token=localStorage.getItem("token")
    token && this.getPersonalDetailsData();
    const role_name = new URLSearchParams(window.location.search).get('role_name');
    token && this.getPresentUserInfo();
    if(role_name===Role_name.Admin){
      localStorage.removeItem("security_id");
      const token = new URLSearchParams(window.location.search).get('token');
      token && localStorage.setItem("token",token)
      const admin_deal_id = (admin_deal_id => admin_deal_id && localStorage.setItem("deal_id", admin_deal_id))(new URLSearchParams(window.location.search).get("id"));
    }else if(!token){
    }else{
      isLoggedIn(this.props.navigation);
    }
    // Customizable Area End
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
  }

  handleSubmit() {

    let token = localStorage.getItem("token");

    const header = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    var formdata = new FormData();

    formdata.append("data[financial_summarie]", this.state?.uploadFinSummary?.name);

    const httpBody = {
      data: formdata,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );

    this.UploadFinSummaryApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinacialSummariSubmitApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "POST"
    );

    // requestMessage.getData
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }


  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };





  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    var responseJson = message.getData(
      getName(MessageEnum.RestAPIResponceSuccessMessage)
    );
    /* istanbul ignore next */
    if (message.id === getName(MessageEnum.redirectMessage)) {
      const customRedirect = message.getData(getName(MessageEnum.redirectData));
      if (this.state.isNavigate && this.state.uploadFinSummary && customRedirect) {
        this.props.history.push("/Catalogue/ManagementProfile")
      }
    }

    const apiRequestCallId = message.getData(
      getName(MessageEnum.RestAPIResponceDataMessage)
    );
    /* istanbul ignore next */
    if (apiRequestCallId && responseJson) {
      if (apiRequestCallId === this.UploadFinSummaryApiCallId) {
        const msg1: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg1.addData(getName(MessageEnum.CustomAlertBodyData), 'Financial Summary saved successfully');
        this.send(msg1);
      }
      if (apiRequestCallId === this.UploadFinSummarySafeDraftApiCallId) {
        let fin_summary_id = JSON.stringify(responseJson?.data?.attributes?.id)
        localStorage.setItem("fin_summary_id", fin_summary_id)
        if (responseJson.data) {
          this.setState({ isUpdateFinancialSummary: true })
          const msg2: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg2.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg2);
        } else if (responseJson.error) {
          const msg3: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg3.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg3);
          this.setState({ isNavigate: false })
        }
      }
      if(apiRequestCallId === this.getApiForPersonalDeatils){
        if(responseJson?.data){
          this.setState({
            userName : responseJson ?.data?.attributes?.full_name
          });
        }
      }
      if (apiRequestCallId === this.fin_show_getApiCallId) {
        if (responseJson.data) {
          this.setState({ isUpdateFinancialSummary: true })
          let document = responseJson.data.attributes.financial_summarie[0]
          localStorage.setItem('fin_summary_id', responseJson.data.attributes.id)
          let documentName = document.url.split('/')
          this.setState({ uploadFinSummary: { name: documentName[documentName.length - 1] } })
        }
      }
      if (apiRequestCallId === this.getPresentUserInformation) {
        this.setState({user:responseJson?.data?.attributes?.role_name})
        
      }
      if (apiRequestCallId === this.fin_summary_put_ApiCallId) {
        if (responseJson.data) {
          this.setState({ isUpdateFinancialSummary: true })
          const msg4: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg4.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg4);
        } else if (responseJson.error) {
          this.setState({isNavigate:false})
          const msg5: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg5.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg5);
        }
      }
      if (apiRequestCallId === this.FinSummaryDownloadApiCallId) {
        if (responseJson.errors) {
          if (responseJson?.errors[0]?.token) {
            const msg6: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg6.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.errors[0]?.token);
            this.send(msg6);
          } else {
            const msg7: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg7.addData(getName(MessageEnum.CustomAlertBodyData), 'Something went wrong.');
            this.send(msg7);
          }
        } else {
          const url = responseJson.url
          const link = document.createElement('a');
          link.href = url;
          link.download = "financial_statements_template.xlsx" //or any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
        }
      }
      if (apiRequestCallId === this.saveAsDraftApiCallId) {
        if (responseJson?.data?.data?.id) {
          const msg8: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg8.addData(getName(MessageEnum.CustomAlertBodyData), 'Save as Draft Successfully');
          this.send(msg8);
        }
        if (responseJson?.message){
          const msg9: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg9.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.messages);
          this.send(msg9);

        } 
        if (responseJson?.errors){
          const msg9: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg9.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors);
          this.send(msg9);

        }
      }
      if (apiRequestCallId === this.switchProfileApiCallId) {
        if(responseJson.data && responseJson.meta){
          localStorage.setItem("token", responseJson.meta.token);
          localStorage.setItem("is_kyc_done", JSON.stringify(responseJson?.meta?.kyc));
          localStorage.setItem("isBankDetailDone", JSON.stringify(responseJson?.meta?.bank_detail));
          localStorage.setItem("isDemateDetailDone", JSON.stringify(responseJson?.meta?.demat_detail));
          localStorage.setItem("role_id", responseJson.data.attributes.role_id);
          this.getUserSpecificRoles();
        } else {
          if(responseJson.errors && responseJson.errors.length > 0){
            const msg10: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg10.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors[0]);
            this.send(msg10);
          } else{
            const msg11: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg11.addData(getName(MessageEnum.CustomAlertBodyData), configJSON.somethingWentWrongErrorMessage);
            this.send(msg11);
          }
        }
      }
      if (apiRequestCallId === this.getUserSpecificRoleApiCallId) {
        if (responseJson.role) {
          const userRoles = responseJson.role.map((data: any)=> data.role_id);
          localStorage.setItem("user_roles", JSON.stringify(userRoles));
          this.props.navigation.navigate("LandingPage");
        }
      }
    }



    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ arrayHolder: responseJson.data });
        runEngine.debugLog("arrayHolder", this.state.arrayHolder);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
   
  getPersonalDetailsData() {
    let token = localStorage.getItem("token");
    const personalHeader = {
      "Content-Type": configJSON.validationApiContentType,
      "token": token
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage),
    );
    this.getApiForPersonalDeatils = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getPersonalDetailsEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(personalHeader)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getUserSpecificRoleApiMethod
    );
    // requestMessage.getData
    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  getPresentUserInfo = () => {
    this.getPresentUserInformation = callApi({
    method: configJSON.apiMethodTypeGet,
    endPoint: 'account_block/accounts',
  })
}
  /* istanbul ignore next */
  handleDraftSubmit(value: string) {

    if (!this.state.uploadFinSummary) {
      const msg12: Message = new Message(getName(MessageEnum.CustomAlertMessage));
      msg12.addData(getName(MessageEnum.CustomAlertBodyData), "Upload Financial Summary can't be blank.");
      this.send(msg12);
    }

    let token = localStorage.getItem("token");

    const header = {
      "token": token
    };
    var formdata = new FormData();
    if (this.state.uploadFinSummary.type) {
      formdata.append("data[financial_summarie][]", this.state.uploadFinSummary);
    } else if (!this.state.uploadFinSummary.type && !this.state.uploadFinSummary.name) {
      return;
    }
    formdata.append("is_draft", value);


    if (this.state.isUpdateFinancialSummary) {
      // api call update
      const httpBody = {
        formdata
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.fin_summary_put_ApiCallId = requestMessage.messageId;
      let deal_id = localStorage.getItem("deal_id")
      let id = localStorage.getItem("fin_summary_id")



      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `${configJSON.FinacialSummaryUpdateApiEndPoint}deal_id=${deal_id}&id=${id}`
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "PUT"
      );

      // requestMessage.getData
      runEngine.sendMessage(requestMessage.id, requestMessage);

    } else {
      //  add api call
      const httpBody = {
        formdata
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage),
      );

      this.UploadFinSummarySafeDraftApiCallId = requestMessage.messageId;
      let deal_id = localStorage.getItem("deal_id")
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.FinacialSummariSubmitApiEndPoint + deal_id
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );

      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formdata
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        "POST"
      );

      // requestMessage.getData
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }



  }

  fin_show_getapi(deal_id: any) {
    // alert(deal_id)
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const getendUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fin_show_getApiCallId = getendUserMsg.messageId;

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinacialSummaryGetApiEndPoint + deal_id
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
  }
  /* istanbul ignore next */
  fin_put_api(fin_summary_id: any) {
    // alert(fin_summary_id)
    const msg13: Message = new Message(getName(MessageEnum.CustomAlertMessage));
    msg13.addData(getName(MessageEnum.CustomAlertBodyData), fin_summary_id);
    this.send(msg13);
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const getendUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.fin_summary_put_ApiCallId = getendUserMsg.messageId;

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinacialSummaryUpdateApiEndPoint
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "PUT"
    );
    runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
  }

  downloadTemplate() {
    let token = localStorage.getItem("token")
    const headers = {
      "Content-Type": configJSON.validationApiContentType,
      token: token
    };

    const getendUserMsg = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.FinSummaryDownloadApiCallId = getendUserMsg.messageId;

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.FinancialSummaryDownloadApiEndPoint
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    getendUserMsg.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      "GET"
    );
    runEngine.sendMessage(getendUserMsg.id, getendUserMsg);
  }

  handleSwitchProfileApiCall = (role_id: number) =>{
    let token = localStorage.getItem("token")
    const headers = {
      token: token,
    };

    const formdata = new FormData();
    formdata.append("data[role_id]", String(role_id));

    const switchProfile = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.switchProfileApiCallId = switchProfile.messageId;

    switchProfile.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.switchProfileApiEndpoint
    );

    switchProfile.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    switchProfile.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    switchProfile.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.switchProfileApiMethod
    );
    runEngine.sendMessage(switchProfile.id, switchProfile);
  }

  getUserSpecificRoles = () => {
    const token = localStorage.getItem("token") || "";
    const header = getHeaders({ token: token });

    const getUserSpecificRole = apiCall({
      url: configJSON.getUserSpecificRoleApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getUserSpecificRoleApiMethod
    });

    this.getUserSpecificRoleApiCallId = getUserSpecificRole.messageId;
    runEngine.sendMessage(getUserSpecificRole.id, getUserSpecificRole);
  }

  // Customizable Area End
}
