// Customizable Area Start
import React from "react";
import CustomAlertController, { Props } from "./CustomAlert.controller";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Modal from "@material-ui/core/Modal";

class CustomAlert extends CustomAlertController {
    constructor(props: Props) {
        super(props);
    }

    render() {
        const { open, message } = this.state;
        const messageWithLineBreaks = message.replace(/\n/g, "<br>");
        /* istanbul ignore next */
        const numLines = this.state.message.split('\n').length

        return (
            <div>
                <Modal
                    aria-labelledby="transition-modal-title"
                    aria-describedby="transition-modal-description"
                    className="modal"
                    open={open}
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 500,
                    }}
                >
                    <Fade in={open}>
                        <div>
                            <div className="custom_alert_Box" style={{ borderRadius: "unset", width: "385px" }}>
                                <p data-test-id="message-body" style={{maxHeight: 150,overflowY: numLines > 7 ? 'scroll' : 'hidden'}} 
                                    dangerouslySetInnerHTML={{ __html: messageWithLineBreaks }}>
                                </p>
                                {/* <p>{message}</p> */}
                                <button data-test-id="click" onClick={this.handleCloseAlert} className="ok_button">Ok</button>
                            </div>

                        </div>
                    </Fade>
                </Modal>
            </div>
        );
    }
}

export default CustomAlert;
// Customizable Area End
