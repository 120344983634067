import { Message } from "../../framework/src/Message";
import MessageEnum, { getName } from "../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../framework/src/RunEngine";

interface IApiCall {
  header: any;
  httpBody: any;
  url: string;
  httpMethod: string;
}

export enum Role_name {
  Seeker = "Debt Seeker",
  Provider = "Debt_Provider",
  Arranger = "Debt_Arranger",
  Admin = "Sub_Admin",
}

export function isDealIdAvailable(): boolean {
  const dealId = localStorage.getItem("deal_id");
  if (dealId && typeof dealId === "string") {
    return true;
  } else {
    return false;
  }
}

export function isLoggedIn(navigation: any): boolean | string {
  const authToken = localStorage.getItem("token");
  if (!authToken) {
    if (navigation) {
      navigation.navigate("EmailAccountLoginBlock");
      return false;
    } else {
      return false;
    }
  } else {
    return authToken;
  }
}

export function getHeaders(obj: Record<string, string>): Headers {
  const headers: any = {};
  headers["Content-Type"] = "application/json";
  for (let key in obj) {
    headers[key] = obj[key];
  }
  return headers;
}

export function apiCall({ header, httpBody, url, httpMethod }: IApiCall) {
  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    url
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  if (Object.keys(httpBody).length > 0) {
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(httpBody)
    );
  }


  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    httpMethod
  );

  return requestMessage;
}

export function showTextWithValidation(text: string | null | undefined) {
  if (!text || text.length === 0) {
    return '-'
  }
  return text;
}

export function showErrors(errors: any, onlyValues: boolean = false, onlyKeys: boolean = false){
  let text = '';
  
  errors.forEach((obj: any) => {
    
    for(const [key,value] of Object.entries(obj)){
      if(onlyValues){
        text = `${text} ${value}`
      } else if(onlyKeys){
        text = `${text} ${key}`
      } else {
        text = `${text} ${key} ${value}`
      }
    }
  });
  alert(text);
}

export function isAuthorisedUser(response: any): boolean {
  if (response?.errors && response?.errors?.length > 0 && response?.errors[0]?.token) {
    return false;
  } else {
    return true;
  }
}

export function isIssuer(): boolean{
  const roleId = localStorage.getItem("role_id");
  if(roleId=== String(1)){
    return true;
  }
  return false;
}

export function isArranger(): boolean{
  const roleId = localStorage.getItem("role_id");
  if(roleId=== String(3)){
    return true;
  }
  return false;
}

export function isArrayEmpty(array: any): boolean{
  if(!array || (array && array.length === 0)){
    return true
  }
  return false
}


export function checkTotalDeal(myString:any){
  let str = parseFloat(myString)
  return str ? String(str/10000000) : String(0);
  }


export function checkForReadMode(location: any){
  if(location && location.search === "?readMode"){
    return true;
  }
  return false;
}

export function handleReviewSubmitClick(location:any, history: any){
  if(location.pathname.includes("CompanyOverview")){
    history.push("/Catalogue/ProposedInstrumentDetails?readMode");
  } else if(location.pathname.includes("ProposedInstrumentDetails")){
    history.push("/Catalogue/StructureandSecurity?readMode");
  } else if(location.pathname.includes("StructureandSecurity")){
    history.push("/Catalogue/ProjectDetails?readMode");
  } else if(location.pathname.includes("ProjectDetails")){
    history.push("/Catalogue/FinancialSummary?readMode");
  } else if(location.pathname.includes("FinancialSummary")){
    history.push("/Catalogue/ManagementProfile?readMode");
  } else if(location.pathname.includes("ManagementProfile")){
    history.push("/Catalogue/DebtProfile?readMode");
  } else if(location.pathname.includes("DebtProfile")){
    history.push("/Catalogue/SupportiveDocuments?readMode");
  } else if(location.pathname.includes("SupportiveDocuments")){
    history.push("/Catalogue/FAQlist?readMode");
  } else if(location.pathname.includes("FAQlist")){
    const dealId = localStorage.getItem("deal_id");
    history.push(`/Decision/${dealId}`);
  } 
}

//Structure of Api calling start
export function callApi  (data: any) {
  let { method, endPoint, payload } = data
  const token = localStorage.getItem("token");
  const header = {
    "Content-Type": "application/json",
    token: token
  };

  const requestMessage = new Message(
    getName(MessageEnum.RestAPIRequestMessage)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestHeaderMessage),
    JSON.stringify(header)
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIResponceEndPointMessage),
    endPoint
  );

  requestMessage.addData(
    getName(MessageEnum.RestAPIRequestMethodMessage),
    method
  );

  payload && requestMessage.addData(
    getName(MessageEnum.RestAPIRequestBodyMessage),
    JSON.stringify(payload)
  );

  runEngine.sendMessage(requestMessage.id, requestMessage);
  return requestMessage.messageId;
}

export function download (url: string, fileName: string) {
  fetch(url, {
      method: "GET",

  }).then(response => response.blob())
      .then(blob => {
          let url = window.URL.createObjectURL(blob);
          let a = document.createElement('a');
          a.href = url;
          a.download = fileName;
          a.click();
      }).catch(error => console.log('error', error));

}

export function handleDynamicBackground(index:number){
  let bg=''
  switch(true){
      case index===0:
          bg="rgb(94,63,192)";
          break;
      case index===1:
          bg="rgb(203,182,247)";
          break;
      case index===2:
          bg="rgb(229,218,252)";
          break;
      case index===3:
          bg="rgb(244,240,254)";
          break;
      default:bg="rgb(244,240,254)"
  }
  return bg;
}


export const options = {
  title: "",
  legend: "none",
  pieStartAngle: 252,
  pieHole: 0,
  fontSize:16,
  pieSliceTextStyle: {
  color: 'black',
  },
  slices: {
      0: { color: "rgb(94,63,192)",
      textStyle:{
          color:'white'
      }},
      1: { color: "rgb(203,182,247)" },
      2: { color: "rgb(229,218,252)" },
      3: {color: "rgb(244,240,254)"},
      4: {color: "rgb(244,240,254)"},
      5: {color: "rgb(244,240,254)"},
      6: {color: "rgb(244,240,254)"},
      7: {color: "rgb(244,240,254)"},
      8: {color: "rgb(244,240,254)"},
      9: {color: "rgb(244,240,254)"},
  },
  };

//Structure of Api calling End