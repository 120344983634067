export const finLogo = require("../assets/finLogo.png");
export const seekers = require("../assets/seekers.png");
export const providers = require("../assets/providers.png");
export const arrangers = require("../assets/arrangers.png");
export const linkedIn = require("../assets/linkedIn.png");
export const twitter = require("../assets/twitter.png");
export const facebook = require("../assets/facebook.png");
export const instagram = require("../assets/instagram.png");
export const finmart_logo = require("../assets/finmart_logo.png");
export const profile = require("../assets/profile.svg");
export const password = require("../assets/password.svg");
export const signOut = require("../assets/signOut.svg");
export const switchProfile = require("../assets/switchProfile.svg");
export const headerProfile = require("../assets/headerProfile.svg");
export const careerPage = require("../assets/group_Careers.png");
export const aboutuspage = require("../assets/aboutuspage.webp");
export const Business_256 = require("../assets/Business_256.png");
export const report_256 = require("../assets/report_256.png");
export const bell_256 = require("../assets/bell_256.png");
export const update_256 = require("../assets/update_256.webp");
export const Compliance_256 = require("../assets/Compliance_256.png");
export const web6 = require("../assets/web6.webp");
export const trigger = require("../assets/trigger.png");
export const Automated = require("../assets/Automated.png");
export const email = require("../assets/email-notification.png");
export const Compliance = require("../assets/Compliance.png");
export const data_256 = require("../assets/data_256.webp");