import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { IDirectorData } from "./ManagementProfileController";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  directManagementData: IDirectorData
  updateDirectManageMentData: (data: IDirectorData, index: number) => void,
  index: number,
  deleteManageData:(index:number)=>void,
  isReadMode: boolean;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
   showDropDown:boolean;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class DirectorProfileFormController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
      this.state={
        showDropDown:false
      };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
