// Customizable Area Start
import { callApi } from "../../../components/src/common";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import MessageEnum, { getName } from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";


export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  history: any;
  match: any;
  location: any;
}

interface S {
  arrayHolder: any;
  token: string;
  openFilter: boolean;
  openFilter2: boolean;
  openFilterCurrent: boolean;
  openFiltertTransaction: boolean;
  dealDiscovery: any[];
  onGoingDealsData: any[];
  onGoingDetailsData: any[];
  currentHoldingsData: any[];
  transactionHistory: any[];
  filterData: any;
  openModal: boolean;
  kyc: boolean;
  role: string;
  yieldData: string;
  proposed_total_tenor: string;
  proposed_type_of_instrument_id: string;
  proposed_type_of_instrument_title: string;
  proposed_sectors: string;
  proposed_sectorsTitle: string;
  rating_details_rating_id: string;
  rating_details_rating_title: string;
  sort_by: string;
  sort_type: string;
  currentData: string;
  sortByData: any;
  sortTypeData: any;
  onGoingModal: boolean;
  transactionDetails: any[];
  projectDropdown: any[];
  OngoingProjectDropdown: any[];
  currentProjectDropdown: any[];
  ongoingDropdownId: string;
  currentHoldingDropdownId: string;
  transactionDropdownId: string;
  transactionFilter: any[];
  onGoingHistoryMeta: any;
  deal_status: string;
  sortByIssuerId: string;
  range: number;
  utrNumber: string;
  currentHoldingCardId: string;
  utrError: boolean;
  checked: boolean;
  expanded: boolean | string;
  onGoingExpanded: boolean;
  currentHoldingExpand: boolean;
  transactionExpand: boolean;
  transactionExpand2: boolean;
  loading: boolean;
  spinner: boolean;
  transactionSpinner: boolean;
  changeDiscoverDeals: boolean;
  onGoingLoading: boolean;
  isBank: boolean;
  sortbyTop: Number;
  sortTypeTop: Number;
  isDemat: boolean,
  open:boolean,
  groupChatDialogId:any,
  dealName:any
}

interface SS {
  id: any;
}

export default class DealDiscoveryController extends BlockComponent<
  Props,
  S,
  SS
> {
  getProductApiCallId: any;
  getDealDescriptionId: string = '';
  getOngoingDealId: string = '';
  getCurrentHoldingsId: string = '';
  getTransactionHistoryId: string = '';
  getFilterId: string = '';
  getSortById: string = '';
  getTransactionSortById: string = '';
  sortPostId: string = '';
  getOngoingDealHistory: string = '';
  getTransactionDetailID: string = '';
  getProjectDropdownID: string = '';
  getOngoingProjectDropdownID: string = '';
  getCurrentHoldingProjectDropdownID: string = '';
  getTransactionSelectID: string = '';
  ongoingAfterFilter: string = '';
  transactionAfterFilter: string = '';
  currentHoldingsAfterFilter: string = '';
  postUtrNumberid: string = '';
  downloadRepaymentId: string = '';
  downloadAssedtMonitoringId: string = '';
  getUserInfo: string = '';
  notificationsToChatApiCallId:string=''

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage)

    ];

    this.state = {
      arrayHolder: [],
      token: "",
      openFilter: false,
      openFilter2: false,
      openFilterCurrent: false,
      dealDiscovery: [],
      filterData: [],
      openModal: false,
      kyc: false,
      role: '',
      yieldData: '',
      proposed_total_tenor: '',
      proposed_type_of_instrument_id: '',
      proposed_sectors: '',
      rating_details_rating_id: '',
      sort_by: '',
      sort_type: '',
      sortTypeData: [],
      sortByData: [],
      rating_details_rating_title: '',
      proposed_sectorsTitle: '',
      proposed_type_of_instrument_title: '',
      currentData: "Discover_Deals",
      onGoingDealsData: [],
      currentHoldingsData: [],
      transactionHistory: [],
      onGoingDetailsData: [],
      onGoingModal: false,
      openFiltertTransaction: false,
      transactionDetails: [],
      projectDropdown: [],
      OngoingProjectDropdown: [],
      currentProjectDropdown: [],
      ongoingDropdownId: '',
      transactionDropdownId: '',
      currentHoldingDropdownId: '',
      transactionFilter: [],
      onGoingHistoryMeta: {},
      deal_status: '',
      sortByIssuerId: '',
      range: 5,
      utrNumber: '',
      currentHoldingCardId: '',
      utrError: false,
      checked: false,
      expanded: false,
      onGoingExpanded: false,
      currentHoldingExpand: false,
      transactionExpand: false,
      transactionExpand2: false,
      loading: false,
      spinner: false,
      transactionSpinner: false,
      changeDiscoverDeals: false,
      onGoingLoading: false,
      isBank: false,
      sortbyTop: 50,
      sortTypeTop: 50,
      isDemat: false,
      open:false,
      groupChatDialogId:'',
      dealName:''
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    window.scrollTo(0, 0)
    const urlParams = new URLSearchParams(window.location.search).get('current');
    const token = localStorage.getItem("token")
    const kyc = localStorage.getItem("is_kyc_done")
    const isBank = localStorage.getItem("isBankDetailDone")

    if (isBank === 'true') {
      this.setState({ isBank: true })
    }
    if (kyc === 'true') {
      this.setState({ kyc: true })
    }
    if (urlParams == 'Ongoing_Deals') {
      this.setState({ currentData: 'Ongoing_Deals' })
      if (kyc === 'true') {
        this.getOngoingDeals()
      }
    }
    if (urlParams == 'Transaction_History') {
      this.setState({ currentData: 'Transaction_History' })
      if (kyc === 'true') {
        this.getTransactionHistory()
      }
    }
    if (urlParams == 'Current_Holdings') {
      this.setState({ currentData: 'Current_Holdings' })
      if (kyc === 'true') {
        this.getCurrentHolding()
      }
    }
    if (urlParams == 'Discover_Deals') {
      this.setState({ currentData: 'Discover_Deals' })
      this.getDealDiscovery()
    }

    if (token) {
      this.getUser()
      this.getDealDiscovery()
      this.getDiscoveryFilter()
      this.getSortBy()
      this.getTransactionHProjectFilter()
      this.getTransactionSelectVal()
      this.getCurrentHoldingProjectFilter()
      this.getOngoingProjectFilter()
    }

    let timerId = setInterval(() => {
      if (!token) {
        this.setState({ openModal: true })
      }
    }, 1000);

    setTimeout(() => {
      clearInterval(timerId);
    }, 1001)

  }



  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );


      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      /* istanbul ignore next */
      if (responseJson) {
        if (responseJson?.errors) {
          if (responseJson?.errors[0]?.token === 'Token has Expired') {
            localStorage.removeItem("token")
            this.setState({ openModal: true, loading: false, spinner: false })
          } else {
            let res = responseJson?.errors.map((i: any) => (Object.keys(i) + ' ' + Object.values(i))).join('\n')
            const msg73: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg73.addData(getName(MessageEnum.CustomAlertBodyData), res);
            this.send(msg73);
            this.setState({ loading: false, spinner: false })
          }
        } else {
          if (this.getDealDescriptionId === apiRequestCallId) {
            this.setState({ dealDiscovery: responseJson?.data, spinner: false })
            if (!this.state.kyc || !this.state.isBank) {
              localStorage.setItem("is_kyc_done", JSON.stringify(responseJson?.meta?.kyc));
              localStorage.setItem("isBankDetailDone", JSON.stringify(responseJson?.meta?.is_bank));
              responseJson?.meta?.kyc ? this.setState({ kyc: true }) : "";
              responseJson?.meta?.is_bank ? this.setState({ isBank: true }) : "";
            }
            if (responseJson?.meta?.is_demat == false) {
              this.setState({ isDemat: true })
            }
          }
          if (this.getFilterId === apiRequestCallId) {
            this.setState({ filterData: responseJson?.data })
          }
          if (this.getSortById === apiRequestCallId) {
            responseJson?.data && this.setState({ sortByData: responseJson?.data[0]?.value })
            responseJson?.data && this.setState({ sortTypeData: responseJson?.data[1]?.value })
          }
          if (this.sortPostId === apiRequestCallId) {
            this.setState({ spinner: false })
            this.setState({ dealDiscovery: responseJson?.data })
            /* istanbul ignore next */
            if(responseJson?.data?.length === 0){
              const msg186: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg186.addData(getName(MessageEnum.CustomAlertBodyData), "No data found for this filter");
              this.send(msg186);
            } 
          }
          if (this.getOngoingDealId === apiRequestCallId) {
            this.setState({ onGoingDealsData: responseJson?.data, onGoingLoading: false })

          }
          if (this.getCurrentHoldingsId === apiRequestCallId) {
            this.setState({ currentHoldingsData: responseJson?.data, loading: false })
          }
          if (this.getTransactionHistoryId === apiRequestCallId) {
            this.setState({ transactionHistory: responseJson?.data, transactionSpinner: false })
          }
          if (this.getOngoingDealHistory === apiRequestCallId) {
            this.setState({ onGoingDetailsData: responseJson?.data })
            this.setState({ onGoingHistoryMeta: responseJson?.meta })
          }
          if (this.getTransactionDetailID === apiRequestCallId) {
            this.setState({ transactionDetails: responseJson?.data })
          }
          if (this.getTransactionSortById === apiRequestCallId) {
            this.setState({ transactionFilter: responseJson?.provider_list })
          }
          if (this.getUserInfo === apiRequestCallId) {
            this.setState({ role: responseJson?.data?.attributes?.role_name })
          }
          if (this.getProjectDropdownID === apiRequestCallId) {
            responseJson?.data && this.setState({ projectDropdown: responseJson?.data[0]?.value })
          }
          if (this.getOngoingProjectDropdownID === apiRequestCallId) {
            responseJson?.data && this.setState({ OngoingProjectDropdown: responseJson?.data[0]?.value })
          }
          if (this.getCurrentHoldingProjectDropdownID === apiRequestCallId) {
            responseJson?.data && this.setState({ currentProjectDropdown: responseJson?.data[0]?.value })
          }
          if (this.ongoingAfterFilter === apiRequestCallId) {
            this.setState({ onGoingDealsData: responseJson?.data })

          }
          if (this.transactionAfterFilter === apiRequestCallId) {
            /* istanbul ignore next */
            if(responseJson?.data){
              this.setState({ transactionHistory: responseJson?.data }) 
            } else{
              const msg187: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg187.addData(getName(MessageEnum.CustomAlertBodyData), responseJson?.error);
              this.send(msg187);
            }

          }
          if (this.currentHoldingsAfterFilter === apiRequestCallId) {
            this.setState({ currentHoldingsData: responseJson?.data })
          }
          /* istanbul ignore next */
          if (this.postUtrNumberid === apiRequestCallId) {
            this.getCurrentHolding()
            const msg188: Message = new Message(getName(MessageEnum.CustomAlertMessage));
            msg188.addData(getName(MessageEnum.CustomAlertBodyData), 'UTR number added for this deal.');
            this.send(msg188);
          }
          /* istanbul ignore next */
          if (this.downloadRepaymentId === apiRequestCallId) {
            if (responseJson?.url) {
              this.download(responseJson?.url)
            } else {
              const msg189: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg189.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message);
              this.send(msg189);
            }
          }
          /* istanbul ignore next */
          if (this.downloadAssedtMonitoringId === apiRequestCallId) {
            if (responseJson?.url) {
              this.download2(responseJson?.url)
            } else {
              const msg190: Message = new Message(getName(MessageEnum.CustomAlertMessage));
              msg190.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.message);
              this.send(msg190);
            }
          }
        }

      }
    }
  }

  download = (url: string) => {
    fetch(url, {
      method: "GET",

    }).then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Repayment profile';
        a.click();
      }).catch(error => console.log('error', error));

  }
  download2 = (url: string) => {
    fetch(url, {
      method: "GET",

    }).then(response => response.blob())
      .then(blob => {
        let url = window.URL.createObjectURL(blob);
        let a = document.createElement('a');
        a.href = url;
        a.download = 'Asset monitoring report';
        a.click();
      }).catch(error => console.log('error', error));

  }



  repaymentDownload = async (id: any) => {
    const { apiMethodTypeGet } = configJSON
    this.downloadRepaymentId = callApi({
      method: apiMethodTypeGet,
      endPoint: `bx_block_bank_deal/deal_discovery/download_repayment_profile?deal_id=${id}`,
    })
  }
  assetMonitoringDownload = async (id: any) => {
    const { apiMethodTypeGet } = configJSON
    this.downloadAssedtMonitoringId = callApi({
      method: apiMethodTypeGet,
      endPoint: `bx_block_bank_deal/deal_discovery/asset_monitoring_report?debt_deal_id=${id}`,
    })
  }
  onGoingAfterFilter = async (id: any, name: string) => {
    const { apiMethodTypeGet } = configJSON
    if (name === 'onGoing') {
      this.ongoingAfterFilter = callApi({
        method: apiMethodTypeGet,
        endPoint: `bx_block_bank_deal/deal_discovery/ongoing_deals?project_id=${id}`,
      });
    } else {
      this.currentHoldingsAfterFilter = callApi({
        method: apiMethodTypeGet,
        endPoint: `bx_block_bank_deal/deal_discovery/current_holdings_deals?project_id=${id}`,
      });
    }
  }
  getDeal_Transaction_History = async (id: any) => {
    const { apiMethodTypeGet } = configJSON
    this.getTransactionDetailID = callApi({
      method: apiMethodTypeGet,
      endPoint: `bx_block_bank_deal/deal_transaction_histories/get_transaction_history?deal_id=${id}`,
    })
  }

  postUtrNumber = async (id: any) => {

    const data2 = {
      data: {
        utr: this.state.utrNumber,
        debt_deal_id: id
      }
    };

    const { apiPostMethod } = configJSON
    this.postUtrNumberid = callApi({
      method: apiPostMethod,
      endPoint: 'bx_block_bank_deal/offers/add_utr',
      payload: data2
    })
  };

  getDeal_History = async (id: any) => {
    const { apiMethodTypeGet } = configJSON
    this.getOngoingDealHistory = callApi({
      method: apiMethodTypeGet,
      endPoint: `bx_block_bank_deal/deal_transaction_histories/get_transaction_history?deal_id=${id}`,
    })
  }

  getTransactionHProjectFilter = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getProjectDropdownID = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/fetch_thistory_project',
    })
  }
  getOngoingProjectFilter = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getOngoingProjectDropdownID = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/fetch_ongoing_project',
    })
  }
  getCurrentHoldingProjectFilter = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getCurrentHoldingProjectDropdownID = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/fetch_current_holdings_project',
    })
  }
  getTransactionSelectVal = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getTransactionSortById = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_transaction_histories/debt_seeker',
    })
  }
  getTransactionFilter = async () => {
    const { apiMethodTypeGet } = configJSON
    const { transactionDropdownId, deal_status, sortByIssuerId } = this.state;
    this.transactionAfterFilter = callApi({
      method: apiMethodTypeGet,
      endPoint: `bx_block_bank_deal/deal_transaction_histories/get_transaction_history_deals${sortByIssuerId && `?debt_seeker_id=${sortByIssuerId}`}${deal_status && `?deal_status=${deal_status}`}${transactionDropdownId && `?project_details_id=${transactionDropdownId}`}`,
    })

  }
  getDiscoveryFilter = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getFilterId = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/deals_discovery_filter_dropdown',
    })
  }

  getDealDiscovery = async () => {
    const { apiMethodTypeGet, dealDiscoveryEndpoint } = configJSON
    this.setState({ spinner: true })
    this.getDealDescriptionId = callApi({
      method: apiMethodTypeGet,
      endPoint: dealDiscoveryEndpoint,
    })
  }

  getOngoingDeals = async () => {
    const { applicationType, apiMethodTypeGet } = configJSON
    this.setState({ onGoingLoading: true })
    this.getOngoingDealId = callApi({
      ContentType: applicationType,
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/ongoing_deals',
    })
  }
  getCurrentHolding = async () => {
    const { apiMethodTypeGet } = configJSON
    this.setState({ loading: true })
    this.getCurrentHoldingsId = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/current_holdings_deals',
    })
  }
  getTransactionHistory = async () => {
    const { apiMethodTypeGet } = configJSON
    this.setState({ transactionSpinner: true })
    this.getTransactionHistoryId = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_transaction_histories/get_transaction_history_deals',
    })
  }
  getSortBy = async () => {
    const { apiMethodTypeGet } = configJSON
    this.getSortById = callApi({
      method: apiMethodTypeGet,
      endPoint: 'bx_block_bank_deal/deal_discovery/deals_discovery_sort_by_dropdown',
    })
  }

  handleSubmitController = async () => {
    const { yieldData, proposed_total_tenor, proposed_type_of_instrument_id, rating_details_rating_id, proposed_sectors, sort_by, sort_type } = this.state
    this.setState({ spinner: true })
    var data = {
      yield: yieldData,
      proposed_total_tenor,
      proposed_type_of_instrument_id,
      rating_details_rating_id,
      proposed_sectors,
      sort_by,
      sort_type,
    };
    const { apiPostMethod } = configJSON
    this.sortPostId = callApi({
      method: apiPostMethod,
      endPoint: `bx_block_bank_deal/deal_discovery/filter_deals`,
      payload: data
    })
  }
  getUser = () => {
    this.getUserInfo = callApi({
      method: configJSON.apiMethodTypeGet,
      endPoint: 'account_block/accounts',
    })
  }
  openChatBox = (e:any,dialogId:any,dealId:any,dealName:string)=>{
    e.stopPropagation()
    this.sendNotifications(dealId)
    this.setState({open:!this.state.open,groupChatDialogId:dialogId,dealName:dealName})

  }

  handleDialogBox = ()=>{
    this.setState({open:!this.state.open})
  }

  sendNotifications = (offerId:any)=>{
    const data={method:'GET',payload:'',
    endPoint:configJSON.initiateChatApiEndpoint +`?deal_id=${offerId}`}
    this.notificationsToChatApiCallId= callApi(data)
   
    
    

  }

  // Customizable Area End
}
