import React from "react";
// Customizable Area Start
import {
    Paper,
    Box,
    Toolbar,
    Grid,
    Typography,
} from "@material-ui/core";
import LandingPageController, { Props } from "../../../blocks/landingpage/src/LandingPageController";
import Header from "../Header.web";
import Footer from "../../../blocks/landingpage/src/Footer.web";



export default class Team extends LandingPageController {
    constructor(props: Props) {
        super(props);
    }

    componentDidMount(): any {
        this.getPrivacyPolicy();
    }

    render() {
        return (
            <>

                <Header match='' location="" history="" id="" navigation={this.props.navigation} />
                <Toolbar />
                <Toolbar />
             
                <Typography style={webStyle.headingBlog}>Our Team</Typography>

                <Grid container spacing={4} style={{ display: 'flex', width: "100%", justifyContent: 'center', margin:'0',paddingLeft:'3em',paddingRight:"3em",paddingTop:'1em'}}>
                    {this.context.AboutUsState && this.context.AboutUsState.map((e: any) => {
                        return (
                            <Grid item
                                xs={12} sm={6} md={4} lg={4} xl={4}
                              style={{display: 'flex',alignItems:'stretch',justifyContent: 'center'}}
                            >
                                <Paper style={{backgroundColor:"#fff",width:'23.2vw'}}>
                                    {/* <Box style={{textAlign:"center",justifyContent: "center"}}> */}
                                <Box style={webStyle.imagepad} key={e?.id}>
                                    <a href={e.attributes?.linkedin_link} target="_blank">
                                    <img
                                        alt="connect"
                                        src={e.attributes?.image?.url
                                        }
                                        style={webStyle.imgStyle}
                                    />
                                    </a>
                                    
                                </Box>
                                <div style={{textAlign:"center",paddingRight:"1em",paddingLeft:"1em"}}>
                                <Typography style={webStyle.headingStyle} >{e.attributes?.name}</Typography>
                                <Typography style={webStyle.headingStyleSub} >{e.attributes?.title}</Typography>
                                <Box style={{backgroundColor: "transparent"}} >
                                <Typography align="justify" style={webStyle.headingSubStyle}
                                    dangerouslySetInnerHTML={{ __html: e.attributes?.description }}
                                >
                                </Typography>
                                </Box>
                                
                                </div>
                                
                                
                                {/* </Box> */}
                                </Paper>
                            </Grid>
                        )
                    })}


                </Grid>

                <div style={{ marginTop: '2em' }}>
                    <Footer navigation={this.props.navigation} id={""} match={undefined} location={undefined} history={undefined} />
                </div>


                {/* </Container> */}


            </>
        );
    }
}

const webStyle = {
    headingBlog: {
        color: "#323232",
        fontFamily: "Lato",
        fontSize: "32px",
        fontWeight: 700,
        paddingLeft: '2.8em',
        paddingTop: "0.5em",
     
    },
    mainInnerWrapper: {
        display: "block",
    },
    imgStyle: {
        width: "20.833vw",
        height: "20.833vw",

    },
    imagepad: {

        paddingLeft: '1em',
        display: 'flex',
        paddingTop: '1em',
    },
    headingStyle: {
        marginTop: '0.6em',
        fontFamily: " Lato",
        fontSize: "23px",
        fontWeight: 600,
        color: "#011d57", 
    },
    headingStyleSub: {
        marginTop: '0.6em',
        fontFamily: " Lato",
        fontSize: "18px",
        fontWeight: 600,
        color: "#666", 
    },
    headingSubStyle: {
        // paddingLeft: '2.2em',
        color: "#323232",
        fontFamily: " Lato",
        fontSize: "20px",
        fontWeight: 400,
        paddingRight:"0.1em",
        paddingBottom:"1em",
         backgroundColor:"transparent"
    },



}
// Customizable Area End

