import React from 'react'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
// import DateFnsUtils from "@date-io/date-fns";

type datePickerProps = {
    name: string;
    label: string;
    value: string;
    onChange: any;
}
export default function CustomDatePicker(props: datePickerProps) {

    const { name, label, value, onChange } = props


    const convertToDefEventPara = (name: string, value: string) => ({
        target: {
            name, value
        }
    })

    return (
        <MuiPickersUtilsProvider utils="">
            <KeyboardDatePicker disableToolbar variant="inline" inputVariant="outlined"
                label={label}
                format="MMM/dd/yyyy"
                name={name}
                value={value}
                onChange={date => onChange(convertToDefEventPara(name, date))}

            />
        </MuiPickersUtilsProvider>
    )
}