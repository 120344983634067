import React from "react";
// Customizable Area Start
import {
  Box,
  Button,
  Typography,
  Paper,
  InputLabel,
  TextField,
  FormControlLabel,
  Checkbox,
  Link,
  Grid,
  Container,
  OutlinedInput,
} from "@material-ui/core";
import { createTheme, makeStyles } from "@material-ui/core/styles";
import CheckCircleIcon from '@material-ui/icons/CheckCircle';

import { backgroundImage, logo } from "./assets";
import "./style.css";


const styles = makeStyles(() => ({
  root: {},
  paper: {},
  header: {},
}));

import ForgotPasswordController, { Props } from "./ForgotPasswordController";
import CustomSelect from "../../../components/src/CustomSelect";
import CustomInput from "../../../components/src/CustomInput";
// import ShowMsg from "./ShowMsg";


export default class NewPassword extends ForgotPasswordController {
  constructor(props: Props) {
    super(props);
  }
  handlePasswordChange = (e: any) => {
    let password = e.currentTarget.value;
    const regex = new RegExp(
      "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
    );
    !regex.test(password) || password.length > 20
      ? this.setState({ isErrorPassword: true })
      : this.setState({ isErrorPassword: false });
    this.setState({ password: e.currentTarget.value });
  };

  handleConfirmPasswordChange = (e: any) => {
    this.setState({ reTypePassword: e.currentTarget.value });
  };

  handleSubmit = (e: any) => {
    e.preventDefault();

    this.state.password.length == 0
      ? this.setState({ isErrorPassword: true })
      : null;

    this.state.reTypePassword.length == 0
      ? this.setState({ isErrorConfirmPasssword: true })
      : null;

    this.state.isErrorPassword || this.state.isErrorConfirmPasssword
      ? null
      : null;

    this.setNewPassword();
  };

  render() {
    const { navigation } = this.props;

    let isError =
      this.state.password.length === 0 ||
      this.state.reTypePassword.length === 0;

    return (
      <Grid container className="grid_parent_forgot">
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          {this.state.newCompo && (
            <div className="alert_box">
              <CheckCircleIcon style={{ color: "#29bf04", marginLeft: "40%", fontSize: "50px" }} />

              <Typography>{this.state.newCompo !== true ? this.state.newCompo : 'Your password has been Changed Successfully.'}</Typography>
              <Box>
                <Typography style={{ textAlign: "center", marginTop: "50px" }}>
                  Back to{" "}
                  <Link
                    id="sign_link"
                    onClick={() =>
                      navigation.navigate("EmailAccountLoginBlock")
                    }
                  >
                    Login
                  </Link>
                </Typography>
              </Box>

            </div>
          )}
          <Paper elevation={3} className="paper_container">
            <Box className="left_container_reset" style={{ backgroundImage: `url(${backgroundImage})`, }} >
              <Box className="sign_up_left_container">
                <Box>
                  <img alt="logo" src={logo} />
                </Box>
              </Box>
            </Box>
            <Box className="sign_rightInner_container">
              <Box className="signUp_right_content_Container">
                <form onSubmit={this.handleSubmit}>
                  <Typography
                    className="header_part"
                    style={{fontWeight:'bold'}}
                  >
                    Reset Password
                  </Typography>
                  <Typography
                    variant="body2"
                    className="change_password_subheader"
                    style={{ color: 'grey', marginTop: '1%' }}
                  >
                    Your new password must be different from the recent
                    <br />
                    password
                  </Typography>
                  <Box className="right_textInput_container">
                    <InputLabel required id="inputLabel">
                      Create new password
                    </InputLabel>

                    <input
                      className="signUp_right_textInput"
                      type="password"
                      name="password"
                      placeholder="**********"
                      onChange={this.handlePasswordChange}
                    />

                    {this.state.isErrorPassword ? (
                      <>
                      <Typography style={{ color: "red", paddingLeft: "16px" }}>
                        Please enter a password between 8 to 20 characters.
                      </Typography>
                      <Typography style={{ color: 'grey', paddingLeft: '16px' }}>
                      *User can enter 8 characters,1 uppercase letter, 1
                      number, 1 special characters combinations of them in
                      this field.
                    </Typography>
                    </>
                    ) : null}
                  </Box>
                  <Box className="right_textInput_container">
                    <InputLabel required id="inputLabel">
                      Confirm new password
                    </InputLabel>

                    <input
                      className="signUp_right_textInput"
                      type="password"
                      name="reTypePassword"
                      placeholder="**********"
                      onChange={this.handleConfirmPasswordChange}
                    />
                    {this.state.password === this.state.reTypePassword ||
                      this.state.isErrorPassword ||
                      this.state.reTypePassword.length === 0 ? null : (
                        <Typography style={{ color: "red", paddingLeft: "16px" }}>
                          Password and Confirm Password does not match.
                        </Typography>
                    )}
                  </Box>
                  <Box />

                  <Box id="MuiButton-fullWidth">
                    <Button
                      type="submit"
                      color="primary"
                      fullWidth
                      variant="contained"
                      className="req_button"
                      disabled={this.state.password !== this.state.reTypePassword ||
                        this.state.isErrorPassword ||
                        this.state.reTypePassword.length === 0 ?true:false}
                    // onClick={this.setNewPassword}
                    >
                      Submit
                    </Button>
                  </Box>
                  <Box>
                    <Typography
                      style={{ textAlign: "center", marginTop: "50px", marginBottom: '16%' }}
                    >
                      Back to{" "}
                      <Link
                        id="sign_link"
                        href="EmailAccountLoginBlock"
                      >
                        Login
                      </Link>
                    </Typography>
                  </Box>
                </form>
              </Box>
            </Box>
          </Paper>
        </Grid>
      </Grid>
    );
    return (
      <div className="main_container">
        <Container>
          {this.state.newCompo && (
            <div className="alert_box">
              <CheckCircleIcon style={{ color: "#29bf04", marginLeft: "40%", fontSize: "50px" }} />

              <Typography>{this.state.newCompo !== true ? this.state.newCompo : 'Your password has been Changed Successfully.'}</Typography>
              <Box>
                <Typography style={{ textAlign: "center", marginTop: "50px" }}>
                  Back to{" "}
                  <Link
                    id="sign_link"
                    onClick={() =>
                      navigation.navigate("EmailAccountLoginBlock")
                    }
                  >
                    Login
                  </Link>
                </Typography>
              </Box>

            </div>
          )}
          <Paper elevation={3} className="paper_container">
            <Box className="left_container" style={{ backgroundImage: `url(${backgroundImage})`, }}>
              <Box className="sign_up_left_container">
                <Box style={{ marginTop: "250px", fontSize: "20px" }}>
                  <img alt="logo" src={logo} />
                </Box>
              </Box>
            </Box>
            <Box className="signUp_rightContainer">
              <Box className="sign_rightInner_container">
                <Box className="signUp_right_content_Container">
                  <form onSubmit={this.handleSubmit}>
                    <Typography
                      className="header_part"
                    >
                      Reset Password
                    </Typography>
                    <Typography
                      variant="body2"
                      className="subheader"
                    >
                      Your new password must be different from recent
                      <br />
                      password
                    </Typography>
                    <Box className="right_textInput_container">
                      <InputLabel required id="inputLabel">
                        Create New Password
                      </InputLabel>

                      <input
                        className="signUp_right_textInput"
                        type="password"
                        name="password"
                        placeholder="**********"
                        onChange={this.handlePasswordChange}
                      />

                      {this.state.isErrorPassword ? (
                        <Typography style={{ color: "red", paddingLeft: "16px" }}>
                          Please enter a password between 8 to 20 characters.
                        </Typography>
                      ) : null}
                    </Box>
                    <Box className="right_textInput_container">
                      <InputLabel required id="inputLabel">
                        Confirm New Password
                      </InputLabel>

                      <input
                        className="signUp_right_textInput"
                        type="password"
                        name="reTypePassword"
                        placeholder="**********"
                        onChange={this.handleConfirmPasswordChange}
                      />
                      {this.state.password === this.state.reTypePassword ||
                        this.state.isErrorPassword ||
                        this.state.reTypePassword.length === 0 ? null : (
                        <Typography style={{ color: "red", paddingLeft: "16px" }}>
                          Password and Confirm Password does not match.
                        </Typography>
                      )}
                    </Box>
                    <Box />

                    <Box id="MuiButton-fullWidth">
                      <Button
                        type="submit"
                        color="primary"
                        fullWidth
                        variant="contained"
                        className="req_button"
                      // onClick={this.setNewPassword}
                      >
                        Submit
                      </Button>
                    </Box>
                    <Box>
                      <Typography
                        style={{ textAlign: "center", marginTop: "50px" }}
                      >
                        Back to{" "}
                        <Link
                          id="sign_link"
                          href="EmailAccountLoginBlock"
                        >
                          Login
                        </Link>
                      </Typography>
                    </Box>
                  </form>
                </Box>
              </Box>
            </Box>
          </Paper>
        </Container>
      </div>
    );
  }
}
// Customizable Area End