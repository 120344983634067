Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "PrivacySettings";
exports.labelBodyText = "PrivacySettings Body";

exports.btnExampleTitle = "CLICK ME";
exports.getProvidersListApiEndpoint = "bx_block_bank_deal/deal_visibilities/get_provider_list";
exports.getProvidersListApiMethod = "GET";
exports.submitSettingApiEndpoint = "bx_block_bank_deal/deal_visibilities";
exports.submitSettingApiMethod = "POST";
exports.getDealVisibilityApiEndpoint = "bx_block_bank_deal/deal_visibilities/show";
exports.getDealVisibilityApiMethod = "GET";
exports.updateVisibilitySettingsApiEndpoint = "bx_block_bank_deal/deal_visibilities/update";
exports.updateVisibilitySettingsApiMethod = "PUT";
// Customizable Area End