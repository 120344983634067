
import { BlockComponent } from "../../../framework/src/BlockComponent";
 // Customizable Area Start
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
  navigation?: any;
  id?: string;
  // Customizable Area Start
    isReadMode?: boolean;
    htmlFor?: string;
    label: string;
    cinId: string;
    placeholder: string;
    inputProps: any;
    value: any;
    onKeyDown?: (e: any) => void;
    onChange: (e: any) => void;
    disabled?: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
      
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CinForProfileController extends BlockComponent<
  Props,
  S,
  SS
> {
  constructor(props: Props) {
    super(props);

    // Customizable Area Start
    this.state={
        
    };
    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End
}
