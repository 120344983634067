Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.apiPostMethod="POST";
exports.kycUpdateApiEndPoint="bx_block_bank_details/kyc_details/update";
exports.endPointApiGetBankListDropDownDetail="bx_block_bank_details/banks";
exports.endPointApiGetarrangerDropDownDetail="bx_block_bank_details/debt_arrangers";
exports.endPointApiGetProviderDropDownDetail="bx_block_bank_details/debt_providers";
exports.endPointApiGetSeekarDropDownDetail="bx_block_bank_details/debt_seekers";
exports.endPointApiUpdateUserAddDematDetail="bx_block_bank_details/demat_details/update";
exports.kycVerificationLabel="KYC Verification";
exports.kycViewApiEndPoint="bx_block_bank_details/kyc_details/show";
exports.kycAddBankDetailLabel="Bank Account Details";
exports.kycAddDematDetail="Demat Details";
exports.tabValue=0;
exports.validationApiUpdateMethod="PUT";
exports.dematViewApiEndPoint="bx_block_bank_details/demat_details/show";
exports.kycUploadLabel="Upload PAN photo";
exports.bankUploadLabel="Upload cancel cheque photo";
exports.debtSeekerInputLabel="Type of Debt Seeker";
exports.debtArrangerInputLabel="Type of Debt Arranger";
exports.debtProviderInputLabel="Type of Debt Provider";
exports.dematUploadLabel="Upload Demat CMR Copy or Complete ECAS statement";
exports.uploadpanlabel="Upload Pan Photo";
exports.skipText="This step can be skipped. You can come back later to complete it.";
exports.endPointApiGetUser="bx_block_bank_details/kyc_details";
exports.endPointApiPostUserAddBankDetail="bx_block_bank_details/account_details";
exports.endPointApiPostUserAddKYCDetail="bx_block_bank_details/kyc_details";
exports.endPointApiPostUserAddDematDetail="bx_block_bank_details/demat_details";
exports.endPointApiGetUserBankAccountDetail="bx_block_bank_details/account_details/show";
exports.endPointApiBankDetailUpdateApi="bx_block_bank_details/account_details/update";
exports.nsdl="nsdl";
exports.cdsl="cdsl";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "CfEkycVerification3";
exports.labelBodyText = "CfEkycVerification3 Body";

exports.btnExampleTitle = "CLICK ME";
// Customizable Area End