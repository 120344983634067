import React from "react";

import {
  Box,
  Button,
  Typography,
  IconButton,
  // Customizable Area Start
  Grid,
  Paper,
   Modal,
  TextField,
  Avatar,
  
  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme,  withStyles } from "@material-ui/core/styles";
import AttachFileOutlinedIcon from '@material-ui/icons/AttachFileOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import SendIcon from '@material-ui/icons/Send';
import CloseIcon from '@material-ui/icons/Close';
import { InsertDriveFileOutlined } from '@material-ui/icons';



const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const StyledTextField:any = withStyles({
  root: {
    width: '70%',
    height: '6vh',
    borderRadius: '1.1vw',
    "& .MuiFilledInput-root": {
    },
    "& .MuiFilledInput-root:hover": {

      "@media (hover: none)": {
      }
    },
    "& .MuiFilledInput-root.Mui-focused": {

    },
    "& input::placeholder": {
      borderRadius: '1.1vw',
      backgroundColor: '#ffffff',
      backgroundBlendMode: 'normal',
      boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
      fontSize : '14px',
      color: '#001d56'

    }
  },
})((props: any) => <TextField {...props} />);


// Customizable Area End

import ChatQuickbloxController, {
  Props,
} from "../../../components/src/components/ChatQuickbloxController";

export default class Chat9 extends ChatQuickbloxController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start
  // Customizable Area End

  render() {
    console.log('props', this.props)
    return (
      // Customizable Area Start
      <Box>
        <Modal
          open={this.state.open}
          onClose={this.handleCloseChatBox}
          style={webStyle.modalContainer}
        >
          <Grid item xs={12} md={4} style={webStyle.blockChatMsg}>
            <Paper elevation={0}
              style={{ backgroundColor: '#fff', boxShadow: '-1px 5px 30px rgba(0,0,0,0.1)', boxSizing: 'border-box', ...webStyle.paperChatContainer }}  >
              <Grid container style={webStyle.chatMsgContainerGrid} >
                <Grid item  style={webStyle.chatMsgAvatarGrid}>
                  <Avatar style={webStyle.chatMsgAvatarStyle}>
                    {this.props.dealName.slice(0,1).toUpperCase()}
                     </Avatar>
                  </Grid>
                  <Grid item xs={9} style={{maxHeight:'2.5vw',overflow:'hidden',textAlign:'start',padding:'0px'}}>
                  <Typography style={webStyle.chatMsgNameStyle}>{this.props.dealName}</Typography>
                  </Grid>
                  <Grid item xs={1} style={{color:'#fff',cursor:'pointer'}}onClick={this.props.handleDialog}>
                  <CloseIcon style={{fontSize:'31px'}}/>
                  </Grid>
                
              </Grid>


              {/* -----------------scrollable chat grid starts--------------------- */}
              <Paper id='chat-content' elevation={0} style={{ boxSizing: 'border-box',  ...webStyle.paperchatScrollContainer }}  >

                {this.state.isLoading && <Typography align="center">Loading..</Typography>}

                {this.state.allMessages.map((message: any) => {
                  
                  let recepient = message.sender_id == localStorage.getItem('id')

                  
                    const fileUrl: any = message.attachments.length > 0?this.handleDownloadFile(message.attachments[0].id):null
                 
                  return (
                    <Box key={message._id}
                      style={recepient ? webStyle.senderChatMessageBox
                        : webStyle.recipientChatMessageBox}>
                      {message.sender_id != localStorage.getItem('id') && 
                      // <IconButton><Avatar alt="chatMsg avatar" style={webStyle.chatMsgAvatarLogoStyle} /></IconButton>
                      <Avatar style={{backgroundColor:'#bdbdbd',...webStyle.chatMsgAvatarLogoStyle}}>{message?.senderName?.slice(0,1).toUpperCase()}</Avatar>
                    }

                      <Box style={recepient?{borderTopLeftRadius:'9px',...webStyle.chatAdvisorMessageBox}:{borderTopRightRadius:'9px',...webStyle.chatAdvisorMessageBox}}>
                        <Box style={recepient ? webStyle.chatSenderName : webStyle.chatRecepientName}>
                          <Typography style={webStyle.chatMsgAdvisorTime}>{message?.senderName}</Typography>
                        </Box>
                        <Box style={message.attachments.length > 0 ? webStyle.attachmentMessageBox : webStyle.messageBox}>
                          {message.attachments.length > 0 ?<IconButton><InsertDriveFileOutlined></InsertDriveFileOutlined></IconButton>  : null}

                          <Typography style={webStyle.chatMsgAdvisorText}>
                            {message?.message}
                          </Typography>
                          {message.attachments.length > 0 ? <IconButton onClick={this.handleDownloadFile}>
                              <a href={fileUrl} download="proposed_file_name" target="_blank" style={{ color: '#474747', display: 'inline-block' }}>
                                <GetAppIcon></GetAppIcon>  </a>

                            </IconButton> : null}
                        </Box>
                      </Box>
                      {message.sender_id == localStorage.getItem('id') &&<Avatar style={{backgroundColor:'green',...webStyle.chatMsgAvatarLogoStyle}}>
                        {message?.senderName?.slice(0,1).toUpperCase()}</Avatar>                      }
                    </Box>
                  )
                })}
                {/* ----------------------- Client Chat message end---------------- */}
              </Paper>
              {/* -----------------scrollable chat grid starts--------------------- */}
              <Box style={{ display: 'flex', alignItems: "center", justifyContent: 'center' }}>
                <Box style={webStyle.sendBoxStyle}>
                  <div style={{width:'310px'}} >
                    <StyledTextField
                      variant="standard"
                      placeholder={this.state.file ? this.state.file.name : "Send your message.."}
                      fullWidth={true}
                      style={{
                        borderRadius: '1em',
                        backgroundColor: '#ffff',
                        backgroundBlendMode: 'normal',
                        // boxShadow: '0px 5px 15px rgba(68,68,79,0.1)',
                      }}

                      InputProps={{
                        disableUnderline: true,
                        style: { width: '310px' },
                      }}
                      name='typeMessage'
                      value={this.state.inputMessage}
                      data-test-id="file"
                      // onChange={(e: React.ChangeEvent<HTMLInputElement>) => this.setState({ inputMessage: e.target.value })}
                      onChange = {this.handleMessageInput}
                      onKeyDown={(e:any)=>e.key==='Enter'?this.handleSendMessage():null}
                      

                    />
                  </div>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                   
                    <IconButton component='label' style={{width:'9px'}}>
                    <input hidden accept=".xlsx,.xls,image/*,.doc, .docx,.ppt, .pptx,.txt,.pdf" onChange={(e:any)=>this.setState({file:e.target.files[0]},()=>document.getElementsByName('typeMessage')[0]?.focus())} type="file" />
                    <AttachFileOutlinedIcon></AttachFileOutlinedIcon>
                    </IconButton>
                   
                   
                   
                    <Button onClick={this.handleSendMessage} style={webStyle.iconAtttachBtn} >
                      <SendIcon style={{fontSize:'16px',transform: 'rotate(-43deg)'}}/>
                    </Button>
                  </div>
                </Box>
              </Box>
            </Paper>
           
          </Grid>
        </Modal>
      </Box>
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const webStyle = {
  mainWrapper: {
    display: "flex",

    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  modalContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  blockChatMsg: {
    display: 'flex',
    alignItems: 'stretch',
    
  },
  chatMsgContainerGrid: {
    padding: '0vw 0.4vw',
    paddingLeft:'0.4vw',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems:'center',
    borderTopLeftRadius:'5px',
    borderTopRightRadius:'5px',
    background: 'linear-gradient(108.82deg, rgb(0, 62, 143) 25.52%, rgb(0, 29, 86) 85.05%)',
    minHeight:'9vh'
  },
  chatMsgAvatarGrid: {
    display: 'flex',
    alignItems: 'center',
    
    },
  chatMsgAvatarStyle: {
    display:'flex',
    justifyContent:'center',
    alignItems:'center',
    borderRadius: '49%',
    width: '45px',
    height: '45px',
    
   },
  chatMsgNameStyle: {
    fontFamily: 'Poppins',
    fontSize: '1.5vw',
    fontWeight: 600,
    color: '#fff',
  },
  chatMsgStatusStyle: {
    fontFamily: 'Poppins',
    fontSize: '0.9vw',
    fontWeight: 500,
    color: 'fff',
    paddingLeft: '11px'
  },
  chatMsgAvatarLogoStyle: {
    borderRadius: '50%',
    height:'34px',
    width:'34px',
    display:'flex',
    alignItems:'center',
    justifyContent:'center',
    color:'fff',
    },
  iconAtttachBtn: {
    // transform: 'rotate(-25deg)',
    height: '2.5vw',
    minWidth: '34px',
    backgroundColor: 'rgb(0, 29, 86)',
    color: '#fff',
    padding:'2px 2px 4px 2px',
    marginLeft:'6px'
    
  },
  senderChatMessageBox: {
    margin: '1vh 0.3vw 1vw 9vh',
    justifyContent: 'flex-end',
    display: 'flex',
  },
  recipientChatMessageBox: {
    margin: '1vh 9vh 1vw 0.3vw',
    justifyContent: 'flex-start',
    display: 'flex',
  },
  paperChatContainer: {
    display: 'flex',
    alignText: 'stretch',
    margin: '0.5vw',
    flexDirection: 'column' as 'column',
    maxHeight: '100vh',
    width: '78vh',
    borderRadius: '5px'
  },
  paperchatScrollContainer: {
    display: 'flex',
    alignText: 'stretch',
    flexDirection: 'column' as 'column',
    height: '60vh',
    maxWidth: '100vw',
    overflow: 'auto',
    backgroundColor: '#DCDCDC',

  },
  chatAdvisorMessageBox: {
    backgroundColor: '#fff',
    boxShadow: '0px 5px 9px rgba(68,68,79,0.1)',
    borderBottomLeftRadius:'9px',
    borderBottomRightRadius:'9px',
    border: '1px solid #f1f1f5',
    margin: '0 0.2vw 0 0.2vw',
    padding: '0.2vw 0.5vw',
  },
  
  chatSenderName: {
    display: 'flex',
    justifyContent: 'flex-end',

  },
  chatRecepientName: {
    display: 'flex',
    justifyContent: 'flex-start',

  },
  chatMsgAdvisorTime: {
    color: '#001d56',
    fontSize: '0.9vw',

  },
  attachmentMessageBox: {
    display: 'flex',
    alignItems : 'center',
    backgroundColor: 'rgb(239, 255, 238)',
    // backgroundColor :'#f7f8fa',
    borderRadius: '9px',
    padding: '0.4vw 0.4vw',
    margin: '0.2vw'
  },
  chatMsgAdvisorText: {
    fontFamily: 'Roboto',
    fontSize: '1vw',
    fontWeight: 400,
    color: '#474747',
    display: "inline-block",
  },
  messageBox: {
    display: 'flex'
  },
  sendBoxStyle: {
    width: '99%',
    padding: ' 0 0.4vw 0 0.7vw',
    height: '6vh',
    backgroundColor: '#fafafb',
    borderRadius: '5px',
    display: 'flex',
    justifyContent:'space-between',
    alignItems: 'center',
    zIndex: 100,
  },



};
// Customizable Area End
