Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start

exports.validationApiContentType = "application/json";
exports.getProductListApiContentType = "application/json";
exports.getTermAndConditionApiContentType = "application/json";
exports.getPrivacyPolicyApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.privacyTermAndConditionAPiEndPoint = "privacy_settings/terms_and_conditions/";
exports.privacyPolicyAPiEndPoint = "privacy_settings/privacy_policies/";
exports.productListAPiEndPoint = "bx_block_productdescription/products/";
exports.debtTypeAPiEndPoint = "bx_block_landingpage3/debt_types";
exports.blogs = "bx_block_admin/blogs";
exports.aboutUs = "bx_block_landingpage3/teams";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "LandingPage";
exports.labelBodyText = "LandingPage Body";
exports.btnExampleTitle = "CLICK ME";
exports.carrerApi="bx_block_profile_bio/careers";
exports.carrerApiCities="bx_block_location/cities";
exports.carrerApiMethod="POST";
exports.carrerApiCitiesMethod="GET";
// Customizable Area End
