import React from 'react'
// Customizable Area Start

type otpProps = {
    id: string;
    onKeyUp: any;
    className?: any;
}

class OtpInput extends React.Component<otpProps>{

    state = {
        otpValue: ''
    }

    validatedOtp = (e: any) => {
        const regex = /\b[0-9]\b/;
        if (regex.test(e.target.value) || e.target.value == '') {
            this.setState({
                otpValue: e.target.value
            })
        }
    }

    render(): React.ReactNode {
        const { id, onKeyUp, className } = this.props
        return (
            <div style={webStyle.root} >
                <input
                    className={className}
                    style={{
                        width: "40px",
                        height: "40px",
                        borderRadius: "50%",
                        border: "1px solid #F4F3EF",
                        background: this.state.otpValue ? '#C8C8C8' : "#C8C8C8",
                        outline: "none",
                        textAlign: "center",
                        padding: 0,
                        color: "#001D56",
                        fontSize: "24px",
                        fontWeight: 400,
                    }}
                    id={id}
                    onKeyUp={onKeyUp}
                    type="text"
                    value={this.state.otpValue}
                    onChange={this.validatedOtp}
                    maxLength={1} />
            </div>
        )
    }
}
export default OtpInput as any;

const webStyle = {
    root: {
        width: "70%"
    },
}

// Customizable Area End