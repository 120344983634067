// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { DealDetailsInterface, ISeekerDealDetailsResponse } from "./Interface.web";
import { apiCall, getHeaders, isAuthorisedUser, isLoggedIn } from "../../../components/src/common";

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  classes: any;
}

export interface IDealVisibilityObject {
  Selected_investors: {
    id: number;
    first_name: string;
    last_name: null | string;
  }[];
  Show_to_Selected_investors: boolean;
  Show_to_Selected_investors_except_for: boolean;
  Show_to_all_investors: boolean;
  account_id: number;
  debt_deal_id: number;
  dont_go_live_as_of_now: boolean;
}
interface S {
  arrayHolder: any;
  openFilter: boolean;
  isLoading: boolean;
  dealDetails: any | null;
  isShowVisibilitySettings: boolean;
  selectedVisibilityOption: VisbilityOptionsEnum;
  selectedProviders: any[];
  pieData: any[];
  excelData: any[];
  providerList: {
    id: number;
    first_name: string;
    last_name: string;
    full_phone_number: string;
    country_code: number;
    phone_number: number;
    email: string;
    activated: boolean;
    device_id?: any;
    unique_auth_id: string;
    password_digest: string;
    created_at: Date;
    updated_at: Date;
    user_name?: any;
    platform?: any;
    user_type?: any;
    app_language_id?: any;
    last_visit_at?: any;
    is_blacklisted: boolean;
    suspend_until?: any;
    status: string;
    stripe_id?: any;
    stripe_subscription_id?: any;
    stripe_subscription_date?: any;
    role_id: number;
    full_name: string;
    gender?: any;
    date_of_birth?: any;
    age?: any;
    is_paid: boolean;
    company_name: string;
    is_age_above_18: boolean;
    terms_and_conditions: boolean;
    login_at?: any;
    approved_by_admin: boolean;
    isSelected: boolean;
  }[];
  dealVisibilitySetting: null | IDealVisibilityObject,
  isUpdate: boolean;
}

export enum VisbilityOptionsEnum {
  "SHOW_TO_ALL_DEBT_PROVIDERS" = "Show to all debt providers",
  "SHOW_TO_SELECTED_INVESTORS" = "Show_to_Selected_investors",
  "SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR" = "Show_to_Selected_investors_except_for",
  "DONT_GO_LIVE_AS_OF_NOW" = "dont_go_live_as_of_now"
}
interface SS {
  id: any;
}

export default class SeekerArrangerDealDetailsController extends BlockComponent<
  Props,
  S,
  SS
> {
  getDealDetailsId: string = "";
  authToken: string = "";
  dealId: any = "";
  getDealDetailsApiCallId: string = "";
  getProvidersListApiCallId: string = "";
  getDealVisibilitySettingsApiCallId: string = "";
  submitSettingApiCallId: string = "";
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
    ];

    this.state = {
      arrayHolder: [],
      openFilter: false,
      dealDetails: null,
      isLoading: true,
      isShowVisibilitySettings: false,
      pieData: [["x", "y", { role: "tooltip", type: "string" }]],
      excelData: [],
      selectedVisibilityOption: VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS,
      selectedProviders: [],
      providerList: [],
      dealVisibilitySetting: null,
      isUpdate: false
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }
  /* istanbul ignore next */
  async componentDidMount() {
    const authToken = isLoggedIn(this.props.navigation);
    this.dealId = this.props.navigation.getParam("id");
    if (typeof (authToken) === "string") {
      this.authToken = authToken;
      this.getProvidersList();
      this.getDealDetailsApi();
      this.getDealVisibilityDetail();
    }
  }

  async receive(from: string, message: Message) {
    if (message.id === getName(MessageEnum.RestAPIResponceMessage)) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      var errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      const isAuthenticated = isAuthorisedUser(responseJson);
      if (!isAuthenticated) {
        /* istanbul ignore next */
        this.props.navigation.navigate("EmailAccountLoginBlock")
      }
      /* istanbul ignore next */
      if (responseJson.error) {
        const msg192: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg192.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
        this.send(msg192);
        return;
      }
      /* istanbul ignore next */
      if (this.getDealDetailsApiCallId === apiRequestCallId) {
        if (responseJson.errors) {
          let res9 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg009: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg009.addData(getName(MessageEnum.CustomAlertBodyData), res9);
          this.send(msg009);
        } else if (responseJson.deal) {
          this.setState({ dealDetails: responseJson, excelData: responseJson?.xlsx_arr })
          let data: any[] = [["x", "y", { role: "tooltip", type: "string" }]]
          const leng = responseJson?.structures?.data?.attributes?.share_holdings.length;
          for (var i = 0; i < leng; i++) {
            /* istanbul ignore next */
            data.push(["", responseJson?.structures?.data?.attributes?.share_holdings[i]?.percentage_holding, `${responseJson?.structures?.data?.attributes?.share_holdings[i]?.share_holder_type}`])
          }
          this.setState({ pieData: data })
        }
      }
      /* istanbul ignore next */
      if (this.getProvidersListApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.provider_list) {
          this.setState({
            providerList: responseJson.provider_list.map((obj: any) => {
              return { ...obj, isSelected: false }
            }),
          });
        } else if (responseJson.error) {
          const msg193: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg193.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.alert);
          this.send(msg193);
        } 
        /* istanbul ignore next */
        else if (responseJson.errors) {
          let res10 = responseJson.errors
            .map((i: any) => Object.keys(i) + " " + Object.values(i))
            .join("\n");
          const msg0010: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg0010.addData(getName(MessageEnum.CustomAlertBodyData), res10);
          this.send(msg0010);
        }
      }

      /* istanbul ignore next */

      if (this.getDealVisibilitySettingsApiCallId === apiRequestCallId) {
        if (responseJson && responseJson.data) {
          this.setState({
            isUpdate: true,
            dealVisibilitySetting: responseJson.data.attributes,
            selectedProviders: responseJson.data.attributes.Selected_investors.map((provider: any) => {
              return provider.id
            }),
            selectedVisibilityOption: responseJson.data.attributes.Show_to_Selected_investors ?
              VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS :
              responseJson.data.attributes.Show_to_Selected_investors_except_for ?
                VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR :
                responseJson.data.attributes.Show_to_all_investors ? VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS : responseJson.data.attributes.dont_go_live_as_of_now ? VisbilityOptionsEnum.DONT_GO_LIVE_AS_OF_NOW : VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS
          });
        } 
        else if (responseJson.error) {
          const msg77: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg77.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg77);
        } else if (responseJson.errors) {
          const msg78: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg78.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.errors);
          this.send(msg78);
        }
      }

      /* istanbul ignore next */
      if (this.submitSettingApiCallId === apiRequestCallId) {
        if (responseJson.error) {
          const msg79: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg79.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.error);
          this.send(msg79);
        } else if (responseJson.data && responseJson.meta) {
          const msg80: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg80.addData(getName(MessageEnum.CustomAlertBodyData), responseJson.meta.message);
          this.send(msg80);
          this.setState({
            isShowVisibilitySettings: false
          });
          this.getDealVisibilityDetail();
        } else {
          const msg81: Message = new Message(getName(MessageEnum.CustomAlertMessage));
          msg81.addData(getName(MessageEnum.CustomAlertBodyData), "Something went wrong");
          this.send(msg81);
        }
      }
    }
  }

  getDealDetailsApi = () => {
    this.setState({
      isLoading: true
    });

    const header = getHeaders({ token: this.authToken });

    const getDealDetails = apiCall({
      url: configJSON.getDealDetailsApiEndpoint + `?deal_id=${this.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getDealDetailsApiMethod
    });

    this.getDealDetailsApiCallId = getDealDetails.messageId;
    runEngine.sendMessage(getDealDetails.id, getDealDetails);

  }

  handleVisibilitySettingsDropdownChange = (event: any) => {
    const { value } = event.target;

    this.setState({
      selectedProviders: typeof value === 'string' ? value.split(',') : value,
    })
  }

  getProvidersList = () => {
    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    const getProviders = apiCall({
      url: configJSON.getProvidersListApiEndpoint,
      header,
      httpBody: {},
      httpMethod: configJSON.getProvidersListApiMethod,
    });

    this.getProvidersListApiCallId = getProviders.messageId;
    runEngine.sendMessage(getProviders.id, getProviders);
  };

  getDealVisibilityDetail = () => {
    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    const getVisibility = apiCall({
      url: configJSON.getDealVisibilityApiEndpoint + `?debt_deal_id=${this.dealId}`,
      header,
      httpBody: {},
      httpMethod: configJSON.getDealVisibilityApiMethod,
    });

    this.getDealVisibilitySettingsApiCallId = getVisibility.messageId;
    runEngine.sendMessage(getVisibility.id, getVisibility);
  };

  /* istanbul ignore next */
  validateAndPrepareRequestBody = () => {
    const requestBody: any = {
      data: {
        debt_deal_id: this.dealId
      }
    };
    requestBody.data.Show_to_all_investors = false;
    requestBody.data.Show_to_Selected_investors = false;
    requestBody.data.Show_to_Selected_investors_except_for = false;
    requestBody.data.dont_go_live_as_of_now = false;
    requestBody.data.Selected_investors = "";
    const selectedProvidersList = this.state.selectedProviders;
    if (this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_ALL_DEBT_PROVIDERS) {
      requestBody.data.Show_to_all_investors = true;
    } else if (this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS) {
      /* istanbul ignore next */
      if (selectedProvidersList.length === 0) {
        const msg194: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg194.addData(getName(MessageEnum.CustomAlertBodyData), "Please select Debt Provider");
        this.send(msg194);
        return false;
      } else {
        requestBody.data.Selected_investors = selectedProvidersList.toString();
        requestBody.data.Show_to_Selected_investors = true;
      }
    } else if (this.state.selectedVisibilityOption === VisbilityOptionsEnum.SHOW_TO_SELECTED_INVESTORS_EXCEPT_FOR) {
      /* istanbul ignore next */
      if (selectedProvidersList.length === 0) {
        const msg195: Message = new Message(getName(MessageEnum.CustomAlertMessage));
        msg195.addData(getName(MessageEnum.CustomAlertBodyData), "Please select Debt Provider");
        this.send(msg195);
        return false;
      } else {
        requestBody.data.Selected_investors = selectedProvidersList.toString();
        requestBody.data.Show_to_Selected_investors_except_for = true;
      }
    } else if (this.state.selectedVisibilityOption === VisbilityOptionsEnum.DONT_GO_LIVE_AS_OF_NOW) {
      requestBody.data.dont_go_live_as_of_now = true;
    }

    return requestBody;
  };

  /* istanbul ignore next */
  submitVisibilitySettings = () => {
    const requestBody = this.validateAndPrepareRequestBody();
    if (!requestBody) {
      return;
    }

    this.setState({
      isLoading: true,
    });
    const header = getHeaders({ token: this.authToken });

    let url = '';
    if (this.state.isUpdate) {/* istanbul ignore next */
      url = configJSON.updateVisibilitySettingsApiEndpoint;
    } else {
      url = configJSON.submitSettingApiEndpoint + `?deal_id=${this.dealId}`;
    }

    const submitSettings = apiCall({
      url: url,
      header,
      httpBody: requestBody,
      httpMethod: this.state.isUpdate ? configJSON.updateVisibilitySettingsApiMethod : configJSON.submitSettingApiMethod,
    });

    this.submitSettingApiCallId = submitSettings.messageId;
    runEngine.sendMessage(submitSettings.id, submitSettings);
  };


  // Customizable Area End
}
